import RoundArrowLeftIcon from 'assets/svgs/RoundArrowLeftIcon';
import RoundArrowRightIcon from 'assets/svgs/RoundArrowRightIcon';
import React from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';

function Arrow({ children, side, onClick }) {
  return (
    <div
      onClick={onClick}
      className="clickable"
      style={side === 'left' ? { marginRight: 10 } : { paddingLeft: 10 }}
    >
      {children}
    </div>
  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow onClick={() => scrollPrev()} side="left">
      <RoundArrowLeftIcon isEnable={!disabled} size={36} />
    </Arrow>
  );
}

export function RightArrow() {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators
  } = React.useContext(VisibilityContext);

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow onClick={() => !disabled && scrollNext()} side="right">
      <RoundArrowRightIcon isEnable={!disabled} size={36} />
    </Arrow>
  );
}
