import React, { useState } from 'react';
import moment from 'moment';

import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import UCoinIcon from 'assets/svgs/UCoinsIcon';

import './index.styles.scss';
import BookMarkedIcon from 'assets/svgs/BookMarkedIcon';
import {
  apiQABookMarkAnswer,
  apiQAUnBookMarkAnswer,
  apiQAVote
} from 'shared/api/qa';
import RobotIcon from 'assets/svgs/RobotIcon';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { useNavigate } from 'react-router-dom';
interface OtherAnswerData {
  id: number;

  ai_id?: number;
  ai_type?: 'user' | 'general' | 'organisation' | 'group';
  ai_name?: string;
  ai_image?: string;
  timestamp: string;
  answer: string;
  creator_id?: number;
  creator_image?: string;
  creator_name?: string;
  creator_email?: string;
  user_vote?: number;
  1: number;
  2: number;
  3: number;
  is_anonymous: number;
  points: number;
  is_bookmarked: number;
}
interface CardOtherUserAnswerProps {
  data: OtherAnswerData;
  onVoteUpdate: (points: number) => void;
}

export const CardOtherUserAnswer = ({
  data,
  onVoteUpdate
}: CardOtherUserAnswerProps) => {
  const navigate = useNavigate();

  const [vote, setVote] = useState(data.user_vote);
  const [agree, setAgree] = useState(data['1']);
  const [doubt, setDoubt] = useState(data['2']);
  const [notSure, setNotSure] = useState(data['3']);

  const [isBookmarking, setIsBookmarking] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(data.is_bookmarked === 1);
  const [questionPoints, setQuestionPoints] = useState(data.points);

  const onVote = async (vote: number) => {
    const res = await apiQAVote({
      answer: data.id,
      vote: vote
    });
    if (res.success) {
      setVote(vote);
      setQuestionPoints(res.points_answer);
      onVoteUpdate(res.points_question);
      const voteData = res.voting;
      setAgree(voteData['1']);
      setDoubt(voteData['2']);
      setNotSure(voteData['3']);
    }
  };

  const onBookmarkAnswer = async () => {
    if (!isBookmarking) {
      setIsBookmarking(true);

      if (isBookmarked) {
        const res = await apiQAUnBookMarkAnswer({
          answer: data.id
        });

        if (res.success) {
          setIsBookmarked(false);
        }
      } else {
        const res = await apiQABookMarkAnswer({
          answer: data.id,
          item_id: undefined,
          item_type: 'user'
        });

        if (res.success) {
          setIsBookmarked(true);
        }
      }

      setIsBookmarking(false);
    }
  };

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between">
        {renderCreatorCreatorInfo()}
        {renderTopRight()}
      </div>
    );
  };

  const renderCreatorCreatorInfo = () => {
    if (data.is_anonymous == 1) {
      return (
        <div className="align-vertical-center">
          <DefaultAvatarIcon size={24} />
          <div
            className="font-medium font16 user_name"
            style={{ marginLeft: 8 }}
          >
            Anonymous
          </div>
        </div>
      );
    } else {
      if (data.creator_id) {
        return (
          <div
            className="align-vertical-center clickable"
            onClick={onViewProfile}
          >
            {data.creator_image ? (
              <img
                className="creator_image"
                src={data.creator_image}
                width="24"
                height="24"
                style={{ borderRadius: 24 }}
              ></img>
            ) : (
              <DefaultAvatarIcon size={24} />
            )}

            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {data.creator_name ? data.creator_name : 'Anonymous'}
            </div>
          </div>
        );
      } else {
        return (
          <div className="align-vertical-center">
            <RobotIcon />
            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {'General AI'}
            </div>
          </div>
        );
      }
    }
  };
  const renderTopRight = () => {
    return (
      <div className="row_align_items">
        <div className="answer_point">
          <UCoinIcon />
          &nbsp;
          {`${questionPoints} pt${questionPoints > 1 ? 's' : ''}`}
        </div>
        <div className="question_date font14">
          {moment(data.timestamp).format('DD MMM yyyy').toString()}
        </div>
      </div>
    );
  };

  const renderAnswer = () => {
    return (
      <div className="font-regular font16" style={{ marginTop: 16 }}>
        <pre className="font16">{data.answer}</pre>
      </div>
    );
  };
  const renderBottom = () => {
    return (
      <div className="answer_box_bottom">
        <div className="answer_box_bottom_left">
          {isBookmarking
            ? renderIsBookmarking()
            : isBookmarked
            ? renderBookmarkedBtn()
            : renderBookmarkBtn()}
          <div
            className={
              vote === 1
                ? 'agree_btn_highlighted clickable'
                : 'agree_btn clickable'
            }
            onClick={() => onVote(1)}
          >
            <div className="btn_icon font-medium font16">🙆‍♂️</div>
            <div className="btn_label font-medium font12">Agree</div>
            <div className="btn_number font-medium font12">{agree}</div>
          </div>
          <div
            className={
              vote === 2
                ? 'agree_btn_highlighted clickable'
                : 'agree_btn clickable'
            }
            onClick={() => onVote(2)}
          >
            <div className="btn_icon font-medium font16">🙅‍♀️️</div>
            <div className="btn_label font-medium font12">Doubt</div>
            <div className="btn_number font-medium font12">{doubt}</div>
          </div>
          <div
            className={
              vote === 3
                ? 'agree_btn_highlighted clickable'
                : 'agree_btn clickable'
            }
            onClick={() => onVote(3)}
          >
            <div className="btn_icon font-medium font16">🤔️</div>
            <div className="btn_label font-medium font12">Unsure</div>
            <div className="btn_number font-medium font12">{notSure}</div>
          </div>
        </div>
      </div>
    );
  };
  const renderIsBookmarking = () => {
    return (
      <div className="bookmark_btn_icon_only">
        <LoadingCircular color="main" size={20} />
      </div>
    );
  };

  const renderBookmarkBtn = () => {
    return (
      <div
        className="bookmark_btn_icon_only clickable"
        onClick={onBookmarkAnswer}
      >
        <BookMarkedIcon color={'rgba(0, 0, 0, 0.4)'} />
      </div>
    );
  };

  const renderBookmarkedBtn = () => {
    return (
      <div
        className="bookmark_btn_icon_only clickable"
        onClick={onBookmarkAnswer}
      >
        <BookMarkedIcon opacity="0.4" />
      </div>
    );
  };
  return (
    <div className="card_other_user_answer">
      {renderHeader()}
      {renderAnswer()}
      {renderBottom()}
    </div>
  );
};
