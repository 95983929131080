import React, { useEffect, useState } from 'react';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';

import './index.styles.scss';
import { GroupHome } from './Home';
import { Route, Routes } from 'react-router-dom';
import { GroupDetail } from './Detail';
import { GroupMember } from './GroupMember';
import { sendAnalytics } from 'shared/utils/analytics';

export const GroupScreen = () => {
  const [groupId, setGroupId] = useState(0);

  useEffect(() => {
    sendAnalytics('Groups');
  }, []);

  return (
    <div className="group_container">
      <MainHeader currentPage="group" groupId={groupId} />
      <Routes>
        <Route index path="" element={<GroupHome setGroupId={setGroupId} />} />
        <Route
          path="detail/:code/*"
          element={<GroupDetail setGroupId={setGroupId} groupId={groupId} />}
        />
        <Route path="member/:id/*" element={<GroupMember />} />
      </Routes>
    </div>
  );
};
