import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import React, { useEffect } from 'react';
import moment from 'moment';

import './index.styles.scss';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import { useNavigate } from 'react-router-dom';
import MicIcon from 'assets/svgs/MicIcon';

interface CardAllSharedDocsProp {
  data: any;
}

export const CardAllSharedDocs = ({ data }: CardAllSharedDocsProp) => {
  const navigate = useNavigate();

  useEffect(() => {
    var element = document.getElementById(`card_shared_doc_title_${data.id}`);

    if (isElementOverflowing(element)) {
      wrapContentsInMarquee(element);
    }
  }, []);

  function isElementOverflowing(element) {
    var overflowX = element.offsetWidth < element.scrollWidth,
      overflowY = element.offsetHeight < element.scrollHeight;

    return overflowX || overflowY;
  }

  function wrapContentsInMarquee(element) {
    var marquee = document.createElement('marquee'),
      contents = element.innerText;

    marquee.innerText = contents;
    element.innerHTML = '';
    element.appendChild(marquee);
  }

  const onDetail = () => {
    if (data.dump_type === 'pdf' && data.status === 'completed') {
      navigate(`/document/${data.id}`);
    }
  };

  const renderIcon = () => {
    if (data.dump_type === 'pdf') return <PdfFileIcon size={100} />;
    else if (data.dump_type === 'audio')
      return (
        <div className="audio_play_icon_view">
          <MicIcon size={80} color="#FF3E9A" />
        </div>
      );
    else return <div style={{ fontSize: 80 }}>📄</div>;
  };

  const renderTop = () => {
    return (
      <div className="share_doc_body row_space_between">
        <div className="row_align_items">{renderIcon()}</div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="info_body">
        <div
          className="doc_title font-bold font18"
          id={`card_shared_doc_title_${data.id}`}
        >
          {data.title ?? data.filename ?? data.url}
        </div>
        <div className="row_align_items" style={{ marginTop: 8, gap: 8 }}>
          {data.size && (
            <div
              className="font-regular font12"
              style={{ color: 'rgba(0, 0, 0, 0.8)' }}
            >
              {`Size ${data.size} MB`}
            </div>
          )}
          <div
            className="font-regular font12"
            style={{ color: 'rgba(0, 0, 0, 0.8)' }}
          >
            {moment(data.timestamp).format('DD MMM')}
          </div>
        </div>
        <div className="row_align_items" style={{ marginTop: 8 }}>
          {data.user !== 0 && data.creator_image.startsWith('https') ? (
            <img
              src={data.creator_image}
              style={{ width: 16, height: 16, borderRadius: 16 }}
            />
          ) : (
            <DefaultAvatarIcon size={16} />
          )}
          <div className="font-medium font10" style={{ marginLeft: 4 }}>
            {data.user !== 0 ? data.creator_name : 'Anonymous'}
          </div>
        </div>
      </div>
    );
  };

  const renderShadowView = () => {
    return <div className="sub_effect_body2"></div>;
  };

  return (
    <div className="card_all_shared_doc_container clickable" onClick={onDetail}>
      {renderShadowView()}
      <div className="card_all_shared_doc_org">
        {renderTop()}
        {renderBottom()}
      </div>
    </div>
  );
};
