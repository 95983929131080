import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import CloseIcon from 'assets/svgs/CloseIcon';
import LightBulbIcon from 'assets/svgs/LightBulbIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { convertFirstToUpper, getImageFromHtml } from 'shared/utils/utils';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { apiGetUserSharingQR } from 'shared/api/user';

import './index.styles.scss';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import { apiQAGetShareLink } from 'shared/api/qa';
import { StatementData } from 'components/Main/Organisation/OrgHome/components/TopDidYouKnow/CardDidYouKnow';

interface StatementShareModalProps {
  show: boolean;
  onClose: () => void;
  selectedStatement?: StatementData;
}

export const StatementShareModal = ({
  show,
  onClose,
  selectedStatement
}: StatementShareModalProps) => {
  const { uploadBase64 } = useIPFS();

  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [isLoadingQrcode, setIsLoadingQRcode] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (show) {
      getSharingQRcode();
    } else {
      setShareUrl('');
    }
  }, [selectedStatement, show]);

  const initShareUrl = async () => {
    if (selectedStatement) {
      const base64Image = await getImageFromHtml(
        `statement_share_body_${selectedStatement.id}`
      );
      const url = await uploadBase64(base64Image, 'image/png', '');

      const res = await apiQAGetShareLink({
        url: url,
        item_id: selectedStatement!.id,
        type: 'statement'
      });

      if (res.success) {
        setShareUrl(res.sharing_url);
      }
    }
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        let base64Image = await getImageFromHtml(
          `statement_share_body_${selectedStatement?.id}`
        );

        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const getSharingQRcode = async () => {
    setIsLoadingQRcode(true);
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
      setIsLoadingQRcode(false);

      setTimeout(() => {
        initShareUrl();
      }, 2000);
    }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const renderContentHeader = () => {
    return (
      <div className="statement_card_header">
        <div className="statement_card_header_tip">
          <div className="statement_card_header_tip_lamp">
            <LightBulbIcon />
          </div>
          <div className="statement_card_header_tip_did_you_know">
            Did you know
          </div>
        </div>
        <div className="statement_card_header_attach">
          <img
            src="/logo512.png"
            width={26}
            height={26}
            style={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    );
  };

  const descTxt = useMemo(() => {
    const tmpTxt = selectedStatement?.text.startsWith('Did you know that ')
      ? selectedStatement?.text
          .replace('Did you know that ', '')
          .replace('?', '')
      : selectedStatement?.text.startsWith('Did you know that')
      ? selectedStatement?.text
          .replace('Did you know that', '')
          .replace('?', '')
      : selectedStatement?.text.replace('Did you know', '').replace('?', '');

    return convertFirstToUpper(tmpTxt);
  }, [selectedStatement]);

  const renderContentBody = () => {
    return <div className="statement_card_body">{descTxt}</div>;
  };

  const renderScanBody = () => {
    return (
      <div className="statement_card_bottom">
        <div className="statement_card_bottom_user">
          <div className="statement_card_bottom_user_avatar">
            {selectedStatement?.creator_image &&
            selectedStatement?.creator_image.startsWith('https') ? (
              <img
                src={selectedStatement.creator_image}
                style={{ width: 24, height: 24, borderRadius: 24 }}
              />
            ) : (
              <DefaultAvatarIcon size={24} />
            )}
          </div>
          <div className="statement_card_bottom_user_name font16 font-normal">
            {selectedStatement?.creator_name}
          </div>
        </div>
        <div className="statement_card_bottom_btns clickable">
          {isLoadingQrcode ? (
            <LoadingCircular color="main" size={60} />
          ) : (
            <>
              <div className="statement_card_bottom_btns_QR">
                <img
                  src={qrCodeUrl}
                  style={{ width: '60px', height: '60px' }}
                  crossOrigin="anonymous"
                />
              </div>
              {/* <div className="statement_card_bottom_btns_scan_me  text-white">
                <div className="font-bold" style={{ fontSize: 6 }}>
                  Scan Me
                </div>
              </div> */}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderShartBody = () => {
    return (
      <div className="statement_link_bar">
        <div className="statement_link_bar_items">
          <div
            className="statement_link_bar_items_item download_itm clickable"
            onClick={() => onShare('download')}
          >
            {isDownloading ? (
              <LoadingCircular size={20} color={'white'} />
            ) : (
              <Download1Icon />
            )}
          </div>
          <div className="font10">Download</div>
        </div>

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <FacebookShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div
                className="statement_link_bar_items_item Telegram_itm "
                style={{ padding: 0 }}
              >
                <FacebookIcon borderRadius={16} size={48} />
              </div>
              <div className="font10">Facebook</div>
            </div>
          </FacebookShareButton>
        )}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div>
        <div
          className="statement_card"
          id={`statement_share_body_${selectedStatement?.id}`}
        >
          {renderContentHeader()}
          {renderContentBody()}
          {renderScanBody()}
        </div>
        {renderShartBody()}
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={510} onClose={onClose}>
      <div className="modal_share_statement">
        <div className="modal_share_statement_header">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon opacity={0.4} />
          </div>
          <div className="title font-bold font20">Share</div>
        </div>
        <div className="modal_share_statement_body">{renderBody()}</div>
      </div>
    </PrimaryModal>
  );
};
