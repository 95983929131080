import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';

import './index.styles.scss';

interface DumpData {
  id: number;
  interest: number;
  name: string;
  image: string;
  followers: number;
  is_interest_followed: number;
  posts: number;
}

interface CardOrgProps {
  data: DumpData;
  selectedOrg: any;
  onClick: (orgInfo) => void;
}

export const CardOrg = ({ data, selectedOrg, onClick }: CardOrgProps) => {
  const navigate = useNavigate();

  const onSelected = () => {
    onClick(data);
    navigate(`/school/${data.id}`);
  };

  return (
    <div
      className="org_box clickable selected_org"
      style={
        selectedOrg?.id !== data.id
          ? { backgroundColor: 'transparent', boxShadow: 'none' }
          : { backgroundColor: 'white' }
      }
      onClick={() => onSelected()}
    >
      <img src={data.image} className="org_box_icon" />
      <div className="font-regular font20 org_box_label">{data.name}</div>
    </div>
  );
};
