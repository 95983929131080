import CloseIcon from 'assets/svgs/CloseIcon';
import React, { useEffect, useState } from 'react';
import { apiGetUserMobileQR } from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

import './index.styles.scss';

interface MobileLoginModalProps {
  show: boolean;
  onClose: () => void;
}

export const MobileLoginModal = ({ show, onClose }: MobileLoginModalProps) => {
  const [qrCodeImage, setQRCodeImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (show) {
      loadUserQRCode();
    }
  }, [show]);

  const loadUserQRCode = async () => {
    setIsLoading(true);

    const res = await apiGetUserMobileQR();

    if (res.success) {
      setQRCodeImage(res.qr_url);
    }

    setIsLoading(false);
  };

  return (
    <PrimaryModal show={show} width={500} onClose={onClose}>
      <div className="mobile_login_qr_modal">
        <div style={{ width: '100%' }}>
          <div className="clickable" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="mobile_login_qr_modal_header">
          <div className="align-vertical-center">
            <img
              src={'/logo512.png'}
              width={20}
              height={24}
              style={{ objectFit: 'contain' }}
            />
          </div>
          <div className="title font-bold font16" style={{ marginLeft: 8 }}>
            {process.env.REACT_APP_NAME}
          </div>
        </div>
        <div style={{ marginTop: 38 }}>
          {isLoading ? (
            <div className="mobile_login_qr_modal_image">
              <LoadingCircular size={40} color="main" />
            </div>
          ) : (
            <img src={qrCodeImage} className="mobile_login_qr_modal_image" />
          )}
        </div>
        <div className="font-bold font24" style={{ marginTop: 38 }}>
          QR Login for Mobile
        </div>
        <div
          className="font-regular font16"
          style={{ marginTop: 12, opacity: 0.6 }}
        >
          Scan this QR code to login with your phone
        </div>
        <div
          className="mobile_login_qr_modal_close clickable"
          onClick={onClose}
        >
          <div className="font-bold font14">Close</div>
        </div>
      </div>
    </PrimaryModal>
  );
};
