import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useState } from 'react';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';
import CloseIcon from 'assets/svgs/CloseIcon';
import ReadCheckIcon from 'assets/svgs/ReadCheckIcon';
import {
  apiAcceptGroupRequesst,
  apiRejectGroupRequest
} from 'shared/api/group';

interface CardPendingMemberProps {
  data: any;
  onRemove: (memberId) => void;
}

export const CardPendingMember = ({
  data,
  onRemove
}: CardPendingMemberProps) => {
  const [isRejecting, setIsRejecting] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  const onAcceptInvitation = async () => {
    if (!isAccepting && !isRejecting) {
      setIsAccepting(true);

      const res = await apiAcceptGroupRequesst({ request: data.id });

      if (res.success) {
        onRemove(data.id);
      }
      setIsAccepting(false);
    }
  };

  const onRejectInvitation = async () => {
    if (!isAccepting && !isRejecting) {
      setIsRejecting(true);

      const res = await apiRejectGroupRequest({ request: data.id });
      if (res.success) {
        onRemove(data.id);
      }
      setIsRejecting(false);
    }
  };

  return (
    <div className="row_space_between" style={{ marginBottom: 36 }}>
      <div className="row_align_items">
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img
            src={data.creator_image}
            style={{ width: 40, height: 40, borderRadius: 40 }}
          />
        ) : (
          <DefaultAvatarIcon size={40} />
        )}
        <div style={{ marginLeft: 12 }}>
          <div className="font-bold font14">{data.creator_name}</div>
          <div className="font-regular font14" style={{ opacity: 0.4 }}>
            {data.creator_email}
          </div>
        </div>
      </div>
      <div className="row_align_items">
        <div className="remove_btn clickable" onClick={onRejectInvitation}>
          {isRejecting ? (
            <LoadingCircular size={20} color="main" />
          ) : (
            <CloseIcon size={20} opacity={0.3} />
          )}
        </div>
        <div className="check_btn clickable" onClick={onAcceptInvitation}>
          {isAccepting ? (
            <LoadingCircular size={20} color="white" />
          ) : (
            <ReadCheckIcon size={20} color="#FFFFFF" />
          )}
        </div>
      </div>
    </div>
  );
};
