import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import { ConversationReference } from 'components/Common/ConversationReference';
import { ConversationReferenceDetail } from 'components/Common/ReferenceDetail/ConversationReferenceDetail';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { TypeLoadingText } from 'shared/ui-kit/TypeLoadingText';
import { getArrFromString } from 'shared/utils/utils';

import './index.styles.scss';

interface ChatDataType {
  id: number;
  message: string;
  is_ai: number;
  type: 'text';
  is_new?: boolean;
}

interface CardHighlightProps {
  data: ChatDataType;
}

export const CardHighlightChat = ({ data }: CardHighlightProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isExpandReference, setIsExpandReference] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(data.is_new);

  useEffect(() => {
    setIsNewMessage(data.is_new ? data.is_new : false);
  }, [data.is_new]);

  if (data.is_ai === 0) {
    return (
      <>
        <div
          className="card_chat"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}
        >
          <div className="card_chat_header">
            <div className="card_chat_header_image">
              {userInfo?.image && userInfo?.image.startsWith('https') ? (
                <img src={userInfo?.image} className="creator_image" />
              ) : (
                <DefaultAvatarIcon size={24} />
              )}
            </div>
            <div className="font-bold font12" style={{ marginLeft: 8 }}>
              {'You'}
            </div>
          </div>
          <div className="card_chat_own_message font-regular font20">
            {data.message}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="card_chat">
          <div className="card_chat_header">
            <div className="card_chat_header_image">
              <RobotIcon size={24} />
            </div>
            <div className="font-bold font12" style={{ marginLeft: 8 }}>
              {`File AI`}
            </div>
          </div>
          <div className="card_chat_message">
            {isNewMessage ? (
              <div className="font-regular font20">
                <TypeLoadingText
                  text={data.message}
                  speed={100}
                  onFinished={() => {
                    data.is_new = false;
                    setIsNewMessage(false);
                  }}
                />
              </div>
            ) : (
              <div className="font-regular font20">{data.message}</div>
            )}
          </div>
        </div>
      </>
    );
  }
};
