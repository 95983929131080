import React, { useEffect, useState } from 'react';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

import moment from 'moment';

import './index.styles.scss';
import useIPFS from 'shared/utils-IPFS/useIPFS';

import CloseIcon from 'assets/svgs/CloseIcon';
import LightBulbIcon from 'assets/svgs/LightBulbIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { getImageFromHtml } from 'shared/utils/utils';
import { apiQAGetShareLink } from 'shared/api/qa';
import { apiGetUserSharingQR } from 'shared/api/user';
import TwitterIcon from 'assets/svgs/TwitterIcon';

interface Props {
  show: boolean;
  eventData: any;
  onClose: () => void;
}

export const ShareEventModal = ({ show, eventData, onClose }: Props) => {
  const { uploadBase64 } = useIPFS();

  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [isLoadingQrcode, setIsLoadingQRcode] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (show) {
      getSharingQRcode();
    } else {
      setShareUrl('');
    }
  }, [eventData, show]);

  const initShareUrl = async () => {
    if (eventData) {
      const base64Image = await getImageFromHtml(
        `event_share_body_${eventData.id}`
      );
      const url = await uploadBase64(base64Image, 'image/png', '');

      const res = await apiQAGetShareLink({
        url: url,
        item_id: eventData!.id,
        type: 'event'
      });

      if (res.success) {
        setShareUrl(res.sharing_url);
      }
    }
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        let base64Image = await getImageFromHtml(
          `event_share_body_${eventData?.id}`
        );

        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const getSharingQRcode = async () => {
    setIsLoadingQRcode(true);
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
      setIsLoadingQRcode(false);

      setTimeout(() => {
        initShareUrl();
      }, 2000);
    }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const renderHeader = () => {
    return (
      <div className="card_header row_space_between">
        <div className="row_align_items">
          <div className="row_align_items">
            {eventData.creator_image?.startsWith('https') ? (
              <img src={eventData.creator_image} className="item_image" />
            ) : (
              <DefaultAvatarIcon size={28} />
            )}
            {eventData.org_image?.startsWith('https') ? (
              <img
                src={eventData.org_image}
                className="item_image"
                style={{ marginLeft: -6 }}
              />
            ) : (
              <div style={{ marginLeft: -6 }}>
                <DefaultAvatarIcon size={28} />
              </div>
            )}
          </div>
          <div className="font-bold font12" style={{ marginLeft: 4 }}>
            {eventData.creator_name}
            <span
              className="font-bold font12"
              style={{ opacity: 0.4, margin: '0px 4px' }}
            >
              in
            </span>
            {eventData.org_name}
          </div>
        </div>
        <div className="statement_card_bottom_btns clickable">
          {isLoadingQrcode ? (
            <LoadingCircular color="main" size={60} />
          ) : (
            <>
              <div className="statement_card_bottom_btns_QR">
                <img
                  src={qrCodeUrl}
                  style={{ width: '60px', height: '60px' }}
                  crossOrigin="anonymous"
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div>
        <div
          className="statement_card"
          id={`event_share_body_${eventData?.id}`}
        >
          {renderHeader()}
          <div className="row_align_items" style={{ marginTop: 8 }}>
            <div style={{ flex: 1 }}>{renderInfo()}</div>

            {renderImage()}
          </div>
        </div>
        {renderShartBody()}
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <div className="info_view">
        <div className="row_align_items">
          <div className="card_mark"></div>
          <div className="card_title font-medium font24">{eventData.title}</div>
        </div>
        <div className="row_align_items" style={{}}>
          <div style={{ fontSize: 14 }}>📍</div>
          <div className="card_desc font-regular font14">
            {eventData.location}
          </div>
        </div>
        <div className="row_align_items">
          <div className="row_align_items" style={{}}>
            <div style={{ fontSize: 14 }}>🗓</div>
            <div
              className="font-regular font14"
              style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: 6 }}
            >
              {moment(eventData.date).format('DD MMM YYYY')} •{' '}
              {moment(eventData.date).format('hh:mm A')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderImage = () => {
    if (eventData.image?.startsWith('https')) {
      return <img src={eventData.image} className="event_image" />;
    } else {
      return <div></div>;
    }
  };

  const renderShartBody = () => {
    return (
      <div className="statement_link_bar">
        <div className="statement_link_bar_items">
          <div
            className="statement_link_bar_items_item download_itm clickable"
            onClick={() => onShare('download')}
          >
            {isDownloading ? (
              <LoadingCircular size={20} color={'white'} />
            ) : (
              <Download1Icon />
            )}
          </div>
          <div className="font10">Download</div>
        </div>

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <FacebookShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div
                className="statement_link_bar_items_item Telegram_itm "
                style={{ padding: 0 }}
              >
                <FacebookIcon borderRadius={16} size={48} />
              </div>
              <div className="font10">Facebook</div>
            </div>
          </FacebookShareButton>
        )}
        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Twitter_itm">
                <TwitterIcon />
              </div>
              <div className="font10">Twitter</div>
            </div>
          </TwitterShareButton>
        )}
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={510} onClose={onClose}>
      <div
        className="modal_share_event"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal_share_event_header">
          <div
            className="align-vertical-center clickable"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <CloseIcon opacity={0.4} />
          </div>
          <div className="title font-bold font20">Share Event</div>
        </div>
        <div className="modal_share_event_body">{renderBody()}</div>
      </div>
    </PrimaryModal>
  );
};
