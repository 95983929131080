import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AnswerBox } from './AnswerBox';
import './index.styles.scss';
import { NavigationBar } from './NavigationBar';
interface LeftPanelProps {
  setTab: any;
  answer: string;
  setAnswer: any;
  setIsEditAnswer: any;
  isEditAnswer: boolean;
  questionDetail: any;
  selectedUserRefs: any;
  selectedFileRefs: any;
  onAddAnswer: () => void;
  showSnackBar: () => void;
}
export const LeftPanel = ({
  setTab,
  answer,
  setAnswer,
  setIsEditAnswer,
  isEditAnswer,
  selectedUserRefs,
  selectedFileRefs,
  questionDetail,
  onAddAnswer,
  showSnackBar
}: LeftPanelProps) => {
  return (
    <div className="org_right_panel">
      <NavigationBar setTab={setTab} />
      <AnswerBox
        answer={answer}
        setAnswer={setAnswer}
        isEditAnswer={isEditAnswer}
        setIsEditAnswer={setIsEditAnswer}
        selectedUserRefs={selectedUserRefs}
        selectedFileRefs={selectedFileRefs}
        questionDetail={questionDetail}
        setTab={setTab}
        onAddAnswer={onAddAnswer}
        showSnackBar={showSnackBar}
      />
    </div>
  );
};
