import { AddTagModal } from 'components/Modals/AddTagModal';
import React, { useState } from 'react';
import Columns from 'react-columns';
import { CardPreview, PreviewData } from './CardPreview';
import Masonry from 'react-masonry-css';

import './index.styles.scss';
const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};
interface ListPreviewProps {
  data: Array<PreviewData>;
  item_type: 'user' | 'group';
  onReload: () => void;
  onRemoveOnUI: (id) => void;
}

export const ListPreview = ({
  data,
  item_type,
  onReload,
  onRemoveOnUI
}: ListPreviewProps) => {
  const [modalPosition, setModalPosition] = useState<number | undefined>(24);
  const [isShowAddTagModal, setIsShowAddTagModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const onUpdateTag = (item, newTags) => {
    onReload();
    setIsShowAddTagModal(false);
  };

  const renderAddTagModal = () => {
    return (
      <AddTagModal
        show={isShowAddTagModal}
        isExtension={true}
        onClose={() => setIsShowAddTagModal(false)}
        onSuccess={onUpdateTag}
        selectedFile={selectedFile}
        positionX={modalPosition}
      />
    );
  };

  return (
    <div className="link_preview_body">
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data.map((item, index) => {
          return (
            <CardPreview
              key={`card_preview_${index}`}
              data={item}
              item_type={item_type}
              onAddTag={(data, positionX) => {
                setSelectedFile(data);
                setIsShowAddTagModal(true);
                setModalPosition(positionX);
              }}
              onRemoveOnUI={onRemoveOnUI}
            />
          );
        })}
      </Masonry>
      {/* </Columns> */}
      {renderAddTagModal()}
    </div>
  );
};
