import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import EnvelopeIcon from 'assets/svgs/EnvelopeIcon';
import InstagramIcon from 'assets/svgs/InstagramIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import './index.styles.scss';
export const CardTrendingNewCreators = (data) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const navigate = useNavigate();

  const onClickChat = (id) => {
    navigate(`/conversation/user/${id}`);
  };

  const onClickProfile = (id) => {
    if (userInfo?.id !== id) navigate(`/profile/${id}`);
  };

  return (
    <div className="card_trending_creators">
      <div
        className="card_trending_creators_inner"
        onClick={() => onClickProfile(data.data.ai_id)}
      >
        <div className="card_trending_creators_user_avatar">
          {data.data.ai_image == '' ? (
            <DefaultAvatarIcon size={105} />
          ) : (
            <img src={data.data.ai_image} width={105} height={105} />
          )}
        </div>
        <div className="card_trending_creators_user_info">
          <div className="font-bold font20">{data.data.ai_name}</div>
          {/* <div className='card_trending_creators_user_info_satisfied '>
                        <div className='font16'>
                            👍
                        </div>
                        <div className='font14'>
                            80% satisfied customer
                        </div>
                    </div> */}
          <div className="card_trending_creators_user_info_bottom">
            <div className="card_trending_creators_user_info_bottom_icons">
              <InstagramIcon color="rgb(255, 62, 154)" />
              <TikTokIcon color="rgb(255, 62, 154)" />
              <TwitterIcon color="rgb(255, 62, 154)" />
            </div>
            {/* <div className='card_trending_creators_user_info_bottom_inner'>
                            <div className='card_trending_creators_user_info_bottom_inner_user_avatars'>
                                <img src="https://s3.eu-west-2.wasabisys.com/audio-1/ced3a70298620020e8f45f81b485f18725e155f9cb1d290502aee3fe77ab4055" width={24} height={24}/>
                                <img src="https://s3.eu-west-2.wasabisys.com/audio-1/ced3a70298620020e8f45f81b485f18725e155f9cb1d290502aee3fe77ab4055" width={24} height={24}/>
                                <img src="https://s3.eu-west-2.wasabisys.com/audio-1/ced3a70298620020e8f45f81b485f18725e155f9cb1d290502aee3fe77ab4055" width={24} height={24}/>
                            </div>
                            <div className='font14 '>
                                7 friend give thumbs
                            </div>
                        </div>   */}
          </div>
        </div>
      </div>
      <div
        className="card_trending_creators_chat_btn clickable"
        onClick={() => onClickChat(data.data.ai_id)}
      >
        <EnvelopeIcon />
        <div className="font-bold font18 ">Chat</div>
      </div>
    </div>
  );
};
