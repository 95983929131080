import React from 'react';

function FirstIcon(props) {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.45994 0.79C5.36698 0.696272 5.25637 0.621877 5.13452 0.571109C5.01266 0.52034 4.88195 0.494202 4.74994 0.494202C4.61793 0.494202 4.48722 0.52034 4.36536 0.571109C4.2435 0.621877 4.1329 0.696272 4.03994 0.79L1.03994 3.79C0.946211 3.88296 0.871816 3.99356 0.821048 4.11542C0.770279 4.23728 0.744141 4.36799 0.744141 4.5C0.744141 4.63201 0.770279 4.76272 0.821048 4.88458C0.871816 5.00644 0.946211 5.11704 1.03994 5.21L4.03994 8.21C4.1329 8.30373 4.2435 8.37812 4.36536 8.42889C4.48722 8.47966 4.61793 8.5058 4.74994 8.5058C4.88195 8.5058 5.01266 8.47966 5.13452 8.42889C5.25637 8.37812 5.36698 8.30373 5.45994 8.21C5.55367 8.11704 5.62806 8.00644 5.67883 7.88458C5.7296 7.76272 5.75574 7.63201 5.75574 7.5C5.75574 7.36799 5.7296 7.23728 5.67883 7.11542C5.62806 6.99356 5.55367 6.88296 5.45994 6.79L3.15994 4.5L5.45994 2.21C5.55367 2.11704 5.62806 2.00644 5.67883 1.88458C5.7296 1.76272 5.75574 1.63201 5.75574 1.5C5.75574 1.36799 5.7296 1.23728 5.67883 1.11542C5.62806 0.993564 5.55367 0.882963 5.45994 0.79ZM8.65994 4.5L10.9999 2.21C11.1882 2.0217 11.294 1.7663 11.294 1.5C11.294 1.2337 11.1882 0.978304 10.9999 0.79C10.8116 0.601696 10.5562 0.495908 10.2899 0.495908C10.0236 0.495908 9.76824 0.601696 9.57994 0.79L6.57994 3.79C6.48621 3.88296 6.41182 3.99356 6.36105 4.11542C6.31028 4.23728 6.28414 4.36799 6.28414 4.5C6.28414 4.63201 6.31028 4.76272 6.36105 4.88458C6.41182 5.00644 6.48621 5.11704 6.57994 5.21L9.57994 8.21C9.6729 8.30373 9.7835 8.37812 9.90536 8.42889C10.0272 8.47966 10.1579 8.5058 10.2899 8.5058C10.422 8.5058 10.5527 8.47966 10.6745 8.42889C10.7964 8.37812 10.907 8.30373 10.9999 8.21C11.0937 8.11704 11.1681 8.00644 11.2188 7.88458C11.2696 7.76272 11.2957 7.63201 11.2957 7.5C11.2957 7.36799 11.2696 7.23728 11.2188 7.11542C11.1681 6.99356 11.0937 6.88296 10.9999 6.79L8.65994 4.5Z"
        fill={props.isDisabled ? 'rgba(0, 0, 0, 0.4)' : '#FF3E9A'}
      />
    </svg>
  );
}

export default FirstIcon;
