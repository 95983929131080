import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactCardFlip from 'react-card-flip';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';
import { CardFlipQuestionBody } from './QuestionBody';
import { CardFlipAnswerBody } from './AnswerBody';

interface CardFlashProps {
  data: any;
  selectedOrg: any;
}

export const CardAlllFlash = ({ data, selectedOrg }: CardFlashProps) => {
  const navigate = useNavigate();
  const [isFlip, setIsFlip] = useState(false);

  return (
    <div className="all_main_card_flash">
      <ReactCardFlip isFlipped={isFlip} flipDirection="horizontal">
        <CardFlipQuestionBody
          data={data}
          selectedOrg={selectedOrg}
          onFlip={() => {
            setIsFlip(true);
          }}
        />
        <CardFlipAnswerBody
          data={data}
          onFlip={() => {
            setIsFlip(false);
          }}
        />
      </ReactCardFlip>
    </div>
  );
};
