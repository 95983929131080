import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import CloseIcon from 'assets/svgs/CloseIcon';
import LightBulbIcon from 'assets/svgs/LightBulbIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { convertFirstToUpper, getImageFromHtml } from 'shared/utils/utils';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { apiGetUserSharingQR } from 'shared/api/user';

import './index.styles.scss';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import { apiQAGetShareLink } from 'shared/api/qa';
import { StatementData } from 'components/Main/Organisation/OrgHome/components/TopDidYouKnow/CardDidYouKnow';
import LinkIcon from 'assets/svgs/LinkIcon';

interface LinkShareModalProps {
  show: boolean;
  linkData: any;
  onClose: () => void;
}

export const LinkShareModal = ({
  show,
  linkData,
  onClose
}: LinkShareModalProps) => {
  const { uploadBase64 } = useIPFS();

  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [isLoadingQrcode, setIsLoadingQRcode] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (show) {
      getSharingQRcode();
    } else {
      setShareUrl('');
    }
  }, [linkData, show]);

  const initShareUrl = async () => {
    if (linkData) {
      const base64Image = await getImageFromHtml(
        `link_share_body_${linkData.id}`
      );
      const url = await uploadBase64(base64Image, 'image/png', '');

      const res = await apiQAGetShareLink({
        url: url,
        item_id: linkData!.id,
        type: 'statement'
      });

      if (res.success) {
        setShareUrl(res.sharing_url);
      }
    }
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        let base64Image = await getImageFromHtml(
          `link_share_body_${linkData?.id}`
        );

        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const getSharingQRcode = async () => {
    setIsLoadingQRcode(true);
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
      setIsLoadingQRcode(false);

      setTimeout(() => {
        initShareUrl();
      }, 2000);
    }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const renderTitle = () => {
    if (linkData.site_title !== '' && linkData.site_name !== '') {
      return `${linkData.site_title} - ${linkData.site_name}`;
    } else if (linkData.site_title === '' && linkData.site_name !== '') {
      return `${linkData.site_name}`;
    } else if (linkData.site_title !== '' && linkData.site_name === '') {
      return `${linkData.site_title}`;
    } else {
      return `${linkData.filename}`;
    }
  };

  const renderScanBody = () => {
    return (
      <div className="link_card_bottom">
        <div className="link_top row_align_items">
          <div className="link_icon">
            <LinkIcon color="#FF3E9A" opacity={1} />
          </div>
          <div style={{ marginLeft: 8, width: '100%' }}>
            <div className="font-medium font12">{renderTitle()}</div>
            <div className="font-regular font10">{`https://${linkData.domain}`}</div>
          </div>
        </div>
        <div className="link_card_bottom_btns clickable">
          {isLoadingQrcode ? (
            <LoadingCircular color="main" size={32} />
          ) : (
            <>
              <div className="link_card_bottom_btns_QR">
                <img
                  src={qrCodeUrl}
                  style={{ width: '60px', height: '60px' }}
                  crossOrigin="anonymous"
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderShareBody = () => {
    return (
      <div className="link_link_bar">
        <div className="link_link_bar_items">
          <div
            className="link_link_bar_items_item download_itm clickable"
            onClick={() => onShare('download')}
          >
            {isDownloading ? (
              <LoadingCircular size={20} color={'white'} />
            ) : (
              <Download1Icon />
            )}
          </div>
          <div className="font10">Download</div>
        </div>

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="link_link_bar_items">
              <div className="link_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="link_link_bar_items">
              <div className="link_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <FacebookShareButton title="" url={shareUrl}>
            <div className="link_link_bar_items">
              <div
                className="link_link_bar_items_item Telegram_itm "
                style={{ padding: 0 }}
              >
                <FacebookIcon borderRadius={16} size={48} />
              </div>
              <div className="font10">Facebook</div>
            </div>
          </FacebookShareButton>
        )}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div>
        <div className="link_card" id={`link_share_body_${linkData?.id}`}>
          {renderScanBody()}
          {linkData.image?.startsWith('https') && renderImage()}
        </div>
        {renderShareBody()}
      </div>
    );
  };

  const renderImage = () => {
    return <img src={linkData.image} className="image_view" />;
  };

  return (
    <PrimaryModal show={show} width={510} onClose={onClose}>
      <div
        className="modal_share_link"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal_share_link_header">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon opacity={0.4} />
          </div>
          <div className="title font-bold font20">Share Link</div>
        </div>
        <div className="modal_share_link_body">{renderBody()}</div>
      </div>
    </PrimaryModal>
  );
};
