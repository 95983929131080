import React, { useState, useEffect } from 'react';
import { apiGetQASimilarQuestion } from 'shared/api/qa';
import InfiniteScrollWrapper from 'components/Common/WrapperScroll';
import { QuestionData } from '../../OrgHome/components/TrendingQuestion/CardTrendingQuestion';
import { useNavigate } from 'react-router-dom';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PostQuestionModal } from 'components/Modals/PostQuestionModal';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { CardSimilarQuestion } from './CardSimilarQuestion';

interface SimilarQuestionProps {
  textQuestion: string;
  arrOrganisation: any;
  selectedOrg: any;
  isAskingQuestion: boolean;
}

export const SimilarQuestion = ({
  textQuestion,
  selectedOrg,
  arrOrganisation,
  isAskingQuestion
}: SimilarQuestionProps) => {
  const [notFound, setNotFound] = useState(false);

  const [similarQuestions, setSimilarQuestions] = useState<Array<any>>([]);
  const [hasMoreQuestions, setHasMoreQuestions] = useState(true);
  const [lastIdQuestions, setLastIdQuestions] = useState(0);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  const [selectedQuestionData, setSelectedQuestionData] = useState<
    QuestionData | undefined
  >();
  const [isShareModalShow, setIsShareModalShow] = useState(false);
  const [shareModalType, setShareModalType] = useState<'question' | 'answer'>(
    'question'
  );

  const [isPostingQuestion, setIsPostingQuestion] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (textQuestion) {
      if (selectedOrg === null)
        if (arrOrganisation === null) return;
        else if (arrOrganisation.length == 0) return;
        else loadSimilarQuestions();
      else loadSimilarQuestions();
    }
  }, [textQuestion, selectedOrg, arrOrganisation]);

  const loadSimilarQuestions = async (init = true) => {
    if (init) {
      setIsLoadingQuestions(true);
      const res = await apiGetQASimilarQuestion({
        question: textQuestion,
        item_id: selectedOrg ? selectedOrg.id : arrOrganisation[0].id,
        item_type: 'organisation',
        limit: 10,
        offset: 0
      });
      if (res.success) {
        if (res.data.length > 0) {
          setSimilarQuestions(res.data);
          setHasMoreQuestions(res.hasMore);
          setLastIdQuestions(res.lastId);
          setNotFound(false);
        } else setNotFound(true);
      }
      setIsLoadingQuestions(false);
    }
    //
    else {
      if (!isLoadingQuestions && hasMoreQuestions) {
        const res = await apiGetQASimilarQuestion({
          question: textQuestion,
          item_id: selectedOrg ? selectedOrg.id : arrOrganisation[0].id,
          item_type: 'organisation',
          limit: 10,
          offset: lastIdQuestions
        });

        if (res.success) {
          setSimilarQuestions((prev) => [...prev, ...res.data]);
          setHasMoreQuestions(res.hasMore);
          setLastIdQuestions(res.lastId);
        }
      }
    }
  };

  const onShareHandler = (data, modalType) => {
    setSelectedQuestionData(data);
    setIsShareModalShow(true);
    setShareModalType(modalType);
  };

  const onNext = () => {
    loadSimilarQuestions(false);
  };

  const onPostQuestion = async () => {
    setIsShareModalShow(true);
  };
  const renderShareQuestionToSocial = () => {
    return (
      <PostQuestionModal
        show={isShareModalShow}
        onClose={() => {
          setIsShareModalShow(false);
        }}
        question={textQuestion}
        selectedOrgId={selectedOrg ? selectedOrg.id : arrOrganisation[0].id}
      />
    );
  };

  return (
    <div>
      <div
        className="font-bold font24"
        style={{ marginTop: 92, marginLeft: 16 }}
      >
        Similar Questions
      </div>
      <div style={{ marginBottom: 32 }}></div>
      <div className="similar_question_list" id="similar_question_list">
        {isLoadingQuestions ? (
          <div className="post_question_box" style={{ height: 200 }}>
            <LoadingBarAnimation />
          </div>
        ) : (
          <InfiniteScrollWrapper
            isInfiniteScrollOn={true}
            lengthData={similarQuestions.length}
            functionNext={onNext}
            hasOrgsMore={hasMoreQuestions}
            parentId="similar_question_list"
          >
            {!notFound ? (
              similarQuestions?.map((item, index) => {
                return (
                  <CardSimilarQuestion
                    key={`card_similar_question_${index}`}
                    data={item}
                    type="top"
                    onShareMedia={(data, modalType) => {
                      onShareHandler(data, modalType);
                    }}
                  />
                );
              })
            ) : (
              <div className="similar_question_box">
                <div className="question_box_not_found">
                  <div className="speech_balloon">💬</div>
                  <div className="font-bold font18 not_found_title">
                    No similar question found
                  </div>
                  <div className="not_found_description font14">
                    Post your question or use answer from AI to create a
                    discussion
                  </div>
                </div>
              </div>
            )}
          </InfiniteScrollWrapper>
        )}
      </div>
      {!isAskingQuestion && (
        <div className="post_question_box">
          <div className="post_question_text">
            <div className="post_question_text_title font-bold font20">
              Couldn’t Find Perfect Answer for your Question?
            </div>
            <div className="post_question_text_description font14">
              Don’t worry, you can always post your own question to the
              community.
            </div>
          </div>
          <div className="post_question_btn clickable" onClick={onPostQuestion}>
            <div className="post_question_btn_text">
              {isPostingQuestion ? (
                <LoadingCircular color="white" size={14} />
              ) : (
                'Post Question'
              )}
            </div>
          </div>
          {renderShareQuestionToSocial()}
        </div>
      )}
    </div>
  );
};
