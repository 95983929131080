import React from 'react';

function RoundOutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <rect width="40" height="40" fill="#fff" rx="20"></rect>
      <path
        fill="#FF3E9A"
        d="M25.92 14.62a1 1 0 00-.54-.54A1.001 1.001 0 0025 14H15a1 1 0 000 2h7.59l-8.3 8.29a1.002 1.002 0 00.325 1.639 1 1 0 001.095-.219l8.29-8.3V25a1 1 0 002 0V15a1.001 1.001 0 00-.08-.38z"
      ></path>
    </svg>
  );
}

export default RoundOutIcon;
