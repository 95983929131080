import React, { useEffect, useState } from 'react';
import usePreventBodyScroll from 'shared/utils/usePreventBodyScroll';
import { apiGetQATagList, apiQACreateTag } from 'shared/api/qa';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useDebounce } from 'use-debounce';
import { LeftArrow, RightArrow } from './ArrowBtns';
import Tooltip from '@mui/material/Tooltip';

import './index.styles.scss';
import { LinkPreiveTag } from './Tag';
import PlusIcon from 'assets/svgs/PlusIcon';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import TagIcon from 'assets/svgs/TagIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface LinkPreviewTagFilterProps {
  itemId: any;
  itemType: any;
  onChangedSelection: (tags) => void;
}

export const LinkPreviewTagFilter = ({
  itemId,
  itemType,
  onChangedSelection
}: LinkPreviewTagFilterProps) => {
  const [arrTag, setArrTag] = useState<Array<any>>([]);
  const [lastIdTag, setLastIdTag] = useState(0);
  const [hasMoreTag, setHasMoreTag] = useState(true);
  const [isLoadingTag, setIsLoadingTag] = useState(false);

  const [selectedTags, setSelectedTags] = useState<Array<any>>([]);
  // new tag
  const [showCreateNewTag, setShowCreateNewTag] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [isCreatingNewtag, setIsCreatingNewTag] = useState(false);

  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const [debouncedKeyword] = useDebounce(selectedTags, 500);

  useEffect(() => {
    loadTagList();
  }, []);

  useEffect(() => {
    onChangedSelection(debouncedKeyword);
  }, [debouncedKeyword]);

  const loadTagList = async (init = true) => {
    if (init) {
      setIsLoadingTag(true);

      const res = await apiGetQATagList({
        item_id: itemType === 'user' ? undefined : itemId,
        item_type: itemType === 'user' ? undefined : itemType,
        tagType: null,
        limit: 20,
        offset: 0
      });

      if (res.success) {
        setArrTag(res.data);
        setLastIdTag(res.lastId);
        setHasMoreTag(res.hasMore);
      }
      setIsLoadingTag(false);
    } else {
      if (!isLoadingTag && hasMoreTag) {
        setIsLoadingTag(true);

        const res = await apiGetQATagList({
          item_id: itemType === 'user' ? undefined : itemId,
          item_type: itemType === 'user' ? undefined : itemType,
          tagType: null,
          limit: 20,
          offset: lastIdTag
        });
        if (res.success) {
          setArrTag((prev) => [...prev, ...res.data]);
          setLastIdTag(res.lastId);
          setHasMoreTag(res.hasMore);
        }
        setIsLoadingTag(false);
      }
    }
  };

  const onCreateNewTag = async () => {
    setIsCreatingNewTag(true);
    const res = await apiQACreateTag({
      name: newTagName,
      to: itemType === 'group' ? itemId : undefined,
      to_type: itemType === 'group' ? 'group' : undefined
    });

    if (res.success) {
      setShowCreateNewTag(false);
      setNewTagName('');
      loadTagList();

      showToast('success', 'Success');
    }
    setIsCreatingNewTag(false);
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const onLoadMoreInterest = (vc) => {
    if (vc.isLastItemVisible) {
      loadTagList(false);
    }
  };

  const onClickTag = (tag) => {
    if (
      selectedTags.filter(
        (sItem: any, sIndex) =>
          sItem.item_id === tag.item_id && sItem.item_type === tag.item_type
      ).length > 0
    ) {
      const newFilters = selectedTags.filter(
        (pItem: any, pIndex) =>
          !(pItem.item_id === tag.item_id && pItem.item_type === tag.item_type)
      );
      setSelectedTags(newFilters);
    } else {
      setSelectedTags((prev) => [...prev, tag]);
    }
  };

  const renderFilters = () => {
    return (
      <div className="row_align_items" style={{ width: '100%' }}>
        {renderNewTag()}
        {showCreateNewTag && renderInputNewTag()}
        {!showCreateNewTag && (
          <div className="link_preview_tag_filter_tag">
            <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
              <ScrollMenu
                LeftArrow={LeftArrow}
                RightArrow={RightArrow}
                onWheel={onWheel}
                onUpdate={onLoadMoreInterest}
              >
                {arrTag.map((item, index) => {
                  const isSelected =
                    selectedTags.filter(
                      (sItem, index) =>
                        sItem.item_id === item.item_id &&
                        sItem.item_type === item.item_type
                    ).length > 0;

                  return (
                    <LinkPreiveTag
                      isSelected={isSelected}
                      isEditMode={true}
                      data={item}
                      onClicked={() => {
                        onClickTag(item);
                      }}
                      key={`filter_tag_list_${index}`}
                    />
                  );
                })}
              </ScrollMenu>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderNewTag = () => {
    return (
      <Tooltip
        title={
          <div className="font-regular font14">
            {!showCreateNewTag &&
              `Create a tag to sort your knowledge base by topics, group or anything you want to filter by`}
          </div>
        }
      >
        <div
          className="row_align_items clickable"
          style={{
            minWidth: 120,
            height: 36,
            borderRadius: 18,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => setShowCreateNewTag((prev) => !prev)}
        >
          <PlusIcon size={16} />
          <span className="font-medium font14" style={{ marginLeft: 6 }}>
            {showCreateNewTag ? `Done` : `New Tag`}
          </span>
        </div>
      </Tooltip>
    );
  };

  const renderInputNewTag = () => {
    return (
      <div className="add_new_tag_btn">
        <TagIcon />
        <input
          value={newTagName}
          className="tag_name_input"
          placeholder="Write your tag here"
          onChange={(e) => {
            setNewTagName(e.target.value);
          }}
        />
        <div className="create_tag_btn clickable" onClick={onCreateNewTag}>
          {isCreatingNewtag ? (
            <LoadingCircular color="white" size={12} />
          ) : (
            'Add'
          )}
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return <div></div>;
  };

  return (
    <div className="link_preview_tag_filter">
      {renderFilters()}
      {renderSearch()}
    </div>
  );
};
