import React from 'react';

function UserCheckIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 16}
      height={props.size ?? 16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={props.color ?? '#FF3E9A'}
        d="M8.867 8.147A3.28 3.28 0 0010 5.667a3.333 3.333 0 00-6.667 0 3.28 3.28 0 001.134 2.48A5.334 5.334 0 001.334 13a.667.667 0 101.333 0 4 4 0 118 0A.667.667 0 0012 13a5.333 5.333 0 00-3.133-4.853zm-2.2-.48a2 2 0 110-4 2 2 0 010 4zm7.806-1.58a.666.666 0 00-.946 0L12.194 7.42 11.78 7a.667.667 0 10-.946.94l.893.893a.667.667 0 00.94 0l1.78-1.78a.667.667 0 00.027-.966z"
      ></path>
    </svg>
  );
}

export default UserCheckIcon;
