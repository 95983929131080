import React from 'react';

function AngleDoubleUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#FF3E9A"
        d="M11.79 12.54a1.002 1.002 0 011.42 0l3 3a1.017 1.017 0 01-.696 1.748A1.017 1.017 0 0114.79 17l-2.29-2.34L10.21 17a1.018 1.018 0 11-1.42-1.46l3-3zm3-1.08l-2.29-2.3-2.29 2.3A1.018 1.018 0 118.79 10l3-3a1 1 0 011.42 0l3 3a1.004 1.004 0 11-1.42 1.42v.04z"
      ></path>
    </svg>
  );
}

export default AngleDoubleUpIcon;
