import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import Tooltip from '@mui/material/Tooltip';

import './index.styles.scss';
import { getArrFromString, getNormalString } from 'shared/utils/utils';
import { Tag } from 'shared/ui-kit/Tag';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import TagIcon from 'assets/svgs/TagIcon';
import LinkBotMarkIcon from 'assets/svgs/LinkBotMarkIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import ChatSendIcon from 'assets/svgs/ChatSendIcon';
import { SendDumpModal } from 'components/Modals/SendDumpModal';
import RobotIcon from 'assets/svgs/RobotIcon';
import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import { FilePrivacyModal } from 'components/Modals/FilePrivacyModal';
import {
  apiExtensionChangePrivacy,
  apiGetExtensionSummary,
  apiStopExtensionSummary
} from 'shared/api/extension';
import { LinkShareModal } from 'components/Modals/ShareLinkModal';
import ShareIcon from 'assets/svgs/ShareIcon';
import { useNavigate } from 'react-router-dom';
import TrashIcon from 'assets/svgs/TrashIcon';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiDeletionLink } from 'shared/api/delete';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { LoadingDialog } from 'shared/ui-kit/LoadingDialog';
import { showToast } from 'shared/ui-kit/PrimaryToast';

export interface PreviewData {
  id: number;
  user: number;
  filename: string;
  is_private: number;
  tag_ids: string;
  tag_types: string;
  tag_names: string;
  tag_images: string;
  topics?: string;
  topic_emojis?: string;
  site_name: string | undefined;
  site_title: string | undefined;
  image: string | undefined;
  summary_status:
    | 'uploading'
    | 'processing'
    | 'pending'
    | 'completed'
    | 'failed';
  domain?: string;
  privacy_type: 'private' | 'public' | 'anonymous';
  insights_enabled: boolean;
}
interface CardPreviewProps {
  data: PreviewData;
  item_type: 'user' | 'group';
  onAddTag: (data: any, positionX: number | undefined) => void;
  onRemoveOnUI: (id) => void;
}
export interface APIResponse {
  title: string | null;
  description: string | null;
  image: string | null;
  siteName: string | null;
  hostname: string | null;
}

export const CardPreview = ({
  data,
  item_type,
  onAddTag,
  onRemoveOnUI
}: CardPreviewProps) => {
  const navigate = useNavigate();
  const positionRef = useRef<HTMLDivElement>(null);
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [isRemoving, setIsRemoving] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [isSummarising, setIsSummarising] = useState(false);
  const [enabledInsight, setEnabledInsight] = useState(data.insights_enabled);

  const [privacyStatus, setPrivacyStatus] = useState<
    'private' | 'public' | 'anonymous'
  >(data.privacy_type);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [privacyModalPos, setPrivacyModalPos] = useState({ top: 0, left: 0 });

  useEffect(() => {
    var element = document.getElementById(`card_preview_title_${data.id}`);

    if (isElementOverflowing(element)) {
      wrapContentsInMarquee(element);
    }
  }, []);

  function isElementOverflowing(element) {
    var overflowX = element.offsetWidth < element.scrollWidth,
      overflowY = element.offsetHeight < element.scrollHeight;

    return overflowX || overflowY;
  }

  function wrapContentsInMarquee(element) {
    var marquee = document.createElement('marquee'),
      contents = element.innerText;

    marquee.innerText = contents;
    element.innerHTML = '';
    element.appendChild(marquee);
  }

  const onSummarise = async (e) => {
    e.stopPropagation();

    if (!isSummarising) {
      if (!enabledInsight) {
        setIsSummarising(true);
        const res = await apiGetExtensionSummary({ browser: data.id });

        if (res.success) {
          setEnabledInsight(true);
        }
        setIsSummarising(false);
      } else {
        setIsSummarising(true);
        const res = await apiStopExtensionSummary({ browser: data.id });

        if (res.success) {
          setEnabledInsight(false);
        }
        setIsSummarising(false);
      }
    }
  };

  const onRemove = async (e) => {
    e.stopPropagation();
    if (!isRemoving && onRemoveOnUI) {
      setIsRemoving(true);

      const res = await apiDeletionLink({
        link: data.id
      });

      setIsRemoving(false);
      if (res.success) {
        showToast('success', 'Deletion successful!');
        onRemoveOnUI && onRemoveOnUI(data.id);
      }
    }
  };

  const onDetail = () => {
    window.open(`https://${data.domain}`, '_blank');
  };

  const onClickTag = (e) => {
    e.stopPropagation();
    onAddTag(data, positionRef.current?.offsetLeft);
  };

  const onWheelTopic = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const onWheelTag = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const arrTopic = useMemo(() => {
    return getArrFromString(data.topics ?? '', ',');
  }, [data]);

  const arrEmoji = useMemo(() => {
    return getArrFromString(data.topic_emojis ?? '', ',');
  }, [data]);

  const arrTags = useMemo(() => {
    if (data.tag_types) {
      const arrTags: Array<any> = [];
      const arrTagIds = getArrFromString(data.tag_ids, ',');
      const arrTagNames = getArrFromString(data.tag_names, ',');
      const arrTagTypes = getArrFromString(data.tag_types, ',');
      const arrTagImages = getArrFromString(data.tag_images, ',');

      arrTagTypes.forEach((tagType, index) => {
        arrTags.push({
          id: arrTagIds[index],
          item_type: arrTagTypes[index],
          name: arrTagNames[index],
          image: arrTagImages[index]
        });
      });

      return arrTags;
    } else {
      return [];
    }
  }, [data]);

  const onSend = (e) => {
    e.stopPropagation();

    setShowSendModal(true);
  };

  const onShare = (e) => {
    e.stopPropagation();

    setShowShareModal(true);
  };

  const onClickPrivacy = (e) => {
    e.stopPropagation();

    const clientRectangle = document!
      .getElementById(`file_privacy_btn_${data.id}`)!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setPrivacyModalPos({
      top:
        bottomFromWindow < 200
          ? clientRectangle.top - 200
          : clientRectangle.top + 50,
      left: clientRectangle.left
    });

    setShowPrivacyModal(true);
  };

  const onChangedPrivacy = async (
    status: 'private' | 'public' | 'anonymous'
  ) => {
    const res = await apiExtensionChangePrivacy({
      browser: data.id,
      privacy: status
    });

    if (res.success) {
      setPrivacyStatus(status);
    }
  };

  const onClickedTag = (tagInfo) => {
    if (tagInfo.item_type === 'group') {
      // navigate(`/`)
    } else if (tagInfo.item_type === 'organisation') {
      navigate(`/school/${tagInfo.id}`);
    }
  };

  const renderImage = () => {
    return (
      <div className="image_view">
        <img src={data.image} />
      </div>
    );
  };

  const renderSetting = () => {
    return (
      <div className="setting_view">
        <div style={{ width: 'calc(100%)' }}>
          <ScrollMenu onWheel={onWheelTopic}>
            {arrTopic.map((item, index) => {
              return (
                <div
                  className="setting_view_item"
                  key={`setting_view_item_${index}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <span
                    className="font-regular font12"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {arrEmoji[index]}
                  </span>
                  <span
                    className="font-regular font12"
                    style={{ marginLeft: 4, whiteSpace: 'nowrap' }}
                  >
                    {item}
                  </span>
                </div>
              );
            })}
          </ScrollMenu>
        </div>
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="prev_tag_view row_align_items">
        {renderPrivacy()}
        {arrTags.length > 0 ? (
          <div className="edit_tag_btn clickable" onClick={onClickTag}>
            <EditPencilColoredIcon size={16} />
          </div>
        ) : (
          <Tooltip
            title={
              <div className="font-regular font14">
                Add a tag to sort your knowledge base and improve search
              </div>
            }
          >
            <div
              ref={positionRef}
              className="add_tab_btn clickable font-regular font12"
              onClick={onClickTag}
            >
              <TagIcon color="#FF3E9A" /> Add Tag
            </div>
          </Tooltip>
        )}

        <ScrollMenu onWheel={onWheelTag}>
          {arrTags.map((tag, index) => {
            return (
              <Tag
                key={`link_preview_tag_${index}`}
                type="else"
                isSelected={false}
                isEditMode={false}
                data={tag}
                onClicked={(data) => {
                  onClickedTag(data);
                }}
              />
            );
          })}
        </ScrollMenu>
      </div>
    );
  };

  const renderPrivacy = () => {
    return (
      <div
        className="privacy_btn row_align_items clickable"
        id={`file_privacy_btn_${data.id}`}
        onClick={onClickPrivacy}
      >
        <div className="font16">
          {privacyStatus === 'private'
            ? '🔒'
            : privacyStatus === 'public'
            ? '🤗'
            : '🎭'}
        </div>
        <div className="font-regular font12">
          {privacyStatus === 'private'
            ? 'Private'
            : privacyStatus === 'public'
            ? 'Public'
            : 'Anonymous'}
        </div>
        <div>
          <AngleDownIcon color="#FF3E9A" />
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    if (data.site_title !== '' && data.site_name !== '') {
      return `${data.site_title} - ${data.site_name}`;
    } else if (data.site_title === '' && data.site_name !== '') {
      return `${data.site_name}`;
    } else if (data.site_title !== '' && data.site_name === '') {
      return `${data.site_title}`;
    } else {
      return `${data.filename}`;
    }
  };

  const renderMark = () => {
    return (
      <Tooltip
        title={
          <div className="font-regular font14">
            {enabledInsight
              ? `AI has generated summary and insights for you successfully.`
              : `Analyse this to generate a summary and Q&As or ask questions about the content`}
          </div>
        }
      >
        <div className="bot_mark" onClick={onSummarise}>
          <LinkBotMarkIcon color={enabledInsight ? '#FF3E9A' : '#F3F4F5'} />
          <div className={`robot_icon`}>
            <RobotIcon
              color={enabledInsight ? 'white' : 'rgba(0, 0, 0, 0.2)'}
              size={24}
            />
          </div>
        </div>
      </Tooltip>
    );
  };

  const renderShareView = () => {
    return (
      <div className="share_view row_align_items">
        <div className="link_view">
          <LinkIcon color="rgba(0, 0, 0, 0.3)" />
        </div>
        <div className="link_url font-regular font14">{`https://${data.domain}`}</div>
        <Tooltip
          title={
            <div className="font-regular font14">
              Send and share this with a friend, group or school
            </div>
          }
        >
          <div className="link_share_btn clickable" onClick={onSend}>
            <ChatSendIcon />
          </div>
        </Tooltip>
        <div
          className="link_share_btn clickable"
          style={{ marginLeft: 8 }}
          onClick={onShare}
        >
          <ShareIcon color="white" />
        </div>
        <div
          className="link_share_btn clickable"
          style={{ marginLeft: 8 }}
          onClick={onRemove}
        >
          {isRemoving ? (
            <LoadingCircular size={16} color="white" />
          ) : (
            <TrashIcon color={'white'} />
          )}
        </div>
      </div>
    );
  };

  const renderSendModal = () => {
    return (
      <SendDumpModal
        bookmarkId={data.id}
        item_id={data.id}
        item_type={item_type}
        show={showSendModal}
        type="link"
        onClose={() => setShowSendModal(false)}
        onSuccess={(groupData) => {}}
      />
    );
  };

  const renderPrivacyModal = () => {
    return (
      <FilePrivacyModal
        show={showPrivacyModal}
        posX={privacyModalPos.left}
        posY={privacyModalPos.top}
        onClose={() => {
          setShowPrivacyModal(false);
        }}
        onSelected={(action) => {
          onChangedPrivacy(action);
        }}
      />
    );
  };

  const renderShareModal = () => {
    return (
      <LinkShareModal
        show={showShareModal}
        linkData={data}
        onClose={() => setShowShareModal(false)}
      />
    );
  };

  const renderRemovingModal = () => {
    return (
      <LoadingDialog
        show={isRemoving}
        title={'Deleting data'}
        description="This may take a moment..."
        onClose={() => setIsRemoving(false)}
      />
    );
  };

  return (
    <div
      className="card_preview clickable"
      style={
        enabledInsight
          ? {
              border: `2px solid #FF3E9A`
            }
          : {}
      }
      onClick={onDetail}
    >
      <div
        className="title font-bold font24"
        id={`card_preview_title_${data.id}`}
      >
        {renderTitle()}
      </div>
      {renderShareView()}
      {data.image?.startsWith('https') && renderImage()}
      {renderSetting()}
      <div>{renderTags()}</div>
      {renderMark()}
      {renderSendModal()}
      {renderPrivacyModal()}
      {renderShareModal()}
      {renderRemovingModal()}
    </div>
  );
};
