import ArrowLeftIcon from 'assets/svgs/ArrowLeftIcon';
import React from 'react';
import { EmailInputBar } from './EmailInputBar';
interface CreateNewOrgProps {
  setTab: any;
}

export const CreateNewOrg = ({ setTab }: CreateNewOrgProps) => {
  return (
    <>
      <div className="create_new_org_header">
        <div
          className="back_to_main_btn font-medium font14 clickable"
          onClick={() => setTab('main')}
        >
          <ArrowLeftIcon />
          Back to Schools
        </div>
      </div>
      <div className="create_new_org_body">
        <div className="input_panel">
          <div className="font-bold font48 building_mark">🏛</div>
          <div className="header_bar">
            <div className="header_title font-bold font24">
              Create Your Schools
            </div>
            <div className="header_description font16">
              Add 5 email with the same org domain to start an organization
            </div>
          </div>
          <div className="email_input_list">
            {Array(5)
              .fill(0)
              .map((item, index) => {
                return (
                  <EmailInputBar
                    key={`input_bar_index${index}`}
                    order={index}
                  />
                );
              })}
          </div>
        </div>

        <div className="bottom_btn text-white font-16 font-bold clickable">
          Create School
        </div>
      </div>
    </>
  );
};
