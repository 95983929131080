import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { LeftArrow, RightArrow } from './ArrowBtns';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import { CardFlash } from './CardFlash';
import './index.styles.scss';
import BoltIcon from 'assets/svgs/BoltIcon';
import { apiGetContentFlashCards } from 'shared/api/content';

interface SharedFlashProps {
  selectedOrg: any;
  onShowAll: () => void;
}

export const SharedFlash = ({ selectedOrg, onShowAll }: SharedFlashProps) => {
  const [arrFiles, setArrFiles] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (selectedOrg) {
      loadData();
    }
  }, [selectedOrg]);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiGetContentFlashCards({
        query: '',
        to: selectedOrg ? selectedOrg.id : undefined,
        to_type: 'organisation',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrFiles(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }
    } else {
      if (!isLoadingFile && hasMore) {
        const res = await apiGetContentFlashCards({
          query: '',
          to: selectedOrg ? selectedOrg.id : undefined,
          to_type: 'organisation',
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrFiles((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const renderIntro = () => {
    return (
      <div className="intro_view" key="shared_doc_intro">
        <div className="mark_view">
          <BoltIcon color="white" opacity={1} />
        </div>
        <div
          className="font-bold font24"
          style={{ marginTop: 8 }}
        >{`Challenge Your\nKnowledge with\nthese Flashcard`}</div>
        <div
          className="font-regular font14"
          style={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: 8 }}
        >
          {`Check the question, flip the card and find the answer!`}
        </div>
        <div
          className="font-bold font14 clickable"
          style={{ color: '#FF3E9A', marginTop: 8 }}
          onClick={onShowAll}
        >
          Show All
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="row_align_items" style={{ width: '100%' }}>
        {renderIntro()}
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onWheel={onWheel}
        >
          {arrFiles.map((item, index) => {
            return (
              <CardFlash
                key={`card_flash_${index}`}
                data={item}
                selectedOrg={selectedOrg}
              />
            );
          })}
        </ScrollMenu>
      </div>
    );
  };

  if (selectedOrg && arrFiles.length > 0) {
    return (
      <div className="shared_flash_container">
        <div className="shared_link">{renderBody()}</div>
      </div>
    );
  } else {
    return <div />;
  }
};
