import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import DocumentLayoutIcon from 'assets/svgs/DocumentLayoutIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import { NewAddReferenceModal } from 'components/Modals/AddReferenceModal/NewAddReferenceModal';
import React, { useMemo, useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './index.styles.scss';
import MicIcon from 'assets/svgs/MicIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import FileIcon from 'assets/svgs/FileIcon';
import { useNavigate } from 'react-router-dom';

interface ReferenceData {
  id: any;
  name: string;
  image: string;
  type: 'user' | 'file';
  text: string;
  fileType: 'pdf' | 'url' | 'html' | 'text' | 'audio';
  url: any;
}
interface ReferenceProps {
  data: Array<ReferenceData>;
  itemId?: number;
  itemType?: 'user' | 'group' | 'organisation';
  showAddButton: boolean;
  showExpandButton?: boolean;
  enableVisit?: boolean;
  itemWidth?: any;
  setIsExpandReference: any;
  isExpandReference: boolean;
  onDoneAddReference?: (newUserRef, newFileRef) => void;
}

export const Reference = ({
  data,
  showAddButton,
  showExpandButton = true,
  enableVisit = false,
  itemId,
  itemType,
  itemWidth,
  setIsExpandReference,
  isExpandReference,
  onDoneAddReference
}: ReferenceProps) => {
  const navigate = useNavigate();

  const addBtnRef = useRef<HTMLDivElement>(null);
  const [showAddDlg, setShowAddDlg] = useState(false);

  const [addDlgPos, setAddDlgPos] = useState({ top: 0, left: 0 });

  const onShowAddDlg = (e) => {
    setShowAddDlg(true);

    const clientRectangle = document!
      .getElementById('add_btn_ref')!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setAddDlgPos({
      top:
        bottomFromWindow < 400
          ? clientRectangle.top - 400
          : clientRectangle.top,
      left: clientRectangle.left
    });
  };

  const onClickRef = (itemData) => {
    if (enableVisit) {
      if (itemData.fileType === 'pdf') {
        navigate(`/document/${itemData.id}`);
      } else if (
        itemData.fileType === 'url' ||
        itemData.fileType === 'html' ||
        itemData.fileType === 'browser'
      ) {
        window.open(itemData.image);
      } else if (itemData.fileType === 'text') {
        navigate(`/file/${itemData.id}`);
      } else if (itemData.fileType === 'audio') {
        navigate(`/audio/${itemData.id}`);
      }
    }
  };

  const onViewProfile = (userId) => {
    if (userId) {
      navigate(`/profile/${userId}`);
    }
  };

  const renderUserRef = (refData: ReferenceData, index) => {
    return (
      <div
        className="reference_item clickable"
        key={`reference_item_${index}`}
        onClick={() => onViewProfile(refData.id)}
      >
        {refData.image && refData.image.startsWith('https') ? (
          <img
            src={refData.image}
            style={{ width: 24, height: 24, borderRadius: 24 }}
          />
        ) : (
          <DefaultAvatarIcon size={24} />
        )}
      </div>
    );
  };

  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 28, height: 28 }}>
        {type === 'pdf' ? (
          <PdfFileIcon size={28} />
        ) : type === 'audio' ? (
          <MicIcon size={24} color="#FF3E9A" opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={28} color="#FF3E9A" opacity={1} />
        ) : (
          <FileIcon />
        )}
      </div>
    );
  };

  const renderFileRef = (refData: ReferenceData, index) => {
    return (
      <div
        className="reference_item clickable"
        key={`reference_item_${index}`}
        onClick={() => onClickRef(refData)}
        data-tooltip-id={`ref_file_${index}`}
        data-tooltip-content={refData.name}
      >
        <div style={{ width: 24, height: 24 }}>
          {renderFileIcon(refData.fileType)}
        </div>
        <div
          className="reference_name font-bold font14"
          style={itemWidth ? { maxWidth: itemWidth } : {}}
        >
          {refData.name}
        </div>
        <ReactTooltip id={`ref_file_${index}`} />
      </div>
    );
  };

  const renderAddDlg = () => {
    return (
      <NewAddReferenceModal
        show={showAddDlg}
        itemId={itemId}
        itemType={itemType}
        posX={addDlgPos.left}
        posY={addDlgPos.top}
        onClose={() => setShowAddDlg(false)}
        onDone={(userRef, fileRef) => {
          setShowAddDlg(false);
          onDoneAddReference && onDoneAddReference(userRef, fileRef);
        }}
      />
    );
  };

  return (
    <div className="reference_container">
      {data.length > 0 && showExpandButton && (
        <div
          className={'reference_action_btn clickable'}
          style={{
            borderColor: isExpandReference ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'
          }}
          onClick={() => setIsExpandReference((prev: boolean) => !prev)}
        >
          <div style={{ width: 24, height: 24 }}>
            <DocumentLayoutIcon
              size={24}
              color={isExpandReference ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
            />
          </div>
        </div>
      )}
      {data.map((item, index) => {
        if (item.type === 'user') return renderUserRef(item, index);
        else return renderFileRef(item, index);
      })}
      {showAddButton && (
        <div
          ref={addBtnRef}
          id="add_btn_ref"
          className="reference_item clickable"
          style={{ width: 170 }}
          onClick={onShowAddDlg}
          data-tooltip-id={`add_btn_ref`}
          data-tooltip-content={`Narrow down your results by filtering for specific creators or
        document uploads`}
        >
          <PlusIcon />
          <div
            className="font-bold font14"
            style={{ marginLeft: 8, width: 110 }}
          >
            Set references
          </div>
          <ReactTooltip id={`add_btn_ref`} />
        </div>
      )}
      {renderAddDlg()}
    </div>
  );
};
