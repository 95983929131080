import React from 'react';

function LockIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 12}
      height={props.size ?? 13}
      fill="none"
      viewBox="0 0 12 13"
    >
      <g opacity={props.opacity ?? 1}>
        <path
          d="M6 7C5.86739 7 5.74021 7.05268 5.64645 7.14645C5.55268 7.24021 5.5 7.36739 5.5 7.5V9C5.5 9.13261 5.55268 9.25979 5.64645 9.35355C5.74021 9.44732 5.86739 9.5 6 9.5C6.13261 9.5 6.25979 9.44732 6.35355 9.35355C6.44732 9.25979 6.5 9.13261 6.5 9V7.5C6.5 7.36739 6.44732 7.24021 6.35355 7.14645C6.25979 7.05268 6.13261 7 6 7ZM8.5 5V4C8.5 3.33696 8.23661 2.70107 7.76777 2.23223C7.29893 1.76339 6.66304 1.5 6 1.5C5.33696 1.5 4.70107 1.76339 4.23223 2.23223C3.76339 2.70107 3.5 3.33696 3.5 4V5C3.10218 5 2.72064 5.15804 2.43934 5.43934C2.15804 5.72064 2 6.10218 2 6.5V10C2 10.3978 2.15804 10.7794 2.43934 11.0607C2.72064 11.342 3.10218 11.5 3.5 11.5H8.5C8.89782 11.5 9.27936 11.342 9.56066 11.0607C9.84196 10.7794 10 10.3978 10 10V6.5C10 6.10218 9.84196 5.72064 9.56066 5.43934C9.27936 5.15804 8.89782 5 8.5 5ZM4.5 4C4.5 3.60218 4.65804 3.22064 4.93934 2.93934C5.22064 2.65804 5.60218 2.5 6 2.5C6.39782 2.5 6.77936 2.65804 7.06066 2.93934C7.34196 3.22064 7.5 3.60218 7.5 4V5H4.5V4ZM9 10C9 10.1326 8.94732 10.2598 8.85355 10.3536C8.75979 10.4473 8.63261 10.5 8.5 10.5H3.5C3.36739 10.5 3.24021 10.4473 3.14645 10.3536C3.05268 10.2598 3 10.1326 3 10V6.5C3 6.36739 3.05268 6.24021 3.14645 6.14645C3.24021 6.05268 3.36739 6 3.5 6H8.5C8.63261 6 8.75979 6.05268 8.85355 6.14645C8.94732 6.24021 9 6.36739 9 6.5V10Z"
          fill={props.color ?? "black"}
        />
      </g>
    </svg>
  );
}

export default LockIcon;
