import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Columns from 'react-columns';

import { apiGetOrgMembers } from 'shared/api/org';
import { SearchBox } from 'shared/ui-kit/SearchBox';

import './index.styles.scss';
import { CardOrgMember } from './CardOrgMember';

var queries = [
  {
    columns: 2,
    query: 'min-width: 500px'
  },
  {
    columns: 3,
    query: 'min-width: 1200px'
  },
  {
    columns: 4,
    query: 'min-width: 1400px'
  },
  {
    columns: 5,
    query: 'min-width: 1600px'
  },
  {
    columns: 6,
    query: 'min-width: 1800px'
  },
  {
    columns: 7,
    query: 'min-width: 2000px'
  }
];

export const OrgMember = () => {
  const navigate = useNavigate();
  const params = useParams();
  const orgId = params.id;

  const [searchTxt, setSearchTxt] = useState('');

  const [arrUser, setArrUser] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [requestedCount, setRequestedCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);

  useEffect(() => {
    loadData();
  }, [searchTxt]);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiGetOrgMembers({
        organisation: orgId,
        query: searchTxt === '' ? undefined : searchTxt,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrUser(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setRequestedCount(res.requests);
        setActiveCount(res.total_members);
      }
    } else {
      if (hasMore && !isLoading) {
        const res = await apiGetOrgMembers({
          organisation: orgId,
          query: searchTxt === '' ? undefined : searchTxt,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrUser((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div
            className="clickable"
            onClick={() => {
              navigate(-1);
            }}
          >
            <RoundBackIcon />
          </div>
          <div className="font-bold font32" style={{ marginLeft: 16 }}>
            School Members
          </div>
        </div>
        {requestedCount > 0 && (
          <div className="pending_view row_align_items clickable">
            <div className="font-bold font14">Pending Members</div>
            <div className="pending_mark font-bold font14">
              {requestedCount}
            </div>
            <div>
              <AngleRightIcon />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderSubHeader = () => {
    return (
      <div className="row_space_between" style={{ marginTop: 72 }}>
        <div className="font-bold font26">{activeCount} Active Members</div>
        <SearchBox
          onSearch={setSearchTxt}
          width={'300px'}
          height={'48px'}
          fontSize={14}
          placeholder="Search here..."
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div style={{ marginTop: 40 }}>
        <Columns
          queries={queries}
          gap={'16px'}
          rootStyles={{ paddingBottom: 10, width: '100%' }}
        >
          {arrUser.map((item, index) => {
            return (
              <CardOrgMember
                data={{
                  ...item,
                  name: item.creator_name,
                  image: item.creator_image,
                  id: item.creator_id
                }}
                key={`card_org_member_${index}`}
              />
            );
          })}
        </Columns>
      </div>
    );
  };

  return (
    <div className="org_member">
      {renderHeader()}
      {renderSubHeader()}
      {renderBody()}
    </div>
  );
};
