import React from 'react';

function ProcessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FF3E9A"
        d="M4.661 9.668a.666.666 0 00-.667.667v1.188A5.315 5.315 0 012.661 8c0-.196.012-.392.035-.586a.667.667 0 10-1.323-.16c-.03.247-.045.497-.045.746 0 1.591.572 3.129 1.611 4.334h-.945a.667.667 0 100 1.333h2.667a.655.655 0 00.53-.281c.007-.01.017-.018.025-.029.004-.007.004-.014.008-.021a.644.644 0 00.07-.172.635.635 0 00.022-.104c.002-.02.012-.039.012-.06v-2.666a.667.667 0 00-.667-.667zm1-5.667H4.473a5.316 5.316 0 013.521-1.333c.196 0 .392.011.587.035a.667.667 0 10.16-1.324 6.152 6.152 0 00-.747-.044c-1.59 0-3.128.572-4.333 1.61v-.944a.667.667 0 10-1.333 0v2.667a.636.636 0 00.026.13c.016.083.048.16.094.23l.008.012c.044.064.1.12.163.164.007.005.01.014.018.019.01.006.02.008.03.014a.638.638 0 00.295.091c.011 0 .02.007.032.007h2.667a.667.667 0 100-1.334zm7.974 7.203a.646.646 0 00-.094-.23l-.008-.011a.648.648 0 00-.163-.164c-.007-.006-.01-.014-.018-.02-.008-.004-.016-.004-.024-.008a.768.768 0 00-.273-.09c-.02-.003-.039-.013-.06-.013h-2.667a.667.667 0 100 1.333h1.188a5.315 5.315 0 01-3.522 1.334c-.195 0-.391-.012-.586-.036a.667.667 0 00-.16 1.324c.247.03.497.045.746.045 1.591 0 3.129-.573 4.334-1.611V14a.667.667 0 001.333 0v-2.666a.637.637 0 00-.026-.13zm.36-7.536a.667.667 0 100-1.333h-2.667a.636.636 0 00-.128.025l-.005.001a.644.644 0 00-.227.093l-.013.01a.65.65 0 00-.163.162c-.005.007-.014.01-.018.018-.005.007-.005.016-.01.023a.762.762 0 00-.09.275c-.003.02-.013.038-.013.06v2.666a.667.667 0 101.334 0V4.479a5.317 5.317 0 011.333 3.522c0 .196-.012.392-.035.587a.667.667 0 001.323.16 6.13 6.13 0 00.045-.747 6.643 6.643 0 00-1.611-4.333h.944z"
      ></path>
    </svg>
  );
}

export default ProcessIcon;
