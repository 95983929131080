import React, { useEffect, useState } from 'react';
import { apiGetQABookmarks } from 'shared/api/qa';
import { CardBookMarked } from './CardBookMarked';

import './index.styles.scss';

export const GroupDetailBookMark = ({ groupId }) => {
  const [arrQuestion, setArrQuestion] = useState<Array<any>>([]);
  const [lastIdDump, setLastIdDump] = useState(0);
  const [hasMoreDump, setHasMoreDump] = useState(true);
  const [isLoadingDump, setIsLoadingDump] = useState(false);

  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoadingDump(true);
      const res = await apiGetQABookmarks({
        query: '',
        item_id: groupId,
        item_type: 'group',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrQuestion(res.data);
        setLastIdDump(res.lastId);
        setHasMoreDump(res.hasMore);
        setTotalCount(res.total_count);
      }
      setIsLoadingDump(false);
    } else {
      if (hasMoreDump && !isLoadingDump) {
        setIsLoadingDump(true);
        const res = await apiGetQABookmarks({
          query: '',
          item_id: groupId,
          item_type: 'group',
          limit: 10,
          offset: lastIdDump
        });

        if (res.success) {
          setArrQuestion((prev) => [...prev, ...res.data]);
          setLastIdDump(res.lastId);
          setHasMoreDump(res.hasMore);
          setTotalCount(res.total_count);
        }
        setIsLoadingDump(false);
      }
    }
  };

  return (
    <div>
      {arrQuestion.map((item, index) => {
        return (
          <CardBookMarked
            data={item}
            index={index}
            totalCount={totalCount}
            key={`card_bookmark_${index}`}
            onRefresh={() => {}}
          />
        );
      })}
    </div>
  );
};
