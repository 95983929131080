import CloseIcon from 'assets/svgs/CloseIcon';
import React from 'react';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

interface Props {
  show: boolean;
  imageUrl: any;
  onClose: () => void;
}

export const FullImageModal = ({ show, imageUrl, onClose }: Props) => {
  return (
    <PrimaryModal
      show={show}
      width={'80%'}
      style={{
        height: '80%',
        borderRadius: 8,
        padding: 0,
        backgroundColor: 'transparent',
        boxShadow: 'none'
      }}
      onClose={() => {
        onClose();
      }}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          className="full_image_viewer"
          style={{
            maxWidth: '80%',
            height: '100%',
            borderRadius: 8,
            display: 'flex'
          }}
        >
          <img
            src={imageUrl}
            style={{
              height: '100%',
              borderRadius: 8,
              objectFit: 'contain'
            }}
          />
          <div
            className="clickable"
            style={{ marginLeft: 16 }}
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon size={32} color={'white'} />
          </div>
        </div>
      </div>
    </PrimaryModal>
  );
};
