import CloseIcon from 'assets/svgs/CloseIcon';
import React, { useState } from 'react';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { validateEmail, validURL } from 'shared/utils/utils';

import './index.styles.scss';
import { apiCreateQADump } from 'shared/api/qa';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface AddLinkModalProps {
  show: boolean;
  itemId?: any;
  itemType?: any;
  onClose: () => void;
  onSuccess: (dumpId, link) => void;
}

export const AddLinkModal = ({
  show,
  itemId,
  itemType,
  onClose,
  onSuccess
}: AddLinkModalProps) => {
  const [newEmail, setNewEmail] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const onCreate = async () => {
    if (validURL(newEmail)) {
      if (!isCreating) {
        setIsCreating(true);

        const res = await apiCreateQADump({
          url: newEmail,
          type: 'url',
          to: itemId,
          to_type: itemType
        });

        setIsCreating(false);
        if (res.success) {
          onSuccess(res.id, newEmail);
          setNewEmail('');
        } else {
          showToast('error', 'Unable to process link url');
        }
      }
    } else {
      showToast('error', 'Unable to process link url');
    }
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items" style={{ marginTop: 12 }}>
        <div className="clickable" onClick={onClose}>
          <CloseIcon />
        </div>
        <div
          className="font-bold font16"
          style={{ textAlign: 'center', flex: 1, marginRight: 24 }}
        >
          Add Link
        </div>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <div className="link_container">
        <div className="title font-bold font10">Target Link</div>
        <div className="add_link_input_container">
          <input
            value={newEmail}
            placeholder="Enter link here..."
            className="sub_input font-bold font16"
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </div>
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={400} onClose={onClose}>
      <div className="add_link_modal">
        {renderHeader()}
        {renderInput()}
        <div style={{ marginTop: 24 }}>
          <PrimaryButton
            label="Add Link"
            isLoading={isCreating}
            onPressed={onCreate}
          />
        </div>
      </div>
    </PrimaryModal>
  );
};
