import React from 'react';
import Columns from 'react-columns';
import { CardTrendingNewCreators } from './CardTrandingNewCreators';
import { CategoryBar } from './CategoryBar';
export const TrendingNewCreators = (personalAIs) => {
  const renderTrendingUser = () => {
    return (
      <div className="find_new_creator_trending_new_creators_trending_creators">
        {personalAIs.personalAIs.length > 0 ? (
          <Columns columns={2} gap={'16px'} rootStyles={{ paddingBottom: 10 }}>
            {personalAIs.personalAIs.map((item, index) => {
              return (
                <CardTrendingNewCreators
                  key={`card_trending_creators_${index}`}
                  data={item}
                />
              );
            })}
          </Columns>
        ) : (
          <></>
        )}
      </div>
    );
  };
  return (
    <div className="find_new_creator_trending_new_creators">
      {renderTrendingUser()}
    </div>
  );
};
