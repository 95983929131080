import React from 'react';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

import './index.styles.scss';

interface Props {
  show: boolean;
  onContinue: () => void;
  onCheckMyOrg: () => void;
}

export const JoinRequestSendModal = ({
  show,
  onContinue,
  onCheckMyOrg
}: Props) => {
  return (
    <PrimaryModal show={show} width={500} onClose={onCheckMyOrg}>
      <div className="join_request_sent_modal">
        <div className="font-bold" style={{ fontSize: 88 }}>
          📬
        </div>
        <div className="font-bold font24" style={{ marginTop: 22 }}>
          Join Request Sent
        </div>
        <div
          className="font-regular font16"
          style={{
            color: 'rgba(0, 0, 0, 0.6)',
            marginTop: 12,
            textAlign: 'center'
          }}
        >
          Now you have to wait for the orgs approval
        </div>
        <div style={{ width: '100%', marginTop: 22 }}>
          <PrimaryButton label="Continue Explore" onPressed={onContinue} />
        </div>
        <div
          className="check_btn clickable font-bold font14"
          onClick={onCheckMyOrg}
        >
          Check My Orgs
        </div>
      </div>
    </PrimaryModal>
  );
};
