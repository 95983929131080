import AllOrgIcon from 'assets/svgs/AllOrgIcon';
import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import BookMarkedIcon from 'assets/svgs/BookMarkedIcon';
import CommentAltCheckIcon from 'assets/svgs/CommentAltCheckIcon';
import EditIcon from 'assets/svgs/EditIcon';
import AlertCheckIcon from 'assets/svgs/AlertCheckIcon';
import GeneralAiIcon from 'assets/svgs/GeneralAiIcon';
import { AddReferenceModal } from 'components/Modals/AddReferenceModal';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import Tooltip from '@mui/material/Tooltip';

import {
  apiQABookMark,
  apiQAEditAnswer,
  apiQAUnBookMark,
  apiGetQAAskGeneral
} from 'shared/api/qa';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { getArrFromString } from 'shared/utils/utils';

import './index.styles.scss';
import { Reference } from 'components/Common/Reference';
import { ReferenceDetail } from 'components/Common/ReferenceDetail';
import { SelectPersonAiModal } from 'components/Modals/SelectPersonAIModal';
import RegenerateIcon from 'assets/svgs/RegenerateIcon';
import { useNavigate } from 'react-router-dom';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';

interface AskQuestionProps {
  selectedOrg: any;
  textQuestion: string;
  qaAskInfo: { [key: string]: any } | null;
  isAsking: boolean;
  setQuestion: any;
  onRefresh: () => void;
  onReloadQuestion: (orgId) => void;
}

export const AskQuestion = ({
  selectedOrg,
  textQuestion,
  qaAskInfo,
  isAsking,
  setQuestion,
  onRefresh,
  onReloadQuestion
}: AskQuestionProps) => {
  const navigate = useNavigate();

  const [isOpenAIToogle, setIsOpenAIToogle] = useState(false);
  const [isSavingAnswer, setIsSavingAnswer] = useState(false);
  const [isEditAnswer, setIsEditAnswer] = useState(false);

  const [isOpenAddReferenceDlg, setIsOpenAddReferenceDlg] = useState(false);
  const [isExpandReference, setIsExpandReference] = useState(false);

  const [isBookMarkingAnswer, setIsBookMarkingAnswer] = useState(false);
  const [isUnBookMarkingAnswer, setIsUnBookMarkingAnswer] = useState(false);
  const [answer, setAnswer] = useState(qaAskInfo?.ai_answer);
  const [snackBarShow, setSnackBarShow] = useState(false);

  const [isAIGeneral, setIsAIGeneral] = useState(false);
  const [isSavingGeneralAIAnswer, setIsSavingGeneralAIAnswer] = useState(false);
  const [isEditGeneralAIAnswer, setIsEditGeneralAIAnswer] = useState(false);
  const [generalAIAnswer, setGeneralAIAnswer] = useState('');
  const [tempGeneralAIAnswer, setTempGeneralAIAnswer] = useState('');
  const [isAskingGeneralAIAnswer, setIsAskingGeneralAIAnswer] = useState(false);

  const [arrExtraUserRef, setArrExtraUserRef] = useState<Array<any>>([]);
  const [arrExtraFileRef, setArrExtraFileRef] = useState<Array<any>>([]);
  const [aiInfo, setAiInfo] = useState<any>();

  useEffect(() => {
    if (qaAskInfo) {
      if (isAIGeneral) {
        setGeneralAIAnswer(qaAskInfo?.answer);
      } else {
        setAnswer(qaAskInfo?.ai_answer);
      }
    }
  }, [qaAskInfo, isAIGeneral]);

  useEffect(() => {
    if (selectedOrg) {
      const ai_info: any = {
        ai_id: selectedOrg.id,
        ai_name: selectedOrg.name,
        ai_image: selectedOrg.image,
        ai_type: 'organisation'
      };
      setAiInfo(ai_info);
      setIsAIGeneral(false);
    } else {
      setIsAIGeneral(true);
    }
  }, [selectedOrg]);

  const onChangeAi = (newAI) => {
    setAiInfo(newAI);
    onReloadQuestion(newAI.ai_id);
  };

  const onDoneAddReference = (newUserRef, newFileRef) => {
    setArrExtraUserRef(newUserRef);
    setArrExtraFileRef(newFileRef);
  };

  const onSaveAnswer = async () => {
    if (isAIGeneral)
      if (!isSavingGeneralAIAnswer) {
        setIsSavingGeneralAIAnswer(true);

        const res = await apiQAEditAnswer({
          question: qaAskInfo ? qaAskInfo.id : 0,
          answer: tempGeneralAIAnswer
        });

        if (res.success) {
          setGeneralAIAnswer(tempGeneralAIAnswer);
          setIsSavingGeneralAIAnswer(false);
          setIsEditGeneralAIAnswer(false);
        }
      } else if (!isSavingAnswer) {
        setIsSavingAnswer(true);

        const res = await apiQAEditAnswer({
          question: qaAskInfo ? qaAskInfo.id : 0,
          answer: answer
        });

        if (res.success) {
          setIsSavingAnswer(false);
          setIsEditAnswer(false);
        }
      }
  };

  const onAnswerUnBookMark = async () => {
    if (!isUnBookMarkingAnswer) {
      setIsUnBookMarkingAnswer(true);

      const res = await apiQAUnBookMark({
        question: qaAskInfo ? qaAskInfo.id : 0,
        item_id: aiInfo ? aiInfo.ai_id : 30,
        item_type: 'organisation'
      });
      if (res.success && qaAskInfo) {
        qaAskInfo.bookmark = false;
      }

      setIsUnBookMarkingAnswer(false);
    }
  };

  const onAnswerBookMark = async () => {
    if (!isBookMarkingAnswer && !isAsking) {
      setIsBookMarkingAnswer(true);

      const res = await apiQABookMark({
        question: qaAskInfo ? qaAskInfo.id : 0,
        item_id: aiInfo ? aiInfo.ai_id : 30,
        item_type: 'organisation'
      });

      if (res.success && qaAskInfo) {
        qaAskInfo.bookmark = true;
        setSnackBarShow(true);
        setInterval(() => {
          setSnackBarShow(false);
          //
        }, 1000);

        navigate(`/school/question/${res.bookmark_question}/answer`);
      }

      setIsBookMarkingAnswer(false);
    }
  };

  const onSwitchAIType = async () => {
    setIsAIGeneral(!isAIGeneral);
    if (!isAIGeneral) {
      setIsAskingGeneralAIAnswer(true);
      const res = await apiGetQAAskGeneral({
        question: textQuestion,
        to_type: 'organisation',
        to: 30
      });
      if (res.success) {
        setGeneralAIAnswer(res.answer);
        setTempGeneralAIAnswer(res.answer);
      }
      setIsAskingGeneralAIAnswer(false);
    }

    setIsAIGeneral(!isAIGeneral);
  };

  const arrReferences = useMemo(() => {
    if (qaAskInfo?.ref_names || qaAskInfo?.ref_types) {
      const arrTmpRefData: Array<any> = [];

      const arrRefIds = getArrFromString(qaAskInfo.ref_ids, ';;;');
      // for user
      const arrTmpName = getArrFromString(qaAskInfo.ref_names, ';;;');
      const arrTmpImage = getArrFromString(qaAskInfo.ref_images, ';;;');

      // for files
      const arrTmpFileName = getArrFromString(
        qaAskInfo.ref_titles ?? qaAskInfo.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(qaAskInfo.ref_urls, ';;;');
      const arrTmpFileType = getArrFromString(qaAskInfo.ref_types, ';;;');

      arrTmpName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpName[index],
          image: arrTmpImage[index],
          type: 'user'
        });
      });

      arrTmpFileName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpFileName[index],
          image: arrTmpFileUrl[index],
          type: 'file',
          fileType: arrTmpFileType[index]
        });
      });

      // add extra file references
      arrExtraFileRef.forEach((fileRef) => {
        arrTmpRefData.push({
          name: fileRef.filename,
          image: fileRef.url,
          type: 'file'
        });
      });

      // add extra user references
      arrExtraUserRef.forEach((userRef) => {
        arrTmpRefData.push({
          name: userRef.creator_name,
          image: userRef.creator_image,
          type: 'user'
        });
      });
      // remove duplicated
      const uniqueArr = arrTmpRefData.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.image === value.image || t.name === value.name
          )
      );
      return uniqueArr;
    } else {
      return [];
    }
  }, [qaAskInfo, arrExtraUserRef, arrExtraFileRef]);

  const referencesForBelow = useMemo(() => {
    if (qaAskInfo?.ref_names || qaAskInfo?.ref_types) {
      const arrTmpRefData: Array<any> = [];

      const arrRefIds = getArrFromString(qaAskInfo.ref_ids, ';;;');
      // for user
      const arrTmpName = getArrFromString(qaAskInfo.ref_names, ';;;');
      const arrTmpImage = getArrFromString(qaAskInfo.ref_images, ';;;');

      // for files
      const arrTmpFileName = getArrFromString(
        qaAskInfo.ref_titles ?? qaAskInfo.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(qaAskInfo.ref_urls, ';;;');
      const arrTmpTxt = getArrFromString(qaAskInfo.ref_texts, ';;;');
      const arrTmpFileType = getArrFromString(qaAskInfo.ref_types, ';;;');

      arrRefIds.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpName[index],
          image: arrTmpImage[index],
          fileName: arrTmpFileName[index],
          url: arrTmpFileUrl[index],
          text: arrTmpTxt[index],
          fileType: arrTmpFileType[index]
        });
      });
      // add extra file references
      arrExtraFileRef.forEach((fileRef) => {
        arrTmpRefData.push({
          fileName: fileRef.filename,
          url: fileRef.url,
          type: 'file',
          fileType: fileRef.dump_type
        });
      });

      // add extra user references
      arrExtraUserRef.forEach((userRef) => {
        arrTmpRefData.push({
          name: userRef.creator_name,
          image: userRef.creator_image,
          type: 'user'
        });
      });

      // remove duplicated
      const uniqueArr = arrTmpRefData.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.image === value.image || t.name === value.name
          )
      );
      return uniqueArr;
    } else {
      return [];
    }
  }, [qaAskInfo, arrExtraUserRef, arrExtraFileRef]);

  const renderQuestion = () => {
    return (
      <input
        className="question_text font-bold font32"
        value={textQuestion}
        onChange={(e) => setQuestion(e.target.value)}
      />
    );
  };

  const renderTopView = () => {
    return (
      <div className="row_space_between" style={{ marginTop: 32 }}>
        <div className="row_align_items">
          {!isAIGeneral ? (
            <>
              {/* <DefaultAvatarIcon size={48} /> */}
              <img
                src={aiInfo?.ai_image}
                style={{ width: '48px', height: '48px', borderRadius: '48px' }}
              />
              <div style={{ marginLeft: 8, position: 'relative' }}>
                <div className="font-bold font12 org_ai_label">School AI</div>
                <div
                  className="row_align_items clickable"
                  onClick={() => setIsOpenAIToogle(true)}
                >
                  <div className="font-bold font20">{aiInfo?.ai_name}</div>
                  <div>
                    <AngleDownIcon opacity="0.4" />
                  </div>
                </div>
              </div>
              {isOpenAIToogle && renderAIToogle()}
            </>
          ) : (
            <>
              <div className="general_ai_icon">
                <GeneralAiIcon />
              </div>
              <div className="font-bold font20 general_ai_label">
                General AI
              </div>
            </>
          )}
        </div>
        <div className="row_align_items">
          <Tooltip
            placement="left"
            title={
              <div className="font-regular font14">
                Switch to another AI to answer with a different knowledge base
              </div>
            }
            PopperProps={{
              sx: {
                marginRight: '232px !important',
                marginTop: '-60px !important'
              }
            }}
          >
            <div
              className="switch_ai_btn clickable"
              onClick={() => onSwitchAIType()}
            >
              {!isAIGeneral ? <AllOrgIcon /> : <GeneralAiIcon />}
              <div className="font-medium font16 ">Switch AI</div>
            </div>
          </Tooltip>
          <Tooltip
            placement="left"
            title={
              <div className="font-regular font14">
                Generate an alternative answer with AI
              </div>
            }
            PopperProps={{
              sx: {
                marginRight: '232px !important',
                marginTop: '-60px !important'
              }
            }}
          >
            <div
              className="refresh_btn clickable"
              style={{ width: 120, height: 40 }}
              onClick={onRefresh}
            >
              <RegenerateIcon />
              <div className="font-medium font16 ">Refresh</div>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };
  const renderSnackbar = () => {
    return (
      <div className="snackbar">
        <AlertCheckIcon />
        <div className="alert-message font-bold font15">
          Successfully post question on org
        </div>
      </div>
    );
  };
  const renderAnswer = () => {
    return (
      <div className="question_detail_box">
        {isAsking ? (
          <LoadingBarAnimation />
        ) : isEditAnswer ? (
          renderEditAnswer()
        ) : (
          renderNormalAnswer()
        )}
      </div>
    );
  };

  const renderNormalAnswer = () => {
    return (
      <>
        <div className="font-regular font20" style={{ marginBottom: 32 }}>
          <pre className="font20">{answer}</pre>
        </div>
        <div className="row_space_between" style={{ position: 'relative' }}>
          <Reference
            data={arrReferences}
            showAddButton={false}
            itemId={selectedOrg?.id}
            itemType={'organisation'}
            onDoneAddReference={onDoneAddReference}
            setIsExpandReference={setIsExpandReference}
            isExpandReference={isExpandReference}
          />
          <div className="row_align_items" style={{ marginLeft: 12 }}>
            {selectedOrg && (
              <Tooltip
                placement="left"
                title={
                  <div className="font-regular font14">
                    Edit the generated answer yourself
                  </div>
                }
                PopperProps={{
                  sx: {
                    marginRight: '232px !important',
                    marginTop: '-60px !important'
                  }
                }}
              >
                <div
                  className="edit_btn clickable"
                  onClick={() => {
                    if (!isAsking) {
                      setIsEditAnswer(true);
                    }
                  }}
                >
                  <EditIcon color="#FF3E9A" />
                </div>
              </Tooltip>
            )}
            {qaAskInfo && qaAskInfo.bookmark ? (
              <>
                <div
                  className="edit_btn clickable"
                  style={{ marginLeft: 12 }}
                  onClick={onAnswerUnBookMark}
                >
                  {isUnBookMarkingAnswer ? (
                    <LoadingCircular size={24} color="main" />
                  ) : (
                    <BookMarkedIcon size={24} />
                  )}
                </div>
              </>
            ) : (
              <Tooltip
                placement="left"
                title={
                  <div className="font-regular font14">
                    Post and share question and answers to organisation
                  </div>
                }
                PopperProps={{
                  sx: {
                    marginRight: '232px !important',
                    marginTop: '-60px !important'
                  }
                }}
              >
                <div
                  className="post_as_answer_btn clickable"
                  onClick={onAnswerBookMark}
                >
                  {isBookMarkingAnswer ? (
                    <LoadingCircular size={20} color="white" />
                  ) : (
                    <>
                      <CommentAltCheckIcon />
                      <div className="font-bold font16">Post as Answer</div>
                    </>
                  )}
                </div>
              </Tooltip>
            )}
          </div>
          <AddReferenceModal
            show={isOpenAddReferenceDlg}
            onClose={() => setIsOpenAddReferenceDlg(false)}
            selectedOrg={selectedOrg}
          />
        </div>
        {isExpandReference && <ReferenceDetail data={referencesForBelow} />}
      </>
    );
  };
  const renderEditAnswer = () => {
    return (
      <>
        <div className="row_space_between">
          <div className="font-bold font18">Edit Answer</div>
          <div
            className="font-bold font14 clickable"
            style={{ color: '#FF3E9A' }}
            onClick={() => {
              setAnswer(qaAskInfo ? qaAskInfo.ai_answer : '');
            }}
          >
            Refresh
          </div>
        </div>
        <textarea
          value={answer}
          className="font-regular font20 answer_text_area"
          onChange={(e) => setAnswer(e.target.value)}
        />
        <div className="row_space_between">
          <div
            className="cancel_btn font-medium font16 clickable"
            onClick={() => {
              setIsEditAnswer(false);
              setAnswer(qaAskInfo ? qaAskInfo.ai_answer : '');
            }}
          >
            Cancel
          </div>
          <div
            className="save_answer_btn font-bold font16 clickable"
            onClick={onSaveAnswer}
          >
            {isSavingAnswer ? (
              <LoadingCircular size={20} color="white" />
            ) : (
              <>Save Answer</>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderGeneralAIAnswer = () => {
    return (
      <div className="question_detail_box">
        {isEditGeneralAIAnswer
          ? renderEditGeneralAIAnswer()
          : renderNormalGeneralAIAnswer()}
      </div>
    );
  };

  const renderNormalGeneralAIAnswer = () => {
    return (
      <>
        <div className="font-regular font20" style={{ marginBottom: 32 }}>
          {isAskingGeneralAIAnswer || isAsking ? (
            <LoadingBarAnimation />
          ) : (
            <>
              <pre className="font20">{generalAIAnswer}</pre>
            </>
          )}
        </div>
        <div className="row_space_between" style={{ position: 'relative' }}>
          <div />
          <div className="row_align_items">
            {selectedOrg && (
              <div
                className="edit_btn clickable"
                onClick={() => {
                  if (!isAsking) {
                    setIsEditGeneralAIAnswer(true);
                  }
                }}
              >
                <EditIcon color="#FF3E9A" />
              </div>
            )}
            {qaAskInfo && qaAskInfo.bookmark ? (
              <>
                <div
                  className="edit_btn clickable"
                  style={{ marginLeft: 12 }}
                  onClick={onAnswerUnBookMark}
                >
                  {isUnBookMarkingAnswer ? (
                    <LoadingCircular size={24} color="main" />
                  ) : (
                    <BookMarkedIcon size={24} />
                  )}
                </div>
              </>
            ) : (
              selectedOrg && (
                <Tooltip
                  placement="left"
                  title={
                    <div className="font-regular font14">
                      Post and share question and answers to organisation
                    </div>
                  }
                  PopperProps={{
                    sx: {
                      marginRight: '232px !important',
                      marginTop: '-60px !important'
                    }
                  }}
                >
                  <div
                    className="post_as_answer_btn clickable"
                    onClick={onAnswerBookMark}
                  >
                    {isBookMarkingAnswer ? (
                      <LoadingCircular size={20} color="white" />
                    ) : (
                      <>
                        <CommentAltCheckIcon />
                        <div className="font-bold font16">Post as Answer</div>
                      </>
                    )}
                  </div>
                </Tooltip>
              )
            )}
          </div>
        </div>
      </>
    );
  };

  const renderEditGeneralAIAnswer = () => {
    return (
      <>
        <div className="row_space_between">
          <div className="font-bold font18">Edit Answer</div>
          <div
            className="font-bold font14 clickable"
            style={{ color: '#FF3E9A' }}
            onClick={() => {
              setAnswer(qaAskInfo ? qaAskInfo.ai_answer : '');
            }}
          >
            Refresh
          </div>
        </div>
        <textarea
          value={tempGeneralAIAnswer}
          className="font-regular font20 answer_text_area"
          onChange={(e) => setTempGeneralAIAnswer(e.target.value)}
        />
        <div className="row_space_between">
          <div
            className="cancel_btn font-medium font16 clickable"
            onClick={() => {
              setIsEditGeneralAIAnswer(false);
              setTempGeneralAIAnswer(generalAIAnswer);
            }}
          >
            Cancel
          </div>
          <div
            className="save_answer_btn font-bold font16 clickable"
            onClick={onSaveAnswer}
          >
            {isSavingAnswer ? (
              <LoadingCircular size={20} color="white" />
            ) : (
              <>Save Answer</>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderAIToogle = () => {
    return (
      <SelectPersonAiModal
        show={isOpenAIToogle}
        aiType="organisation"
        selectedAi={aiInfo}
        onClose={() => {
          setIsOpenAIToogle(false);
        }}
        onSelected={(newAI) => {
          setIsOpenAIToogle(false);
          onChangeAi(newAI);
        }}
      />
    );
  };

  return (
    <div className="org_question_box">
      {renderQuestion()}
      {renderTopView()}
      {!isAIGeneral ? renderAnswer() : renderGeneralAIAnswer()}
      {snackBarShow ? renderSnackbar() : <></>}
    </div>
  );
};
