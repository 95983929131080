import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  apiAcceptGroupInvitation,
  apiRejectGroupInvitation
} from 'shared/api/group';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';

interface CardGroupInvitationRrops {
  data: any;
  onRemove: (groupId, isAccepted) => void;
}

export const CardGroupInvitation = ({
  data,
  onRemove
}: CardGroupInvitationRrops) => {
  const navigate = useNavigate();

  const groupId = data.id;
  const [isRejecting, setIsRejecting] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  const onAcceptInvitation = async () => {
    if (!isAccepting && !isRejecting) {
      setIsAccepting(true);

      const res = await apiAcceptGroupInvitation({ group: groupId });
      if (res.success) {
        onRemove(groupId, true);
      }
      setIsAccepting(false);
    }
  };

  const onRejectInvitation = async () => {
    if (!isAccepting && !isRejecting) {
      setIsRejecting(true);

      const res = await apiRejectGroupInvitation({ group: groupId });
      if (res.success) {
        onRemove(groupId, false);
      }
      setIsRejecting(false);
    }
  };

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const renderTopView = () => {
    return (
      <div className="card_group_invitation_top">
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img
            src={data.creator_image}
            style={{ width: 24, height: 24, borderRadius: 24 }}
          />
        ) : (
          <DefaultAvatarIcon size={24} />
        )}
        <div
          className="font-medium font14 clickable"
          style={{ marginLeft: 8 }}
          onClick={onViewProfile}
        >
          {data.creator_name}
        </div>
        <div
          className="font-regular font14"
          style={{ marginLeft: 8, opacity: 0.4 }}
        >
          invited you to a group
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="card_group_invitation_body">
        <div className="card_group_invitation_body_top">
          <div
            className="card_group_invitation_body_top_body"
            style={{ margin: '8px 0px' }}
          >
            {data.url?.startsWith('https://') ? (
              <img className="group_image" src={data.url}></img>
            ) : (
              <DefaultAvatarIcon size={32} />
            )}
            <div className="font-bold font20" style={{ marginLeft: 16 }}>
              {data.name}
            </div>
          </div>
          <div
            className="font-regular font12"
            style={{ opacity: 0.6, marginTop: 4 }}
          >
            {data.description}
          </div>
        </div>
        <div className="card_group_invitation_body_right">
          <div style={{ fontSize: 14 }}>🙋‍&nbsp;</div>
          <div className="card_group_invitation_like">{`${data.members}`}</div>
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="card_group_invitation_bottom">
        <div className="dismiss_btn clickable" onClick={onRejectInvitation}>
          {isRejecting ? (
            <LoadingCircular size={20} color="main" />
          ) : (
            <div className="font-bold font14">Dismiss</div>
          )}
        </div>
        <div className="request_btn clickable" onClick={onAcceptInvitation}>
          {isAccepting ? (
            <LoadingCircular size={20} color="white" />
          ) : (
            <div className="font-bold font14" style={{ color: 'white' }}>
              Accept
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="card_group_invitation">
      {renderTopView()}
      {renderBody()}
      {renderBottom()}
    </div>
  );
};
