import React from 'react';

function TagIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size :"20"}
      height={props.size ? props.size :"21"}
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill={props.color ? props.color : "#000"}
        stroke={props.color ? props.color : "#FFF"}
        d="M17.166 11.195a1.331 1.331 0 00-.386-.947l-.002-.001-6.766-6.767-.147-.146H2.833v7.032l.147.146 6.767 6.759h0a1.333 1.333 0 001.88 0h.001l5.15-5.142.002-.002c.246-.248.385-.583.386-.932zm0 0s0 0 0 0l-.5-.003.5.003s0 0 0 0zM10 2.667h.003a.333.333 0 01.236.096s0 0 0 0l6.86 6.87v.04l.147.147a2 2 0 010 2.826v.001l-5.14 5.098-.001.002a2 2 0 01-2.827 0s0 0 0 0L2.263 10.74s0 0 0 0a.335.335 0 01-.096-.237h0V3a.333.333 0 01.333-.333H10zm-4.167 3.46a.75.75 0 11.834 1.247.75.75 0 01-.834-1.247z"
      ></path>
    </svg>
  );
}

export default TagIcon;
