import CloseIcon from 'assets/svgs/CloseIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiQAGetShareLink } from 'shared/api/qa';
import { apiGetUserSharingQR } from 'shared/api/user';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { getImageFromHtml } from 'shared/utils/utils';

import './index.styles.scss';
import { InviteUsingEmail } from './InviteUsingEmail';
import { InviteUsingQR } from './InviteUsingQR';

interface InviteFriendModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: (arrData: Array<any>) => void;
}

export const InviteFriendModal = ({
  show,
  onClose,
  onSuccess
}: InviteFriendModalProps) => {
  const [tab, setTab] = useState<'email' | 'qr'>('email');

  const renderHeader = () => {
    return (
      <div className="row_align_items" style={{ marginTop: 12 }}>
        <div className="clickable" onClick={onClose}>
          <CloseIcon />
        </div>
        <div
          className="font-bold font16"
          style={{ textAlign: 'center', flex: 1, marginRight: 24 }}
        >
          {`Invite Friends to ${process.env.REACT_APP_NAME}`}
        </div>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className="invite_tab">
        <div
          className={
            (tab === 'email' ? 'selected_tab' : 'default_tab') + ' clickable'
          }
          onClick={() => setTab('email')}
        >
          <div
            className={
              (tab === 'email' ? 'font-bold' : 'font-regular') + ' font12'
            }
          >
            Email
          </div>
        </div>
        <div
          className={
            (tab === 'qr' ? 'selected_tab' : 'default_tab') + ' clickable'
          }
          onClick={() => setTab('qr')}
        >
          <div
            className={
              (tab === 'qr' ? 'font-bold' : 'font-regular') + ' font12'
            }
          >
            QR Code
          </div>
        </div>
      </div>
    );
  };

  const renderEmailBody = () => {
    return <InviteUsingEmail onSuccess={onSuccess} />;
  };

  const renderQrBody = () => {
    return <InviteUsingQR />;
  };

  return (
    <PrimaryModal show={show} width={600} onClose={onClose}>
      <div className="invite_friend_modal">
        {renderHeader()}
        {renderTab()}
        {tab === 'email' ? renderEmailBody() : renderQrBody()}
      </div>
    </PrimaryModal>
  );
};
