import Cookies from 'js-cookie';

export const getToken = () => {
  let token: string = Cookies.get('accessToken') || '';
  // token =
  //   'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ODU5OSwiZW1haWwiOiJhbmdlbHNhdG9ycmVzQGdtYWlsLmNvbSIsInJvbGUiOiJVU0VSIiwiaWF0IjoxNjc5OTI4NDI1LCJleHAiOjE2ODI1MjA0MjV9.R9CfSt4mHjEP0ECpA-SG3gD1g0NQnnCE-GtUv6IYbOEck6N4EZ80URh6XmBoqCeXzrPX1HDeQOp_YYcg46HDbG4Olxkr502q18gMWnEtHcRN82Av8A436dwhDfXDHot242H-Xz1ZF-k1J0lPJvf4_P0uvp_6Hu2u1J156vLdT5epgS1yKYXVK7K2HnO6jH53XT7tcjTUgp87Bk-Ptzapw7PwO0QyLn7bjXOzUk6WoVHUe-_uGPEaEO0HTFKwJ1QFVuifY3y5BZ0NwarS96yq50BrxaX5hX3_WlN5Sde0rtfVNnNb0KenOpjosni6s65q9cmIHUGvLOAAT2P1g0oRtA';

  return token;
};

export const removeToken = () => {
  Cookies.set('accessToken', null);
};

export const setToken = (token) => {
  Cookies.set('accessToken', token);
};

export const removeCookie = () => {
  Cookies.remove('accessToken');
};
