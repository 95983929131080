import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RoundDetailIcon from 'assets/svgs/RoundDetailIcon';
import React, { useEffect, useMemo, useState } from 'react';
import Switch from 'react-switch';
import { getArrFromString } from 'shared/utils/utils';
import moment from 'moment';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { apiAddDumpEditor, apiRemoveDumpEditor } from 'shared/api/group';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

interface CardActiveMemberProps {
  data: any;
  editType: 'everyone' | 'selected' | 'admin';
  groupId: number;
}

export const CardActiveMember = ({
  data,
  groupId,
  editType
}: CardActiveMemberProps) => {
  const navigate = useNavigate();

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const roles = getArrFromString(data.roles, ',');
  const isAdmin = roles.includes('creator');
  const [isEditor, setIsEditor] = useState(roles.includes('editor'));
  const [isUpdatingRole, setIsUpdatingRole] = useState(false);

  useEffect(() => {
    if (editType === 'everyone') {
      setIsEditor(true);
    } else if (editType === 'admin') {
      setIsEditor(isAdmin);
    }
  }, [editType, data]);

  const sinceDateTxt = useMemo(() => {
    return moment(data.timestamp).format('DD MMM YYYY');
  }, [data]);

  const onUpdateEditRole = async () => {
    if (editType === 'selected') {
      if (isEditor) {
        setIsUpdatingRole(true);

        const res = await apiRemoveDumpEditor({
          group: groupId,
          toUser: data.user
        });

        if (res.success) {
          setIsEditor((prev) => !prev);
        }
        setIsUpdatingRole(false);
      } else {
        setIsUpdatingRole(true);

        const res = await apiAddDumpEditor({
          group: groupId,
          toUser: data.user
        });

        if (res.success) {
          setIsEditor((prev) => !prev);
        }
        setIsUpdatingRole(false);
      }
    }
  };

  const onViewProfile = () => {
    if (data.creator_id === userInfo?.id) {
      navigate(`/profile`);
    } else {
      navigate(`/profile/${data.creator_id}`);
    }
  };

  return (
    <div
      className="card_active_member"
      style={
        isAdmin
          ? {
              background:
                'linear-gradient(90deg, rgba(255, 62, 154, 0.05) 0.59%, rgba(216, 62, 255, 0.05) 100.59%)'
            }
          : {}
      }
    >
      <div className="avatar">
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img
            src={data.creator_image}
            style={{ width: 48, height: 48, borderRadius: 48 }}
          />
        ) : (
          <DefaultAvatarIcon size={48} />
        )}
      </div>
      <div className="card_active_member_info">
        <div className="card_active_member_info_top">
          <div
            className="font-bold font20"
            style={
              isAdmin
                ? {
                    width: 'fit-content',
                    background:
                      'linear-gradient(90deg, #FF3E9A 0.59%, #D83EFF 100.59%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }
                : {}
            }
          >
            {data.creator_name}
          </div>
          {isAdmin && (
            <div className="active_member_admin">
              <div className="font-bold font10" style={{ color: 'white' }}>
                Administrator
              </div>
            </div>
          )}
        </div>

        <div className="font-regular font14" style={{ marginTop: 8 }}>
          {data.creator_email}
        </div>
      </div>
      <div
        className="card_active_member_joined_since font-medium font14"
        style={{ opacity: 0.4 }}
      >
        {sinceDateTxt}
      </div>
      <div className="card_active_member_dump_editor">
        {isUpdatingRole ? (
          <LoadingCircular size={24} color="main" />
        ) : (
          <Switch
            onChange={() => onUpdateEditRole()}
            checked={isEditor}
            checkedIcon={<div />}
            uncheckedIcon={<div />}
            offColor={'#EAEBEC'}
            onColor={'#FF3E9A'}
            activeBoxShadow={undefined}
            handleDiameter={18}
            width={44}
            height={24}
          />
        )}
      </div>
      <div
        className="card_active_member_action"
        style={{ marginRight: 24 }}
        onClick={onViewProfile}
      >
        <div className="card_active_member_action_profile clickable">
          <div className="font-medium font12">View Profile</div>
        </div>
      </div>
    </div>
  );
};
