import RoundArrowLeftIcon from 'assets/svgs/RoundArrowLeftIcon';
import React, { useState, useEffect } from 'react';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';
import Tooltip from '@mui/material/Tooltip';
import AngleDoubleUpIcon from 'assets/svgs/AngleDoubleUpIcon';

import './index.styles.scss';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import { DocumentQuestion } from '../DocumentQuestion';
import QuestionIcon from 'assets/svgs/QuestionIcon';
import AngleDoubleDownIcon from 'assets/svgs/AngleDoubleDownIcon';
import { DocumentFileAiView } from './FileAiView';
import { HighlightConversation } from './HighlightConversation';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { apiQAFile } from 'shared/api/qa';
import { apiFileSummarize } from 'shared/api/file';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { socket } from 'components/Auth';

export const NoteViewer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dumpId = params.dumpId!;

  const [fileName, setFileName] = useState('');
  const [fileUrl, setFileUrl] = useState(null);

  const [showFileAi, setShowFileAi] = useState(false);
  const [showHighlightConversation, setShowHighlightConversation] = useState(
    false
  );

  const [showAskQuestion, setShowAskQuestion] = useState(false);
  const [showBackDocBtn, setShowBackDocBtn] = useState(false);

  // ask
  const [answer, setAnswer] = useState<any>(null);
  const [highlightId, setHightlightId] = useState(undefined);
  const [isWholeSummarizing, setIsWholeSummarizing] = useState(false);
  const [isPartSummarizing, setIsPartSummarizing] = useState(false);

  useEffect(() => {
    loadDatil();
  }, []);

  useEffect(() => {
    if (socket && highlightId) {
      socket.emit('join-room', `file-${highlightId}`);

      const newMessageHandler = (data) => {
        onReceivedSummary(data);
      };

      socket.on('file-summary', newMessageHandler);

      return () => {
        socket.removeListener('file-summary', newMessageHandler);
      };
    }
  }, [socket, highlightId]);

  useEffect(() => {
    if (!showAskQuestion) {
      onScrollToDocumentTop();
    }
  }, [showAskQuestion]);

  useEffect(() => {
    if (showAskQuestion) {
      setShowBackDocBtn(true);
    } else {
      setShowBackDocBtn(false);
    }
  }, [showAskQuestion]);

  const loadDatil = async () => {
    const res = await apiQAFile({
      dump: dumpId
    });
    console.log({ res });
    if (res.success) {
      setFileName(res.data.title);
      setFileUrl(res.data.url);
    }
  };

  const loadAnswer = async () => {
    setIsWholeSummarizing(true);

    const res = await apiFileSummarize({
      dump: dumpId,
      text: '',
      type: 'general'
    });

    setHightlightId(res.highlight_id);
    setAnswer(res.summary);
    setShowFileAi(true);

    setIsWholeSummarizing(false);
  };

  const onReceivedSummary = (data) => {
    setAnswer(data.summary);
    setShowFileAi(true);
    setIsPartSummarizing(false);
  };

  const onBack = () => {
    navigate(-1);
  };

  const onScrollToDocumentTop = () => {
    const viewer = document.getElementById('document_viewer');
    viewer?.scrollIntoView();
  };

  const onClickBackBtn = () => {
    setShowFileAi(false);
    setShowAskQuestion(false);
    setShowHighlightConversation(false);
  };

  const renderHeader = () => {
    return (
      <div className="document_header">
        <div className="row_align_items">
          <div className="clickable" onClick={onBack}>
            <RoundArrowLeftIcon isEnable={true} />
          </div>
          <div style={{ marginLeft: 16 }}>
            <div className="font36">📄</div>
          </div>
          <div className="font-regular font18" style={{ marginLeft: 8 }}>
            {fileName}
          </div>
        </div>
      </div>
    );
  };

  const renderDocumentViewer = () => {
    return (
      <div
        id="document_viewer"
        style={{ height: `calc(100% - 300px)`, margin: '0px 32px' }}
      >
        <div className="document_content_body">
          <pre
            className="font-regular font24 text_input"
            style={{ overflowY: 'auto' }}
          >
            {fileUrl}
          </pre>
        </div>
      </div>
    );
  };

  const renderPageNaviator = () => {
    return (
      <div className="document_control row_align_items row_space_between">
        {!showAskQuestion && (
          <div
            className="row_align_items scroll_to_question_btn clickable"
            onClick={(e) => setShowAskQuestion(true)}
          >
            <div className="font-medium font16">Scroll to Show Questions</div>
            <div style={{ marginLeft: 16 }}>
              <AngleDoubleDownIcon />
            </div>
          </div>
        )}
        <div />
        <div className="actions row_align_items">
          <Tooltip
            placement="left"
            title={
              <div className="font-regular font14">
                Ask a question relating to this file
              </div>
            }
            PopperProps={{
              sx: {
                marginRight: '200px !important'
              }
            }}
          >
            <div
              className="action_item clickable row_align_items"
              style={{
                opacity: !showAskQuestion ? 1 : 0.4
              }}
              onClick={() =>
                !showAskQuestion && setShowAskQuestion((prev) => !prev)
              }
            >
              <QuestionIcon color={'#FF3E9A'} />
              <span className="font-medium font14" style={{ marginLeft: 8 }}>
                Ask Question
              </span>
            </div>
          </Tooltip>
          <Tooltip
            placement="left"
            title={
              <div className="font-regular font14">
                Create a summary of this document and ask more details through
                AI chat
              </div>
            }
            PopperProps={{
              sx: {
                marginRight: '232px !important'
              }
            }}
          >
            <div
              className="action_item clickable row_align_items"
              id="btn_summarize"
              style={{
                marginLeft: 16,
                opacity: !(
                  showFileAi ||
                  showHighlightConversation ||
                  isPartSummarizing
                )
                  ? 1
                  : 0.4
              }}
              onClick={(e) => {
                !(
                  showFileAi ||
                  showHighlightConversation ||
                  isWholeSummarizing ||
                  isPartSummarizing
                ) && loadAnswer();
              }}
            >
              {isWholeSummarizing ? (
                <LoadingCircular size={18} color="main" />
              ) : (
                <div className="row_align_items">
                  <EditPencilColoredIcon color={'#FF3E9A'} />
                  <span
                    className="font-medium font14"
                    style={{ marginLeft: 8 }}
                  >
                    Summarize
                  </span>
                </div>
              )}
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderDocumentQuestion = () => {
    return (
      <DocumentQuestion
        show={showAskQuestion}
        dumpId={dumpId}
        onClose={() => setShowAskQuestion((prev) => !prev)}
        onViewHighLight={(highlightId) => {
          setHightlightId(highlightId);
          setShowHighlightConversation(true);
        }}
      />
    );
  };

  const renderFileAiView = () => {
    return (
      <DocumentFileAiView
        isSummarizing={isPartSummarizing}
        dumpId={dumpId}
        answerData={answer}
        type={'whole'}
        highlight={highlightId}
        highlightData={undefined}
        onShowConversation={() => {
          setShowFileAi(false);
          setShowHighlightConversation(true);
        }}
        onClose={() => {
          setShowHighlightConversation(false);
          setShowFileAi(false);
        }}
      />
    );
  };

  const renderHighlightConversation = () => {
    return (
      <HighlightConversation
        highlighId={highlightId}
        onClose={() => {
          setShowHighlightConversation(false);
          setShowFileAi(false);
        }}
      />
    );
  };

  const renderScrollToDocumentViewer = () => {
    return (
      <div
        className="row_align_items scroll_to_viewer clickable"
        onClick={() => {
          onClickBackBtn();
        }}
      >
        <div className="font-medium font16">Back to Docs</div>
        <div style={{ marginLeft: 16 }}>
          <AngleDoubleUpIcon />
        </div>
      </div>
    );
  };

  return (
    <div className="document_container">
      <div
        style={
          !showBackDocBtn
            ? {}
            : {
                position: 'fixed',
                width: '100%',
                zIndex: 300
              }
        }
      >
        <MainHeader currentPage="user" />
      </div>

      {!showBackDocBtn && renderHeader()}
      {fileUrl ? (
        <>
          {!showAskQuestion && renderDocumentViewer()}
          {showFileAi && !showBackDocBtn && renderFileAiView()}
          {showHighlightConversation && renderHighlightConversation()}
          {!showBackDocBtn && renderPageNaviator()}
          {renderDocumentQuestion()}
          {showBackDocBtn && renderScrollToDocumentViewer()}
        </>
      ) : (
        <LoadingBarAnimation />
      )}
    </div>
  );
};
