import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import './index.styles.scss';

interface SuccessJoinGroupModalProps {
  show: boolean;
  groupDetail: any;
  onClose: () => void;
}

export const SuccessJoinGroupModal = ({
  show,
  groupDetail,
  onClose
}: SuccessJoinGroupModalProps) => {
  const navigate = useNavigate();

  const renderGroupDetail = () => {
    return (
      <div className="success_group_detail">
        <div className="font-bold font24" style={{ textAlign: 'center' }}>
          {groupDetail?.name}
        </div>
        <div className="success_group_desc font-regular font16">
          {groupDetail?.description}
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 18 }}
        >
          <div className="success_group_member">
            <div style={{ fontSize: 18 }}>🙋‍♂️</div>
            <div className="font-regular font14">
              {groupDetail?.members} members
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className="success_group_action">
        <div>
          <PrimaryButton
            label="View Inside"
            isLoading={false}
            onPressed={() => {
              navigate(`/group/detail/${groupDetail?.code}`);
            }}
          />
        </div>
        <div
          className="success_group_action_skip clickable"
          onClick={() => {
            onClose();
          }}
        >
          <div className="skip_txt font-medium font14">Skip for now</div>
        </div>
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={500} onClose={onClose}>
      <div className="success_join_group_modal">
        <div style={{ fontSize: 80, textAlign: 'center' }}>🎉</div>
        <div className="success_title font-bold font-24">
          Successfully joined a group
        </div>
        {renderGroupDetail()}
        {renderActions()}
      </div>
    </PrimaryModal>
  );
};
