import React from 'react';

function UserPlusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '21'}
      height={props.size ?? '18'}
      fill="none"
      viewBox="0 0 21 18"
    >
      <path
        fill="#FF3E9A"
        d="M19.75 7.5h-1v-1a1 1 0 00-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 002 0v-1h1a1 1 0 100-2zm-7.7 1.72a4.92 4.92 0 001.7-3.72 5 5 0 00-10 0 4.92 4.92 0 001.7 3.72 8 8 0 00-4.7 7.28 1 1 0 102 0 6 6 0 1112 0 1 1 0 002 0 8 8 0 00-4.7-7.28zm-3.3-.72a3 3 0 110-6 3 3 0 010 6z"
      ></path>
    </svg>
  );
}

export default UserPlusIcon;
