import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { OtherProfileScreen } from './OtherProfile';
import { OwnProfileScreen } from './OwnProfile';

export const ProfileScreen = () => {
  const param = useParams();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const userId = param.id;

  if (!userId || (userInfo && userInfo!.id === parseInt(userId))) {
    return <OwnProfileScreen />;
  } else {
    return <OtherProfileScreen userId={userId} />;
  }
};
