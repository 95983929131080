import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import EditIcon from 'assets/svgs/EditIcon';
import BookMarkIcon from 'assets/svgs/BookMarkIcon';
import AddReferenceIcon from 'assets/svgs/AddReferenceIcon';
import AnonymousIcon from 'assets/svgs/AnonymousIcon';
import HorizontalDetailIcon from 'assets/svgs/HorizontalDetailIcon';
import RegenerateIcon from 'assets/svgs/RegenerateIcon';
import ReferenceIcon from 'assets/svgs/ReferenceIcon';
import UnlockIcon from 'assets/svgs/UnlockIcon';
import {
  apiGetQAAnswers,
  apiQAEditAnswer,
  apiQABookMark,
  apiQAUnBookMark
} from 'shared/api/qa';

import './index.styles.scss';

interface DumpData {
  id: number;
  user: number;
  question: string;
  to: number;
  to_type: string;
  ai_answer: string;
  context: string;
  timestamp: string;
  answer_count: number;
  bookmark?: boolean;
}

interface CardBookMarkedProps {
  data: DumpData;
  totalCount: number;
  index: number;
  onRefresh: () => void;
}

export const CardBookMarked = ({
  data,
  index,
  totalCount,
  onRefresh
}: CardBookMarkedProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [isAnswerEditing, setIsAnswerEditing] = useState(false);
  const [answer, setAnswer] = useState('');
  const [isSavingAnswer, setIsSavingAnswer] = useState(false);
  const [isBookMarkingAnswer, setIsBookMarkingAnswer] = useState(false);
  const [isUnBookMarkingAnswer, setIsUnBookMarkingAnswer] = useState(false);

  const markNumber = totalCount - index + 1;

  const onAnswerEdit = () => {
    setIsAnswerEditing(true);
    setAnswer(data.ai_answer);
  };

  const onSaveAnswer = async () => {
    if (!isSavingAnswer) {
      setIsSavingAnswer(true);

      const res = await apiQAEditAnswer({
        question: data.id,
        answer
      });
      if (res.success) {
        data.ai_answer = answer;
        onRefresh();
      }

      setIsSavingAnswer(false);
      setIsAnswerEditing(false);
    }
  };

  const onAnswerBookMark = async () => {
    if (!isBookMarkingAnswer) {
      setIsBookMarkingAnswer(true);

      const res = await apiQABookMark({
        question: data.id,
        item_id: userInfo ? userInfo.id : 0,
        item_type: 'user'
      });
      if (res.success) {
        data.bookmark = true;
      }

      setIsBookMarkingAnswer(false);
    }
  };

  const onAnswerUnBookMark = async () => {
    if (!isUnBookMarkingAnswer) {
      setIsUnBookMarkingAnswer(true);

      const res = await apiQAUnBookMark({
        question: data.id,
        item_id: userInfo ? userInfo.id : 0,
        item_type: 'user'
      });
      if (res.success) {
        data.bookmark = false;
      }

      setIsUnBookMarkingAnswer(false);
    }
  };

  const renderTop = () => {
    return (
      <div className="top_view">
        <div className="top_view_left">
          <AnonymousIcon />
          <div className="creator_name font-medium font16">Anonymous</div>
          <div className="align-center" style={{ alignItems: 'center' }}>
            <div className="font20">🍿</div>
            <div className="font-medium font16" style={{ marginLeft: 8 }}>
              Snack
            </div>
          </div>
          <div
            className="align-center"
            style={{ alignItems: 'center', marginLeft: 24 }}
          >
            <div style={{ fontSize: 20 }}>👀</div>
            <div className="font-medium font16" style={{ marginLeft: 4 }}>
              14,354 view
            </div>
          </div>
        </div>
        <div className="top_view_right">
          <div className="created_date_pre"></div>
          <div className="created_date font-medium font14">
            {moment(data.timestamp).format('DD MMM YYYY')}
          </div>
          <HorizontalDetailIcon />
        </div>
      </div>
    );
  };

  const renderQuestion = () => {
    return (
      <div className="question_view">
        <div className="font-bold font32">{data.question}</div>
        <div className="answer_top">
          <div>
            <div className="ai_tab">
              <div className="ai_general font-bold font14">General AI</div>
              <div className="ai_group font-bold font14">Group AI</div>
            </div>
          </div>
          <div className="add_regenerate_btn clickable">
            <RegenerateIcon />
            <div className="font-medium font16" style={{ marginLeft: '10px' }}>
              Regenerate
            </div>
          </div>
        </div>
        {isAnswerEditing ? (
          <div className="edit_answer">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="font-bold font18">Edit Answer</div>
              <div
                className="font-medium font14 clickable"
                style={{ color: '#FF3E9A' }}
                onClick={() => setAnswer(data.ai_answer)}
              >
                Refresh
              </div>
            </div>
            <div className="">
              <textarea
                value={answer}
                id="answer_box_input"
                className="answer_box_input font-regular font20"
                onChange={(e) => setAnswer(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                className="cancel_btn clickable"
                onClick={() => setIsAnswerEditing(false)}
              >
                <div className="font-bold font16">Cancel</div>
              </div>
              <div className="save_answer_btn clickable" onClick={onSaveAnswer}>
                <div className="font-bold font16" style={{ color: 'white' }}>
                  {isSavingAnswer ? (
                    <LoadingCircular size={20} color="white" />
                  ) : (
                    <>Save Answer</>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="correct_answer font-regular font20">
              {data.ai_answer}
            </div>
            <div className="sub_info">
              <div className="sub_info_left">
                <div className="reference_btn clickable">
                  <ReferenceIcon />
                  <div
                    className="font-bold font14"
                    style={{ marginLeft: '10px' }}
                  >
                    References
                  </div>
                </div>
                <div className="user_icon_btn clickable">
                  <AnonymousIcon />
                  <div
                    className="font-bold font14"
                    style={{ marginLeft: '10px', marginRight: 8 }}
                  >
                    Andrea
                  </div>
                  <div className="unlock_icon">
                    <UnlockIcon />
                  </div>
                </div>
                <div className="user_icon_btn clickable">
                  <AnonymousIcon />
                  <div
                    className="font-bold font14"
                    style={{ marginLeft: '10px', marginRight: 8 }}
                  >
                    Jessica
                  </div>
                  <div className="unlock_icon">
                    <UnlockIcon />
                  </div>
                </div>
                <div className="add_reference_btn clickable">
                  <AddReferenceIcon />
                  <div
                    className="font-bold font14"
                    style={{ marginLeft: '10px' }}
                  >
                    Add reference
                  </div>
                </div>
              </div>
              <div className="sub_info_right">
                <div
                  className="edit_answer_btn clickable"
                  onClick={onAnswerEdit}
                >
                  <EditIcon color="#FF3E9A" />
                  <div
                    className="font-bold font16"
                    style={{ marginLeft: '10px' }}
                  >
                    Edit Answer
                  </div>
                </div>
                {data.bookmark ? (
                  <div
                    className="un_book_mark_btn clickable"
                    onClick={onAnswerUnBookMark}
                  >
                    {isUnBookMarkingAnswer ? (
                      <LoadingCircular size={20} color="main" />
                    ) : (
                      <>
                        <BookMarkIcon color="#FF3E9A" />
                        <div
                          className="font-bold font16"
                          style={{ marginLeft: '6px' }}
                        >
                          Bookmarked
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    className="book_mark_btn clickable"
                    onClick={onAnswerBookMark}
                  >
                    {isBookMarkingAnswer ? (
                      <LoadingCircular size={20} color="white" />
                    ) : (
                      <>
                        <BookMarkIcon color="#FFFFFF" />
                        <div
                          className="font-bold font16"
                          style={{ color: 'white', marginLeft: '6px' }}
                        >
                          Bookmark
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="card_book_makred">
      <div className="card_book_makred_left">
        <div className="card_book_makred_left_marker"></div>
        <div className="card_book_makred_left_number font-bold font24">{`#${markNumber}`}</div>
      </div>
      <div className="card_book_makred_right">
        {renderTop()}
        {renderQuestion()}
        {/* <div className="divider" />
      {renderAnswers()} */}
      </div>
    </div>
  );
};
