import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { apiGroupAskToJoin, apiDismissSuggestion } from 'shared/api/group';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';
import StarIcon from 'assets/svgs/StarIcon';
import UserFollowerIcon from 'assets/svgs/UserFollowerIcon';
import { getArrFromString } from 'shared/utils/utils';
import { useNavigate } from 'react-router-dom';

interface GroupRecommendData {
  id: number;
  name: string;
  description: string;
  url?: string;
  code: string;
  members: number;
  count: number;
  member_ids?: string;
  member_names?: string;
  member_images?: string;
  isRequested?: boolean;
  isFollowed?: string;
}
interface CardRecommendGroup {
  data: GroupRecommendData;
  onRemove: (groupId) => void;
}

export const CardGroupRecommendation = ({
  data,
  onRemove
}: CardRecommendGroup) => {
  const navigate = useNavigate();

  const [isJoining, setIsJoining] = useState(false);
  const [isDismissing, setIsDismissing] = useState(false);

  const [isRequested, setIsRequested] = useState(
    data.isRequested || data.isFollowed === 'requested' ? true : false
  );

  useEffect(() => {
    setIsRequested(
      data.isRequested || data.isFollowed === 'requested' ? true : false
    );
  }, [data]);

  const onAskToJoin = async () => {
    setIsJoining(true);

    const res = await apiGroupAskToJoin({ group: data.id });
    if (res.sucesss) {
      setIsRequested(true);
    }
    setIsJoining(false);
  };

  const onDismiss = async () => {
    setIsDismissing(true);

    const res = await apiDismissSuggestion({ group: data.id });
    if (res.success) {
      onRemove(data.id);
    }
    setIsDismissing(false);
  };

  const onViewProfile = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const memberId = useMemo(() => {
    if (data.member_ids) {
      return getArrFromString(data.member_ids, ';');
    } else {
      return [];
    }
  }, [data]);

  const memberNameTxt = useMemo(() => {
    if (data.member_names) {
      const arrTmpName = getArrFromString(data.member_names, ';');
      if (arrTmpName.length > 2) {
        return `${arrTmpName[0]}, ${arrTmpName[1]}, and ${
          arrTmpName.length - 2
        } others are part of this group`;
      } else if (arrTmpName.length == 2) {
        return `${arrTmpName[0]}, ${arrTmpName[1]} are part of this group`;
      } else {
        return `${arrTmpName[0]} is part of this group`;
      }
    } else {
      return '';
    }
  }, [data]);

  const memberImages = useMemo(() => {
    if (data.member_images) {
      const arrTmpImages: Array<string> = getArrFromString(
        data.member_images,
        ';'
      );

      return arrTmpImages.slice(0, 2);
    } else {
      return [];
    }
  }, [data]);

  const renderRequestBtn = () => {
    return (
      <div
        className="request_join_btn font-bold font14 clickable"
        onClick={onAskToJoin}
      >
        {isJoining ? (
          <LoadingCircular size={24} color="white" />
        ) : (
          <>Request to Join</>
        )}
      </div>
    );
  };

  const renderRequestedBtn = () => {
    return (
      <div className="requested_join_btn font-bold font14 clickable">
        Requested
      </div>
    );
  };

  return (
    <div className="card_group_recommendation">
      <div className="row_align_items">
        {data.url?.startsWith('https://') ? (
          <img className="group_image" src={data.url}></img>
        ) : (
          <DefaultAvatarIcon size={32} />
        )}
        <div className="font-bold font24" style={{ marginLeft: 16 }}>
          {data.name}
        </div>
      </div>
      <div
        className="font-regular font14"
        style={{ marginTop: 8, opacity: 0.6 }}
      >
        {data.description}
      </div>
      <div className="row_align_items" style={{ marginTop: 16 }}>
        <div className="row_align_items">
          {memberImages.map((item, index) => {
            return (
              <div
                className="clickable"
                onClick={() => {
                  onViewProfile(memberId[index]);
                }}
              >
                {item?.startsWith('https') ? (
                  <img
                    src={item}
                    key={`card_group_rec_member_image_${index}`}
                    style={{ width: 16, height: 16, borderRadius: 16 }}
                  />
                ) : (
                  <DefaultAvatarIcon size={16} />
                )}
              </div>
            );
          })}
        </div>
        <div className="font-medium font12" style={{ marginLeft: 8 }}>
          {memberNameTxt}
        </div>
      </div>
      <div className="row_align_items" style={{ marginTop: 16 }}>
        <div className="row_align_items">
          <UserFollowerIcon size={16} color="#FF3E9A" />
          <div className="font-medium font12" style={{ marginLeft: 4 }}>
            {data.members}
          </div>
        </div>
      </div>
      <div className="row_space_between" style={{ marginTop: 16 }}>
        {!isRequested && (
          <div
            className="dismiss_btn font-bold font14 clickable"
            onClick={onDismiss}
          >
            {isDismissing ? (
              <LoadingCircular size={24} color="main" />
            ) : (
              <>Dismiss</>
            )}
          </div>
        )}
        {isRequested || data.isFollowed === 'requested'
          ? renderRequestedBtn()
          : renderRequestBtn()}
      </div>
    </div>
  );
};
