import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import PointIcon from 'assets/svgs/PointIcon';
import React from 'react';
import moment from 'moment';

import './index.styles.scss';
import CloseIcon from 'assets/svgs/CloseIcon';

export interface InvitationData {
  id: number;
  email: string;
  points: number;
  timestamp: string;
  creator_image?: string;
  creator_name?: string;
}

interface CardPendingProps {
  data: InvitationData;
}

export const CardPending = ({ data }: CardPendingProps) => {
  const renderLeft = () => {
    return (
      <div className="row_align_items">
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img
            src={data.creator_image}
            style={{
              width: 40,
              height: 40,
              borderRadius: 40,
              objectFit: 'cover'
            }}
          />
        ) : (
          <DefaultAvatarIcon size={40} />
        )}

        <div style={{ marginLeft: 12 }}>
          <div
            className="font-regular font14"
            style={{ color: 'rgba(0, 0, 0, 0.8)' }}
          >
            {data.email}
          </div>
        </div>
      </div>
    );
  };

  const renderRight = () => {
    return (
      <div className="cancel_btn clickable">
        <CloseIcon color={'rgba(0, 0, 0, 0.3)'} />
      </div>
    );
  };

  return (
    <div className="card_pending_invitation row_space_between">
      {renderLeft()}
      {/* {renderRight()} */}
    </div>
  );
};
