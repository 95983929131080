import React from 'react';

function LastIcon(props) {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.54006 0.79C6.63302 0.696272 6.74363 0.621877 6.86548 0.571109C6.98734 0.52034 7.11805 0.494202 7.25006 0.494202C7.38207 0.494202 7.51278 0.52034 7.63464 0.571109C7.7565 0.621877 7.8671 0.696272 7.96006 0.79L10.9601 3.79C11.0538 3.88296 11.1282 3.99356 11.179 4.11542C11.2297 4.23728 11.2559 4.36799 11.2559 4.5C11.2559 4.63201 11.2297 4.76272 11.179 4.88458C11.1282 5.00644 11.0538 5.11704 10.9601 5.21L7.96006 8.21C7.8671 8.30373 7.7565 8.37812 7.63464 8.42889C7.51278 8.47966 7.38207 8.5058 7.25006 8.5058C7.11805 8.5058 6.98734 8.47966 6.86548 8.42889C6.74363 8.37812 6.63302 8.30373 6.54006 8.21C6.44633 8.11704 6.37194 8.00644 6.32117 7.88458C6.2704 7.76272 6.24426 7.63201 6.24426 7.5C6.24426 7.36799 6.2704 7.23728 6.32117 7.11542C6.37194 6.99356 6.44633 6.88296 6.54006 6.79L8.84006 4.5L6.54006 2.21C6.44633 2.11704 6.37194 2.00644 6.32117 1.88458C6.2704 1.76272 6.24426 1.63201 6.24426 1.5C6.24426 1.36799 6.2704 1.23728 6.32117 1.11542C6.37194 0.993564 6.44633 0.882963 6.54006 0.79ZM3.34006 4.5L1.00006 2.21C0.811757 2.0217 0.705969 1.7663 0.705969 1.5C0.705969 1.2337 0.811757 0.978304 1.00006 0.79C1.18836 0.601696 1.44376 0.495908 1.71006 0.495908C1.97636 0.495908 2.23176 0.601696 2.42006 0.79L5.42006 3.79C5.51379 3.88296 5.58818 3.99356 5.63895 4.11542C5.68972 4.23728 5.71586 4.36799 5.71586 4.5C5.71586 4.63201 5.68972 4.76272 5.63895 4.88458C5.58818 5.00644 5.51379 5.11704 5.42006 5.21L2.42006 8.21C2.3271 8.30373 2.2165 8.37812 2.09464 8.42889C1.97278 8.47966 1.84207 8.5058 1.71006 8.5058C1.57805 8.5058 1.44734 8.47966 1.32549 8.42889C1.20363 8.37812 1.09302 8.30373 1.00006 8.21C0.906333 8.11704 0.831939 8.00644 0.78117 7.88458C0.730401 7.76272 0.704262 7.63201 0.704262 7.5C0.704262 7.36799 0.730401 7.23728 0.78117 7.11542C0.831939 6.99356 0.906333 6.88296 1.00006 6.79L3.34006 4.5Z"
        fill={props.isDisabled ? 'rgba(0, 0, 0, 0.4)' : '#FF3E9A'}
      />
    </svg>
  );
}

export default LastIcon;
