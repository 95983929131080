import React from 'react';
import { getNormalString } from 'shared/utils/utils';

import './index.styles.scss';

interface TopicData {
  topic_id: number;
  topic: string;
  topic_emoji: string;
  count: number;
}

interface CardTopicProps {
  data: TopicData;
  isSelected: boolean;
  onClick: (selectedTopicId) => void;
}

export const CardTopic = ({ data, isSelected, onClick }: CardTopicProps) => {
  return (
    <div
      className="card_topic clickable selected_org"
      style={
        !isSelected
          ? { backgroundColor: 'transparent', boxShadow: 'none' }
          : { backgroundColor: 'white' }
      }
      onClick={() => onClick(data.topic_id)}
    >
      <div className="font-regular font20 org_box_label">
        {data.topic_emoji + '  ' + data.topic}
      </div>
    </div>
  );
};
