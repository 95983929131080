import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import StarIcon from 'assets/svgs/StarIcon';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import UCoinIcon from 'assets/svgs/UCoinsIcon';
import DocumentLayoutIcon from 'assets/svgs/DocumentLayoutIcon';
import { apiQAAnswer, apiQABookMark } from 'shared/api/qa';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import RobotIcon from 'assets/svgs/RobotIcon';

import './index.styles.scss';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import FileIcon from 'assets/svgs/FileIcon';
import MicIcon from 'assets/svgs/MicIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import { useNavigate } from 'react-router-dom';
interface AnswerBoxProps {
  answer: string;
  setAnswer: any;
  setIsEditAnswer: any;
  isEditAnswer: boolean;
  selectedUserRefs: Array<any>;
  selectedFileRefs: Array<any>;
  questionDetail: any;
  setTab: any;
  onAddAnswer: () => void;
  showSnackBar: () => void;
}
export const AnswerBox = ({
  answer,
  setAnswer,
  setIsEditAnswer,
  isEditAnswer,
  selectedUserRefs,
  selectedFileRefs,
  questionDetail,
  setTab,
  onAddAnswer,
  showSnackBar
}: AnswerBoxProps) => {
  const navigate = useNavigate();

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isBookMarkingAnswer, setIsBookMarkingAnswer] = useState(false);

  const onPostAnswer = async () => {
    if (!isBookMarkingAnswer) {
      setIsBookMarkingAnswer(true);

      const res = await apiQAAnswer({
        bookmark: questionDetail.bookmark_id,
        answer: answer,
        is_anonymous: isAnonymous,
        media_type: 'text'
      });

      if (res.sucess) {
        setAnswer('');
        setIsBookMarkingAnswer(false);
        setTab('detail');
        onAddAnswer();
        showSnackBar();
      }
    }
  };

  const onViewProfile = () => {
    navigate(`/profile/${questionDetail.creator_id}`);
  };

  const renderEditAnswer = () => {
    return (
      <textarea
        value={answer}
        className="font-regular font20 answer_text_area"
        onChange={(e) => setAnswer(e.target.value)}
        placeholder="Write your answer here..."
      />
    );
  };

  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 28, height: 28 }}>
        {type === 'pdf' ? (
          <PdfFileIcon size={28} />
        ) : type === 'audio' ? (
          <MicIcon size={24} color="#FF3E9A" opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={28} color="#FF3E9A" opacity={1} />
        ) : (
          <FileIcon />
        )}
      </div>
    );
  };

  const renderReferenceBar = () => {
    return (
      <div className="reference_status_bar">
        <div className="document_layout_cirfle">
          <DocumentLayoutIcon color="#bdbdbd" />
        </div>
        {selectedUserRefs.map((item, index) => {
          return (
            <div className="selected_reference_bar">
              <div className="reference_item1">
                <img src={item.creator_image} className="user_avatar" />
              </div>
            </div>
          );
        })}
        {selectedFileRefs.map((item, index) => {
          return (
            <div className="selected_reference_bar">
              <div className="reference_item1">
                {renderFileIcon(item.dump_type)}
                <div
                  className="user_name font-regular font14"
                  style={{
                    maxWidth: 150,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {item.title ?? item.filename}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderNormalAnswer = () => {
    return (
      <div className="font-regular font20 normal_answer">
        {answer}
        <div
          className=" add_your_edit"
          onClick={() => {
            setIsEditAnswer(true);
          }}
        >
          +Add your edit
        </div>
      </div>
    );
  };

  const renderAddAnswerbar = () => {
    return (
      <div className="add_answer_bar">
        <div className="left_side">
          <div className="question_info">
            <div className="snack">
              <div className="font16 question_info_icon">👀</div>
              <div className="font14 question_info_text">
                {`${questionDetail.views} view${
                  questionDetail.views > 1 ? 's' : ''
                }`}
              </div>
            </div>
            <div className="snack">
              <div className="font16 question_info_icon">💬</div>
              <div className="font14 question_info_text">
                {`${questionDetail.answer_count} answer${
                  questionDetail.answer_count > 1 ? 's' : ''
                }`}
              </div>
            </div>
          </div>
          <div className="question_points">
            <UCoinIcon />
            &nbsp;
            {`${questionDetail.points} pt${
              questionDetail.points > 1 ? 's' : ''
            }`}
          </div>
        </div>
        <div className="right_side">
          <div className="question_date font14">
            {moment(questionDetail.timestamp).format('DD MMM yyyy').toString()}
          </div>
        </div>
      </div>
    );
  };

  const renderQuestion = () => {
    return (
      <div
        className="question_text font-bold font24"
        style={{ marginTop: '24px' }}
      >
        {questionDetail?.question}
      </div>
    );
  };

  const renderQuestionBar = () => {
    return (
      <div className="answer_add_top">
        <div className="add_answer_box_top">
          {renderCreatorInfo()}
          {renderPlaceInfo()}
        </div>

        {renderQuestion()}
        {renderAddAnswerbar()}
      </div>
    );
  };

  const renderCreatorInfo = () => {
    if (questionDetail?.is_anonymous == 1) {
      return (
        <div className="align-vertical-center">
          <DefaultAvatarIcon size={24} />
          <div
            className="font-medium font16 user_name"
            style={{ marginLeft: 8 }}
          >
            {'Anonymous'}
          </div>
        </div>
      );
    } else {
      if (questionDetail?.creator_id) {
        return (
          <div
            className="align-vertical-center clickable"
            onClick={onViewProfile}
          >
            {questionDetail?.creator_image ? (
              <img
                className="creator_image"
                src={questionDetail?.creator_image}
                width="24"
                height="24"
                style={{ borderRadius: 24 }}
              ></img>
            ) : (
              <DefaultAvatarIcon size={24} />
            )}

            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {questionDetail?.creator_name
                ? questionDetail?.creator_name
                : 'Anonymous'}
            </div>
          </div>
        );
      } else {
        return (
          <div className="align-vertical-center">
            <RobotIcon />
            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {'General AI'}
            </div>
          </div>
        );
      }
    }
  };

  const renderPlaceInfo = () => {
    if (
      !questionDetail?.ai_type ||
      questionDetail?.ai_type === 'user' ||
      questionDetail?.ai_type === 'general' ||
      !questionDetail?.ai_id
    ) {
      return <div />;
    } else {
      return (
        <div className="align-vertical-center">
          <div className="font-medium font16 in_label">in</div>
          <img
            className="creator_image"
            src={questionDetail?.ai_image}
            width="24"
            height="24"
            style={{ borderRadius: 24 }}
          ></img>
          <div
            className="font-medium font16 org_name"
            style={{ marginLeft: 8 }}
          >
            {questionDetail?.ai_name}
          </div>
          <div className="font-medium font16 posted_question">
            posted a new question
          </div>
        </div>
      );
    }
  };

  const renderBottomBar = () => {
    return (
      <div className="add_answer_bottom_bar">
        <div />
        <div className="add_answer_bottom_bar_btns">
          <div className="add_answer_bottom_bar_referneces">
            <div
              className={
                !isAnonymous
                  ? 'user_star_btn_selected clickable'
                  : 'user_star_btn clickable'
              }
              key={`card_ref_user_1`}
              onClick={() => setIsAnonymous(false)}
            >
              {userInfo?.image === '' ? (
                <DefaultAvatarIcon size={24} />
              ) : (
                <img src={userInfo?.image} className="user_avatar" />
              )}
              <div className="font-medium font14">{userInfo?.name}</div>
            </div>
            <div
              className={
                isAnonymous
                  ? 'user_star_btn_selected clickable'
                  : 'user_star_btn clickable'
              }
              key={`card_ref_user_2`}
              onClick={() => setIsAnonymous(true)}
            >
              <DefaultAvatarIcon size={24} />
              <div className="font-medium font14">Anonymous</div>
            </div>
          </div>
          <div
            className="add_answer_btn clickable"
            onClick={() => {
              onPostAnswer();
            }}
          >
            <div className="font-bold font16">
              {isBookMarkingAnswer ? (
                <LoadingCircular color="white" size={16} />
              ) : (
                'Post Answer'
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="answer_add_box">
      {renderQuestionBar()}
      <div className="font-bold font24">Add Answer</div>
      {!isEditAnswer ? renderNormalAnswer() : renderEditAnswer()}
      {selectedUserRefs ? renderReferenceBar() : <></>}
      <div className="add_answer_detail_box">{renderBottomBar()}</div>
    </div>
  );
};
