import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { apiOrgRequestJoin } from 'shared/api/org';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

interface CardOrgProps {
  data: any;
}

export const CarOrg = ({ data }: CardOrgProps) => {
  const [isFollowed, setIsFollowed] = useState<'true' | 'false' | 'requested'>(
    'false'
  );
  const [isRequesting, setIsRequesting] = useState(false);

  useEffect(() => {
    setIsFollowed(data.isFollowed);
  }, [data]);

  const onRequestJoin = async (orgId) => {
    setIsRequesting(true);
    const res = await apiOrgRequestJoin({ organisation: orgId });
    if (res.success) {
      setIsFollowed(res.isFollowed);
      setIsRequesting(false);
    }
    setIsRequesting(false);
  };

  const requestTypeClassName = {
    true: 'joined_btn font-bold font14 ',
    false: 'request_join_btn font-bold font14 text-white clickable',
    requested: 'requested_join_btn font-bold font14'
  };

  const requestJoinBtnText = {
    true: 'Joined',
    false: 'Request to Join',
    requested: 'Requested'
  };

  return (
    <div className="card_org">
      <div className="org_image">
        <img src={data.image} className="org_box_icon" />
      </div>
      <div className="org_info">
        <div className="first_line">
          <div className="org_title font-bold font22">{data.name}</div>
          {isRequesting ? (
            <div className="requested_join_btn font-bold font14 text-white clickable">
              <LoadingCircular color="main" size={16} />
            </div>
          ) : (
            <div
              className={
                isFollowed === 'true'
                  ? requestTypeClassName['true']
                  : isFollowed === 'false'
                  ? requestTypeClassName['false']
                  : requestTypeClassName['requested']
              }
              onClick={() => onRequestJoin(data.id)}
              style={{ whiteSpace: 'nowrap' }}
            >
              {isFollowed === 'true'
                ? requestJoinBtnText['true']
                : isFollowed === 'false'
                ? requestJoinBtnText['false']
                : requestJoinBtnText['requested']}
            </div>
          )}
        </div>
        <div className="second_line">
          <div className="members_count">
            🙋&nbsp;{data.member_number} members
          </div>
        </div>
      </div>
    </div>
  );
};
