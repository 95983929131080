import CalendarIcon from 'assets/svgs/CalendarIcon';
import React from 'react';
import { CardPointHistory } from './CardPointHistory';

import './index.styles.scss';

export const PointHistory = ({ data }) => {
  const renderHeader = () => {
    return (
      <div className="point_history_header">
        <div className="font-bold font24">Point History</div>
        {/* {renderCalender()} */}
      </div>
    );
  };

  const renderCalender = () => {
    return (
      <div className="calendar_view clickable">
        <CalendarIcon />
        <div className="font-regular font14" style={{ marginLeft: 8 }}>
          20 May - 21 June 2022
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="point_history_body">
        {data.map((item, index) => {
          return (
            <CardPointHistory data={item} key={`point_history_card_${index}`} />
          );
        })}
      </div>
    );
  };

  return (
    <div className="point_history">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
