import React from 'react';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import './index.styles.scss';
import CloseIcon from 'assets/svgs/CloseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import { StatementData } from 'components/Main/Organisation/OrgHome/components/TopDidYouKnow/CardDidYouKnow';
import { useNavigate } from 'react-router-dom';

interface StatementTextModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: (groupData) => void;
  selectedStatement?: StatementData;
}

export const StatementTextModal = ({
  show,
  onClose,
  onSuccess,
  selectedStatement
}: StatementTextModalProps) => {
  const navigate = useNavigate();

  const openProfile = () => {
    onClose();
    navigate(`/profile/${selectedStatement?.creator_id}`);
  };

  const renderBody = () => {
    return (
      <>
        <div className="statement_text">
          {selectedStatement?.text.startsWith('Did you know that ')
            ? selectedStatement?.text
                .replace('Did you know that ', '')
                .replace('?', '')
            : selectedStatement?.text.startsWith('Did you know that')
            ? selectedStatement?.text
                .replace('Did you know that', '')
                .replace('?', '')
            : selectedStatement?.text
                .replace('Did you know', '')
                .replace('?', '')}
        </div>
        <div className="statement_bottome">
          <div
            className="statement_bottome_user clickable"
            onClick={openProfile}
          >
            <div className="statement_bottome_user_avatar">
              {selectedStatement?.creator_image &&
              selectedStatement?.creator_image.startsWith('https') ? (
                <img
                  src={selectedStatement.creator_image}
                  style={{ width: 24, height: 24, borderRadius: 24 }}
                />
              ) : (
                <DefaultAvatarIcon size={24} />
              )}
            </div>
            <div className="statement_bottome_user_name font16 font-normal">
              {selectedStatement?.creator_name}
            </div>
          </div>
          <div
            className="statement_bottome_btns clickable"
            onClick={() => {
              onSuccess(true);
            }}
          >
            <div className="statement_bottome_btns_share ">
              <ShareIcon />
            </div>
            <div>Share</div>
          </div>
        </div>
      </>
    );
  };
  return (
    <PrimaryModal show={show} width={510} onClose={onClose}>
      <div className="modal_create_group">
        <div className="modal_create_group_header">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon opacity={0.4} />
          </div>
          <div className="title font-bold font20">Did you know</div>
        </div>
        <div className="modal_create_group_body">{renderBody()}</div>
      </div>
    </PrimaryModal>
  );
};
