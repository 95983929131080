import CoinIcon from 'assets/svgs/CoinIcont';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetUserPoint } from 'shared/api/user';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';

import './index.styles.scss';
import { PointHistory } from './PointHistory';

export const Point = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [arrData, setArrData] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [availablePoint, setAvailablePoint] = useState(0);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const res = await apiGetUserPoint();

    if (res.success) {
      setAvailablePoint(res.energy);
      setArrData(res.data);
    }
  };

  const renderHeader = () => {
    return (
      <div className="point_header">
        <div className="clickable" onClick={() => navigate(-1)}>
          <RoundBackIcon />
        </div>
        <div style={{ marginLeft: 24 }}>
          <div className="font-bold font40">My Points</div>
          <div
            className="font-regular font20"
            style={{ color: 'rgba(0, 0, 0, 0.6)' }}
          >
            Earn from activities and exchange to get rewards
          </div>
        </div>
      </div>
    );
  };

  const renderAvailablePoint = () => {
    return (
      <div className="point_available">
        <div className="point_available_icon">
          <CoinIcon />
        </div>
        <div
          className="font-regular font16"
          style={{ marginLeft: 16, flex: 1 }}
        >
          Available Points
        </div>
        <div className="font-bold font18" style={{ marginRight: 16 }}>
          {availablePoint} pts
        </div>
      </div>
    );
  };

  const renderPointHistory = () => {
    return <PointHistory data={arrData} />;
  };

  return (
    <div>
      <MainHeader />
      <div className="point_container">
        {renderHeader()}
        {renderAvailablePoint()}
        {renderPointHistory()}
      </div>
    </div>
  );
};
