import React from 'react';
import './index.styles.scss';
import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import { useNavigate } from 'react-router-dom';
interface NavigationBarProps {
  setTab: any;
}
export const NavigationBar = ({ setTab }: NavigationBarProps) => {
  const navigate = useNavigate();
  const onHome = () => {
    navigate(-1);
  };

  return (
    <div className="add_answer_right_panel_tab">
      <div
        className={`add_answer_right_panel_tab_item font-bold font24 clickable`}
        onClick={() => {
          onHome();
        }}
      >
        School
      </div>
      <div style={{ marginLeft: 20 }}>
        <AngleRightIcon />
      </div>
      <div
        className={`add_answer_right_panel_tab_item font-bold font24 clickable`}
        style={{ marginLeft: 20 }}
        onClick={() => setTab('detail')}
      >
        Question Detail
      </div>
      <div style={{ marginLeft: 20 }}>
        <AngleRightIcon />
      </div>
      <div
        className={`detail_right_panel_tab_item_selected font-bold font24 clickable`}
        style={{ marginLeft: 20 }}
      >
        Add Answer
      </div>
    </div>
  );
};
