import CopyIcon from 'assets/svgs/CopyIcon';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import { MyDraft } from 'components/Main/Home/MyDraft';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import {
  apiAcceptGroupInvitation,
  apiGetGroupDetail,
  apiGroupAskToJoin,
  apiRejectGroupInvitation
} from 'shared/api/group';

import './index.styles.scss';
import EnvelopStarIcon from 'assets/svgs/EnvelopStarIcon';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import LockIcon from 'assets/svgs/LockIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface GroupDetailProps {
  groupId: any;
  setGroupId: any;
}

export const GroupDetail = ({ groupId, setGroupId }: GroupDetailProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const groupCode = params.code;

  const [groupDetail, setGroupDetail] = useState<any>(null);
  const [isLoadedDetail, setIsLoadedDetail] = useState(false);
  const [isCreator, setIsCreator] = useState(false);

  const [isRejecting, setIsRejecting] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [isNotAuth, setIsNotAuth] = useState(false);
  const [status, setStatus] = useState<any>('false');
  const [canDump, setCanDump] = useState(false);

  const [pendingCount, setPendingCount] = useState(0);

  useEffect(() => {
    loadGroupDatil();
  }, []);

  const loadGroupDatil = async () => {
    setIsLoadingDetail(true);
    const res = await apiGetGroupDetail({ group: groupCode });
    setIsLoadedDetail(true);

    if (res.success) {
      setGroupDetail(res.data);
      setGroupId(res.data.id);
      setIsCreator(res.is_creator);
      setPendingCount(res.request_count);
      setStatus(res.data.isFollowed);
      setCanDump(res.can_dump);
    } else {
      setIsNotAuth(true);
    }

    setIsLoadingDetail(false);
  };

  const onAcceptInvitation = async () => {
    if (!isAccepting && !isRejecting) {
      setIsAccepting(true);

      const res = await apiAcceptGroupInvitation({ group: groupId });
      if (res.success) {
        setStatus('true');
      }
      setIsAccepting(false);
    }
  };

  const onRejectInvitation = async () => {
    if (!isAccepting && !isRejecting) {
      setIsRejecting(true);

      const res = await apiRejectGroupInvitation({ group: groupId });
      if (res.success) {
        navigate(`/group`);
      }
      setIsRejecting(false);
    }
  };

  const onAskToJoin = async () => {
    if (status === 'false') {
      setIsJoining(true);
      const res = await apiGroupAskToJoin({ group: groupDetail.id });
      if (res.sucesss) {
        setStatus('requested');
      }
      setIsJoining(false);
    }
  };

  const onViewGroupMember = () => {
    navigate(`/group/member/${groupId}`);
  };

  const onCopyCode = () => {
    navigator.clipboard.writeText(groupDetail.code);

    showToast('success', 'Successfully copied!');
  };

  const renderHeader = () => {
    return (
      <div className="group_detail_header">
        <div className="clickable" onClick={() => navigate('/group')}>
          <RoundBackIcon />
        </div>
        {groupDetail && (
          <div>
            <img className="group_detail_header_image" src={groupDetail.url} />
          </div>
        )}
        {groupDetail && (
          <div className="header_info">
            <div className="font-bold font40">{groupDetail.name}</div>
            <div className="font-regular font20">{groupDetail.description}</div>
          </div>
        )}
        {groupDetail && (
          <div
            className="member_view clickable"
            onClick={() => {
              isCreator && onViewGroupMember();
            }}
          >
            <div className="member_view_icon">
              <div style={{ fontSize: 34 }}>🙋‍♂️</div>
            </div>
            <div className="member_view_info">
              <div className="member_view_info_title font-bold font14">
                Members
              </div>
              <div className="font-bold font20">
                {`${groupDetail.members}`}{' '}
                {`${groupDetail.members > 1 ? 'members' : 'member'}`}
              </div>
            </div>
            {isCreator && pendingCount > 0 && renderPendingCount()}
          </div>
        )}
        {groupDetail && (
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Send this group code to others to easily find your group
              </div>
            }
          >
            <div className="member_code clickable" onClick={onCopyCode}>
              <div className="member_code_info">
                <div className="member_code_info_title font-bold font14">
                  Group Code
                </div>
                <div className="font-bold font20">{`#${groupDetail.code}`}</div>
              </div>
              <div className="member_code_icon">
                <CopyIcon />
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const renderPendingCount = () => {
    return (
      <div className="pendingCount">
        <div className="font-bold font16" style={{ color: 'white' }}>
          {pendingCount}
        </div>
      </div>
    );
  };

  const renderRejectAcceptButtons = () => {
    return (
      <div className="card_group_invitation_bottom">
        <div className="dismiss_btn clickable" onClick={onRejectInvitation}>
          <div className="request_btn clickable" onClick={onAcceptInvitation}>
            {isAccepting ? (
              <LoadingCircular size={20} color="white" />
            ) : (
              <div className="font-bold font14" style={{ color: 'white' }}>
                Accept
              </div>
            )}
          </div>
          {isRejecting ? (
            <LoadingCircular size={20} color="main" />
          ) : (
            <div className="font-bold font14">Decline</div>
          )}
        </div>
      </div>
    );
  };

  const renderEncryptView = () => {
    return (
      <div className="encrypt_view">
        <div className="row_align_items">
          <LockIcon size={20} />
          <div className="font-regular font20" style={{ marginLeft: 6 }}>
            All your links and all your content is encrypted and no one except
            you can see it
          </div>
        </div>
      </div>
    );
  };

  if (isLoadedDetail) {
    if (!isLoadingDetail && status !== 'true') {
      return (
        <div className="group_not_auth">
          <div style={{ fontSize: 96 }}>🚧</div>
          <div className="font-bold font24">
            {status === 'invited'
              ? 'If you want to see what’s inside, accept the invite below'
              : 'Oh no... you are not part of this group (yet)'}
          </div>
          <div
            className="font-regular font16"
            style={{ color: 'rgba(0, 0, 0, 0.6)' }}
          >
            {status === 'invited'
              ? `If you want to see what's inside, request to join below and wait for approval`
              : `If you want to see what's inside, accept the invite below`}
          </div>
          {status === 'invited' ? (
            renderRejectAcceptButtons()
          ) : (
            <div
              className="request_join clickable"
              style={
                status === 'requested'
                  ? {
                      backgroundColor: '#ffe5f2'
                    }
                  : {}
              }
              onClick={onAskToJoin}
            >
              {isJoining ? (
                <LoadingCircular size={24} color="white" />
              ) : (
                <div className="row_align_items">
                  <EnvelopStarIcon />
                  <div
                    className="request_txt font-bold font14"
                    style={
                      status === 'requested'
                        ? {
                            color: 'black'
                          }
                        : {}
                    }
                  >
                    {status === 'requested' ? 'Requested' : 'Request to Join'}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="group_detail">
          {renderEncryptView()}
          {groupId && renderHeader()}
          {groupId && (
            <MyDraft
              filters={[]}
              canDump={canDump}
              type="group"
              groupId={parseInt(groupId ?? '')}
            />
          )}
        </div>
      );
    }
  } else {
    return (
      <div
        className="group_detail"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <LoadingBarAnimation />
      </div>
    );
  }
};
