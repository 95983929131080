import CloseIcon from 'assets/svgs/CloseIcon';
import React, { useEffect, useState } from 'react';
import { apiGetQATagList, apiQATag } from 'shared/api/qa';
import Columns from 'react-columns';
import InfiniteScrollWrapper from 'components/Common/WrapperScroll';
import './index.styles.scss';
import { Tag } from 'shared/ui-kit/Tag';
import PlusIcon from 'assets/svgs/PlusIcon';
import { getArrFromString } from 'shared/utils/utils';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { apiExtensionTag } from 'shared/api/extension';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

interface AddTagModalProps {
  show: boolean;
  itemId?: any;
  itemType?: any;
  isExtension?: boolean;
  onClose: () => void;
  onSuccess?: (itemData, arrSelected) => void;
  selectedFile: any;
  positionX: number | undefined;
}

export const AddTagModal = ({
  show,
  itemId,
  itemType,
  isExtension = false,
  onClose,
  onSuccess,
  selectedFile,
  positionX
}: AddTagModalProps) => {
  const [category, setCategory] = useState<
    'all' | 'user_tag' | 'group' | 'organisation'
  >('all');
  const [tagList, setTagList] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [arrSelectedTag, setArrSelectedTag] = useState<Array<any>>([]);
  const [isAddingTag, setIsAddingTag] = useState(false);

  useEffect(() => {
    if (show) {
      if (selectedFile) getSelectedTagsList();
    } else {
      setArrSelectedTag([]);
    }
  }, [show, selectedFile]);

  const getSelectedTagsList = () => {
    const arrTmpData: Array<any> = [];
    const arrTagsId = getArrFromString(selectedFile.tag_ids, ',');
    const arrTagsType = getArrFromString(selectedFile.tag_types, ',');
    const arrTagsName = getArrFromString(selectedFile.tag_names, ',');
    const arrTagsImage = getArrFromString(selectedFile.tag_images, ',');

    arrTagsId.map((item, index) => {
      arrTmpData.push({
        id: item,
        type: arrTagsType[index],
        name: arrTagsName[index],
        image: arrTagsImage[index]
      });
    });

    setArrSelectedTag(arrTmpData);
    loadTagList(true);
  };

  const loadTagList = async (init = true, category = 'all') => {
    if (init) {
      const res = await apiGetQATagList({
        item_id: itemId,
        item_type: itemType,
        limit: 20,
        offset: 0,
        tagType: category == 'all' ? null : category
      });
      if (res.success) {
        setTagList(res.data);
        setHasMore(res.hasMore);
        setLastId(res.lastId);
      }
    } else {
      if (hasMore) {
        const res = await apiGetQATagList({
          item_id: itemId,
          item_type: itemType,
          limit: 10,
          offset: lastId,
          tagType: category == 'all' ? null : category
        });
        if (res.success) {
          setTagList((prev) => [...prev, ...res.data]);
          setHasMore(res.hasMore);
          setLastId(res.lastId);
        }
      }
    }
  };

  const handleDone = async () => {
    setIsAddingTag(true);

    if (isExtension) {
      const res = await apiExtensionTag({
        browser: selectedFile.id,
        tags: arrSelectedTag
      });
      console.log({
        browser: selectedFile.id,
        tags: arrSelectedTag
      });
      console.log({ res });
      if (res.success) {
        onSuccess ? onSuccess(selectedFile, arrSelectedTag) : onClose();
        setArrSelectedTag([]);
      }
    } else {
      const res = await apiQATag({
        dump: selectedFile.id,
        tags: arrSelectedTag
      });
      if (res.success) {
        onSuccess ? onSuccess(selectedFile, arrSelectedTag) : onClose();
        setArrSelectedTag([]);
      }
    }

    setIsAddingTag(false);
  };

  const onNext = () => {
    loadTagList(false);
  };

  const onChangeCategory = (value) => {
    setCategory(value);
    loadTagList(true, value);
  };

  const onClickedTag = (tag) => {
    if (
      arrSelectedTag.some((item) => {
        return item.id == tag.id && item.type === tag.type;
      })
    ) {
      setArrSelectedTag((prev) => {
        return arrSelectedTag.filter(
          (item, index) => !(item.id == tag.id && item.type === tag.type)
        );
      });
    } else {
      setArrSelectedTag((prev) => [...prev, tag]);
    }
  };

  const renderTagCategory = () => {
    return (
      <div className="modal_add_tag_body_nav">
        <div
          className={
            category == 'all'
              ? 'modal_add_tag_body_nav_item_selected font-bold font14'
              : 'modal_add_tag_body_nav_item font14'
          }
          onClick={() => onChangeCategory('all')}
        >
          All
        </div>
        <div
          className={
            category == 'user_tag'
              ? 'modal_add_tag_body_nav_item_selected font-bold font14'
              : 'modal_add_tag_body_nav_item font14'
          }
          onClick={() => onChangeCategory('user_tag')}
        >
          Tags
        </div>
        <div
          className={
            category == 'group'
              ? 'modal_add_tag_body_nav_item_selected font-bold font14'
              : 'modal_add_tag_body_nav_item font14'
          }
          onClick={() => onChangeCategory('group')}
        >
          Groups
        </div>
        <div
          className={
            category == 'organisation'
              ? 'modal_add_tag_body_nav_item_selected font-bold font14'
              : 'modal_add_tag_body_nav_item font14'
          }
          onClick={() => onChangeCategory('organisation')}
        >
          Schools
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div
        style={{ marginTop: '24px', overflowY: 'auto', height: '240px' }}
        id="modal_tag_list"
      >
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={tagList.length}
          functionNext={onNext}
          hasOrgsMore={hasMore}
          parentId="modal_tag_list"
        >
          {tagList.length > 0 ? (
            <Columns columns={2} gap={'8px'} rootStyles={{ paddingBottom: 10 }}>
              {tagList.map((item, index) => {
                return (
                  <div
                    style={{ marginBottom: '12px' }}
                    key={`draft_answer_tags_${index}`}
                  >
                    <Tag
                      isSelected={arrSelectedTag.some((val) => {
                        return (
                          val.id == item.item_id && val.type === item.item_type
                        );
                      })}
                      isEditMode={false}
                      data={item}
                      type="modal"
                      key={`draft_answer_tags_${index}`}
                      onClicked={(data) => {
                        onClickedTag({
                          id: data.item_id,
                          type: data.item_type,
                          name: data.name,
                          image: data.image
                        });
                      }}
                    />
                  </div>
                );
              })}
            </Columns>
          ) : (
            <></>
          )}
        </InfiniteScrollWrapper>
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={500} onClose={onClose}>
      <div className="modal_add_tag">
        <div className="modal_add_tag_header">
          <div className="modal_add_tag_header_left">
            <div className="clickable" onClick={onClose}>
              <CloseIcon />
            </div>
            <div className="font-bold font18" style={{ marginLeft: 16 }}>
              Set Tag
            </div>
          </div>
          <div
            className="clickable font16 font-bold modal_add_tag_header_right"
            onClick={handleDone}
          >
            {isAddingTag ? <LoadingCircular color="main" size={16} /> : 'Done'}
          </div>
        </div>
        <div className="modal_add_tag_body">
          {renderTagCategory()}
          {renderBody()}
        </div>
      </div>
    </PrimaryModal>
  );
};
