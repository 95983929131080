import React from "react";

function AnswerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props.color}
        d="M10 7a1 1 0 100 2 1 1 0 000-2zm7-7H3a3 3 0 00-3 3v10a3 3 0 003 3h11.59l3.7 3.71A1 1 0 0019 20a.84.84 0 00.38-.08A1 1 0 0020 19V3a3 3 0 00-3-3zm1 16.59l-2.29-2.3A1 1 0 0015 14H3a1 1 0 01-1-1V3a1 1 0 011-1h14a1 1 0 011 1v13.59zM6 7a1 1 0 100 2 1 1 0 000-2zm8 0a1 1 0 100 2 1 1 0 000-2z"
      ></path>
    </svg>
  );
}

export default AnswerIcon;
