import React from "react";

function AlertCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <rect
        width="40"
        height="40"
        fill="#2BBC3A"
        fillOpacity="0.1"
        rx="20"
      ></rect>
      <path
        fill="#2BBC3A"
        d="M28.388 14.012a1.252 1.252 0 00-1.775 0L17.3 23.337l-3.912-3.925a1.279 1.279 0 00-1.775 1.838l4.8 4.8a1.248 1.248 0 001.775 0l10.2-10.2a1.251 1.251 0 000-1.838z"
      ></path>
    </svg>
  );
}

export default AlertCheckIcon;