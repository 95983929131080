import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import { LinkPreviewTopFilter } from 'components/Main/Home/MyDraft/LinkPreview/TopFilter';
import React, { useEffect, useState } from 'react';
import Columns from 'react-columns';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetContentLinks } from 'shared/api/content';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { CardAllSharedLink } from './CardAllSharedLink';

import './index.styles.scss';

interface AllSharedLinksProps {
  selectedOrg?: any;
  onBack?: () => void;
}

export const AllSharedLink = ({ selectedOrg, onBack }: AllSharedLinksProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [searchText, setSearchText] = useState('');
  const [totalLinkCount, setTotalLinkCount] = useState(0);
  const [selectedToics, setSelectedTopics] = useState('');

  const [arrPreview, setArrPreview] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id, selectedToics, searchText]);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiGetContentLinks({
        query: searchText,
        item_id: id,
        item_type: 'organisation',
        topics: selectedToics === '' ? undefined : selectedToics,
        received: true,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrPreview(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }
    } else {
      if (!isLoadingPreview && hasMore) {
        const res = await apiGetContentLinks({
          query: searchText,
          item_id: id,
          item_type: 'organisation',
          topics: selectedToics === '' ? undefined : selectedToics,
          received: true,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrPreview((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const onChangedTopicFilter = (topics) => {
    setSelectedTopics(topics + '');
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div className="clickable" onClick={onClickBack}>
            <RoundBackIcon />
          </div>
          <div className="font-bold font26" style={{ marginLeft: 8 }}>
            All Shared Links within Schools
          </div>
        </div>

        <SearchBox
          onSearch={setSearchText}
          width={'300px'}
          height={'48px'}
          fontSize={14}
        />
      </div>
    );
  };

  const renderTopFilter = () => {
    return (
      <div
        className="row_align_items row_space_between"
        style={{ marginTop: 16 }}
      >
        <LinkPreviewTopFilter
          isReceived={true}
          isBot={false}
          item_id={selectedOrg?.id}
          item_type={'organisation'}
          totalLinkCount={totalLinkCount}
          onChangedSelection={onChangedTopicFilter}
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="shared_all_link_body">
        <Columns
          columns={4}
          gap={'16px'}
          rootStyles={{ paddingBottom: 10, width: '100%' }}
        >
          {arrPreview.map((item, index) => {
            return (
              <CardAllSharedLink
                key={`card_all_shared_link_${index}`}
                data={item}
              />
            );
          })}
        </Columns>
      </div>
    );
  };

  return (
    <div className="shared_all_links">
      {renderHeader()}
      {renderTopFilter()}
      {renderBody()}
    </div>
  );
};
