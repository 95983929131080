import EditIcon from 'assets/svgs/EditIcon';
import RegenerateIcon from 'assets/svgs/RegenerateIcon';
import SearchIcon from 'assets/svgs/SearchIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiGetQAAsk, apiQABookMark, apiQAEditAnswer } from 'shared/api/qa';
import useOnScreen from 'shared/hooks/useOnScreen';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { CardFileAiAnswer } from '../AskedQuestion/CardFileAIAnswer';

import './index.styles.scss';

interface DocumentAskQuestionProps {
  dumpId: string;
  onAddQuestion: (newQuestion) => void;
}

export const DocumentAskQuestion = ({
  dumpId,
  onAddQuestion
}: DocumentAskQuestionProps) => {
  const editAnswerRef = useRef<HTMLDivElement>(null);
  const normalAnswerRef = useRef<HTMLDivElement>(null);
  const isVisiableEditAnswer = useOnScreen(editAnswerRef);

  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [question, setQuestion] = useState('');
  const [isFindingAnswer, setIsFindingAnswer] = useState(false);
  const [isPosting, setIsPosting] = useState(false);

  const [answer, setAnswer] = useState<any>(null);
  const [postType, setPostType] = useState<'user' | 'anonymous'>('user');

  const [isAnswerEditing, setIsAnswerEditing] = useState(false);
  const [isSavingAnswer, setIsSavingAnswer] = useState(false);
  const [editAnswerTxt, setEditAnswerTxt] = useState('');
  const [isRefreshingQuestion, setIsRefreshingQuestion] = useState(false);

  const onFindAnswer = async () => {
    setIsFindingAnswer(true);

    if (!isPosting && !isFindingAnswer && question) {
      setIsFindingAnswer(true);
      const userId = localStorage.getItem('userId') || '';

      const res = await apiGetQAAsk({
        question,
        to: userId,
        to_type: 'user',
        dump_string: dumpId
      });

      if (res.success) {
        setAnswer(res.data);
      }

      setIsFindingAnswer(false);
    }
  };

  const onPostAnswer = async () => {
    if (!isPosting && !isFindingAnswer) {
      setIsPosting(true);

      const res = await apiQABookMark({
        question: answer.id,
        item_id: dumpId,
        item_type: 'file'
      });

      if (res.success) {
        onAddQuestion({
          ...answer,
          top_answer_id: res.top_answer,
          creator_id: userInfo?.id,
          creator_image: userInfo?.image,
          creator_name: userInfo?.name,
          bookmark_id: res.bookmark_question
        });

        setAnswer(null);
        setQuestion('');
      }

      setIsPosting(false);
    }
  };

  const onRefreshQuestion = async () => {
    if (!isRefreshingQuestion) {
      setIsRefreshingQuestion(true);

      const res = await apiGetQAAsk({
        question,
        to: userInfo?.id,
        to_type: 'user',
        dump_string: dumpId
      });

      if (res.success) {
        setAnswer(res.data);
      }

      setIsRefreshingQuestion(false);
    }
  };

  const onSaveAnswer = async () => {
    if (!isSavingAnswer) {
      setIsSavingAnswer(true);

      const res = await apiQAEditAnswer({
        question: answer.id,
        answer: editAnswerTxt
      });

      if (res.success) {
        setAnswer({
          ...answer,
          ai_answer: editAnswerTxt
        });
      }

      setIsSavingAnswer(false);
      setIsAnswerEditing(false);
    }
  };

  const onEnter = (e) => {
    e.stopPropagation();
    if (e.keyCode == 13) {
      e.preventDefault();
      onFindAnswer();
    }
  };

  const onInput = (e) => {
    e.target.style.maxHeight = '1px';
    e.target.style.maxHeight = e.target.scrollHeight + 'px';
    e.target.style.minHeight = e.target.scrollHeight + 'px';
  };

  const onAnswerEdit = () => {
    setIsAnswerEditing(true);
    setEditAnswerTxt(answer.ai_answer);
  };

  const renderAnswer = () => {
    return (
      <div ref={normalAnswerRef}>
        <CardFileAiAnswer data={answer} enableShowMore={false} />
      </div>
    );
  };

  const renderPostQuestion = () => {
    return (
      <div className="post_question row_space_between">
        <div className="row_align_items">
          {isRefreshingQuestion ? (
            <div className="add_regenerate_btn">
              <LoadingCircular size={20} color="main" />
            </div>
          ) : (
            <div
              className="add_regenerate_btn clickable"
              onClick={() => onRefreshQuestion()}
            >
              <RegenerateIcon />
              <div
                className="font-medium font16"
                style={{ marginLeft: '10px' }}
              >
                Regenerate
              </div>
            </div>
          )}
          <div className="edit_answer_btn clickable" onClick={onAnswerEdit}>
            <EditIcon color="#FF3E9A" />
            <div className="font-bold font16" style={{ marginLeft: '10px' }}>
              Edit Answer
            </div>
          </div>
        </div>
        <PrimaryButton
          label="Post Question"
          fontSize={14}
          isLoading={isPosting}
          containerStyle={{ width: 180, height: 45 }}
          onPressed={onPostAnswer}
        />
      </div>
    );
  };

  const renderEditAnswer = () => {
    return (
      <div className="edit_answer" ref={editAnswerRef}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="font-bold font18">Edit Answer</div>
          <div
            className="font-medium font14 clickable"
            style={{ color: '#FF3E9A' }}
            onClick={() => setEditAnswerTxt(answer.ai_answer)}
          >
            Refresh
          </div>
        </div>
        <textarea
          value={editAnswerTxt}
          placeholder=""
          className="answer_box_input font-regular font20"
          onChange={(e) => {
            onInput(e);
            setEditAnswerTxt(e.target.value);
          }}
          onFocus={onInput}
          autoFocus={true}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            className="cancel_btn clickable"
            onClick={() => setIsAnswerEditing(false)}
          >
            <div className="font-medium font16">Cancel</div>
          </div>
          <div className="save_answer_btn clickable" onClick={onSaveAnswer}>
            <div className="font-bold font16" style={{ color: 'white' }}>
              {isSavingAnswer ? (
                <LoadingCircular size={20} color="white" />
              ) : (
                <>Save Answer</>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="document_ask_question">
      <div className="row_space_between">
        <div className="font-bold font20" style={{ color: '#FF3E9A' }}>
          Ask a Question!
        </div>
        <div className="post_question_btn clickable" onClick={onFindAnswer}>
          <div className="font-bold font16" style={{ color: 'white' }}>
            <div className="post_question_btn_find_btn">
              <SearchIcon color={'white'} />
              <div style={{ marginLeft: 12 }}>Find Answers</div>
            </div>
          </div>
        </div>
      </div>
      <div className="document_ask_question_body">
        <textarea
          value={question}
          placeholder="Write your question here..."
          className="question_box_input font-regular font28"
          onInput={onInput}
          onChange={(e) => {
            if (e.nativeEvent['inputType'] === 'insertLineBreak') {
              e.preventDefault();
              return;
            } else {
              setQuestion(e.target.value);
            }
          }}
          onKeyUp={(e) => {
            e.preventDefault();
            onEnter(e);
          }}
        />
      </div>
      {(isFindingAnswer || isRefreshingQuestion) && <LoadingBarAnimation />}
      {answer &&
        !isFindingAnswer &&
        !isRefreshingQuestion &&
        (isAnswerEditing ? renderEditAnswer() : renderAnswer())}
      {answer &&
        !isFindingAnswer &&
        !isRefreshingQuestion &&
        !isAnswerEditing &&
        renderPostQuestion()}
    </div>
  );
};
