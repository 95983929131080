import React, { useState, useEffect } from 'react';
import { apiGetQASimilarQuestion } from 'shared/api/qa';
import CloseIcon from 'assets/svgs/CloseIcon';
import { CardRelatedQuestion } from './CardRelatedQuestion';
import InfiniteScrollWrapper from 'components/Common/WrapperScroll';

interface RelatedQuestionProps {
  selectedOrg: any;
  arrOrganisation: any;
  textQuestion: string;
}

export const RelatedQuestion = ({
  selectedOrg,
  arrOrganisation,
  textQuestion
}: RelatedQuestionProps) => {
  const [similarQuestions, setSimilarQuestions] = useState<any>([]);
  const [hasMoreQuestions, setHasMoreQuestions] = useState(false);
  const [lastIdQuestions, setLastIdQuestions] = useState(0);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  useEffect(() => {
    if (selectedOrg === null)
      if (arrOrganisation === null) return;
      else if (arrOrganisation.length == 0) return;
      else loadSimilarQuestions();
    else loadSimilarQuestions();
  }, [textQuestion, arrOrganisation, selectedOrg]);

  const loadSimilarQuestions = async (init = true) => {
    if (init) {
      setIsLoadingQuestions(true);
      const res = await apiGetQASimilarQuestion({
        question: textQuestion,
        item_id: selectedOrg ? selectedOrg?.id : arrOrganisation[0].id,
        item_type: 'organisation',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setSimilarQuestions(res.data);
        setHasMoreQuestions(res.hasMore);
        setLastIdQuestions(res.lastId);
      }
      setIsLoadingQuestions(false);
    } else {
      if (!isLoadingQuestions && hasMoreQuestions) {
        const res = await apiGetQASimilarQuestion({
          question: textQuestion,
          item_id: selectedOrg ? selectedOrg.id : arrOrganisation[0].id,
          item_type: 'organisation',
          limit: 10,
          offset: lastIdQuestions
        });

        if (res.success) {
          setSimilarQuestions((prev) => [...prev, ...res.data]);
          setHasMoreQuestions(res.hasMore);
          setLastIdQuestions(res.lastId);
        }
      }
    }
  };

  const onRemoveQuestionOnUI = (questionId) => {
    setSimilarQuestions((prev) =>
      prev.filter((item, index) => item.bookmark_id != questionId)
    );
  };

  const onNext = () => {
    loadSimilarQuestions(false);
  };

  if (similarQuestions?.length > 0) {
    return (
      <>
        <div className="font-bold font24" style={{ marginTop: 64 }}>
          Other Related Questions
        </div>
        <div style={{ marginBottom: 32 }}></div>
        <div className="related_question_list">
          <InfiniteScrollWrapper
            isInfiniteScrollOn={true}
            lengthData={similarQuestions.length}
            functionNext={onNext}
            hasOrgsMore={hasMoreQuestions}
            parentId="org_question_detail_right_panel"
          >
            {similarQuestions?.map((item, index) => {
              return (
                <CardRelatedQuestion
                  key={`card_similar_question_${index}`}
                  data={item}
                />
              );
            })}
          </InfiniteScrollWrapper>
        </div>
      </>
    );
  } else {
    return <div />;
  }
};
