import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import OrgIcon from 'assets/svgs/OrgIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import UserCheckIcon from 'assets/svgs/UserCheckIcon';
import UserFollowerIcon from 'assets/svgs/UserFollowerIcon';
import React, { useState } from 'react';

import './index.styles.scss';

interface SelectAITypeModalProps {
  show: boolean;
  onClose: () => void;
  onSelected: (aiType: 'general' | 'user' | 'group' | 'organisation') => void;
}

export const SelectAITypeModal = ({
  show,
  onClose,
  onSelected
}: SelectAITypeModalProps) => {
  if (show) {
    return (
      <div className="modal_custom_body" onClick={onClose}>
        <div className="modal_select_ai_type">
          <div className="modal_select_ai_type_header">
            <div className="clickable" onClick={onClose}>
              <CloseIcon />
            </div>
            <div className="font-bold font18" style={{ marginLeft: 16 }}>
              Select AI Type
            </div>
          </div>
          <div className="modal_select_ai_type_body">
            <div
              className="modal_select_ai_type_body_item clickable"
              onClick={() => onSelected('general')}
            >
              <div className="ai_type_icon">
                <RobotIcon size={20} />
              </div>
              <div
                className="font-bold font14"
                style={{ flex: 1, marginLeft: 12 }}
              >
                General AI
              </div>
              <AngleRightIcon color={'#FF3E9A'} opacity={1} />
            </div>
            <div
              className="modal_select_ai_type_body_item clickable"
              onClick={() => onSelected('user')}
            >
              <div className="ai_type_icon">
                <UserCheckIcon size={20} />
              </div>
              <div
                className="font-bold font14"
                style={{ flex: 1, marginLeft: 12 }}
              >
                Personal AI
              </div>
              <AngleRightIcon color={'#FF3E9A'} opacity={1} />
            </div>
            <div
              className="modal_select_ai_type_body_item clickable"
              onClick={() => onSelected('group')}
            >
              <div className="ai_type_icon">
                <UserFollowerIcon size={20} color={'#FF3E9A'} />
              </div>
              <div
                className="font-bold font14"
                style={{ flex: 1, marginLeft: 12 }}
              >
                Group AI
              </div>
              <AngleRightIcon color={'#FF3E9A'} opacity={1} />
            </div>
            <div
              className="modal_select_ai_type_body_item clickable"
              onClick={() => onSelected('organisation')}
            >
              <div className="ai_type_icon">
                <OrgIcon size={20} color={'#FF3E9A'} />
              </div>
              <div
                className="font-bold font14"
                style={{ flex: 1, marginLeft: 12 }}
              >
                School AI
              </div>
              <AngleRightIcon color={'#FF3E9A'} opacity={1} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};
