import QuestionIcon from 'assets/svgs/QuestionIcon';
import React, { useMemo, useState } from 'react';
import Question from 'assets/pngs/Question.png';
import './index.styles.scss';
import QuestionMarkIcon from 'assets/svgs/QuestionMarkIcon';
import AnswerCheckIcon from 'assets/svgs/AnswerCheckIcon';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from 'shared/utils/constants';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import MicIcon from 'assets/svgs/MicIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import FileIcon from 'assets/svgs/FileIcon';
import { useNavigate } from 'react-router-dom';
import ShareIcon from 'assets/svgs/ShareIcon';
import { QuestionAnswerShareModal } from 'components/Modals/QuestionAnswerShareModal';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiDeletionGeneratedQuestion } from 'shared/api/delete';

import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import TrashIcon from 'assets/svgs/TrashIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';
export interface QuestionBookmarkData {
  id: number;
  user: number;
  question: string;
  to: number;
  to_type: string;
  ai_answer: string;
  context: string;
  timestamp: string;
  ref_names?: string;
  ref_images?: string;
  ref_filenames?: string;
  creator_id: string;
  creator_image: string;
  creator_name: string;
  ref_urls?: string;
  ref_types?: string;
  ref_titles?: string;
  ref_texts?: string;
  ref_count?: string;
  ref_ids: number;
  is_suggested: number;
  url?: string;
  bookmark_id?: any;
}
interface QuestionBookmarkProps {
  data: QuestionBookmarkData;
  onRemoveOnUI?: (id) => void;
}
export const CardQuestion = ({ data, onRemoveOnUI }: QuestionBookmarkProps) => {
  const navigate = useNavigate();

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isRemoving, setIsRemoving] = useState(false);

  const [flip, setFlip] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const [showShareModal, setShowShareModal] = useState(false);

  const onClickRef = () => {
    if (data.ref_types === 'pdf') {
      navigate(`/document/${data.is_suggested}`);
    } else if (
      data.ref_types === 'url' ||
      data.ref_types === 'html' ||
      data.ref_types === 'browser'
    ) {
      window.open(data.ref_urls, '_blank');
    } else if (data.ref_types === 'text') {
      navigate(`/file/${data.is_suggested}`);
    } else if (data.ref_types === 'audio') {
      navigate(`/audio/${data.is_suggested}`);
    }
  };

  const onRemove = async () => {
    if (!isRemoving && onRemoveOnUI) {
      setIsRemoving(true);

      const res = await apiDeletionGeneratedQuestion({
        bookmark: data.bookmark_id
      });

      if (res.success) {
        showToast('success', 'Deletion successful!');
        onRemoveOnUI && onRemoveOnUI(data.bookmark_id);
      }

      setIsRemoving(false);
    }
  };

  const renderQuestionbody = () => {
    if (!isMobile)
      return (
        <div className="card_question_home">
          <div
            className="row_align_items row_space_between"
            style={{ paddingRight: 16 }}
          >
            <div className="card_question_home_mobile_inner_top_bar">
              <QuestionIcon color="#FF3E9A" />
              <div className="font-bold font16">Question</div>
            </div>
            <div className="row_align_items" style={{ gap: 8 }}>
              <div
                className="detail_btn clickable"
                onClick={() => {
                  setShowShareModal(true);
                }}
              >
                <ShareIcon size={16} color="rgba(0, 0, 0, 0.3)" />
              </div>
              {userInfo?.id == data.user && (
                <div className="detail_btn clickable" onClick={onRemove}>
                  {isRemoving ? (
                    <LoadingCircular size={16} color="main" />
                  ) : (
                    <TrashIcon />
                  )}
                </div>
              )}
            </div>
          </div>

          {renderReferences()}
          <div className="bg_image">
            <img src={Question} />
          </div>
          <div className="card_question_home_body">
            <div className="question_title_container">
              <div className="card_question_home_body_question_title font-bold font26">
                {data.question}
              </div>
            </div>
          </div>
          <div
            className="row_align_items"
            style={{ width: 'calc(100% - 32px)', justifyContent: 'center' }}
          >
            <div
              className="card_question_home_bottom font-bold clickable"
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => setFlip((prev) => !prev)}
            >
              Flip
            </div>
          </div>
        </div>
      );
    else
      return (
        <div className="card_question_home_mobile">
          <div className="card_question_home_mobile_inner">
            <div className="row_align_items row_space_between">
              <div className="card_question_home_mobile_inner_top_bar">
                <QuestionIcon color="#FF3E9A" />
                <div className="font-bold font16">Question</div>
              </div>
              <div
                className="detail_btn"
                onClick={() => setShowShareModal(true)}
              >
                <ShareIcon size={16} color="rgba(0, 0, 0, 0.3)" />
              </div>
            </div>

            <div>{renderReferences()}</div>
            <div className="bg_image">
              <img src={Question} />
            </div>
            <div className="card_question_home_mobile_inner_body">
              <div className="card_question_home_mobile_inner_body_question_title font-bold font26">
                {data.question}
              </div>
            </div>

            <div className="row_align_items" style={{ width: '100%' }}>
              <div
                className="card_question_home_mobile_inner_bottom font-bold clickable"
                onClick={() => setFlip((prev) => !prev)}
              >
                Flip
              </div>
            </div>
          </div>
        </div>
      );
  };

  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 20, height: 20 }} className="align-center">
        {type === 'pdf' ? (
          <PdfFileIcon size={20} color={'rgba(0, 0, 0, 0.3)'} />
        ) : type === 'audio' ? (
          <MicIcon size={20} color={'rgba(0, 0, 0, 0.3)'} opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={20} color={'rgba(0, 0, 0, 0.3)'} opacity={1} />
        ) : (
          <FileIcon size={20} color={'rgba(0, 0, 0, 0.3)'} />
        )}
      </div>
    );
  };

  const renderReferences = () => {
    return (
      <div
        className="ref_container row_align_items clickable"
        style={{
          width: 'calc(100% - 16px)'
        }}
        onClick={onClickRef}
      >
        {renderFileIcon(data.ref_types)}
        <div
          style={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: 4
          }}
        >
          {data.ref_titles ?? data.ref_filenames ?? data.ref_urls}
        </div>
      </div>
    );
  };

  const renderAnswerBody = () => {
    return (
      <div className="card_question_home">
        <div className="row_align_items row_space_between">
          <div className="card_question_home_top_bar">
            {!flip ? <QuestionMarkIcon /> : <AnswerCheckIcon />}
            <div className="font-bold font16">
              {flip ? 'Answer' : 'Question'}
            </div>
          </div>
          <div className="row_align_items" style={{ gap: 8, marginRight: 16 }}>
            <div
              className="detail_btn clickable"
              onClick={() => {
                setShowShareModal(true);
              }}
            >
              <ShareIcon size={16} color="rgba(0, 0, 0, 0.3)" />
            </div>
            {userInfo?.id == data.user && (
              <div className="detail_btn clickable" onClick={onRemove}>
                {isRemoving ? (
                  <LoadingCircular size={16} color="main" />
                ) : (
                  <TrashIcon />
                )}
              </div>
            )}
          </div>
        </div>

        {renderReferences()}
        <div className="card_question_home_body">
          <div className="card_question_home_body_answer  font20 font-general">
            {data.ai_answer}
          </div>
        </div>

        <div
          className="bottom_container row_align_items"
          style={{ width: 'calc(100%)', justifyContent: 'center' }}
        >
          <div
            className="card_question_home_bottom_back font-bold clickable"
            onClick={() => setFlip((prev) => !prev)}
          >
            Flip
          </div>
        </div>
      </div>
    );
  };

  const renderShareToSocial = () => {
    return (
      <QuestionAnswerShareModal
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
        question={data}
        type={'question'}
      />
    );
  };

  return (
    <>
      {flip ? renderAnswerBody() : renderQuestionbody()}
      {renderShareToSocial()}
    </>
  );
};
