import FilterIcon from 'assets/svgs/FilterIcon';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuestionAskBar } from '../components/QuestionAskBar';
import { EventHosted } from './components/EventHosted';
import { EventAttending } from './components/EventAttending';

import './index.styles.scss';
import { AllEvent } from './components/AllEvent';

export const Events = () => {
  const navigate = useNavigate();

  const [searchTxt, setSearchTxt] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const onClickBack = () => {
    if (isSearchFocused) {
      setIsSearchFocused(false);
      setSearchTxt('');
    } else {
      navigate(-1);
    }
  };

  const onSearch = (question) => {
    setSearchTxt(question);
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div className="clickable" onClick={onClickBack}>
            <RoundBackIcon color="#ff3e9a" />
          </div>
          <div className="font-bold font32" style={{ marginLeft: 8 }}>
            Events
          </div>
        </div>
      </div>
    );
  };

  const renderAskBar = () => {
    return (
      <div className="row_align_items search_view">
        <QuestionAskBar
          onSearch={onSearch}
          onRealTimeSearch={onSearch}
          onFocus={() => {
            setIsSearchFocused(true);
          }}
          width="100%"
          height="64px"
          backgroundColor="#F1F6F9"
          placeholder="Discover new events here..."
          fontSize={20}
          searchIconClickable={true}
          showButton={false}
        />
        {/* <div className="filter_btn row_align_items clickable">
          <div className="font-bold font16">Filter</div>
          <FilterIcon />
        </div> */}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="events_body">
        <EventHosted />
        <EventAttending />
        <AllEvent searchTxt={''} isFocusedSearch={false} />
      </div>
    );
  };

  const renderSearchBody = () => {
    if (searchTxt === '') {
      return <div className="events_body"></div>;
    } else {
      return (
        <div className="events_body">
          <AllEvent searchTxt={searchTxt} isFocusedSearch={true} />
        </div>
      );
    }
  };

  return (
    <div className="events">
      {renderHeader()}
      {renderAskBar()}
      {isSearchFocused ? renderSearchBody() : renderBody()}
    </div>
  );
};
