import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HashRouter, BrowserRouter, Route, Routes } from 'react-router-dom';
import { useLogin } from 'shared/hooks/userLogin';

import { AuthRoutes } from './AuthRoutes';
import { MainRoutes } from './MainRoutes';

import profileAction from 'redux/profile/actions';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from 'shared/utils/constants';
import { MobileMainRoutes } from './MobileMainRotes';
import { TmpRoutes } from './TmpRoutes';

export const AppRoutes = ({ sock }) => {
  const dispatch = useDispatch();

  const loginStatus: any = useLogin();

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  useEffect(() => {
    if (loginStatus.isSignedIn) {
      loadUserInfo();
    }
  }, [loginStatus]);

  const loadUserInfo = async () => {
    const userId = localStorage.getItem('userId');
    dispatch(profileAction.loadUserInfo(parseInt(userId!)));
  };

  if (loginStatus.isLoginChecking) {
    return <div />;
  } else {
    return (
      <HashRouter>
        <Routes>
          {loginStatus.isSignedIn ? (
            <Route
              path={'*'}
              element={isMobile ? <MobileMainRoutes /> : <MainRoutes />}
              // element={isMobile ? <MobileMainRoutes /> : <TmpRoutes />}
            />
          ) : (
            <Route path={'*'} element={<AuthRoutes />} />
          )}
        </Routes>
      </HashRouter>
    );
  }
};
