import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { apiGetQABookmarks, apiGetReceivedQuestion } from 'shared/api/qa';
import Columns from 'react-columns';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import './index.styles.scss';
import { CardBookmark } from './CardBookmark';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { ShareQuestionToOrgModal } from 'components/Modals/ShareQuestionToOrgModal';
import { SelectOrgModal } from 'components/Modals/SelectOrgModal';
import AlertCheckIcon from 'assets/svgs/AlertCheckIcon';
import InfiniteScrollWrapper from 'components/Common/WrapperScroll';
import { QuestionBookmarkData } from './CardBookmark/index';
import { QuestionAnswerShareModal } from 'components/Modals/QuestionAnswerShareModal';
import NoteBookIcon from 'assets/svgs/NoteBookIcon';
import AnswerIcon from 'assets/svgs/AnswerIcon';
import BookmarkOutlineIcon from 'assets/svgs/BookmarkOutlineIcon';
import GearIcon from 'assets/svgs/GearIcon';
import { CardQuestion } from './CardQuestion';
import { CardBookmarked } from './CardBookmarked';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { CardAnswer } from './CardAnswer';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from 'shared/utils/constants';
import { CardReceivedQuestion } from './CardReceivedQuestion';
import CommentDownIcon from 'assets/svgs/CommentDownIcon';
import useOnScreen from 'shared/hooks/useOnScreen';
import appAction from 'redux/app/action';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import {
  apiGetContentFlashCards,
  apiGetContentStatements
} from 'shared/api/content';

interface HomeBookmarkProps {
  shouldRefresh: boolean;
  itemId: number;
  itemType: 'user' | 'group' | 'organisation';
  setMobileTab: any;
}

export const HomeBookmark = ({ itemId, itemType }: HomeBookmarkProps) => {
  const dispatch = useDispatch();
  const { hasNewBookmark, newBookmarkData } = useSelector(
    (state: RootState) => state.app
  );
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const bookmarkRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const isVisibleNavBar = useOnScreen(navRef);
  const isVisibleBookmark = useOnScreen(bookmarkRef);

  const [searchText, setSearchText] = useState('');
  const [isLoadedData, setIsLoadedData] = useState(false);

  const [arrQuestion, setArrQuestion] = useState<Array<any>>([]);
  const [lastIdQuestion, setLastIdQuestion] = useState(0);
  const [hasMoreQuestion, setHasMoreQuestion] = useState(true);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);

  const [arrAnswer, setArrAnswer] = useState<Array<any>>([]);
  const [lastIdAnswer, setLastIdAnswer] = useState(0);
  const [hasMoreAnswer, setHasMoreAnswer] = useState(true);
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false);

  const [arrBookmark, setArrBookmark] = useState<Array<any>>([]);
  const [lastIdBookmark, setLastIdBookmark] = useState(0);
  const [hasMoreBookmark, setHasMoreBookmark] = useState(true);
  const [isLoadingBookmark, setIsLoadingBookmark] = useState(false);
  const [isLoadedBookmark, setIsLoadedBookmark] = useState(false);

  const [arrPublish, setArrPublish] = useState<Array<any>>([]);
  const [lastIdPublish, setLastIdPublish] = useState(0);
  const [hasMorePublish, setHasMorePublish] = useState(true);
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);

  const [arrReceived, setArrReceived] = useState<Array<any>>([]);
  const [lastIdReceived, setLastIdReceived] = useState(0);
  const [hasMoreReceived, setHasMoreReceived] = useState(true);
  const [isLoadingReceived, setIsLoadingReceived] = useState(false);
  const [isLoadedReceived, setIsLoadedReceived] = useState(false);

  const [isSendToOrg, setIsSendToOrg] = useState(false);
  const [isSelectOrg, setIsSelectOrg] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [snackBarShow, setSnackBarShow] = useState(false);
  const [selectedQuestionData, setSelectedQuestionData] = useState<
    QuestionBookmarkData | undefined
  >();
  const [isShareModalShow, setIsShareModalShow] = useState(false);

  const [navItem, setNavItem] = useState<
    'question' | 'answer' | 'bookmark' | 'publish' | 'received'
  >('question');

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  useEffect(() => {
    if (navItem === 'question') loadQuestion();
    else if (navItem === 'answer') loadStatement();
    else if (navItem === 'bookmark') loadBookmarks();
    else if (navItem === 'received') loadReceivedQuestion();
  }, [searchText, navItem]);

  useEffect(() => {
    if (newBookmarkData) {
      setArrBookmark((prev) => [newBookmarkData, ...prev]);
      dispatch(appAction.addBookMarkData(null));
    }
  }, [hasNewBookmark, newBookmarkData]);

  useEffect(() => {
    loadQuestion();
    loadStatement();
    loadBookmarks();
    loadReceivedQuestion();
  }, [itemId]);

  const onShareQuestion = () => {
    setSnackBarShow(true);
    setInterval(() => {
      setSnackBarShow(false);
    }, 3000);
  };

  const loadQuestion = async (init = true) => {
    if (init) {
      setIsLoadingQuestion(true);

      const res = await apiGetContentFlashCards({
        query: searchText,
        to: itemId,
        to_type: itemType,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrQuestion(res.data);
        setLastIdQuestion(res.lastId);
        setHasMoreQuestion(res.hasMore);
      }

      setIsLoadedData(true);
      setIsLoadingQuestion(false);
    } else {
      if (hasMoreQuestion && !isLoadingQuestion) {
        setIsLoadingQuestion(true);
        const res = await apiGetContentFlashCards({
          query: searchText,
          to: itemId,
          to_type: itemType,
          limit: 10,
          offset: lastIdQuestion
        });

        if (res.success) {
          setArrQuestion((prev) => [...prev, ...res.data]);
          setLastIdQuestion(res.lastId);
          setHasMoreQuestion(res.hasMore);
        }

        setIsLoadedData(true);
        setIsLoadingQuestion(false);
      }
    }
  };

  const loadBookmarks = async (init = true) => {
    if (init) {
      console.log('--- load bookmark');
      setIsLoadingBookmark(true);

      const res = await apiGetQABookmarks({
        query: searchText,
        item_id: itemId,
        item_type: itemType,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrBookmark(res.data);
        setLastIdBookmark(res.lastId);
        setHasMoreBookmark(res.hasMore);
        setIsLoadedBookmark(true);
      }

      setIsLoadedData(true);
      setIsLoadingBookmark(false);
    } else {
      if (hasMoreBookmark && !isLoadingBookmark) {
        setIsLoadingBookmark(true);

        const res = await apiGetContentFlashCards({
          query: searchText,
          to: itemId,
          to_type: itemType,
          limit: 10,
          offset: lastIdBookmark
        });
        if (res.success) {
          setArrBookmark((prev) => [...prev, ...res.data]);
          setLastIdBookmark(res.lastId);
          setHasMoreBookmark(res.hasMore);
        }

        setIsLoadedData(true);
        setIsLoadingBookmark(false);
      }
    }
  };

  const loadStatement = async (init = true) => {
    if (init) {
      setIsLoadingAnswer(true);
      const res = await apiGetContentStatements({
        query: searchText,
        to: itemId,
        to_type: itemType,
        limit: 10,
        offset: 0
      });
      if (res.success) {
        setArrAnswer(res.data);
        setLastIdAnswer(res.lastId);
        setHasMoreAnswer(res.hasMore);
      }
      setIsLoadingAnswer(false);
    } else {
      if (!isLoadingAnswer && hasMoreAnswer) {
        setIsLoadingAnswer(true);

        const res = await apiGetContentStatements({
          query: searchText,
          to: userInfo?.id,
          to_type: 'user',
          limit: 10,
          offset: lastIdAnswer
        });
        if (res.success) {
          setArrAnswer((prev) => [...prev, ...res.data]);
          setLastIdAnswer(res.lastId);
          setHasMoreAnswer(res.hasMore);
        }

        setIsLoadingAnswer(false);
      }
    }
  };

  const loadReceivedQuestion = async (init = true) => {
    if (init) {
      setIsLoadingReceived(true);

      const res = await apiGetReceivedQuestion({
        query: searchText,
        item_id: itemId,
        item_type: itemType,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrReceived(res.data);
        setLastIdReceived(res.lastId);
        setHasMoreReceived(res.hasMore);
      }

      setIsLoadedReceived(true);
      setIsLoadingReceived(false);
    } else {
      if (hasMoreReceived && !isLoadingReceived) {
        setIsLoadingReceived(true);
        const res = await apiGetReceivedQuestion({
          query: searchText,
          item_id: itemId,
          item_type: itemType,
          limit: 10,
          offset: lastIdQuestion
        });

        if (res.success) {
          setArrReceived((prev) => [...prev, ...res.data]);
          setLastIdReceived(res.lastId);
          setHasMoreReceived(res.hasMore);
        }

        setIsLoadedData(true);
        setIsLoadingReceived(false);
      }
    }
  };

  const onNext = () => {
    if (navItem === 'question' || navItem === 'publish') loadQuestion(false);
    else if (navItem === 'answer') loadStatement(false);
    else if (navItem === 'bookmark') loadBookmarks(false);
    else if (navItem === 'received') loadReceivedQuestion(false);
  };

  const onShareHandler = (data) => {
    setSelectedQuestionData(data);
    setIsShareModalShow(true);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      onNext();
    }
  };

  const onRemoveBookmarkOnUI = (questionId) => {
    setArrBookmark((prev) =>
      prev.filter((item, index) => item.bookmark_id != questionId)
    );
  };

  const onRemoveQuestionOnUI = (questionId) => {
    setArrQuestion((prev) =>
      prev.filter((item, index) => item.bookmark_id != questionId)
    );
  };

  const onRemoveAnswerOnUI = (answerId) => {
    setArrAnswer((prev) => prev.filter((item, index) => item.id != answerId));
  };

  const renderEmptyView = () => {
    return (
      <div className="bookmark_body_empty_view" style={{ marginTop: 30 }}>
        <div style={{ fontSize: 100, textAlign: 'center' }}>🔖</div>
        <div
          className="font-bold font24"
          style={{ marginTop: 16, textAlign: 'center' }}
        >
          {navItem === 'question'
            ? 'No flip cards generated yet'
            : navItem === 'answer'
            ? 'No statements generated yet'
            : navItem === 'bookmark'
            ? 'No bookmarked answers yet'
            : navItem === 'publish'
            ? 'No Q&A generated yet'
            : 'No questions received yet'}
        </div>
        <div
          className="font-regular font18"
          style={{ marginTop: 8, textAlign: 'center' }}
        >
          {navItem === 'question'
            ? 'Flip cards with questions and their answers will be generated automatically'
            : navItem === 'answer'
            ? 'hort statements with insights will be automatically generated'
            : navItem === 'bookmark'
            ? 'Set bookmarks for your favourite answer'
            : navItem === 'publish'
            ? 'Detailed question and answers with references will be automatically generated'
            : 'You have not received any questions from other users yet,'}
          <br />
          {navItem === 'question'
            ? 'after you upload to your knowledge base'
            : navItem === 'answer'
            ? 'after you upload to your knowledge base'
            : navItem === 'bookmark'
            ? 'to collect and display them here'
            : navItem === 'publish'
            ? 'after you upload to your knowledge base'
            : 'they will show up here'}
        </div>
      </div>
    );
  };

  const renderNavbar = () => {
    return (
      <div className="bookmark_navbar" ref={navRef}>
        <div
          className={
            navItem === 'question'
              ? 'bookmark_navbar_item_selected'
              : 'bookmark_navbar_item'
          }
          onClick={() => setNavItem('question')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">
                Flip cards help you quickly remember ideas from knowledge base
              </div>
            }
          >
            <IconButton>
              <NoteBookIcon color={navItem === 'question' ? '#fff' : '#000'} />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={
            navItem === 'answer'
              ? 'bookmark_navbar_item_selected'
              : 'bookmark_navbar_item'
          }
          onClick={() => setNavItem('answer')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">
                Statements with short insights from your knowledge base
              </div>
            }
          >
            <IconButton>
              <AnswerIcon color={navItem === 'answer' ? '#fff' : '#000'} />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={
            navItem === 'bookmark'
              ? 'bookmark_navbar_item_selected'
              : 'bookmark_navbar_item'
          }
          onClick={() => setNavItem('bookmark')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">
                Bookmark your favourite answers to view later
              </div>
            }
          >
            <IconButton>
              <BookmarkOutlineIcon
                color={navItem === 'bookmark' ? '#fff' : '#000'}
              />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={
            navItem === 'publish'
              ? 'bookmark_navbar_item_selected'
              : 'bookmark_navbar_item'
          }
          onClick={() => setNavItem('publish')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">
                Detailed Q&A with deeper findings from your knowledge base
              </div>
            }
          >
            <IconButton>
              <GearIcon color={navItem === 'publish' ? '#fff' : '#000'} />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={
            navItem === 'received'
              ? 'bookmark_navbar_item_selected'
              : 'bookmark_navbar_item'
          }
          onClick={() => setNavItem('received')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">Received questions</div>
            }
          >
            <IconButton>
              <CommentDownIcon
                color={navItem === 'received' ? '#fff' : '#000'}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderFixedNavbar = () => {
    return (
      <div
        style={{
          position: 'fixed',
          width: 'calc(100%)',
          backgroundColor: '#f7fafc',
          top: 100,
          height: 100,
          paddingRight: 32,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div className="bookmark_navbar" style={{ width: 'fit-content' }}>
          <div
            className={
              navItem === 'question'
                ? 'bookmark_navbar_item_selected'
                : 'bookmark_navbar_item'
            }
            onClick={() => setNavItem('question')}
          >
            <NoteBookIcon color={navItem === 'question' ? '#fff' : '#000'} />
          </div>
          <div
            className={
              navItem === 'answer'
                ? 'bookmark_navbar_item_selected'
                : 'bookmark_navbar_item'
            }
            onClick={() => setNavItem('answer')}
          >
            <AnswerIcon color={navItem === 'answer' ? '#fff' : '#000'} />
          </div>
          <div
            className={
              navItem === 'bookmark'
                ? 'bookmark_navbar_item_selected'
                : 'bookmark_navbar_item'
            }
            onClick={() => setNavItem('bookmark')}
          >
            <BookmarkOutlineIcon
              color={navItem === 'bookmark' ? '#fff' : '#000'}
            />
          </div>
          <div
            className={
              navItem === 'publish'
                ? 'bookmark_navbar_item_selected'
                : 'bookmark_navbar_item'
            }
            onClick={() => setNavItem('publish')}
          >
            <GearIcon color={navItem === 'publish' ? '#fff' : '#000'} />
          </div>
          <div
            className={
              navItem === 'received'
                ? 'bookmark_navbar_item_selected'
                : 'bookmark_navbar_item'
            }
            onClick={() => setNavItem('received')}
          >
            <CommentDownIcon color={navItem === 'received' ? '#fff' : '#000'} />
          </div>
        </div>
        <SearchBox onSearch={setSearchText} width={'300px'} fontSize={20} />
      </div>
    );
  };

  const renderShareQuestionToOrgMoal = () => {
    return (
      <ShareQuestionToOrgModal
        type="home"
        chatMessages={arrQuestion}
        show={isSendToOrg}
        onClose={() => {
          setIsSendToOrg(false);
        }}
        onSuccess={() => {
          setIsSendToOrg(false);
          setIsSelectOrg(true);
        }}
        question={currentQuestion}
        selectedOrgId={'0'}
      />
    );
  };

  const renderSelectOrgMoal = () => {
    return (
      <SelectOrgModal
        type="home"
        show={isSelectOrg}
        onBack={() => {
          setIsSelectOrg(false);
          setIsSendToOrg(true);
        }}
        onClose={() => {
          setIsSelectOrg(false);
        }}
        onSuccess={() => {
          setIsSelectOrg(false);
          onShareQuestion();
        }}
        question={currentQuestion}
      />
    );
  };

  const renderShareToSocial = () => {
    return (
      <QuestionAnswerShareModal
        show={isShareModalShow}
        onClose={() => {
          setIsShareModalShow(false);
        }}
        selectedQuestionDetail={selectedQuestionData}
        type="bookmarked"
      />
    );
  };

  const renderSnackbar = () => {
    return (
      <div className="snackbar">
        <AlertCheckIcon />
        <div className="alert-message font-bold font15">
          Successfully post question on org
        </div>
      </div>
    );
  };

  const renderQuestionTab = () => {
    return (
      <div style={{ marginTop: '32px' }} onScroll={handleScroll}>
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={arrQuestion.length}
          functionNext={onNext}
          hasOrgsMore={hasMoreQuestion}
          parentId="home_body"
        >
          {arrQuestion.length !== 0 ? (
            <Columns
              columns={isMobile ? 1 : 4}
              gap={'24px'}
              rootStyles={{ paddingBottom: 10 }}
            >
              {arrQuestion.map((item, index) => {
                return (
                  <CardQuestion
                    data={item}
                    key={`card_question_key${index}`}
                    onRemoveOnUI={onRemoveQuestionOnUI}
                  />
                );
              })}
            </Columns>
          ) : (
            renderEmptyView()
          )}
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderAnswerTab = () => {
    return (
      <div style={{ marginTop: '32px' }}>
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={arrAnswer.length}
          functionNext={onNext}
          hasOrgsMore={hasMoreAnswer}
          parentId="home_body"
        >
          {arrAnswer.length >= 1 ? (
            <Columns
              columns={isMobile ? 1 : 2}
              gap={'24px'}
              rootStyles={{ paddingBottom: 10 }}
            >
              {arrAnswer.map((item, index) => {
                return (
                  <CardAnswer
                    data={item}
                    key={`card_question_key${index}`}
                    index={index + 1}
                    onRemoveOnUI={onRemoveAnswerOnUI}
                  />
                );
              })}
            </Columns>
          ) : (
            renderEmptyView()
          )}
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderBookmarked = () => {
    if (isLoadedBookmark) {
      return (
        <div
          className="home_bookmark_body"
          id="home_bookmark_body"
          onScroll={handleScroll}
        >
          <InfiniteScrollWrapper
            isInfiniteScrollOn={true}
            lengthData={arrBookmark.length}
            functionNext={onNext}
            hasOrgsMore={hasMoreBookmark}
            parentId="home_body"
          >
            {arrBookmark.length > 0 ? (
              <div id="scrollableDiv">
                <Columns
                  columns={isMobile ? 1 : 2}
                  gap={'24px'}
                  rootStyles={{ paddingBottom: 10 }}
                >
                  {arrBookmark.map((item, index) => {
                    return (
                      <CardBookmarked
                        key={`month_bookmark_${index}`}
                        data={item}
                        itemId={itemId}
                        itemType={itemType}
                        onRefresh={loadQuestion}
                        setCurrentQuestion={setCurrentQuestion}
                        setIsSendToOrg={setIsSendToOrg}
                        onShareMedia={(data) => {
                          onShareHandler(data);
                        }}
                        onRemoveOnUI={onRemoveBookmarkOnUI}
                      />
                    );
                  })}
                </Columns>
              </div>
            ) : (
              renderEmptyView()
            )}
          </InfiniteScrollWrapper>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 100 }}>
          <LoadingBarAnimation />
        </div>
      );
    }
  };

  const renderPublish = () => {
    return (
      <div
        className="home_bookmark_body"
        id="home_bookmark_body"
        onScroll={handleScroll}
      >
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={arrQuestion.length}
          functionNext={onNext}
          hasOrgsMore={hasMoreQuestion}
          parentId="home_body"
        >
          {arrQuestion.length > 0 ? (
            <div id="scrollableDiv">
              <Columns
                columns={isMobile ? 1 : 2}
                gap={'24px'}
                rootStyles={{ paddingBottom: 10 }}
              >
                {arrQuestion.map((item, index) => {
                  return (
                    <CardBookmark
                      key={`month_bookmark_${index}`}
                      data={item}
                      onRefresh={loadQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                      setIsSendToOrg={setIsSendToOrg}
                      onShareMedia={(data) => {
                        onShareHandler(data);
                      }}
                      onRemoveOnUI={onRemoveQuestionOnUI}
                    />
                  );
                })}
              </Columns>
            </div>
          ) : (
            renderEmptyView()
          )}
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderReceviedQuestion = () => {
    return (
      <div
        className="home_bookmark_body"
        id="home_bookmark_body"
        onScroll={handleScroll}
      >
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={arrReceived.length}
          functionNext={onNext}
          hasOrgsMore={hasMoreReceived}
          parentId="home_body"
        >
          {arrReceived.length > 0 ? (
            <Columns
              columns={2}
              gap={'24px'}
              rootStyles={{ paddingBottom: 10 }}
            >
              {arrReceived.map((item, index) => {
                return (
                  <CardReceivedQuestion
                    key={`card_received_question_${index}`}
                    data={item}
                  />
                );
              })}
            </Columns>
          ) : (
            renderEmptyView()
          )}
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderNormalPage = () => {
    return (
      <div className="home_bookmark" ref={bookmarkRef}>
        <div
          className="row_space_between"
          id="bookmark_navBar"
          style={{ paddingRight: 32 }}
        >
          {renderNavbar()}
          <SearchBox onSearch={setSearchText} width={'300px'} fontSize={20} />
        </div>
        {navItem === 'question' && renderQuestionTab()}
        {navItem === 'bookmark' && renderBookmarked()}
        {navItem === 'answer' && renderAnswerTab()}
        {navItem === 'publish' && renderPublish()}
        {navItem === 'received' && renderReceviedQuestion()}
        {renderShareQuestionToOrgMoal()}
        {renderSelectOrgMoal()}
        {renderShareToSocial()}
        {snackBarShow && renderSnackbar()}
        {isVisibleBookmark && !isVisibleNavBar && renderFixedNavbar()}
      </div>
    );
  };
  return renderNormalPage();
};
