import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import CheckCircleIcon from 'assets/svgs/CheckCircleIcon';
import FileIcon from 'assets/svgs/FileIcon';
import HeartBreakIcon from 'assets/svgs/HeartBreakIcon';
import HeartIcon from 'assets/svgs/HeartIcon';
import HorizontalDetailIcon from 'assets/svgs/HorizontalDetailIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import MicIcon from 'assets/svgs/MicIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiContentVoteAI } from 'shared/api/content';

import './index.styles.scss';

export const CardFlipAnswerBody = ({ data, onFlip }) => {
  const navigate = useNavigate();
  const [showActionModal, setShowActionModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [likeCount, setLikeCount] = useState(data.likes);
  const [dislikeCount, setDislikeCount] = useState(data.dislikes);
  const [userVote, setUserVote] = useState(data.user_like);

  const onVote = async (vote: number) => {
    const res = await apiContentVoteAI({
      item_id: data.bookmark_id,
      item_type: 'generated_question',
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      setLikeCount(voteData.likes);
      setDislikeCount(voteData.dislikes);
    }
  };

  const openRefLink = () => {
    if (data.ref_types === 'html') {
      window.open(data.ref_filenames);
    } else if (data.ref_types === 'browser') {
      window.open(data.ref_urls);
    } else if (data.ref_types === 'pdf') {
      navigate(`/document/${data.bookmark_id}`);
    }
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items row_space_between">
        <div className="mark row_align_items">
          <div className="light_view">
            <CheckCircleIcon />
          </div>
          <div
            className="font-bold font16"
            style={{ color: '#FF3E9A', marginLeft: 6 }}
          >
            Answer
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="flip_answer_body" style={{ marginTop: 12 }}>
        <div className="answer_main_body">
          <div className="question_txt font-regular">{data.ai_answer}</div>
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="flip_answer_bottom">
        <div className="flip_action_conatiner row_align_items">
          <div className="flip_btn font-bold font14 clickable" onClick={onFlip}>
            Flip
          </div>
        </div>
      </div>
    );
  };

  const renderRightAction = () => {
    return (
      <div className="right_action_view">
        <div className="action_item">
          <AudioPlayIcon color="#FF3E9A" />
        </div>
        <div className="vote_divider"></div>
        <div
          className="action_item"
          onClick={() => onVote(1)}
          style={{ marginTop: 16 }}
        >
          <HeartIcon
            color={userVote === 1 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)'}
          />
          <div
            className="font-medium font16"
            style={{ color: userVote === 1 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)' }}
          >
            {likeCount}
          </div>
        </div>
        <div
          className="action_item"
          onClick={() => onVote(0)}
          style={{ marginTop: 16 }}
        >
          <HeartBreakIcon
            color={userVote === 0 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)'}
          />
          <div
            className="font-medium font16"
            style={{ color: userVote === 0 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)' }}
          >
            {dislikeCount}
          </div>
        </div>
      </div>
    );
  };

  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 20, height: 20 }} className="align-center">
        {type === 'pdf' ? (
          <PdfFileIcon size={20} color={'rgba(0, 0, 0, 0.3)'} />
        ) : type === 'audio' ? (
          <MicIcon size={20} color={'rgba(0, 0, 0, 0.3)'} opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={20} color={'rgba(0, 0, 0, 0.3)'} opacity={1} />
        ) : (
          <FileIcon size={20} color={'rgba(0, 0, 0, 0.3)'} />
        )}
      </div>
    );
  };

  const renderReferences = () => {
    return (
      <div
        className="ref_container row_align_items clickable"
        onClick={openRefLink}
        style={{ marginTop: 12 }}
      >
        {renderFileIcon(data.ref_types)}
        <div
          style={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: 4
          }}
        >
          {data.ref_titles ?? data.ref_filenames ?? data.ref_urls}
        </div>
      </div>
    );
  };

  return (
    <div className="main_card_flip_answer">
      {renderHeader()}
      {renderReferences()}
      {renderBody()}
      {renderBottom()}
      {/* {renderRightAction()} */}
    </div>
  );
};
