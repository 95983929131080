import AlertCheckIcon from 'assets/svgs/AlertCheckIcon';
import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import CommentAltCheckIcon from 'assets/svgs/CommentAltCheckIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import EditIcon from 'assets/svgs/EditIcon';
import { Reference } from './Reference';
import { ReferenceDetail } from 'components/Common/ReferenceDetail';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiCreateGroup } from 'shared/api/group';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import CustomizedSwitch from 'shared/ui-kit/SwitchMui';
import { getArrFromString } from 'shared/utils/utils';
import './index.styles.scss';

interface ShareQuestionToOrgModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: (groupData) => void;
  question: any;
  selectedOrgId: string | undefined;
  chatMessages: any;
  type: 'conversation' | 'home';
}

export const ShareQuestionToOrgModal = ({
  show,
  onClose,
  onSuccess,
  question,
  selectedOrgId,
  chatMessages,
  type
}: ShareQuestionToOrgModalProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [checked, setChecked] = useState(false);
  const [isEditAnswer, setIsEditAnswer] = useState(false);
  const [isAsking, setIsAsking] = useState(false);
  const [answer, setAnswer] = useState('');
  const [qaAskInfo, setQaAskInfo] = useState('');
  const [aiAnswer, setAiAnswer] = useState<{ [key: string]: any } | null>([]);
  const [isExpandReference, setIsExpandReference] = useState(false);
  const [isSavingAnswer, setIsSavingAnswer] = useState(false);

  useEffect(() => {
    if (type === 'home') {
      setQaAskInfo(question.ai_answer);
      setAiAnswer(question);
    } else {
      const ai_Answer = chatMessages.filter(
        (item) => item.is_ai == 1 && item.question == question.question
      );
      setAiAnswer(ai_Answer[0]);
      setQaAskInfo(ai_Answer[0].message);
    }
  }, [question]);

  const arrReferences = useMemo(() => {
    if (aiAnswer?.ref_names || aiAnswer?.ref_types) {
      const arrTmpRefData: Array<any> = [];

      const arrRefIds = getArrFromString(aiAnswer.ref_ids, ';;;');
      // for user
      const arrTmpName = getArrFromString(aiAnswer.ref_names, ';;;');
      const arrTmpImage = getArrFromString(aiAnswer.ref_images, ';;;');

      // for files
      const arrTmpFileName = getArrFromString(
        aiAnswer.ref_titles ?? aiAnswer.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(aiAnswer.ref_urls, ';;;');
      const arrTmpFileType = getArrFromString(aiAnswer.ref_types, ';;;');

      arrTmpName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpName[index],
          image: arrTmpImage[index],
          type: 'user'
        });
      });

      arrTmpFileName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpFileName[index],
          image: arrTmpFileUrl[index],
          type: 'file',
          fileType: arrTmpFileType[index]
        });
      });

      // remove duplicated
      const uniqueArr = arrTmpRefData.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.image === value.image || t.name === value.name
          )
      );
      return uniqueArr;
    } else {
      return [];
    }
  }, [aiAnswer]);

  const arrReferencesForBelow = useMemo(() => {
    if (aiAnswer?.ref_names || aiAnswer?.ref_types) {
      const arrTmpRefData: Array<any> = [];

      // for user
      const arrTmpName = getArrFromString(aiAnswer.ref_names, ';;;');
      const arrTmpImage = getArrFromString(aiAnswer?.ref_images, ';;;');
      // for files
      const arrTmpFileName = getArrFromString(
        aiAnswer?.ref_titles ?? aiAnswer?.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(aiAnswer?.ref_urls, ';;;');
      const arrTmpTxt = getArrFromString(
        aiAnswer?.ref_texts ?? aiAnswer?.context,
        ';;;'
      );
      const arrTmpFileType = getArrFromString(aiAnswer?.ref_types, ';;;');

      if (arrTmpTxt.length > 0) {
        arrTmpTxt.map((item, index) => {
          arrTmpRefData.push({
            name: arrTmpName[index],
            fileName: arrTmpFileName[index],
            image: arrTmpImage[index],
            url: arrTmpFileUrl[index],
            text: arrTmpTxt[index],
            fileType: arrTmpFileType[index]
          });
        });
      }

      return arrTmpRefData;
    } else {
      return [];
    }
  }, [aiAnswer]);

  const onContinue = async () => {
    setIsCreating(true);
    onSuccess(true);
    setIsCreating(false);
  };

  const onSaveAnswer = () => {
    setQaAskInfo(answer);
    setIsEditAnswer(false);
  };

  const renderCheckBoxBar = () => {
    return (
      <div className="share_with_answer_switch">
        <div className="share_with_answer_switch_left">
          <div className="share_with_answer_switch_left_icon">
            <CommentAltCheckIcon color="#000000" />
          </div>
          <div className="font15 text-dark">Share with answer</div>
        </div>
        <CustomizedSwitch setIsChecked={setChecked} isChecked={checked} />
      </div>
    );
  };

  const renderNormalAnswer = () => {
    return (
      <>
        <div className="font-regular font14 text_answer">
          {isAsking ? (
            <LoadingCircular size={20} color="main" />
          ) : (
            <>{qaAskInfo}</>
          )}
        </div>
        <div className="reference_bar">
          <div className="row_align_items">
            <div
              className="edit_btn clickable"
              onClick={() => {
                if (!isAsking) {
                  setIsEditAnswer(true);
                  setAnswer(qaAskInfo);
                }
              }}
            >
              <EditIcon color="#FF3E9A" size={16} />
            </div>
            <Reference
              data={arrReferences}
              showAddButton={false}
              showExpandButton={false}
              itemId={Number(selectedOrgId)}
              itemType={'organisation'}
              onDoneAddReference={() => {}}
              setIsExpandReference={setIsExpandReference}
              isExpandReference={isExpandReference}
            />
          </div>
        </div>
        {isExpandReference && <ReferenceDetail data={arrReferencesForBelow} />}
      </>
    );
  };

  const renderEditAnswer = () => {
    return (
      <>
        <textarea
          value={answer}
          className="font-regular font14 card_share_question_org_question_text_area"
          rows={12}
          onChange={(e) => setAnswer(e.target.value)}
        />
        <div className="row_space_between">
          <div
            className="cancel_btn font-medium font12 clickable"
            onClick={() => {
              setIsEditAnswer(false);
            }}
          >
            Cancel
          </div>
          <div
            className="save_answer_btn font-bold font12 clickable"
            onClick={onSaveAnswer}
          >
            {isSavingAnswer ? (
              <LoadingCircular size={20} color="white" />
            ) : (
              <>Save</>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderNoramlQuestionCard = () => {
    return (
      <div className="card_share_question_org">
        <div className="card_share_question_org_user">
          <div className="card_share_question_org_user_image">
            {userInfo?.image === null ? (
              <img src={userInfo?.image} />
            ) : (
              <DefaultAvatarIcon />
            )}
          </div>
          <div className="card_share_question_org_user_name font12 font-bold">
            {userInfo?.name}
          </div>
        </div>
        <div className="card_share_question_org_question font-bold font20">
          {type === 'home' ? question.question : question.message}
        </div>
        {!isEditAnswer ? renderNormalAnswer() : renderEditAnswer()}
      </div>
    );
  };

  const renderQuestionWithAnswerCard = () => {
    return (
      <div className="card_share_question_org">
        <div className="card_share_question_org_user">
          <div className="card_share_question_org_user_image">
            {userInfo?.image === null ? (
              <img src={userInfo?.image} />
            ) : (
              <DefaultAvatarIcon />
            )}
          </div>
          <div className="card_share_question_org_user_name font12 font-bold">
            {userInfo?.name}
          </div>
        </div>
        <div className="card_share_question_org_question font-bold font20">
          {type === 'home' ? question.question : question.message}
        </div>
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={423} onClose={onClose}>
      <div className="modal_create_group">
        <div className="modal_create_group_header">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon opacity={0.4} />
          </div>
          <div className="title font-bold font16">
            Share Question to Schools
          </div>
        </div>
        <div className="modal_create_group_body">
          {checked
            ? renderNoramlQuestionCard()
            : renderQuestionWithAnswerCard()}
          {renderCheckBoxBar()}
          <div className="btn_create">
            <PrimaryButton
              label="Continue"
              isLoading={isCreating}
              onPressed={onContinue}
            />
          </div>
        </div>
      </div>
    </PrimaryModal>
  );
};
