import React, { useMemo, useState } from 'react';
import ShowMoreText from 'react-show-more-text';

import RobotIcon from 'assets/svgs/RobotIcon';

import './index.styles.scss';
import { ConversationReference } from 'components/Common/ConversationReference';
import { getArrFromString } from 'shared/utils/utils';
import { ReferenceDetail } from 'components/Common/ReferenceDetail';

interface CardFileAiAnswerProps {
  data?: any;
  enableShowMore: boolean;
}

export const CardFileAiAnswer = ({
  data,
  enableShowMore
}: CardFileAiAnswerProps) => {
  const [isExpandReference, setIsExpandReference] = useState(false);

  const arrReferencesForBelow = useMemo(() => {
    if (data?.ref_names || data?.ref_types) {
      const arrTmpRefData: Array<any> = [];

      // for user
      const arrTmpName = getArrFromString(data?.ref_names, ';;;');
      const arrTmpImage = getArrFromString(data?.ref_images, ';;;');
      // for files
      const arrTmpFileName = getArrFromString(
        data?.ref_titles ?? data?.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(data?.ref_urls, ';;;');
      const arrTmpTxt = getArrFromString(
        data?.ref_texts ?? data?.context,
        ';;;'
      );
      const arrTmpFIleTypes = getArrFromString(data?.ref_types, ';;;');

      if (arrTmpTxt.length > 0) {
        arrTmpTxt.map((item, index) => {
          arrTmpRefData.push({
            name: arrTmpName[index],
            fileName: arrTmpFileName[index],
            image: arrTmpImage[index],
            url: arrTmpFileUrl[index],
            text: arrTmpTxt[index],
            fileType: arrTmpFIleTypes[index]
          });
        });
      }

      return arrTmpRefData;
    } else {
      return [];
    }
  }, [data]);

  const arrReferences = useMemo(() => {
    if (data?.ref_names || data?.ref_types) {
      const arrTmpRefData: Array<any> = [];

      // for user
      const arrTmpName = getArrFromString(data?.ref_names, ';;;');
      const arrTmpImage = getArrFromString(data?.ref_images, ';;;');

      // for files
      const arrTmpFileName = getArrFromString(
        data?.ref_titles ?? data?.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(data?.ref_urls, ';;;');
      const arrTmpTxt = getArrFromString(data?.ref_texts, ';;;');
      const arrTmpFIleTypes = getArrFromString(data?.ref_types, ';;;');

      arrTmpName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpName[index],
          image: arrTmpImage[index],
          text: arrTmpTxt[index],
          type: 'user'
        });
      });

      arrTmpFileName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpFileName[index],
          image: arrTmpFileUrl[index],
          text: arrTmpTxt[index],
          type: 'file',
          fileType: arrTmpFIleTypes[index]
        });
      });

      if (!data?.ref_filenames) {
        arrTmpFileUrl.map((item, index) => {
          arrTmpRefData.push({
            name: arrTmpFileUrl[index],
            image: arrTmpFileUrl[index],
            text: arrTmpTxt[index],
            type: 'file',
            fileType: arrTmpFIleTypes[index]
          });
        });
      }

      // remove duplicated
      const uniqueArr = arrTmpRefData.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.image === value.image || t.name === value.name
          )
      );
      return uniqueArr;
    } else {
      return [];
    }
  }, [data]);

  const renderTop = () => {
    return (
      <div className="row_align_items">
        <RobotIcon size={20} />
        <div className="font-bold font15" style={{ marginLeft: 8 }}>
          File AI
        </div>
      </div>
    );
  };

  const renderAnswer = () => {
    return (
      <div className="answer font-regular font18">
        {enableShowMore ? (
          <ShowMoreText
            lines={4}
            className="font-regular font18"
            expanded={false}
            truncatedEndingComponent={'... '}
            more={
              <span className="show_action_txt font-bold font14">
                Show More
              </span>
            }
            less={
              <span className="show_action_txt font-bold font14">
                Show Less
              </span>
            }
          >
            <pre className="font18">{data?.ai_answer}</pre>
          </ShowMoreText>
        ) : (
          <pre className="font-regular font18">{data?.ai_answer}</pre>
        )}
      </div>
    );
  };

  const renderReference = () => {
    return (
      <ConversationReference
        data={arrReferences}
        setIsExpandReference={setIsExpandReference}
        isExpandReference={isExpandReference}
      />
    );
  };

  const renderReferenceDetail = () => {
    return (
      isExpandReference && <ReferenceDetail data={arrReferencesForBelow} />
    );
  };

  return (
    <div className="card_file_ai_answer">
      {renderTop()}
      {renderAnswer()}
      {renderReference()}
      {renderReferenceDetail()}
    </div>
  );
};
