import React, { useState } from 'react';
import moment from 'moment';

import './index.styles.scss';
import { EditEventModal } from 'components/Modals/EditEventModal';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import { EventActionModal } from 'components/Modals/EventActionModal';
import { ShareEventModal } from 'components/Modals/ShareEventModal';
import { useNavigate } from 'react-router-dom';
import TrashIcon from 'assets/svgs/TrashIcon';
import { apiDeletionEvent } from 'shared/api/delete';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

interface Props {
  data: any;
  onReloadData?: () => void;
}

export const CardEventHosted = ({ data, onReloadData }: Props) => {
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionModalPos, setActionModalPos] = useState({ top: 0, left: 0 });
  const [showShare, setShowShare] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const onDetail = (e) => {
    e.stopPropagation();
    navigate(`/school/events/${data.qr_code}`);
  };

  const onShowAddDlg = (e) => {
    e.stopPropagation();
    setShowActionModal(true);

    const clientRectangle = document!
      .getElementById(`event_edit_btn_${data.id}`)!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setActionModalPos({
      top: clientRectangle.top + 30,
      left: clientRectangle.left - 200
    });
  };

  const onDeleteEvent = async (e) => {
    e && e.stopPropagation();
    setShowActionModal(false);
    setIsRemoving(true);
    const res = await apiDeletionEvent({ event: data.id });

    setIsRemoving(false);
    if (res.success) {
      onReloadData && onReloadData();
    }
  };

  const renderEditModal = () => {
    return (
      <EditEventModal
        eventData={data}
        show={showEditModal}
        onClose={() => setShowEditModal(false)}
        onSuccess={() => {
          setShowEditModal(false);
          onReloadData && onReloadData();
        }}
      />
    );
  };

  const renderActionModal = () => {
    return (
      <EventActionModal
        show={showActionModal}
        posX={actionModalPos.left}
        posY={actionModalPos.top}
        onClose={() => {
          setShowActionModal(false);
        }}
        onSelected={(action) => {
          if (action === 'edit') {
            setShowEditModal(true);
          } else if (action === 'delete') {
            onDeleteEvent(null);
          } else if (action === 'share') {
            setShowActionModal(false);
            setShowShare(true);
          }
        }}
      />
    );
  };

  const renderShareModal = () => {
    return (
      <ShareEventModal
        show={showShare}
        eventData={data}
        onClose={() => {
          setShowShare(false);
        }}
      />
    );
  };

  return (
    <div
      className="card_event_hosted clickable row_align_items"
      onClick={onDetail}
    >
      <div style={{ width: 'calc(100% - 70px)' }}>
        <div className="row_align_items">
          <div className="card_mark"></div>
          <div className="card_title font-medium font16">{data.title}</div>
        </div>
        <div
          className="row_align_items"
          style={{ marginLeft: 16, marginTop: 6 }}
        >
          <div style={{ fontSize: 14 }}>🗓</div>
          <div
            className="font-regular font14"
            style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: 6 }}
          >
            {moment(data.date).format('DD MMM YYYY')} •{' '}
            {moment(data.date).format('hh:mm A')}
          </div>
        </div>
      </div>
      <div className="row_align_items" style={{ gap: 8, marginRight: 8 }}>
        <div
          style={{ width: 24 }}
          className="clickable"
          id={`event_edit_btn_${data.id}`}
          onClick={onShowAddDlg}
        >
          <EditPencilColoredIcon />
        </div>
        <div
          style={{ width: 24 }}
          className="clickable"
          onClick={onDeleteEvent}
        >
          {isRemoving ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            <TrashIcon color="#FF3E9A" />
          )}
        </div>
      </div>

      {renderEditModal()}
      {renderActionModal()}
      {renderShareModal()}
    </div>
  );
};
