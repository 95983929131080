import React from 'react';

function LineSpacingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 16}
      height={props.size ?? 16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FF3E9A"
        d="M4.193 6.473a.667.667 0 001.093-.217.667.667 0 00-.146-.73L3.807 4.194a.667.667 0 00-.22-.14.667.667 0 00-.507 0 .667.667 0 00-.22.14L1.527 5.527a.67.67 0 10.946.946l.194-.2v3.454l-.194-.2a.67.67 0 10-.946.946l1.333 1.334c.063.06.138.108.22.14a.628.628 0 00.507 0 .668.668 0 00.22-.14l1.333-1.334a.669.669 0 10-.947-.946l-.193.2V6.273l.193.2zm3.14-1.14H14A.667.667 0 0014 4H7.333a.667.667 0 000 1.333zm6.667 2H7.333a.667.667 0 100 1.334H14a.667.667 0 000-1.334zm0 3.334H7.333a.667.667 0 100 1.333H14a.667.667 0 100-1.333z"
      ></path>
    </svg>
  );
}

export default LineSpacingIcon;
