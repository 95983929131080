import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

import './index.styles.scss';
import { ListPreview } from './ListPreview';
import { LinkPreviewTagFilter } from './TagFilter';
import { LinkPreviewTopFilter } from './TopFilter';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import EmptyImage from 'assets/pngs/empty_world.png';
import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import FoloderDownloadIcon from 'assets/svgs/FolderDownloadIcon';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import RobotIcon from 'assets/svgs/RobotIcon';
import { apiGetContentLinks } from 'shared/api/content';

interface LinkPreviewProps {
  item_id: any;
  item_type: 'user' | 'group';
}

export const LinkPreview = ({ item_id, item_type }: LinkPreviewProps) => {
  const [searchText, setSearchText] = useState('');

  const [isReceived, setIsReceived] = useState(false);
  const [receivedCount, setReceivedCount] = useState(0);

  const [isBot, setIsBot] = useState(false);

  const [arrPreview, setArrPreview] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadCount = 13;
  const perDisplayCount = 7;
  const [arrDisplayPreview, setArrDisplayPreview] = useState<Array<any>>([]);

  const [totalLinkCount, setTotalLinkCount] = useState(0);
  const [selectedToics, setSelectedTopics] = useState('');
  const [selectedTags, setSelectedTags] = useState<Array<any>>([]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [searchText, selectedToics, selectedTags, isReceived, isBot]);

  const loadData = async (init = true) => {
    const arrTagIds = selectedTags.map((item, index) => item.item_id) + '';
    const arrTagTypes = selectedTags.map((item, index) => item.item_type) + '';

    if (init) {
      setIsLoadingPreview(true);
      const res = await apiGetContentLinks({
        query: searchText,
        topics: selectedToics === '' ? undefined : selectedToics,
        tag_ids: arrTagIds === '' ? undefined : arrTagIds,
        tag_types: arrTagTypes === '' ? undefined : arrTagTypes,
        item_id: item_id,
        item_type: item_type,
        received: isReceived,
        is_summarised: isBot ? 'true' : 'false',
        limit: loadCount,
        offset: 0
      });

      if (res.success) {
        setArrPreview(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setTotalLinkCount(res.total_count);
        setReceivedCount(res.received_count);
        setIsLoaded(true);

        if (res.data.length > perDisplayCount) {
          setArrDisplayPreview(res.data.slice(0, perDisplayCount));
        } else {
          setArrDisplayPreview(res.data);
        }
      }
      setIsLoadingPreview(false);
    } else {
      if (!isLoadingPreview && hasMore) {
        setIsLoadingPreview(true);

        const res = await apiGetContentLinks({
          query: searchText,
          topics: selectedToics === '' ? undefined : selectedToics,
          tag_ids: arrTagIds === '' ? undefined : arrTagIds,
          tag_types: arrTagTypes === '' ? undefined : arrTagTypes,
          item_id: item_id,
          item_type: item_type,
          received: isReceived,
          is_summarised: isBot ? 'true' : 'false',
          limit: loadCount,
          offset: lastId
        });

        if (res.success) {
          setArrPreview((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoadingPreview(false);
      }
    }
  };

  const onRemoveOnUI = (id) => {
    setArrPreview((prev) => prev.filter((item, index) => item.id != id));
  };

  const onClickLoadMore = () => {
    if (arrDisplayPreview.length <= arrPreview.length) {
      setArrDisplayPreview((prev) => [
        ...prev,
        arrPreview.slice(prev.length, arrPreview.length)
      ]);

      loadData(false);
    } else {
      loadData(false);
    }
  };

  const onChangedTagFilter = (tags) => {
    setSelectedTags(tags);
  };

  const onChangedTopicFilter = (topics) => {
    setSelectedTopics(topics + '');
  };

  const renderTop = () => {
    return (
      <div
        className="row_align_items row_space_between"
        style={{ padding: '0px 32px' }}
      >
        <LinkPreviewTopFilter
          isReceived={isReceived}
          isBot={isBot}
          item_id={item_id}
          item_type={item_type}
          totalLinkCount={item_type === 'user' ? totalLinkCount : receivedCount}
          onChangedSelection={onChangedTopicFilter}
        />
        <div className="row_align_items">
          <SearchBox
            onSearch={setSearchText}
            width={'300px'}
            height={'48px'}
            fontSize={14}
          />
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Check for web links received by other users
              </div>
            }
          >
            <div
              className={`${
                isBot ? 'selected_received_btn' : 'received_btn'
              } clickable`}
              onClick={() => setIsBot((prev) => !prev)}
            >
              <RobotIcon size={24} color="#FF3E9A" />
            </div>
          </Tooltip>
          {item_type !== 'group' && (
            <Tooltip
              arrow
              title={
                <div className="font-regular font14">
                  Check for web links received by other users
                </div>
              }
            >
              <div
                className={`${
                  isReceived ? 'selected_received_btn' : 'received_btn'
                } clickable`}
                onClick={() => setIsReceived((prev) => !prev)}
              >
                <FoloderDownloadIcon size={24} color="#FF3E9A" />
                {receivedCount > 0 && (
                  <div className="received_count_not font-bold font12">
                    {receivedCount}
                  </div>
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  const renderTag = () => {
    return (
      <div className="link_preview_tag">
        <LinkPreviewTagFilter
          itemId={item_id}
          itemType={item_type}
          onChangedSelection={onChangedTagFilter}
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div style={{ height: 'calc(100% - 200px)' }}>
        <ListPreview
          data={arrPreview}
          item_type={item_type}
          onReload={loadData}
          onRemoveOnUI={onRemoveOnUI}
        />
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div className="empty_container">
        <div className="empty_body">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <img src={EmptyImage} style={{ width: 70, height: 70 }} />
            <div className="font-regular font24" style={{ marginTop: 12 }}>
              No browsing history found
            </div>
            <div
              className="font-regular font18"
              style={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: 8 }}
            >
              Install the browser extension and your history will show here.
              (Available soon)
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLoadingBar = () => {
    return (
      <div className="empty_container">
        <div className="empty_body">
          <LoadingBarAnimation />
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="bottom_container">
        <div
          className="load_more_btn row_align_items clickable"
          onClick={() => {
            onClickLoadMore();
          }}
        >
          <div className="font-bold font16">Load More</div>
          <div style={{ marginLeft: 6 }}>
            <AngleDownIcon color="#FF3E9A" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="link_preview"
      style={item_type === 'group' ? { marginLeft: 32 } : {}}
    >
      {renderTop()}
      {renderTag()}
      {renderBody()}
      {isLoaded && hasMore && renderBottom()}
      {isLoaded && arrPreview.length === 0 && renderEmpty()}
      {isLoadingPreview && !isLoaded && renderLoadingBar()}
    </div>
  );
};
