import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { apiOrgRequestJoin } from 'shared/api/org';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

interface CardOrgProps {
  data: any;
  setSelectedOrgs: any;
}

export const CarOrg = ({ data, setSelectedOrgs }: CardOrgProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const onSelect = async (orgId) => {
    setIsSelected(true);
    setSelectedOrgs((prev) => [...prev, orgId]);
  };
  const onUnselect = (orgId) => {
    setIsSelected(false);
    setSelectedOrgs((prev) => prev.filter((item) => item !== orgId));
  };
  return (
    <div className="card_org">
      <div className="org_image">
        <img src={data.image} className="org_box_icon" />
      </div>
      <div className="org_info">
        <div className="first_line">
          <div className="org_title font-bold font14">{data.name}</div>
        </div>
        <div className="second_line">
          <div className="members_count font12 font-normal">
            {data.member_number} members
          </div>
        </div>
      </div>
      <div className="org_select">
        {!isSelected ? (
          <div
            className="select_btn text-white font12 font-bold clickable"
            onClick={() => onSelect(data.id)}
          >
            Select
          </div>
        ) : (
          <div
            className="selected_btn font12 font-bold clickable"
            onClick={() => onUnselect(data.id)}
          >
            Selected
          </div>
        )}
      </div>
    </div>
  );
};
