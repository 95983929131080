import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import FileUploadIcon from 'assets/svgs/FileUploadIcon';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiQAAnswer } from 'shared/api/qa';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { getBase64String } from 'shared/utils-IPFS/functions';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { CardFileAnswer } from './CardFileAnswer';
import appAction from 'redux/app/action';

import './index.styles.scss';
import { showToast } from 'shared/ui-kit/PrimaryToast';
interface AddAnswerProps {
  onSuccess: () => void;
}

export const AddAnswer = ({ onSuccess }: AddAnswerProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const { uploadBase64 } = useIPFS();
  const dispatch = useDispatch();

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [answer, setAnswer] = useState('');
  const [fileAnswer, setFileAnswer] = useState<Array<any>>([]);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isFileOrText, setIsFileOrText] = useState(false);
  const [mediaType, setMediaType] = useState('text');
  const [url, setUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState(0);

  const params = useParams();
  const questionId = String(params.code);

  const onSave = async () => {
    if (!isCreating && (!!answer || fileAnswer.length > 0)) {
      setIsCreating(true);

      const res = await apiQAAnswer({
        bookmark: questionId,
        answer: mediaType === 'text' ? answer : url,
        is_anonymous: isAnonymous,
        media_type: mediaType,
        filename: mediaType === 'doc' ? fileName : undefined,
        size: mediaType === 'doc' ? fileSize : undefined
      });

      if (res.sucess) {
        showToast('success', 'Successfully post answer on school');

        setUrl('');
        setAnswer('');
        handleScrollTo();
        setFileAnswer([]);
        setMediaType('text');
        setIsFileOrText(false);

        onSuccess();
        dispatch(appAction.refreshOrgOtherAnswer());
      }

      setIsCreating(false);
    }
  };

  const handleScrollTo = () => {
    const element = document.getElementById('other_answers_section');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const uploadAttachment = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept =
      'application/msword, application/vnd.ms-powerpoint, .ppt, .pptx, text/plain, application/pdf';
    inputElement.multiple = true;

    // set onchange event to call callback when user has selected file
    inputElement.addEventListener('change', fileInputMessageAttachment);

    // dispatch a click event to open the file dialog
    inputElement.dispatchEvent(new MouseEvent('click'));
  };

  const fileInputMessageAttachment = async (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length) {
      const name = files[0].name;
      const size = files[0].size;
      const fileType = files[0].type;

      setIsUploadingFile(true);

      const currentTime = new Date();
      const tmpFileId = currentTime.getTime();
      const tmpData = {
        isDummy: true,
        id: tmpFileId,
        filename: name,
        size: (parseInt(size) / 1000000).toFixed(2),
        dump_type: 'pdf',
        status: 'uploading'
      };

      setFileAnswer([tmpData]);

      const base64String = await getBase64String(files[0]);
      const url = await uploadBase64(base64String, fileType, '');
      setUrl(url + '');
      setFileName(name);
      setFileSize(parseInt(size) / 1000000);
      onUpdateDumpStatus(tmpFileId, 'processing');
      setIsFileOrText(true);
      setMediaType('doc');
      setIsUploadingFile(false);
    }
  };

  const onUpdateDumpStatus = (dumpId, status) => {
    setFileAnswer((prev) =>
      prev.map((item, index) => {
        if (item.id == dumpId) {
          return { ...item, status: status };
        } else {
          return item;
        }
      })
    );
  };

  const renderEditAnswer = () => {
    return (
      <textarea
        value={answer}
        className="font-regular font20 answer_text_area"
        onChange={(e) => setAnswer(e.target.value)}
        disabled={isFileOrText}
      />
    );
  };

  const renderBottomBar = () => {
    return (
      <div className="add_answer_bottom_bar">
        <div className="add_answer_bottom_bar_icons">
          {isUploadingFile ? (
            <div />
          ) : (
            <div
              className="upload_file_btn clickable"
              onClick={uploadAttachment}
              id="label-file-upload"
            >
              <>
                <FileUploadIcon color="#ff3e9a" />
                <div className="upload_file_btn_txt font-medium font14">
                  Upload a File
                </div>
              </>
            </div>
          )}
        </div>
        <div className="add_answer_bottom_bar_btns">
          <div className="add_answer_bottom_bar_referneces">
            <div
              className={
                !isAnonymous ? 'user_star_btn_selected' : 'user_star_btn'
              }
              key={`card_ref_user_1`}
              onClick={() => setIsAnonymous(false)}
            >
              {userInfo?.image ? (
                <img className="user_avatar" src={userInfo?.image} />
              ) : (
                <DefaultAvatarIcon size={24} />
              )}
              <div className="font-medium font14">{userInfo?.name}</div>
            </div>
            <div
              className={
                isAnonymous ? 'user_star_btn_selected' : 'user_star_btn'
              }
              key={`card_ref_user_2`}
              onClick={() => setIsAnonymous(true)}
            >
              <DefaultAvatarIcon size={24} />
              <div className="font-medium font14">Anonymous</div>
            </div>
          </div>
          <div className="add_answer_btn clickable" onClick={() => onSave()}>
            {isCreating ? (
              <LoadingCircular color="white" size={24} />
            ) : (
              <div
                className="font-bold font16"
                style={{ whiteSpace: 'nowrap' }}
              >
                Post Answer
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="add_answer_box">
      <div className="font-bold font24">Add Answer</div>
      <div className="add_answer_detail_box">
        {fileAnswer.length === 0 && renderEditAnswer()}
        {fileAnswer.map((item, index) => {
          return (
            <CardFileAnswer
              key={`my_draft_answer_${index}`}
              data={item}
              setFileAnswer={setFileAnswer}
              setMediaType={setMediaType}
              setIsFileOrText={setIsFileOrText}
            />
          );
        })}
        {renderBottomBar()}
      </div>
    </div>
  );
};
