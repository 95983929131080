import React, { useEffect, useState } from 'react';
import { RelatedQuestion } from './RelatedQustions';
import './index.styles.scss';
import { OtherAnswers } from './OtherAnswers';
import { PostAnswer } from './PostAnswer';
import { apiGetQAAsk } from 'shared/api/qa';
import { AddAnswer } from './AddAnswer';
import { useNavigate } from 'react-router-dom';

import AngleRightIcon from 'assets/svgs/AngleRightIcon';
interface QuestionRightPanelProps {
  selectedOrg: any;
  setTab: any;
  questionDetail: any;
  questionId: any;
  textQuestion: string;
  refreshOtherAnswers: boolean;
  arrOrganisation: any;
  questionPoints: any;
  setQuestionPoints: any;
  initSection: any;
  onSuccess: () => void;
}
export const QuestionRightPanel = ({
  selectedOrg,
  setTab,
  questionDetail,
  questionId,
  textQuestion,
  refreshOtherAnswers,
  arrOrganisation,
  questionPoints,
  setQuestionPoints,
  initSection,
  onSuccess
}: QuestionRightPanelProps) => {
  const navigate = useNavigate();

  const onHome = () => {
    navigate(-1);
  };

  const [question, setQuestion] = useState('');
  const [isAsking, setIsAsking] = useState(false);
  const [qaAskInfo, setQaAskInfo] = useState<{ [key: string]: any } | null>(
    null
  );

  useEffect(() => {
    loadQuestionData(question);
  }, [question]);

  const loadQuestionData = async (question: string) => {
    if (question) {
      setIsAsking(true);
      setQuestion(question);

      const res = await apiGetQAAsk({
        question: question,
        to: 30,
        to_type: 'organisation'
      });

      if (res.success) {
        setQaAskInfo(res.data);
      } else {
        setQaAskInfo(null);
      }

      setIsAsking(false);
    } else {
      setQaAskInfo(null);
    }
  };

  const renderNavigation = () => {
    return (
      <div className="detail_right_panel_tab">
        <div
          className={`detail_right_panel_tab_item font-bold font24 clickable`}
          onClick={() => {
            onHome();
          }}
        >
          School
        </div>
        <div style={{ marginLeft: 20 }}>
          <AngleRightIcon />
        </div>
        <div
          className={`detail_right_panel_tab_item_selected font-bold font24 clickable`}
          style={{ marginLeft: 20 }}
        >
          Question Detail
        </div>
      </div>
    );
  };

  return (
    <div className="question_detail_right_panel">
      {renderNavigation()}
      <div>
        <PostAnswer
          selectedOrg={selectedOrg}
          textQuestion={textQuestion}
          setQuestion={setQuestion}
          qaAskInfo={qaAskInfo}
          isAsking={isAsking}
          onUpdateQAInfo={setQaAskInfo}
          setTab={setTab}
          questionDetail={questionDetail}
          questionPoints={questionPoints}
        />
        <OtherAnswers
          questionId={questionId}
          refreshOtherAnswers={refreshOtherAnswers}
          setQuestionPoints={setQuestionPoints}
          orgInfo={questionDetail}
          initFocus={initSection === 'answer'}
        />

        <AddAnswer onSuccess={() => onSuccess()} />
        <RelatedQuestion
          arrOrganisation={arrOrganisation}
          selectedOrg={selectedOrg}
          textQuestion={textQuestion}
        />
      </div>
    </div>
  );
};
