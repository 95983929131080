import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import DocumentLayoutIcon from 'assets/svgs/DocumentLayoutIcon';
import FileIcon from 'assets/svgs/FileIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import MicIcon from 'assets/svgs/MicIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.styles.scss';

interface ReferenceData {
  name: string;
  image: string;
  type: 'user' | 'file';
  text: string;
  fileType: 'pdf' | 'url' | 'text' | 'audio';
}

interface ReferenceProps {
  data: Array<ReferenceData>;
  setIsExpandReference: any;
  isExpandReference: boolean;
}

export const ConversationReference = ({
  data,
  setIsExpandReference,
  isExpandReference
}: ReferenceProps) => {
  const navigate = useNavigate();

  const onClickRef = (itemData) => {
    if (itemData.fileType === 'pdf') {
      navigate(`/document/${itemData.id}`);
    } else if (itemData.fileType === 'url' || itemData.fileType === 'html') {
      window.open(itemData.image);
    }
  };

  const onClickUser = (itemData) => {
    navigate(`/profile/${itemData.id}`);
  };

  const renderUserRef = (refData: ReferenceData, index) => {
    return (
      <div
        className="conversation_reference_item_user"
        key={`conversation_reference_item_${index}`}
        onClick={() => onClickUser(refData)}
      >
        {refData.image && refData.image.startsWith('https') ? (
          <img
            src={refData.image}
            style={{ width: 16, height: 16, borderRadius: 16 }}
          />
        ) : (
          <DefaultAvatarIcon size={16} />
        )}
      </div>
    );
  };

  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 28, height: 28 }}>
        {type === 'pdf' ? (
          <PdfFileIcon size={28} />
        ) : type === 'audio' ? (
          <MicIcon size={24} color="#FF3E9A" opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={28} color="#FF3E9A" opacity={1} />
        ) : (
          <FileIcon />
        )}
      </div>
    );
  };

  const renderFileRef = (refData: ReferenceData, index) => {
    return (
      <div
        className="conversation_reference_item clickable"
        key={`conversation_reference_item_${index}`}
        onClick={() => onClickRef(refData)}
      >
        {renderFileIcon(refData.fileType)}
        <span className="conversation_reference_name font-regular font14">
          {refData.name}
        </span>
      </div>
    );
  };

  return (
    <div className="conversation_reference_container">
      {data.length > 0 && (
        <div
          className={'conversation_reference_action_btn clickable'}
          style={{
            borderColor: isExpandReference ? '#FF3E9A' : 'rgba(0, 0, 0, 0.1)'
          }}
          onClick={() => setIsExpandReference((prev: boolean) => !prev)}
        >
          <DocumentLayoutIcon
            size={16}
            color={isExpandReference ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
          />
        </div>
      )}
      {data.map((item, index) => {
        if (item.type === 'user') return renderUserRef(item, index);
        else return renderFileRef(item, index);
      })}
    </div>
  );
};
