import React, { useEffect, useState } from 'react';
import './index.styles.scss';
import { CardAnswer } from './CardAnswer';
import { CardFileAnswer } from './CardFileAnswer';
import CommentAltCheckIcon from 'assets/svgs/CommentAltCheckIcon';
import TypographyIcon from 'assets/svgs/TypographyIcon';
import DocumentLayoutIcon from 'assets/svgs/DocumentLayoutIcon';
import { apiGetQAAnswers } from 'shared/api/qa';

import EmptyUserIcon from 'assets/pngs/user_with_hand_grey.png';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
interface OtherAnswersProps {
  questionId: number;
  refreshOtherAnswers: boolean;
  setQuestionPoints: any;
  orgInfo: any;
  initFocus: boolean;
}

export const OtherAnswers = ({
  questionId,
  refreshOtherAnswers,
  setQuestionPoints,
  orgInfo,
  initFocus
}: OtherAnswersProps) => {
  const { hasNewOrgOtherAnswer } = useSelector((state: RootState) => state.app);

  const [filterType, setFilterType] = useState<string>('all');
  const [otherAnswers, setOtherAnswers] = useState<Array<any>>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [lastId, setLastId] = useState(0);

  useEffect(() => {
    setFilterType('all');
    loadOtherAnswers(true);
  }, [refreshOtherAnswers, hasNewOrgOtherAnswer]);

  const handleScrollTo = () => {
    const element = document.getElementById('other_answers_section');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const loadOtherAnswers = async (init) => {
    if (init) {
      const res = await apiGetQAAnswers({ bookmark: questionId, limit: 5 });
      if (res.success) {
        setOtherAnswers(res.data);
        setHasMore(res.hasMore);
        setLastId(res.lastId);
      }
      if (initFocus) {
        handleScrollTo();
      }
    } else {
      const res = await apiGetQAAnswers({
        bookmark: questionId,
        limit: 5,
        offset: lastId
      });
      if (res.success) {
        setOtherAnswers((prev) => [...prev, ...res.data]);
        setHasMore(res.hasMore);
        setLastId(res.lastId);
      }
    }
  };

  const onShowMore = () => {
    loadOtherAnswers(false);
  };

  const handleUpdateVote = (point) => {
    setQuestionPoints(point);
  };

  const onRemoveFromUI = (answerId) => {
    setOtherAnswers((prev) => {
      return prev.filter((item, index) => item.id != answerId);
    });
  };

  const renderAnswerFilterBar = () => {
    return (
      <div className="answer_filter_bar">
        <div
          className={
            filterType == 'all' ? 'filter_bar_item_selected' : 'filter_bar_item'
          }
          onClick={() => setFilterType('all')}
        >
          <CommentAltCheckIcon color="#FF3E9A" size="24" />
          <div className="font-bold font16">All Answer</div>
        </div>
        <div
          className={
            filterType == 'text'
              ? 'filter_bar_item_selected'
              : 'filter_bar_item'
          }
          onClick={() => setFilterType('text')}
        >
          <TypographyIcon />
          <div className="font-bold font16">Text Answers</div>
        </div>
        <div
          className={
            filterType == 'file'
              ? 'filter_bar_item_selected'
              : 'filter_bar_item'
          }
          onClick={() => setFilterType('file')}
        >
          <DocumentLayoutIcon />
          <div className="font-bold font16">Documents</div>
        </div>
      </div>
    );
  };

  const renderEmptyAnswer = () => {
    return (
      <div className="empty_view">
        <img src={EmptyUserIcon} style={{ width: 56, height: 56 }} />
        <div className="font-bold font18" style={{ marginTop: 16 }}>
          Nobody answered yet
        </div>
        <div
          className="font-regular font14"
          style={{ marginTop: 8, textAlign: 'center' }}
        >
          Be the first to post an answer
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="related_question_list">
        {otherAnswers?.map((item, index) => {
          return (
            <>
              {item.media_type == 'text' ? (
                <CardAnswer
                  key={`card_other_text_answer_${index}`}
                  filterType={filterType}
                  data={item}
                  orgInfo={orgInfo}
                  onVoteUpdate={(point) => handleUpdateVote(point)}
                  onRemoveFromUI={onRemoveFromUI}
                />
              ) : (
                <CardFileAnswer
                  key={`card_other_file_answer_${index}`}
                  filterType={filterType}
                  data={item}
                />
              )}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div
        className="font-bold font24"
        style={{ marginTop: 92 }}
        id="other_answers_section"
      >
        Other Answers
      </div>
      {otherAnswers?.length > 0 && renderAnswerFilterBar()}
      <div style={{ marginBottom: 32 }}></div>
      {otherAnswers?.length > 0 ? renderBody() : renderEmptyAnswer()}
      {hasMore && filterType !== 'file' ? (
        <div
          className="show_more_answer_btn font16 font-bold"
          onClick={() => onShowMore()}
        >
          Show More
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
