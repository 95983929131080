import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig } from 'axios';
import { redirect } from 'react-router-dom';
import { getToken, setToken, removeCookie } from './storageHelper';
import jwt_decode from "jwt-decode";

const REFRESH_TIME = process.env.REACT_APP_REFRESH_TIME || 86400000; //1 day before

class HttpClient {
  httpClient: AxiosInstance;
  axiosWithoutInterceptor: AxiosInstance;

  constructor(baseURL: string) {
    const httpClient = axios.create({
      baseURL
    });
    const axiosWithoutInterceptor = axios.create({
      baseURL,
    });
    this.httpClient = httpClient;
    this.axiosWithoutInterceptor = axiosWithoutInterceptor;

    this.addAuthInterceptor();
  }

  addAuthInterceptor() {
    let refreshTokenPromise: Promise<any> | null = null;
    
    this.httpClient.interceptors.request.use(async (request: AxiosRequestConfig) => {
      // Skip interceptor if this is a refresh token request
      if (request.url === '/user/refreshToken') {
        return request;
      }
  
      const token = await getToken();
  
      if (token) {
        const decodedToken: any = jwt_decode(token);
        
        if (decodedToken.exp * 1000 < Date.now() + Number(REFRESH_TIME)) {
          console.log("Token is about to expire, attempting to refresh...");
          
          if (!refreshTokenPromise) {
            refreshTokenPromise = this.axiosWithoutInterceptor.get('/user/refreshToken', {
              headers: { Authorization: `Bearer ${token}` },
            }).then(async res => {
              refreshTokenPromise = null;  // Once finished, allow new refresh requests in the future
              if (res.data.success) {
                await setToken(res.data.accessToken);
              }
            }).catch(error => {
              console.error("Failed to refresh token: ", error);
              refreshTokenPromise = null;  // In case of failure, allow new attempts
            });
          }
          
          await refreshTokenPromise;
  
          request.headers = {
            ...request.headers,
            Authorization: `Bearer ${await getToken()}`,
          } as unknown as AxiosHeaders;;
          return request;
        }
  
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${token}`,
        } as unknown as AxiosHeaders;;
      }
  
      return request;
    });
  }

  post(apiEndpoint: string, params: any, config?: any) {
    try {
      return this.httpClient.post(apiEndpoint, params, config);
    } catch (error) {
      throw error;
    }
  }

  get(apiEndpoint: string, params: any = {}) {
    return this.httpClient.get(apiEndpoint, { params });
  }

  async getWithToken(apiEndpoint: string, params: any = {}) {
    this.httpClient.interceptors.request.use(async (request) => {
      const token = await getToken();
      request!.headers = { ...request!.headers } as AxiosHeaders;
      request!.headers = {
        Authorization: `Bearer ${token}`
      };

      return request;
    });

    try {
      const response = await this.httpClient.get(apiEndpoint, { params });
      return response;
    } catch (error: any) {
      if (error.response.status === 403) {
        await removeCookie();
        redirect('/');
      }
    }
  }

  async postWithToken(apiEndpoint: string, params = {}, config = {}) {
    this.httpClient.interceptors.request.use(async (request) => {
      const token = await getToken();
      request!.headers = { ...request!.headers } as AxiosHeaders;
      request!.headers = {
        Authorization: `Bearer ${token}`
      };

      return request;
    });

    try {
      const response = await this.httpClient.post(apiEndpoint, params, config);
      return response;
    } catch (error: any) {
      if (error.response.status === 403) {
        await removeCookie();
        redirect('/');
      }
    }
  }
}

export default new HttpClient(
  process.env.REACT_APP_SERVER_URL ?? 'http://localhost:3000'
);
