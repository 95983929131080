import React from 'react';

function EnvelopePlusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 21}
      height={props.size ?? 21}
      fill="none"
      viewBox="0 0 21 18"
    >
      <path
        fill="#FF3E9A"
        d="M15.25 4h1v1a1 1 0 002 0V4h1a1 1 0 100-2h-1V1a1 1 0 00-2 0v1h-1a1 1 0 100 2zm4 4a1 1 0 00-1 1v6a1 1 0 01-1 1h-14a1 1 0 01-1-1V5.41l5.88 5.89a3 3 0 004.24 0l2.47-2.47a1.004 1.004 0 10-1.42-1.42l-2.47 2.47a1 1 0 01-1.4 0L3.66 4h7.59a1 1 0 100-2h-8a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V9a1 1 0 00-1-1z"
      ></path>
    </svg>
  );
}

export default EnvelopePlusIcon;
