import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import RoundArrowLeftIcon from 'assets/svgs/RoundArrowLeftIcon';
import React, { useState, useRef, useEffect } from 'react';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';
import Tooltip from '@mui/material/Tooltip';

import FirstIcon from 'assets/svgs/FirstIcon';
import LastIcon from 'assets/svgs/LastIcon';
import PrevIcon from 'assets/svgs/PrevIcon';
import NextIcon from 'assets/svgs/NextIcon';
import AngleDoubleUpIcon from 'assets/svgs/AngleDoubleUpIcon';

import { PageChangeEvent, ScrollMode, Viewer } from '@react-pdf-viewer/core';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import {
  HighlightArea,
  highlightPlugin,
  RenderHighlightsProps,
  RenderHighlightTargetProps
} from '@react-pdf-viewer/highlight';
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
  RenderGoToPageProps
} from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { useDebounce } from 'use-debounce';

import './index.styles.scss';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import jumpToPagePlugin from 'shared/plugins/JumpToPagePlugin';
import { DocumentQuestion } from '../DocumentQuestion';
import QuestionIcon from 'assets/svgs/QuestionIcon';
import AngleDoubleDownIcon from 'assets/svgs/AngleDoubleDownIcon';
import { SummarizeModal } from 'components/Modals/SummarizeModal';
import { DocumentFileAiView } from './FileAiView';
import { HighlightConversation } from './HighlightConversation';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { apiQAFile } from 'shared/api/qa';
import { apiFileHighLight, apiFileSummarize } from 'shared/api/file';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { socket } from 'components/Auth';

export const DocumentViewer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dumpId = params.dumpId!;

  const [fileName, setFileName] = useState('');
  const [fileUrl, setFileUrl] = useState(null);

  const scrollModePluginInstance = scrollModePlugin();
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const jumpToPagePluginInstance = jumpToPagePlugin();
  const { jumpToPage } = jumpToPagePluginInstance;

  const highLightPropsRef = useRef<any>();

  const {
    GoToFirstPage,
    GoToLastPage,
    GoToNextPage,
    GoToPreviousPage,
    CurrentPageLabel
  } = pageNavigationPluginInstance;

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [jumpPage, setJumpPage] = useState(0);
  const [zoomPercent, setZoomPercent] = useState(100);
  const [selectedData, setSelectedData] = useState<HighlightData | undefined>(
    undefined
  );

  const [showFileAi, setShowFileAi] = useState(false);
  const [showHighlightConversation, setShowHighlightConversation] = useState(
    false
  );
  const [showSummarizeModal, setShowSummarizeModal] = useState(false);
  const [showAskQuestion, setShowAskQuestion] = useState(false);
  const [addDlgPos, setAddDlgPos] = useState({ top: 0, left: 0 });
  const [showBackDocBtn, setShowBackDocBtn] = useState(false);

  // ask
  const [answer, setAnswer] = useState<any>(null);
  const [summaryType, setSummaryType] = useState<
    'selection' | 'whole' | 'custom'
  >('selection');
  const [highlightId, setHightlightId] = useState(undefined);
  const [isWholeSummarizing, setIsWholeSummarizing] = useState(false);
  const [isPartSummarizing, setIsPartSummarizing] = useState(false);

  // highlight
  const [arrHighLight, setArrHighLight] = useState([]);
  const [isLoadingHighLight, setIsLoadingHighLight] = useState(false);
  const [debouncedPageValue] = useDebounce(currentPage, 1000);

  useEffect(() => {
    loadDatil();
  }, []);

  useEffect(() => {
    onAdjustInput();
  }, [jumpPage]);

  useEffect(() => {
    loadHighLight(debouncedPageValue);
  }, [debouncedPageValue]);

  useEffect(() => {
    if (socket && highlightId) {
      socket.emit('join-room', `file-${highlightId}`);

      const newMessageHandler = (data) => {
        onReceivedSummary(data);
      };

      socket.on('file-summary', newMessageHandler);

      return () => {
        socket.removeListener('file-summary', newMessageHandler);
      };
    }
  }, [socket, highlightId]);

  useEffect(() => {
    if (!showAskQuestion) {
      onScrollToDocumentTop();
    }
  }, [showAskQuestion]);

  useEffect(() => {
    if (showAskQuestion) {
      setShowBackDocBtn(true);
    } else {
      setShowBackDocBtn(false);
    }
  }, [showAskQuestion]);

  const loadDatil = async () => {
    const res = await apiQAFile({
      dump: dumpId
    });

    if (res.success) {
      setFileName(res.data.filename);
      setFileUrl(res.data.url);
    }
  };

  const loadAnswer = async (
    type,
    startPage,
    endPage,
    selectedInfo: any = null
  ) => {
    setSummaryType(type);

    if (type === 'whole') {
      setIsWholeSummarizing(true);

      const res = await apiFileSummarize({
        dump: dumpId,
        text: '',
        type: 'general'
      });

      setHightlightId(res.highlight_id);
      setAnswer(res.summary);
      setShowFileAi(true);

      setIsWholeSummarizing(false);
    } else if (type === 'selection') {
      setIsPartSummarizing(true);

      const res = await apiFileSummarize({
        dump: dumpId,
        text: selectedInfo?.quote,
        type: 'highlight'
      });

      // if (highLightPropsRef.current) {
      //   highLightPropsRef!.current!.cancel();
      // }

      setHightlightId(res.highlight_id);
      setShowFileAi(true);
    } else if (type === 'custom') {
      setIsPartSummarizing(true);

      const res = await apiFileSummarize({
        dump: dumpId,
        text: '',
        type: 'pagewise',
        from_page: startPage,
        to_page: endPage
      });

      setHightlightId(res.highlight_id);
      setShowFileAi(true);
    }
  };

  const loadHighLight = async (pageNumber) => {
    if (!isLoadingHighLight) {
      setIsLoadingHighLight(true);

      const res = await apiFileHighLight({
        dump: dumpId,
        from_page: pageNumber - 1 < 1 ? 1 : pageNumber - 1,
        to_page: pageNumber + 2,
        limit: 100,
        offset: 0
      });

      if (res.success) {
        const arrTmpData = res.data.map((item, index) => {
          return {
            id: item.id,
            highlightAreas: [
              {
                height: item.height,
                left: item.left,
                pageIndex: item.pageIndex,
                top: item.top,
                width: item.width
              }
            ]
          };
        });

        setArrHighLight(arrTmpData);
      }

      setIsLoadingHighLight(false);
    }
  };

  const onReceivedSummary = (data) => {
    if (data.summary === '') {
      setAnswer(selectedData?.quote ?? 'There is no summary');
    } else {
      setAnswer(data.summary);
    }

    setShowFileAi(true);
    setIsPartSummarizing(false);
  };

  const onBack = () => {
    navigate(-1);
  };

  const onAdjustInput = () => {
    const pageNumInputEle = document.getElementById('page_num_input');
    pageNumInputEle!.style.width = jumpPage.toString().length + 10 + 'ch';
  };

  const onShowSummarizeDlg = (e) => {
    setShowSummarizeModal(true);

    const clientRectangle = document!
      .getElementById('btn_summarize')!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setAddDlgPos({
      top:
        bottomFromWindow < 200
          ? clientRectangle.top - 200
          : clientRectangle.top,
      left: clientRectangle.left - 130
    });
  };

  const onScrollToDocumentTop = () => {
    const viewer = document.getElementById('document_viewer');
    viewer?.scrollIntoView();
  };

  const onGoToPage = (pageNumber = jumpPage) => {
    if (pageNumber >= 0 && pageNumber <= totalPage) {
      jumpToPage(pageNumber);
    }
  };

  const onSelectedPdfText = (hightlightProps: RenderHighlightTargetProps) => {
    if (!isPartSummarizing) {
      highLightPropsRef.current = hightlightProps;

      const newSelection: HighlightData = {
        highlightAreas: hightlightProps.highlightAreas,
        quote: hightlightProps.selectedText
      };

      // hightlightProps.cancel();
      setSelectedData(newSelection);
      loadAnswer('selection', 0, 0, newSelection);
    }
  };

  const onSelectedPage = (pageNum) => {
    if (pageNum > totalPage) {
      onGoToPage(totalPage);
    } else {
      onGoToPage(pageNum);
    }
  };

  const handlePageChange = (e: PageChangeEvent) => {
    setCurrentPage(e.currentPage);
  };

  const onSelectedHighlight = (highLightData) => {
    setHightlightId(highLightData.id);
    setShowFileAi(false);
    setShowHighlightConversation(true);
  };

  const onClickBackBtn = () => {
    setShowFileAi(false);
    setShowAskQuestion(false);
    setShowHighlightConversation(false);
  };

  const renderHeader = () => {
    return (
      <div className="document_header">
        <div className="row_align_items">
          <div className="clickable" onClick={onBack}>
            <RoundArrowLeftIcon isEnable={true} />
          </div>
          <div style={{ marginLeft: 16 }}>
            <PdfFileIcon />
          </div>
          <div className="font-regular font18" style={{ marginLeft: 8 }}>
            {fileName}
          </div>
        </div>
        <div className="row_align_items">
          {/* <ZoomLevelView
            zoomPercent={zoomPercent}
            zoomPluginInstance={zoomPluginInstance}
            onChanged={setZoomPercent}
          /> */}
          <div className="go-to-box">
            <div
              className="font-regular font18"
              style={{ marginLeft: 8, color: 'rgba(0 0 0 0.3)' }}
            >
              Go to page
            </div>
            <input
              type="number"
              id="page_num_input"
              onChange={(e) => setJumpPage(Number(e.target.value))}
              style={{}}
            />
            <button onClick={() => onSelectedPage(jumpPage)}>Go</button>
          </div>
        </div>
      </div>
    );
  };

  const renderHighlightTarget = (props: RenderHighlightTargetProps) => {
    return (
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform: 'translate(0, 8px)',
          zIndex: 1
        }}
      >
        <div
          className="clickable row_align_items"
          style={{
            width: 120,
            height: 30,
            borderRadius: 40,
            border: '1px solid rgba(0, 0, 0, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white'
          }}
          onClick={() => {
            onSelectedPdfText(props);
          }}
        >
          {isPartSummarizing ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            <div className="row_align_items">
              <EditPencilColoredIcon size={16} />
              <div
                className="font-bold font12"
                style={{ color: '#FF3E9A', marginLeft: 8 }}
              >
                Summarize
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <div>
        <React.Fragment>
          {selectedData &&
            selectedData.highlightAreas
              .filter((area) => area.pageIndex === props.pageIndex)
              .map((area, idx) => (
                <div
                  key={idx}
                  style={Object.assign(
                    {},
                    {
                      backgroundColor: '#ff3E9A',
                      opacity: 0.2
                    },
                    props.getCssProperties(area, props.rotation)
                  )}
                ></div>
              ))}
        </React.Fragment>
        {arrHighLight.map((item: any, index) => {
          return (
            <React.Fragment key={`highlight_item_container_${item.id}`}>
              {item.highlightAreas
                .filter((area) => area.pageIndex === props.pageIndex)
                .map((area, idx) => (
                  <div
                    key={`highlight_item_${idx}`}
                    style={Object.assign(
                      {},
                      {
                        backgroundColor: '#ff3E9A',
                        opacity: 0.2,
                        zIndex: 1000
                      },
                      props.getCssProperties(area, props.rotation)
                    )}
                    className="clickable"
                    onClick={() => onSelectedHighlight(item)}
                  ></div>
                ))}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    renderHighlightTarget
  });

  const renderDocumentViewer = () => {
    return (
      <div
        id="document_viewer"
        style={{ height: `calc(100% - 250px)`, margin: '0px 32px' }}
      >
        <>
          <Viewer
            fileUrl={fileUrl!}
            scrollMode={ScrollMode.Horizontal}
            defaultScale={zoomPercent / 70}
            onDocumentLoad={(e) => {
              setTotalPage(e.doc.numPages);
            }}
            onPageChange={handlePageChange}
            plugins={[
              highlightPluginInstance,
              scrollModePluginInstance,
              zoomPluginInstance,
              pageNavigationPluginInstance,
              jumpToPagePluginInstance
            ]}
          />
        </>
      </div>
    );
  };

  const renderPageNaviator = () => {
    return (
      <div className="document_control row_align_items">
        {!showAskQuestion && (
          <div
            className="row_align_items scroll_to_question_btn clickable"
            onClick={(e) => setShowAskQuestion(true)}
          >
            <div className="font-medium font16">Scroll to Show Questions</div>
            <div style={{ marginLeft: 16 }}>
              <AngleDoubleDownIcon />
            </div>
          </div>
        )}
        {!(showFileAi || showHighlightConversation || showAskQuestion) ? (
          <div className="pagenation-section">
            <GoToFirstPage>
              {(props: RenderGoToPageProps) => {
                return (
                  <button onClick={() => props.onClick()}>
                    <FirstIcon isDisabled={props.isDisabled} />
                  </button>
                );
              }}
            </GoToFirstPage>
            <GoToPreviousPage>
              {(props: RenderGoToPageProps) => {
                return (
                  <button
                    onClick={() => {
                      onGoToPage(currentPage - 1);
                    }}
                  >
                    <PrevIcon isDisabled={props.isDisabled} />
                  </button>
                );
              }}
            </GoToPreviousPage>
            <CurrentPageLabel>
              {(props: RenderCurrentPageLabelProps) => {
                return (
                  <span>{`Page ${props.currentPage + 1} of ${
                    props.numberOfPages
                  }`}</span>
                );
              }}
            </CurrentPageLabel>
            <GoToNextPage>
              {(props: RenderGoToPageProps) => {
                return (
                  <button
                    onClick={() => {
                      onGoToPage(currentPage + 1);
                    }}
                  >
                    <NextIcon isDisabled={props.isDisabled} />
                  </button>
                );
              }}
            </GoToNextPage>
            <GoToLastPage>
              {(props: RenderGoToPageProps) => {
                return (
                  <button onClick={() => props.onClick()}>
                    <LastIcon isDisabled={props.isDisabled} />
                  </button>
                );
              }}
            </GoToLastPage>
          </div>
        ) : (
          <div className="pagenation-section" />
        )}
        <div className="actions row_align_items">
          <Tooltip
            placement="left"
            title={
              <div className="font-regular font14">
                Ask a question relating to this file
              </div>
            }
            PopperProps={{
              sx: {
                marginRight: '200px !important'
              }
            }}
          >
            <div
              className="action_item clickable row_align_items"
              style={{
                opacity: !showAskQuestion ? 1 : 0.4
              }}
              onClick={() =>
                !showAskQuestion && setShowAskQuestion((prev) => !prev)
              }
            >
              <QuestionIcon color={'#FF3E9A'} />
              <span className="font-medium font14" style={{ marginLeft: 8 }}>
                Ask Question
              </span>
            </div>
          </Tooltip>
          <Tooltip
            placement="left"
            title={
              <div className="font-regular font14">
                Create a summary of this document and ask more details through
                AI chat
              </div>
            }
            PopperProps={{
              sx: {
                marginRight: '232px !important'
              }
            }}
          >
            <div
              className="action_item clickable row_align_items"
              id="btn_summarize"
              style={{
                marginLeft: 16,
                opacity: !(
                  showFileAi ||
                  showHighlightConversation ||
                  isPartSummarizing
                )
                  ? 1
                  : 0.4
              }}
              onClick={(e) => {
                !(
                  showFileAi ||
                  showHighlightConversation ||
                  isWholeSummarizing ||
                  isPartSummarizing
                ) && loadAnswer('whole', 0, 0);
              }}
            >
              {isWholeSummarizing ? (
                <LoadingCircular size={18} color="main" />
              ) : (
                <div className="row_align_items">
                  <EditPencilColoredIcon color={'#FF3E9A'} />
                  <span
                    className="font-medium font14"
                    style={{ marginLeft: 8 }}
                  >
                    Summarize
                  </span>
                </div>
              )}
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderDocumentQuestion = () => {
    return (
      <DocumentQuestion
        show={showAskQuestion}
        dumpId={dumpId}
        onClose={() => setShowAskQuestion((prev) => !prev)}
        onViewHighLight={(highlightId) => {
          setHightlightId(highlightId);
          setShowHighlightConversation(true);
        }}
      />
    );
  };

  const renderFileAiView = () => {
    return (
      <DocumentFileAiView
        isSummarizing={isPartSummarizing}
        dumpId={dumpId}
        answerData={answer}
        type={summaryType}
        highlight={highlightId}
        highlightData={selectedData}
        onShowConversation={() => {
          setSelectedData(undefined);
          setShowFileAi(false);
          setShowHighlightConversation(true);
          loadHighLight(currentPage);
        }}
        onClose={() => {
          setShowHighlightConversation(false);
          setShowFileAi(false);
        }}
      />
    );
  };

  const renderHighlightConversation = () => {
    return (
      <HighlightConversation
        highlighId={highlightId}
        onClose={() => {
          setShowHighlightConversation(false);
          setShowFileAi(false);
        }}
      />
    );
  };

  const renderSummarizeDlg = () => {
    return (
      <SummarizeModal
        show={showSummarizeModal}
        selectedData={selectedData}
        posX={addDlgPos.left}
        posY={addDlgPos.top}
        onClose={() => setShowSummarizeModal(false)}
        onDone={(type, fromPage, endPage) => {
          setShowSummarizeModal(false);
          loadAnswer(type, fromPage, endPage);
        }}
      />
    );
  };

  const renderScrollToDocumentViewer = () => {
    return (
      <div
        className="row_align_items scroll_to_viewer clickable"
        onClick={() => {
          onClickBackBtn();
        }}
      >
        <div className="font-medium font16">Back to Docs</div>
        <div style={{ marginLeft: 16 }}>
          <AngleDoubleUpIcon />
        </div>
      </div>
    );
  };

  return (
    <div className="document_container">
      <div
        style={
          !showBackDocBtn
            ? {}
            : {
                position: 'fixed',
                width: '100%',
                zIndex: 300
              }
        }
      >
        <MainHeader currentPage="user" />
      </div>

      {!showBackDocBtn && renderHeader()}
      {fileUrl ? (
        <>
          {!showAskQuestion && renderDocumentViewer()}
          {showFileAi && !showBackDocBtn && renderFileAiView()}
          {showHighlightConversation && renderHighlightConversation()}
          {!showBackDocBtn && renderPageNaviator()}
          {renderDocumentQuestion()}
          {renderSummarizeDlg()}
          {showBackDocBtn && renderScrollToDocumentViewer()}
        </>
      ) : (
        <LoadingBarAnimation />
      )}
    </div>
  );
};

export interface HighlightData {
  highlightAreas: HighlightArea[];
  quote: string;
}
