import LinkIcon from 'assets/svgs/LinkIcon';
import React from 'react';
import { getNormalString } from 'shared/utils/utils';

import './index.styles.scss';

export interface TopicData {
  topic_id: any;
  topic: string;
  count: number;
  topic_emoji: string;
}
interface LinkPreviewTopFilterItemProps {
  isSelected: boolean;
  data: TopicData;
  onClick: (topic) => void;
}

export const LinkPreviewTopFilterItem = ({
  isSelected,
  data,
  onClick
}: LinkPreviewTopFilterItemProps) => {
  return (
    <div
      className="row_align_items clickable"
      style={{
        height: 48,
        borderRadius: 48,
        backgroundColor: !isSelected ? 'white' : '#FF3E9A',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 24,
        paddingRight: 24,
        marginRight: 12
      }}
      onClick={() => onClick(data)}
    >
      <div className="row_align_items">
        <span
          className="font-bold font16"
          style={{
            marginRight: 6,
            color: isSelected ? 'white' : 'black',
            whiteSpace: 'nowrap'
          }}
        >
          {data.topic_emoji + '   ' + data.topic}
        </span>
        <LinkIcon
          color={isSelected ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'}
        />
        <span
          className="font-regular font14"
          style={{ color: isSelected ? 'white' : 'black', marginLeft: 4 }}
        >
          {data.count}
        </span>
      </div>
    </div>
  );
};
