import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import Tooltip from '@mui/material/Tooltip';

import './MainHeader.styles.scss';
import { TabBar } from './TabBar';
import QrCodeIcon from 'assets/svgs/QrCodeIcon';
import { MobileLoginModal } from 'components/Modals/MobileLoginModal';
import QuestionIcon from 'assets/svgs/QuestionIcon';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from 'shared/utils/constants';
import EnvelopAddIcon from 'assets/svgs/EnvelopAddIcon';
interface MainHeaderProps {
  currentPage?: 'school' | 'group' | 'user' | 'converstaion';
  selectedOrg?: any;
  arrOrganisation?: any;
  groupId?: number;
}

export const MainHeader = ({
  currentPage,
  selectedOrg,
  arrOrganisation,
  groupId
}: MainHeaderProps) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [showQRForMobileLogin, setShowQRForMobileLogin] = useState(false);

  const onHome = () => {
    navigate('/');
  };

  const onProfile = () => {
    navigate('/profile');
  };

  const onToggle = () => {
    if (currentPage === 'school') {
      if (selectedOrg) {
        navigate(`/conversation/organisation/${selectedOrg.id}`);
      } else if (arrOrganisation?.length > 0) {
        navigate(`/conversation/organisation/${arrOrganisation[0].id}`);
      } else {
        navigate(`/conversation/general/0`);
      }
    } else if (currentPage === 'group') {
      if (groupId !== 0) {
        navigate(`/conversation/group/${groupId}`);
      } else {
        navigate(`/conversation/general/0`);
      }
    } else if (currentPage === 'user') {
      navigate(`/conversation/user/${userInfo?.id}`);
    } else {
      navigate(`/conversation/general/0`);
    }
  };

  const onInvite = () => {
    navigate('/share');
  };

  const renderLeftView = () => {
    return (
      <div className="leftView row_align_items clickable" onClick={onHome}>
        <div className="align-vertical-center">
          <img
            src="/logo512.png"
            width={35}
            height={35}
            style={{ objectFit: 'contain' }}
          />
        </div>
        <div className="title align-vertical-center app-font-bold font40">
          {process.env.REACT_APP_NAME}
        </div>
      </div>
    );
  };

  const renderCenterView = () => {
    return (
      <div className="centerView">
        <TabBar />
      </div>
    );
  };

  const renderRightView = () => {
    if (!isMobile)
      return (
        <div className="rightView">
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                AI Chat with the knowledge base of yourself, friends, groups or
                schools - or the whole internet with general AI
              </div>
            }
          >
            <div className="AI_chat_button">
              <div className="ask_btn" onClick={onToggle}>
                <div className="align-vertical-center">
                  <QuestionIcon />
                </div>
              </div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Receive a QR code to login with your phone
              </div>
            }
          >
            <div
              className="qr_code clickable"
              onClick={() => {
                setShowQRForMobileLogin(true);
              }}
            >
              <QrCodeIcon size={23} />
            </div>
          </Tooltip>
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Invite your friends and earn extra points
              </div>
            }
          >
            <div
              className="qr_code clickable"
              onClick={() => {
                onInvite();
              }}
            >
              <EnvelopAddIcon />
            </div>
          </Tooltip>
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Manage your profile and see your activity
              </div>
            }
          >
            <div className="user_info clickable" onClick={onProfile}>
              <div className="user_avatar">
                {userInfo !== undefined && userInfo.image ? (
                  <img src={userInfo.image} className="user_avatar" />
                ) : (
                  <DefaultAvatarIcon size={30} />
                )}
              </div>
            </div>
          </Tooltip>
        </div>
      );
    else
      return (
        <div className="rightView">
          <div className="user_info clickable" onClick={onProfile}>
            <div className="user_avatar">
              {userInfo !== undefined && userInfo.image ? (
                <img src={userInfo.image} className="user_avatar" />
              ) : (
                <DefaultAvatarIcon size={30} />
              )}
            </div>
            {/* <div className="user_name font-bold font23">{userInfo?.name}</div> */}
          </div>
        </div>
      );
  };

  const renderQRModalForMobile = () => {
    return (
      <MobileLoginModal
        show={showQRForMobileLogin}
        onClose={() => {
          setShowQRForMobileLogin(false);
        }}
      />
    );
  };

  return !isMobile ? (
    <div className="header">
      {renderLeftView()}
      {renderCenterView()}
      {userInfo && renderRightView()}
      {renderQRModalForMobile()}
    </div>
  ) : (
    <div className="header_mobile">{renderLeftView()}</div>
  );
};
