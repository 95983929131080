import CloseIcon from 'assets/svgs/CloseIcon';
import NoteIcon from 'assets/svgs/NoteIcon';
import UserCheckIcon from 'assets/svgs/UserCheckIcon';
import React, { useEffect, useState } from 'react';
import { apiGetQAFileReference } from 'shared/api/qa';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { CardFileReference } from './CardReference';

import './index.styles.scss';

export const AddReferenceModal = ({ show, selectedOrg, onClose }) => {
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [selectedRef, setSelectedRef] = useState<Array<number>>([]);

  const [arrReference, setArrReference] = useState<Array<any>>([]);
  const [lastReferenceIdDump, setLastReferenceIdDump] = useState(0);
  const [hasReferenceMoreDump, setHasReferenceMoreDump] = useState(true);
  const [isLoadingReferenceDump, setIsLoadingReferenceDump] = useState(false);

  useEffect(() => {
    if (show) {
      const org_home_view_obj = document.getElementById('org_home_view');
      const add_reference_btn_obj = document.getElementById(
        'add_reference_btn'
      );
      const dlg_add_reference_obj = document.getElementById(
        'dlg_add_reference'
      );
      if (org_home_view_obj && dlg_add_reference_obj && add_reference_btn_obj) {
        if (
          org_home_view_obj.offsetWidth - 50 <=
          add_reference_btn_obj.offsetLeft + dlg_add_reference_obj.offsetWidth
        ) {
          setOffsetLeft(
            org_home_view_obj.offsetWidth -
              50 -
              dlg_add_reference_obj.offsetWidth
          );
        }
      }
    }
  }, [show]);

  const loadReferenceData = async (init = true, keyword = '') => {
    if (init) {
      setIsLoadingReferenceDump(true);
      const res = await apiGetQAFileReference({
        query: keyword,
        to: selectedOrg?.id,
        to_type: 'organisation'
      });

      if (res.success) {
        setArrReference(res.data);
        setLastReferenceIdDump(res.lastId);
        setHasReferenceMoreDump(res.hasMore);
      }
      setIsLoadingReferenceDump(false);
    } else {
      if (hasReferenceMoreDump && !isLoadingReferenceDump) {
        setIsLoadingReferenceDump(true);
        const res = await apiGetQAFileReference({
          query: keyword,
          to: selectedOrg.id,
          to_type: 'organisation',
          offset: lastReferenceIdDump
        });

        if (res.success) {
          setArrReference((prev) => [...prev, ...res.data]);
          setLastReferenceIdDump(res.lastId);
          setHasReferenceMoreDump(res.hasMore);
        }
        setIsLoadingReferenceDump(false);
      }
    }
  };

  const onSelect = (referenceIdx: number) => {
    if (selectedRef.includes(referenceIdx)) {
      setSelectedRef((prev) =>
        prev.filter((item, index) => item !== referenceIdx)
      );
    } else {
      setSelectedRef((prev) => [...prev, referenceIdx]);
    }
  };

  const renderTop = () => {
    return (
      <div
        className="row_space_between row_align_items"
        style={{ marginBottom: 16 }}
      >
        <div className="row_align_items">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon opacity={0.4} />
          </div>
          <div className="font-bold font16" style={{ marginLeft: 16 }}>
            Add References
          </div>
        </div>
        <div
          className="font-bold font12 clickable"
          style={{ color: '#FF3E9A' }}
          onClick={() => {
            setSelectedRef([]);
          }}
        >
          Clear
        </div>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className="dlg_tab">
        <div className="dlg_tab_creator">
          <UserCheckIcon />
          <div className="font-bold font12">Creators</div>
        </div>
        <div className="dlg_tab_document">
          <NoteIcon />
          <div className="font-bold font12">Documents</div>
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <SearchBox
        onSearch={(keyword) => {
          loadReferenceData(true, keyword);
        }}
      />
    );
  };

  const renderBody = () => {
    return (
      <div className="dlg_list">
        <InfiniteScroll
          dataLength={arrReference.length}
          next={() => loadReferenceData(false)}
          hasMore={hasReferenceMoreDump}
          loader={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingCircular size={20} color="main" />
            </div>
          }
          refreshFunction={() => {}}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          style={{ overflow: 'hidden' }}
        >
          {arrReference.map((item, index: number) => {
            return (
              <CardFileReference
                key={`card_refer_dlg_${index}`}
                onSelect={onSelect}
                data={item}
                isSelected={selectedRef.includes(item.id) ? true : false}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    );
  };

  if (show) {
    return (
      <div
        className="dlg_add_reference"
        id="dlg_add_reference"
        style={{ left: offsetLeft }}
      >
        {renderTop()}
        {renderTab()}
        {renderSearch()}
        {renderBody()}
        <div className="done_btn clickable">Done</div>
      </div>
    );
  } else {
    return <div />;
  }
};
