import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useMemo, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Switch from 'react-switch';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetOrgDetail, apiOrgRequestJoin } from 'shared/api/org';
import { apiGetQAAsk } from 'shared/api/qa';

import './index.styles.scss';
import { QuestionAskBar } from '../components/QuestionAskBar';
import UserCheckIcon from 'assets/svgs/UserCheckIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import FileIcon from 'assets/svgs/FileIcon';
import { TrendingQuestion } from '../OrgHome/components/TrendingQuestion';
import { NewAddReferenceModal } from 'components/Modals/AddReferenceModal/NewAddReferenceModal';
import { OrgAsked } from '../components/Asked';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import EnvelopStarIcon from 'assets/svgs/EnvelopStarIcon';
import { JoinRequestSendModal } from 'components/Modals/JoinRequestSentModal';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import CommentQuestionIcon from 'assets/svgs/CommentQuestionIcon';
import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import { QuestionFilterActionModal } from 'components/Modals/QuestionFilterModal';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface Props {
  question?: any;
  selectedOrg?: any;
  selectedTopics?: any;
  arrOrganisation?: Array<any>;

  onSetSelectedOrg?: (orgInfo) => void;
  onSetQuestion?: (question) => void;
}

export const OrgDetail = ({
  question,
  selectedOrg,
  selectedTopics,
  arrOrganisation,
  onSetSelectedOrg,
  onSetQuestion
}: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const orgId = params.id;

  const [isLoadedDetail, setIsLoadedDetail] = useState(false);
  const [isFollowing, setIsFollowing] = useState<
    'false' | 'requested' | 'true'
  >('false');
  const [isJoining, setIsJoining] = useState(false);
  const [showSuccessSentModal, setShowSuccessSentModal] = useState(false);

  const [isAsking, setIsAsking] = useState(false);
  const [qaAskInfo, setQaAskInfo] = useState<{ [key: string]: any } | null>(
    null
  );

  const [isFocusedSearch, setIsFocusedSearch] = useState(false);
  const [showAskedView, setShowAskedView] = useState(false);

  const [isUnAnsweredFilter, setIsUnAnsweredFilter] = useState(false);
  const [isWithDoc, setIsWithDoc] = useState(false);
  const [isTrendingFilter, setIsTrendingFilter] = useState(true);

  // add reference
  const [selectedRef, setSelectedRef] = useState<Array<any>>([]);
  const [showAddDlg, setShowAddDlg] = useState(false);
  const [addDlgPos, setAddDlgPos] = useState({ top: 0, left: 0 });

  const [showActionModal, setShowActionModal] = useState(false);
  const [actionModalPos, setActionModalPos] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (orgId) {
      onCheckOrgInfo();
    }
  }, [orgId]);

  const onCheckOrgInfo = async () => {
    const res = await apiGetOrgDetail({ organisation: orgId });

    if (res.success) {
      if (res.data) {
        setIsLoadedDetail(true);
        setIsFollowing(res.data.isFollowed);
        onSetSelectedOrg &&
          onSetSelectedOrg({
            ...res.data,
            member_number: res.data.organisation_members
          });
      } else {
        navigate(-1);
      }
    }
  };

  const loadQuestionData = async (question, orgId = null) => {
    if (question) {
      setIsAsking(true);
      const userRef = selectedRef.map((item, index) => item.creator_id);
      const res = await apiGetQAAsk({
        question,
        to: orgId ?? selectedOrg?.id,
        to_type: orgId ?? selectedOrg ? 'organisation' : 'general',
        users: userRef.length > 0 ? userRef + '' : undefined
      });

      if (res.success) {
        setQaAskInfo(res.data);
      } else {
        setQaAskInfo(null);
      }
      setIsAsking(false);
    } else {
      setQaAskInfo(null);
    }
  };

  const onSearch = (question) => {
    setShowAskedView(true);
    setIsFocusedSearch(false);

    if (arrOrganisation?.length == 0) {
      showToast('error', 'You do not belong to any school yet');
    } else {
      onSetQuestion && onSetQuestion(question);
      loadQuestionData(question);
    }
  };

  const onAskToJoin = async () => {
    if (isFollowing === 'false') {
      setIsJoining(true);
      const res = await apiOrgRequestJoin({ organisation: orgId });

      if (res.success) {
        setShowSuccessSentModal(true);
        setIsFollowing(res.isFollowed);
      }
      setIsJoining(false);
    }
  };

  const onFocusSearch = () => {
    setIsFocusedSearch(true);
  };

  const onLeaveSearch = () => {
    // setIsFocusedSearch(false);
  };

  const onShowAddRefDlg = (e) => {
    setShowAddDlg(true);

    const clientRectangle = document!
      .getElementById('org_answer_add_btn_ref')!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setAddDlgPos({
      top:
        bottomFromWindow < 400
          ? clientRectangle.top - 400
          : clientRectangle.top,
      left: clientRectangle.left
    });
  };

  const onShowFilterModal = (e) => {
    e.stopPropagation();
    setShowActionModal(true);

    const clientRectangle = document!
      .getElementById(`question_filter_btn`)!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setActionModalPos({
      top:
        bottomFromWindow < 400
          ? clientRectangle.top - 400 + 30
          : clientRectangle.top + 30,
      left: clientRectangle.left - 200
    });
  };

  const onDoneAddReference = (userRef, fileRef) => {
    setSelectedRef(userRef);
  };

  const onMember = () => {
    navigate(`/school/member/${selectedOrg.id}`);
  };

  const arrReferences = useMemo(() => {
    const arrTmpRefData: Array<any> = [];

    selectedRef.map((item, index) => {
      arrTmpRefData.push({
        name: selectedRef[index].creator_name,
        image: selectedRef[index].creator_image,
        type: 'user'
      });
    });
    return arrTmpRefData;
  }, [selectedRef]);

  const renderOrgDetail = () => {
    return (
      <div className="selected_org_top">
        <div className="selected_org_top_info">
          <img src={selectedOrg.image} className="selected_org_top_info_img" />
          <div className="selected_org_top_info_text">
            <div className="selected_org_top_info_text_title font-bold font32">
              {selectedOrg.name}
            </div>
            <div className="selected_org_top_info_text_subTitle font20 font-normal">
              {selectedOrg.description ? selectedOrg.description : ''}
            </div>
          </div>
        </div>
        <div className="selected_org_top_members clickable" onClick={onMember}>
          <DefaultAvatarIcon />
          <div className="font-bold font16">
            {selectedOrg.member_number} members
          </div>
        </div>
      </div>
    );
  };

  const renderAskBar = () => {
    return (
      <div style={{ padding: '0px 16px' }}>
        <div className="row_align_items">
          <QuestionAskBar
            onSearch={onSearch}
            onFocus={onFocusSearch}
            onBlur={onLeaveSearch}
            width="100%"
            height="64"
            backgroundColor="#F1F6F9"
            placeholder="Ask new question here..."
            fontSize={20}
            showButton={isFocusedSearch}
            searchIconClickable={true}
          />
          {!isFocusedSearch && (
            <div
              className="filter_btn row_align_items clickable"
              id="question_filter_btn"
              onClick={onShowFilterModal}
            >
              <div
                className="font-bold font16"
                style={{ whiteSpace: 'nowrap' }}
              >
                {isTrendingFilter ? 'Most Popular' : 'Latest Posts'}
              </div>
              <div style={{ marginLeft: 6 }}>
                <AngleDownIcon color="#FF3E9A" />
              </div>
            </div>
          )}
          {!isFocusedSearch && (
            <div
              className={`${
                isUnAnsweredFilter
                  ? 'selected_unanswer_btn'
                  : 'default_unanswer_btn'
              } clickable`}
              onClick={() => setIsUnAnsweredFilter((prev) => !prev)}
            >
              <CommentQuestionIcon
                color={isUnAnsweredFilter ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)'}
              />
            </div>
          )}
        </div>
        {isFocusedSearch && (
          <div style={{ marginTop: 32 }}>{renderReference()}</div>
        )}
      </div>
    );
  };

  const renderReference = () => {
    return (
      <div className="org_reference">
        <div className="org_reference_left">
          <UserCheckIcon size={24} />
          {arrReferences.length > 0 ? (
            <div className="font-medium font14" style={{ marginLeft: 8 }}>
              References
            </div>
          ) : (
            <div
              className="font-medium font14"
              style={{ marginLeft: 8, flex: 1 }}
            >
              No references set (optional). Showing insights from all members
            </div>
          )}
          {arrReferences.length > 0 && renderReferenceItems()}

          <Tooltip
            title={
              <div className="font-regular font14">
                Set a reference and narrow down your results by filtering for
                specific creators or document uploads
              </div>
            }
          >
            <div
              className="round_btn clickable"
              id={'org_answer_add_btn_ref'}
              onClick={onShowAddRefDlg}
            >
              <PlusIcon size={16} />
            </div>
          </Tooltip>
        </div>
        <div className="org_reference_right">
          <FileIcon />
          <div
            className="font-medium font16"
            style={{ marginLeft: 8, flex: 1 }}
          >
            With Docs Only
          </div>
          <Switch
            onChange={() => {
              setIsWithDoc((prev) => !prev);
            }}
            checked={isWithDoc}
            checkedIcon={<div />}
            uncheckedIcon={<div />}
            offColor={'#EAEBEC'}
            onColor={'#FF3E9A'}
            activeBoxShadow={undefined}
            handleDiameter={18}
            width={44}
            height={24}
          />
        </div>
      </div>
    );
  };

  const renderReferenceItems = () => {
    return (
      <div className="org_answer_reference_list">
        {arrReferences.map((item, index) => {
          return (
            <div
              className="reference_item"
              key={`reference_item_${index}`}
              style={{ height: 40 }}
            >
              {item.image && item.image.startsWith('https') ? (
                <img
                  src={item.image}
                  style={{ width: 24, height: 24, borderRadius: 24 }}
                />
              ) : (
                <DefaultAvatarIcon size={24} />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTrendingQuestion = () => {
    return (
      <TrendingQuestion
        selectedOrg={selectedOrg}
        selectedTopic={selectedTopics}
        arrOrganisation={arrOrganisation}
        isUnAnsweredFilter={isUnAnsweredFilter}
        isTrendingFilter={isTrendingFilter}
        onPostQuestion={() => setIsFocusedSearch(true)}
        onShowAllSharedDoc={() => {
          navigate(`/school/${selectedOrg.id}/docs`);
        }}
        onShowAllSharedLink={() => {
          navigate(`/school/${selectedOrg.id}/links`);
        }}
        onShowAllSharedMeme={() => {
          navigate(`/school/${selectedOrg.id}/memes`);
        }}
        onShowAllSharedFlash={() => {
          navigate(`/school/${selectedOrg.id}/flashcards`);
        }}
      />
    );
  };

  const renderAddRefDlg = () => {
    return (
      <NewAddReferenceModal
        show={showAddDlg}
        itemId={selectedOrg?.id}
        itemType={'organisation'}
        posX={addDlgPos.left}
        posY={addDlgPos.top}
        onlyCreator={true}
        onClose={() => setShowAddDlg(false)}
        onDone={(userRef, fileRef) => {
          setShowAddDlg(false);
          onDoneAddReference(userRef, fileRef);
        }}
      />
    );
  };

  const renderAskedBody = () => {
    return (
      <OrgAsked
        question={question}
        isAsking={isAsking}
        qaAskInfo={qaAskInfo}
        selectedOrg={selectedOrg}
        arrOrganisation={arrOrganisation ?? []}
        onSetQuestion={(newQuestion) => {
          onSetQuestion && onSetQuestion(newQuestion);
        }}
        onLoadQuestionData={(newQuestion) => loadQuestionData(newQuestion)}
        onReloadQuestion={(newOrgId) => {
          loadQuestionData(question, newOrgId);
        }}
        onBack={() => setShowAskedView(false)}
      />
    );
  };

  const renderNotBelong = () => {
    return (
      <div className="not_belong">
        <div style={{ fontSize: 96 }}>🚧</div>
        <div className="font-bold font24">
          {isFollowing === 'false'
            ? 'Oh no... you are not part of this org (yet)'
            : 'Your access request is pending'}
        </div>
        <div
          className="font-regular font16"
          style={{ color: 'rgba(0, 0, 0, 0.6)' }}
        >
          {isFollowing === 'false'
            ? `If you want to see what inside, you can send a request to join and wait for approval`
            : `Once an admin confirms you will get in`}
        </div>
        <div
          className="request_join clickable"
          style={
            isFollowing === 'requested'
              ? {
                  backgroundColor: '#ffe5f2'
                }
              : {}
          }
          onClick={onAskToJoin}
        >
          {isJoining ? (
            <LoadingCircular size={24} color="white" />
          ) : (
            <div className="row_align_items">
              <div className="align-center">
                <EnvelopStarIcon
                  color={isFollowing === 'false' ? 'white' : 'black'}
                />
              </div>
              <div
                className="request_txt font-bold font14"
                style={
                  isFollowing === 'requested'
                    ? {
                        color: 'black'
                      }
                    : {}
                }
              >
                {isFollowing === 'requested' ? 'Requested' : 'Request to Join'}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderSuccessSentModal = () => {
    return (
      <JoinRequestSendModal
        show={showSuccessSentModal}
        onCheckMyOrg={() => {
          setShowSuccessSentModal(false);
          navigate('/school');
        }}
        onContinue={() => {
          setShowSuccessSentModal(false);
          navigate('/school/join');
        }}
      />
    );
  };

  const renderActionModal = () => {
    return (
      <QuestionFilterActionModal
        show={showActionModal}
        posX={actionModalPos.left}
        posY={actionModalPos.top}
        onClose={() => {
          setShowActionModal(false);
        }}
        onSelected={(action) => {
          setShowActionModal(false);
          if (action === 'most') {
            setIsTrendingFilter(true);
          } else {
            setIsTrendingFilter(false);
          }
        }}
      />
    );
  };

  if (!isLoadedDetail) {
    return (
      <div className="org_detail">
        <LoadingBarAnimation />
      </div>
    );
  } else {
    return (
      <div className="org_detail">
        {selectedOrg && renderOrgDetail()}
        {isFollowing === 'true' ? (
          <div className="org_home_body">
            {renderAskBar()}
            {!isFocusedSearch && !showAskedView && renderTrendingQuestion()}
            {!isFocusedSearch && showAskedView && renderAskedBody()}
          </div>
        ) : (
          renderNotBelong()
        )}
        {renderAddRefDlg()}
        {renderSuccessSentModal()}
        {renderActionModal()}
      </div>
    );
  }
};
