import CloseIcon from 'assets/svgs/CloseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { apiGroupSendInvitation } from 'shared/api/group';
import { apiGetSearchedUser } from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { SearchBox } from 'shared/ui-kit/SearchBox';

import './index.styles.scss';
import BlackBackIcon from 'assets/svgs/BlackBackIcon';

interface ShareQuestionToOrgModalProps {
  show: boolean;
  onClose: () => void;
  onBack: () => void;
  createdGroup: any;
}

export const InviteUserToGroupByIdModal = ({
  show,
  createdGroup,
  onClose,
  onBack
}: ShareQuestionToOrgModalProps) => {
  const [isAdding, setIsAdding] = useState(false);

  const [query, setQuery] = useState('');

  const [selectedUsers, setSelectedUsers] = useState<Array<any>>([]);
  const [usersList, setUserList] = useState<Array<any>>([]);
  const [hasMoreUser, setHasMoreUser] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    loadUsersList(true, query);
  }, [selectedUsers]);
  const loadUsersList = async (init = true, keyword) => {
    if (init) {
      setIsLoading(true);
      const res = await apiGetSearchedUser({
        query: keyword,
        full: 'false',
        limit: 10,
        offset: 0
      });
      if (res.success) {
        if (selectedUsers.length > 0) {
          let userResArr = res.data;
          selectedUsers.forEach((element) => {
            userResArr = userResArr.filter(
              (item, index) => item.id !== element.id
            );
          });
          setUserList(userResArr);
        } else {
          setUserList(res.data);
        }
        setLastId(res.lastId);
        setHasMoreUser(res.hasMore);
      }
      setIsLoading(false);
    } else {
      const res = await apiGetSearchedUser({
        query: query,
        full: 'false',
        limit: 10,
        offset: lastId
      });
      if (res.success) {
        setUserList((prev) => [...prev, ...res.data]);
        setLastId(res.lastId);
        setHasMoreUser(res.hasMore);
      }
    }
  };
  const onSendInvite = async () => {
    const res = await apiGroupSendInvitation({
      group: createdGroup.groupId,
      toUser: selectedUsers[0].id
    });
    if (res.success) {
      onClose();
    }
  };
  const onAddUser = (user) => {
    setSelectedUsers((prev) => [...prev, user]);
    setUserList(usersList.filter((item, index) => item.id !== user.id));
  };
  const onCancel = (selectedIndex: any) => {
    setSelectedUsers(
      selectedUsers.filter((item, index) => index !== selectedIndex)
    );
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const renderInputEmail = () => {
    return (
      <div className="send_add_body_input_bar">
        <SearchBox
          fontSize={20}
          onSearch={(keyword) => {
            loadUsersList(true, keyword);
            setQuery(keyword);
          }}
        />
      </div>
    );
  };
  const renderSelectedEmails = () => {
    return (
      <div
        className="send_invite_body_selected_users"
        id="send_invite_body_selected_users"
      >
        <InfiniteScroll
          dataLength={usersList.length}
          next={() => loadUsersList(false, query)}
          hasMore={hasMoreUser}
          loader={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingCircular size={20} color="main" />
            </div>
          }
          refreshFunction={() => {}}
          scrollableTarget="send_invite_body_selected_users"
          // style={{ overflow: 'hidden' }}
        >
          {usersList.map((item, index) => {
            return (
              <div className="card_user" key={`card_user_${index}`}>
                <div className="card_user_avatar">
                  {item.creator_image === '' ? (
                    <DefaultAvatarIcon size={40} />
                  ) : (
                    <img
                      src={item.creator_image}
                      width={40}
                      height={40}
                      className="user_image"
                    />
                  )}
                </div>
                <div className="card_user_text ">
                  <div className="user_name font-bold font16">
                    {item.creator_name}
                  </div>
                  <div className="user_email font14">{item.creator_email}</div>
                </div>
                <div
                  className="card_user_cancel clickable"
                  onClick={() => {
                    onAddUser(item);
                  }}
                >
                  <PlusIcon />
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    );
  };
  const renderBody = () => {
    return (
      <div className="send_invite_body">
        <>
          {renderInputEmail()}
          {renderSelectedEmails()}
        </>
      </div>
    );
  };
  const renderBottomBar = () => {
    return (
      <div className="send_invite_bottom_bar">
        <div className="selected_user_bar" style={{ width: '100%' }}>
          <ScrollMenu onWheel={onWheel}>
            {selectedUsers.map((item, index) => {
              return (
                <div className="selected_user" key={`selected_user_${index}`}>
                  <div>
                    {item.creator_image === '' ? (
                      <DefaultAvatarIcon size={24} />
                    ) : (
                      <img
                        src={item.creator_image}
                        className="user_image"
                        width={24}
                        height={24}
                      />
                    )}
                  </div>
                  <div className="font-regular font14">{item.creator_name}</div>
                  <div
                    style={{ opacity: '0.3' }}
                    className="clickable"
                    onClick={() => onCancel(index)}
                  >
                    <CloseIcon />
                  </div>
                </div>
              );
            })}
          </ScrollMenu>
        </div>
        <div
          className="send_btn text-white font-bold font16 clickable"
          onClick={onSendInvite}
          style={{ marginTop: 16 }}
        >
          Add {selectedUsers.length} User by ID
        </div>
      </div>
    );
  };
  return (
    <PrimaryModal show={show} width={500} onClose={onClose}>
      <div className="modal_create_group">
        <div className="modal_create_group_header">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onBack();
            }}
          >
            <BlackBackIcon />
          </div>
          <div className="question_title font-bold font20">
            Add New User by ID
          </div>
        </div>

        <div className="modal_send_invite_body">
          {renderBody()}
          {renderBottomBar()}
        </div>
      </div>
    </PrimaryModal>
  );
};
