import React from 'react';

function EnvelopStarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill={props.color ?? '#fff'}
        d="M11.367 8.615l-.242 1.442a1.291 1.291 0 00.542 1.275 1.217 1.217 0 001.316.075l1.184-.675 1.2.658a1.216 1.216 0 001.3-.058 1.291 1.291 0 00.525-1.258l-.242-1.442 1-1.017a1.284 1.284 0 00-.708-2.166l-1.35-.2-.609-1.292a1.25 1.25 0 00-2.266 0l-.609 1.292-1.35.2a1.283 1.283 0 00-.708 2.166l1.017 1zm1.525-1.775a1.259 1.259 0 00.95-.708l.325-.692.325.692a1.292 1.292 0 00.95.717l.833.116-.608.617a1.308 1.308 0 00-.35 1.117l.133.833-.658-.358a1.233 1.233 0 00-1.2 0l-.659.358.134-.833a1.282 1.282 0 00-.35-1.109l-.609-.625.784-.125zm4.608 6.375a.833.833 0 00-.833.834v2.5a.833.833 0 01-.834.833H4.167a.833.833 0 01-.834-.833V8.557l4.9 4.9a2.45 2.45 0 001.75.733h.225a.833.833 0 10-.15-1.658.784.784 0 01-.641-.233L4.508 7.382H7.5a.833.833 0 100-1.667H4.167a2.5 2.5 0 00-2.5 2.5v8.334a2.5 2.5 0 002.5 2.5h11.666a2.5 2.5 0 002.5-2.5v-2.5a.834.834 0 00-.833-.834z"
      ></path>
    </svg>
  );
}

export default EnvelopStarIcon;
