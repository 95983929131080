import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiQAGetShareLink } from 'shared/api/qa';
import { apiGetUserSharingQR } from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import useIPFS from 'shared/utils-IPFS/useIPFS';
import { getImageFromHtml } from 'shared/utils/utils';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

import './index.styles.scss';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import Download1Icon from 'assets/svgs/Download1Icon';

export const InviteUsingQR = () => {
  const { uploadBase64 } = useIPFS();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [isDownloading, setIsDownloading] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');

  useEffect(() => {
    getSharingQRcode();
  }, []);

  useEffect(() => {
    initShareUrl();
  }, [qrCodeUrl]);

  const initShareUrl = async () => {
    const base64Image = await getImageFromHtml('mobile_share_body_image');
    const url = await uploadBase64(base64Image, 'image/png', '');

    const res = await apiQAGetShareLink({
      url: url,
      item_id: userInfo?.id,
      type: 'user'
    });

    if (res.success) {
      setShareUrl(res.sharing_url);
    }
  };

  const getSharingQRcode = async () => {
    const res = await apiGetUserSharingQR();
    if (res.success) {
      setQRCodeUrl(res.qr_url);
    }
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        let base64Image = await getImageFromHtml(`mobile_share_body_image`);

        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const renderBody = () => {
    return (
      <div
        className="main_body"
        id="mobile_share_body_image"
        style={{ backgroundColor: 'white' }}
      >
        <div className="row_space_between">
          <div className="row_align_items">
            {userInfo?.image && userInfo?.image.startsWith('https') ? (
              <img
                src={userInfo?.image}
                style={{ width: 24, height: 24, borderRadius: 24 }}
              />
            ) : (
              <DefaultAvatarIcon size={24} />
            )}
            <div className="font-bold font14" style={{ marginLeft: 8 }}>
              {userInfo?.name}
            </div>
          </div>
          <div
            className="font-bold font12"
            style={{ color: 'rgba(0, 0, 0, 0.3)' }}
          >
            #04637
          </div>
        </div>
        <div className="image_view">
          <img src={qrCodeUrl} style={{ width: '100%', height: '100%' }} />
        </div>
        {/* <div className="row_space_between" style={{ marginTop: 12 }}>
          <div
            className="font-bold font12"
            style={{ color: 'rgba(0, 0, 0, 0.4)' }}
          >
            Gold Member
          </div>
          <div className="font-bold font12" style={{ color: '#FF3E9A' }}>
            Earn 1.5x pts
          </div>
        </div> */}
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="share_bottom">
        <div className="statement_link_bar_items">
          <div
            className="statement_link_bar_items_item download_itm clickable"
            onClick={() => onShare('download')}
          >
            {isDownloading ? (
              <LoadingCircular size={20} color={'white'} />
            ) : (
              <Download1Icon />
            )}
          </div>
          <div className="font10">Download</div>
        </div>
        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <FacebookShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div
                className="statement_link_bar_items_item Telegram_itm "
                style={{ padding: 0 }}
              >
                <FacebookIcon borderRadius={16} size={48} />
              </div>
              <div className="font10">Facebook</div>
            </div>
          </FacebookShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Twitter_itm">
                <TwitterIcon />
              </div>
              <div className="font10">Twitter</div>
            </div>
          </TwitterShareButton>
        )}
      </div>
    );
  };

  return (
    <div className="invite_using_qr">
      {renderBody()}
      {renderBottom()}
    </div>
  );
};
