import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Tooltip from '@mui/material/Tooltip';
import { apiEditDumpPermission, apiGetGroupMember } from 'shared/api/group';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { CardActiveMember } from './CardActiveMember';

import './index.styles.scss';

export const ActiveMember = ({ groupId, shouldRefresh }) => {
  const [tab, setTab] = useState<'everyone' | 'selected' | 'admin'>('admin');

  const [arrMember, setArrMember] = useState<Array<any>>([]);
  const [hasMoreMember, setHasMoreMember] = useState(true);
  const [lastIdMember, setLastIdMember] = useState(0);
  const [isLoadingMember, setIsLoadingMember] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    loadMember(true, searchKey);
  }, [searchKey, shouldRefresh]);

  useEffect(() => {
    onChangeDumpPermission(tab);
  }, [tab]);

  const loadMember = async (init = true, searchText = '') => {
    if (init) {
      setIsLoadingMember(true);

      const res = await apiGetGroupMember({
        group: groupId,
        query: searchText,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrMember(res.data);
        setLastIdMember(res.lastId);
        setHasMoreMember(res.hasMore);
        setTotalCount(res.count);
      }
      setIsLoadingMember(false);
    } else {
      if (!isLoadingMember && hasMoreMember) {
        setIsLoadingMember(true);

        const res = await apiGetGroupMember({
          group: groupId,
          query: searchText,
          limit: 10,
          offset: lastIdMember
        });

        if (res.success) {
          setArrMember((prev) => [...prev, ...res.data]);
          setLastIdMember(res.lastId);
          setHasMoreMember(res.hasMore);
          setTotalCount(res.count);
        }
        setIsLoadingMember(false);
      }
    }
  };

  const onChangeDumpPermission = async (
    permssion: 'everyone' | 'selected' | 'admin'
  ) => {
    const res = await apiEditDumpPermission({
      group: parseInt(groupId!),
      permission: permssion
    });

    if (res.success) {
    }
  };

  const onSearch = (searchText: string) => {
    setSearchKey(searchText);
  };

  const renderTopView = () => {
    return (
      <div className="active_member_top_view">
        <div className="font-bold font24">{totalCount} Active Members</div>
        <SearchBox onSearch={onSearch} fontSize={16} />
      </div>
    );
  };

  const renderDumpEditor = () => {
    return (
      <div className="active_member_dump_editor">
        <div className="dump_editor_desc">
          <div style={{ fontSize: 56 }}>✍️</div>
          <div style={{ marginLeft: 24 }}>
            <div className="font-bold font20">Dump Editors</div>
            <div className="font-regular font16" style={{ opacity: 0.6 }}>
              Manage your group dumps editor freely.
            </div>
          </div>
        </div>
        <div className="dump_editor_tab">
          <Tooltip
            arrow
            placement="bottom"
            title={
              <div className="font-regular font14">
                Only the group creator (admin) can add to the knowledge base
              </div>
            }
          >
            <div
              className={
                tab === 'admin'
                  ? 'dump_editor_selected_tab clickable'
                  : 'dump_editor_default_tab clickable'
              }
              onClick={() => setTab('admin')}
            >
              <div className="font-bold font12">Only Admin</div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            placement="bottom"
            title={
              <div className="font-regular font14">
                Every group member can add to the knowledge base
              </div>
            }
          >
            <div
              className={
                tab === 'everyone'
                  ? 'dump_editor_selected_tab clickable'
                  : 'dump_editor_default_tab clickable'
              }
              onClick={() => setTab('everyone')}
            >
              <div className="font-bold font12">Let Everyone</div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            placement="bottom"
            title={
              <div className="font-regular font14">
                The group creator (admin) can select which members can add to
                the knowledge base
              </div>
            }
          >
            <div
              className={
                tab === 'selected'
                  ? 'dump_editor_selected_tab clickable'
                  : 'dump_editor_default_tab clickable'
              }
              onClick={() => setTab('selected')}
            >
              <div className="font-bold font12">Set Individually</div>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="active_member_body">
        {renderBodyHeader()}
        <InfiniteScroll
          dataLength={arrMember.length}
          next={() => loadMember(false, searchKey)}
          hasMore={hasMoreMember}
          loader={
            <div
              style={{
                display: 'flex'
              }}
            >
              {/* <LoadingCircular size={20} color="main" /> */}
            </div>
          }
          refreshFunction={() => {}}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          style={{
            width: '100%',
            overflow: 'hidden',
            overflowX: 'hidden'
          }}
        >
          {arrMember.map((item, index) => {
            return (
              <CardActiveMember
                key={`card_active_member_${index}`}
                data={item}
                editType={tab}
                groupId={groupId}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    );
  };

  const renderBodyHeader = () => {
    return (
      <div className="active_member_body_header">
        <div className="active_member_body_header_member font-bold font14">
          Members
        </div>
        <div className="active_member_body_header_joined_since font-bold font14">
          Joined since
        </div>
        <div className="active_member_body_header_dump_editor font-bold font14">
          Dump Editor
        </div>
        <div className="active_member_body_header_action font-bold font14">
          Action
        </div>
      </div>
    );
  };

  return (
    <div className="active_member">
      {renderTopView()}
      {renderDumpEditor()}
      {renderBody()}
    </div>
  );
};
