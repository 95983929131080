import React from 'react';

function PrevIcon(props) {
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.28982 5.50001L6.82982 1.96001C7.01607 1.77265 7.12061 1.5192 7.12061 1.25501C7.12061 0.990824 7.01607 0.737372 6.82982 0.55001C6.73686 0.456281 6.62626 0.381887 6.5044 0.331118C6.38254 0.280349 6.25183 0.254211 6.11982 0.254211C5.98781 0.254211 5.8571 0.280349 5.73524 0.331118C5.61339 0.381887 5.50278 0.456281 5.40982 0.55001L1.16982 4.79001C1.07609 4.88297 1.0017 4.99357 0.950931 5.11543C0.900162 5.23729 0.874023 5.368 0.874023 5.50001C0.874023 5.63202 0.900162 5.76273 0.950931 5.88459C1.0017 6.00645 1.07609 6.11705 1.16982 6.21001L5.40982 10.5C5.50326 10.5927 5.61408 10.666 5.73592 10.7158C5.85775 10.7655 5.98821 10.7908 6.11982 10.79C6.25143 10.7908 6.38189 10.7655 6.50373 10.7158C6.62557 10.666 6.73638 10.5927 6.82982 10.5C7.01607 10.3126 7.12061 10.0592 7.12061 9.79501C7.12061 9.53082 7.01607 9.27737 6.82982 9.09001L3.28982 5.50001Z"
        fill={props.isDisabled ? 'rgba(0, 0, 0, 0.4)' : '#FF3E9A'}
      />
    </svg>
  );
}

export default PrevIcon;
