import React from 'react';

function WholeDocIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 16}
      height={props.size ?? 16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FF3E9A"
        d="M10.667 13.333H5.333a2 2 0 01-2-2V4.667a.667.667 0 10-1.333 0v6.666a3.334 3.334 0 003.333 3.334h5.334a.667.667 0 000-1.334zm-4-4.666a.667.667 0 00.666.666h3.334a.667.667 0 000-1.333H7.333a.667.667 0 00-.666.667zM14 5.96a.874.874 0 00-.04-.18v-.06a.713.713 0 00-.127-.187l-4-4a.713.713 0 00-.186-.126.213.213 0 00-.06 0 .587.587 0 00-.22-.074h-2.7a2 2 0 00-2 2V10a2 2 0 002 2H12a2 2 0 002-2V6v-.04zm-4-2.353l1.727 1.726h-1.06A.667.667 0 0110 4.667v-1.06zM12.667 10a.667.667 0 01-.667.667H6.667A.667.667 0 016 10V3.333a.667.667 0 01.667-.666h2v2a2 2 0 00.12.666H7.333a.667.667 0 000 1.334h5.334V10z"
      ></path>
    </svg>
  );
}

export default WholeDocIcon;
