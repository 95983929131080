import BookMarkedIcon from 'assets/svgs/BookMarkedIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import moment from 'moment';
import RobotIcon from 'assets/svgs/RobotIcon';
import UCoinIcon from 'assets/svgs/UCoinsIcon';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.styles.scss';
import { apiQABookMarkAnswer, apiQAUnBookMarkAnswer } from 'shared/api/qa';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import ShareIcon from 'assets/svgs/ShareIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import FoloderDownloadIcon from 'assets/svgs/FolderDownloadIcon';
import { getArrFromString, removeFirstBlank } from 'shared/utils/utils';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import { apiContentVoteAI } from 'shared/api/content';

export interface QuestionData {
  ai_id?: number;
  ai_type?: 'user' | 'general' | 'organisation' | 'group';
  ai_name?: string;
  ai_image?: string;
  creator_id?: number;
  creator_image?: string;
  creator_name?: string;
  question: string;
  ai_answer: string;
  id: number;
  bookmark_id: number;
  views: number;
  points: number;
  answer_count: number;
  timestamp: string;
  is_anonymous: number;
  likes: number;
  dislikes: number;
  user_like: any;

  top_creator_id: number;
  top_creator_image: string;
  top_creator_name: string;
  top_points: number;
  top_user_vote: number;
  top_answer_text: string;
  top_answer_id: number;
  top_is_anonymous: number;
  top_is_bookmarked: number;
  top_1: number;
  top_2: number;
  top_3: number;
  top_answer_filename: string;
  top_answer_size: string;
  top_answer_media_type: string;
  images?: string;
}

interface CardTrendingQuestionProps {
  data: QuestionData;
  type: 'top' | 'creator';
  hideBookMark?: boolean;
  onShareMedia: (data: QuestionData, type: string) => void;
}

export const CardSimilarQuestion = ({
  data,
  type,
  hideBookMark,
  onShareMedia
}: CardTrendingQuestionProps) => {
  const navigate = useNavigate();

  const [isBookmarking, setIsBookmarking] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(
    data.top_is_bookmarked === 1
  );

  const [likeCount, setLikeCount] = useState(data.likes);
  const [dislikeCount, setDislikeCount] = useState(data.dislikes);
  const [userVote, setUserVote] = useState(data.user_like);

  const [answerPoints, setAnswerPoints] = useState(data.top_points);
  const [questionPoints, setQuestionPoints] = useState(data.points);

  const onDetail = (bookmark_id) => {
    navigate(`/school/question/${bookmark_id}`);
  };

  const onBookmarkAnswer = async () => {
    if (!isBookmarking) {
      setIsBookmarking(true);

      if (isBookmarked) {
        const res = await apiQAUnBookMarkAnswer({
          answer: data.top_answer_id
        });

        if (res.success) {
          setIsBookmarked(false);
        }
      } else {
        const res = await apiQABookMarkAnswer({
          answer: data.top_answer_id,
          item_id: undefined,
          item_type: 'user'
        });

        if (res.success) {
          setIsBookmarked(true);
        }
      }

      setIsBookmarking(false);
    }
  };

  const onClickShare = (modalType) => {
    onShareMedia(data, modalType);
  };

  const onVote = async (vote: number) => {
    const res = await apiContentVoteAI({
      item_id: data.top_answer_id,
      item_type: 'answer',
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      setLikeCount(voteData.likes);
      setDislikeCount(voteData.dislikes);
    }
  };

  const onViewProfile = (id) => {
    navigate(`/profile/${id}`);
  };

  const onViewOrg = (id) => {
    navigate(`/school/${id}`);
  };

  const imageUrl = useMemo(() => {
    if (data.images) {
      return getArrFromString(data.images, ',http')[0];
    } else {
      return null;
    }
  }, [data]);

  const renderTopView = () => {
    return (
      <div className="question_box_top">
        <div className="question_box_top_user">
          {type === 'top' ? renderTopCreatorInfo() : renderCreatorCreatorInfo()}
          {renderPlaceInfo()}
        </div>
        <div className="right_panel">
          {data.top_answer_media_type !== 'doc' && (
            <div
              className="share_btn clickable"
              onClick={(e) => {
                e.stopPropagation();
                onClickShare('question');
              }}
            >
              <div
                style={{
                  opacity: '0.3',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <ShareIcon />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderTopCreatorInfo = () => {
    if (data.top_is_anonymous === 1) {
      <div className="align-vertical-center">
        <DefaultAvatarIcon size={24} />
        <div className="font-medium font16 user_name" style={{ marginLeft: 8 }}>
          {'Anonymous'}
        </div>
      </div>;
    } else {
      if (data.top_creator_id) {
        return (
          <div
            className="align-vertical-center clickable"
            onClick={() => onViewProfile(data.top_creator_id)}
          >
            {data.top_creator_image ? (
              <img
                className="creator_image"
                src={data.top_creator_image}
                width="24"
                height="24"
                style={{ borderRadius: 24 }}
              ></img>
            ) : (
              <DefaultAvatarIcon size={24} />
            )}

            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {data.top_creator_name ? data.top_creator_name : 'Anonymous'}
            </div>
          </div>
        );
      } else {
        return (
          <div className="align-vertical-center">
            <RobotIcon />
            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {'General AI'}
            </div>
          </div>
        );
      }
    }
  };

  const renderCreatorCreatorInfo = () => {
    if (data.is_anonymous === 1) {
      <div className="align-vertical-center">
        <DefaultAvatarIcon size={24} />
        <div className="font-medium font16 user_name" style={{ marginLeft: 8 }}>
          {'Anonymous'}
        </div>
      </div>;
    } else {
      if (data.creator_id) {
        return (
          <div
            className="align-vertical-center clickable"
            onClick={() => onViewProfile(data.creator_id)}
          >
            {data.creator_image ? (
              <img
                className="creator_image"
                src={data.creator_image}
                width="24"
                height="24"
                style={{ borderRadius: 24 }}
              ></img>
            ) : (
              <DefaultAvatarIcon size={24} />
            )}

            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {data.creator_name ? data.creator_name : 'Anonymous'}
            </div>
          </div>
        );
      } else {
        return (
          <div className="align-vertical-center">
            <RobotIcon />
            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {'General AI'}
            </div>
          </div>
        );
      }
    }
  };

  const renderPlaceInfo = () => {
    if (
      !data.ai_type ||
      data.ai_type === 'user' ||
      data.ai_type === 'general' ||
      !data.ai_id
    ) {
      return <div />;
    } else {
      return (
        <div className="align-vertical-center">
          <div className="font-medium font16 in_label">in</div>
          <img
            className="creator_image clickable"
            src={data.ai_image}
            width="24"
            height="24"
            style={{ borderRadius: 24 }}
            onClick={() => onViewOrg(data.ai_id)}
          ></img>
          <div
            className="font-medium font16 org_name clickable"
            onClick={() => onViewOrg(data.ai_id)}
          >
            {data.ai_name}
          </div>
          <div className="font-medium font16 posted_question">
            posted a new question
          </div>
        </div>
      );
    }
  };

  const renderQuestion = () => {
    return (
      <div className="question_text font-bold font26">{data.question}</div>
    );
  };

  const renderAddAnswerbar = () => {
    return (
      <div
        className="add_answer_bar"
        onClick={() => onDetail(data.bookmark_id)}
      >
        <div className="left_side">
          <div className="question_info">
            <div className="snack">
              <div className="font16 question_info_icon">👀</div>
              <div className="font14 question_info_text">
                {`${data.views} view${data.views > 1 ? 's' : ''}`}
              </div>
            </div>
            <div className="snack">
              <div className="font16 question_info_icon">💬</div>
              <div className="font14 question_info_text">
                {`${data.answer_count} answer${
                  data.answer_count > 1 ? 's' : ''
                }`}
              </div>
            </div>
          </div>
          <div
            style={{
              width: 2,
              height: 16,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              margin: `0px 16px`
            }}
          />
          <div className="question_points font14 font-regular">
            <UCoinIcon />
            &nbsp;
            {`${questionPoints} pt${questionPoints > 1 ? 's' : ''}`}
          </div>
        </div>
        <div className="right_side">
          <div className="question_date font14">
            {moment(data.timestamp).format('DD MMM yyyy').toString()}
          </div>
        </div>
      </div>
    ); //
  };

  const renderTopUserInfoBar = () => {
    return (
      <div className="answer_box_top">
        <div className="answer_box_top_left">
          <div className="answer_box_top_user">
            {data.top_creator_id ? (
              <>
                {data.top_creator_image === '' ? (
                  <DefaultAvatarIcon size={32} />
                ) : (
                  <img
                    src={data.top_creator_image}
                    style={{
                      width: '32px',
                      height: '32px',
                      borderRadius: '50%'
                    }}
                  />
                )}
                <div
                  className="user_name font-bold font16 clickable"
                  onClick={() => onViewProfile(data.top_creator_id)}
                >
                  {data.top_creator_name}
                </div>
              </>
            ) : (
              <div className="row_align_items">
                <img
                  className="creator_image"
                  src={data.ai_image}
                  width="24"
                  height="24"
                  style={{ borderRadius: 24, marginRight: 8 }}
                ></img>
                <div className="font-medium font16 org_name">
                  {`${data.ai_name} AI`}
                </div>
              </div>
            )}
          </div>
          {data.top_creator_id && (
            <div className="top_voted_mark font-bold">Top</div>
          )}
        </div>
        {data.top_creator_id && (
          <div className="answer_box_top_right">
            <div className="answer_date">
              {moment(data.timestamp).format('DD MMM yyyy').toString()}
            </div>
            <div className="divide_line"></div>
            <div className="answer_top_bar_other_info_pt_btn">
              <div className="answer_point">
                <UCoinIcon />
                &nbsp;
                {`${answerPoints} pt${answerPoints > 1 ? 's' : ''}`}
              </div>
              {data.top_answer_media_type === 'text' && (
                <div
                  className="share_btn clickable"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickShare('answer');
                  }}
                >
                  <div
                    style={{
                      opacity: '0.3',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <ShareIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderAnswerBody = () => {
    if (data.ai_answer != null || data.top_answer_text !== null)
      return (
        <div
          className="answer_box"
          style={
            !data.top_answer_media_type || data.top_answer_media_type === 'text'
              ? {}
              : { marginTop: 32 }
          }
          onClick={(e) => e.stopPropagation()}
        >
          {renderTopUserInfoBar()}
          {data.top_answer_media_type === null
            ? renderAnswer()
            : data.top_answer_media_type === 'text'
            ? renderAnswer()
            : renderFileAnswer()}
          {renderReivews()}
        </div>
      );
  };

  const renderFileAnswer = () => {
    if (data.top_answer_filename !== null && data.top_answer_size !== null)
      return (
        <div className="answer_media" style={{ margin: '32px 0px' }}>
          <div className="answer_media_content">
            <PdfFileIcon size={72} />
            <div className="answer_media_content_txt">
              <div className="answer_media_content_txt_title font20 font-bold">
                {data.top_answer_filename}
              </div>
              <div className="answer_media_content_txt_size font16">
                Size {data.top_answer_size}
              </div>
            </div>
          </div>
          <a
            href={data.top_answer_text}
            style={{ textDecoration: 'none' }}
            target="blank"
          >
            <div className="answer_media_download_btn">
              <FoloderDownloadIcon />
              <div className="answer_media_download_btn_txt font14 font-bold">
                Download
              </div>
            </div>
          </a>
        </div>
      );
  };

  const renderAnswer = () => {
    if (data.ai_answer != null || data.top_answer_text !== null)
      return (
        <div className="row_space_between">
          <div
            className="trending_answer_text font-regular font20"
            style={{ marginTop: 16 }}
          >
            <pre className="font20">
              {removeFirstBlank(
                data.top_answer_id ? data.top_answer_text : data.ai_answer
              )}
            </pre>
          </div>
          {imageUrl && imageUrl.startsWith('https') && (
            <img
              src={imageUrl}
              style={{
                width: 240,
                height: 160,
                objectFit: 'cover',
                borderRadius: 16,
                margin: 16,
                marginRight: 0
              }}
            />
          )}
        </div>
      );
  };

  const renderReivews = () => {
    return (
      <div className="answer_box_bottom">
        {data.top_answer_id ? (
          <div className="answer_box_bottom_left">
            <div className={'agree_btn clickable'} style={{ padding: 8 }}>
              <AudioPlayIcon color="#FF3E9A" />
            </div>
            <div
              className={
                userVote === 1
                  ? 'agree_btn_highlighted clickable'
                  : 'agree_btn clickable'
              }
              onClick={() => onVote(1)}
            >
              {/* <HeartIcon /> */}
              <div>{`\u2764`}</div>
              <div className="btn_number font-medium font12">{likeCount}</div>
            </div>
            <div
              className={
                userVote === 0
                  ? 'agree_btn_highlighted clickable'
                  : 'agree_btn clickable'
              }
              onClick={() => onVote(0)}
            >
              {/* <HeartBreakIcon /> */}

              <div className="font-medium font18">{`\u{1F494}`}</div>
              <div className="btn_number font-medium font12">
                {dislikeCount}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {!hideBookMark &&
          (isBookmarking
            ? renderIsBookmarking()
            : isBookmarked
            ? renderBookmarkedBtn()
            : renderBookmarkBtn())}
      </div>
    );
  };

  const renderIsBookmarking = () => {
    return (
      <div className="bookmark_btn">
        <LoadingCircular color="main" size={20} />
      </div>
    );
  };

  const renderBookmarkBtn = () => {
    return (
      <div className="bookmark_btn clickable" onClick={onBookmarkAnswer}>
        <BookMarkedIcon color={'rgba(0, 0, 0, 0.4)'} />
        <div
          className="btn_label font-reqular font14"
          style={{ color: 'rgba(0, 0, 0)' }}
        >
          Bookmark this Answer
        </div>
      </div>
    );
  };

  const renderBookmarkedBtn = () => {
    return (
      <div className="bookmark_btn clickable" onClick={onBookmarkAnswer}>
        <BookMarkedIcon opacity="0.4" />
        <div className="btn_label font-reqular font14">
          Bookmarked this Answer
        </div>
      </div>
    );
  };

  return (
    <div className="card_similar_question">
      <div
        className="similar_question_box clickable"
        onClick={() => onDetail(data.bookmark_id)}
      >
        {renderTopView()}
        {renderQuestion()}
        {renderAddAnswerbar()}
        {renderAnswerBody()}
      </div>
    </div>
  );
};
