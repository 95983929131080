import React from 'react';

function BoltIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.color ?? '#000'}
        d="M19.89 9.55A1 1 0 0019 9h-5V3a1 1 0 00-1.309-1.003 1 1 0 00-.501.362l-8 11a1 1 0 00-.08 1A1 1 0 005 15h5v6a1 1 0 001.454.893 1 1 0 00.356-.303l8-11a1 1 0 00.08-1.04zM12 17.92V14a1 1 0 00-1-1H7l5-6.92V10a1 1 0 001 1h4l-5 6.92z"
      ></path>
    </svg>
  );
}

export default BoltIcon;
