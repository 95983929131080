import { SuccessJoinGroupModal } from 'components/Modals/SuccessJoinGroupModal';
import React, { useEffect, useState } from 'react';
import {
  apiGetGroupsInvitation,
  apiGetSuggestedGroups
} from 'shared/api/group';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { CardGroupInvitation } from './CardGroupInvitation';
import { CardGroupRecommendation } from './CardGroupRecommendation';

import './index.styles.scss';

interface Props {
  changedId: any;
}

export const GroupInvitation = ({ changedId }: Props) => {
  const [arrData, setArrData] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingInvited, setIsLoading] = useState(false);
  const [isLoadedInviteData, setIsLoadedInviteData] = useState(false);

  const [arrRecommendData, setArrRecommendData] = useState<Array<any>>([]);
  const [lastIdRecommend, setLastIdRecommend] = useState(0);
  const [hasMoreRecommend, setHasMoreRecommend] = useState(true);
  const [isLoadingRecommend, setIsLoadingRecommend] = useState(false);

  const [showSuccessJoinModal, setShowSuccessJoinModal] = useState(false);
  const [selectedGroupDetail, setSelectedGroupDetail] = useState(null);

  useEffect(() => {
    loadInvitedData();
    loadRecommendData();
  }, []);

  useEffect(() => {
    setArrRecommendData((prev) =>
      prev.map((item, index) => {
        if (item.id === changedId) {
          return {
            ...item,
            isRequested: true
          };
        } else {
          return item;
        }
      })
    );
  }, [changedId]);

  const loadInvitedData = async (init = true) => {
    if (init) {
      setIsLoading(true);

      const res = await apiGetGroupsInvitation({ limit: 10, offset: 0 });

      if (res.success) {
        setArrData(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoadedInviteData(true);
      }

      setIsLoading(false);
    } else {
      if (!isLoadingInvited && hasMore) {
        const res = await apiGetGroupsInvitation({ limit: 10, offset: lastId });
        if (res.success) {
          setArrData((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const loadRecommendData = async (init = true) => {
    if (init) {
      setIsLoadingRecommend(true);

      const res = await apiGetSuggestedGroups({ limit: 10, offset: 0 });

      if (res.success) {
        setArrRecommendData(res.data);
        setLastIdRecommend(res.lastId);
        setHasMoreRecommend(res.hasMore);
      }

      setIsLoadingRecommend(false);
    } else {
      if (!isLoadingRecommend && hasMoreRecommend) {
        const res = await apiGetSuggestedGroups({
          limit: 10,
          offset: lastIdRecommend
        });
        if (res.success) {
          setArrRecommendData((prev) => [...prev, ...res.data]);
          setLastIdRecommend(res.lastId);
          setHasMoreRecommend(res.hasMore);
        }
      }
    }
  };

  const removeInvitedGroupOnUI = (groupId) => {
    setArrData((prev) => prev.filter((item, index) => item.id != groupId));
  };

  const removeRecommenedGroupOnUI = (groupId) => {
    setArrRecommendData((prev) =>
      prev.filter((item, index) => item.id != groupId)
    );
  };

  const onClearItem = (groupId, isAccepting, groupDetail) => {
    if (isAccepting) {
      setSelectedGroupDetail(groupDetail);
      setShowSuccessJoinModal(true);
      removeInvitedGroupOnUI(groupId);
    } else {
      removeInvitedGroupOnUI(groupId);
    }
  };

  const renderGroupInvitation = () => {
    return (
      <div>
        <div className="font-bold font32">Group Invitations</div>
        <div style={{ marginBottom: 42 }}>
          {isLoadingInvited && (
            <div style={{ marginTop: 32 }}>
              <LoadingBarAnimation />
            </div>
          )}
          {arrData.length > 0 ? (
            <>
              {arrData.map((item, index) => (
                <CardGroupInvitation
                  key={`card_group_invitation_${index}`}
                  data={item}
                  onRemove={(groupId, isAccepting) =>
                    onClearItem(groupId, isAccepting, item)
                  }
                />
              ))}
            </>
          ) : (
            isLoadedInviteData && (
              <div style={{ paddingLeft: 16 }}>
                <div className="invitation_empty_view">
                  <div style={{ fontSize: 56 }}>💌</div>
                  <div className="title font-bold font18">
                    No group invitations yet
                  </div>
                  <div className="description font-regular font14">
                    Reach out to someone you know to be invited,
                    <br />
                    or request to join a group below.
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const renderSuccessJoinModal = () => {
    return (
      <SuccessJoinGroupModal
        show={showSuccessJoinModal}
        groupDetail={selectedGroupDetail}
        onClose={() => setShowSuccessJoinModal(false)}
      />
    );
  };

  const renderGroupRecommendation = () => {
    return (
      <>
        <div className="font-bold font32">Group Recommendations</div>
        {isLoadingRecommend && (
          <div style={{ marginTop: 32 }}>
            <LoadingBarAnimation />
          </div>
        )}
        {arrRecommendData.map((item, index) => {
          return (
            <CardGroupRecommendation
              key={`card_group_recommend_${index}`}
              data={item}
              onRemove={removeRecommenedGroupOnUI}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className="group_invitation_body">
      {renderGroupInvitation()}
      {renderGroupRecommendation()}
      {renderSuccessJoinModal()}
    </div>
  );
};
