import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import FileIcon from 'assets/svgs/FileIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import LockIcon from 'assets/svgs/LockIcon';
import MicIcon from 'assets/svgs/MicIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import React from 'react';

import './index.styles.scss';

export const ReferenceDetail = ({ data, type = 'home' }) => {
  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 28, height: 28 }}>
        {type === 'pdf' ? (
          <PdfFileIcon size={28} />
        ) : type === 'audio' ? (
          <MicIcon size={24} color="#FF3E9A" opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={28} color="#FF3E9A" opacity={1} />
        ) : (
          <FileIcon />
        )}
      </div>
    );
  };

  return (
    <>
      {data.length > 0 && (
        <div className="reference_box">
          <div className="font-bold font16">References</div>
          {data.map((item, index) => {
            if (!!item.text) {
              return (
                <div key={`card_ref_${index}`}>
                  <div
                    className="font-regular font16 reference_text"
                    style={{ color: 'rgba(0, 0, 0, 0.8)' }}
                  >
                    {item.text && item.text}
                  </div>
                  <div className="reference_user">
                    {item.name && item.image.startsWith('https') ? (
                      <img
                        src={item.image}
                        style={{
                          width: 16,
                          height: 16,
                          borderRadius: 16
                        }}
                      />
                    ) : (
                      item.name && <DefaultAvatarIcon size={16} />
                    )}
                    <div>{renderFileIcon(item.fileType)}</div>
                    <div
                      className="reference_name font-medium font12"
                      style={{ marginLeft: 0, maxWidth: '100%' }}
                    >
                      {item.fileName ?? item.url}
                    </div>
                    <div className="font-regular font12 on_label">on</div>
                    <LockIcon opacity={0.4} />
                    <div
                      className="font-regular font12"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {type === 'group'
                        ? 'Group knowledge base'
                        : 'Private dump'}
                    </div>
                  </div>
                  {data.length > 1 && (
                    <div
                      className="row_divide_line"
                      style={{ margin: '24px 0' }}
                    />
                  )}
                </div>
              );
            } else {
              return <div key={`card_ref_${index}`} />;
            }
          })}
        </div>
      )}
    </>
  );
};
