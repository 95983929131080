import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import React, { useMemo } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useNavigate } from 'react-router-dom';
import { convertFirstToUpper } from 'shared/utils/utils';
import './index.styles.scss';

export interface StatementData {
  id: number;
  text: string;
  creator_id: any;
  creator_image: string;
  creator_name: string;
  timestamp: string;
}
interface CardDidYouKnow {
  data: StatementData;
  onClick: () => void;
  onDetail: (data: StatementData) => void;
  onShare: (data: StatementData) => void;
}

export const CardDidYouKnow = ({
  data,
  onClick,
  onDetail,
  onShare
}: CardDidYouKnow) => {
  const navigate = useNavigate();
  const visibility = React.useContext(VisibilityContext);

  const onClickShare = () => {
    onShare(data);
  };

  const onClickDetail = () => {
    onDetail(data);
  };

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const renderCreatorInfo = () => {
    return (
      <div className="top_recommend_question_body_user" onClick={onViewProfile}>
        <div className="top_recommend_question_body_user_avatar">
          {data.creator_image && data.creator_image.startsWith('https') ? (
            <img
              src={data.creator_image}
              style={{
                width: 24,
                height: 24,
                borderRadius: 24,
                objectFit: 'cover'
              }}
            />
          ) : (
            <DefaultAvatarIcon size={24} />
          )}
        </div>
        <div className="top_recommend_question_body_user_name font16 font-normal">
          {data.creator_name}
        </div>
      </div>
    );
  };

  const descTxt = useMemo(() => {
    const tmpTxt = data.text.startsWith('Did you know that ')
      ? data.text.replace('Did you know that ', '').replace('?', '')
      : data.text.startsWith('Did you know that')
      ? data.text.replace('Did you know that', '').replace('?', '')
      : data.text.replace('Did you know', '').replace('?', '');

    return convertFirstToUpper(tmpTxt);
  }, [data.text]);

  return (
    <div className="top_recommend_question" onClick={() => onClick()}>
      <div className="top_recommend_question_header">
        <div className="top_recommend_question_header_title font-medium font30">
          {data.text.startsWith('Did you know that ')
            ? data.text.replace('Did you know that ', '').replace('?', '')
            : data.text.startsWith('Did you know that')
            ? data.text.replace('Did you know that', '').replace('?', '')
            : data.text.replace('Did you know', '').replace('?', '')}
        </div>
      </div>
      <div className="top_recommend_question_body">
        {renderCreatorInfo()}
        <div className="top_recommend_question_body_btns">
          <div
            className="top_recommend_question_body_btns_detail clickable"
            onClick={onClickDetail}
          >
            Details
          </div>
          <div
            className="top_recommend_question_body_btns_share clickable"
            onClick={onClickShare}
          >
            <div
              style={{
                opacity: '0.3',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <ShareIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
