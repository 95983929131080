import BookMarkedIcon from 'assets/svgs/BookMarkedIcon';
import BookmarkOutlineIcon from 'assets/svgs/BookmarkOutlineIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import React, { useEffect, useState } from 'react';
import { apiFileFollowUp, apiFileMark } from 'shared/api/file';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { HighlightData } from './../index';
import Tooltip from '@mui/material/Tooltip';

import appAction from 'redux/app/action';

import './index.styles.scss';
import { useDispatch } from 'react-redux';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface DocumentFileAiViewProps {
  isSummarizing: boolean;
  dumpId: any;
  type: 'selection' | 'whole' | 'custom';
  answerData: any;
  highlight: number | undefined;
  highlightData: HighlightData | undefined;
  onShowConversation: () => void;
  onClose: () => void;
}

export const DocumentFileAiView = ({
  isSummarizing,
  dumpId,
  answerData,
  type,
  highlight,
  highlightData,
  onClose,
  onShowConversation
}: DocumentFileAiViewProps) => {
  const dispatch = useDispatch();

  const [isFollowingUp, setIsFollowingUp] = useState(false);
  const [isMarking, setIsMakring] = useState(false);
  const [isMarked, setIsMarked] = useState(false);

  useEffect(() => {
    onScrollToTop();
  }, []);

  const onAskUp = async () => {
    if (!isFollowingUp) {
      setIsFollowingUp(true);

      const width =
        type === 'selection' ? highlightData?.highlightAreas[0].width : null;
      const height =
        type === 'selection' ? highlightData?.highlightAreas[0].height : null;
      const top =
        type === 'selection' ? highlightData?.highlightAreas[0].top : null;
      const left =
        type === 'selection' ? highlightData?.highlightAreas[0].left : null;
      const pageIndex =
        type === 'selection'
          ? highlightData?.highlightAreas[0].pageIndex
          : null;

      const res = await apiFileFollowUp({
        dump: dumpId,
        summary: answerData,
        width,
        height,
        top,
        left,
        pageIndex,
        highlight
      });
      if (res.sucess) {
        onShowConversation();
      }

      setIsFollowingUp(false);
    }
  };

  const onMarkUp = async () => {
    if (!isMarking && !isMarked) {
      setIsMakring(true);

      const res = await apiFileMark({ highlight: highlight });
      if (res.success) {
        dispatch(appAction.refreshHighLightData());

        setIsMarked(true);
        showToast('success', 'Successfully marked!');
      }
      setIsMakring(false);
    }
  };

  const onScrollToTop = () => {
    const viewer = document.getElementById('document_file_ai_view');
    viewer?.scrollIntoView();
  };

  const renderTop = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <RobotIcon size={24} />
          <div className="font-bold font16" style={{ marginLeft: 8 }}>
            File AI
          </div>
        </div>
        <div className="row_align_items">
          <div
            className="action_btn clickable"
            style={{ width: 156 }}
            onClick={onMarkUp}
          >
            {isMarking ? (
              <LoadingCircular size={18} color="main" />
            ) : (
              <Tooltip
                placement="left"
                title={
                  <div className="font-regular font14">
                    Highlight this section of the document that is visible to
                    you and others
                  </div>
                }
                PopperProps={{
                  sx: {
                    marginRight: '232px !important',
                    marginTop: '30px !important'
                  }
                }}
              >
                <div className="row_align_items">
                  <div className="font-medium font12">Highlight section</div>
                  <div style={{ marginLeft: 8, display: 'flex' }}>
                    {!isMarked ? (
                      <BookmarkOutlineIcon color="#FF3E9A" />
                    ) : (
                      <BookMarkedIcon />
                    )}
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
          <Tooltip
            placement="left"
            title={
              <div className="font-regular font14">
                Open an AI chat and ask more details about this document
              </div>
            }
            PopperProps={{
              sx: {
                marginRight: '232px !important',
                marginTop: '30px !important'
              }
            }}
          >
            <div className="action_btn clickable" onClick={onAskUp}>
              {isFollowingUp ? (
                <LoadingCircular size={18} color="main" />
              ) : (
                <div className="font-bold font12" style={{ color: '#FF3E9A' }}>
                  Ask Follow Up
                </div>
              )}
            </div>
          </Tooltip>

          <div className="clickable" onClick={onClose}>
            <CloseIcon opacity={0.3} />
          </div>
        </div>
      </div>
    );
  };

  const renderAnswer = () => {
    return <div className="answer font-regular font16">{answerData}</div>;
  };

  const renderBottom = () => {
    return (
      <div className="row_align_items">
        <PdfFileIcon size={16} />
        <div className="font-medium font12" style={{ marginLeft: 12 }}>
          Chemisty Notes
        </div>
        <div
          className="font-medium font12"
          style={{ opacity: 0.4, marginLeft: 8 }}
        >
          (page 54)
        </div>
      </div>
    );
  };

  const renderLoading = () => {
    return <LoadingBarAnimation />;
  };

  return (
    <div className="document_file_ai_view" id="document_file_ai_view">
      {renderTop()}

      {isSummarizing ? renderLoading() : renderAnswer()}
      {/* {renderBottom()} */}
    </div>
  );
};
