import React from "react";

function TypographyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      fill="none"
      viewBox="0 0 18 12"
      // color=""
    >
      <path
        fill="#FF3E9A"
        d="M12.333 1A.833.833 0 0011.5.167h-10a.833.833 0 100 1.667h4.167V11a.833.833 0 001.666 0V1.834H11.5A.833.833 0 0012.333 1zM16.5 5.167h-5a.833.833 0 000 1.667h1.666V11a.833.833 0 001.667 0V6.834H16.5a.833.833 0 100-1.667z"
      ></path>
    </svg>
  );
}

export default TypographyIcon;
