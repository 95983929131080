import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import { socket } from 'components/Auth';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  apiChatCreate,
  apiChatGet,
  apiChatGetAi,
  apiChatRooms
} from 'shared/api/chat';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';
import { ChatBody } from './ChatBody';
import { ChatHistory } from './ChatHistory';
import { ChatRoomData } from './ChatHistory/CardChatHistory';
import moment from 'moment';

import './index.styles.scss';
import { ShareQuestionToOrgModal } from 'components/Modals/ShareQuestionToOrgModal';
import { SelectOrgModal } from 'components/Modals/SelectOrgModal';
import AlertCheckIcon from 'assets/svgs/AlertCheckIcon';
import RobotIcon from 'assets/svgs/RobotIcon';

export const Conversation = () => {
  const params = useParams();
  const navigate = useNavigate();

  // @ts-ignore
  const [aiType, setAiType] = useState<
    'general' | 'user' | 'group' | 'organisation' | any
  >(params.type);
  const [aiId, setAiId] = useState(params.id);

  // @ts-ignore
  const [selectedAiType, setSelectedAiType] = useState<
    'general' | 'user' | 'group' | 'organisation' | any
  >(params.type);

  // conversation
  const [roomId, setRoomId] = useState<any>();
  const [roomInfo, setRoomInfo] = useState<ChatRoomData | undefined>();
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);

  // rooms
  const [arrRoom, setArrRoom] = useState<Array<any>>([]);
  const [lastIdRoom, setLastIdRoom] = useState(0);
  const [hasMoreRoom, setHasMoreRoom] = useState(true);
  const [isLoadingRoom, setIsLoadingRoom] = useState(false);

  // messages
  const [arrMessage, setArrMessage] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [isSendToOrg, setIsSendToOrg] = useState(false);
  const [isSelectOrg, setIsSelectOrg] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [snackBarShow, setSnackBarShow] = useState(false);
  useEffect(() => {
    loadRooms();
    checkIfExistedConversation(aiId, aiType);
  }, []);

  useEffect(() => {
    loadMessage();
  }, [roomId, selectedAiType]);

  useEffect(() => {
    if (socket && roomId) {
      socket.emit('join-room', `chat-ai-${roomId}`);

      const newMessageHandler = (data) => {
        onAddMessageOnUI(data);
      };

      socket.on('new-chat-message-ai', newMessageHandler);

      return () => {
        socket.removeListener('new-chat-message-ai', newMessageHandler);
      };
    }
  }, [socket, roomId]);

  const checkIfExistedConversation = async (
    newAiId,
    newAiType,
    shouldUpdateRoom = true
  ) => {
    const res = await apiChatGet({
      room: undefined,
      ai_id: newAiType === 'general' ? null : newAiId,
      ai_type: newAiType,
      limit: 0,
      offset: 0
    });

    if (res.success) {
      if (res.room) {
        setRoomId(res.room.id);
        setRoomInfo(res.room);

        if (selectedAiType !== aiType) onMoveRoomToTopOnUI(res.room);
      } else {
        initRoom(res.info, newAiType);
      }
    }
  };

  const initRoom = async (defaultAi, aiType) => {
    removeOldNewRoom();
    setRoomId(null);
    if (defaultAi) {
      const defaultRoom: ChatRoomData = {
        id: -1,
        name: '...',
        last_interacted: moment().toString(),
        ai_id: defaultAi.ai_id,
        ai_name: defaultAi.ai_name,
        ai_image: defaultAi.ai_image,
        ai_type: aiType,
        is_voted: 0
      };

      setRoomInfo(defaultRoom);

      // add chat history
      setArrRoom((prev) => [...prev, defaultRoom]);
    } else {
      const res = await apiChatGetAi({
        ai_type: aiType,
        query: '',
        limit: 10,
        offset: 0
      });

      if (res.success && res.data && res.data.length > 0) {
        const defaultRoom: ChatRoomData = {
          id: -1,
          name: '...',
          last_interacted: moment().toString(),
          ai_id: res.data[0].ai_id,
          ai_name: res.data[0].ai_name,
          ai_image: res.data[0].ai_image,
          ai_type: aiType,
          is_voted: 0
        };

        setRoomInfo(defaultRoom);

        // add chat history
        setArrRoom((prev) => [...prev, defaultRoom]);
      } else {
        setRoomInfo(undefined);

        // add general chat history
        const defaultRoom: ChatRoomData = {
          id: -1,
          name: '...',
          last_interacted: moment().toString(),
          ai_id: -1,
          ai_name: '',
          ai_image: '',
          ai_type: 'general',
          is_voted: 0
        };

        // add chat history
        setArrRoom((prev) => [...prev, defaultRoom]);

        setRoomId(-1);
        setRoomInfo(defaultRoom);
        setSelectedAiType('general');
      }
    }
  };

  const removeOldNewRoom = () => {
    setArrRoom((prev) => prev.filter((item, index) => item.id !== -1));
  };

  const loadMessage = async (init = true) => {
    if (init) {
      setIsLoadingMessage(true);
      const res = await apiChatGet({
        room: roomId,
        ai_id: selectedAiType === 'general' ? 1 : parseInt(aiId!),
        ai_type: selectedAiType,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrMessage(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      } else {
        setArrMessage([]);
      }

      setIsLoadingMessage(false);
    } else {
      if (!isLoadingMessage && hasMore) {
        setIsLoadingMessage(true);

        const res = await apiChatGet({
          room: roomId,
          ai_id: selectedAiType === 'general' ? 1 : parseInt(aiId!),
          ai_type: selectedAiType,
          limit: 10,
          offset: 0
        });

        if (res.success) {
          setArrMessage((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }

        setIsLoadingMessage(false);
      }
    }
  };

  const loadRooms = async (init = true) => {
    if (init) {
      setIsLoadingRoom(true);

      const res = await apiChatRooms({
        limit: 100,
        offset: 0
      });

      if (res.success) {
        setArrRoom(res.data);
        setLastIdRoom(res.lastId);
        setHasMoreRoom(res.hasMore);
      }

      setIsLoadingRoom(false);
    } else {
      if (!isLoadingRoom && hasMoreRoom) {
        setIsLoadingRoom(true);

        const res = await apiChatRooms({
          limit: 100,
          offset: lastIdRoom
        });

        if (res.success) {
          setArrRoom((prev) => [...prev, ...res.data]);
          setLastIdRoom(res.lastId);
          setHasMoreRoom(res.hasMore);
        }

        setIsLoadingRoom(false);
      }
    }
  };

  const onAddMessageOnUI = (data) => {
    setArrMessage((prev) => [{ ...data, is_new: true }, ...prev]);
    setArrRoom((prev) =>
      prev.map((item, index) => {
        if (item.id === data.room) {
          return {
            ...item,
            name: data.name
          };
        } else {
          return item;
        }
      })
    );
    setIsWaitingResponse((prev) => !prev);
  };
  const onVote = () => {
    loadRooms();
  };
  const onCreateChat = async (firstMessage) => {
    const res = await apiChatCreate({
      ai_id: selectedAiType === 'general' ? 1 : roomInfo?.ai_id,
      ai_type: selectedAiType,
      type: 'text',
      message: firstMessage
    });

    setIsWaitingResponse(false);
    if (res.success) {
      setRoomId(res.room.id);
      setRoomInfo(res.room);
      onUpdateNewRoomOnUI(res.room);
      setArrMessage((prev) => [{ ...res.data, is_new: true }, ...prev]);
    }
  };

  const onUpdateNewRoomOnUI = (newRoomData) => {
    if (arrRoom.length > 0) {
      setArrRoom((prev) =>
        prev.map((item, index) => {
          if (item.id === -1) {
            return newRoomData;
          } else {
            return item;
          }
        })
      );
    } else {
      setArrRoom((prev) => [newRoomData, ...prev]);
    }
  };

  const onMoveRoomToTopOnUI = (roomInfo) => {
    const existedRoom = arrRoom.filter(
      (item, index) => item.id === roomInfo.id
    );
    const newArrRoom = arrRoom.filter((item, index) => item.id !== roomInfo.id);

    if (existedRoom.length > 0) {
      setArrRoom([...newArrRoom, roomInfo]);
    } else {
      setArrRoom((prev) => [...prev, roomInfo]);
    }
  };

  const onCreateNewConversation = () => {
    if (roomInfo) {
      if (arrRoom.find((item, _) => item.id === -1)) {
        setArrRoom((prev) =>
          prev.map((item, _) => {
            if (item.id === -1) {
              const newTmpRoom = {
                ...item,
                last_interacted: moment().toString(),
                ai_name: roomInfo.ai_name,
                ai_image: roomInfo.ai_image,
                ai_type: selectedAiType
              };
              setRoomInfo(newTmpRoom);
              return newTmpRoom;
            } else {
              return item;
            }
          })
        );
      } else {
        const newTmpRoom: ChatRoomData = {
          id: -1,
          name: '...',
          last_interacted: moment().toString(),
          ai_id: roomInfo.ai_id,
          ai_name: roomInfo.ai_name,
          ai_image: roomInfo.ai_image,
          ai_type: selectedAiType,
          is_voted: 0
        };
        setRoomInfo(newTmpRoom);
        setArrRoom((prev) => [...prev, newTmpRoom]);
      }

      setArrMessage([]);
      setRoomId(-1);
    }
  };

  const onUpdateAIType = (newAiType) => {
    setSelectedAiType(newAiType);
    checkIfExistedConversation(null, newAiType);
  };

  const onChangeAi = (aiInfo) => {
    setAiId(aiInfo.ai_id);
    setSelectedAiType(aiInfo.ai_type);
    checkIfExistedConversation(aiInfo.ai_id, aiInfo.ai_type, false);

    // update room info
    const newRoomInfo: ChatRoomData = {
      id: -1,
      name: '...',
      last_interacted: moment().toString(),
      ai_id: aiInfo.ai_id,
      ai_name: aiInfo.ai_name,
      ai_image: aiInfo.ai_image,
      ai_type: aiInfo.ai_type,
      is_voted: 0
    };
    setRoomInfo(newRoomInfo);
  };
  const onShareQuestion = () => {
    setSnackBarShow(true);
    setInterval(() => {
      setSnackBarShow(false);
    }, 3000);
  };
  const renderHeader = () => {
    return (
      <div className="conversation_header">
        <div className="conversation_header_left">
          <div className="back_icon clickable" onClick={() => navigate(-1)}>
            <AngleLeftIcon />
          </div>
          <div className="conversation_header_title font-bold font32">
            Conversation
          </div>
        </div>
        <div className="row_align_items">
          <div
            className="btn_find_creator row_align_items clickable"
            onClick={() => navigate('/creators')}
          >
            <RobotIcon size={24} />
            <div className="btn_txt font-bold font16">Find Creators</div>
          </div>
          <div
            className="conversation_header_right clickable"
            onClick={onCreateNewConversation}
          >
            <PlusIcon />
            <div className="start_new font-bold font16">Start New</div>
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="conversation_body">
        <div className="conversation_body_chat_history">
          <ChatHistory
            data={arrRoom}
            selectedRoom={roomInfo}
            onSelected={(newRoom) => {
              setRoomId(newRoom.id);
              setRoomInfo(newRoom);
              setSelectedAiType(newRoom.ai_type);
            }}
          />
        </div>
        <div className="conversation_body_chat_body">
          <ChatBody
            data={arrMessage}
            roomId={roomId}
            roomInfo={roomInfo}
            isLoadingMessage={isLoadingMessage}
            isWaitingResponse={isWaitingResponse}
            selectedAiType={selectedAiType}
            onAdd={onAddMessageOnUI}
            onCreateChat={onCreateChat}
            onChangeAi={onChangeAi}
            onUpdateAIType={onUpdateAIType}
            onUpdateWaitingStatus={setIsWaitingResponse}
            setIsSendToOrg={setIsSendToOrg}
            setCurrentQuestion={setCurrentQuestion}
            onVote={onVote}
          />
        </div>
      </div>
    );
  };

  const renderSnackbar = () => {
    return (
      <div className="snackbar">
        <AlertCheckIcon />
        <div className="alert-message font-bold font15">
          Successfully post question on org
        </div>
      </div>
    );
  };
  const renderShareQuestionToOrgMoal = () => {
    return (
      <ShareQuestionToOrgModal
        type="conversation"
        chatMessages={arrMessage}
        show={isSendToOrg}
        onClose={() => {
          setIsSendToOrg(false);
        }}
        onSuccess={() => {
          setIsSendToOrg(false);
          setIsSelectOrg(true);
        }}
        question={currentQuestion}
        selectedOrgId={aiId}
      />
    );
  };
  const renderSelectOrgMoal = () => {
    return (
      <SelectOrgModal
        type="conversation"
        show={isSelectOrg}
        onBack={() => {
          setIsSelectOrg(false);
          setIsSendToOrg(true);
        }}
        onClose={() => {
          setIsSelectOrg(false);
        }}
        onSuccess={() => {
          setIsSelectOrg(false);
          onShareQuestion();
        }}
        question={currentQuestion}
      />
    );
  };
  return (
    <div className="conversation">
      <MainHeader />
      {renderHeader()}
      {renderBody()}
      {isSendToOrg && renderShareQuestionToOrgMoal()}
      {isSelectOrg && renderSelectOrgMoal()}
      {snackBarShow && renderSnackbar()}
    </div>
  );
};
