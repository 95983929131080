import HttpClient from './apiClient';

export async function apiFileSummarize(params: {
  dump: any;
  text?: string;
  type?: string;
  from_page?: number;
  to_page?: number;
}) {
  try {
    const response = await HttpClient.postWithToken(`file/summarize`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiFileFollowUp(params: {
  dump: any;
  summary: string;
  width: any;
  height: any;
  top: any;
  left: any;
  pageIndex: any;
  highlight?: any;
}) {
  try {
    const response = await HttpClient.postWithToken(`file/followUp`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiFileHighLight(params: {
  dump: any;
  from_page: any;
  to_page: any;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`file/highlights`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiFileChat(params: {
  room: any;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`file/chat`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiFileMark(params: { highlight: any }) {
  try {
    const response = await HttpClient.postWithToken(`file/mark`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetFileMarks(params: {
  dump: any;
  query?: string;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`file/marks`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiFileQuestions(params: {
  dump: any;
  limit: number;
  offset: number;
}) {
  try {
    const response = await HttpClient.getWithToken(`file/questions`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
