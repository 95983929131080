import React from 'react';

function AddReferenceIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 12}
      height={props.size ?? 12}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        d="M10.667 5.33464H6.66699V1.33464C6.66699 1.15782 6.59675 0.988255 6.47173 0.863231C6.34671 0.738207 6.17714 0.667969 6.00033 0.667969C5.82351 0.667969 5.65394 0.738207 5.52892 0.863231C5.4039 0.988255 5.33366 1.15782 5.33366 1.33464V5.33464H1.33366C1.15685 5.33464 0.987279 5.40487 0.862254 5.5299C0.73723 5.65492 0.666992 5.82449 0.666992 6.0013C0.666992 6.17811 0.73723 6.34768 0.862254 6.47271C0.987279 6.59773 1.15685 6.66797 1.33366 6.66797H5.33366V10.668C5.33366 10.8448 5.4039 11.0143 5.52892 11.1394C5.65394 11.2644 5.82351 11.3346 6.00033 11.3346C6.17714 11.3346 6.34671 11.2644 6.47173 11.1394C6.59675 11.0143 6.66699 10.8448 6.66699 10.668V6.66797H10.667C10.8438 6.66797 11.0134 6.59773 11.1384 6.47271C11.2634 6.34768 11.3337 6.17811 11.3337 6.0013C11.3337 5.82449 11.2634 5.65492 11.1384 5.5299C11.0134 5.40487 10.8438 5.33464 10.667 5.33464Z"
        fill={props.color ?? "#FF3E9A"}
      />
    </svg>
  );
}

export default AddReferenceIcon;
