import React, { useEffect, useState } from 'react';

import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';

import './Home.styles.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MyDraft } from './MyDraft';
import { MyQuestion } from './MyQuestion';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from 'shared/utils/constants';
import { WriteNewDoc } from './WriteNewDoc';
import { ViewDoc } from './WriteNewDoc/ViewDoc';
import { sendAnalytics } from 'shared/utils/analytics';

export const HomeScreen = () => {
  const [selectedFilter, setSelectedFilter] = useState<Array<number>>([]);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  useEffect(() => {
    sendAnalytics('Home');
  }, []);

  const handleScrollWindow = () => {
    // const body = document.getElementById('home_body');
    // const bookmark_header = document.getElementById('bookmark_navBar');
    // let sticky_origin = bookmark_header?.offsetTop;
    // if (sticky_origin && body) {
    //   if (body?.scrollTop > 1500) {
    //     bookmark_header?.classList.add('sticky_bookmark_navbar');
    //   } else {
    //     bookmark_header?.classList.remove('sticky_bookmark_navbar');
    //   }
    // }
  };

  return (
    <div className="home_container">
      <MainHeader currentPage="user" />
      <div
        className={!isMobile ? 'home_body' : 'home_body_mobile'}
        id="home_body"
        onScroll={handleScrollWindow}
      >
        <Routes>
          <Route
            index
            path="/"
            element={<MyDraft filters={selectedFilter} type="home" />}
          />
          <Route
            path="/question"
            element={<MyQuestion filters={selectedFilter} />}
          />
          <Route path="/write/:type/:id" element={<WriteNewDoc />} />
          <Route path="view/:dumpId/*" element={<ViewDoc />} />
        </Routes>
      </div>
    </div>
  );
};
