import React, { useEffect, useState } from 'react';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import InfiniteScroll from 'react-infinite-scroll-component';
import { apiGetQAPostedQuestions } from 'shared/api/qa';

import './index.styles.scss';
import { QuestionAnswerShareModal } from 'components/Modals/QuestionAnswerShareModal';
import {
  CardTrendingQuestion,
  QuestionData
} from 'components/Main/Organisation/OrgHome/components/TrendingQuestion/CardTrendingQuestion';

interface MyQuestionProps {
  filters: Array<any>;
}

export const MyQuestion = ({ filters }: MyQuestionProps) => {
  const [arrQuestion, setArrQuestion] = useState<Array<any>>([]);
  const [lastIdDump, setLastIdDump] = useState(0);
  const [hasMoreDump, setHasMoreDump] = useState(true);
  const [isLoadingDump, setIsLoadingDump] = useState(false);

  const [selectedQuestionData, setSelectedQuestionData] = useState<
    QuestionData | undefined
  >();
  const [isShareModalShow, setIsShareModalShow] = useState(false);
  const [shareModalType, setShareModalType] = useState<'question' | 'answer'>(
    'question'
  );

  useEffect(() => {
    loadPostedData();
  }, []);

  const loadPostedData = async (init = true) => {
    if (init) {
      setIsLoadingDump(true);
      const res = await apiGetQAPostedQuestions({
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrQuestion(res.data);
        setLastIdDump(res.lastId);
        setHasMoreDump(res.hasMore);
      }
      setIsLoadingDump(false);
    } else {
      if (hasMoreDump && !isLoadingDump) {
        setIsLoadingDump(true);
        const res = await apiGetQAPostedQuestions({
          limit: 10,
          offset: lastIdDump
        });

        if (res.success) {
          setArrQuestion((prev) => [...prev, ...res.data]);
          setLastIdDump(res.lastId);
          setHasMoreDump(res.hasMore);
        }
        setIsLoadingDump(false);
      }
    }
  };

  const onShareHandler = (data, modalType) => {
    setSelectedQuestionData(data);
    setIsShareModalShow(true);
    setShareModalType(modalType);
  };

  const renderQuestions = () => {
    return (
      <div className="my_question_answers">
        <InfiniteScroll
          dataLength={arrQuestion.length}
          next={() => loadPostedData(false)}
          hasMore={hasMoreDump}
          loader={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingCircular size={20} color="main" />
            </div>
          }
          refreshFunction={() => {}}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          style={{ overflow: 'hidden' }}
        >
          {arrQuestion.map((item, index) => {
            return (
              <CardTrendingQuestion
                key={`card_my_question_${index}`}
                data={item}
                type={'creator'}
                hideBookMark={true}
                onShareMedia={(data, modalType) => {
                  onShareHandler(data, modalType);
                }}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    );
  };
  const renderShareQuestionToSocial = () => {
    return (
      <QuestionAnswerShareModal
        show={isShareModalShow}
        onClose={() => {
          setIsShareModalShow(false);
        }}
        question={selectedQuestionData}
        type={shareModalType}
      />
    );
  };
  return (
    <div className="my_question">
      {renderQuestions()}
      {renderShareQuestionToSocial()}
    </div>
  );
};
