import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { LeftArrow, RightArrow } from './ArrowBtns';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import { CardSharedLink } from './CardSharedLink';
import LinkIcon from 'assets/svgs/LinkIcon';
import './index.styles.scss';
import { apiGetContentLinks } from 'shared/api/content';

interface SharedLinksProps {
  selectedOrg: any;
  onShowAll: () => void;
}

export const SharedLinks = ({ selectedOrg, onShowAll }: SharedLinksProps) => {
  const [arrPreview, setArrPreview] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (selectedOrg) {
      loadData();
    }
  }, [selectedOrg]);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiGetContentLinks({
        query: '',
        // topics: selectedToics === '' ? undefined : selectedToics,
        // tag_ids: arrTagIds === '' ? undefined : arrTagIds,
        // tag_types: arrTagTypes === '' ? undefined : arrTagTypes,
        item_id: selectedOrg ? selectedOrg.id : undefined,
        item_type: 'organisation',
        received: true,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrPreview(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }
    } else {
      if (!isLoadingPreview && hasMore) {
        const res = await apiGetContentLinks({
          query: '',
          // topics: selectedToics === '' ? undefined : selectedToics,
          // tag_ids: arrTagIds === '' ? undefined : arrTagIds,
          // tag_types: arrTagTypes === '' ? undefined : arrTagTypes,
          item_id: selectedOrg ? selectedOrg.id : undefined,
          item_type: 'organisation',
          received: true,
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrPreview((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const renderIntro = () => {
    return (
      <div className="intro_view" key="shared_link_intro">
        <div className="mark_view">
          <LinkIcon opacity={1} />
        </div>
        <div
          className="font-bold font24"
          style={{ marginTop: 8 }}
        >{`Shared Links\nwithin Schools`}</div>
        <div
          className="font-regular font14"
          style={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: 8 }}
        >
          {`Discover any helpful links\nfrom other members`}
        </div>
        <div
          className="font-bold font14 clickable"
          style={{ color: '#FF3E9A', marginTop: 8 }}
          onClick={onShowAll}
        >
          Show All
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="row_align_items" style={{ width: '100%' }}>
        {renderIntro()}
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onWheel={onWheel}
        >
          {arrPreview.map((item, index) => {
            return (
              <CardSharedLink key={`card_shared_link_${index}`} data={item} />
            );
          })}
        </ScrollMenu>
      </div>
    );
  };

  if (selectedOrg && arrPreview.length > 0) {
    return (
      <div className="shared_link_container">
        <div className="shared_link">{renderBody()}</div>
      </div>
    );
  } else {
    return <div />;
  }
};
