import React, { useEffect, useMemo, useState } from 'react';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import { getArrFromString } from 'shared/utils/utils';
import { Reference } from 'components/Common/Reference';
import { ReferenceDetail } from 'components/Common/ReferenceDetail';

import { apiQAUnBookMark } from 'shared/api/qa';
import LocationArrowIcon from 'assets/svgs/LocationArrowIcon';

import './index.styles.scss';
import BookMarkedIcon from 'assets/svgs/BookMarkedIcon';

import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from 'shared/utils/constants';
import { SendDumpModal } from 'components/Modals/SendDumpModal';
import TrashIcon from 'assets/svgs/TrashIcon';
import { apiDeletionBookmark } from 'shared/api/delete';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { showToast } from 'shared/ui-kit/PrimaryToast';

export interface QuestionBookmarkData {
  id: number;
  user: number;
  question: string;
  to: number;
  to_type: string;
  ai_answer: string;
  context: string;
  timestamp: string;
  ref_names?: string;
  ref_images?: string;
  ref_filenames?: string;
  creator_id: string;
  creator_image: string;
  creator_name: string;
  ref_urls?: string;
  ref_types?: string;
  ref_texts?: string;
  ref_titles?: string;
  ref_count?: string;
  ref_ids: number;
  ref_users?: string;
  is_suggested: number;
  bookmark_id: number;
}

interface QuestionBookmarkProps {
  data: QuestionBookmarkData;
  setCurrentQuestion: any;
  setIsSendToOrg: any;
  itemId: any;
  itemType: any;
  onShareMedia: (data: QuestionBookmarkData) => void;
  onRemoveOnUI: (id) => void;
  onRefresh: () => void;
}

export const CardBookmarked = ({
  data,
  itemId,
  itemType,
  onRefresh,
  setCurrentQuestion,
  setIsSendToOrg,
  onShareMedia,
  onRemoveOnUI
}: QuestionBookmarkProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [answer, setAnswer] = useState(data.ai_answer);
  const [isExpandReference, setIsExpandReference] = useState(false);

  const [isBooking, setIsBooking] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const [isExpandAnswer, setIsExpandAnswer] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  useEffect(() => {
    setAnswer(data.ai_answer);
  }, [data]);

  const onBookMark = async () => {
    if (!isBooking) {
      setIsBooking(true);

      const res = await apiQAUnBookMark({
        bookmark: data.bookmark_id,
        item_id: itemId,
        item_type: itemType
      });

      if (res.success) {
        onRemoveOnUI(data.bookmark_id);
      }

      setIsBooking(false);
    }
  };

  const onRemove = async () => {
    if (!isRemoving) {
      setIsRemoving(true);

      const res = await apiDeletionBookmark({
        bookmark: data.bookmark_id
      });

      if (res.success) {
        showToast('success', 'Deletion successful!');
        onRemoveOnUI(data.bookmark_id);
      }

      setIsRemoving(false);
    }
  };

  const arrReferencesForBelow = useMemo(() => {
    if (data.ref_names || data.ref_types) {
      const arrTmpRefData: Array<any> = [];

      // for user
      const arrTmpName = getArrFromString(data.ref_names, ';;;');
      const arrTmpImage = getArrFromString(data.ref_images, ';;;');

      // for files
      const arrTmpFileName = getArrFromString(
        data?.ref_titles ?? data?.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(data.ref_urls, ';;;');
      const arrTmpTxt = getArrFromString(data.ref_texts ?? data.context, ';;;');
      const arrTmpFileType = getArrFromString(data.ref_types, ';;;');

      if (arrTmpTxt.length > 0) {
        arrTmpTxt.map((item, index) => {
          arrTmpRefData.push({
            name: arrTmpName[index],
            fileName: arrTmpFileName[index],
            image: arrTmpImage[index],
            url: arrTmpFileUrl[index],
            text: arrTmpTxt[index],
            fileType: arrTmpFileType[index]
          });
        });
      }

      return arrTmpRefData;
    } else {
      return [];
    }
  }, [data]);

  const arrReferences = useMemo(() => {
    if (data.ref_names || data.ref_types) {
      const arrTmpRefData: Array<any> = [];

      // for user
      const arrTmpUserId = getArrFromString(data.ref_users, ';;;');
      const arrTmpName = getArrFromString(data.ref_names, ';;;');
      const arrTmpImage = getArrFromString(data.ref_images, ';;;');

      // for files
      const arrTmpFileId = getArrFromString(data.ref_ids, ';;;');
      const arrTmpFileName = getArrFromString(
        data?.ref_titles ?? data?.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(data.ref_urls, ';;;');
      const arrTmpTxt = getArrFromString(data.ref_texts, ';;;');
      const arrTmpFileType = getArrFromString(data.ref_types, ';;;');

      arrTmpName.map((item, index) => {
        arrTmpRefData.push({
          id: arrTmpUserId[index],
          name: arrTmpName[index],
          image: arrTmpImage[index],
          text: arrTmpTxt[index],
          type: 'user'
        });
      });

      arrTmpFileName.map((item, index) => {
        arrTmpRefData.push({
          id: arrTmpFileId[index],
          name: arrTmpFileName[index],
          image: arrTmpFileUrl[index],
          text: arrTmpTxt[index],
          type: 'file',
          fileType: arrTmpFileType[index]
        });
      });

      if (!data.ref_filenames) {
        arrTmpFileUrl.map((item, index) => {
          arrTmpRefData.push({
            id: arrTmpFileId[index],
            name: arrTmpFileUrl[index],
            image: arrTmpFileUrl[index],
            text: arrTmpTxt[index],
            type: 'file',
            fileType: arrTmpFileType[index]
          });
        });
      }

      // remove duplicated
      const uniqueArr = arrTmpRefData.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.image === value.image || t.name === value.name
          )
      );
      return uniqueArr;
    } else {
      return [];
    }
  }, [data]);

  const renderReferences = () => {
    return (
      <Reference
        data={arrReferences}
        showAddButton={false}
        enableVisit={true}
        setIsExpandReference={() => setIsExpandReference((prev) => !prev)}
        isExpandReference={isExpandReference}
      />
    );
  };

  const renderNormalAnswer = () => {
    return (
      <>
        <div className="question_bookmark">
          <div className="question_bookmark_top">
            <div className="question_bookmark_top_title">
              <div className="font-medium font26">{data.question}</div>
            </div>
            <div
              className="row_align_items"
              style={{ display: 'flex', alignItems: 'center', gap: 8 }}
            >
              <div className="book_marked_icon clickable" onClick={onBookMark}>
                {isBooking ? (
                  <LoadingCircular size={24} color="main" />
                ) : (
                  <BookMarkedIcon />
                )}
              </div>
              {userInfo?.id == data.user && (
                <div className="book_marked_icon clickable" onClick={onRemove}>
                  {isRemoving ? (
                    <LoadingCircular size={24} color="main" />
                  ) : (
                    <TrashIcon />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="answer_ai">
            {!isMobile ? (
              <div
                className="font-regular font18"
                style={{ marginTop: 8, lineHeight: `32px` }}
              >
                <pre className="font18">{answer}</pre>
              </div>
            ) : isExpandAnswer ? (
              data.ai_answer && (
                <div
                  className="font-regular font18"
                  style={{ marginTop: 8, lineHeight: `32px` }}
                >
                  <pre className="font18">{answer}</pre>{' '}
                  <span
                    onClick={() => setIsExpandAnswer(false)}
                    style={{ color: '#ff3e9a' }}
                  >
                    Less
                  </span>
                </div>
              )
            ) : (
              <div
                className="font-regular font18"
                style={{ marginTop: 8, lineHeight: `32px` }}
              >
                {answer.slice(0, 40)}...{' '}
                {answer.length > 40 && (
                  <span
                    onClick={() => setIsExpandAnswer(true)}
                    style={{ color: '#ff3e9a' }}
                  >
                    Read More
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="bottom_bar row_space_between">
            {arrReferences.length > 0 ? renderReferences() : <div></div>}
            <div
              className="share_btn clickable"
              onClick={() => setShowSendModal(true)}
            >
              <LocationArrowIcon color={'white'} />
            </div>
          </div>
          {isExpandReference && (
            <ReferenceDetail data={arrReferencesForBelow} />
          )}
        </div>
      </>
    );
  };

  const renderSendModal = () => {
    return (
      <SendDumpModal
        bookmarkId={data.bookmark_id}
        item_id={data.id}
        item_type={itemType}
        show={showSendModal}
        type="answer"
        onClose={() => setShowSendModal(false)}
        onSuccess={(groupData) => {}}
      />
    );
  };

  return (
    <div className="date_bookmark">
      {renderNormalAnswer()}
      {renderSendModal()}
    </div>
  );
};
