import React from 'react';

function UnlockIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 16}
      height={props.size ?? 16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g opacity="0.3">
        <path
          d="M11.3337 6.0019H6.00033V4.66857C5.99935 4.27269 6.11589 3.88543 6.33516 3.55584C6.55444 3.22624 6.8666 2.96913 7.23211 2.81707C7.59762 2.66502 8.00003 2.62485 8.38839 2.70166C8.77674 2.77848 9.13356 2.96881 9.41366 3.24857C9.66429 3.50461 9.84356 3.82178 9.93366 4.16857C9.95554 4.25349 9.99394 4.33327 10.0467 4.40335C10.0994 4.47343 10.1654 4.53244 10.2409 4.57701C10.3164 4.62159 10.4 4.65085 10.4868 4.66312C10.5737 4.6754 10.6621 4.67045 10.747 4.64857C10.8319 4.62668 10.9117 4.58828 10.9818 4.53556C11.0519 4.48284 11.1109 4.41684 11.1554 4.34131C11.2 4.26579 11.2293 4.18222 11.2415 4.09539C11.2538 4.00856 11.2489 3.92015 11.227 3.83523C11.0751 3.25843 10.7739 2.73186 10.3537 2.30857C9.88711 1.84348 9.29322 1.52706 8.64696 1.39926C8.00071 1.27147 7.33108 1.33803 6.72263 1.59054C6.11418 1.84305 5.59419 2.27019 5.22833 2.81802C4.86247 3.36584 4.66713 4.0098 4.66699 4.66857V6.0019C4.13656 6.0019 3.62785 6.21261 3.25278 6.58769C2.87771 6.96276 2.66699 7.47147 2.66699 8.0019V12.6686C2.66699 13.199 2.87771 13.7077 3.25278 14.0828C3.62785 14.4579 4.13656 14.6686 4.66699 14.6686H11.3337C11.8641 14.6686 12.3728 14.4579 12.7479 14.0828C13.1229 13.7077 13.3337 13.199 13.3337 12.6686V8.0019C13.3337 7.47147 13.1229 6.96276 12.7479 6.58769C12.3728 6.21261 11.8641 6.0019 11.3337 6.0019ZM12.0003 12.6686C12.0003 12.8454 11.9301 13.0149 11.8051 13.14C11.68 13.265 11.5105 13.3352 11.3337 13.3352H4.66699C4.49018 13.3352 4.32061 13.265 4.19559 13.14C4.07056 13.0149 4.00033 12.8454 4.00033 12.6686V8.0019C4.00033 7.82509 4.07056 7.65552 4.19559 7.5305C4.32061 7.40547 4.49018 7.33523 4.66699 7.33523H11.3337C11.5105 7.33523 11.68 7.40547 11.8051 7.5305C11.9301 7.65552 12.0003 7.82509 12.0003 8.0019V12.6686Z"
          fill={props.color ?? "black"}
        />
      </g>
    </svg>
  );
}

export default UnlockIcon;
