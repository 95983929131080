import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import EditPencilIcon from 'assets/svgs/EditPencilIcon';
import FileIcon from 'assets/svgs/FileIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import MicIcon from 'assets/svgs/MicIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import UserCheckIcon from 'assets/svgs/UserCheckIcon';
import { NewAddReferenceModal } from 'components/Modals/AddReferenceModal/NewAddReferenceModal';
import { OrgAddAnswerReferenceModal } from 'components/Modals/AddReferenceModal/OrgAddAnswerReferenceModal';
import React, { useEffect, useState } from 'react';
import { apiGetQAAsk } from 'shared/api/qa';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';
import { TemplateAnswer } from './TemplateAnswer';
interface RigthPanelProps {
  currentAnswer: string;
  setIsEditAnswer: any;
  selectedUserRefs: any | undefined;
  selectedFileRefs: Array<any>;
  questionDetail: any;
  onUpdatedUserRef: (newRef) => void;
  onUpdatedFileRef: (newRef) => void;
  onUpdateCurrentAnswer: (newAnswer) => void;
}

export const RigthPanel = ({
  currentAnswer,
  setIsEditAnswer,
  selectedUserRefs,
  selectedFileRefs,
  questionDetail,
  onUpdatedUserRef,
  onUpdatedFileRef,
  onUpdateCurrentAnswer
}: RigthPanelProps) => {
  const [tab, setTab] = useState<'file' | 'user'>('user');
  const [showAddDlg, setShowAddDlg] = useState(false);
  const [addDlgPos, setAddDlgPos] = useState({ top: 0, left: 0 });
  const [referencedSentences, setReferencedSentences] = useState('');
  const [
    isLoadingReferencedSentences,
    setIsLoadingReferencedSentences
  ] = useState(false);

  useEffect(() => {
    getReferencedSentences();
  }, [selectedUserRefs, selectedFileRefs]);

  const onShowAddDlg = (e) => {
    setShowAddDlg(true);

    const clientRectangle = document!
      .getElementById('add_btn_ref')!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setAddDlgPos({
      top:
        bottomFromWindow < 400
          ? clientRectangle.top - 400
          : clientRectangle.top,
      left: clientRectangle.left - 350
    });
  };

  const getReferencedSentences = async () => {
    const arrFileRefId = selectedFileRefs.map((item: any, index) => item.id);
    const arrUserRefId = selectedUserRefs.map(
      (item: any, index) => item.creator_id
    );

    setIsLoadingReferencedSentences(true);

    const res = await apiGetQAAsk({
      question: questionDetail.question,
      to: 30,
      to_type: 'organisation',
      dumpString: arrFileRefId.length > 0 ? arrFileRefId + '' : undefined,
      users: arrUserRefId.length > 0 ? arrUserRefId + '' : ''
    });

    if (res.success) {
      setReferencedSentences(res.data.ai_answer);
      setIsLoadingReferencedSentences(false);
    }
  };

  const renderEmptyReferenceBar = () => {
    return (
      <div className="empty_reference_bar">
        <div className="icon_circle"></div>
      </div>
    );
  };

  const renderAddDlg = () => {
    return (
      <OrgAddAnswerReferenceModal
        show={showAddDlg}
        itemId={5}
        itemType={'organisation'}
        showType={tab === 'file' ? 'dump' : 'reference'}
        posX={addDlgPos.left}
        posY={addDlgPos.top}
        onClose={() => setShowAddDlg(false)}
        onDone={(userRef, fileRef) => {
          setShowAddDlg(false);

          if (tab === 'file') {
            onUpdatedFileRef(fileRef);
          } else {
            onUpdatedUserRef(userRef);
          }
        }}
      />
    );
  };

  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 28, height: 28 }}>
        {type === 'pdf' ? (
          <PdfFileIcon size={28} />
        ) : type === 'audio' ? (
          <MicIcon size={24} color="#FF3E9A" opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={28} color="#FF3E9A" opacity={1} />
        ) : (
          <FileIcon />
        )}
      </div>
    );
  };

  const renderSelectedReferenceBar = () => {
    if (tab === 'file') {
      return (
        <div className="row_align_items">
          {selectedFileRefs.map((item, index) => {
            return (
              <div
                className="selected_reference_bar"
                key={'selected_reference_bar_' + index}
              >
                <div className="reference_item1">
                  {renderFileIcon(item.dump_type)}
                  <div
                    className="user_name font-regular font14"
                    style={{
                      maxWidth: 100,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {item.filename}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="row_align_items">
          {selectedUserRefs &&
            selectedUserRefs.map((item, index) => {
              return (
                <div
                  className="selected_reference_bar"
                  key={'selected_reference_bar_' + index}
                >
                  <div className="reference_item1">
                    <img src={item.creator_image} className="user_avatar" />
                    <div className="user_name font14">{item.creator_name}</div>
                  </div>
                </div>
              );
            })}
        </div>
      );
    }
  };

  const renderReferenceBar = () => {
    return (
      <div className="refernce_bar">
        {tab === 'user'
          ? selectedUserRefs.length == 0
            ? renderEmptyReferenceBar()
            : renderSelectedReferenceBar()
          : selectedFileRefs.length == 0
          ? renderEmptyReferenceBar()
          : renderSelectedReferenceBar()}
        <div
          className="reference_select_btn"
          id="add_btn_ref"
          onClick={onShowAddDlg}
        >
          <EditPencilColoredIcon />
        </div>
        {renderAddDlg()}
      </div>
    );
  };

  const renderTemplateAnswerBoxes = () => {
    if (selectedUserRefs)
      return (
        <div className="template_answers">
          <TemplateAnswer
            text={referencedSentences}
            key={`template_answer_1`}
            setIsEditAnswer={setIsEditAnswer}
            isSelected={referencedSentences === currentAnswer}
            onSelected={(answer) => {
              onUpdateCurrentAnswer(answer);
            }}
          />
        </div>
      );
    else return renderEmptyTemplateAnswerBox();
  };

  const renderEmptyTemplateAnswerBox = () => {
    return (
      <div className="empty_template_box">
        <div className="light_bulb">💡️</div>
        <div className="message_box">
          <div className="title">No recommendation yet</div>
          <div className="message">
            Add references to start showing recommendation
          </div>
        </div>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className="right_panel_tabs">
        <div
          className={`${
            tab === 'file' ? 'selected_tab' : 'normal_tab'
          } clickable`}
          onClick={() => setTab('file')}
        >
          <EditPencilIcon />
          <div className="dump_entry_text font-bold font14">Dump Entry</div>
        </div>
        <div
          className={`${
            tab === 'user' ? 'selected_tab' : 'normal_tab'
          } clickable`}
          onClick={() => setTab('user')}
        >
          <UserCheckIcon size={20} />
          <div className="references_text font-bold font14">References</div>
        </div>
      </div>
    );
  };

  return (
    <div className="add_answer_right_panel">
      <div className="answer_recommendation font-bold font20">
        Answer Recommendation
      </div>
      {renderTab()}
      {renderReferenceBar()}
      {isLoadingReferencedSentences ? (
        <div className="loading-circular">
          <LoadingCircular size={24} color="main" />
        </div>
      ) : (
        renderTemplateAnswerBoxes()
      )}
    </div>
  );
};
