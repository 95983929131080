import React, { useEffect, useMemo, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Switch from 'react-switch';

import { QuestionAskBar } from '../components/QuestionAskBar';

import './index.styles.scss';
import { TrendingQuestion } from './components/TrendingQuestion';
import { useNavigate } from 'react-router-dom';
import { TopDidYouKnow } from './components/TopDidYouKnow';
import UserCheckIcon from 'assets/svgs/UserCheckIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import FileIcon from 'assets/svgs/FileIcon';
import { NewAddReferenceModal } from 'components/Modals/AddReferenceModal/NewAddReferenceModal';
import { OrgAsked } from '../components/Asked';
import { apiGetQAAsk, apiGetQAAskGeneral } from 'shared/api/qa';
import CommentQuestionIcon from 'assets/svgs/CommentQuestionIcon';
import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import { QuestionFilterActionModal } from 'components/Modals/QuestionFilterModal';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface Props {
  question?: string;
  selectedOrg?: any;
  selectedTopics?: any;
  arrOrganisation?: Array<any>;

  onSetQuestion?: (question) => void;
  onInitSelectedOrg?: () => void;
}

export const OrgHome = ({
  question,
  selectedOrg,
  selectedTopics,
  arrOrganisation,
  onSetQuestion,
  onInitSelectedOrg
}: Props) => {
  const navigate = useNavigate();

  const [isAsking, setIsAsking] = useState(false);
  const [qaAskInfo, setQaAskInfo] = useState<{ [key: string]: any } | null>(
    null
  );
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [isFocusedSearch, setIsFocusedSearch] = useState(false);
  const [showAskedView, setShowAskedView] = useState(false);

  const [isWithDoc, setIsWithDoc] = useState(false);
  const [isTrendingFilter, setIsTrendingFilter] = useState(true);
  const [isUnAnsweredFilter, setIsUnAnsweredFilter] = useState(false);

  // add reference
  const [selectedRef, setSelectedRef] = useState<Array<any>>([]);
  const [showAddDlg, setShowAddDlg] = useState(false);
  const [addDlgPos, setAddDlgPos] = useState({ top: 0, left: 0 });

  const [showActionModal, setShowActionModal] = useState(false);
  const [actionModalPos, setActionModalPos] = useState({ top: 0, left: 0 });

  useEffect(() => {
    onInitSelectedOrg && onInitSelectedOrg();
  }, []);

  const loadQuestionData = async (question, orgId = null) => {
    if (question) {
      setIsAsking(true);
      const res = await apiGetQAAskGeneral({
        question
      });

      if (res.success) {
        setQaAskInfo(res);
      } else {
        setQaAskInfo(null);
      }
      setIsAsking(false);
    }
  };

  const onSearch = (question) => {
    setShowAskedView(true);
    setIsFocusedSearch(false);

    if (arrOrganisation?.length == 0) {
      showToast('error', 'You do not belong to any school yet');
    } else {
      console.log('--- set question ---', question);
      onSetQuestion && onSetQuestion(question);
      loadQuestionData(question);
    }
  };

  const onFocusSearch = () => {
    setIsFocusedSearch(true);
  };

  const onLeaveSearch = () => {
    // setIsFocusedSearch(false);
  };

  const onShowFilterModal = (e) => {
    e.stopPropagation();
    setShowActionModal(true);

    const clientRectangle = document!
      .getElementById(`question_filter_btn`)!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setActionModalPos({
      top: clientRectangle.top + 30,
      left: clientRectangle.left - 200
    });
  };

  const onShowAddRefDlg = (e) => {
    setShowAddDlg(true);

    const clientRectangle = document!
      .getElementById('org_answer_add_btn_ref')!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setAddDlgPos({
      top:
        bottomFromWindow < 400
          ? clientRectangle.top - 400
          : clientRectangle.top,
      left: clientRectangle.left
    });
  };

  const onDoneAddReference = (userRef, fileRef) => {
    setSelectedRef(userRef);
  };

  const arrReferences = useMemo(() => {
    const arrTmpRefData: Array<any> = [];

    selectedRef.map((item, index) => {
      arrTmpRefData.push({
        id: selectedRef[index].creator_id,
        name: selectedRef[index].creator_name,
        image: selectedRef[index].creator_image,
        type: 'user'
      });
    });
    return arrTmpRefData;
  }, [selectedRef]);

  const renderDidKnow = () => {
    return <TopDidYouKnow arrOrganisation={arrOrganisation} />;
  };

  const renderAskBar = () => {
    return (
      <div>
        <div className="row_align_items">
          <QuestionAskBar
            onSearch={onSearch}
            onFocus={onFocusSearch}
            onBlur={onLeaveSearch}
            width="100%"
            height="64"
            backgroundColor="#F1F6F9"
            placeholder="Ask new question here..."
            fontSize={20}
            showButton={isFocusedSearch}
            searchIconClickable={true}
          />
          {!isFocusedSearch && (
            <div
              className="filter_btn row_align_items clickable"
              id="question_filter_btn"
              onClick={onShowFilterModal}
            >
              <div
                className="font-bold font16"
                style={{ whiteSpace: 'nowrap' }}
              >
                {isTrendingFilter ? 'Most Popular' : 'Latest Posts'}
              </div>
              <div style={{ marginLeft: 6 }}>
                <AngleDownIcon color="#FF3E9A" />
              </div>
            </div>
          )}
          {!isFocusedSearch && (
            <div
              className={`${
                isUnAnsweredFilter
                  ? 'selected_unanswer_btn'
                  : 'default_unanswer_btn'
              } clickable`}
              onClick={() => setIsUnAnsweredFilter((prev) => !prev)}
            >
              <CommentQuestionIcon
                color={isUnAnsweredFilter ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)'}
              />
            </div>
          )}
        </div>
        {isFocusedSearch && (
          <div style={{ marginTop: 32 }}>{renderReference()}</div>
        )}
      </div>
    );
  };

  const renderReference = () => {
    return (
      <div className="org_reference">
        <div className="org_reference_left">
          <UserCheckIcon size={24} />
          {arrReferences.length > 0 ? (
            <div className="font-medium font14" style={{ marginLeft: 8 }}>
              References
            </div>
          ) : (
            <div
              className="font-medium font14"
              style={{ marginLeft: 8, flex: 1 }}
            >
              No references set (optional). Showing insights from all members
            </div>
          )}
          {arrReferences.length > 0 && renderReferenceItems()}

          <Tooltip
            title={
              <div className="font-regular font14">
                Set a reference and narrow down your results by filtering for
                specific creators or document uploads
              </div>
            }
          >
            <div
              className="round_btn clickable"
              id={'org_answer_add_btn_ref'}
              onClick={onShowAddRefDlg}
            >
              <PlusIcon size={16} />
            </div>
          </Tooltip>
        </div>
        <div className="org_reference_right">
          <FileIcon />
          <div
            className="font-medium font16"
            style={{ marginLeft: 8, flex: 1 }}
          >
            With Docs Only
          </div>
          <Switch
            onChange={() => {
              setIsWithDoc((prev) => !prev);
            }}
            checked={isWithDoc}
            checkedIcon={<div />}
            uncheckedIcon={<div />}
            offColor={'#EAEBEC'}
            onColor={'#FF3E9A'}
            activeBoxShadow={undefined}
            handleDiameter={18}
            width={44}
            height={24}
          />
        </div>
      </div>
    );
  };

  const renderReferenceItems = () => {
    return (
      <div className="org_answer_reference_list">
        {arrReferences.map((item, index) => {
          return (
            <div
              className="reference_item"
              key={`reference_item_${index}`}
              style={{ height: 40 }}
            >
              {item.image && item.image.startsWith('https') ? (
                <img
                  src={item.image}
                  style={{ width: 24, height: 24, borderRadius: 24 }}
                />
              ) : (
                <DefaultAvatarIcon size={24} />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTrendingQuestion = () => {
    return (
      <TrendingQuestion
        selectedOrg={selectedOrg}
        selectedTopic={selectedTopics}
        arrOrganisation={arrOrganisation}
        isUnAnsweredFilter={isUnAnsweredFilter}
        isTrendingFilter={isTrendingFilter}
        onPostQuestion={() => setIsFocusedSearch(true)}
        onShowAllSharedDoc={() => {
          navigate(`/school/${selectedOrg.id}/docs`);
        }}
        onShowAllSharedLink={() => {
          navigate(`/school/${selectedOrg.id}/links`);
        }}
        onShowAllSharedMeme={() => {
          navigate(`/school/${selectedOrg.id}/memes`);
        }}
        onShowAllSharedFlash={() => {
          navigate(`/school/${selectedOrg.id}/flashcards`);
        }}
      />
    );
  };

  const renderAddRefDlg = () => {
    return (
      <NewAddReferenceModal
        show={showAddDlg}
        userRefs={selectedRef}
        itemId={selectedOrgId ?? 30}
        itemType={'organisation'}
        posX={addDlgPos.left}
        posY={addDlgPos.top}
        onlyCreator={true}
        onClose={() => setShowAddDlg(false)}
        onDone={(userRef, fileRef) => {
          setShowAddDlg(false);
          onDoneAddReference(userRef, fileRef);
        }}
      />
    );
  };

  const renderAskedBody = () => {
    return (
      <OrgAsked
        question={question}
        isAsking={isAsking}
        qaAskInfo={qaAskInfo}
        selectedOrg={selectedOrg}
        arrOrganisation={arrOrganisation ?? []}
        onSetQuestion={(newQuestion) => {
          onSetQuestion && onSetQuestion(newQuestion);
        }}
        onLoadQuestionData={(newQuestion) => loadQuestionData(newQuestion)}
        onReloadQuestion={(newOrgId) => {
          loadQuestionData(question, newOrgId);
        }}
        onBack={() => setShowAskedView(false)}
      />
    );
  };

  const renderActionModal = () => {
    return (
      <QuestionFilterActionModal
        show={showActionModal}
        posX={actionModalPos.left}
        posY={actionModalPos.top}
        onClose={() => {
          setShowActionModal(false);
        }}
        onSelected={(action) => {
          setShowActionModal(false);
          if (action === 'most') {
            setIsTrendingFilter(true);
          } else {
            setIsTrendingFilter(false);
          }
        }}
      />
    );
  };

  return (
    <div className="org_home">
      {!isFocusedSearch && !showAskedView && renderDidKnow()}
      <div className="org_home_body">
        {renderAskBar()}
        {!isFocusedSearch && !showAskedView && renderTrendingQuestion()}
        {!isFocusedSearch && showAskedView && renderAskedBody()}
      </div>
      {renderAddRefDlg()}
      {renderActionModal()}
    </div>
  );
};
