import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiCreateQADump } from 'shared/api/qa';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { showToast } from 'shared/ui-kit/PrimaryToast';

import './index.styles.scss';

export const WriteNewDoc = () => {
  const params = useParams();
  const navigate = useNavigate();

  const draftType = params.type;
  const groupId = params.id ? parseInt(params.id) : null;

  const [isCreating, setIsCreating] = useState(false);
  const [text, setText] = useState('');

  const onBack = () => {
    navigate(-1);
  };

  const onSaveDoc = async () => {
    if (text.length > 200) {
      setIsCreating(true);
      if (text !== '') {
        const res = await apiCreateQADump({
          url: text,
          type: 'text',
          to: draftType === 'user' ? undefined : groupId!,
          to_type: draftType === 'user' ? undefined : 'group',
          interests: undefined
        });

        if (res.success) {
          onBack();
        }
      }
      setIsCreating(false);
    } else {
      showToast('error', 'Notes need to be longer than 200 characters');
    }
  };

  const renderTop = () => {
    return (
      <div className="top row_space_between row_align_items">
        <div className="row_align_items">
          <div className="clickable" onClick={onBack}>
            <AngleLeftIcon color="#FF3E9A" />
          </div>
          <div className="font-bold font26" style={{ marginLeft: 8 }}>
            Write New Doc
          </div>
        </div>
        <div className="row_align_items">
          <div className="cancel_btn clickable" onClick={onBack}>
            <div className="font-bold font16">Cancel</div>
          </div>
          <div className="save_btn clickable" onClick={onSaveDoc}>
            {isCreating ? (
              <LoadingCircular color="white" size={24} />
            ) : (
              <div className="font-bold font16" style={{ color: 'white' }}>
                Save Doc
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="body">
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="font-regular font24 text_input"
        />
      </div>
    );
  };

  return (
    <div className="write_doc">
      {renderTop()}
      {renderBody()}
    </div>
  );
};
