import React, { useEffect, useState } from 'react';
import { TabBarItem } from './TabBarItem';

import './TabBar.styles.scss';
import { useLocation } from 'react-router-dom';

export const TabBar = () => {
  const location = useLocation();
  const [tab, setTab] = useState<
    'home' | 'bookmark' | 'group' | 'org' | 'other'
  >('home');

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/question') {
      setTab('home');
    } else if (location.pathname.includes('/group')) {
      setTab('group');
    } else if (location.pathname.includes('/school')) {
      setTab('org');
    } else {
      setTab('other');
    }
  }, [location]);

  return (
    <div className="main_tab">
      <TabBarItem
        type="home"
        isSelected={tab === 'home'}
        hasGap={true}
        onPressed={() => setTab('home')}
      />
      <TabBarItem
        type="group"
        isSelected={tab === 'group'}
        hasGap={true}
        onPressed={() => setTab('group')}
      />
      <TabBarItem
        type="org"
        isSelected={tab === 'org'}
        hasGap={false}
        onPressed={() => setTab('org')}
      />
    </div>
  );
};
