import React from 'react';

import './index.styles.scss';

export interface TagType {
  item_id: number;
  item_type: 'interest' | 'organisation' | 'group' | 'user_tag';
  name: string;
  image: string;
}

interface TagProps {
  isSelected: boolean;
  isEditMode: boolean;
  data: TagType;
  onClicked?: () => void;
}

export const LinkPreiveTag = ({
  isSelected,
  isEditMode,
  onClicked,
  data
}: TagProps) => {
  const markSelectedColor =
    data.item_type === 'interest'
      ? '#2F9BFF'
      : data.item_type === 'group'
      ? '#2BBC3A'
      : data.item_type === 'user_tag'
      ? '#2F9BFF'
      : '#8D1516';
  const bgSelectedColor =
    data.item_type === 'interest'
      ? '#CCE7FF'
      : data.item_type === 'group'
      ? '#CEFDEF'
      : data.item_type === 'user_tag'
      ? '#E0F0FF'
      : '#F8D3D3';

  const markColor =
    !isSelected && isEditMode ? 'rgba(0, 0, 0, 0.3)' : markSelectedColor;
  const bgColor =
    !isSelected && isEditMode ? 'rgba(0, 0, 0, 0.1)' : bgSelectedColor;
  const tagColor = !isSelected && isEditMode ? 'rgba(0, 0, 0, 0.6)' : '#000000';

  return (
    <div
      className="link_preview_tag_view clickable"
      style={{ backgroundColor: bgColor, borderColor: 'rgba(0, 0, 0, 0.1)' }}
      onClick={() => onClicked && onClicked()}
    >
      <div
        className="tag_view_marker"
        style={{ backgroundColor: markColor }}
      ></div>
      {data.item_type === 'user_tag' ? (
        <div
          className="font-medium font12"
          style={{ color: markColor, marginLeft: 8 }}
        >
          #
        </div>
      ) : (
        data.image && <img src={data.image} className="tag_view_image" />
      )}

      <div
        className="tag_view_text font-medium font14"
        style={{ color: tagColor }}
      >
        {data.name}
      </div>
    </div>
  );
};
