import NotificationIcon from 'assets/svgs/NotificationIcon';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Columns from 'react-columns';
import moment from 'moment';

import './index.styles.scss';
import appAction from 'redux/app/action';
import {
  apiEventConfirmRSVP,
  apiEventDetail,
  apiEventRsvp,
  apiEventRemoveRSVP
} from 'shared/api/event';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import { ShareEventModal } from 'components/Modals/ShareEventModal';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiDeletionEvent } from 'shared/api/delete';
import TrashIcon from 'assets/svgs/TrashIcon';

export const EventDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const eventCode = params.code;

  const [showShare, setShowShare] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [eventDetail, setEventDetail] = useState<any>(null);

  const [arrRsvp, setArrRsvp] = useState<Array<any>>([]);
  const [totalRsvp, setTotalRsvp] = useState(0);

  const [isRsvp, setIsRsvp] = useState(false);
  const [isRsvping, setIsRsvping] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    loadDetail();
    loadRsvp();
  }, [eventCode]);

  const loadDetail = async () => {
    const res = await apiEventDetail({ code: eventCode });
    if (res.success) {
      setIsLoaded(true);
      setEventDetail(res.data);
      setIsRsvp(res.data.is_rsvp === 1);
    }
  };

  const loadRsvp = async () => {
    const res = await apiEventRsvp({ code: eventCode });

    if (res.success) {
      setArrRsvp(res.data);
      setTotalRsvp(res.rsvps);
    }
  };

  const onRsvp = async () => {
    if (isRsvp) return;

    setIsRsvping(true);

    const res = await apiEventConfirmRSVP({ event: eventDetail.id });

    if (res.success) {
      setIsRsvp(true);
      setArrRsvp((prev) => {
        return [
          ...prev,
          {
            creator_id: userInfo?.id,
            creator_image: userInfo?.image,
            creator_name: userInfo?.name
          }
        ];
      });
      setTotalRsvp((prev) => prev + 1);
      dispatch(appAction.refreshEventData());
    }

    setIsRsvping(false);
  };

  const onRemoveRsvp = async () => {
    setIsRsvping(true);

    const res = await apiEventRemoveRSVP({ event: eventDetail.id });

    if (res.success) {
      setIsRsvp(false);
      dispatch(appAction.refreshEventData());

      setArrRsvp((prev) =>
        prev.filter((item, _) => {
          if (item.creator_id === userInfo?.id) {
            return false;
          } else {
            return true;
          }
        })
      );

      setTotalRsvp(totalRsvp - 1);
    }

    setIsRsvping(false);
  };

  const onDeleteEvent = async (e) => {
    e && e.stopPropagation();
    setIsRemoving(true);
    const res = await apiDeletionEvent({ event: eventDetail.id });

    setIsRemoving(false);
    if (res.success) {
      dispatch(appAction.refreshEventData());
      navigate(-1);
    }
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const onViewProfile = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items">
        <div className="clickable" onClick={onClickBack}>
          <RoundBackIcon color="#FF3E9A" />
        </div>
        <div className="font-bold font26" style={{ marginLeft: 8 }}>
          Event Detail
        </div>
      </div>
    );
  };

  const renderTop = () => {
    return (
      <div className="event_top">
        {eventDetail.image && eventDetail.image.startsWith('https') && (
          <img
            src={eventDetail.image}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              borderRadius: 24
            }}
          />
        )}
        <div className="event_top_content">
          <div className="row_space_between">
            <div />
            <div className="row_align_items" style={{ gap: 8 }}>
              <div
                className="share_container clickable"
                onClick={() => setShowShare(true)}
              >
                <ShareIcon color="#FF3E9A" />
              </div>
              {eventDetail.creator === userInfo?.id && (
                <div
                  className="share_container clickable"
                  onClick={(e) => onDeleteEvent(e)}
                >
                  {isRemoving ? (
                    <LoadingCircular size={24} color="main" />
                  ) : (
                    <TrashIcon color="#FF3E9A" />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="event_content_body">
            <div className="event_detail_users row_align_items">
              {eventDetail.creator_image &&
              eventDetail.creator_image.startsWith('https') ? (
                <img
                  src={eventDetail.creator_image}
                  className="event_user_image"
                />
              ) : (
                <DefaultAvatarIcon size={36} />
              )}
              <div style={{ marginLeft: -6 }}>
                {eventDetail.org_image &&
                eventDetail.org_image.startsWith('https') ? (
                  <img
                    src={eventDetail.org_image}
                    className="event_user_image"
                  />
                ) : (
                  <DefaultAvatarIcon size={36} />
                )}
              </div>

              <div className="font-medium font15" style={{ marginLeft: 8 }}>
                {eventDetail.creator_name}{' '}
                <span style={{ opacity: 0.4 }}>in</span> {eventDetail.org_name}
              </div>
            </div>
            <div
              className="font-bold font48"
              style={{ color: 'white', fontSize: 48, marginTop: 24 }}
            >
              {eventDetail.title}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="event_bottom">
        {renderBottomLeft()}
        {renderBottomRight()}
      </div>
    );
  };

  const renderBottomLeft = () => {
    return (
      <div className="event_bottom_left">
        <div className="row_space_between row_align_items">
          <div>
            <div className="font-bold font14">Date Time</div>
            <div className="font-regular font18" style={{ marginTop: 6 }}>
              {moment(eventDetail.date).format('DD MMM yyyy • hh a')}
            </div>
          </div>
          <div className="reminde_me clickable">
            <div
              className="font-bold font14 align-center"
              style={{ color: '#FF3E9A' }}
            >
              Remind Me
            </div>
            <div className="align-center" style={{ marginLeft: 6 }}>
              <NotificationIcon />
            </div>
          </div>
        </div>
        <div>
          <div className="font-bold font14">Location</div>
          <div className="font-regular font18" style={{ marginTop: 6 }}>
            {eventDetail.location}
          </div>
        </div>
        <div>
          <div className="font-bold font14">Description</div>
          <div className="font-regular font18" style={{ marginTop: 6 }}>
            {eventDetail.description}
          </div>
        </div>
      </div>
    );
  };

  const renderBottomRight = () => {
    return (
      <div className="event_bottom_right">
        <div className="font-bold font24">{totalRsvp} attending</div>
        <div>
          <Columns
            columns={4}
            gap={'16px'}
            rootStyles={{ paddingBottom: 10, width: '100%' }}
          >
            {arrRsvp.map((item, index) => {
              return (
                <div
                  key={`event_attending_card_${index}`}
                  className="clickable"
                  style={{
                    width: 80,
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                  onClick={() => onViewProfile(item.creator_id)}
                >
                  {item.creator_image &&
                  item.creator_image.startsWith('https') ? (
                    <img
                      src={item.creator_image}
                      className="event_attending_card_image"
                    />
                  ) : (
                    <DefaultAvatarIcon size={40} />
                  )}
                  <div
                    className="font-regular font12"
                    style={{
                      marginTop: 8,
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textAlign: 'center'
                    }}
                  >
                    {item.creator_name}
                  </div>
                </div>
              );
            })}
          </Columns>
        </div>
        <div style={{ padding: '0px 24px', marginTop: 24 }}>
          {!isRsvp ? (
            <div
              className="claim_btn clickable font-bold font16"
              onClick={onRsvp}
            >
              {isRsvping ? (
                <LoadingCircular color="white" size={24} />
              ) : (
                <div>Claim Your Spot</div>
              )}
            </div>
          ) : (
            <div
              className="claimed_button font-bold font16 clickable"
              onClick={onRemoveRsvp}
            >
              {isRsvping ? (
                <LoadingCircular color="main" size={24} />
              ) : (
                <div>RSVP-ed</div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderShareModal = () => {
    return (
      <ShareEventModal
        show={showShare}
        eventData={eventDetail}
        onClose={() => {
          setShowShare(false);
        }}
      />
    );
  };

  if (eventDetail) {
    return (
      <div className="event_detail">
        {renderHeader()}
        {renderTop()}
        {renderBottom()}
        {renderShareModal()}
      </div>
    );
  } else if (!isLoaded) {
    return <LoadingBarAnimation />;
  } else {
    return <div />;
  }
};
