import React from "react";

function LocationArrowWhiteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M14.1 1.906a1.927 1.927 0 00-2-.474L2.667 4.586a1.933 1.933 0 00-.08 3.646L6.08 9.566a.62.62 0 01.354.346l1.333 3.5a1.913 1.913 0 001.807 1.253h.046a1.92 1.92 0 001.794-1.333l3.153-9.446a1.927 1.927 0 00-.467-1.98zm-.766 1.56l-3.187 9.453a.587.587 0 01-.56.413.614.614 0 01-.58-.387l-1.333-3.5a1.94 1.94 0 00-1.114-1.12l-3.5-1.333a.6.6 0 01-.393-.58.587.587 0 01.413-.56L12.534 2.7a.607.607 0 01.8.767z"
      ></path>
    </svg>
  );
}

export default LocationArrowWhiteIcon;
