import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { apiCreateGroup } from 'shared/api/group';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import './index.styles.scss';

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { apiGetWorldInterest } from 'shared/api/world';

import { LeftArrow, RightArrow } from './ArrowBtns';
import usePreventBodyScroll from 'shared/utils/usePreventBodyScroll';

interface CreateGroupModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: (groupData) => void;
}

export const CreateGroupModal = ({
  show,
  onClose,
  onSuccess
}: CreateGroupModalProps) => {
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const [isCreating, setIsCreating] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupDesc, setNewGroupDesc] = useState('');
  const [newGroupImage, setNewGroupImage] = useState(null);

  const [arrInterest, setArrInteres] = useState<Array<any>>([]);
  const [lastIdInterest, setLastIdInterest] = useState(0);
  const [hasMoreInterest, setHasMoreInterest] = useState(true);
  const [isLoadingInterest, setIsLoadingInterest] = useState(false);

  useEffect(() => {
    if (show) {
      onLoadInterest(true);
    }
  }, [show]);

  const onLoadInterest = async (init = false) => {
    if (init) {
      setIsLoadingInterest(true);

      const res = await apiGetWorldInterest({ limit: 10, offset: 0 });
      if (res.sucess) {
        setArrInteres(res.data);
        setLastIdInterest(res.lastId);
        setHasMoreInterest(res.hasMore);
      }
      setIsLoadingInterest(false);
    } else {
      if (!isLoadingInterest && hasMoreInterest) {
        setIsLoadingInterest(true);

        const res = await apiGetWorldInterest({
          limit: 10,
          offset: lastIdInterest
        });
        if (res.sucess) {
          setArrInteres((prev) => [...prev, ...res.data]);
          setLastIdInterest(res.lastId);
          setHasMoreInterest(res.hasMore);
        }
        setIsLoadingInterest(false);
      }
    }
  };

  const onCreate = async () => {
    if (
      !isCreating &&
      newGroupName !== '' &&
      newGroupDesc !== '' &&
      newGroupImage !== null
    ) {
      setIsCreating(true);

      const res = await apiCreateGroup({
        name: newGroupName,
        description: newGroupDesc,
        url: newGroupImage!,
        tags: '',
        is_private: true
      });

      if (res.success) {
        onSuccess({
          ...res,
          name: newGroupName
        });
        setNewGroupName('');
        setNewGroupDesc('');
      }

      setIsCreating(false);
    }
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const onLoadMoreInterest = (vc) => {
    if (vc.isLastItemVisible) {
      onLoadInterest(false);
    }
  };

  const renderInterest = useMemo(() => {
    return (
      <div className="interest_filter_row">
        <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}
            onUpdate={onLoadMoreInterest}
          >
            {arrInterest.map((item, index) => {
              return (
                <div
                  key={`interest_filter_row_${index}`}
                  className={
                    newGroupImage === item.image
                      ? 'interest_filter_item_selected clickable'
                      : 'interest_filter_item_default clickable'
                  }
                  onClick={() => setNewGroupImage(item.image)}
                >
                  <img src={item.image} style={{ width: 44, height: 44 }} />
                </div>
              );
            })}
          </ScrollMenu>
        </div>
      </div>
    );
  }, [newGroupImage, arrInterest]);

  return (
    <PrimaryModal show={show} width={500} onClose={onClose}>
      <div className="modal_create_group">
        <div className="modal_create_group_header">
          <div className="modal_back_btn" onClick={onClose}>
            <BlackBackIcon />
          </div>
          <div className="title font-medium font16">Create New Group</div>
        </div>
        {renderInterest}
        <div className="modal_create_group_body">
          <div className="group_name">
            <div className="sub_title font-bold">Group Name</div>
            <div>
              <input
                value={newGroupName}
                placeholder="Enter group name here..."
                className="sub_input font-bold font16"
                onChange={(e) => setNewGroupName(e.target.value)}
              />
            </div>
          </div>
          <div className="group_desc">
            <div className="sub_title font-bold">Description</div>
            <div>
              <textarea
                value={newGroupDesc}
                placeholder="Enter group description here..."
                className="sub_input font-regular font14"
                onChange={(e) => setNewGroupDesc(e.target.value)}
              />
            </div>
          </div>
          <div className="btn_create">
            <PrimaryButton
              label="Create Group"
              isLoading={isCreating}
              onPressed={onCreate}
            />
          </div>
        </div>
      </div>
    </PrimaryModal>
  );
};
