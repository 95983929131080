import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Cookies from 'js-cookie';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import './index.styles.scss';
import { apiOrgVerifyEmail } from 'shared/api/org';

export const EmailVerifying = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const accessToken = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = async () => {
    const res = await apiOrgVerifyEmail({ token: accessToken! });
    if (res.status === 200 && res.data.success) {
      Cookies.set('accessToken', res.data.accessToken);
      localStorage.setItem('userId', res.data.userData.id);
      navigate(0);
    }

    navigate('/org/join', { replace: true });
  };

  return (
    <div className="content_body_link">
      <div className="loading">
        <LoadingCircular size={80} color="main" />
      </div>
      <div className="verifying_link_txt font-bold font24">
        Verifying link....
      </div>
      <div className="will_take_time_txt font-regular font16">
        You will taken to app in few seconds
      </div>
    </div>
  );
};
