import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import ChatSendIcon from 'assets/svgs/ChatSendIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RegenerateIcon from 'assets/svgs/RegenerateIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import { socket } from 'components/Auth';
import { SelectAITypeModal } from 'components/Modals/SelectAITypeModal';
import { SelectPersonAiModal } from 'components/Modals/SelectPersonAIModal';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { SocketEventName } from 'shared/socket/socket_events';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { ChatRoomData } from '../ChatHistory/CardChatHistory';
import { CardChat } from './CardChat';

import './index.styles.scss';
import { TypeLoadingAnimation } from 'shared/ui-kit/TypeLoadingAnimation';
import { ConversationVoteModal } from 'components/Modals/ConversationVoteModal';

interface ChatBodyProps {
  data: any;
  roomId?: number;
  roomInfo?: ChatRoomData;
  selectedAiType: string;
  isLoadingMessage: boolean;
  isWaitingResponse: boolean;
  onAdd: (message) => void;
  onCreateChat: (message) => void;
  onUpdateAIType: (newAiType) => void;
  onChangeAi: (aiInfo) => void;
  onUpdateWaitingStatus: (status) => void;
  onVote: () => void;
  setIsSendToOrg: any;
  setCurrentQuestion: any;
}

export const ChatBody = ({
  data,
  roomId,
  roomInfo,
  selectedAiType,
  isLoadingMessage,
  isWaitingResponse,
  onAdd,
  onCreateChat,
  onUpdateAIType,
  onChangeAi,
  onUpdateWaitingStatus,
  onVote,
  setIsSendToOrg,
  setCurrentQuestion
}: ChatBodyProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [message, setMessage] = useState('');

  const [arrMessage, setArrMessage] = useState(data);

  // dialogs
  const [showAiPersonModal, setShowAiPersonModal] = useState(false);
  const [showAiTypeModal, setShowAiTypeModal] = useState(false);

  // vote
  const [showVoteModal, setShowVoteModal] = useState(false);
  const [isVoted, setSetIsVoted] = useState(roomInfo?.is_voted);

  useEffect(() => {
    setArrMessage(data);
  }, [data]);

  useEffect(() => {
    setSetIsVoted(roomInfo?.is_voted);
  }, [roomId]);

  const onEnter = (e) => {
    if (e.shiftKey) {
    } else {
      e.stopPropagation();
      if (e.keyCode == 13) {
        e.preventDefault();
        onSendMessage();
        e.target.style.height = '40px';
      }
    }
  };

  const onKeyDown = (e) => {
    e.target.style.height = '40px';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const onSendMessage = () => {
    if (message !== '' && !isWaitingResponse) {
      if (roomId && roomId != -1) {
        socket.emit(SocketEventName.send_message_chat_ai, {
          room: roomId,
          creator: userInfo?.id,
          type: 'text',
          message
        });
      } else {
        onCreateChat(message);
      }

      onAdd({
        ai_id: 5,
        ai_image: userInfo?.image,
        ai_name: userInfo?.name,
        ai_type: 'user',
        is_ai: 0,
        message: message,
        type: 'text'
      });
      setMessage('');
    }
  };

  const renderHeader = () => {
    return (
      <div className="chat_body_header">
        {selectedAiType === 'general'
          ? renderGeneralRoomInfo()
          : renderDropRoomInfo()}
        <div
          className="clickable"
          style={{ marginRight: 16 }}
          onClick={() => {
            setShowAiTypeModal(true);
          }}
        >
          <RegenerateIcon />
          <span className="font-medium font16" style={{ marginLeft: 8 }}>
            Switch AI
          </span>
        </div>
        {showAiTypeModal && renderSelectAITypeModal()}
        {roomInfo && isVoted === 0 ? (
          <div
            className="chat_body_header_end_btn clickable"
            onClick={() => setShowVoteModal(true)}
          >
            <div className="font-bold font16">End</div>
          </div>
        ) : (
          <div className="chat_body_header_end_btn">
            <div className="font-bold font16">Ended</div>
          </div>
        )}
      </div>
    );
  };

  const renderGeneralRoomInfo = () => {
    return (
      <div className="chat_body_header_normal">
        <div className="chat_body_header_image">
          <RobotIcon size={28} />
        </div>
        <div className="chat_body_header_title font-bold font20">
          {`General AI`}
        </div>
      </div>
    );
  };

  const renderDropRoomInfo = () => {
    if (roomInfo) {
      return (
        <div className="chat_body_header_normal clickable">
          <div className="chat_body_header_image">
            {roomInfo?.ai_image && roomInfo?.ai_image.startsWith('https') ? (
              <img
                src={roomInfo?.ai_image}
                style={{ width: 48, height: 48, borderRadius: 48 }}
              />
            ) : selectedAiType === 'user' ? (
              <DefaultAvatarIcon size={48} />
            ) : (
              <img
                src={roomInfo?.ai_image}
                style={{ width: 48, height: 48, borderRadius: 48 }}
              />
            )}
          </div>
          <div
            className="chat_body_header_title clickable"
            onClick={() => setShowAiPersonModal(true)}
          >
            <div className="chat_body_header_drop_header font-bold font12">
              {selectedAiType === 'user'
                ? 'Personal AI'
                : selectedAiType === 'group'
                ? 'Group AI'
                : selectedAiType === 'organisation'
                ? 'School AI'
                : 'General AI'}
            </div>
            <div className="font-bold font20">
              {`${roomInfo?.ai_name ? roomInfo?.ai_name : ''} AI`}
              <span style={{ marginLeft: 16 }}>
                <AngleDownIcon />
              </span>
            </div>
          </div>
          {showAiPersonModal && renderSelectPersonAIModal()}
        </div>
      );
    } else {
      return <div className="chat_body_header_normal clickable"></div>;
    }
  };

  const renderBody = () => {
    return (
      <div className="chat_body_body">
        {isLoadingMessage ? (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              marginTop: 12
            }}
          >
            <LoadingCircular size={24} color="main" />
          </div>
        ) : (
          arrMessage.map((item, index) => {
            return (
              <CardChat
                key={`card_chat_${index}`}
                data={item}
                setIsSendToOrg={setIsSendToOrg}
                setCurrentQuestion={setCurrentQuestion}
              />
            );
          })
        )}
      </div>
    );
  };

  const renderInput = () => {
    if (isVoted == 0)
      return (
        <div>
          {isWaitingResponse && (
            <div style={{ marginLeft: 24, marginBottom: 16, marginTop: 16 }}>
              <TypeLoadingAnimation
                background={'#FF3E9A'}
                size={'12px'}
                margin={'4px'}
                duration={'0.5s'}
              />
            </div>
          )}
          <div className="chat_body_input">
            <textarea
              value={message}
              placeholder="Ask more questions here..."
              className="chat_box_input font-regular font20"
              onChange={(e) => {
                if (e.nativeEvent['inputType'] === 'insertLineBreak') {
                  e.preventDefault();
                  return;
                } else {
                  setMessage(e.target.value);
                }
              }}
              onKeyUp={(e) => {
                e.preventDefault();
                onEnter(e);
              }}
            />
            <div className="clickable" onClick={onSendMessage}>
              <ChatSendIcon />
            </div>
          </div>
        </div>
      );
    else
      return (
        <div>
          <div className="ended_chat font16">
            Chat has ended. Saved as
            <br />
            <span className="font-bold">“{roomInfo?.name}”</span>
          </div>
        </div>
      );
  };

  const renderSelectAITypeModal = () => {
    return (
      <SelectAITypeModal
        show={showAiTypeModal}
        onClose={() => {
          setShowAiTypeModal(false);
        }}
        onSelected={(newAiType) => {
          onUpdateAIType(newAiType);
          setShowAiTypeModal(false);
        }}
      />
    );
  };

  const renderSelectPersonAIModal = () => {
    return (
      <SelectPersonAiModal
        show={showAiPersonModal}
        aiType={selectedAiType}
        selectedAi={roomInfo}
        onClose={() => {
          setShowAiPersonModal(false);
        }}
        onSelected={(newAI) => {
          setShowAiPersonModal(false);
          onChangeAi(newAI);
        }}
      />
    );
  };

  return (
    <div className="chat_body">
      {renderHeader()}
      {renderBody()}
      {renderInput()}
      <ConversationVoteModal
        show={showVoteModal}
        onClose={() => setShowVoteModal(false)}
        roomId={roomId}
        roomInfo={roomInfo}
        onSuccess={() => {
          setShowVoteModal(false);
          setSetIsVoted(1);
          onVote();
        }}
      />
    </div>
  );
};
