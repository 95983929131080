import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import './index.styles.scss';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';

interface DumpData {
  id: number;
  isDummy: boolean;
  dump_type: 'text' | 'url' | 'blog' | 'pdf' | 'audio';
  filename?: string;
  size?: string;
  status?: 'uploading' | 'processing' | 'pending' | 'completed' | 'failed';
}
interface CardMyDraftProps {
  data: DumpData;
  setFileAnswer: any;
  setMediaType: any;
  setIsFileOrText: any;
}
export const CardFileAnswer = ({
  data,
  setFileAnswer,
  setMediaType,
  setIsFileOrText
}: CardMyDraftProps) => {
  const onCancel = () => {
    setFileAnswer([]);
    setMediaType('text');
    setIsFileOrText(false);
  };

  const renderProcessingPdfAnswer = () => {
    return (
      <div
        className="file_answer row_space_between"
        style={{
          backgroundColor: 'transparent',
          border: '1px dashed rgba(255, 255, 255, 0.6)',
          borderRadius: 16,
          display: 'flex',
          marginBottom: 16,
          width: '100%'
        }}
      >
        <div
          className="row_align_items"
          style={{ width: 'calc(100% - 100px)' }}
        >
          <div style={{ fontSize: 36 }}>
            <PdfFileIcon />
          </div>
          <div className="file_info" style={{ marginLeft: 16, width: '100%' }}>
            <div
              className="file_name font-medium font20"
              style={{
                width: 'calc(100% - 100px)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {data.filename}
            </div>
            <div className="file_sub_info">
              <div className="file_size_txt font-regular font16">
                Size {data.size} MB
              </div>
              <div className="file_readable_view">
                <div className="file_pending font-medium font12"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="delete_file_btn clickable"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '12px',
            background: '#ffffff',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            borderRadius: '50px',
            fontWeight: '500',
            fontSize: '16px',
            width: '90px'
          }}
          onClick={onCancel}
        >
          Cancel
        </div>
      </div>
    );
  };

  const renderUploadingPdfAnswer = () => {
    return (
      <div
        style={{
          backgroundColor: 'transparent',
          border: '1px dashed rgba(255, 255, 255, 0.6)',
          borderRadius: 16,
          padding: 16
        }}
      >
        <div
          className="file_answer"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div style={{ fontSize: 36 }}>📄</div>
          <div className="file_info" style={{ marginLeft: 16 }}>
            <div className="file_name font-medium font20">{data.filename}</div>
            <div className="file_sub_info">
              <div className="file_size_txt font-regular font16">
                Size {data.size} MB
              </div>
              <div className="file_readable_view">
                <div className="file_pending font-medium font12">
                  Uploading...
                </div>
              </div>
            </div>
          </div>
        </div>

        <Box sx={{ width: '100%' }} mt={1}>
          <LinearProgress />
        </Box>
      </div>
    );
  };
  return (
    <>
      {data.status === 'processing' || data.status === 'pending' ? (
        renderProcessingPdfAnswer()
      ) : (
        <>{renderUploadingPdfAnswer()}</>
      )}
    </>
  );
};
