import ReadCheckIcon from "assets/svgs/ReadCheckIcon";
import React, { useEffect, useState } from "react";
import './index.styles.scss';

interface TemplateAnswerProps {
    text: string;
    isSelected: boolean;
    onSelected: (answer) => void;
    setIsEditAnswer: any
    
}
export const TemplateAnswer = ({text, isSelected, onSelected, setIsEditAnswer}: TemplateAnswerProps) => {
    return(
        <>
            {isSelected?  
                <div className="template_answer_box">
                    <div className="answer_sentence font14">
                    {text} 
                    </div>
                    <div className="used_btn" >
                            Used
                        <ReadCheckIcon/>        
                    </div>    
                </div>:
                 <div className="template_answer_box">
                    <div className="answer_sentence font14">
                    {text} 
                    </div>
                    <div className="use_btn" onClick={() => {onSelected(text); setIsEditAnswer(false)}}>
                            Use as Answer
                        <ReadCheckIcon/>    
                    </div>    
                </div>
            }
        </>
            
          
    )
}