import CommentDotIcon from 'assets/svgs/CommentIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiUserFollow, apiUserUnFollow } from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';

export const CardOrgMember = ({ data }) => {
  const navigate = useNavigate();

  const [followStatus, setFollowStatus] = useState<
    'false' | 'true' | 'requested'
  >(data.isFollowed);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followerCount, setFollowerCount] = useState(data.followers);
  const [hasAI, setHasAI] = useState(false);

  useEffect(() => {
    setHasAI(data.has_ai === 1);
  }, [data]);

  const onFollowAction = (e) => {
    e.stopPropagation();

    if (followStatus === 'false') {
      onFollow();
    } else {
      onUnFollow();
    }
  };

  const onFollow = async () => {
    if (!isFollowing) {
      setIsFollowing(true);

      const res = await apiUserFollow({ followingId: data.id });
      if (res.success) {
        setFollowerCount((prev) => prev + 1);
        setFollowStatus(res.isFollowed);
      }

      setIsFollowing(false);
    }
  };

  const onUnFollow = async () => {
    if (!isFollowing) {
      setIsFollowing(true);

      const res = await apiUserUnFollow({ following: data.id });
      if (res.success) {
        setFollowerCount((prev) => prev - 1);
        setFollowStatus('false');
      }

      setIsFollowing(false);
    }
  };

  const onChat = (e) => {
    e.stopPropagation();
    if (hasAI) {
      navigate(`/conversation/user/${data.id}`);
    }
  };

  const onProfile = () => {
    navigate(`/profile/${data.id}`);
  };

  const renderBottom = () => {
    return (
      <div className="row_align_items" style={{ marginTop: 12 }}>
        <div className="chat_btn clickable" onClick={onChat}>
          <CommentDotIcon
            size={24}
            color={hasAI ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
          />
        </div>
        {renderFollowBtn()}
      </div>
    );
  };

  const renderFollowBtn = () => {
    if (followStatus === 'false') {
      return (
        <div
          className="follow_btn font-bold font14 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? <LoadingCircular size={16} color="white" /> : `Follow`}
        </div>
      );
    } else if (followStatus === 'true') {
      return (
        <div
          className="following_btn font-bold font14 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            `Unfollow`
          )}
        </div>
      );
    } else {
      return (
        <div
          className="requested_btn font-bold font14 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            `Requested`
          )}
        </div>
      );
    }
  };

  return (
    <div className="card_org_member clickable" onClick={onProfile}>
      {data.image && data.image.startsWith('https') ? (
        <img src={data.image} className="user_image" />
      ) : (
        <DefaultAvatarIcon size={70} />
      )}
      <div className="user_info">
        <div className="font-bold font18">{data.name}</div>
        <div
          className="font-regular font16"
          style={{ opacity: 0.4 }}
        >{`${followerCount} followers`}</div>
      </div>
      {renderBottom()}
    </div>
  );
};
