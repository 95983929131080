import UserFollowerIcon from 'assets/svgs/UserFollowerIcon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import './CardProfileGroup.styles.scss';

interface DumpData {
  id: number;
  name: string;
  email_prefix: string;
  creator: number;
  description: string;
  url: string;
  joining_type: string;
  timestamp: string;
  colour: number;
  code: string;
  members: number;
}

interface CardProfileGroupProps {
  data: DumpData;
}

export const CardProfileGroup = ({ data }: CardProfileGroupProps) => {
  const navigate = useNavigate();

  const onDetail = () => {
    navigate(`/group/detail/${data.code}`);
  };

  return (
    <div
      className="card_profile_group clickable"
      onClick={onDetail}
      style={{ width: '100%' }}
    >
      <div className="org_image">
        <img src={data.url} className="org_image" />
      </div>
      <div className="org_info">
        <div className="org_name font-bold font14">{data.name}</div>
        <div className="org_followers">
          <UserFollowerIcon size={16} color="rgba(0, 0, 0, 0.6)" />
          <div className="org_follower_txt font-regular font12">
            {data.members}
          </div>
        </div>
      </div>
    </div>
  );
};
