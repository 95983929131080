import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGroupAskToJoin } from 'shared/api/group';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { getArrFromString } from 'shared/utils/utils';
import { CardJGQuestion } from '../CardJGQuestion';

import './index.styles.scss';

interface GroupData {
  id: number;
  name: string;
  creator: number;
  description: string;
  url?: string;
  tags: string;
  code: string;
  is_private: number;
  timestamp: string;
  to: number;
  members: number;
  isFollowed: 'true' | 'false' | 'requested';
  questions?: string;
  ai_answers?: string;
  timestamps?: string;
  creator_names?: string;
  creator_images?: string;
}
interface CardJoinedGroupProps {
  data: GroupData;
  showQuestion: boolean;
  onChangedStatus: (groupId) => void;
}

export const CardJoinedGroup = ({
  data,
  showQuestion,
  onChangedStatus
}: CardJoinedGroupProps) => {
  const navigate = useNavigate();
  const [joinStatus, setJoinStatus] = useState(data.isFollowed);
  const [isJoining, setIsJoining] = useState(false);

  useEffect(() => {
    setJoinStatus(data.isFollowed);
  }, [data]);

  const onDetail = () => {
    if (joinStatus === 'true') {
      navigate(`/group/detail/${data.code}`);
    }
  };

  const onAskToJoin = async () => {
    setIsJoining(true);

    const res = await apiGroupAskToJoin({ group: data.id });
    if (res.sucesss) {
      setJoinStatus('requested');
      onChangedStatus(data.id);
    }
    setIsJoining(false);
  };

  const arrQuestions = useMemo(() => {
    if (data.questions) {
      const tmpArrQuestion = getArrFromString(data.questions, ';');
      const tmpArrAnswer = getArrFromString(data.ai_answers, ';');
      const tmpArrTimeStamp = getArrFromString(data.timestamps, ';');
      const tmpArrCreatorName = getArrFromString(data.creator_names, ';');
      const tmpArrCreatorImage = getArrFromString(data.creator_images, ';');

      const tmpData = tmpArrQuestion.map((item, index) => {
        return {
          question: tmpArrQuestion[index],
          answer: tmpArrAnswer[index],
          timestamp: tmpArrTimeStamp[index],
          creator_name: tmpArrCreatorName[index],
          creator_image: tmpArrCreatorImage[index]
        };
      });

      return tmpData;
    } else {
      return [];
    }
  }, [data]);

  const renderAskJoin = () => {
    return (
      <div className="card_joined_group_ask_join">
        <PrimaryButton
          label={joinStatus === 'requested' ? 'Requested' : 'Ask to Join'}
          isLoading={isJoining}
          disable={joinStatus === 'requested' ? true : false}
          onPressed={onAskToJoin}
          containerStyle={{ height: 38 }}
        />
      </div>
    );
  };

  const renderQuestions = () => {
    return (
      <div className="card_joined_group_questions">
        {arrQuestions.map((item, index) => {
          return (
            <CardJGQuestion key={`card_jg_question_${index}`} data={item} />
          );
        })}
      </div>
    );
  };

  return (
    <div className="card_joined_group clickable" onClick={onDetail}>
      <div className="card_joined_group_header">
        <div className="card_joined_group_top">
          {data.url?.startsWith('https://') ? (
            <div>
              <img src={data.url} style={{ width: 68, height: 68 }} />
            </div>
          ) : (
            <DefaultAvatarIcon size={68} />
          )}
          <div style={{ marginLeft: 32 }}>
            <div className="font-bold font26">{data.name}</div>
            <div className="description font-regular font20">
              {data.description}
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="bottom_item">
            <div style={{ fontSize: 18 }}>🙋‍♂️</div>
            <div className="bottom_item_txt font-bold font16">
              {data.members} member{data.members > 1 ? 's' : ''}
            </div>
            {joinStatus !== 'true' && renderAskJoin()}
          </div>
        </div>
      </div>

      {joinStatus === 'true' && arrQuestions.length > 0 && renderQuestions()}
    </div>
  );
};
