import React, { useState } from 'react';
import './index.styles.scss';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import ReadCheckIcon from 'assets/svgs/ReadCheckIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';

interface UserReferenceData {
  creator_id: number;
  creator_image?: string;
  creator_name?: string;
}
interface CardUserReferenceProps {
  data: UserReferenceData;
  isSelected: boolean;
  onSelect: (referenceIdx: number) => void;
}

export const CardUserReference = ({
  data,
  isSelected,
  onSelect
}: CardUserReferenceProps) => {
  return (
    <div
      className={
        'row_space_between dlg_list_item' + (isSelected ? ' added_item' : '')
      }
    >
      <div className="row_align_items">
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img
            className="creator_image"
            src={data.creator_image}
            width="24"
            height="24"
            style={{ borderRadius: 24 }}
          ></img>
        ) : (
          <div style={{ width: 24, height: 24 }}>
            <DefaultAvatarIcon size={24} />
          </div>
        )}
        <div style={{ marginLeft: 8 }}>
          <div className="ref_name font-medium font12">{data.creator_name}</div>
        </div>
      </div>
      {isSelected ? (
        <div
          className="check_icon clickable"
          onClick={() => onSelect(data.creator_id)}
        >
          <ReadCheckIcon color="#FF3E9A" size={18} />
        </div>
      ) : (
        <div
          className="add_btn clickable"
          onClick={() => {
            onSelect(data.creator_id);
          }}
        >
          +
        </div>
      )}
    </div>
  );
};
