import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import './index.styles.scss';

interface Props {
  show: boolean;
  posX: number;
  posY: number;
  onClose: () => void;
  onSelected: (action: 'private' | 'public' | 'anonymous') => void;
}

export const FilePrivacyModal = ({
  show,
  posX,
  posY,
  onClose,
  onSelected
}: Props) => {
  const onCloseModal = (e) => {
    e.stopPropagation();
    onClose();
  };

  if (show) {
    return (
      <div className="file_privacy_modal" onClick={onCloseModal}>
        <div
          className="file_privacy_modal_body"
          style={{ left: posX, top: posY, position: 'fixed' }}
        >
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Only accessible for you or in the group you shared it with
              </div>
            }
          >
            <div
              className="action_item row_align_items"
              onClick={() => {
                onSelected('private');
              }}
            >
              <div className="action_icon_view font20">🔒</div>
              <div className="action_name font-regular font16">Private</div>
            </div>
          </Tooltip>
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Accessible to others via your personal AI and credited to you
              </div>
            }
          >
            <div
              className="action_item row_align_items"
              onClick={() => {
                onSelected('public');
              }}
            >
              <div className="action_icon_view font20">🤗</div>
              <div className="action_name font-regular font16">Public</div>
            </div>
          </Tooltip>
          <Tooltip
            // arrow
            title={
              <div className="font-regular font14">
                Anonymously accessible to others via your personal AI
              </div>
            }
            PopperProps={{
              sx: {
                marginTop: '0px !important'
              }
            }}
          >
            <div
              className="action_item row_align_items"
              onClick={() => {
                onSelected('anonymous');
              }}
            >
              <div className="action_icon_view font20">🎭</div>
              <div className="action_name font-regular font16">Anonymous</div>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};
