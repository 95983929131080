import CopyIcon from 'assets/svgs/CopyIcon';
import React, { useEffect, useState } from 'react';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

import './index.styles.scss';
import CloseIcon from 'assets/svgs/CloseIcon';
import EnvelopePlusIcon from 'assets/svgs/EnvelopePlusIcon';
import UserPlusIcon from 'assets/svgs/UserPlusIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface SuccessCreatedGroupModalProps {
  show: boolean;
  groupDetail: any;
  onClose: () => void;
  onSendInvitationEmail: () => void;
  onAddUserById: () => void;
}

export const SuccessCreatedGroupModal = ({
  show,
  groupDetail,
  onClose,
  onSendInvitationEmail,
  onAddUserById
}: SuccessCreatedGroupModalProps) => {
  const [searchText, setSearchText] = useState('');
  const [shareUrl, setShareUrl] = useState('');

  useEffect(() => {
    if (groupDetail) {
      setShareUrl(`${process.env.REACT_APP_WEB_URL}group/${groupDetail?.code}`);
    }
  }, [groupDetail]);

  const onCopyShareLink = () => {
    navigator.clipboard.writeText(shareUrl);

    showToast('success', 'Successfully copied!');
  };

  const renderCopy = () => {
    return (
      <div className="success_create_group_modal_copy">
        <div
          className="font-bold font16"
          style={{ display: 'flex', justifyContent: 'center', opacity: '0.6' }}
        >
          Copy and share group link
        </div>
        <div className="success_create_group_modal_copy_body">
          <div
            className="font-bold font16"
            style={{
              width: 'calc(100% - 50px)',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {shareUrl.replace('https://', '')}
          </div>
          <div className="clickable" onClick={onCopyShareLink}>
            <CopyIcon />
          </div>
        </div>
      </div>
    );
  };

  const renderBottomBar = () => {
    return (
      <>
        <div className="success_create_group_modal_otherWayText font16 font-bold">
          or try other way
        </div>
        <div className="success_create_group_modal_bottom">
          <div
            className="success_create_group_modal_bottom_left clickable"
            onClick={onSendInvitationEmail}
          >
            <div>
              {' '}
              <EnvelopePlusIcon />{' '}
            </div>
            <div className="font16 font-bold">Send Invitation Email</div>
          </div>
          <div
            className="success_create_group_modal_bottom_right clickable"
            onClick={onAddUserById}
          >
            <div>
              {' '}
              <UserPlusIcon />{' '}
            </div>
            <div className="font16 font-bold">Add User by ID</div>
          </div>
        </div>
      </>
    );
  };

  return (
    <PrimaryModal show={show} width={500} onClose={onClose}>
      <div className="success_create_group_modal">
        <div
          className="success_create_group_modal_close_icon clickable"
          onClick={onClose}
        >
          <CloseIcon />
        </div>
        <div className="success_create_group_modal_icon">🎉</div>
        <div className="success_create_group_modal_title font-bold font24">
          Yeay! Your group was
          <br />
          created successfully!
        </div>
        {renderCopy()}
        {renderBottomBar()}
      </div>
    </PrimaryModal>
  );
};
