import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import FileIcon from 'assets/svgs/FileIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import UserCheckIcon from 'assets/svgs/UserCheckIcon';
import React, { useMemo, useState } from 'react';
import { apiGetQAAsk } from 'shared/api/qa';
import { QuestionAskBar } from '../components/QuestionAskBar';
import Switch from 'react-switch';

import './index.styles.scss';
import { NewAddReferenceModal } from 'components/Modals/AddReferenceModal/NewAddReferenceModal';

export const OrgUnAnsweredQuestion = ({ selectedOrg }) => {
  const [isAsking, setIsAsking] = useState(false);
  const [qaAskInfo, setQaAskInfo] = useState<{ [key: string]: any } | null>(
    null
  );

  const [isFocusedSearch, setIsFocusedSearch] = useState(false);
  const [showAskedView, setShowAskedView] = useState(false);
  const [isWithDoc, setIsWithDoc] = useState(false);

  // add reference
  const [selectedRef, setSelectedRef] = useState<Array<any>>([]);
  const [showAddDlg, setShowAddDlg] = useState(false);
  const [addDlgPos, setAddDlgPos] = useState({ top: 0, left: 0 });

  const loadQuestionData = async (question, orgId = null) => {
    if (question) {
      setIsAsking(true);
      const userRef = selectedRef.map((item, index) => item.creator_id);
      const res = await apiGetQAAsk({
        question,
        to: orgId ?? selectedOrg?.id,
        to_type: orgId ?? selectedOrg ? 'organisation' : 'general',
        users: userRef.length > 0 ? userRef + '' : undefined
      });

      if (res.success) {
        setQaAskInfo(res.data);
      } else {
        setQaAskInfo(null);
      }
      setIsAsking(false);
    } else {
      setQaAskInfo(null);
    }
  };

  const onSearch = (question) => {
    setShowAskedView(true);
    setIsFocusedSearch(false);

    loadQuestionData(question);
  };

  const onFocusSearch = () => {
    setIsFocusedSearch(true);
  };

  const onLeaveSearch = () => {
    // setIsFocusedSearch(false);
  };

  const onShowAddRefDlg = (e) => {
    setShowAddDlg(true);

    const clientRectangle = document!
      .getElementById('org_answer_add_btn_ref')!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setAddDlgPos({
      top:
        bottomFromWindow < 400
          ? clientRectangle.top - 400
          : clientRectangle.top,
      left: clientRectangle.left
    });
  };

  const onDoneAddReference = (userRef, fileRef) => {
    setSelectedRef(userRef);
  };

  const arrReferences = useMemo(() => {
    const arrTmpRefData: Array<any> = [];

    selectedRef.map((item, index) => {
      arrTmpRefData.push({
        name: selectedRef[index].creator_name,
        image: selectedRef[index].creator_image,
        type: 'user'
      });
    });
    return arrTmpRefData;
  }, [selectedRef]);

  const renderAskBar = () => {
    return (
      <div>
        <QuestionAskBar
          onSearch={onSearch}
          onFocus={onFocusSearch}
          onBlur={onLeaveSearch}
          width="100%"
          height="64"
          backgroundColor="#F1F6F9"
          placeholder="Ask new question here..."
          fontSize={20}
          searchIconClickable={true}
        />
        {isFocusedSearch && (
          <div style={{ marginTop: 32 }}>{renderReference()}</div>
        )}
      </div>
    );
  };

  const renderReference = () => {
    return (
      <div className="org_reference">
        <div className="org_reference_left">
          <UserCheckIcon size={24} />
          {arrReferences.length > 0 ? (
            <div className="font-medium font14" style={{ marginLeft: 8 }}>
              References
            </div>
          ) : (
            <div
              className="font-medium font14"
              style={{ marginLeft: 8, flex: 1 }}
            >
              No references set (optional). Showing insights from all members
            </div>
          )}
          {arrReferences.length > 0 && renderReferenceItems()}

          <div
            className="round_btn clickable"
            id={'org_answer_add_btn_ref'}
            onClick={onShowAddRefDlg}
          >
            <PlusIcon size={16} />
          </div>
        </div>
        <div className="org_reference_right">
          <FileIcon />
          <div
            className="font-medium font16"
            style={{ marginLeft: 8, flex: 1 }}
          >
            With Docs Only
          </div>
          <Switch
            onChange={() => {
              setIsWithDoc((prev) => !prev);
            }}
            checked={isWithDoc}
            checkedIcon={<div />}
            uncheckedIcon={<div />}
            offColor={'#EAEBEC'}
            onColor={'#FF3E9A'}
            activeBoxShadow={undefined}
            handleDiameter={18}
            width={44}
            height={24}
          />
        </div>
      </div>
    );
  };

  const renderReferenceItems = () => {
    return (
      <div className="org_answer_reference_list">
        {arrReferences.map((item, index) => {
          return (
            <div
              className="reference_item"
              key={`reference_item_${index}`}
              style={{ height: 40 }}
            >
              {item.image && item.image.startsWith('https') ? (
                <img
                  src={item.image}
                  style={{ width: 24, height: 24, borderRadius: 24 }}
                />
              ) : (
                <DefaultAvatarIcon size={24} />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderSubHeader = () => {
    return (
      <div className="row_space_between row_align_items">
        <div className="font-bold font20">Showing unanswered questions</div>
        <div
          className="font-bold font14 clickable"
          style={{ color: '#FF3E9A' }}
        >
          Clear
        </div>
      </div>
    );
  };

  const renderAddRefDlg = () => {
    return (
      <NewAddReferenceModal
        show={showAddDlg}
        itemId={selectedOrg?.id}
        itemType={'organisation'}
        posX={addDlgPos.left}
        posY={addDlgPos.top}
        onlyCreator={true}
        onClose={() => setShowAddDlg(false)}
        onDone={(userRef, fileRef) => {
          setShowAddDlg(false);
          onDoneAddReference(userRef, fileRef);
        }}
      />
    );
  };

  return (
    <div className="org_un_answered">
      {renderAskBar()}
      {renderSubHeader()}
      {renderAddRefDlg()}
    </div>
  );
};
