import React from 'react';

function NextIcon(props) {
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.71018 5.50001L1.17018 1.96001C0.983927 1.77265 0.879386 1.5192 0.879386 1.25501C0.879386 0.990824 0.983927 0.737372 1.17018 0.55001C1.26314 0.456281 1.37374 0.381887 1.4956 0.331118C1.61746 0.280349 1.74817 0.254211 1.88018 0.254211C2.01219 0.254211 2.1429 0.280349 2.26476 0.331118C2.38661 0.381887 2.49722 0.456281 2.59018 0.55001L6.83018 4.79001C6.92391 4.88297 6.9983 4.99357 7.04907 5.11543C7.09984 5.23729 7.12598 5.368 7.12598 5.50001C7.12598 5.63202 7.09984 5.76273 7.04907 5.88459C6.9983 6.00645 6.92391 6.11705 6.83018 6.21001L2.59018 10.5C2.49674 10.5927 2.38592 10.666 2.26408 10.7158C2.14225 10.7655 2.01179 10.7908 1.88018 10.79C1.74857 10.7908 1.61811 10.7655 1.49627 10.7158C1.37443 10.666 1.26362 10.5927 1.17018 10.5C0.983927 10.3126 0.879386 10.0592 0.879386 9.79501C0.879386 9.53082 0.983927 9.27737 1.17018 9.09001L4.71018 5.50001Z"
        fill={props.isDisabled ? 'rgba(0, 0, 0, 0.4)' : '#FF3E9A'}
      />
    </svg>
  );
}

export default NextIcon;
