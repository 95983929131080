import React from 'react';

function RefreshIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#5F6368"
        fillRule="evenodd"
        d="M21.497 9.497v-5.29c0-.45-.54-.67-.85-.35l-1.78 1.78a9.01 9.01 0 00-7.21-2.6c-4.19.38-7.64 3.75-8.1 7.94-.6 5.42 3.63 10.02 8.94 10.02 4.59 0 8.38-3.44 8.93-7.88.07-.6-.4-1.12-1-1.12-.5 0-.92.37-.98.86-.43 3.49-3.44 6.19-7.05 6.14-3.71-.05-6.84-3.18-6.9-6.9-.06-3.9 3.11-7.1 7-7.1 1.93 0 3.68.79 4.95 2.05l-2.09 2.09c-.32.32-.1.86.35.86h5.29c.28 0 .5-.22.5-.5z"
        clipRule="evenodd"
        opacity="0.6"
      ></path>
    </svg>
  );
}

export default RefreshIcon;
