import HttpClient from './apiClient';

export async function apiGetWorldInterest({ limit = 10, offset = 0 }) {
  try {
    const params = {
      limit,
      offset
    };

    const response = await HttpClient.getWithToken(`world/interests`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
