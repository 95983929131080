import React from 'react';

function NotificationIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 16}
      height={props.size ?? 16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g
        stroke="#FF3E9A"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_3677_114312)"
      >
        <path d="M3.51 6.5A4.494 4.494 0 018.03 2c2.475.019 4.456 2.075 4.456 4.556V7c0 2.238.468 3.538.881 4.25a.5.5 0 01-.431.75H3.06a.5.5 0 01-.432-.75c.413-.712.882-2.012.882-4.25v-.5zM6 12v.5a2 2 0 004 0V12"></path>
      </g>
      <defs>
        <clipPath id="clip0_3677_114312">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default NotificationIcon;
