import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import TrashIcon from 'assets/svgs/TrashIcon';
import React from 'react';

import './index.styles.scss';

interface Props {
  show: boolean;
  posX: number;
  posY: number;
  onClose: () => void;
  onSelected: (action: 'most' | 'latest') => void;
}

export const QuestionFilterActionModal = ({
  show,
  posX,
  posY,
  onClose,
  onSelected
}: Props) => {
  if (show) {
    return (
      <div
        className="question_filter_action_modal"
        id="question_filter_action_modal"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <div
          className="question_filter_action_modal_body"
          style={{ left: posX, top: posY, position: 'fixed' }}
        >
          <div
            className="action_item row_align_items clickable"
            onClick={(e) => {
              e.stopPropagation();
              onSelected('most');
            }}
          >
            <div className="action_name font-medium font18">Most Popular</div>
            <div style={{ marginLeft: 6 }}>
              <AngleRightIcon color={'#FF3E9A'} opacity={1} />
            </div>
          </div>
          <div
            className="action_item row_align_items clickable"
            onClick={(e) => {
              e.stopPropagation();
              onSelected('latest');
            }}
          >
            <div className="action_name font-medium font18">Latest Posts</div>
            <div style={{ marginLeft: 6 }}>
              <AngleRightIcon color={'#FF3E9A'} opacity={1} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};
