import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { QuestionRightPanel } from './RightPanel';
import { AddAnswer } from './AddAnswer';
import { apiGetQAQuestionDetail, apiGetQASimilarQuestion } from 'shared/api/qa';

import './index.styles.scss';
import AlertCheckIcon from 'assets/svgs/AlertCheckIcon';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import EnvelopStarIcon from 'assets/svgs/EnvelopStarIcon';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { apiOrgRequestJoin } from 'shared/api/org';

export const QuestionDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const questionId = Number(params.code);
  const [section, setSection] = useState(params.section);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [isFollowedOrg, setIsFollowedOrg] = useState<
    'true' | 'false' | 'requested'
  >('false');

  const [selectedOrg, setSelectedOrg] = useState<any>(null);
  const [orgDetail, setOrgDetail] = useState<any>(null);

  const [arrOrganisation, setArrOrganisation] = useState<any>([]);
  const [tab, setTab] = useState<'detail' | 'addAnswer'>('detail');
  const [questionDetail, setQuestionDetail] = useState<any>(null);
  const [textQuestion, setTextQuestion] = useState<any>(null);
  const [questionPoints, setQuestionPoints] = useState(null);
  const [refreshOtherAnswers, setRefreshOtherAnswers] = useState(false);
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);

  useEffect(() => {
    loadQuestionData();
  }, [questionId]);

  const onAddAnswer = () => {
    // setRefreshOtherAnswers((prev) => !prev);
    setSection('answer');
  };

  const loadQuestionData = async () => {
    setIsLoading(true);
    const res = await apiGetQAQuestionDetail({ bookmark: questionId });

    if (res.success) {
      setIsFollowedOrg(res.status);

      if (res.status === 'true') {
        setQuestionDetail(res.data);
        setQuestionPoints(res.data.points);
        setTextQuestion(res.data.question);
      } else {
        setOrgDetail(res.data);
      }

      setIsLoaded(true);
    }

    setIsLoading(false);
  };

  const onRequestJoin = async () => {
    setIsJoining(true);
    const res = await apiOrgRequestJoin({ organisation: orgDetail.id });
    if (res.success) {
      setIsFollowedOrg(res.isFollowed);
    }

    setIsJoining(false);
  };

  const showSnackBar = () => {
    setIsShowSnackBar(true);
    setInterval(() => setIsShowSnackBar(false), 3000);
  };

  const renderRightPanel = () => {
    return (
      <div
        style={{ height: `calc(100%)`, overflowY: 'auto' }}
        id="org_question_detail_right_panel"
      >
        <QuestionRightPanel
          selectedOrg={selectedOrg}
          setTab={setTab}
          questionDetail={questionDetail}
          questionId={questionId}
          textQuestion={textQuestion}
          onSuccess={() => onAddAnswer()}
          refreshOtherAnswers={refreshOtherAnswers}
          arrOrganisation={arrOrganisation}
          setQuestionPoints={setQuestionPoints}
          questionPoints={questionPoints}
          initSection={section}
        />
      </div>
    );
  };

  const renderSnackbar = () => {
    return (
      <div className="snackbar">
        <AlertCheckIcon />
        <div className="alert-message font-bold font15">
          Successfully post answer on org
        </div>
      </div>
    );
  };

  const renderBody = () => {
    if (tab == 'detail')
      return (
        <div className="row question_detail">
          {isFollowedOrg === 'true' && isLoaded
            ? renderRightPanel()
            : renderNoPermission()}
          {isShowSnackBar && renderSnackbar()}
        </div>
      );
    if (tab == 'addAnswer')
      return (
        <AddAnswer
          onAddAnswer={() => onAddAnswer()}
          showSnackBar={() => showSnackBar()}
          questionDetail={questionDetail}
          setTab={setTab}
        />
      );
  };

  const renderSelectedOrgBar = () => {
    return (
      <div className="selected_org_top">
        <div className="selected_org_top_info">
          <img src={orgDetail?.image} className="selected_org_top_info_img" />
          <div className="selected_org_top_info_text">
            <div className="selected_org_top_info_text_title font-bold font32">
              {orgDetail?.name}
            </div>
            <div className="selected_org_top_info_text_subTitle font20 font-normal">
              {orgDetail?.description ? orgDetail?.description : ''}
            </div>
          </div>
        </div>
        <div className="selected_org_top_members">
          <div style={{ fontSize: 26 }}>🙋</div>
          <div className="font-bold font16">
            {orgDetail?.member_number} members
          </div>
        </div>
      </div>
    );
  };

  const renderNoPermission = () => {
    return (
      <div
        className="no_permission"
        style={{ height: `calc(100%)`, overflowY: 'auto' }}
      >
        {renderSelectedOrgBar()}
        <div className="no_permission_body">
          <div style={{ fontSize: 96 }}>🚧</div>
          <div className="font-bold font24">
            Oh oh... You are not part of this org
          </div>
          <div
            className="font-regular font16"
            style={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'center' }}
          >
            If you want to see what inside, you can send a <br />
            request to join and wait for approval
          </div>
          {isFollowedOrg === 'requested'
            ? renderRquestedBtn()
            : renderRequestBtn()}
        </div>
      </div>
    );
  };

  const renderRequestBtn = () => {
    return (
      <div className="request_btn clickable" onClick={onRequestJoin}>
        {isJoining ? (
          <LoadingCircular size={20} color="white" />
        ) : (
          <div className="row_align_items">
            <EnvelopStarIcon />
            <div
              className="font-bold font14"
              style={{ color: 'white', marginLeft: 8 }}
            >
              Request to join
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRquestedBtn = () => {
    return (
      <div className="requested_btn clickable">
        <div className="row_align_items">
          <div className="font-bold font14" style={{ color: 'black' }}>
            Requested
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div style={{ marginTop: 100 }}>
        <LoadingBarAnimation />
      </div>
    );
  } else {
    return <>{renderBody()}</>;
  }
};
