import CloseIcon from 'assets/svgs/CloseIcon';
import React, { useEffect, useState } from 'react';
import { apiGetQAAnswerWith, apiGetQAWith, apiQATag } from 'shared/api/qa';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import InfiniteScrollWrapper from './Wrapper';

import './index.styles.scss';
import { CardQuestionGroup } from './CardQuestionGroup';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { apiQAShareAnswer } from 'shared/api/qa';
import { apiExtensionShareWith, apiExtensionTag } from 'shared/api/extension';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface SendDumpModalProps {
  bookmarkId: any;
  show: boolean;
  type: 'answer' | 'link' | 'mobile_answer' | 'file';
  item_id: any;
  item_type: 'user' | 'group' | 'organisation';
  answerId?: any;
  questionId?: any;
  modalWidth?: string;
  onClose: () => void;
  onSuccess: (groupData) => void;
}

export const SendDumpModal = ({
  bookmarkId,
  show,
  type,
  item_id,
  item_type,
  answerId,
  questionId,
  modalWidth,
  onClose,
  onSuccess
}: SendDumpModalProps) => {
  const [searchTxt, setSearchTxt] = useState('');
  const [isSearchedResult, setIsSearchedResult] = useState(false);

  const [tab, setTab] = useState<'user' | 'group' | 'organisation'>(item_type);

  const [arrData, setArrData] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [isSending, setIsSending] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Array<any>>([]);

  useEffect(() => {
    if (show) {
      loadData();
    } else {
      setArrData([]);
      setSelectedQuestion([]);
    }
  }, [show, tab]);

  const loadData = async (init = true, keyword = '') => {
    setIsSearchedResult(false);
    if (init) {
      setIsLoading(true);

      let res: any = null;

      if (type === 'link') {
        res = await apiExtensionShareWith({
          browser: bookmarkId,
          type: tab,
          query: keyword,
          limit: 10,
          offset: 0
        });
      } else if (type === 'file') {
        res = await apiGetQAWith({
          dump: bookmarkId,
          type: tab,
          query: keyword,
          limit: 10,
          offset: 0
        });
      } else if (type === 'answer') {
        res = await apiGetQAAnswerWith({
          bookmark: bookmarkId,
          type: tab,
          query: keyword,
          limit: 10,
          offset: 0
        });
      } else if (type === 'mobile_answer') {
        res = await apiGetQAAnswerWith({
          answer: bookmarkId,
          type: tab,
          query: keyword,
          limit: 10,
          offset: 0
        });
      }

      if (res.success) {
        setArrData(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }
      setIsLoading(false);
    } else {
      if (hasMore && !isLoading) {
        setIsLoading(true);

        let res: any = null;

        if (type === 'link' || type === 'file') {
          res = await apiGetQAWith({
            dump: bookmarkId,
            type: tab,
            query: keyword,
            limit: 10,
            offset: lastId
          });
        } else if (type === 'answer') {
          res = await apiGetQAAnswerWith({
            bookmark: bookmarkId,
            type: tab,
            query: keyword,
            limit: 10,
            offset: lastId
          });
        } else if (type === 'mobile_answer') {
          res = await apiGetQAAnswerWith({
            answer: bookmarkId,
            type: tab,
            query: keyword,
            limit: 10,
            offset: lastId
          });
        }

        if (res.success) {
          setArrData((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
        setIsLoading(false);
      }
    }
  };

  const onSend = async () => {
    if (selectedQuestion.length > 0 && !isSending) {
      setIsSending(true);
      const aiIds = selectedQuestion.map((item, _) => item.aiId);
      const aiTypes = selectedQuestion.map((item, _) => item.aiType);

      const arrTags = selectedQuestion.map((item, _) => {
        return { id: item.aiId, type: item.aiType };
      });

      let res: any = null;
      if (type === 'link') {
        res = await apiExtensionTag({
          browser: item_id,
          tags: arrTags
        });
      } else if (type === 'answer') {
        res = await apiQAShareAnswer({
          bookmark: bookmarkId,
          ai_ids: aiIds,
          ai_types: aiTypes
        });
      } else if (type === 'mobile_answer') {
        res = await apiQAShareAnswer({
          answer: answerId,
          question: questionId,
          ai_ids: aiIds,
          ai_types: aiTypes
        });
      } else if (type === 'file') {
        res = await apiQATag({
          dump: bookmarkId,
          tags: arrTags
        });
      }

      if (res.success) {
        showToast('success', 'Successfully sent!');
        setSelectedQuestion([]);
        onClose();
      }

      setIsSending(false);
    }
  };

  const onSearch = (sT: string) => {
    setSearchTxt(sT);
    if (sT === '') {
      loadData(true, sT);
    } else {
      loadData(true, sT);
    }
  };

  const onNext = () => {
    loadData(false);
  };

  const onItemClicked = (aiId, aiType) => {
    if (
      selectedQuestion.filter(
        (item, _) => item.aiId === aiId && item.aiType === aiType
      ).length > 0
    ) {
      setSelectedQuestion((prev) =>
        prev.filter(
          (item, index) => !(item.aiId === aiId && item.aiType === aiType)
        )
      );
    } else {
      setSelectedQuestion((prev) => [
        ...prev,
        {
          aiId,
          aiType
        }
      ]);
    }
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items">
        <div
          className="clickable"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon color="#000000" />
        </div>
        <div className="send_modal_title font-bold font16">
          {type === 'answer' ? 'Send Question' : 'Send Link'}
        </div>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className="send_tab_container row_align_items">
        <div
          className={
            tab === 'user'
              ? 'selected_tab_item' + ' font-bold font14 clickable'
              : 'default_tab_item' + ' font-bold font14 clickable'
          }
          onClick={() => setTab('user')}
        >
          Friends
        </div>
        <div
          className={
            tab === 'group'
              ? 'selected_tab_item' + ' font-bold font14 clickable'
              : 'default_tab_item' + ' font-bold font14 clickable'
          }
          onClick={() => setTab('group')}
        >
          Groups
        </div>
        <div
          className={
            tab === 'organisation'
              ? 'selected_tab_item' + ' font-bold font14 clickable'
              : 'default_tab_item' + ' font-bold font14 clickable'
          }
          onClick={() => setTab('organisation')}
        >
          Schools
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div style={{ marginTop: 24 }}>
        <SearchBox
          onSearch={onSearch}
          fontSize={14}
          placeholder="Search here..."
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="org_list" id="scrollableDiv">
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={arrData.length}
          functionNext={onNext}
          hasOrgsMore={hasMore}
          parentId="scrollableDiv"
        >
          <div className="card_org_list" id="scrollableDiv">
            {arrData.map((item, index) => {
              return (
                <CardQuestionGroup
                  key={`card_question_group_${index}`}
                  data={item}
                  isSelected={
                    selectedQuestion.filter(
                      (sItem, _) =>
                        item.to === sItem.aiId && item.type === sItem.aiType
                    ).length > 0
                  }
                  onClick={onItemClicked}
                />
              );
            })}
          </div>
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderSendBtn = () => {
    return (
      <div style={{ paddingTop: 24 }}>
        <PrimaryButton
          label={
            type === 'answer'
              ? `Send Question (${selectedQuestion.length})`
              : `Send Link (${selectedQuestion.length})`
          }
          isLoading={isSending}
          onPressed={onSend}
        />
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={modalWidth ?? 500} onClose={onClose}>
      <div
        className="send_question_modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {renderHeader()}
        {renderTab()}
        {renderSearch()}
        {renderBody()}
        {renderSendBtn()}
      </div>
    </PrimaryModal>
  );
};
