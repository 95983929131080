import React, { useEffect, useState } from 'react';
import { apiFileQuestions, apiGetFileMarks } from 'shared/api/file';
import { DocumentAskQuestion } from './AskQuestion';
import Tooltip from '@mui/material/Tooltip';
import InfiniteScrollWrapper from 'components/Common/WrapperScroll';
import Columns from 'react-columns';

import './index.styles.scss';
import AnswerIcon from 'assets/svgs/AnswerIcon';
import { IconButton } from '@mui/material';
import NoteBookIcon from 'assets/svgs/NoteBookIcon';
import BoltIcon from 'assets/svgs/BoltIcon';
import LightIcon from 'assets/svgs/LightIcon';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { CardAskedQuestion } from './AskedQuestion/CardAskedQuestion';
import { CardSavedHighlight } from './SavedHighlight/CardSavedHighlight';
import { CardAnswer } from 'components/Main/Home/Bookmark/CardAnswer';
import { CardQuestion } from 'components/Main/Home/Bookmark/CardQuestion';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import {
  apiGetContentFlashCards,
  apiGetContentStatements
} from 'shared/api/content';

interface DocumentQuestionProps {
  show: boolean;
  dumpId: string;
  onClose: () => void;
  onViewHighLight: (highLightId) => void;
}

export const DocumentQuestion = ({
  show,
  dumpId,
  onClose,
  onViewHighLight
}: DocumentQuestionProps) => {
  const { hasNewHightLight } = useSelector((state: RootState) => state.app);
  const [searchText, setSearchText] = useState('');

  const [navItem, setNavItem] = useState<
    'question' | 'answer' | 'bookmark' | 'highlight'
  >('question');

  const [arrQuestion, setArrQuestion] = useState<Array<any>>([]);
  const [lastIdQuestion, setLastIdQuestion] = useState(0);
  const [hasMoreQuestion, setHasMoreQuestion] = useState(true);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);

  const [arrSuggestedQuestion, setArrSuggestedQuestion] = useState<Array<any>>(
    []
  );
  const [lastIdSuggestedQuestion, setLastIdSuggestedQuestion] = useState(0);
  const [hasMoreSuggestedQuestion, setHasMoreSuggestedQuestion] =
    useState(true);
  const [isLoadingSuggestedQuestion, setIsLoadingSuggestedQuestion] =
    useState(false);

  const [arrBookmark, setArrBookmark] = useState<Array<any>>([]);
  const [lastIdBookmark, setLastIdBookmark] = useState(0);
  const [hasMoreBookmark, setHasMoreBookmark] = useState(true);
  const [isLoadingBookmark, setIsLoadingBookmark] = useState(false);

  const [arrStatement, setArrStatement] = useState<Array<any>>([]);
  const [lastIdStatement, setLastIdStatement] = useState(0);
  const [hasMoreStatement, setHasMoreStatement] = useState(true);
  const [isLoadingStatement, setIsLoadingStatement] = useState(false);

  useEffect(() => {
    loadQuestion();
    loadBookmark();
    loadSuggestedQuestion();
    loadStatement();
  }, [searchText]);

  useEffect(() => {
    loadBookmark();
  }, [hasNewHightLight]);

  useEffect(() => {
    if (show) {
      onGoToQuestion();
    }
  }, [show]);

  const loadQuestion = async (init = true) => {
    if (init) {
      setIsLoadingQuestion(true);
      const res = await apiFileQuestions({
        dump: dumpId,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrQuestion(res.data);
        setLastIdQuestion(res.lastId);
        setHasMoreQuestion(res.hasMore);
      }

      setIsLoadingQuestion(false);
    } else {
      if (!isLoadingQuestion && hasMoreQuestion) {
        setIsLoadingQuestion(true);

        const res = await apiFileQuestions({
          dump: dumpId,
          limit: 10,
          offset: lastIdQuestion
        });

        if (res.success) {
          setArrQuestion((prev) => [...prev, ...res.data]);
          setLastIdQuestion(res.lastId);
          setHasMoreQuestion(res.hasMore);
        }

        setIsLoadingQuestion(false);
      }
    }
  };

  const loadSuggestedQuestion = async (init = true) => {
    if (init) {
      setIsLoadingSuggestedQuestion(true);

      const res = await apiGetContentFlashCards({
        query: searchText,
        dump: dumpId,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrSuggestedQuestion(res.data);
        setLastIdSuggestedQuestion(res.lastId);
        setHasMoreSuggestedQuestion(res.hasMore);
      }

      setIsLoadingSuggestedQuestion(false);
    } else {
      if (hasMoreSuggestedQuestion && !isLoadingSuggestedQuestion) {
        setIsLoadingSuggestedQuestion(true);
        const res = await apiGetContentFlashCards({
          query: searchText,
          dump: dumpId,
          limit: 10,
          offset: lastIdSuggestedQuestion
        });

        if (res.success) {
          setArrSuggestedQuestion((prev) => [...prev, ...res.data]);
          setLastIdSuggestedQuestion(res.lastId);
          setHasMoreSuggestedQuestion(res.hasMore);
        }

        setIsLoadingSuggestedQuestion(false);
      }
    }
  };

  const loadBookmark = async (init = true) => {
    if (init) {
      setIsLoadingBookmark(true);

      const res = await apiGetFileMarks({
        dump: dumpId,
        query: searchText,
        offset: 0,
        limit: 10
      });

      if (res.success) {
        setArrBookmark(res.data);
        setLastIdBookmark(res.lastId);
        setHasMoreBookmark(res.hasMore);
      }
      setIsLoadingBookmark(false);
    } else {
      if (hasMoreBookmark && !isLoadingBookmark) {
        setIsLoadingBookmark(true);

        const res = await apiGetFileMarks({
          dump: dumpId,
          query: searchText,
          offset: lastIdBookmark,
          limit: 10
        });

        if (res.success) {
          setArrBookmark((prev) => [...prev, ...res.data]);
          setLastIdBookmark(res.lastId);
          setHasMoreBookmark(res.hasMore);
        }
        setIsLoadingBookmark(false);
      }
    }
  };

  const loadStatement = async (init = true) => {
    if (init) {
      setIsLoadingStatement(true);
      const res = await apiGetContentStatements({
        dump: dumpId,
        limit: 10,
        offset: 0
      });
      if (res.success) {
        setArrStatement(res.data);
        setLastIdStatement(res.lastId);
        setHasMoreStatement(res.hasMore);
      }
      setIsLoadingStatement(false);
    } else {
      if (!isLoadingStatement && hasMoreStatement) {
        setIsLoadingStatement(true);

        const res = await apiGetContentStatements({
          dump: dumpId,
          limit: 10,
          offset: lastIdStatement
        });
        if (res.success) {
          setArrStatement((prev) => [...prev, ...res.data]);
          setLastIdStatement(res.lastId);
          setHasMoreStatement(res.hasMore);
        }

        setIsLoadingStatement(false);
      }
    }
  };

  const onNext = () => {
    if (navItem === 'question') loadQuestion(false);
    else if (navItem === 'answer') loadStatement(false);
    else if (navItem === 'bookmark') loadSuggestedQuestion(false);
    else if (navItem === 'highlight') loadBookmark(false);
  };

  const onSearch = (st: string) => {
    setSearchText(st);
  };

  const onGoToQuestion = () => {
    const questionView = document.getElementById('document_question');
    questionView?.scrollIntoView();
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      onNext();
    }
  };

  const renderAskQuestion = () => {
    return (
      <DocumentAskQuestion
        dumpId={dumpId}
        onAddQuestion={(newQuestion) => {
          setArrQuestion((prev) => [newQuestion, ...prev]);
        }}
      />
    );
  };

  const renderBody = () => {
    return (
      <div className="question_body">
        <div className="row_space_between row_align_items">
          {renderNavbar()}
          <SearchBox
            onSearch={onSearch}
            width={'300px'}
            height={'44px'}
            padding={'1px 16px'}
            fontSize={14}
          />
        </div>
        <div>
          {navItem === 'question' && renderFileQuestionTab()}
          {navItem === 'answer' && renderStatementTab()}
          {navItem === 'bookmark' && renderSuggestedQuestionTab()}
          {navItem === 'highlight' && renderFileBookmarkTab()}
        </div>
      </div>
    );
  };

  const renderNavbar = () => {
    return (
      <div className="bookmark_navbar">
        <div
          className={
            navItem === 'question'
              ? 'bookmark_navbar_item_selected'
              : 'bookmark_navbar_item'
          }
          onClick={() => setNavItem('question')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">
                Bookmark your favourite answers to view later
              </div>
            }
          >
            <IconButton>
              <NoteBookIcon color={navItem === 'question' ? '#fff' : '#000'} />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={
            navItem === 'answer'
              ? 'bookmark_navbar_item_selected'
              : 'bookmark_navbar_item'
          }
          onClick={() => setNavItem('answer')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">
                Statements with short insights from your knowledge base
              </div>
            }
          >
            <IconButton>
              <AnswerIcon color={navItem === 'answer' ? '#fff' : '#000'} />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={
            navItem === 'bookmark'
              ? 'bookmark_navbar_item_selected'
              : 'bookmark_navbar_item'
          }
          onClick={() => setNavItem('bookmark')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">
                Flip cards help you quickly remember ideas from knowledge base
              </div>
            }
          >
            <IconButton>
              <BoltIcon color={navItem === 'bookmark' ? '#fff' : '#000'} />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={
            navItem === 'highlight'
              ? 'bookmark_navbar_item_selected'
              : 'bookmark_navbar_item'
          }
          onClick={() => setNavItem('highlight')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">
                Highlight important sections to tune insights for focused AI
                summary and chat.
              </div>
            }
          >
            <IconButton>
              <LightIcon color={navItem === 'highlight' ? '#fff' : '#000'} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderFileQuestionTab = () => {
    return (
      <div style={{ marginTop: '32px' }} onScroll={handleScroll}>
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={arrQuestion.length}
          functionNext={onNext}
          hasOrgsMore={hasMoreQuestion}
          parentId="home_body"
        >
          {arrQuestion.length > 0
            ? arrQuestion.map((item, index) => {
                return (
                  <CardAskedQuestion
                    key={`card_asked_question_${index}`}
                    data={item}
                  />
                );
              })
            : renderEmptyView()}
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderSuggestedQuestionTab = () => {
    return (
      <div style={{ marginTop: '32px' }} onScroll={handleScroll}>
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={arrQuestion.length}
          functionNext={onNext}
          hasOrgsMore={hasMoreQuestion}
          parentId="home_body"
        >
          {arrSuggestedQuestion.length > 0 ? (
            <Columns
              columns={4}
              gap={'24px'}
              rootStyles={{ paddingBottom: 10 }}
            >
              {arrSuggestedQuestion.map((item, index) => {
                return (
                  <CardQuestion
                    data={item}
                    key={`card_suggested_question_key${index}`}
                  />
                );
              })}
            </Columns>
          ) : (
            renderEmptyView()
          )}
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderFileBookmarkTab = () => {
    return (
      <div style={{ marginTop: '32px' }} onScroll={handleScroll}>
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={arrBookmark.length}
          functionNext={onNext}
          hasOrgsMore={hasMoreBookmark}
          parentId="home_body"
        >
          {arrBookmark.length > 0
            ? arrBookmark.map((item, index) => {
                return (
                  <CardSavedHighlight
                    key={`card_saved_highlight_${index}`}
                    data={item}
                    onClicked={onViewHighLight}
                  />
                );
              })
            : renderEmptyView()}
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderStatementTab = () => {
    return (
      <div style={{ marginTop: '32px' }} onScroll={handleScroll}>
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={arrStatement.length}
          functionNext={onNext}
          hasOrgsMore={hasMoreStatement}
          parentId="home_body"
        >
          {arrStatement.length > 0 ? (
            <Columns
              columns={2}
              gap={'24px'}
              rootStyles={{ paddingBottom: 10 }}
            >
              {arrStatement.map((item, index) => {
                return (
                  <CardAnswer
                    data={item}
                    key={`card_statement_key${index}`}
                    index={index + 1}
                  />
                );
              })}
            </Columns>
          ) : (
            renderEmptyView()
          )}
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderEmptyView = () => {
    return (
      <div className="document_question_empty_view" style={{ marginTop: 30 }}>
        <div style={{ fontSize: 100, textAlign: 'center' }}>🔖</div>
        <div
          className="font-bold font24"
          style={{ marginTop: 16, textAlign: 'center' }}
        >
          {navItem === 'bookmark'
            ? 'No flip cards generated yet'
            : navItem === 'answer'
            ? 'No statements generated yet'
            : navItem === 'question'
            ? 'No bookmarked answers yet'
            : navItem === 'highlight'
            ? 'No hightlights yet'
            : ''}
        </div>
        <div
          className="font-regular font18"
          style={{ marginTop: 8, textAlign: 'center' }}
        >
          {navItem === 'bookmark'
            ? 'Flip cards with questions and their answers will be generated automatically'
            : navItem === 'answer'
            ? 'hort statements with insights will be automatically generated'
            : navItem === 'question'
            ? 'Set bookmarks for your favourite answer'
            : navItem === 'highlight'
            ? 'Highlight important sections to get insights'
            : ''}
          <br />
          {navItem === 'bookmark'
            ? 'after you upload to your knowledge base'
            : navItem === 'answer'
            ? 'after you upload to your knowledge base'
            : navItem === 'question'
            ? 'to collect and display them here'
            : navItem === 'highlight'
            ? 'for an improved AI summary and chat.'
            : ''}
        </div>
      </div>
    );
  };

  if (show) {
    return (
      <div className="document_question" id="document_question">
        {renderAskQuestion()}
        {renderBody()}
      </div>
    );
  } else {
    return <div />;
  }
};
