import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import LineSpacingIcon from 'assets/svgs/LineSpacingIcon';
import SingleFileIcon from 'assets/svgs/SingleFileIcon';
import WholeDocIcon from 'assets/svgs/WholeDocIcon';
import React, { useState } from 'react';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';

import './index.styles.scss';

interface SummarizeModalPrpos {
  show: boolean;
  selectedData: any;
  posX: number;
  posY: number;
  onClose: () => void;
  onDone: (type: 'selection' | 'whole' | 'custom', fromPage, toPage) => void;
}

export const SummarizeModal = ({
  show,
  selectedData,
  posX,
  posY,
  onClose,
  onDone
}: SummarizeModalPrpos) => {
  const [showCustomPage, setShowCustomPage] = useState(false);
  const [startPage, setStartPage] = useState('1');
  const [endPage, setEndPage] = useState('2');

  const onCustomPageSumarize = () => {
    if (!!startPage && !!endPage && parseInt(startPage) < parseInt(endPage)) {
      onDone('custom', parseInt(startPage), parseInt(endPage));
    }
  };

  const renderPageInput = () => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="input_pages">
          <div className="input_page">
            <input
              value={startPage}
              type="number"
              className="input_body border_less_input"
              onChange={(e) => {
                setStartPage(e.target.value);
              }}
            />
          </div>
          <div
            className="font-medium font16"
            style={{ color: 'rgba(0, 0, 0, 0.3)', margin: '0px 8px' }}
          >
            to
          </div>
          <div className="input_page">
            <input
              value={endPage}
              type="number"
              className="input_body border_less_input"
              onChange={(e) => {
                setEndPage(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: 16 }}>
          <PrimaryButton
            label="Summarize"
            fontSize={14}
            isLoading={false}
            onPressed={onCustomPageSumarize}
          />
        </div>
      </div>
    );
  };

  if (show) {
    return (
      <div className="summarize_modal_custom_body" onClick={onClose}>
        <div
          className="dlg_summarize"
          id="dlg_summarize"
          style={{
            left: posX,
            top: showCustomPage ? posY - 50 : posY,
            position: 'fixed'
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className="item_container clickable"
            style={{ opacity: selectedData ? 1 : 0.4 }}
            onClick={(e) => {
              e.stopPropagation();
              selectedData && onDone('selection', 0, 0);
            }}
          >
            <LineSpacingIcon size={24} />
            <div className="item_text font-medium font14">From selection</div>
            <AngleRightIcon />
          </div>
          <div className="item_divider" />

          <div
            className="item_container clickable"
            onClick={(e) => {
              e.stopPropagation();
              onDone('whole', 0, 0);
            }}
          >
            <WholeDocIcon size={24} />
            <div className="item_text font-medium font14">Whole doc</div>
            <AngleRightIcon />
          </div>
          {/* <div className="item_divider" />

          <div
            className="item_container clickable"
            onClick={(e) => {
              e.stopPropagation();
              setShowCustomPage(true);
            }}
          >
            <SingleFileIcon size={24} />
            <div className="item_text font-medium font14">Custom page</div>
            <AngleRightIcon />
          </div> */}

          {showCustomPage && renderPageInput()}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};
