import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import CloseIcon from 'assets/svgs/CloseIcon';
import LightBulbIcon from 'assets/svgs/LightBulbIcon';
import AttachmentIcon from 'assets/svgs/AttachmentIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import Download1Icon from 'assets/svgs/Download1Icon';
import WhatsappIcon from 'assets/svgs/WhatsappIcon';
import TelegramIcon from 'assets/svgs/TelegramIcon';
import InstagramIcon from 'assets/svgs/InstagramIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { getImageFromHtml, testHtmlCanvas } from 'shared/utils/utils';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { apiGetUserSharingQR } from 'shared/api/user';

import './index.styles.scss';

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import { apiQAGetShareLink } from 'shared/api/qa';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import moment from 'moment';
import UCoinIcon from 'assets/svgs/UCoinsIcon';

interface QuestionAnswerShareModalProps {
  show: boolean;
  selectedOrg?: any;
  selectedQuestionDetail?: any;
  question?: any;
  answer?: any;
  showAnswer?: boolean;
  type: 'bookmarked' | 'question' | 'answer';
  onClose: () => void;
}

export const QuestionAnswerShareModal = ({
  show,
  selectedOrg,
  selectedQuestionDetail,
  type,
  question,
  showAnswer = true,
  onClose
}: QuestionAnswerShareModalProps) => {
  const { uploadBase64 } = useIPFS();

  const [shareUrl, setShareUrl] = useState('');
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [isLoadingQrcode, setIsLoadingQRcode] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { userInfo } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (show) {
      getSharingQRcode();
    }
  }, [selectedQuestionDetail, question, show]);

  const getSharingQRcode = async () => {
    setIsLoadingQRcode(true);
    const res = await apiGetUserSharingQR();

    if (res.success) {
      setQRCodeUrl(res.qr_url);
      setIsLoadingQRcode(false);
      initShareUrl();
    }
  };

  const initShareUrl = async () => {
    if (show) {
      if (type === 'bookmarked')
        if (selectedQuestionDetail) {
          const base64Image = await getImageFromHtml('statement_share_body');
          const url = await uploadBase64(base64Image, 'image/png', '');

          const res = await apiQAGetShareLink({
            url: url,
            item_id: selectedQuestionDetail!.id,
            type: 'statement'
          });

          if (res.success) {
            setShareUrl(res.sharing_url);
          }
        }
      if (type === 'question' || type === 'answer')
        if (question) {
          const base64Image = await getImageFromHtml('statement_share_body');
          const url = await uploadBase64(base64Image, 'image/png', '');

          const res = await apiQAGetShareLink({
            url: url,
            item_id: question!.bookmark_id,
            type: 'statement'
          });

          if (res.success) {
            setShareUrl(res.sharing_url);
          }
        }
    }
  };

  const onShare = async (type: 'download') => {
    if (!isDownloading)
      if (type === 'download') {
        setIsDownloading(true);
        const base64Image = await getImageFromHtml('statement_share_body');
        const url = await uploadBase64(base64Image, 'image/png', '');

        await onDownloadFile(url);
        setIsDownloading(true);
      }
  };

  const onDownloadFile = async (fileUrl) => {
    if (!isDownloading) {
      setIsDownloading(true);

      fetch(fileUrl)
        .then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'share_image';
            alink.click();
          });
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  };

  const renderBookmarkedContentBody = () => {
    return (
      <div className="question_card_body">
        <div className="statement_card_body_question">
          {selectedQuestionDetail?.question}
        </div>
        <div className="statement_card_body_answer">
          {selectedQuestionDetail?.ai_answer}
        </div>
      </div>
    );
  };

  const renderQuestionContentBody = () => {
    return (
      <div className="question_card_body">
        <div className="statement_card_body_question_only">
          {question?.question}
        </div>
        {showAnswer && (
          <div
            className="statement_card_body_answer font-regular font18"
            style={{ marginTop: 8 }}
          >
            {question?.top_answer_text ?? question?.ai_answer}
          </div>
        )}
      </div>
    );
  };
  const renderAnswerContentBody = () => {
    return (
      <>
        <div className="statement_card_bottom_user">
          <div className="statement_card_bottom_user_avatar">
            {question?.top_creator_image &&
            question?.top_creator_image.startsWith('https') ? (
              <img
                src={question?.top_creator_image}
                style={{ width: 24, height: 24, borderRadius: 24 }}
              />
            ) : (
              <DefaultAvatarIcon size={24} />
            )}
          </div>
          <div
            className="statement_card_bottom_user_name font16 font-regular"
            style={{ marginLeft: 6 }}
          >
            {question?.top_creator_name}
          </div>
        </div>
        <div className="question_card_body">
          <div className="statement_card_body_anwer_only">
            {question?.top_answer_text}
          </div>
        </div>
        <div className="date_points">
          <div className="font16 date">
            {moment(question.timestamp).format('DD MMM yyyy').toString()}
          </div>
          <div className="question_points">
            <UCoinIcon />
            &nbsp;
            {`${question.points} pt${question.points > 1 ? 's' : ''}`}
          </div>
        </div>
      </>
    );
  };
  const renderScanBookmarkedBody = () => {
    return (
      <div className="statement_card_bottom">
        <div className="statement_card_bottom_user">
          <div className="statement_card_bottom_user_avatar">
            {userInfo?.image && userInfo?.image.startsWith('https') ? (
              <img
                src={userInfo.image}
                style={{ width: `24px`, height: `24px`, borderRadius: `24px` }}
              />
            ) : (
              <DefaultAvatarIcon size={24} />
            )}
          </div>
          <div className="statement_card_bottom_user_name font16 font-normal">
            <span style={{ opacity: '0.3' }}>Bookmarked by </span>
            {userInfo?.name}
          </div>
        </div>
        <div className="statement_card_bottom_btns clickable">
          {isLoadingQrcode ? (
            <LoadingCircular color="main" size={60} />
          ) : (
            <>
              <div className="statement_card_bottom_btns_QR">
                {/* <QRCodeBlackIcon size={60} /> */}
                <img
                  src={qrCodeUrl}
                  style={{ width: '60px', height: '60px' }}
                />
              </div>
              {/* <div className="statement_card_bottom_btns_scan_me  text-white">
                <div className="font-bold" style={{ fontSize: 6 }}>
                  Scan Me
                </div>
              </div> */}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderScanQuestionBody = () => {
    return (
      <div className="statement_card_bottom">
        <div className="row_align_items">
          <div className="row_align_items">
            {userInfo?.image?.startsWith('https') ? (
              <img src={userInfo?.image} className="item_image" />
            ) : (
              <DefaultAvatarIcon size={28} />
            )}
            {selectedOrg && selectedOrg.image?.startsWith('https') && (
              <img
                src={selectedOrg.image}
                className="item_image"
                style={{ marginLeft: -6 }}
              />
            )}
          </div>
          <div className="font-bold font12" style={{ marginLeft: 4 }}>
            {userInfo?.name}
            {selectedOrg && (
              <span
                className="font-bold font12"
                style={{ opacity: 0.4, margin: '0px 4px' }}
              >
                in
              </span>
            )}
            {selectedOrg?.name}
          </div>
        </div>
        <div className="statement_card_bottom_btns clickable">
          {isLoadingQrcode ? (
            <LoadingCircular color="main" size={24} />
          ) : (
            <>
              <div className="statement_card_bottom_btns_QR">
                <img
                  src={qrCodeUrl}
                  style={{ width: '60px', height: '60px' }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };
  const renderScanAnswerBody = () => {
    return (
      <div className="statement_card_bottom">
        <div className="statement_card_bottom_question_info">
          <div className="statement_card_bottom_question_info_title font-bold font20">
            {question?.question}
          </div>
          <div className="statement_card_bottom_question_info_snack">
            <div className="snack">
              <div className="font14 question_info_icon">👀</div>
              <div className="font14 question_info_text">
                {`${question.views} view${question.views > 1 ? 's' : ''}`}
              </div>
            </div>
            <div className="snack">
              <div className="font14 question_info_icon">💬</div>
              <div className="font14 question_info_text">
                {`${question.answer_count} answer${
                  question.answer_count > 1 ? 's' : ''
                }`}
              </div>
            </div>
          </div>
        </div>
        <div className="statement_card_bottom_btns clickable">
          {isLoadingQrcode ? (
            <LoadingCircular color="main" size={60} />
          ) : (
            <>
              <div className="statement_card_bottom_btns_QR">
                {/* <QRCodeBlackIcon size={60} /> */}
                <img
                  src={qrCodeUrl}
                  style={{ width: '60px', height: '60px' }}
                />
              </div>
              {/* <div className="statement_card_bottom_btns_scan_me  text-white">
                <div className="font-bold" style={{ fontSize: 6 }}>
                  Scan Me
                </div>
              </div> */}
            </>
          )}
        </div>
      </div>
    );
  };
  const renderShareBody = () => {
    return (
      <div className="statement_link_bar">
        <div className="statement_link_bar_items">
          <div
            className="statement_link_bar_items_item download_itm clickable"
            onClick={() => onShare('download')}
          >
            {isDownloading ? (
              <LoadingCircular size={20} color={'white'} />
            ) : (
              <Download1Icon />
            )}
          </div>
          <div className="font10" style={{ marginTop: 4 }}>
            Download
          </div>
        </div>

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <WhatsappShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Whatsapp_itm">
                <WhatsappIcon />
              </div>
              <div className="font10">Whatsapp</div>
            </div>
          </WhatsappShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TelegramShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Telegram_itm ">
                <TelegramIcon />
              </div>
              <div className="font10">Telegram</div>
            </div>
          </TelegramShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <FacebookShareButton title="" url={shareUrl}>
            <div className="statement_link_bar_items">
              <div
                className="statement_link_bar_items_item Telegram_itm "
                style={{ padding: 0 }}
              >
                <FacebookIcon borderRadius={16} size={48} />
              </div>
              <div className="font10">Facebook</div>
            </div>
          </FacebookShareButton>
        )}

        {false ? (
          <LoadingCircular size={48} color="main" />
        ) : (
          <TwitterShareButton title={''} url={shareUrl}>
            <div className="statement_link_bar_items">
              <div className="statement_link_bar_items_item Twitter_itm">
                <TwitterIcon />
              </div>
              <div className="font10">Twitter</div>
            </div>
          </TwitterShareButton>
        )}
      </div>
    );
  };

  const renderBookmarkedBody = () => {
    return (
      <>
        <div className="statement_card" id="statement_share_body">
          {renderScanBookmarkedBody()}
          {renderBookmarkedContentBody()}
        </div>
        {renderShareBody()}
      </>
    );
  };
  const renderQuestionBody = () => {
    return (
      <>
        <div className="statement_card" id="statement_share_body">
          {renderScanQuestionBody()}
          {renderQuestionContentBody()}
        </div>
        {renderShareBody()}
      </>
    );
  };

  const renderAnswerBody = () => {
    return (
      <>
        <div className="statement_card" id="statement_share_body">
          {renderScanAnswerBody()}
          {renderAnswerContentBody()}
        </div>
        {renderShareBody()}
      </>
    );
  };

  const renderBody = () => {
    if (type === 'bookmarked') return renderBookmarkedBody();
    else if (type == 'question') return renderQuestionBody();
    else return renderAnswerBody();
  };

  return (
    <PrimaryModal show={show} width={510} onClose={onClose}>
      <div className="modal_share_statement">
        <div className="modal_share_statement_header">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon opacity={0.4} />
          </div>
          <div className="title font-bold font20">
            {type === 'bookmarked'
              ? 'Share Bookmarked Question'
              : type === 'question'
              ? 'Share Question'
              : 'Share Answer'}
          </div>
        </div>
        <div className="modal_share_statement_body">{renderBody()}</div>
      </div>
    </PrimaryModal>
  );
};
