import React, { useState } from 'react';
import './index.styles.scss';
import AllOrgIcon from 'assets/svgs/AllOrgIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import StarIcon from 'assets/svgs/StarIcon';
import BookMarkedIcon from 'assets/svgs/BookMarkedIcon';
import FoloderDownloadIcon from 'assets/svgs/FolderDownloadIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import {
  apiQABookMarkAnswer,
  apiQAUnBookMarkAnswer,
  apiQAVote
} from 'shared/api/qa';
import RobotIcon from 'assets/svgs/RobotIcon';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import moment from 'moment';
import UCoinIcon from 'assets/svgs/UCoinsIcon';
import { useNavigate } from 'react-router-dom';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import { apiContentVoteAI } from 'shared/api/content';
interface CardFileAnswerProps {
  filterType: string;
  data: any;
}
export const CardFileAnswer = ({ filterType, data }: CardFileAnswerProps) => {
  const navigate = useNavigate();

  const [likeCount, setLikeCount] = useState(data.likes);
  const [dislikeCount, setDislikeCount] = useState(data.dislikes);
  const [userVote, setUserVote] = useState(data.user_like);

  const [isBookmarking, setIsBookmarking] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(data.is_bookmarked === 1);
  const [answerPoints, setAnswerPoints] = useState(data.points);

  const onVote = async (vote: number) => {
    const res = await apiContentVoteAI({
      item_id: data.id,
      item_type: 'answer',
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      setLikeCount(voteData.likes);
      setDislikeCount(voteData.dislikes);
    }
  };

  const onBookmarkAnswer = async () => {
    if (!isBookmarking) {
      setIsBookmarking(true);

      if (isBookmarked) {
        const res = await apiQAUnBookMarkAnswer({
          answer: data.id
        });

        if (res.success) {
          setIsBookmarked(false);
        }
      } else {
        const res = await apiQABookMarkAnswer({
          answer: data.id,
          item_id: undefined,
          item_type: 'user'
        });

        if (res.success) {
          setIsBookmarked(true);
        }
      }

      setIsBookmarking(false);
    }
  };

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const renderTopView = () => {
    return (
      <div className="answer_box_top">
        <div className="answer_box_top_user">
          {renderCreatorCreatorInfo()}
          {renderPlaceInfo()}
        </div>

        <div className="answer_box_top_right">
          <div className="answer_date font16" style={{ marginRight: '28px' }}>
            {moment(data.timestamp).format('DD MMM yyyy').toString()}
          </div>
        </div>
      </div>
    );
  };
  const renderCreatorCreatorInfo = () => {
    if (data.is_anonymous === 1) {
      return (
        <div className="align-vertical-center">
          <DefaultAvatarIcon size={24} />
          <div
            className="font-medium font16 user_name"
            style={{ marginLeft: 8 }}
          >
            Anonymous
          </div>
        </div>
      );
    } else {
      if (data.creator_id) {
        return (
          <div
            className="align-vertical-center clickable"
            onClick={() => onViewProfile()}
          >
            {data.creator_image ? (
              <img
                className="creator_image"
                src={data.creator_image}
                width="24"
                height="24"
                style={{ borderRadius: 24 }}
              ></img>
            ) : (
              <DefaultAvatarIcon size={24} />
            )}

            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {data.creator_name ? data.creator_name : 'Anonymous'}
            </div>
          </div>
        );
      } else {
        return (
          <div className="align-vertical-center">
            <RobotIcon />
            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {'General AI'}
            </div>
          </div>
        );
      }
    }
  };
  const renderPlaceInfo = () => {
    if (
      !data.ai_type ||
      data.ai_type === 'user' ||
      data.ai_type === 'general' ||
      !data.ai_id
    ) {
      return <div />;
    } else {
      return (
        <div className="align-vertical-center">
          <div className="font-medium font16 in_label">in</div>
          <img
            className="creator_image"
            src={data.ai_image}
            width="24"
            height="24"
            style={{ borderRadius: 24 }}
          ></img>
          <div className="font-medium font16 org_name">{data.ai_name}</div>
          <div className="font-medium font16 posted_question">
            posted a new question
          </div>
        </div>
      );
    }
  };

  const renderAnswer = () => {
    return (
      <div className="answer_media">
        <div className="answer_media_content">
          <PdfFileIcon size={72} />
          <div className="answer_media_content_txt">
            <div className="answer_media_content_txt_title font20 font-bold">
              {data.filename}
            </div>
            <div className="answer_media_content_txt_size font16">
              Size {data.size}MB
            </div>
          </div>
        </div>
        <a href={data.answer} style={{ textDecoration: 'none' }} target="blank">
          <div className="answer_media_download_btn">
            <FoloderDownloadIcon />
            <div className="answer_media_download_btn_txt font14 font-bold">
              Download
            </div>
          </div>
        </a>
      </div>
    );
  };
  const renderBottom = () => {
    return (
      <div className="answer_box_bottom">
        <div className="answer_box_bottom_left">
          <div className={'agree_btn clickable'} style={{ padding: 8 }}>
            <AudioPlayIcon color="#FF3E9A" />
          </div>
          <div
            className={
              userVote === 1
                ? 'agree_btn_highlighted clickable'
                : 'agree_btn clickable'
            }
            onClick={() => onVote(1)}
          >
            <div>{`\u2764`}</div>
            <div className="btn_number font-medium font12">{likeCount}</div>
          </div>
          <div
            className={
              userVote === 0
                ? 'agree_btn_highlighted clickable'
                : 'agree_btn clickable'
            }
            onClick={() => onVote(0)}
          >
            <div className="font-medium font18">{`\u{1F494}`}</div>
            <div className="btn_number font-medium font12">{dislikeCount}</div>
          </div>
        </div>
        {isBookmarking
          ? renderIsBookmarking()
          : isBookmarked
          ? renderBookmarkedBtn()
          : renderBookmarkBtn()}
      </div>
    );
  };
  const renderIsBookmarking = () => {
    return (
      <div className="bookmark_btn">
        <LoadingCircular color="main" size={20} />
      </div>
    );
  };

  const renderBookmarkBtn = () => {
    return (
      <div className="bookmark_btn clickable" onClick={onBookmarkAnswer}>
        <BookMarkedIcon color={'rgba(0, 0, 0, 0.4)'} />
        <div
          className="btn_label font-reqular font14"
          style={{ color: 'rgba(0, 0, 0)' }}
        >
          Bookmark this Answer
        </div>
      </div>
    );
  };

  const renderBookmarkedBtn = () => {
    return (
      <div className="bookmark_btn clickable" onClick={onBookmarkAnswer}>
        <BookMarkedIcon opacity="0.4" />
        <div className="btn_label font-reqular font14">
          Bookmarked this Answer
        </div>
      </div>
    );
  };

  return (
    <div style={filterType == 'text' ? { display: 'none' } : {}}>
      <div className="answer_box">
        {renderTopView()}
        {renderAnswer()}
        {renderBottom()}
      </div>
    </div>
  );
};
