import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useState } from 'react';
import moment from 'moment';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';
import ShareIcon from 'assets/svgs/ShareIcon';
import { ShareMemeModal } from 'components/Modals/ShareMemeModal';
import TrashIcon from 'assets/svgs/TrashIcon';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { apiDeletionMeme } from 'shared/api/delete';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface CardAllSharedDocsProp {
  data: any;
  selectedOrg: any;
  onClickedImage: (imgUrl) => void;
  onRemoveOnUI?: (id) => void;
}

export const CardAllSharedDocs = ({
  data,
  selectedOrg,
  onClickedImage,
  onRemoveOnUI
}: CardAllSharedDocsProp) => {
  const navigate = useNavigate();
  const [showShareModal, setShowShareModal] = useState(false);

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isRemoving, setIsRemoving] = useState(false);

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const onRemove = async () => {
    if (!isRemoving && onRemoveOnUI) {
      setIsRemoving(true);

      const res = await apiDeletionMeme({
        meme: data.id
      });

      if (res.success) {
        showToast('success', 'Deletion successful!');
        onRemoveOnUI && onRemoveOnUI(data.id);
      }

      setIsRemoving(false);
    }
  };

  const renderShareModal = () => {
    return (
      <ShareMemeModal
        show={showShareModal}
        data={data}
        selectedOrg={selectedOrg}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  return (
    <div className="card_all_shared_meme">
      {data.meme_url && data.meme_url.startsWith('https') ? (
        <img
          src={data.meme_url}
          className="meme_image clickable"
          onClick={() => {
            onClickedImage(data.meme_url);
          }}
        />
      ) : (
        <div className="meme_image"></div>
      )}
      <div style={{ flex: 1 }} />
      <div className="row_space_between" style={{ zIndex: 10 }}>
        <div className="row_align_items clickable" onClick={onViewProfile}>
          {data.creator_image && data.creator_image.startsWith('https') ? (
            <img src={data.creator_image} className="create_image" />
          ) : (
            <DefaultAvatarIcon size={32} />
          )}
          <div style={{ marginLeft: 8 }}>
            <div className="font-medium font10">{data.creator_name}</div>
            <div className="font-medium font10" style={{ opacity: 0.4 }}>
              {moment(data.timestamp).format('DD MMM')}
            </div>
          </div>
        </div>
        <div className="row_align_items" style={{ gap: 8 }}>
          <div
            className="detail_btn clickable"
            onClick={() => {
              setShowShareModal(true);
            }}
          >
            <ShareIcon color="rgba(0, 0, 0, 0.3)" />
          </div>
          {userInfo?.id == data.user && (
            <div className="detail_btn clickable" onClick={onRemove}>
              {isRemoving ? (
                <LoadingCircular size={16} color="main" />
              ) : (
                <TrashIcon />
              )}
            </div>
          )}
        </div>
      </div>
      {renderShareModal()}
    </div>
  );
};
