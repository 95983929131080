import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import './index.styles.scss';

import CloseIcon from 'assets/svgs/CloseIcon';
import { apiGetPendingMember } from 'shared/api/group';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { CardPendingMember } from './CardPendingMember';
import { SearchBox } from 'shared/ui-kit/SearchBox';

interface PendingMemberModalProps {
  show: boolean;
  onClose: () => void;
  onRefresh: () => void;
  groupId: string | undefined;
  pendingCount: number;
}

export const PendingMemberModal = ({
  show,
  groupId,
  pendingCount,
  onClose,
  onRefresh
}: PendingMemberModalProps) => {
  const [arrMember, setArrMember] = useState<Array<any>>([]);
  const [lastIdMember, setLastIdMember] = useState(0);
  const [hasMoreMember, setHasMoreMember] = useState(true);
  const [isLoadingMember, setIsLoadingMember] = useState(false);
  const [searchTxt, setSearchTxt] = useState('');
  const [isSearchBoxFocus, setIsSearchBoxFocus] = useState(false);
  const [modalpendingCount, setModalpendingCount] = useState(pendingCount);

  useEffect(() => {
    if (show) {
      loadData(true);
    }
  }, [show]);

  const loadData = async (init = false, keyword = '') => {
    if (init) {
      setIsLoadingMember(true);

      const res = await apiGetPendingMember({
        group: groupId,
        query: keyword,
        limit: 10,
        offset: 0
      });
      if (res.success) {
        setModalpendingCount(res.count);
        setArrMember(res.data);
        setLastIdMember(res.lastId);
        setHasMoreMember(res.hasMore);
      }
      setIsLoadingMember(false);
    } else {
      if (!isLoadingMember && hasMoreMember) {
        setIsLoadingMember(true);

        const res = await apiGetPendingMember({
          group: groupId,
          limit: 10,
          offset: lastIdMember
        });
        if (res.success) {
          setArrMember((prev) => [...prev, ...res.data]);
          setLastIdMember(res.lastId);
          setHasMoreMember(res.hasMore);
        }
        setIsLoadingMember(false);
      }
    }
  };

  const removeMemberOnUI = (memberId) => {
    onRefresh();
    setModalpendingCount((prev) => prev - 1);
    setArrMember((prev) => prev.filter((item, index) => item.id != memberId));
  };

  const onSearch = (sT: string) => {
    setSearchTxt(sT);
    loadData(true, sT);
  };

  return (
    <PrimaryModal show={show} width={500} onClose={onClose}>
      <div className="modal_pending_member">
        <div
          className="row_space_between row_align_items"
          style={{ marginBottom: 16 }}
        >
          <div className="row_align_items">
            <div className="align-vertical-center clickable" onClick={onClose}>
              <CloseIcon opacity={0.4} />
            </div>
            <div className="font-bold font20" style={{ marginLeft: 16 }}>
              {modalpendingCount} Pending Members
            </div>
          </div>
          <SearchBox
            onSearch={onSearch}
            onFocus={() => setIsSearchBoxFocus(true)}
            onBlur={() => setIsSearchBoxFocus(false)}
            fontSize={14}
            width={
              isSearchBoxFocus ? 'calc(100% - 270px)' : 'calc(100% - 370px)'
            }
          />
        </div>
        <div className="top_divide_line" />
        <div className="member_list">
          <InfiniteScroll
            dataLength={arrMember.length} //This is important field to render the next data
            next={() => loadData(false, searchTxt)}
            hasMore={hasMoreMember}
            loader={
              <div style={{ display: 'flex', margin: 'auto' }}>
                <LoadingCircular size={20} color="main" />
              </div>
            }
            refreshFunction={() => {}}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {arrMember.map((item, index) => {
              return (
                <CardPendingMember
                  key={`card_pending_member_${index}`}
                  data={item}
                  onRemove={removeMemberOnUI}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </PrimaryModal>
  );
};
