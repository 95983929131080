import PlusIcon from 'assets/svgs/PlusIcon';
import { CreateEventModal } from 'components/Modals/CreateEventModal';
import React, { useEffect, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useDispatch } from 'react-redux';
import { apiEventHosted } from 'shared/api/event';

import appAction from 'redux/app/action';

import { LeftArrow, RightArrow } from './ArrowBtns';
import { CardEventHosted } from './CardEventHosted';
import './index.styles.scss';

export const EventHosted = () => {
  const dispatch = useDispatch();

  const [arrEvent, setArrEvent] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [isLoaded, setIsLoaded] = useState(false);

  //create event modal
  const [isEventModalShow, setIsEventModalShow] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiEventHosted({
        query: '',
        orgs: null,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrEvent([null, ...res.data]);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }
    } else {
      const res = await apiEventHosted({
        query: '',
        orgs: null,
        limit: 10,
        offset: lastId
      });

      if (res.success) {
        setArrEvent((prev) => [...prev, ...res.data]);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }
    }
  };

  const onLoadMoreData = () => {
    loadData(false);
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const renderBody = () => {
    return (
      <div id="attachScrollableDiv" className="event_hosted_body">
        <ScrollMenu
          // LeftArrow={LeftArrow}
          // RightArrow={RightArrow}
          onWheel={onWheel}
          onUpdate={onLoadMoreData}
        >
          {arrEvent.map((item, index) => {
            if (item === null) {
              return renderCreateNew();
            } else {
              return (
                <CardEventHosted
                  key={`card_event_hosted_${index}`}
                  data={item}
                  onReloadData={() => {
                    loadData();
                    dispatch(appAction.refreshEventData());
                  }}
                />
              );
            }
          })}
        </ScrollMenu>
      </div>
    );
  };

  const renderCreateNew = () => {
    return (
      <div
        key={'create_event_btn'}
        className="create_btn clickable"
        onClick={() => setIsEventModalShow(true)}
      >
        <div>
          <PlusIcon />
          <div className="create_btn_txt font-regular font12">Create New</div>
        </div>
      </div>
    );
  };

  const renderCreateModal = () => {
    return (
      <CreateEventModal
        show={isEventModalShow}
        onClose={() => setIsEventModalShow(false)}
        onSuccess={() => {
          loadData();
          setIsEventModalShow(false);
          dispatch(appAction.refreshEventData());
        }}
      />
    );
  };

  const renderEmpty = () => {
    return (
      <div className="event_empty_view row_align_items row_space_between">
        <div className="row_align_items">
          <div style={{ fontSize: 40 }}>🗓</div>
          <div style={{ marginLeft: 24 }}>
            <div className="font-bold font16">No hosted event yet</div>
            <div className="font-regular font14" style={{ opacity: 0.6 }}>
              Host your own event with easel
            </div>
          </div>
        </div>
        <div
          className="create_event_btn font-bold font14 clickable"
          onClick={() => {
            setIsEventModalShow(true);
          }}
        >
          Create New Event
        </div>
      </div>
    );
  };

  return (
    <div className="event_hosted">
      <div className="font-bold font20" style={{ marginLeft: 16 }}>
        Hosted
      </div>
      {isLoaded && arrEvent.length < 2 ? renderEmpty() : renderBody()}
      {renderCreateModal()}
    </div>
  );
};
