import React from 'react';

function UploadAltIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? '16'}
      height={props.size ?? '16'}
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill={props.color ?? '#FF3E9A'}
        opacity={props.opacity ?? 1}
        d="M6.473 4.973l.86-.866v7.726a.667.667 0 101.334 0V4.107l.86.866a.666.666 0 001.092-.217.667.667 0 00-.146-.73l-2-2a.666.666 0 00-.946 0l-2 2a.67.67 0 10.946.947zM12 6.5h-1.333a.667.667 0 100 1.333H12a.667.667 0 01.667.667v4.667a.667.667 0 01-.667.666H4a.667.667 0 01-.667-.666V8.5A.667.667 0 014 7.833h1.333a.667.667 0 100-1.333H4a2 2 0 00-2 2v4.667a2 2 0 002 2h8a2 2 0 002-2V8.5a2 2 0 00-2-2z"
      ></path>
    </svg>
  );
}

export default UploadAltIcon;
