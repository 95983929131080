import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import { Reference } from 'components/Common/Reference';
import { ReferenceDetail } from 'components/Common/ReferenceDetail';
import React, { useMemo, useState } from 'react';
import { getArrFromString } from 'shared/utils/utils';

import moment from 'moment';

import './index.styles.scss';
import ChatSendIcon from 'assets/svgs/ChatSendIcon';
import LocationArrowIcon from 'assets/svgs/LocationArrowIcon';
import { SendDumpModal } from 'components/Modals/SendDumpModal';

export interface QuestionBookmarkData {
  id: number;
  user: number;
  question: string;
  to: number;
  to_type: string;
  ai_answer: string;
  context: string;
  timestamp: string;
  ref_names?: string;
  ref_images?: string;
  ref_filenames?: string;
  ref_titles?: string;
  creator_id: string;
  creator_image: string;
  creator_name: string;
  ref_urls?: string;
  ref_types?: string;
  ref_texts?: string;
  ref_count?: string;
  ref_ids: number;
  is_suggested: number;
  bookmark_id: number;
}

interface CardReceivedQuestionProps {
  data: QuestionBookmarkData;
}

export const CardReceivedQuestion = ({ data }: CardReceivedQuestionProps) => {
  const [isExpandReference, setIsExpandReference] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);

  const arrReferences = useMemo(() => {
    if (data.ref_names || data.ref_types) {
      const arrTmpRefData: Array<any> = [];

      // for user
      const arrTmpName = getArrFromString(data.ref_names, ';;;');
      const arrTmpImage = getArrFromString(data.ref_images, ';;;');

      // for files
      const arrTmpFileName = getArrFromString(
        data?.ref_titles ?? data?.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(data.ref_urls, ';;;');
      const arrTmpTxt = getArrFromString(data.ref_texts, ';;;');
      const arrTmpFileType = getArrFromString(data.ref_types, ';;;');

      arrTmpName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpName[index],
          image: arrTmpImage[index],
          text: arrTmpTxt[index],
          type: 'user'
        });
      });

      arrTmpFileName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpFileName[index],
          image: arrTmpFileUrl[index],
          text: arrTmpTxt[index],
          type: 'file',
          fileType: arrTmpFileType[index]
        });
      });

      if (!data.ref_filenames) {
        arrTmpFileUrl.map((item, index) => {
          arrTmpRefData.push({
            name: arrTmpFileUrl[index],
            image: arrTmpFileUrl[index],
            text: arrTmpTxt[index],
            type: 'file',
            fileType: arrTmpFileType[index]
          });
        });
      }

      // remove duplicated
      const uniqueArr = arrTmpRefData.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.image === value.image || t.name === value.name
          )
      );
      return uniqueArr;
    } else {
      return [];
    }
  }, [data]);

  const arrReferencesForBelow = useMemo(() => {
    if (data.ref_names || data.ref_types) {
      const arrTmpRefData: Array<any> = [];

      // for user
      const arrTmpName = getArrFromString(data.ref_names, ';;;');
      const arrTmpImage = getArrFromString(data.ref_images, ';;;');
      // for files
      const arrTmpFileName = getArrFromString(
        data?.ref_titles ?? data?.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(data.ref_urls, ';;;');
      const arrTmpTxt = getArrFromString(data.ref_texts ?? data.context, ';;;');
      const arrTmpFileType = getArrFromString(data.ref_types, ';;;');

      if (arrTmpTxt.length > 0) {
        arrTmpTxt.map((item, index) => {
          arrTmpRefData.push({
            name: arrTmpName[index],
            fileName: arrTmpFileName[index],
            image: arrTmpImage[index],
            url: arrTmpFileUrl[index],
            text: arrTmpTxt[index],
            fileType: arrTmpFileType[index]
          });
        });
      }

      return arrTmpRefData;
    } else {
      return [];
    }
  }, [data]);

  const renderQuestion = () => {
    return (
      <div className="row_space_between">
        <div className="question_bookmark_top_title">
          <div className="font-medium font26">{data.question}</div>
        </div>
        {/* <div className="border_btn clickable">
          <EditPencilColoredIcon />
        </div> */}
      </div>
    );
  };

  const renderAnswer = () => {
    return (
      <div
        className="font-regular font18"
        style={{ marginTop: 18, lineHeight: `32px` }}
      >
        <pre className="font18">{data.ai_answer}</pre>
      </div>
    );
  };

  const renderReferences = () => {
    return (
      <Reference
        data={arrReferences}
        showAddButton={false}
        setIsExpandReference={() => setIsExpandReference((prev) => !prev)}
        isExpandReference={isExpandReference}
      />
    );
  };

  const renderMiddle = () => {
    return (
      <div>
        <div className="bottom_bar row_space_between">
          {arrReferences.length > 0 ? renderReferences() : <div></div>}
          <div
            className="border_btn clickable"
            onClick={() => setShowSendModal(true)}
          >
            <LocationArrowIcon color="#FF3E9A" size={24} />
          </div>
        </div>
        {isExpandReference && <ReferenceDetail data={arrReferencesForBelow} />}
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="row_space_between" style={{ marginTop: 16 }}>
        <div className="row_align_items">
          {data.creator_image.startsWith('https') ? (
            <img
              src={data.creator_image}
              style={{ width: 24, height: 24, borderRadius: 24 }}
            />
          ) : (
            <DefaultAvatarIcon size={24} />
          )}

          <span
            className="font-medium font12"
            style={{ marginLeft: 8 }}
          >{`Received from ${data.creator_name}`}</span>
        </div>
        <div
          className="font-medium font12"
          style={{ color: 'rgba(0, 0, 0, 0.4)' }}
        >
          {moment(data.timestamp).fromNow()}
        </div>
      </div>
    );
  };

  const renderSendModal = () => {
    return (
      <SendDumpModal
        bookmarkId={data.bookmark_id}
        item_id={data.id}
        item_type={'user'}
        show={showSendModal}
        type="answer"
        onClose={() => setShowSendModal(false)}
        onSuccess={(groupData) => {}}
      />
    );
  };

  return (
    <div className="card_received_question">
      {renderQuestion()}
      {renderAnswer()}
      {renderMiddle()}
      {renderBottom()}
      {renderSendModal()}
    </div>
  );
};
