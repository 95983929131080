import React from "react";

function NoteBookIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      fill="none"
      viewBox="0 0 16 20"
    >
      <path
        fill={props.color}
        d="M11 4H5a1 1 0 00-1 1v4a1 1 0 001 1h6a1 1 0 001-1V5a1 1 0 00-1-1zm-1 4H6V6h4v2zm3-8H1a1 1 0 00-1 1v18a1 1 0 001 1h12a3 3 0 003-3V3a3 3 0 00-3-3zm1 17a1 1 0 01-1 1H2V2h11a1 1 0 011 1v14z"
      ></path>
    </svg>
  );
}

export default NoteBookIcon;