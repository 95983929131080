import CopyIcon from 'assets/svgs/CopyIcon';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import './index.styles.scss';
import { Popper, TextField } from '@mui/material';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { useDebounce } from 'use-debounce';
import { apiGetSearchedUser } from 'shared/api/user';
import { apiGroupSendInvitation } from 'shared/api/group';
import { showToast } from 'shared/ui-kit/PrimaryToast';
interface User {
  creator_id?: number;
  creator_name?: string;
  creator_email?: string;
}

export const GroupMemberTopView = ({ groupCode, groupId }) => {
  const [searchTxt, setSearchTxt] = useState('');
  const [debouncedKeyword] = useDebounce(searchTxt, 500);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly User[]>([]);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const [keyUpdate, setKeyUpdate] = useState(false);

  const shareLink = `${process.env.REACT_APP_WEB_URL}group/${groupCode}`;

  useEffect(() => {
    // load user data
    loadUser();
  }, [debouncedKeyword]);

  const loadUser = async () => {
    if (!isLoadingUser) {
      setIsLoadingUser(true);
      const res = await apiGetSearchedUser({
        query: searchTxt,
        full: 'false',
        limit: 20,
        offset: 0
      });

      if (res.success) {
        setOptions(res.data);
      }

      setIsLoadingUser(false);
    }
  };

  const onCopyCode = () => {
    navigator.clipboard.writeText(shareLink);

    showToast('success', 'Successfully copied!');
  };

  const onSendInvitation = async () => {
    if (!isSendingInvitation && selectedUser) {
      setIsSendingInvitation(true);

      const res = await apiGroupSendInvitation({
        group: groupId,
        toUser: selectedUser.creator_id!
      });

      if (res.success) {
        setKeyUpdate((prev) => !prev);
        showToast('success', 'Invitate sent successfully!');
      }
      setIsSendingInvitation(false);
    }
  };

  return (
    <div className="top_view">
      <div className="left_view">
        <div style={{ fontSize: 56 }}>💌</div>
        <div style={{ marginLeft: 24 }}>
          <div className="font-bold font20">Invite new members</div>
          <div className="font-regular font16" style={{ opacity: 0.6 }}>
            Invite anyone you want to share this space with
          </div>
        </div>
      </div>
      <div className="right_view">
        <div>
          <div className="font-bold font16" style={{ textAlign: 'center' }}>
            Copy and Share Group Link with code
          </div>
          <div
            className="sub_container"
            style={{
              width: 350,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 16
            }}
          >
            <div
              className="font-bold font16"
              style={{
                textAlign: 'center',
                width: 'calc(100% - 70px)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {shareLink.replace('https://', '')}
            </div>
            <div
              className="clickable"
              style={{ marginLeft: 16 }}
              onClick={onCopyCode}
            >
              <CopyIcon />
            </div>
          </div>
        </div>
        <div
          className="font-bold font16"
          style={{
            marginLeft: 24,
            marginRight: 24,
            color: 'rgba(0, 0, 0, 0.3)'
          }}
        >
          Or
        </div>
        <div>
          <div className="font-bold font16">Send Invite by Email</div>
          <div
            className="sub_container"
            style={{
              marginTop: 16,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              paddingLeft: 16,
              paddingTop: 12,
              paddingRight: 16
            }}
          >
            <div style={{ width: '100%', height: '100%' }}>
              <div className="font-bold font10" style={{ opacity: 0.4 }}>
                Member Email
              </div>
              <Autocomplete
                id="asynchronous-demo"
                sx={{ width: 300 }}
                key={keyUpdate === true ? 1 : 0}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={(event, value) => setSelectedUser(value!)}
                isOptionEqualToValue={(option, value) =>
                  selectedUser?.creator_email === value.creator_email &&
                  selectedUser?.creator_name === value.creator_name
                }
                getOptionLabel={(option) =>
                  option.creator_email ?? option.creator_name ?? ''
                }
                options={options}
                loading={isLoadingUser}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Enter email..."
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingUser ? (
                            <LoadingCircular color="main" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      style: {
                        fontFamily: 'Popin-Bold',
                        fontSize: 16
                      }
                    }}
                    onChange={(e) => {
                      setSearchTxt(e.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div
              className="send_invite_btn clickable"
              onClick={onSendInvitation}
            >
              {isSendingInvitation ? (
                <LoadingCircular color="white" size={24} />
              ) : (
                <div className="font-bold font14">Send</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const PopperMy = function (props) {
  return <Popper {...props} style={{ width: 300 }} placement="bottom-end" />;
};
