import React from 'react';

function RoundArrowRightIcon({ isEnable = false, size = 48 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 48 48"
    >
      <g opacity={isEnable ? 1 : '0.3'}>
        <rect width="48" height="48" fill="#fff" rx="24"></rect>
        <path
          fill={isEnable ? '#FF3E9A' : '#000000'}
          d="M26.83 23.29l-4.24-4.24a1 1 0 10-1.42 1.41L24.71 24l-3.54 3.54a1 1 0 000 1.41 1.001 1.001 0 00.71.29 1 1 0 00.71-.29l4.24-4.24a.999.999 0 000-1.42z"
        ></path>
        <rect
          width="47"
          height="47"
          x="0.5"
          y="0.5"
          stroke="#000"
          strokeOpacity={isEnable ? 0.1 : '0.5'}
          rx="23.5"
        ></rect>
      </g>
    </svg>
  );
}

export default RoundArrowRightIcon;
