import InstagramIcon from 'assets/svgs/InstagramIcon';
import TikTokIcon from 'assets/svgs/TikTokIcon';
import TwitterIcon from 'assets/svgs/TwitterIcon';
import React, { useMemo } from 'react';

import './index.styles.scss';

interface SocialMediaProps {
  showConnectButton: boolean;
  userInfo: any;
  onConnect: () => void;
}
export const SocialMedia = ({
  showConnectButton,
  userInfo,
  onConnect
}: SocialMediaProps) => {
  const isExistedTwitter = useMemo(() => {
    if (
      userInfo &&
      userInfo.socials.filter((item, index) => item.type === 'twitter').length >
        0
    ) {
      return true;
    } else {
      return false;
    }
  }, [userInfo]);

  const twitterHandleName = useMemo(() => {
    if (
      userInfo &&
      userInfo.socials.filter((item, index) => item.type === 'twitter').length >
        0
    ) {
      return userInfo.socials.filter(
        (item, index) => item.type === 'twitter'
      )[0].handle;
    } else {
      return '';
    }
  }, [userInfo]);

  const isExistedTikTok = useMemo(() => {
    if (
      userInfo &&
      userInfo.socials.filter((item, index) => item.type === 'tiktok').length >
        0
    ) {
      return true;
    } else {
      return false;
    }
  }, [userInfo]);

  const tiktokHandleName = useMemo(() => {
    if (
      userInfo &&
      userInfo.socials.filter((item, index) => item.type === 'tiktok').length >
        0
    ) {
      return userInfo.socials.filter((item, index) => item.type === 'tiktok')[0]
        .handle;
    } else {
      return '';
    }
  }, [userInfo]);

  const isExistedInstagram = useMemo(() => {
    if (
      userInfo &&
      userInfo.socials.filter((item, index) => item.type === 'instagram')
        .length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }, [userInfo]);

  const instagramHandleName = useMemo(() => {
    if (
      userInfo &&
      userInfo.socials.filter((item, index) => item.type === 'instagram')
        .length > 0
    ) {
      return userInfo.socials.filter(
        (item, index) => item.type === 'instagram'
      )[0].handle;
    } else {
      return '';
    }
  }, [userInfo]);

  const openSocialUrl = (type: 'twitter' | 'instagram' | 'tiktok') => {
    if (type === 'twitter') {
      window.open(`https://twitter.com/${twitterHandleName}`);
    } else if (type === 'instagram') {
      window.open(`https://www.instagram.com/${instagramHandleName}`);
    } else if (type === 'tiktok') {
      window.open(`https://www.tiktok.com/${tiktokHandleName}`);
    }
  };

  const renderBody = () => {
    return (
      <div className="profile_social_body">
        {isExistedInstagram && (
          <div
            className="social_item clickable"
            onClick={() => {
              openSocialUrl('instagram');
            }}
          >
            <div className="">
              <InstagramIcon color={'#FF3E9A'} size={32} />
            </div>
            <div className="social_item_divider" style={{ marginLeft: 16 }} />
            <div className="social_item_name font-regular font18">
              {instagramHandleName}
            </div>
          </div>
        )}
        {isExistedTikTok && (
          <div
            className="social_item clickable"
            onClick={() => {
              openSocialUrl('tiktok');
            }}
          >
            <div className="">
              <TikTokIcon color={'#333333'} size={36} />
            </div>
            <div className="social_item_divider" style={{ marginLeft: 16 }} />
            <div className="social_item_name font-regular font18">
              {tiktokHandleName}
            </div>
          </div>
        )}
        {isExistedTwitter && (
          <div
            className="social_item clickable"
            onClick={() => {
              openSocialUrl('twitter');
            }}
          >
            <div className="">
              <TwitterIcon color={'#2D9CDB'} size={32} />
            </div>
            <div className="social_item_divider" style={{ marginLeft: 16 }} />
            <div className="social_item_name font-regular font18">
              {twitterHandleName}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderConnect = () => {
    return (
      <div
        className="profile_social_connect clickable font-bold font18"
        onClick={onConnect}
      >
        Connect
      </div>
    );
  };

  const renderEmptyBody = () => {
    return (
      <div className="profile_social_body">
        <div className="social_item_icon">
          <InstagramIcon color="#FF3E9A" size={32} />
        </div>
        <div className="social_item_icon">
          <TikTokIcon color={'#333333'} size={36} />
        </div>
        <div className="social_item_icon">
          <TwitterIcon color={'#2D9CDB'} size={32} />
        </div>
        <div className="social_item_divider"></div>
        <div className="font-regular font18">No social media connected</div>
      </div>
    );
  };

  return (
    <div className="profile_social">
      {!isExistedInstagram && !isExistedTikTok && !isExistedTwitter
        ? renderEmptyBody()
        : renderBody()}
      {showConnectButton &&
        !isExistedInstagram &&
        !isExistedTikTok &&
        !isExistedTwitter &&
        renderConnect()}
    </div>
  );
};
