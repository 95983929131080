import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import ShareIcon from 'assets/svgs/ShareIcon';
import TrashIcon from 'assets/svgs/TrashIcon';
import React from 'react';

import './index.styles.scss';

interface Props {
  show: boolean;
  posX: number;
  posY: number;
  onClose: () => void;
  onSelected: (action: 'edit' | 'copy' | 'share' | 'delete') => void;
}

export const EventActionModal = ({
  show,
  posX,
  posY,
  onClose,
  onSelected
}: Props) => {
  if (show) {
    return (
      <div
        className="event_action_modal"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <div
          className="event_action_modal_body"
          style={{ left: posX, top: posY, position: 'fixed' }}
        >
          <div
            className="action_item row_align_items"
            onClick={(e) => {
              e.stopPropagation();
              onSelected('edit');
            }}
          >
            <div className="action_icon_view">
              <EditPencilColoredIcon size={16} />
            </div>
            <div className="action_name font-regular font16">Edit</div>
          </div>
          <div
            className="action_item row_align_items"
            onClick={(e) => {
              e.stopPropagation();
              onSelected('copy');
            }}
          >
            <div className="action_icon_view">
              <LinkIcon color="#FF3E9A" opacity={1} />
            </div>
            <div className="action_name font-regular font16">Copy Link</div>
          </div>
          <div
            className="action_item row_align_items"
            onClick={(e) => {
              e.stopPropagation();
              onSelected('share');
            }}
          >
            <div className="action_icon_view">
              <ShareIcon color="#FF3E9A" />
            </div>
            <div className="action_name font-regular font16">Share</div>
          </div>
          <div
            className="action_item row_align_items"
            onClick={(e) => {
              e.stopPropagation();
              onSelected('delete');
            }}
          >
            <div className="action_icon_view">
              <TrashIcon color={'rgba(0, 0, 0, 0.3)'} />
            </div>
            <div className="action_name font-regular font16">Delete</div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};
