import React, { useEffect, useState } from 'react';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import BookMarkedIcon from 'assets/svgs/BookMarkedIcon';
import {
  apiQAVote,
  apiQABookMarkAnswer,
  apiQAUnBookMarkAnswer
} from 'shared/api/qa';

import './index.styles.scss';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import moment from 'moment';
import UCoinIcon from 'assets/svgs/UCoinsIcon';
import { useNavigate } from 'react-router-dom';
import { removeFirstBlank } from 'shared/utils/utils';
import { apiContentVoteAI } from 'shared/api/content';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import TrashIcon from 'assets/svgs/TrashIcon';
import { apiDeletionAnswer } from 'shared/api/delete';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { showToast } from 'shared/ui-kit/PrimaryToast';
interface OtherAnswerData {
  id: number;
  user?: any;

  ai_id?: number;
  ai_type?: 'user' | 'general' | 'organisation' | 'group';
  ai_name?: string;
  ai_image?: string;
  timestamp: string;
  answer: string;
  creator_id?: number;
  creator_image?: string;
  creator_name?: string;
  creator_email?: string;
  user_vote?: number;
  1: number;
  2: number;
  3: number;
  is_anonymous: number;
  points: number;
  is_bookmarked: number;
  likes: number;
  dislikes: number;
  user_like: any;
}
interface CardAnswerProps {
  filterType: string;
  data: OtherAnswerData;
  orgInfo: any;
  onVoteUpdate: (points: number) => void;
  onRemoveFromUI: (answerId) => void;
}

export const CardAnswer = ({
  filterType,
  data,
  orgInfo,
  onVoteUpdate,
  onRemoveFromUI
}: CardAnswerProps) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [likeCount, setLikeCount] = useState(data.likes);
  const [dislikeCount, setDislikeCount] = useState(data.dislikes);
  const [userVote, setUserVote] = useState(data.user_like);

  const [isRemoving, setIsRemoving] = useState(false);
  const [isBookmarking, setIsBookmarking] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(data.is_bookmarked === 1);
  const [answerPoints, setAnswerPoints] = useState(data.points);

  const onVote = async (vote: number) => {
    const res = await apiContentVoteAI({
      item_id: data.id,
      item_type: 'answer',
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      setLikeCount(voteData.likes);
      setDislikeCount(voteData.dislikes);
    }
  };

  const onBookmarkAnswer = async () => {
    if (!isBookmarking) {
      setIsBookmarking(true);

      if (isBookmarked) {
        const res = await apiQAUnBookMarkAnswer({
          answer: data.id
        });

        if (res.success) {
          setIsBookmarked(false);
        }
      } else {
        const res = await apiQABookMarkAnswer({
          answer: data.id,
          item_id: undefined,
          item_type: 'user'
        });

        if (res.success) {
          setIsBookmarked(true);
        }
      }

      setIsBookmarking(false);
    }
  };

  const onRemove = async () => {
    if (!isRemoving) {
      setIsRemoving(true);

      const res = await apiDeletionAnswer({ answer: data.id });
      if (res.success) {
        showToast('success', 'Deletion successful!');
        onRemoveFromUI(data.id);
      }

      setIsRemoving(false);
    }
  };

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const renderTopView = () => {
    return (
      <div className="answer_box_top">
        <div className="answer_box_top_user">
          {renderCreatorCreatorInfo()}
          {renderPlaceInfo()}
        </div>
        <div className="answer_box_top_right">
          <div className="answer_date">
            {moment(data.timestamp).format('DD MMM yyyy').toString()}
          </div>
          <div className="divide_line"></div>
          <div className="answer_top_bar_other_info_pt_btn">
            <div className="answer_point">
              <UCoinIcon color="#000000" />
              &nbsp;
              {`${answerPoints} pt${answerPoints > 1 ? 's' : ''}`}
            </div>
          </div>
          {userInfo?.id === data.user && (
            <div className="row_align_items">
              <div className="divide_line"></div>
              <div className="clickable" onClick={onRemove}>
                {isRemoving ? (
                  <LoadingCircular color="main" size={20} />
                ) : (
                  <TrashIcon />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCreatorCreatorInfo = () => {
    if (data.is_anonymous == 1) {
      return (
        <div className="align-vertical-center">
          <DefaultAvatarIcon size={24} />
          <div
            className="font-medium font16 user_name"
            style={{ marginLeft: 8 }}
          >
            Anonymous
          </div>
        </div>
      );
    } else {
      if (data.creator_id) {
        return (
          <div
            className="align-vertical-center clickable"
            onClick={onViewProfile}
          >
            {data.creator_image ? (
              <img
                className="creator_image"
                src={data.creator_image}
                width="24"
                height="24"
                style={{ borderRadius: 24 }}
              ></img>
            ) : (
              <DefaultAvatarIcon size={24} />
            )}

            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {data.creator_name ? data.creator_name : 'Anonymous'}
            </div>
          </div>
        );
      } else {
        return (
          <div className="align-vertical-center">
            <img
              className="creator_image"
              src={orgInfo?.ai_image}
              width="16"
              height="16"
              style={{ borderRadius: 16 }}
            ></img>
            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {`${orgInfo?.ai_name} AI`}
            </div>
          </div>
        );
      }
    }
  };

  const renderPlaceInfo = () => {
    if (
      !data.ai_type ||
      data.ai_type === 'user' ||
      data.ai_type === 'general' ||
      !data.ai_id
    ) {
      return <div />;
    } else {
      return (
        <div className="align-vertical-center">
          <div className="font-medium font16 in_label">in</div>
          <img
            className="creator_image"
            src={data.ai_image}
            width="24"
            height="24"
            style={{ borderRadius: 24 }}
          ></img>
          <div className="font-medium font16 org_name">{data.ai_name}</div>
          <div className="font-medium font16 posted_question">
            posted a new question
          </div>
        </div>
      );
    }
  };

  const renderAnswer = () => {
    return (
      <div className="answer_text  font20">
        <pre className="font20">{removeFirstBlank(data?.answer)}</pre>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="answer_box_bottom">
        {data.id ? (
          <div className="answer_box_bottom_left">
            <div className={'agree_btn clickable'} style={{ padding: 8 }}>
              <AudioPlayIcon color="#FF3E9A" />
            </div>
            <div
              className={
                userVote === 1
                  ? 'agree_btn_highlighted clickable'
                  : 'agree_btn clickable'
              }
              onClick={() => onVote(1)}
            >
              <div>{`\u2764`}</div>
              <div className="btn_number font-medium font12">{likeCount}</div>
            </div>
            <div
              className={
                userVote === 0
                  ? 'agree_btn_highlighted clickable'
                  : 'agree_btn clickable'
              }
              onClick={() => onVote(0)}
            >
              <div className="font-medium font18">{`\u{1F494}`}</div>
              <div className="btn_number font-medium font12">
                {dislikeCount}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {isBookmarking
          ? renderIsBookmarking()
          : isBookmarked
          ? renderBookmarkedBtn()
          : renderBookmarkBtn()}
      </div>
    );
  };

  const renderIsBookmarking = () => {
    return (
      <div className="bookmark_btn">
        <LoadingCircular color="main" size={20} />
      </div>
    );
  };

  const renderBookmarkBtn = () => {
    return (
      <div className="bookmark_btn clickable" onClick={onBookmarkAnswer}>
        <BookMarkedIcon color={'rgba(0, 0, 0, 0.4)'} />
        <div
          className="btn_label font-reqular font14"
          style={{ color: 'rgba(0, 0, 0)' }}
        >
          Bookmark this Answer
        </div>
      </div>
    );
  };

  const renderBookmarkedBtn = () => {
    return (
      <div className="bookmark_btn clickable" onClick={onBookmarkAnswer}>
        <BookMarkedIcon opacity="0.4" />
        <div className="btn_label font-reqular font14">
          Bookmarked this Answer
        </div>
      </div>
    );
  };

  return (
    <div style={filterType == 'file' ? { display: 'none' } : {}}>
      <div className="org_other_answer_card">
        {renderTopView()}
        {renderAnswer()}
        {renderBottom()}
      </div>
    </div>
  );
};
