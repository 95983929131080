import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import PointIcon from 'assets/svgs/PointIcon';
import React from 'react';
import moment from 'moment';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';

export interface InvitationData {
  id: number;
  email: string;
  points: number;
  timestamp: string;
  creator_id?: any;
  creator_image?: string;
  creator_name?: string;
}

interface CardAcceptedProps {
  data: InvitationData;
}

export const CardAccepted = ({ data }: CardAcceptedProps) => {
  const navigate = useNavigate();

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const renderLeft = () => {
    return (
      <div className="row_align_items">
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img
            src={data.creator_image}
            style={{
              width: 40,
              height: 40,
              borderRadius: 40,
              objectFit: 'cover'
            }}
            className="clickable"
            onClick={onViewProfile}
          />
        ) : (
          <DefaultAvatarIcon size={40} />
        )}

        <div style={{ marginLeft: 12 }}>
          <div className="font-bold font16 clickable">{data.creator_name}</div>
          <div
            className="font-regular font14"
            style={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: 4 }}
          >
            {`Joined at ` + moment(data.timestamp).format('DD MMM YYYY')}
          </div>
        </div>
      </div>
    );
  };

  const renderRight = () => {
    return (
      <div className="row_align_items">
        <PointIcon size={24} />
        <div className="row_align_items" style={{ marginLeft: 8 }}>
          <div className="font-bold font16">{`+ ${data.points}`}</div>
          <div className="font-regular font16" style={{ marginLeft: 4 }}>
            pts
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="card_share_point row_space_between clickable"
      onClick={onViewProfile}
    >
      {renderLeft()}
      {renderRight()}
    </div>
  );
};
