import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import HorizontalDetailIcon from 'assets/svgs/HorizontalDetailIcon';
import React, { useState } from 'react';
import moment from 'moment';

import './index.styles.scss';
import { useNavigate } from 'react-router-dom';
import { ShareMemeModal } from 'components/Modals/ShareMemeModal';
import ShareIcon from 'assets/svgs/ShareIcon';

export const CardSharedMeme = ({ data, selectedOrg, onClickedImage }) => {
  const navigate = useNavigate();
  const [showShareModal, setShowShareModal] = useState(false);

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const renderShareModal = () => {
    return (
      <ShareMemeModal
        show={showShareModal}
        data={data}
        selectedOrg={selectedOrg}
        onClose={() => {
          setShowShareModal(false);
        }}
      />
    );
  };

  return (
    <div className="card_shared_meme">
      {data.meme_url && data.meme_url.startsWith('https') ? (
        <img
          src={data.meme_url}
          className="meme_image clickable"
          onClick={() => {
            onClickedImage(data.meme_url);
          }}
        />
      ) : (
        <div className="meme_image"></div>
      )}
      <div style={{ flex: 1 }} />
      <div className="row_space_between" style={{ zIndex: 10 }}>
        <div className="row_align_items clickable" onClick={onViewProfile}>
          {data.creator_image && data.creator_image.startsWith('https') ? (
            <img src={data.creator_image} className="create_image" />
          ) : (
            <DefaultAvatarIcon size={32} />
          )}
          <div style={{ marginLeft: 8 }}>
            <div className="font-medium font10">{data.creator_name}</div>
            <div className="font-medium font10" style={{ opacity: 0.4 }}>
              {moment(data.timestamp).format('DD MMM')}
            </div>
          </div>
        </div>
        <div
          className="detail_btn clickable"
          onClick={() => {
            setShowShareModal(true);
          }}
        >
          <ShareIcon color="rgba(0, 0, 0, 0.3)" />
        </div>
      </div>
      {renderShareModal()}
    </div>
  );
};
