import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from 'shared/utils/constants';
import { CardChatHistory, ChatRoomData } from './CardChatHistory';

import './index.styles.scss';

interface ChatHistoryProps {
  data: Array<any>;
  selectedRoom?: ChatRoomData;
  onSelected: (data: ChatRoomData) => void;
}

export const ChatHistory = ({
  data,
  selectedRoom,
  onSelected
}: ChatHistoryProps) => {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  const arrNewRooms = useMemo(() => {
    const uniqueArr = data.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );

    return uniqueArr;
  }, [data]);

  return (
    <div className="chat_history">
      {!isMobile && (
        <div className="chat_history_title font-bold font14">Chat History</div>
      )}
      <div
        className="chat_history_body"
        style={isMobile ? { height: '100%' } : {}}
      >
        <div className="chat_history_body_list">
          {arrNewRooms.map((item, index) => {
            return (
              <CardChatHistory
                key={`card_chat_history_${index}`}
                data={item}
                selectedRoom={selectedRoom}
                onSelected={onSelected}
              />
            );
          })}
        </div>
        <div style={{ height: 24 }}></div>
      </div>
    </div>
  );
};
