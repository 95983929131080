import React, { useState } from 'react';
import { apiGroupSendEmailInvitation } from 'shared/api/group';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

import './index.styles.scss';
import BlackBackIcon from 'assets/svgs/BlackBackIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';
interface ShareQuestionToOrgModalProps {
  show: boolean;
  groupInfo?: any;
  onBack: () => void;
  onClose: () => void;
}

export const SendInvitationEmailModal = ({
  show,
  groupInfo,
  onBack,
  onClose
}: ShareQuestionToOrgModalProps) => {
  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<Array<any>>([]);
  const [email, setEmail] = useState('');

  const onSend = async () => {
    if (!isSendingInvitation && selectedEmails.length > 0) {
      setIsSendingInvitation(true);

      const res = await apiGroupSendEmailInvitation({
        group: groupInfo?.groupId,
        emails: selectedEmails
      });

      if (res.success) {
        setSelectedEmails([]);
        showToast('success', 'Invitate sent successfully!');
      }

      setIsSendingInvitation(false);
    }
  };

  const onAddEmail = () => {
    if (email !== '') setSelectedEmails((prev) => [...prev, email]);
    setEmail('');
  };

  const onCancel = (key) => {
    setSelectedEmails(selectedEmails.filter((item, index) => index !== key));
  };

  const renderInputEmail = () => {
    return (
      <div className="send_invite_body_input_bar">
        <div className="send_invite_body_input_bar_left">
          <div className="subtitle font-bold font14">Email address</div>
          <div style={{ width: '100%' }}>
            <input
              value={email}
              placeholder="Enter your friend's email here..."
              className="email_input"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          className="send_invite_body_input_bar_right clickable"
          onClick={onAddEmail}
        >
          <PlusIcon />
        </div>
      </div>
    );
  };

  const renderSelectedEmails = () => {
    return (
      <div className="send_invite_body_selected_emails">
        {selectedEmails.map((item, index) => {
          return (
            <div className="card_email" key={`card_email_${index}`}>
              <div className="card_email_avatar">
                <DefaultAvatarIcon size={36} />
              </div>
              <div className="card_email_text font-regular font20">{item}</div>
              <div
                className="card_email_cancel clickable"
                onClick={() => {
                  onCancel(index);
                }}
              >
                <div style={{ opacity: 0.3 }}>
                  <CloseIcon />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="send_invite_body">
        <>
          {renderInputEmail()}
          {renderSelectedEmails()}
        </>
      </div>
    );
  };

  const renderBottomBar = () => {
    return (
      <div className="send_invite_bottom_bar">
        <PrimaryButton
          label={`Send Invitation to ${selectedEmails.length} email${
            selectedEmails.length === 1 ? '' : 's'
          }`}
          fontSize={18}
          isLoading={isSendingInvitation}
          onPressed={onSend}
        />
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={500} onClose={onClose}>
      <div className="modal_create_group">
        <div className="modal_create_group_header">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onBack();
            }}
          >
            <BlackBackIcon />
          </div>
          <div className="question_title font-bold font20">
            Send email invite to {groupInfo?.name}
          </div>
        </div>

        <div className="modal_send_invite_body">
          {renderBody()}
          {renderBottomBar()}
        </div>
      </div>
    </PrimaryModal>
  );
};
