import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CardFileAiAnswer } from '../../AskedQuestion/CardFileAIAnswer';

import './index.styles.scss';

interface HighLightData {
  id: number;
  creator_id?: number;
  creator_image?: string;
  creator_name?: string;
  summary?: string;
  is_marked: number;
  from_page?: number;
  to_page?: number;
  pageIndex?: number;
  text?: string;
}
interface CardSavedHighlightProps {
  data: HighLightData;
  onClicked: (hightLightId) => void;
}

export const CardSavedHighlight = ({
  data,
  onClicked
}: CardSavedHighlightProps) => {
  const navigate = useNavigate();

  const renderTop = () => {
    return (
      <div
        className="row_space_between clickable"
        onClick={() => onClicked(data.id)}
      >
        <div className="align-vertical-center">
          {data.creator_image && data.creator_image.startsWith('https') ? (
            <img
              src={data.creator_image}
              style={{ width: 24, height: 24, borderRadius: 24 }}
            />
          ) : (
            <DefaultAvatarIcon size={24} />
          )}
          <div
            className="font-medium font16 user_name"
            style={{ marginLeft: 8 }}
          >
            {`${data.creator_name} `}
            <span className="font-regular font14" style={{ opacity: 0.6 }}>
              in
            </span>{' '}
            {data.pageIndex ? `page ${data.pageIndex}` : 'document'}
          </div>
        </div>
      </div>
    );
  };

  const renderHighlightTxt = () => {
    return (
      <div
        className="highlight_txt_bg  clickable"
        onClick={() => onClicked(data.id)}
      >
        <div className="font-regular font18">{`"${data.text}"`}</div>
      </div>
    );
  };

  const aiAnswer = { ai_answer: data.summary };

  const renderFileAiAnswer = () => {
    return <CardFileAiAnswer data={aiAnswer} enableShowMore={true} />;
  };

  return (
    <div className="card_saved_highlight">
      {renderTop()}
      {!!data.text && renderHighlightTxt()}
      {renderFileAiAnswer()}
    </div>
  );
};
