import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './index.styles.scss';
import { getArrFromString } from 'shared/utils/utils';
import { Tag } from 'shared/ui-kit/Tag';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import TagIcon from 'assets/svgs/TagIcon';
import FileUploadIcon from 'assets/svgs/FileUploadIcon';
import EditIcon from 'assets/svgs/EditIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import MicIcon from 'assets/svgs/MicIcon';

import { useNavigate } from 'react-router-dom';

import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import ProcessIcon from 'assets/svgs/ProcessIcon';
import { Box } from '@mui/material';
import UploadAltIcon from 'assets/svgs/UploadAltIcon';
import LocationArrowIcon from 'assets/svgs/LocationArrowIcon';
import { SendDumpModal } from 'components/Modals/SendDumpModal';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import AngleDoubleDownIcon from 'assets/svgs/AngleDoubleDownIcon';
import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import { FilePrivacyModal } from 'components/Modals/FilePrivacyModal';
import { apiQARetryDump, apiQAShareDump } from 'shared/api/qa';
import WarningIcon from 'assets/svgs/WarningIcon';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import QuestionIcon from 'assets/svgs/QuestionIcon';
import TrashIcon from 'assets/svgs/TrashIcon';
import { apiDeletionDump } from 'shared/api/delete';
import { LoadingDialog } from 'shared/ui-kit/LoadingDialog';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface AttachedFileData {
  id: number;
  filename: string;
  title: string;
  url: string;
  size: number;
  timestamp: string;
  tag_ids?: string;
  tag_types?: string;
  tag_names?: string;
  tag_images?: string;
  dump_type?: 'pdf' | 'url' | 'text' | 'audio' | 'link';
  status: 'uploading' | 'processing' | 'pending' | 'completed' | 'failed';
  summary_status:
    | 'uploading'
    | 'processing'
    | 'pending'
    | 'completed'
    | 'failed';
  privacy_type: 'private' | 'public' | 'anonymous';
  creator_id?: any;
  creator_image?: string;
  creator_name?: string;
  answer_count?: number;
  uploadingName?: string;
}

interface CardAttachedFileProps {
  data: AttachedFileData;
  index: number;
  tab: 'uploaded' | 'received';
  screen: 'user' | 'group';
  onAddTag: (data: AttachedFileData, positionX: number | undefined) => void;
  onRemoveOnUI: (dumpId) => void;
}

export const CardAttachedFile = ({
  data,
  index,
  tab,
  screen,
  onAddTag,
  onRemoveOnUI
}: CardAttachedFileProps) => {
  const navigate = useNavigate();

  const [showSendModal, setShowSendModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const { progress } = useIPFS();
  const positionRef = useRef<HTMLDivElement>(null);

  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [privacyModalPos, setPrivacyModalPos] = useState({ top: 0, left: 0 });
  const [isRetrying, setIsRetrying] = useState(false);

  const [privacyStatus, setPrivacyStatus] = useState<
    'private' | 'public' | 'anonymous'
  >(data.privacy_type);

  const onDetail = (e) => {
    if (data.status === 'completed') {
      if (data.dump_type === 'pdf') {
        navigate(`/document/${data.id}`);
      } else if (data.dump_type === 'url') {
        window.open(data.url, '_blank');
      } else if (data.dump_type === 'text') {
        navigate(`/file/${data.id}`);
      } else if (data.dump_type === 'audio') {
        navigate(`/audio/${data.id}`);
      }
    }
  };

  const onRetry = async (dump) => {
    setIsRetrying(true);
    const res = await apiQARetryDump({ dump: data.id });
    if (res.success) {
    }

    setIsRetrying(false);
  };

  const onChangedPrivacy = async (
    status: 'private' | 'public' | 'anonymous'
  ) => {
    const res = await apiQAShareDump({
      dump: data.id,
      privacy: status
    });

    if (res.success) {
      setPrivacyStatus(status);
    }
  };

  const onClickPrivacy = (e) => {
    e.stopPropagation();

    const clientRectangle = document!
      .getElementById(`file_privacy_btn_${data.id}`)!
      .getBoundingClientRect();

    const bottomFromWindow = window.innerHeight - clientRectangle.top;
    setPrivacyModalPos({
      top:
        bottomFromWindow < 200
          ? clientRectangle.top - 200
          : clientRectangle.top + 50,
      left: clientRectangle.left
    });

    setShowPrivacyModal(true);
  };

  const onClickTag = (e) => {
    e.stopPropagation();
    onAddTag(data, positionRef.current?.offsetLeft);
  };

  const onSend = (e) => {
    e.stopPropagation();
    setShowSendModal(true);
  };

  const arrTag = useMemo(() => {
    if (data.tag_ids) {
      const tmpArrId = getArrFromString(data.tag_ids, ',');

      if (tmpArrId && tmpArrId.length > 0) {
        const tmpArrName = getArrFromString(data.tag_names, ',');
        const tmpArrImage = getArrFromString(data.tag_images, ',');
        const tmpArrType = getArrFromString(data.tag_types, ',');

        const tmpArrData = tmpArrId.map((item, index) => {
          return {
            item_id: parseInt(tmpArrId[index]),
            item_type: tmpArrType[index],
            name: tmpArrName[index],
            image: tmpArrImage[index]
          };
        });

        return tmpArrData;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [data]);

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const onRemove = async (e) => {
    e.stopPropagation();

    if (!isRemoving) {
      setIsRemoving(true);

      const res = await apiDeletionDump({
        dump: data.id
      });
      setIsRemoving(false);

      if (res.success) {
        showToast('success', 'Deletion successful!');
        onRemoveOnUI && onRemoveOnUI(data.id);
      }
    }
  };

  const renderQuestions = () => {
    return (
      <div className="answer_view row_align_items">
        <QuestionIcon color="#FF3E9A" />
        <div
          className="font-bold font12"
          style={{ marginLeft: 6, marginTop: 4 }}
        >
          {data.answer_count} questions asked in this file
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    if (data.status !== 'completed') {
      return (
        <div>
          {renderStatusBar()}
          {screen === 'group' && renderSendInfo()}
        </div>
      );
    } else {
      if (screen === 'user' && tab === 'uploaded') {
        return renderTagSection();
      } else {
        return (
          <>
            {renderTagSection()}
            {renderSendInfo()}
          </>
        );
      }
    }
  };

  const renderType = () => {
    if (data.dump_type === 'pdf')
      return (
        <div className="file_uploaded_label font12 text-white">
          <FileUploadIcon size={16} />
          Uploaded
        </div>
      );
    else if (data.dump_type === 'text')
      return (
        <div className="written_text_label font12 text-white">
          <EditIcon size={16} /> Notes
        </div>
      );
    else if (data.dump_type === 'url')
      return (
        <div className="from_link_label font12 text-white">
          <LinkIcon size={18} color="white" /> Link
        </div>
      );
    else if (data.dump_type === 'audio')
      return (
        <div className="audio_recorded_label font14 text-white">
          <MicIcon size={16} />
          Audio
        </div>
      );
  };

  const renderIcon = () => {
    if (data.dump_type === 'pdf') return <PdfFileIcon size={36} />;
    else if (data.dump_type === 'audio')
      return (
        <div className="audio_play_icon_view">
          <AudioPlayIcon size={36} />
        </div>
      );
    else return <div className="font36">📄</div>;
  };

  const getTitle = () => {
    if (data.summary_status === 'completed') return data.title;
    if (data.dump_type === 'pdf') return data.filename ?? data.url;
    if (data.dump_type === 'url' || data.dump_type === 'link')
      return data.uploadingName ?? data.url ?? data.filename;
    if (data.dump_type === 'audio') return data.filename ?? data.url;
    if (data.dump_type === 'text') return data.url;

    return data.title ? data.title : data.filename ? data.filename : data.url;
  };

  const renderBody = () => {
    return (
      <div className="home_card_attached_file_body">
        <div className="home_card_attached_file_body_image">
          <div className="home_card_attached_file_body_image">
            {renderIcon()}
            {index === 0 && renderNewMark()}
          </div>
        </div>
        <div className="home_card_attached_file_body_info">
          <Tooltip
            title={<div className="font-regular font14">{getTitle()}</div>}
          >
            <div
              className="home_card_attached_file_body_info_title font-bold font18"
              id={`home_card_attached_file_${data.id}`}
            >
              {getTitle()}
            </div>
          </Tooltip>

          <div className="home_card_attached_file_body_info_bottom">
            {renderType()}
            {data.size !== null && (
              <div
                className="home_card_attached_file_body_info_bottom_size font-regular font14"
                style={{ marginLeft: '4px' }}
              >
                Size {data.size} MB
              </div>
            )}
            <div className="home_card_attached_file_body_info_bottom_date font-regular font14">
              {moment(data.timestamp).format('• DD MMM')}
            </div>
            <div
              className="clickable"
              style={{ marginLeft: 16 }}
              onClick={onRemove}
            >
              <TrashIcon />
            </div>
          </div>
        </div>
        {data.status === 'completed' && (
          <Tooltip
            title={
              <div className="font-regular font14">
                Send and share this with a friend, group or school
              </div>
            }
          >
            <div className="send_btn clickable" onClick={onSend}>
              <LocationArrowIcon />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const renderFailedBody = () => {
    return (
      <div className="home_card_attached_file_body">
        <div className="home_card_attached_file_body_image">
          <div className="home_card_attached_file_body_image">
            {renderIcon()}
          </div>
        </div>
        <div className="home_card_attached_file_body_info">
          <div
            className="home_card_attached_file_body_info_title font-bold font18"
            id={`home_card_attached_file_${data.id}`}
          >
            {getTitle()}
          </div>
          <div className="home_card_attached_file_body_info_bottom">
            <div className="failed_mark row_align_items">
              <WarningIcon size={12} color="black" />
              <div className="font-medium font12" style={{ marginLeft: 4 }}>
                Failed
              </div>
            </div>
            {data.size !== null && (
              <div
                className="home_card_attached_file_body_info_bottom_size font-regular font14"
                style={{ marginLeft: '4px' }}
              >
                Size {data.size} MB
              </div>
            )}
            <div className="home_card_attached_file_body_info_bottom_date font-regular font14">
              {moment(data.timestamp).format('• DD MMM')}
            </div>
            <div
              className="clickable"
              style={{ marginLeft: 16 }}
              onClick={onRemove}
            >
              <TrashIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStatusBar = () => {
    if (data.status === 'uploading') {
      return (
        <div className="status_view">
          <div className="row_space_between">
            <div className="row_align_items">
              <UploadAltIcon />
              <div className="status_label font-medium font12">
                Uploading progress
              </div>
            </div>
            <div className="font-bold font18">{`${progress.toFixed(0)}%`}</div>
          </div>

          <div style={{ width: '100%', marginTop: 6 }}>
            <Box sx={{ width: '100%' }} mt={1}>
              <LinearProgress
                sx={{
                  backgroundColor: '#d9d9d9',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#FF3E9A'
                  }
                }}
                variant="determinate"
                value={progress}
              />
            </Box>
          </div>
        </div>
      );
    } else {
      return (
        <div className="status_view">
          <div className="row_align_items">
            <ProcessIcon />
            <div className="status_label font-medium font12">Processing</div>
          </div>
          <div style={{ width: '100%' }}>
            <Box sx={{ width: '100%' }} mt={1}>
              <LinearProgress
                sx={{
                  backgroundColor: '#d9d9d9',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#FF3E9A'
                  }
                }}
              />
            </Box>
          </div>
        </div>
      );
    }
  };

  const renderNewMark = () => {
    return <div className="new_mark font-bold font10">New</div>;
  };

  const renderSendInfo = () => {
    return (
      <div
        className="row_align_items clickable"
        style={{ marginTop: 12 }}
        onClick={onViewProfile}
      >
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img
            src={data.creator_image}
            style={{ width: 24, height: 24, borderRadius: 24 }}
          />
        ) : (
          <DefaultAvatarIcon size={24} />
        )}
        <div className="font-bold font12" style={{ marginLeft: 6 }}>
          {tab === 'uploaded'
            ? `Uploaded by ${data.creator_name}`
            : `Received from ${data.creator_name}`}
        </div>
      </div>
    );
  };

  const renderPrivacy = () => {
    return (
      <div
        className="privacy_btn row_align_items clickable"
        id={`file_privacy_btn_${data.id}`}
        onClick={onClickPrivacy}
      >
        <div className="font16">
          {privacyStatus === 'private'
            ? '🔒'
            : privacyStatus === 'public'
            ? '🤗'
            : '🎭'}
        </div>
        <div className="font-regular font12">
          {privacyStatus === 'private'
            ? 'Private'
            : privacyStatus === 'public'
            ? 'Public'
            : 'Anonymous'}
        </div>
        <div>
          <AngleDownIcon color="#FF3E9A" />
        </div>
      </div>
    );
  };

  const renderTagSection = () => {
    return (
      <div
        className="home_card_attached_file_bottom"
        style={{ marginTop: screen === 'user' && tab === 'uploaded' ? 28 : -4 }}
      >
        {renderPrivacy()}
        {arrTag.length > 0 ? (
          <div
            className="home_card_attached_file_bottom_edit_tag clickable"
            onClick={onClickTag}
          >
            <EditPencilColoredIcon size={16} />
          </div>
        ) : (
          <div
            ref={positionRef}
            className="home_card_attached_file_bottom_add_new_tag clickable font-regular font-12"
            onClick={onClickTag}
            data-tooltip-id={`add_tag_${index}`}
            data-tooltip-content={
              'Add a tag to sort your knowledge base and improve search'
            }
          >
            <TagIcon color="#FF3E9A" /> Add Tag
            <ReactTooltip id={`add_tag_${index}`} />
          </div>
        )}
        {arrTag.map((item, index) => {
          return (
            <Tag
              type="else"
              isSelected={false}
              isEditMode={false}
              data={item}
              onClicked={() => {}}
              key={`card_file_tag_list_${index}`}
            />
          );
        })}
      </div>
    );
  };

  const renderSendModal = () => {
    return (
      <SendDumpModal
        bookmarkId={data.id}
        item_id={data.id}
        item_type={'user'}
        show={showSendModal}
        type="file"
        onClose={() => setShowSendModal(false)}
        onSuccess={(groupData) => {}}
      />
    );
  };

  const renderPrivacyModal = () => {
    return (
      <FilePrivacyModal
        show={showPrivacyModal}
        posX={privacyModalPos.left}
        posY={privacyModalPos.top}
        onClose={() => {
          setShowPrivacyModal(false);
        }}
        onSelected={(action) => {
          onChangedPrivacy(action);
        }}
      />
    );
  };

  const renderRemovingModal = () => {
    return (
      <LoadingDialog
        show={isRemoving}
        title={'Deleting data'}
        description="This may take a moment..."
        onClose={() => setIsRemoving(false)}
      />
    );
  };

  if (data.status === 'failed') {
    return (
      <div
        className="home_card_attached_file clickable"
        onClick={onDetail}
        style={
          data.status === 'failed'
            ? {
                background:
                  'linear-gradient(0deg, rgba(255, 193, 48, 0.1), rgba(255, 193, 48, 0.1)), #FFFFFF',
                border: '1px solid #E1A325',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)'
              }
            : index === 0
            ? {
                border: '2px solid #FF3E9A'
              }
            : {}
        }
      >
        {renderFailedBody()}
        <div className="row_space_between row_align_items failed_view">
          <div className="row_align_items">
            <WarningIcon size={24} color="#E1A325" />
            <div style={{ marginLeft: 24 }}>
              <div className="font-bold font14">Failed to Upload File!</div>
              <div
                className="font-regular font12"
                style={{ opacity: 0.6, marginTop: 4 }}
              >
                Failed to upload this file due to missing internet connection
              </div>
            </div>
          </div>
          <div
            className="retry_btn font-bold font12 clickable"
            onClick={onRetry}
          >
            {isRetrying ? <LoadingCircular color="white" size={16} /> : 'Retry'}
          </div>
        </div>
        {isRemoving && renderRemovingModal()}
      </div>
    );
  } else {
    return (
      <div
        className="home_card_attached_file clickable"
        onClick={onDetail}
        style={
          index === 0
            ? {
                border: '2px solid #FF3E9A'
              }
            : {}
        }
      >
        {renderBody()}
        {renderQuestions()}
        {renderBottom()}
        {renderSendModal()}
        {renderPrivacyModal()}
        {isRemoving && renderRemovingModal()}
      </div>
    );
  }
};
