import React from "react";

function EnvelopeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      fill="none"
      viewBox="0 0 18 14"
    >
      <path
        fill="#FF3E9A"
        d="M14.833.334H3.167a2.5 2.5 0 00-2.5 2.5v8.333a2.5 2.5 0 002.5 2.5h11.666a2.5 2.5 0 002.5-2.5V2.834a2.5 2.5 0 00-2.5-2.5zm-.341 1.667l-4.9 4.9a.833.833 0 01-1.184 0L3.508 2h10.983zm1.174 9.166a.833.833 0 01-.833.834H3.167a.833.833 0 01-.834-.834V3.176l4.9 4.9a2.5 2.5 0 003.534 0l4.9-4.9v7.991z"
      ></path>
    </svg>
  );
}

export default EnvelopeIcon;