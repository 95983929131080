import React, { useState } from 'react';
import './index.styles.scss';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import ReadCheckIcon from 'assets/svgs/ReadCheckIcon';
import MicIcon from 'assets/svgs/MicIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import EditIcon from 'assets/svgs/EditIcon';
import FileIcon from 'assets/svgs/FileIcon';

interface FileReferenceData {
  id: number;
  title?: string;
  filename?: string;
  size?: number;
  url?: string;
  dump_type?: 'text' | 'url' | 'pdf' | 'audio' | 'html';
}

interface CardFileReferenceProps {
  data: FileReferenceData;
  isSelected: boolean;
  onSelect: (referenceIdx: number) => void;
}

export const CardFileReference = ({
  data,
  isSelected,
  onSelect
}: CardFileReferenceProps) => {
  return (
    <div className={'dlg_list_item' + (isSelected ? ' added_item' : '')}>
      <div className="row_align_items" style={{ width: `calc(100% - 32px)` }}>
        <div style={{ width: 28, height: 28 }}>
          {data.dump_type === 'pdf' ? (
            <PdfFileIcon size={28} />
          ) : data.dump_type === 'audio' ? (
            <MicIcon size={24} color="#FF3E9A" opacity={1} />
          ) : data.dump_type === 'url' || data.dump_type === 'html' ? (
            <LinkIcon size={28} color="#FF3E9A" opacity={1} />
          ) : (
            <FileIcon />
          )}
        </div>
        <div style={{ marginLeft: 8, width: 'calc(100% - 60px)' }}>
          <div className="ref_name font-medium font12">
            {data.title ?? data.filename}
          </div>
        </div>
      </div>
      {isSelected ? (
        <div className="check_icon clickable" onClick={() => onSelect(data.id)}>
          <ReadCheckIcon color="#FF3E9A" size={18} />
        </div>
      ) : (
        <div
          className="add_btn clickable"
          onClick={() => {
            onSelect(data.id);
          }}
        >
          +
        </div>
      )}
    </div>
  );
};
