import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { apiGetGroupDetailById, apiGetPendingMember } from 'shared/api/group';
import { ActiveMember } from './ActiveMember';
import { GroupMemberTopView } from './TopView';
import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import { PendingMemberModal } from 'components/Modals/PendingMemberModal';

import './index.styles.scss';

export const GroupMember = () => {
  const navigate = useNavigate();

  const params = useParams();
  const groupId = params.id;
  const [groupCode, setGroupCode] = useState('');

  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isOpenPendingMemberModal, setIsOpenPendingMemberModal] = useState(
    false
  );
  const [pendingCount, setPendingCount] = useState(0);

  useEffect(() => {
    loadGroupDetail();
    loadMember();
  }, []);

  const loadGroupDetail = async () => {
    const res = await apiGetGroupDetailById({ group: groupId });

    if (res.success) {
      setGroupCode(res.data.code);
    }
  };

  const loadMember = async () => {
    const res = await apiGetPendingMember({ group: groupId });

    if (res.success) {
      setPendingCount(res.count);
    }
  };

  const renderHeader = () => {
    return (
      <div className="group_member_header">
        <div className="clickable" onClick={() => navigate(-1)}>
          <RoundBackIcon />
        </div>
        <div className="header_info row_space_between">
          <div className="font-bold font40">Group Members</div>
          <div
            className="pending_member_btn clickable"
            onClick={() => setIsOpenPendingMemberModal(true)}
          >
            <div className="font-bold font14">Pending Members</div>
            <div className="pending_member_count">{pendingCount}</div>
            <AngleRightIcon />
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="group_member_body">
        <GroupMemberTopView groupCode={groupCode} groupId={groupId} />
        <ActiveMember groupId={groupId} shouldRefresh={shouldRefresh} />
      </div>
    );
  };

  const renderPendingMemberModal = () => {
    return (
      <PendingMemberModal
        show={isOpenPendingMemberModal}
        groupId={groupId}
        pendingCount={pendingCount}
        onClose={() => setIsOpenPendingMemberModal(false)}
        onRefresh={() => setShouldRefresh((prev) => !prev)}
      />
    );
  };

  return (
    <div className="group_member">
      {pendingCount > 0 && renderHeader()}
      {renderBody()}
      {renderPendingMemberModal()}
    </div>
  );
};
