import MicIcon from 'assets/svgs/MicIcon';
import RefreshIcon from 'assets/svgs/RefreshIcon';
import UCheckIcon from 'assets/svgs/UCheckIcon';
import React, { useRef, useState } from 'react';
import { ReactMediaRecorder } from 'react-media-recorder';
import { apiCreateQADump } from 'shared/api/qa';

import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { formatSecond } from 'shared/utils/format-time';
import { validURL } from 'shared/utils/utils';

import './index.styles.scss';
import CloseIcon from 'assets/svgs/CloseIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface Props {
  show: boolean;
  itemId?: any;
  itemType?: any;
  onClose: () => void;
  onSuccess: (dumpId, link) => void;
}

export const RecordModal = ({
  show,
  itemId,
  itemType,
  onClose,
  onSuccess
}: Props) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isCreating, setIsCreating] = useState(false);

  const { uploadBase64 } = useIPFS();
  let timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const onCreate = async (audioUrl) => {
    try {
      if (validURL(audioUrl)) {
        if (!isCreating) {
          const res = await apiCreateQADump({
            url: audioUrl,
            type: 'url',
            to: itemId,
            to_type: itemType
          });

          if (res.success) {
            onSuccess(res.id, audioUrl);
          } else {
            showToast('error', 'Unable to process link url');
          }
          setIsCreating(false);
        }
      } else {
        showToast('error', 'Unable to process link url');
      }
    } catch (error) {
      setIsCreating(false);
    } finally {
      setIsCreating(false);
    }
  };

  const onStartRecord = () => {
    setIsRecording(true);
    onStartTimer();
  };

  const onPauseRecord = () => {
    setIsRecording(false);

    if (timerRef) {
      clearInterval(timerRef?.current!);
    }
  };

  const onRefreshRecord = () => {
    if (isRecording) {
      onClearTimer();
      onStartRecord();
    }
  };

  const onStopRecord = async (mediaBlobUrl, blob) => {
    onClearTimer();

    setIsCreating(true);

    try {
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async function () {
        let base64String = reader.result;
        const url = (await uploadBase64(base64String, 'audio/wav', '')) as any;

        await onCreate(url);
      };
    } catch (error) {
      setIsCreating(false);
    } finally {
      setIsCreating(false);
    }
  };

  const onStartTimer = () => {
    timerRef.current = setInterval(() => {
      setRecordingTime((prev) => prev + 1);
    }, 1000);
  };

  const onClearTimer = () => {
    if (timerRef) {
      clearInterval(timerRef?.current!);
      setRecordingTime(0);
      setIsRecording(false);
    }
  };

  return (
    <PrimaryModal show={show} width={400} onClose={onClose}>
      <div className="record_modal">
        <div
          className="row_align_items row_space_between"
          style={{ marginBottom: 24, paddingRight: 24 }}
        >
          <div className="clickable" onClick={onClose}>
            <CloseIcon />
          </div>
          <div className="font-bold font14">{`Recording... ${formatSecond(
            recordingTime
          )}`}</div>
          <div></div>
        </div>

        <ReactMediaRecorder
          audio
          onStop={onStopRecord}
          render={({
            status,
            startRecording,
            stopRecording,
            clearBlobUrl,
            mediaBlobUrl
          }) => {
            return (
              <div className="row_align_items" style={{ gap: 24 }}>
                <div
                  className="refresh_btn clickable"
                  onClick={() => {
                    if (!isCreating) {
                      clearBlobUrl();
                      onRefreshRecord();
                    }
                  }}
                >
                  <RefreshIcon />
                </div>
                <div
                  className="record_btn clickable"
                  style={{ backgroundColor: isRecording ? '#FF3E9A' : 'white' }}
                  onClick={() => {
                    if (!isCreating) {
                      if (isRecording) {
                        onPauseRecord();
                      } else {
                        startRecording();
                        onStartRecord();
                      }
                    }
                  }}
                >
                  <MicIcon
                    size={24}
                    color={isRecording ? 'white' : '#FF3E9A'}
                  />
                </div>
                <div
                  className="done_btn clickable"
                  onClick={() => {
                    if (!isCreating) {
                      stopRecording();
                    }
                  }}
                >
                  <UCheckIcon />
                </div>
              </div>
            );
          }}
        />
      </div>
    </PrimaryModal>
  );
};
