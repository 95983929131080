import BookMarkIcon from 'assets/svgs/BookMarkIcon';
import GroupIcon from 'assets/svgs/GroupIcon';
import HomeIcon from 'assets/svgs/HomeIcon';
import OrgIcon from 'assets/svgs/OrgIcon';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import './TabBarItem.styles.scss';

interface TabBarItemProps {
  type: 'home' | 'bookmark' | 'group' | 'org';
  isSelected: boolean;
  hasGap?: boolean;
  onPressed: () => void;
}

export const TabBarItem = ({
  type,
  isSelected,
  hasGap = false,
  onPressed
}: TabBarItemProps) => {
  const navigate = useNavigate();

  const renderHomeTab = () => {
    return (
      <div
        className={`tab_item_${isSelected ? 'selected' : 'default'}`}
        onClick={() => navigate('/')}
      >
        <HomeIcon
          color={
            type === 'home' && isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'
          }
        />
        <div
          className={`${
            isSelected ? 'selected_txt' : 'default_txt'
          } font23 font-bold`}
        >
          Home
        </div>
      </div>
    );
  };

  const renderBookMarkTab = () => {
    return (
      <div
        className={`tab_item_${isSelected ? 'selected' : 'default'}`}
        onClick={() => navigate('/bookmark')}
      >
        <BookMarkIcon
          color={
            type === 'bookmark' && isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'
          }
        />
        <div
          className={`${
            isSelected ? 'selected_txt' : 'default_txt'
          } font23 font-bold`}
        >
          Bookmarked
        </div>
      </div>
    );
  };

  const renderGroupTab = () => {
    return (
      <div
        className={`tab_item_${isSelected ? 'selected' : 'default'}`}
        onClick={() => navigate('/group')}
      >
        <GroupIcon
          color={
            type === 'group' && isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'
          }
        />
        <div
          className={`${
            isSelected ? 'selected_txt' : 'default_txt'
          } font23 font-bold`}
        >
          Groups
        </div>
      </div>
    );
  };

  const renderOrgTab = () => {
    return (
      <div
        className={`tab_item_${isSelected ? 'selected' : 'default'}`}
        onClick={() => navigate('/school')}
      >
        <OrgIcon
          color={
            type === 'org' && isSelected ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'
          }
        />
        <div
          className={`${
            isSelected ? 'selected_txt' : 'default_txt'
          } font23 font-bold`}
        >
          Schools
        </div>
      </div>
    );
  };

  return (
    <Tooltip
      title={
        <div className="font-regular font14">
          {type === 'home'
            ? `Your knowledge base to upload, organize and share insights and Q&A - generated from your documents, notes, audio notes, and web links`
            : type === 'group'
            ? `Generate and share insights with groups`
            : type === 'org'
            ? `Find the latest insights from your school network`
            : ''}
        </div>
      }
    >
      <div
        className="tab_bar_item"
        style={{ marginRight: hasGap ? 24 : 0 }}
        onClick={onPressed}
      >
        {type === 'home' && renderHomeTab()}
        {type === 'bookmark' && renderBookMarkTab()}
        {type === 'group' && renderGroupTab()}
        {type === 'org' && renderOrgTab()}
      </div>
    </Tooltip>
  );
};
