import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React from 'react';
import moment from 'moment';

import './index.styles.scss';
import RobotIcon from 'assets/svgs/RobotIcon';

export const CardJGQuestion = ({ data }) => {
  return (
    <div className="card_jg_question">
      <div className="card_jg_question_top">
        {data.creator_id ? (
          data.creator_image && data.creator_image.startsWith('https') ? (
            <img
              src={data.creator_image}
              style={{ width: 20, height: 20, borderRadius: 20 }}
            />
          ) : (
            <DefaultAvatarIcon size={20} />
          )
        ) : (
          <RobotIcon size={20} />
        )}
        <div className="card_jg_question_top_creator_name font-medium font12">
          {data.creator_id ? data.creator_name : 'Group AI'}
        </div>
        <div className="card_jg_question_top_created_date font-regular font12">
          {moment(data.timestamp).format('DD MMM YYYY')}
        </div>
      </div>
      <div className="card_jg_question_body">
        <div
          className="card_jg_question_body_title font-medium font20"
          style={{ marginTop: 16 }}
        >
          {data.question}
        </div>
        <div
          className="card_jg_question_body_answer font-regular font17"
          style={{ marginTop: 8 }}
        >
          {data.answer}
        </div>
      </div>
      <div className="card_jg_question_bottom"> </div>
    </div>
  );
};
