import React from 'react';

function AngleDoubleDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#FF3E9A"
        d="M11.29 11.46a1 1 0 001.42 0l3-3A1.02 1.02 0 0014.29 7L12 9.34 9.71 7a1.018 1.018 0 00-1.42 1.46l3 3zm3 1.08L12 14.84l-2.29-2.3A1.018 1.018 0 108.29 14l3 3a1 1 0 001.42 0l3-3a1.004 1.004 0 10-1.42-1.42v-.04z"
      ></path>
    </svg>
  );
}

export default AngleDoubleDownIcon;
