import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import RoundOutIcon from 'assets/svgs/RoundOutIcon';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.styles.scss';

interface CardSharedLinkProps {
  data: any;
}

export const CardSharedLink = ({ data }: CardSharedLinkProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    var element = document.getElementById(`card_shared_link_title_${data.id}`);

    if (isElementOverflowing(element)) {
      wrapContentsInMarquee(element);
    }
  }, []);

  function isElementOverflowing(element) {
    var overflowX = element.offsetWidth < element.scrollWidth,
      overflowY = element.offsetHeight < element.scrollHeight;

    return overflowX || overflowY;
  }

  function wrapContentsInMarquee(element) {
    var marquee = document.createElement('marquee'),
      contents = element.innerText;

    marquee.innerText = contents;
    element.innerHTML = '';
    element.appendChild(marquee);
  }

  const onDetail = () => {
    window.open(`https://${data.domain}`);
  };

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  return (
    <div className="card_shared_link clickable" onClick={onDetail}>
      <div className="top_view">
        {data.image && <img src={data.image} className="top_image" />}
        <div className="mark_view clickable">
          <RoundOutIcon />
        </div>
      </div>
      <div
        className="link_title font-bold font14"
        style={{ marginTop: 8 }}
        id={`card_shared_link_title_${data.id}`}
      >
        {data.site_title && data.site_title !== ''
          ? data.site_title
          : data.site_name}
      </div>
      <div className="row_align_items">
        <LinkIcon color="black" opacity={0.2} />
        <div
          className="link_txt font-regular font10"
          style={{ color: 'rgba(0, 0, 0, 0.5)' }}
        >
          {data.domain}
        </div>
      </div>
      <div className="row_align_items clickable" onClick={onViewProfile}>
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img
            src={data.creator_image}
            style={{ width: 16, height: 16, borderRadius: 16 }}
          />
        ) : (
          <DefaultAvatarIcon size={16} />
        )}
        <div className="font-medium font10" style={{ marginLeft: 4 }}>
          {data.creator_name ? data.creator_name : 'Anonymous'}
        </div>
      </div>
    </div>
  );
};
