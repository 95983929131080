import React from 'react';

function HorizontalDetailIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 20}
      height={props.size ?? 20}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g opacity="0.4">
        <path
          d="M15.8333 10.8346C16.2936 10.8346 16.6667 10.4615 16.6667 10.0013C16.6667 9.54106 16.2936 9.16797 15.8333 9.16797C15.3731 9.16797 15 9.54106 15 10.0013C15 10.4615 15.3731 10.8346 15.8333 10.8346Z"
          fill={props.color ?? '#FF3E9A'}
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0003 10.8346C10.4606 10.8346 10.8337 10.4615 10.8337 10.0013C10.8337 9.54106 10.4606 9.16797 10.0003 9.16797C9.54009 9.16797 9.16699 9.54106 9.16699 10.0013C9.16699 10.4615 9.54009 10.8346 10.0003 10.8346Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16634 10.8346C4.62658 10.8346 4.99967 10.4615 4.99967 10.0013C4.99967 9.54106 4.62658 9.16797 4.16634 9.16797C3.7061 9.16797 3.33301 9.54106 3.33301 10.0013C3.33301 10.4615 3.7061 10.8346 4.16634 10.8346Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default HorizontalDetailIcon;
