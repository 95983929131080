import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import StarIcon from 'assets/svgs/StarIcon';
import UCoinIcon from 'assets/svgs/UCoinsIcon';

import './index.styles.scss';
import { CardFileAiAnswer } from '../CardFileAIAnswer';
import { CardOtherUserAnswer } from '../CardOtherUserAnswer';
import { QuestionBookmarkData } from 'components/Main/Home/Bookmark/CardBookmark';
import { apiGetQAAnswers, apiQAAnswer } from 'shared/api/qa';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { useNavigate } from 'react-router-dom';

interface CardAskedQuestionProps {
  data: QuestionBookmarkData;
}

export const CardAskedQuestion = ({ data }: CardAskedQuestionProps) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [questionPoints, setQuestionPoints] = useState(data.points);
  const [showAddYourAnswer, setShowAddYourAnswer] = useState(false);

  const [yourAnswer, setYourAnswer] = useState('');
  const [isYourAnswerPosting, setIsYourAnswerPosting] = useState(false);

  const [otherAnswers, serOtherAnswers] = useState<Array<any>>([]);
  const [hasMoreOther, setHasMoreOther] = useState(true);
  const [lastIdOther, setLastIdOther] = useState(0);
  const [isLoadingOther, setIsLoadingOther] = useState(false);
  const [totalOtherAnswer, setTotalOtherAnswer] = useState(0);

  useEffect(() => {
    serOtherAnswers([]);
    if (data) loadOtherAnswers();
  }, [data]);

  const loadOtherAnswers = async (init = true) => {
    if (init) {
      setIsLoadingOther(true);

      const res = await apiGetQAAnswers({
        bookmark: data.bookmark_id,
        limit: 1
      });

      if (res.success) {
        serOtherAnswers(res.data);
        setHasMoreOther(res.hasMore);
        setLastIdOther(res.lastId);
        setTotalOtherAnswer(res.total_count - 1);
      }

      setIsLoadingOther(false);
    } else {
      if (!isLoadingOther && hasMoreOther) {
        setIsLoadingOther(true);

        const res = await apiGetQAAnswers({
          bookmark: data.bookmark_id,
          limit: 5,
          offset: lastIdOther
        });

        if (res.success) {
          serOtherAnswers((prev) => [...prev, ...res.data]);
          setHasMoreOther(res.hasMore);
          setLastIdOther(res.lastId);
          setTotalOtherAnswer(res.total_answer - 1);
        }

        setIsLoadingOther(false);
      }
    }
  };

  const onPostAnswer = async () => {
    setIsYourAnswerPosting(true);
    const res = await apiQAAnswer({
      bookmark: String(data.bookmark_id),
      answer: yourAnswer,
      is_anonymous: false,
      media_type: 'text'
    });
    if (res.sucess) {
      setYourAnswer('');
      setShowAddYourAnswer(false);
      onPostedAnswer();
    }
    setIsYourAnswerPosting(false);
  };

  const onPostedAnswer = () => {
    loadOtherAnswers();
  };

  const handleUpdateVote = (point) => {
    setQuestionPoints(point);
  };

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const convertedArrOtherAnswer = useMemo(() => {
    if (data.top_answer_id) {
      return otherAnswers.filter(
        (item, index) => item.id !== data.top_answer_id
      );
    } else {
      return otherAnswers;
    }
  }, [otherAnswers, data]);

  const renderQuestion = () => {
    return (
      <div>
        <div className="row_space_between">
          {renderQuestionLeft()}
          {renderQuestionRight()}
        </div>
        <div className="font-regular font28" style={{ marginTop: 24 }}>
          {data.question}
        </div>
      </div>
    );
  };

  const renderQuestionLeft = () => {
    if (!data.creator_id) {
      return (
        <div className="align-vertical-center">
          <DefaultAvatarIcon size={24} />

          <div
            className="font-medium font16 user_name"
            style={{ marginLeft: 8 }}
          >
            Anonymous
          </div>
        </div>
      );
    } else {
      return (
        <div className="align-vertical-center">
          {data.creator_image && data.creator_image?.startsWith('https') ? (
            <img className="creator_image" src={data.creator_image}></img>
          ) : (
            <DefaultAvatarIcon size={24} />
          )}

          <div
            className="font-medium font16 user_name"
            style={{ marginLeft: 8 }}
            onClick={onViewProfile}
          >
            {data.creator_name}
          </div>
        </div>
      );
    }
  };

  const renderQuestionRight = () => {
    return (
      <div className="row_align_items">
        <div className="answer_point">
          <UCoinIcon />
          &nbsp;
          {`${questionPoints} pt${questionPoints > 1 ? 's' : ''}`}
        </div>
        <div className="question_date font14">
          {moment(data.timestamp).format('DD MMM yyyy').toString()}
        </div>
      </div>
    );
  };

  const renderFileAiAnswer = () => {
    return <CardFileAiAnswer data={data} enableShowMore={true} />;
  };

  const renderOtherUserAnswers = () => {
    return (
      <div className="other_user_answers">
        <div className="font-bold font20">Other user answers</div>
        {convertedArrOtherAnswer.map((item, index) => {
          return (
            <CardOtherUserAnswer
              data={item}
              key={`card_other_answers_${index}`}
              onVoteUpdate={(point) => handleUpdateVote(point)}
            />
          );
        })}
        <div className="row_align_items" style={{ marginTop: 32 }}>
          <div
            className="show_more_answer font-medium font14 clickable"
            onClick={() => {
              loadOtherAnswers(false);
            }}
          >
            {hasMoreOther ? (
              isLoadingOther ? (
                <LoadingCircular size={16} color="main" />
              ) : (
                totalOtherAnswer - convertedArrOtherAnswer.length > 0 && (
                  <div style={{ marginRight: 16 }}>
                    {`Show More Answers(${
                      totalOtherAnswer - convertedArrOtherAnswer.length
                    })`}
                  </div>
                )
              )
            ) : (
              <div
                onClick={() => loadOtherAnswers(true)}
                style={{ marginRight: 16 }}
              >
                Hide
              </div>
            )}
          </div>
          {!showAddYourAnswer && (
            <div
              className="add_your_answer font-medium font14 clickable"
              onClick={() => {
                setShowAddYourAnswer(true);
              }}
            >
              Add Your Answer
            </div>
          )}
        </div>
      </div>
    );
  };
  const renderAddYourAnswer = () => {
    return (
      <>
        <div
          className="font-bold font16"
          style={{ marginTop: '16px', marginBottom: '16px' }}
        >
          Add your answer
        </div>
        <div className="card_add_your_answer">
          <div className="user_info_bar">
            <div className="align-vertical-center">
              {userInfo?.image ? (
                <img
                  className="creator_image"
                  src={userInfo?.image}
                  width="24"
                  height="24"
                  style={{ borderRadius: 24 }}
                />
              ) : (
                <DefaultAvatarIcon size={24} />
              )}
              <div
                className="font-medium font16 user_name"
                style={{ marginLeft: 8 }}
              >
                {userInfo?.name}
              </div>
            </div>
          </div>
          <div className="user_answer_body">
            <textarea
              className="question_box_input font16 font-regular"
              placeholder="Write your answer here..."
              onChange={(e) => {
                setYourAnswer(e.target.value);
              }}
            />
          </div>
          <div className="user_answer_bottom_bar font-medium">
            <div
              className="cancel_btn clickable font12"
              onClick={() => {
                setShowAddYourAnswer(false);
              }}
            >
              Cancel
            </div>
            <div
              className="post_answer_btn clickable text-white font-medium font12"
              onClick={onPostAnswer}
            >
              {isYourAnswerPosting ? (
                <LoadingCircular color="white" size={14} />
              ) : (
                'Post Answer'
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="card_asked_question">
      {renderQuestion()}
      {renderFileAiAnswer()}
      {renderOtherUserAnswers()}
      {showAddYourAnswer && renderAddYourAnswer()}
    </div>
  );
};
