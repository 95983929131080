import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import './index.styles.scss';
import AllOrgIcon from 'assets/svgs/AllOrgIcon';

import { apiGetUserOrganisations } from 'shared/api/user';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import BG_EMPTY_EVENT from 'assets/pngs/bg_empty_event.png';
import { apiGetOrgTopics } from 'shared/api/org';
import { CardTopic } from './components/CardTopic';
import { CardEvent } from './components/CardEvent';
import { CardOrg } from './components/CardOrg';
import { apiEventAll } from 'shared/api/event';
import { CreateEventModal } from 'components/Modals/CreateEventModal';

interface OrgLeftMenuProps {
  selectedOrg: any;
  selectedTopic: Array<string>;
  arrOrganisation: any;
  onSelectedOrg: (newOrg) => void;
  onSelectedTopics: (topics) => void;
  onSetArrOrg: (orgs) => void;
}

export const OrgLeftMenu = ({
  selectedOrg,
  selectedTopic,
  arrOrganisation,
  onSelectedOrg,
  onSelectedTopics,
  onSetArrOrg
}: OrgLeftMenuProps) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const { hasNewEvent } = useSelector((state: RootState) => state.app);

  const [lastIdOrg, setLastIdOrg] = useState(0);
  const [hasMoreOrg, setHasMoreOrg] = useState(true);
  const [isLoadingOrg, setIsLoadingOrg] = useState(false);

  const [eventTab, setEventTab] = useState<'day' | 'week' | 'month'>('day');

  const [arrInterest, setArrInterest] = useState<Array<any>>([]);
  const [lastIdInterest, setLastIdInterest] = useState(0);
  const [hasMoreInterest, setHasMoreInterest] = useState(true);
  const [isLoadingInterest, setIsLoadingInterest] = useState(false);

  //create event modal
  const [isEventModalShow, setIsEventModalShow] = useState(false);
  const [isLoadedEvent, setIsLoadedEvent] = useState(false);

  const [arrEvent, setArrEvent] = useState<any>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (userInfo) {
      loadOrgData();
    }
  }, [userInfo]);

  useEffect(() => {
    loadEventData();
  }, [eventTab]);

  useEffect(() => {
    loadEventData();
  }, [hasNewEvent]);

  useEffect(() => {
    if (userInfo) {
      loadInterestData();
    }
  }, [selectedOrg, userInfo]);

  useEffect(() => {
    const existed = selectedTopic.filter((sItem, sIndex) => {
      return (
        arrInterest.filter((tItem, tIndex) => tItem.topic_id === sItem).length >
        0
      );
    });

    if (existed.length === 0) {
      onSelectedTopics([]);
    }
  }, [arrInterest]);

  const loadOrgData = async (init = true) => {
    if (init) {
      onSetArrOrg([]);
      setIsLoadingOrg(true);
      const res = await apiGetUserOrganisations(userInfo ? userInfo.id : '');

      if (res.success) {
        onSetArrOrg(res.data);
        setLastIdOrg(res.lastId);
        setHasMoreOrg(res.hasMore);
      } else {
        onSetArrOrg([]);
      }
      setIsLoadingOrg(false);
    } else {
      if (hasMoreOrg && !isLoadingOrg) {
        setIsLoadingOrg(true);
        const res = await apiGetUserOrganisations(
          userInfo ? userInfo.id : '',
          5,
          lastIdOrg
        );

        if (res.success) {
          onSetArrOrg([...arrOrganisation, ...res.data]);
          setLastIdOrg(res.lastId);
          setHasMoreOrg(res.hasMore);
        }
        setIsLoadingOrg(false);
      }
    }
  };

  const loadInterestData = async (init = true) => {
    const orgs = !selectedOrg ? null : `${selectedOrg.id}`;
    if (init) {
      setIsLoadingInterest(true);
      const res = await apiGetOrgTopics({ orgs: orgs, limit: 5, offset: 0 });

      if (res.success) {
        setArrInterest(res.data);
        setLastIdInterest(res.lastId);
        setHasMoreInterest(res.hasMore);
      }
      setIsLoadingInterest(false);
    } else {
      if (hasMoreInterest && !isLoadingInterest) {
        setIsLoadingInterest(true);
        const res = await await apiGetOrgTopics({
          orgs: orgs,
          limit: 5,
          offset: lastIdInterest
        });

        if (res.success) {
          setArrInterest((prev) => [...prev, ...res.data]);
          setLastIdInterest(res.lastId);
          setHasMoreInterest(res.hasMore);
        }
        setIsLoadingInterest(false);
      }
    }
  };

  const loadEventData = async (init = true) => {
    if (init) {
      const res = await apiEventAll({
        query: '',
        orgs: null,
        period: eventTab,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrEvent(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoadedEvent(true);
      }
    } else {
      const res = await apiEventAll({
        query: '',
        orgs: null,
        period: eventTab,
        limit: 10,
        offset: lastId
      });

      if (res.success) {
        setArrEvent((prev) => [...prev, ...res.data]);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      }
    }
  };

  const onJoinNewOrg = () => {
    navigate(`/school/join/`);
  };

  const onViewEvents = () => {
    navigate(`/school/events/`);
  };

  const onClickedTopic = (topicId) => {
    if (selectedTopic.includes(topicId)) {
      onSelectedTopics(selectedTopic.filter((item, index) => item !== topicId));
    } else {
      onSelectedTopics([...selectedTopic, topicId]);
    }
  };

  const renderOrgs = () => {
    return (
      <div>
        <div className="sub_title font-bold font20">Your Schools</div>
        <div
          className="all_org_box clickable"
          style={
            selectedOrg
              ? { backgroundColor: 'transparent', boxShadow: 'none' }
              : { backgroundColor: 'white' }
          }
          onClick={() => {
            onSelectedOrg(null);
            navigate('/school');
          }}
        >
          <div className="all_org_box_icon">
            <AllOrgIcon />
          </div>
          <div className="font-bold font20 all_org_box_label">All Schools</div>
        </div>
        <div className="org_list" id="org_list_left_panel">
          <InfiniteScroll
            dataLength={arrOrganisation?.length}
            next={() => loadOrgData(false)}
            hasMore={hasMoreOrg}
            loader={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LoadingCircular size={20} color="main" />
              </div>
            }
            refreshFunction={() => {}}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            style={{ overflow: 'hidden' }}
          >
            {arrOrganisation.map((item, index) => {
              return (
                <CardOrg
                  key={`card_org_${index}`}
                  data={item}
                  selectedOrg={selectedOrg}
                  onClick={(orgInfo) => {
                    onSelectedOrg(orgInfo);
                  }}
                />
              );
            })}
          </InfiniteScroll>
        </div>
        <div
          className="font-bold font16 join_new_btn clickable"
          onClick={() => onJoinNewOrg()}
        >
          + Join New
        </div>
      </div>
    );
  };

  const renderInterest = () => {
    return (
      <div className="org_interest" style={{ marginTop: 48 }}>
        <div className="sub_title font-bold font20">Interests</div>
        <div
          className="all_org_box clickable"
          style={
            selectedTopic.length > 0
              ? { backgroundColor: 'transparent', boxShadow: 'none' }
              : { backgroundColor: 'white' }
          }
          onClick={() => {
            onSelectedTopics([]);
          }}
        >
          <div className="font-bold font20 all_org_box_label">
            All Interests
          </div>
        </div>
        <div className="org_list" id="org_list_left_panel">
          <InfiniteScroll
            dataLength={arrInterest?.length}
            next={() => loadOrgData(false)}
            hasMore={hasMoreInterest}
            loader={
              <div style={{ display: 'flex', justifyContent: 'center' }}></div>
            }
            refreshFunction={() => {}}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            style={{ overflow: 'hidden' }}
          >
            {arrInterest.map((item, index) => {
              return (
                <CardTopic
                  key={`card_topic_${index}`}
                  data={item}
                  isSelected={selectedTopic.includes(item.topic_id)}
                  onClick={(topicId) => {
                    onClickedTopic(topicId);
                  }}
                />
              );
            })}
          </InfiniteScroll>
        </div>
        {isLoadingInterest ? (
          <div style={{ marginTop: 24 }}>
            <LoadingCircular size={20} color={'main'} />
          </div>
        ) : (
          hasMoreInterest && (
            <div
              className="font-bold font16 join_new_btn clickable"
              onClick={() => loadInterestData(false)}
            >
              Show More
            </div>
          )
        )}
        <div style={{ height: 24 }}></div>
      </div>
    );
  };

  const renderEvents = () => {
    return (
      <div className="org_events">
        <div className="row_space_between" style={{ padding: '0px 8px' }}>
          <div className="sub_title font-bold font20">School Events</div>
          <div
            className="font-bold font16 clickable"
            style={{ color: '#FF3E9A' }}
            onClick={onViewEvents}
          >
            View All
          </div>
        </div>
        {renderEventsTab()}
        {isLoadedEvent && arrEvent.length === 0
          ? renderEmpty()
          : renderEventsBody()}
      </div>
    );
  };

  const renderEventsTab = () => {
    return (
      <div className="org_tab_container row_align_items">
        <div
          className={`${
            eventTab === 'day' ? 'selected_tab' : 'default_tab'
          } clickable font-bold font12`}
          onClick={() => setEventTab('day')}
        >
          Today
        </div>
        <div
          className={`${
            eventTab === 'week' ? 'selected_tab' : 'default_tab'
          } clickable font-bold font12`}
          onClick={() => setEventTab('week')}
        >
          This Week
        </div>
        <div
          className={`${
            eventTab === 'month' ? 'selected_tab' : 'default_tab'
          } clickable font-bold font12`}
          onClick={() => setEventTab('month')}
        >
          This Month
        </div>
      </div>
    );
  };

  const renderEventsBody = () => {
    return (
      <div className="org_events_body">
        {arrEvent.map((item, index) => {
          return <CardEvent key={`card_org_event_${index}`} data={item} />;
        })}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div className="event_empty_view">
        <div>
          <img
            src={BG_EMPTY_EVENT}
            style={{ width: 136, aspectRatio: 213 / 119 }}
          />
        </div>
        <div className="font-bold font18" style={{ marginTop: 16 }}>
          No Event for Today
        </div>
        <div
          className="font-regular font12"
          style={{ opacity: 0.6, marginTop: 8, textAlign: 'center' }}
        >
          Host your own event and hang out
          <br />
          with your friends
        </div>
        <div
          className="create_event_btn font-bold font14 clickable"
          onClick={() => {
            setIsEventModalShow(true);
          }}
        >
          Create Event
        </div>
      </div>
    );
  };

  const renderCreateEventModal = () => {
    return (
      <CreateEventModal
        show={isEventModalShow}
        onClose={() => setIsEventModalShow(false)}
        onSuccess={() => {
          loadEventData();
          setIsEventModalShow(false);
        }}
      />
    );
  };

  return (
    <div className="org_left_panel">
      {renderOrgs()}
      {renderEvents()}
      {renderInterest()}
      {renderCreateEventModal()}
    </div>
  );
};
