import Carousel from 'react-spring-3d-carousel';
import LightBulbIcon from 'assets/svgs/LightBulbIcon';
import { StatementShareModal } from 'components/Modals/StatementShareModal';
import { StatementTextModal } from 'components/Modals/StatementTextModal';
import React, { useEffect, useState } from 'react';

import { CardDidYouKnow } from './CardDidYouKnow';
import RoundArrowLeftIcon from 'assets/svgs/RoundArrowLeftIcon';
import { StatementData } from './CardDidYouKnow/index';
import IMG_EMPTY_STATUS from 'assets/pngs/empty_did_you_know.png';

import './index.styles.scss';
import { useSwipeable } from 'react-swipeable';

import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { apiGetContentStatements } from 'shared/api/content';
interface TopDidYouKnowProps {
  arrOrganisation: any;
}

export const TopDidYouKnow = ({ arrOrganisation }: TopDidYouKnowProps) => {
  const [statements, setStatements] = useState([]);

  const [isDetailModalShow, setIsDetailModalShow] = useState(false);
  const [isShareModalShow, setIsShareModalShow] = useState(false);
  const [isLoadingStatements, setIsLoadingStatements] = useState(false);
  const [selectedStatement, setSelectedStatement] = useState<
    StatementData | undefined
  >();
  const [currentSlider, setCurrentSlider] = useState<number>(0);

  useEffect(() => {
    loadQAStatement();
  }, [arrOrganisation]);

  const loadQAStatement = async () => {
    setIsLoadingStatements(true);

    if (arrOrganisation.length > 0) {
      const res = await apiGetContentStatements({
        to: arrOrganisation[0].id,
        to_type: 'organisation',
        limit: 10,
        offset: 0
      });
      if (res.success) {
        setStatements(res.data);
      }
      setIsLoadingStatements(false);
    }
  };

  const onShareHandler = (data) => {
    setSelectedStatement(data);
    setIsShareModalShow(true);
  };

  const swipeActionHandler = useSwipeable({
    onSwipedLeft: () => setCurrentSlider((prev) => prev - 1),
    onSwipedRight: () => setCurrentSlider((prev) => prev + 1),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  const CardData = (items: any[]) => {
    const cardArray: any[] = [];
    items.map((data, index) => {
      cardArray.push({
        key: `uuid_${data.name}_${index}`,
        content: (
          <CardDidYouKnow
            key={`card_statement_${index}`}
            onClick={() => {
              setCurrentSlider(index);
            }}
            data={data}
            onDetail={(data) => {
              setSelectedStatement(data);
              setIsDetailModalShow(true);
            }}
            onShare={(data) => {
              onShareHandler(data);
            }}
          />
        )
      });
    });
    return cardArray;
  };

  const renderEmptyStatus = () => {
    return (
      <div className="did_you_know_empty">
        <img src={IMG_EMPTY_STATUS} style={{ width: 260, height: 180 }} />
        <div style={{ marginLeft: 40 }}>
          <div className="font-bold" style={{ fontSize: 32 }}>
            No fun facts available yet
          </div>
          <div
            className="font-regular font16"
            style={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: 16 }}
          >
            Start to contribute content to the knowledge base by sharing
            documents, links or asking and answering questions
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="did_you_know">
      {arrOrganisation.length > 0 && (
        <>
          <div className="did_you_know_top">
            <div className="light_bulb">
              <LightBulbIcon color="#FFFFFF" size={28} />
            </div>
            <div className="title font-bold font32">Did You Know?</div>
          </div>
          {isLoadingStatements ? (
            <LoadingBarAnimation />
          ) : statements.length > 0 ? (
            <div className="carouselWrapper">
              <div
                className="carouselBox clickable"
                {...swipeActionHandler}
                id="carousel_box_top"
              >
                <Carousel
                  slides={CardData(statements)}
                  goToSlide={currentSlider}
                  showNavigation={false}
                  offsetRadius={2}
                />
              </div>
            </div>
          ) : (
            renderEmptyStatus()
          )}
          {statements.length > 0 && (
            <div className="arrowWrapper">
              <div
                className="arrowBox"
                onClick={() => setCurrentSlider((prev) => prev - 1)}
              >
                <RoundArrowLeftIcon isEnable={true} />
              </div>
              <div
                className="arrowBox"
                style={{ transform: 'rotate(-180deg)' }}
                onClick={() => setCurrentSlider((prev) => prev + 1)}
              >
                <RoundArrowLeftIcon isEnable={true} />
              </div>
            </div>
          )}
          <StatementTextModal
            show={isDetailModalShow}
            onClose={() => {
              setIsDetailModalShow(false);
            }}
            selectedStatement={selectedStatement}
            onSuccess={() => {
              setIsShareModalShow(true);
              setIsDetailModalShow(false);
            }}
          />
          <StatementShareModal
            show={isShareModalShow}
            onClose={() => {
              setIsShareModalShow(false);
            }}
            selectedStatement={selectedStatement}
          />
        </>
      )}
    </div>
  );
};
