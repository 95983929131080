import React, { useEffect, useState } from 'react';
import usePreventBodyScroll from 'shared/utils/usePreventBodyScroll';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useDebounce } from 'use-debounce';
import { LeftArrow, RightArrow } from './ArrowBtns';

import './index.styles.scss';
import { LinkPreviewTopFilterItem } from './Item';
import { apiGetExtensionTopics } from 'shared/api/extension';

interface LinkPreviewTopFilterProps {
  totalLinkCount: number;
  isReceived: boolean;
  isBot: boolean;
  item_id: any;
  item_type: any;
  onChangedSelection: (topics) => void;
}

export const LinkPreviewTopFilter = ({
  totalLinkCount,
  isReceived,
  isBot,
  item_id,
  item_type,
  onChangedSelection
}: LinkPreviewTopFilterProps) => {
  const [arrTopic, setArrTopic] = useState<Array<any>>([]);
  const [lastIdTopic, setLastIdTopic] = useState(0);
  const [hasMoreTopic, setHasMoreTopic] = useState(true);
  const [isLoadingTopic, setIsLoadingTopic] = useState(false);

  const [selectedTopics, setSelectedTopics] = useState<Array<any>>([]);
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const [debouncedKeyword] = useDebounce(selectedTopics, 500);
  const [receivedCount, setReceivedCount] = useState(0);

  useEffect(() => {
    loadTagList();
  }, [isReceived, isBot]);

  useEffect(() => {
    onChangedSelection(debouncedKeyword);
  }, [debouncedKeyword]);

  const loadTagList = async (init = true) => {
    if (init) {
      setIsLoadingTopic(true);

      const res = await apiGetExtensionTopics({
        item_id,
        item_type,
        received: isReceived ? 'true' : 'false',
        is_summarised: isBot ? 'true' : 'false',
        limit: 20,
        offset: 0
      });

      if (res.success) {
        setArrTopic(res.data);
        setLastIdTopic(res.lastId);
        setHasMoreTopic(res.hasMore);
        setReceivedCount(res.received_count);
      }
      setIsLoadingTopic(false);
    } else {
      if (!isLoadingTopic && hasMoreTopic) {
        setIsLoadingTopic(true);

        const res = await apiGetExtensionTopics({
          item_id,
          item_type,
          received: isReceived ? 'true' : 'false',
          is_summarised: isBot ? 'true' : 'false',
          limit: 20,
          offset: lastIdTopic
        });
        if (res.success) {
          setArrTopic((prev) => [...prev, ...res.data]);
          setLastIdTopic(res.lastId);
          setHasMoreTopic(res.hasMore);
        }
        setIsLoadingTopic(false);
      }
    }
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const onLoadMoreInterest = (vc) => {
    if (vc.isLastItemVisible) {
      loadTagList(false);
    }
  };

  const onClickTopic = (topic: any) => {
    if (
      selectedTopics.filter(
        (selectedItem: any, index) => topic.topic_id === selectedItem
      ).length > 0
    ) {
      setSelectedTopics((prev) => {
        return prev.filter(
          (selectedItem: any, index) => topic.topic_id !== selectedItem
        );
      });
    } else {
      setSelectedTopics((prev) => [...prev, topic.topic_id]);
    }
  };

  const renderFilters = () => {
    return (
      <div className="row_align_items" style={{ width: '100%' }}>
        {renderAllBtn()}
        <div className="link_preview_top_filter_tag">
          <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
            <ScrollMenu
              LeftArrow={LeftArrow}
              RightArrow={RightArrow}
              onWheel={onWheel}
              onUpdate={onLoadMoreInterest}
            >
              {arrTopic.map((item, index) => {
                const isSelected =
                  selectedTopics.filter(
                    (selectedItem, index) => item.topic_id === selectedItem
                  ).length > 0;

                return (
                  <LinkPreviewTopFilterItem
                    key={`link_preview_top_filter_item_${index}`}
                    isSelected={isSelected}
                    data={item}
                    onClick={onClickTopic}
                  />
                );
              })}
            </ScrollMenu>
          </div>
        </div>
      </div>
    );
  };

  const renderAllBtn = () => {
    return (
      <LinkPreviewTopFilterItem
        key={`link_preview_top_filter_item_${-1}`}
        isSelected={selectedTopics.length == 0}
        data={{
          topic_id: 0,
          topic: 'All',
          count: receivedCount,
          topic_emoji: ''
        }}
        onClick={() => {
          setSelectedTopics([]);
        }}
      />
    );
  };

  const renderSearch = () => {
    return <div></div>;
  };

  return (
    <div className="link_preview_top_filter">
      {renderFilters()}
      {renderSearch()}
    </div>
  );
};
