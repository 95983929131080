import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiQAFile } from 'shared/api/qa';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';

import './index.styles.scss';

export const ViewDoc = () => {
  const params = useParams();
  const navigate = useNavigate();

  const dumpId = params.dumpId;
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    onLoadData();
  }, []);

  const onBack = () => {
    navigate(-1);
  };

  const onLoadData = async () => {
    if (dumpId) {
      setIsLoading(true);

      const res = await apiQAFile({ dump: dumpId });
      if (res.success) {
        setTitle(res.data.title);
        setUrl(res.data.url);
      }

      setIsLoading(false);
    }
  };

  const renderTop = () => {
    return (
      <div className="top row_space_between row_align_items">
        <div className="row_align_items">
          <div className="clickable" onClick={onBack}>
            <AngleLeftIcon color="#FF3E9A" />
          </div>
          <div className="font-bold font26" style={{ marginLeft: 8 }}>
            {title}
          </div>
        </div>
        <div className="row_align_items">
          <div className="cancel_btn clickable" onClick={onBack}>
            <div className="font-bold font16">Cancel</div>
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="body">
        <div className="font-regular font24 text_input">{url}</div>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingBarAnimation />;
  } else {
    return (
      <div className="write_doc">
        {renderTop()}
        {renderBody()}
      </div>
    );
  }
};
