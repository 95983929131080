import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import FileIcon from 'assets/svgs/FileIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import LockIcon from 'assets/svgs/LockIcon';
import MicIcon from 'assets/svgs/MicIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.styles.conversation.scss';

export const ConversationReferenceDetail = ({ data }) => {
  const navigate = useNavigate();

  const onClickRef = (itemData) => {
    if (itemData.fileType === 'pdf') {
      navigate(`/document/${itemData.id}`);
    } else if (itemData.fileType === 'url' || itemData.fileType === 'html') {
      window.open(itemData.image);
    } else if (itemData.fileType === 'text') {
      navigate(`/file/${itemData.id}`);
    } else if (itemData.fileType === 'audio') {
      navigate(`/audio/${itemData.id}`);
    }
  };

  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 22, height: 22 }} className="align-center">
        {type === 'pdf' ? (
          <PdfFileIcon size={22} />
        ) : type === 'audio' ? (
          <MicIcon size={18} color="#FF3E9A" opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={22} color="#FF3E9A" opacity={1} />
        ) : (
          <FileIcon />
        )}
      </div>
    );
  };

  return (
    <>
      {data.length > 0 && (
        <div className="conversation_reference_box">
          {data.map((item, index) => {
            return (
              <div key={`card_ref_${index}`}>
                <div
                  className="reference_user clickable"
                  onClick={() => {
                    onClickRef(item);
                  }}
                >
                  <div
                    style={{ width: 16, height: 16 }}
                    className="align-center"
                  >
                    {item.name && item.image.startsWith('https') ? (
                      <img
                        src={item.image}
                        style={{
                          width: 16,
                          height: 16,
                          borderRadius: 16
                        }}
                      />
                    ) : (
                      item.name && <DefaultAvatarIcon size={16} />
                    )}
                  </div>
                  {item.fileType && renderFileIcon(item.fileType)}

                  <div
                    className="font-medium font12"
                    style={{
                      maxWidth: 'calc(100% - 40px)',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {item.fileName ?? item.url}
                  </div>
                </div>

                <div
                  className="font-regular font12 reference_text"
                  style={{ color: 'rgba(0, 0, 0, 0.8)', marginTop: 8 }}
                >
                  {item.text && item.text}
                </div>
                {index !== data.length - 1 && (
                  <div
                    className="row_divide_line"
                    style={{ margin: '24px 0' }}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
