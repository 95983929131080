import React from "react";

function GeneralAiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      fill="none"
      viewBox="0 0 22 20"
    >
      <path
        fill="#FF3E9A"
        d="M8 13a1 1 0 100 2 1 1 0 000-2zm-7-1a1 1 0 00-1 1v2a1 1 0 102 0v-2a1 1 0 00-1-1zm20 0a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zm-5-7h-4V3.72A2 2 0 0013 2a2 2 0 10-4 0 2 2 0 001 1.72V5H6a3 3 0 00-3 3v9a3 3 0 003 3h10a3 3 0 003-3V8a3 3 0 00-3-3zm-3.28 2l-.5 2H9.78l-.5-2h3.44zM17 17a1 1 0 01-1 1H6a1 1 0 01-1-1V8a1 1 0 011-1h1.22L8 10.24A1 1 0 009 11h4a1 1 0 001-.76L14.78 7H16a1 1 0 011 1v9zm-3-4a1 1 0 100 2 1 1 0 000-2z"
      ></path>
    </svg>
  );
}

export default GeneralAiIcon;
