import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-responsive-masonry';
import { CardProfileOrg } from './CardProfileOrg/CardProfileOrg';
import UserFollowerIcon from 'assets/svgs/UserFollowerIcon';
import UserFollowingIcon from 'assets/svgs/UserFollowingIcon';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';
import { ProfileChat } from 'components/Modals/ProfileChat';
import {
  apiGetUserOrganisations,
  apiGetUserActivityAI,
  apiEditProfileImage,
  apiEditProfile,
  apiGetUserQuestions
} from 'shared/api/user';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import './Profile.styles.scss';
import StarIcon from 'assets/svgs/StarIcon';
import PointIcon from 'assets/svgs/PointIcon';
import AngleUpIcon from 'assets/svgs/AngleUpIcon';
import EditIcon from 'assets/svgs/EditIcon';
import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import { apiGetGroups } from 'shared/api/group';
import { CardProfileGroup } from './CardProfileGroup/CardProfileGroup';
import OrgIcon from 'assets/svgs/OrgIcon';
import { useNavigate } from 'react-router-dom';

import { SocialMedia } from './SocialMedia';
import { ConnectSocialModal } from 'components/Modals/ConnectSocialModal';

import OrgPng from 'assets/pngs/OrgPng.png';
import JoinedGroupPng from 'assets/pngs/JoinedGroupPng.png';
import RecentActivitiesPng from 'assets/pngs/RecentActivitiesPng.png';
import SocialMediasPng from 'assets/pngs/SocialMediasPng.png';
import { QuestionAnswerShareModal } from 'components/Modals/QuestionAnswerShareModal';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { getBase64String } from 'shared/utils-IPFS/functions';
import CameraIcon from 'assets/svgs/CameraIcon';
import EditPencilColoredIcon from 'assets/svgs/EditPencilColoredIcon';
import profileAction from 'redux/profile/actions';
import {
  CardTrendingQuestion,
  QuestionData
} from '../Organisation/OrgHome/components/TrendingQuestion/CardTrendingQuestion';

export const OwnProfileScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: RootState) => state.profile);

  const [arrOrg, setArrOrg] = useState<Array<any>>([]);
  const [lastIdOrg, setLastIdOrg] = useState(0);
  const [hasMoreOrg, setHasMoreOrg] = useState(true);
  const [isLoadingOrg, setIsLoadingOrg] = useState(false);

  const [arrGroup, setArrGroup] = useState<Array<any>>([]);
  const [lastIdGroup, setLastIdGroup] = useState(0);
  const [hasMoreGroup, setHasMoreGroup] = useState(true);
  const [isLoadingGroup, setIsLoadingGroup] = useState(false);

  const [arrUserActivityAI, setArrUserActivityAI] = useState<Array<any>>([]);
  const [lastIdUserActivityAI, setLastIdUserActivityAI] = useState(0);
  const [hasMoreUserActivityAI, setHasMoreUserActivityAI] = useState(true);
  const [isLoadingUserActivityAI, setIsLoadingUserActivityAI] = useState(false);

  const [isViewOrg, setIsViewOrg] = useState(true);
  const [isViewGroup, setIsViewGroup] = useState(true);
  const [isConnectSocial, setIsConnectSocial] = useState(false);

  const [isEditUserName, setIsEditUserName] = useState(false);
  const [isEditingUserName, setIsEditingUserName] = useState(false);
  const [newUserName, setNewUserName] = useState(userInfo?.name);

  const [isLoadedGroup, setIsLoadedGroup] = useState(false);
  const [isLoadedOrg, setIsLoadedOrg] = useState(false);
  const [selectedQuestionData, setSelectedQuestionData] = useState<
    QuestionData | undefined
  >();
  const [isShareModalShow, setIsShareModalShow] = useState(false);
  const [shareModalType, setShareModalType] = useState<'question' | 'answer'>(
    'question'
  );

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const hiddenFileInput = useRef<any>(null);
  const { uploadBase64 } = useIPFS();

  useEffect(() => {
    if (userInfo) {
      loadOrgData();
      loadGroupData();
      loadUserActivityAIData();
      setImageUrl(userInfo?.image);
      setNewUserName(userInfo?.name);
    }
  }, [userInfo]);

  const loadOrgData = async (init = true) => {
    if (init) {
      setIsLoadingOrg(true);
      const res = await apiGetUserOrganisations(userInfo?.id);
      if (res.success) {
        setIsLoadedOrg(true);
        setArrOrg(res.data);
        setLastIdOrg(res.lastId);
        setHasMoreOrg(res.hasMore);
      }
      setIsLoadingOrg(false);
    } else {
      if (hasMoreOrg && !isLoadingOrg) {
        setIsLoadingOrg(true);
        const res = await apiGetUserOrganisations(userInfo?.id, 10, lastIdOrg);
        if (res.success) {
          setArrOrg((prev) => [...prev, ...res.data]);
          setLastIdOrg(res.lastId);
          setHasMoreOrg(res.hasMore);
        }
        setIsLoadingOrg(false);
      }
    }
  };

  const loadGroupData = async (init = true, keyword = '') => {
    if (init) {
      setIsLoadingGroup(true);
      const res = await apiGetGroups({
        query: '',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setIsLoadedGroup(true);
        setArrGroup(res.data);
        setLastIdGroup(res.lastId);
        setHasMoreGroup(res.hasMore);
      }
      setIsLoadingGroup(false);
    } else {
      if (hasMoreGroup && !isLoadingGroup) {
        setIsLoadingGroup(true);
        const res = await apiGetGroups({
          query: '',
          limit: 10,
          offset: lastIdGroup
        });

        if (res.success) {
          setArrGroup((prev) => [...prev, ...res.data]);
          setLastIdGroup(res.lastId);
          setHasMoreGroup(res.hasMore);
        }
        setIsLoadingGroup(false);
      }
    }
  };

  const loadUserActivityAIData = async (init = true) => {
    if (init) {
      setIsLoadingUserActivityAI(true);
      const res = await apiGetUserQuestions({
        id: userInfo?.id,
        offset: 0,
        limit: 10
      });
      if (res.success) {
        setArrUserActivityAI(res.data);
        setLastIdUserActivityAI(res.lastId);
        setHasMoreUserActivityAI(res.hasMore);
      }
      setIsLoadingUserActivityAI(false);
    } else {
      if (hasMoreUserActivityAI && !isLoadingUserActivityAI) {
        setIsLoadingUserActivityAI(true);
        const res = await apiGetUserQuestions({
          id: userInfo?.id,
          offset: lastIdUserActivityAI,
          limit: 10
        });
        if (res.success) {
          setArrUserActivityAI((prev) => [...prev, ...res.data]);
          setLastIdUserActivityAI(res.lastId);
          setHasMoreUserActivityAI(res.hasMore);
        }
        setIsLoadingUserActivityAI(false);
      }
    }
  };

  const onShareHandler = (data, modalType) => {
    setSelectedQuestionData(data);
    setIsShareModalShow(true);
    setShareModalType(modalType);
  };

  const onEditSocial = () => {
    setIsConnectSocial(true);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files.length) {
      const name = files[0].name;
      const size = files[0].size;
      const type = files[0].type;
      setIsUploadingFile(true);

      const base64String = await getBase64String(files[0]);
      const url = await uploadBase64(base64String, type, '');

      const res = await apiEditProfileImage({ profileImage: url });
      if (res.success) {
        setImageUrl(url + '');
      }
      setIsUploadingFile(false);
    }
  };

  const onEditUserName = () => {
    setIsEditUserName(true);
  };

  const onUpdateUserName = async () => {
    if (!!newUserName && !isEditingUserName) {
      setIsEditingUserName(true);

      const res = await apiEditProfile({ username: newUserName });
      if (res.success) {
        setIsEditUserName(false);
        dispatch(profileAction.loadUserInfo(userInfo?.id ?? 0));
      }

      setIsEditingUserName(false);
    }
  };

  const onViewPoint = () => {
    navigate('/point');
  };

  const renderUserInfo = () => {
    return (
      <>
        <div className="user_info">
          <div style={{ position: 'relative' }}>
            {userInfo !== undefined && imageUrl !== '' ? (
              <img
                src={imageUrl}
                style={{
                  width: 136,
                  height: 136,
                  borderRadius: 136,
                  objectFit: 'cover'
                }}
              />
            ) : (
              <DefaultAvatarIcon size={136} />
            )}
            <div className="camera_icon_tag clickable" onClick={handleClick}>
              {isUploadingFile ? (
                <LoadingCircular color="main" size={40} />
              ) : (
                <CameraIcon />
              )}
            </div>

            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
              accept="image/*"
            />
          </div>
          <div className="user_info_detail">
            {!isEditUserName ? (
              <div className="font-bold font32">
                {userInfo?.name}
                <span
                  className="clickable"
                  style={{ marginLeft: 6 }}
                  onClick={onEditUserName}
                >
                  <EditPencilColoredIcon />
                </span>
              </div>
            ) : (
              <div className="row_align_items">
                <input
                  value={newUserName}
                  placeholder="Enter your name here..."
                  className="sub_input font-bold font32"
                  onChange={(e) => setNewUserName(e.target.value)}
                />
                <div
                  className="edit_done_btn font-bold font16 clickable"
                  onClick={() => onUpdateUserName()}
                >
                  {isEditingUserName ? (
                    <LoadingCircular size={24} color="white" />
                  ) : (
                    <div>Done</div>
                  )}
                </div>
              </div>
            )}
            <div className="user_info_follow">
              <div className="user_info_follower">
                <UserFollowerIcon color="rgba(0, 0, 0, 0.4)" size={16} />
                <div className="font-medium font14">
                  {userInfo?.followers} followers
                </div>
              </div>
              <div className="user_info_following">
                <UserFollowingIcon color="rgba(0, 0, 0, 0.4)" size={16} />
                <div className="font-medium font14">
                  {userInfo?.followings} following
                </div>
              </div>
            </div>
            <div className="description font-regular font18">
              {userInfo?.description}
            </div>
          </div>
        </div>
        <div className="user_evaluation">
          <div className="user_evaluation_box">
            <div className="row_align_items">
              <UserFollowerIcon color="#FF3E9A" size={16} />
              <div className="font-regular font14 user_evaluation_box_label">
                Followers
              </div>
            </div>
            <div className="font-regular font14 user_evaluation_box_number">
              {userInfo?.followers}
            </div>
          </div>
          <div className="user_evaluation_box">
            <div className="row_align_items">
              <UserFollowingIcon color="#FF3E9A" size={16} opacity={1} />
              <div className="font-regular font14 user_evaluation_box_label">
                Following
              </div>
            </div>
            <div className="font-regular font14 user_evaluation_box_number">
              {userInfo?.followings}
            </div>
          </div>
          <div className="user_evaluation_box clickable" onClick={onViewPoint}>
            <div className="row_align_items">
              <PointIcon size={16} />
              <div className="font-regular font14 user_evaluation_box_label">
                Points
              </div>
            </div>
            <div className="font-regular font14 user_evaluation_box_number">
              {userInfo?.energy.toFixed(1)}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderJoinedOrg = () => {
    return (
      <div className="render_block" id={'profile_joined_org'}>
        <div className="block_top row_space_between">
          <div className="row_align_items">
            <img src={OrgPng} />
            <div className="font-bold font32" style={{ marginLeft: 16 }}>
              Joined Schools
            </div>
          </div>
          <div className="row_align_items">
            <div
              className="join_new_btn clickable"
              onClick={() => navigate('/org/join')}
            >
              <div className="plus_icon font-regular font20">+</div>
              <div className="font-bold font12">Join New</div>
            </div>
            <div
              className="fold_btn clickable"
              onClick={() => setIsViewOrg(!isViewOrg)}
            >
              {isViewOrg ? (
                <AngleUpIcon opacity={0.3} size={20} />
              ) : (
                <AngleDownIcon opacity={0.3} size={20} />
              )}
            </div>
          </div>
        </div>
        {isViewOrg &&
          (arrOrg.length > 0 ? (
            <InfiniteScroll
              dataLength={arrOrg.length}
              next={() => loadOrgData(false)}
              hasMore={hasMoreOrg}
              loader={
                <div
                  style={{ display: 'flex', justifyContent: 'center' }}
                ></div>
              }
              refreshFunction={() => {}}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
              style={{ overflow: 'hidden', width: '100%' }}
            >
              <Masonry gutter={'20px'}>
                {arrOrg.map((item, index) => {
                  return (
                    <CardProfileOrg
                      key={`card_profile_org_${index}`}
                      data={item}
                    />
                  );
                })}
              </Masonry>
            </InfiniteScroll>
          ) : (
            isLoadedOrg && renderEmptyOrg()
          ))}
      </div>
    );
  };

  const renderJoinedGroup = () => {
    return (
      <div className="render_block">
        <div className="block_top row_space_between">
          <div className="row_align_items">
            <img src={JoinedGroupPng} />
            <div className="font-bold font32" style={{ marginLeft: 16 }}>
              Joined Groups
            </div>
          </div>
          <div className="row_align_items">
            <div
              className="join_new_btn clickable"
              onClick={() => {
                localStorage.setItem('previous_page', 'profile');
                navigate('/group');
              }}
            >
              <div className="plus_icon font-regular font20">+</div>
              <div className="font-bold font12">Join New</div>
            </div>
            <div
              className="fold_btn clickable"
              onClick={() => setIsViewGroup(!isViewGroup)}
            >
              {isViewGroup ? (
                <AngleUpIcon opacity={0.3} size={20} />
              ) : (
                <AngleDownIcon opacity={0.3} size={20} />
              )}
            </div>
          </div>
        </div>
        {isViewGroup &&
          (arrGroup.length > 0 ? (
            <InfiniteScroll
              dataLength={arrGroup.length} //This is important field to render the next data
              next={() => loadGroupData(false)}
              hasMore={hasMoreGroup}
              loader={
                <div
                  style={{ display: 'flex', justifyContent: 'center' }}
                ></div>
              }
              refreshFunction={() => {}}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
              style={{ overflow: 'hidden', width: '100%' }}
            >
              <Masonry gutter={'20px'}>
                {arrGroup.map((item, index) => {
                  return (
                    <CardProfileGroup
                      key={`card_joined_group_${index}`}
                      data={item}
                    />
                  );
                })}
              </Masonry>
            </InfiniteScroll>
          ) : (
            renderEmptyGroup()
          ))}
      </div>
    );
  };

  const renderEmptyOrg = () => {
    return (
      <div className="empty_status">
        <div className="empty_status_icon">
          <OrgIcon color={'#FF3E9A'} />
        </div>
        <div className="empty_status_info">
          <div className="font-bold font14">
            You haven’t joined any schools or schools yet
          </div>
          <div
            className="font-regular font12"
            style={{ color: 'rgba(0, 0, 0, 0.8)' }}
          >
            Explore existing ones and request to join!
          </div>
        </div>
        <div
          className="font-bold font12 clickable"
          style={{ color: '#FF3E9A' }}
          onClick={() => navigate('/org/join')}
        >
          Explore Now
        </div>
      </div>
    );
  };

  const renderEmptyGroup = () => {
    return (
      <div className="empty_status">
        <div className="empty_status_icon">
          <OrgIcon color={'#FF3E9A'} />
        </div>
        <div className="empty_status_info">
          <div className="font-bold font14">
            You haven’t joined any groups yet
          </div>
          <div
            className="font-regular font12"
            style={{ color: 'rgba(0, 0, 0, 0.8)' }}
          >
            Explore and join any group that matches your interests.
          </div>
        </div>
        <div
          className="font-bold font12 clickable"
          style={{ color: '#FF3E9A' }}
        >
          Explore Now
        </div>
      </div>
    );
  };

  const renderSocialMedia = () => {
    return (
      <div className="render_block">
        <div
          className="block_top row_space_between"
          style={{ marginBottom: 16 }}
        >
          <div className="row_align_items">
            <img src={SocialMediasPng} />
            <div className="font-bold font32" style={{ marginLeft: 16 }}>
              Connected Accounts
            </div>
          </div>
          <div className="edit_btn clickable" onClick={onEditSocial}>
            <EditIcon size={20} color="#FF3E9A" />
            <div className="font-bold font12">Edit</div>
          </div>
        </div>
        <SocialMedia
          showConnectButton={true}
          userInfo={userInfo}
          onConnect={onEditSocial}
        />
      </div>
    );
  };

  const renderRecentActivity = () => {
    return (
      <div className="render_block" style={{ marginBottom: 48 }}>
        <div className="block_top row_align_items">
          <img src={RecentActivitiesPng} />
          <div className="font-bold font32" style={{ marginLeft: 16 }}>
            Recent Activities
          </div>
        </div>
        <div className="user_activity_ai_list">
          {arrUserActivityAI.length > 0 && (
            <InfiniteScroll
              dataLength={arrUserActivityAI.length}
              next={() => loadUserActivityAIData(false)}
              hasMore={hasMoreUserActivityAI}
              loader={<div style={{ display: 'flex', margin: 'auto' }}></div>}
              refreshFunction={() => {}}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
            >
              {arrUserActivityAI.map((item, index) => {
                return (
                  <CardTrendingQuestion
                    key={`card_user_activity_ai_${index}`}
                    data={item}
                    type={'creator'}
                    hideBookMark={true}
                    onShareMedia={(data, modalType) => {
                      onShareHandler(data, modalType);
                    }}
                  />
                );
              })}
            </InfiniteScroll>
          )}
          {!isLoadingUserActivityAI &&
            hasMoreUserActivityAI &&
            arrUserActivityAI.length == 0 && (
              <div className="empty_user_activity_ai">
                <div
                  className="font-bold font56"
                  style={{ textAlign: 'center', marginTop: 24 }}
                >
                  🏃‍♀️
                </div>
                <div
                  className="font-bold font18"
                  style={{ textAlign: 'center', marginTop: 16 }}
                >
                  No activities yet
                </div>
                <div
                  className="font-regular font14"
                  style={{
                    textAlign: 'center',
                    marginTop: 8,
                    marginBottom: 24
                  }}
                >
                  Post a question, add an answer, or upvotes other’s answer!
                </div>
              </div>
            )}
        </div>
      </div>
    );
  };

  const renderShareQuestionToSocial = () => {
    return (
      <QuestionAnswerShareModal
        show={isShareModalShow}
        onClose={() => {
          setIsShareModalShow(false);
        }}
        question={selectedQuestionData}
        showAnswer={false}
        type={shareModalType}
      />
    );
  };

  const renderConnectSocialModal = () => {
    return (
      <ConnectSocialModal
        show={isConnectSocial}
        onClose={() => {
          setIsConnectSocial(false);
        }}
        onSuccess={() => {
          setIsConnectSocial(false);
        }}
      />
    );
  };

  return (
    <div className="profile_container">
      <MainHeader currentPage="user" />
      <div className="profile">
        {renderUserInfo()}
        {renderJoinedOrg()}
        {renderJoinedGroup()}
        {renderSocialMedia()}
        {renderRecentActivity()}
        {renderShareQuestionToSocial()}
      </div>
      {renderConnectSocialModal()}
    </div>
  );
};
