import AngleRightIcon from 'assets/svgs/AngleRightIcon';
import React from 'react';
import { AskQuestion } from '../AskQuestion';
import { SimilarQuestion } from '../SimilarQuestion';

import './index.styles.scss';

interface Props {
  question: any;
  isAsking: boolean;
  selectedOrg?: any;
  qaAskInfo?: any;
  arrOrganisation: Array<any>;

  onSetQuestion: (newQuestion) => void;
  onLoadQuestionData: (question) => void;
  onReloadQuestion: (orgId) => void;
  onBack: () => void;
}

export const OrgAsked = ({
  question,
  isAsking,
  qaAskInfo,
  selectedOrg,
  arrOrganisation,
  onSetQuestion,
  onLoadQuestionData,
  onReloadQuestion,
  onBack
}: Props) => {
  const renderNavigation = () => {
    return (
      <div className="org_navigation">
        <div
          className={`org_navigation_item font-bold font24 clickable`}
          onClick={onBack}
        >
          School
        </div>
        <div style={{ marginLeft: 20 }}>
          <AngleRightIcon />
        </div>
        <div
          className={`org_navigation_item_selected font-bold font24 clickable`}
          style={{ marginLeft: 20 }}
        >
          Ask New Question
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="org_asked" style={{ height: 'calc(100%)' }}>
        <AskQuestion
          selectedOrg={selectedOrg}
          textQuestion={question}
          setQuestion={onSetQuestion}
          qaAskInfo={qaAskInfo}
          isAsking={isAsking}
          onRefresh={() => onLoadQuestionData(question)}
          onReloadQuestion={onReloadQuestion}
        />

        <SimilarQuestion
          isAskingQuestion={isAsking}
          textQuestion={question}
          selectedOrg={selectedOrg}
          arrOrganisation={arrOrganisation}
        />
      </div>
    );
  };

  return (
    <div className="org_asked">
      {renderNavigation()}
      {renderBody()}
    </div>
  );
};
