import React, { useEffect, useMemo, useState } from 'react';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import EditIcon from 'assets/svgs/EditIcon';
import BookMarkIcon from 'assets/svgs/BookMarkIcon';
import RegenerateIcon from 'assets/svgs/RegenerateIcon';
import {
  apiQAEditAnswer,
  apiQABookMark,
  apiQAUnBookMark,
  apiQARefresh,
  apiGetQAAsk,
  apiGetQAAnswers
} from 'shared/api/qa';

import './index.styles.scss';
import { getArrFromString, replaceToBr } from 'shared/utils/utils';
import { Reference } from 'components/Common/Reference';
import { ReferenceDetail } from 'components/Common/ReferenceDetail';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { DeviceSize } from 'shared/utils/constants';
import appAction from 'redux/app/action';

interface DumpData {
  id: number;
  user: number;
  question: string;
  to: number;
  to_type: string;
  ai_answer: string;
  context: string;
  timestamp: string;
  answer_count: number;
  bookmark?: boolean;
  ref_users?: string;
  ref_names?: string;
  ref_images?: string;
  ref_filenames?: string;
  ref_sizes?: string;
  ref_texts?: string;
  ref_urls?: string;
  ref_titles?: string;
  ref_types?: string;
  ref_count?: number;
  ref_ids?: string;
}

interface CardMyQuestionProps {
  question: string;
  data: DumpData;
  itemId: number;
  filters: Array<any>;
  tags: string;
  type: 'home' | 'group';
  onRefresh: () => void;
  onRefreshBookmark?: () => void;
}

export const CardMyQuestion = ({
  question,
  data,
  type,
  itemId,
  filters,
  tags,
  onRefresh,
  onRefreshBookmark
}: CardMyQuestionProps) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [questionData, setQuestionData] = useState(data);

  const [isAnswerEditing, setIsAnswerEditing] = useState(false);
  const [answer, setAnswer] = useState('');
  const [isSavingAnswer, setIsSavingAnswer] = useState(false);
  const [isBookMarkingAnswer, setIsBookMarkingAnswer] = useState(false);
  const [isUnBookMarkingAnswer, setIsUnBookMarkingAnswer] = useState(false);
  const [isRefreshingQuestion, setIsRefreshingQuestion] = useState(false);

  const [arrExtraUserRef, setArrExtraUserRef] = useState<Array<any>>([]);
  const [arrExtraFileRef, setArrExtraFileRef] = useState<Array<any>>([]);

  const [isExpandReference, setIsExpandReference] = useState(false);
  const [bookmarkId, setBookMakrId] = useState(0);

  useEffect(() => {
    setQuestionData(data);
  }, [data]);

  const arrReferences = useMemo(() => {
    const arrTmpRefData: Array<any> = [];

    const arrRefIds = getArrFromString(questionData.ref_ids, ';;;');
    // for user
    const arrTmpName = getArrFromString(questionData.ref_names, ';;;');
    const arrTmpImage = getArrFromString(questionData.ref_images, ';;;');

    // for files
    const arrTmpFileName = getArrFromString(
      questionData.ref_titles ?? questionData.ref_filenames,
      ';;;'
    );
    const arrTmpFileUrl = getArrFromString(questionData.ref_urls, ';;;');
    const arrTmpFileType = getArrFromString(questionData.ref_types, ';;;');

    if (type == 'home') {
      arrTmpFileName.map((item, index) => {
        arrTmpRefData.push({
          id: arrRefIds[index],
          name: arrTmpFileName[index],
          image: arrTmpFileUrl[index],
          type: 'file',
          fileType: arrTmpFileType[index]
        });
      });

      // add extra file references
      arrExtraFileRef.forEach((fileRef) => {
        arrTmpRefData.push({
          id: fileRef.id,
          name: fileRef.filename,
          image: fileRef.url,
          type: 'file',
          fileType: fileRef.dump_type
        });
      });
    } else {
      arrTmpName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpName[index],
          image: arrTmpImage[index],
          type: 'user'
        });
      });

      arrTmpFileName.map((item, index) => {
        arrTmpRefData.push({
          id: arrRefIds[index],
          name: arrTmpFileName[index],
          image: arrTmpFileUrl[index],
          type: 'file'
        });
      });

      // add extra file references
      arrExtraFileRef.forEach((fileRef) => {
        arrTmpRefData.push({
          id: fileRef.id,
          name: fileRef.filename,
          image: fileRef.url,
          type: 'file'
        });
      });

      // add extra user references
      arrExtraUserRef.forEach((userRef) => {
        arrTmpRefData.push({
          name: userRef.creator_name,
          image: userRef.creator_image,
          type: 'user'
        });
      });
    }

    // remove duplicated
    const uniqueArr = arrTmpRefData.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.image === value.image || t.name === value.name)
    );
    return uniqueArr;
  }, [questionData, arrExtraUserRef, arrExtraFileRef]);

  const arrReferencesForBelow = useMemo(() => {
    const arrTmpRefData: Array<any> = [];

    const arrRefIds = getArrFromString(data.ref_ids, ';;;');
    // for user
    const arrTmpName = getArrFromString(questionData.ref_names, ';;;');
    const arrTmpImage = getArrFromString(questionData.ref_images, ';;;');

    // for files
    const arrTmpFileName = getArrFromString(
      questionData.ref_titles ?? questionData.ref_filenames,
      ';;;'
    );
    const arrTmpFileUrl = getArrFromString(questionData.ref_urls, ';;;');
    const arrTmpTxt = getArrFromString(questionData.ref_texts, ';;;');
    const arrTmpFileType = getArrFromString(questionData.ref_types, ';;;');

    if (type == 'home') {
      arrRefIds.map((item, index) => {
        arrTmpRefData.push({
          fileName: arrTmpFileName[index],
          url: arrTmpFileUrl[index],
          type: 'file',
          text: arrTmpTxt[index],
          fileType: arrTmpFileType[index]
        });
      });

      // add extra file references
      arrExtraFileRef.forEach((fileRef) => {
        arrTmpRefData.push({
          fileName: fileRef.filename,
          url: fileRef.url,
          type: 'file',
          fileType: fileRef.dump_type
        });
      });
    } else {
      arrRefIds.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpName[index],
          image: arrTmpImage[index],
          fileName: arrTmpFileName[index],
          url: arrTmpFileUrl[index],
          text: arrTmpTxt[index],
          fileType: arrTmpFileType[index]
        });
      });
      // add extra file references
      arrExtraFileRef.forEach((fileRef) => {
        arrTmpRefData.push({
          fileName: fileRef.filename,
          url: fileRef.url,
          type: 'file',
          fileType: fileRef.dump_type
        });
      });

      // add extra user references
      arrExtraUserRef.forEach((userRef) => {
        arrTmpRefData.push({
          name: userRef.creator_name,
          image: userRef.creator_image,
          type: 'user'
        });
      });
    }

    // remove duplicated
    // const uniqueArr = arrTmpRefData.filter(
    //   (value, index, self) =>
    //     index ===
    //     self.findIndex((t) => t.image === value.image && t.name === value.name)
    // );
    return arrTmpRefData;
  }, [questionData, arrExtraUserRef, arrExtraFileRef]);

  const onAnswerEdit = () => {
    setIsAnswerEditing(true);
    setAnswer(questionData.ai_answer);
  };

  const onSaveAnswer = async () => {
    if (!isSavingAnswer) {
      setIsSavingAnswer(true);

      const res = await apiQAEditAnswer({
        question: questionData.id,
        answer
      });
      if (res.success) {
        questionData.ai_answer = answer;
        onRefresh();
      }

      setIsSavingAnswer(false);
      setIsAnswerEditing(false);
    }
  };

  const onAnswerBookMark = async () => {
    if (!isBookMarkingAnswer) {
      setIsBookMarkingAnswer(true);
      const res = await apiQABookMark({
        question: questionData.id,
        item_id: userInfo ? userInfo.id : 0,
        item_type: 'user'
      });

      if (res.success) {
        questionData.bookmark = true;
        setBookMakrId(res.bookmark);
        onRefreshBookmark && onRefreshBookmark();

        dispatch(
          appAction.addBookMarkData({
            ...questionData,
            bookmark_id: res.bookmark
          })
        );
      }
      setIsBookMarkingAnswer(false);
    }
  };

  const onAnswerUnBookMark = async () => {
    if (!isUnBookMarkingAnswer) {
      setIsUnBookMarkingAnswer(true);

      const res = await apiQAUnBookMark({
        bookmark: bookmarkId,
        item_id: userInfo ? userInfo.id : 0,
        item_type: 'user'
      });

      if (res.success) {
        questionData.bookmark = false;
        onRefreshBookmark && onRefreshBookmark();
      }

      dispatch(appAction.refreshdBookMarkData());
      setIsUnBookMarkingAnswer(false);
    }
  };

  const onRefreshQuestion = async (newUserRef: string, newFileRef: string) => {
    if (!isRefreshingQuestion) {
      setIsRefreshingQuestion(true);

      const res = await apiGetQAAsk({
        question,
        to: type === 'home' ? userInfo?.id : itemId,
        to_type: type === 'home' ? 'user' : 'group',
        interests: filters.length > 0 ? filters + '' : undefined,
        tag_ids: tags.length > 0 ? tags + '' : undefined,
        dumpString: newFileRef !== '' ? newFileRef : undefined,
        users: newUserRef !== '' ? newUserRef + '' : undefined
      });

      if (res.success) {
        setQuestionData(res.data);
      }

      setIsRefreshingQuestion(false);
    }
  };

  const onDoneAddReference = (newUserRef, newFileRef) => {
    setArrExtraUserRef(newUserRef);
    setArrExtraFileRef(newFileRef);
  };

  const renderEditAnswer = () => {
    return (
      <div className="edit_answer">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="font-bold font18">Edit Answer</div>
          <div
            className="font-medium font14 clickable"
            style={{ color: '#FF3E9A' }}
            onClick={() => setAnswer(questionData.ai_answer)}
          >
            Refresh
          </div>
        </div>
        <div className="">
          <textarea
            value={answer}
            placeholder=""
            className="answer_box_input font-regular font20"
            onChange={(e) => {
              setAnswer(e.target.value);
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            className="cancel_btn clickable"
            onClick={() => setIsAnswerEditing(false)}
          >
            <div className="font-medium font16">Cancel</div>
          </div>
          <div className="save_answer_btn clickable" onClick={onSaveAnswer}>
            <div className="font-bold font16" style={{ color: 'white' }}>
              {isSavingAnswer ? (
                <LoadingCircular size={20} color="white" />
              ) : (
                <>Save Answer</>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderNormalQuestion = () => {
    if (isRefreshingQuestion) return <LoadingBarAnimation />;

    if (isMobile) {
      return (
        <>
          <div className="correct_answer">
            <pre className="font-regular font20">{questionData.ai_answer}</pre>
          </div>
          <div className="sub_info_right">
            {isRefreshingQuestion ? (
              <div className="add_regenerate_btn">
                <LoadingCircular size={20} color="main" />
              </div>
            ) : (
              <div
                className="add_regenerate_btn clickable"
                onClick={() => onRefreshQuestion('', '')}
              >
                <RegenerateIcon />
                <div
                  className="font-medium font16"
                  style={{ marginLeft: '10px' }}
                >
                  Regenerate
                </div>
              </div>
            )}
            <div className="edit_answer_btn clickable" onClick={onAnswerEdit}>
              <EditIcon color="#FF3E9A" />
              <div className="font-bold font16" style={{ marginLeft: '10px' }}>
                Edit Answer
              </div>
            </div>
            {data.bookmark ? (
              <div
                className="un_book_mark_btn clickable"
                onClick={onAnswerUnBookMark}
              >
                {isUnBookMarkingAnswer ? (
                  <LoadingCircular size={20} color="main" />
                ) : (
                  <>
                    <BookMarkIcon color="#FF3E9A" />
                    <div
                      className="font-bold font16"
                      style={{ marginLeft: '6px' }}
                    >
                      Bookmarked
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div
                className="book_mark_btn clickable"
                onClick={onAnswerBookMark}
              >
                {isBookMarkingAnswer ? (
                  <LoadingCircular size={20} color="white" />
                ) : (
                  <>
                    <BookMarkIcon color="#FFFFFF" />
                    <div
                      className="font-bold font16"
                      style={{ color: 'white', marginLeft: '6px' }}
                    >
                      Bookmark
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="sub_info">
            <div className="sub_info_left">
              <Reference
                data={arrReferences}
                showAddButton={true}
                enableVisit={true}
                itemId={itemId}
                itemType={type === 'home' ? 'user' : 'group'}
                onDoneAddReference={onDoneAddReference}
                setIsExpandReference={setIsExpandReference}
                isExpandReference={isExpandReference}
              />
            </div>
          </div>
          {isExpandReference && (
            <ReferenceDetail data={arrReferencesForBelow} type={type} />
          )}
        </>
      );
    } else {
      return (
        <>
          <div className="correct_answer">
            <pre className="font-regular font20">{questionData.ai_answer}</pre>
          </div>
          <div className="sub_info">
            <div className="sub_info_left">
              <Reference
                data={arrReferences}
                showAddButton={true}
                enableVisit={true}
                itemId={itemId}
                itemType={type === 'home' ? 'user' : 'group'}
                onDoneAddReference={onDoneAddReference}
                setIsExpandReference={setIsExpandReference}
                isExpandReference={isExpandReference}
              />
            </div>
            <div className="sub_info_right">
              {isRefreshingQuestion ? (
                <div className="add_regenerate_btn">
                  <LoadingCircular size={20} color="main" />
                </div>
              ) : (
                <div
                  className="add_regenerate_btn clickable"
                  onClick={() => onRefreshQuestion('', '')}
                >
                  <RegenerateIcon />
                  <div
                    className="font-medium font16"
                    style={{ marginLeft: '10px' }}
                  >
                    Regenerate
                  </div>
                </div>
              )}
              <div className="edit_answer_btn clickable" onClick={onAnswerEdit}>
                <EditIcon color="#FF3E9A" />
                <div
                  className="font-bold font16"
                  style={{ marginLeft: '10px' }}
                >
                  Edit Answer
                </div>
              </div>
              {data.bookmark ? (
                <div
                  className="un_book_mark_btn clickable"
                  onClick={onAnswerUnBookMark}
                >
                  {isUnBookMarkingAnswer ? (
                    <LoadingCircular size={20} color="main" />
                  ) : (
                    <>
                      <BookMarkIcon color="#FF3E9A" />
                      <div
                        className="font-bold font16"
                        style={{ marginLeft: '6px' }}
                      >
                        Bookmarked
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div
                  className="book_mark_btn clickable"
                  onClick={onAnswerBookMark}
                >
                  {isBookMarkingAnswer ? (
                    <LoadingCircular size={20} color="white" />
                  ) : (
                    <>
                      <BookMarkIcon color="#FFFFFF" />
                      <div
                        className="font-bold font16"
                        style={{ color: 'white', marginLeft: '6px' }}
                      >
                        Bookmark
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          {isExpandReference && (
            <ReferenceDetail data={arrReferencesForBelow} type={type} />
          )}
        </>
      );
    }
  };

  const renderQuestion = () => {
    return (
      <div className="question_view">
        {isAnswerEditing ? renderEditAnswer() : renderNormalQuestion()}
      </div>
    );
  };

  return <div className="card_my_question">{renderQuestion()}</div>;
};
