import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import appAction from 'redux/app/action';

import './index.styles.scss';
import { apiEventConfirmRSVP, apiEventRemoveRSVP } from 'shared/api/event';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { useDispatch } from 'react-redux';
import ShareIcon from 'assets/svgs/ShareIcon';
import { ShareEventModal } from 'components/Modals/ShareEventModal';
import { useNavigate } from 'react-router-dom';

interface CardEventProps {
  data: any;
}

export const CardEvent = ({ data }: CardEventProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showShare, setShowShare] = useState(false);
  const [isRsvp, setIsRsvp] = useState(data.is_rsvp === 1);
  const [isRsvping, setIsRsvping] = useState(false);

  useEffect(() => {
    setIsRsvp(data.is_rsvp);
  }, [data]);

  const onDetail = (e) => {
    e.stopPropagation();
    navigate(`/school/events/${data.qr_code}`);
  };

  const onRsvp = async (e) => {
    e.stopPropagation();
    if (isRsvp) return;

    setIsRsvping(true);

    const res = await apiEventConfirmRSVP({ event: data.id });

    if (res.success) {
      setIsRsvp(true);
      dispatch(appAction.refreshEventData());
    }

    setIsRsvping(false);
  };

  const onRemoveRsvp = async (e) => {
    e.stopPropagation();
    setIsRsvping(true);

    const res = await apiEventRemoveRSVP({ event: data.id });

    if (res.success) {
      setIsRsvp(false);
      dispatch(appAction.refreshEventData());
    }

    setIsRsvping(false);
  };

  const onViewProfile = (e) => {
    e.stopPropagation();
    navigate(`/profile/${data.creator_id}`);
  };

  const onViewOrg = (e) => {
    e.stopPropagation();
    navigate(`/school/${data.organisation}`);
  };

  const renderRsvpButton = () => {
    return (
      <>
        {!isRsvp ? (
          <div
            className="claim_button clickable font-bold font12"
            onClick={onRsvp}
          >
            {isRsvping ? (
              <LoadingCircular color="white" size={18} />
            ) : (
              <div>RSVP Now</div>
            )}
          </div>
        ) : (
          <div
            className="claimed_button font-bold font12 clickable"
            onClick={onRemoveRsvp}
          >
            {isRsvping ? (
              <LoadingCircular color="main" size={24} />
            ) : (
              <div>RSVP-ed</div>
            )}
          </div>
        )}
      </>
    );
  };

  const renderShareModal = () => {
    return (
      <ShareEventModal
        show={showShare}
        eventData={data}
        onClose={() => {
          setShowShare(false);
        }}
      />
    );
  };

  return (
    <div className="card_event clickable" onClick={onDetail}>
      <div className="row_space_between">
        <div className="card_event_user_info font12 font-medium">
          <div className="row_align_items clickable" onClick={onViewProfile}>
            <div className="align-center">
              {data.creator_image?.startsWith('https://') ? (
                <img
                  src={data.creator_image}
                  style={{ width: 16, height: 16, borderRadius: 16 }}
                />
              ) : (
                <DefaultAvatarIcon />
              )}
            </div>
            <span style={{ marginLeft: 2 }}>{data.creator_name}</span>
          </div>
          <span style={{ opacity: '0.6' }}>in</span>
          <div className="row_align_items">
            <div className="align-center">
              {data.org_image?.startsWith('https://') ? (
                <img
                  src={data.org_image}
                  style={{ width: 16, height: 16, borderRadius: 16 }}
                  className="clickable"
                  onClick={onViewOrg}
                />
              ) : (
                <RobotIcon />
              )}
            </div>
            <span
              style={{ marginLeft: 2 }}
              className="clickable"
              onClick={onViewOrg}
            >
              {data.org_name}
            </span>
          </div>
        </div>
        <div
          className="share_btn clickable"
          onClick={() => {
            setShowShare(true);
          }}
        >
          <ShareIcon color="rgba(0, 0, 0, 0.3)" />
        </div>
      </div>

      <div className="card_event_title font20 font-medium">{data.title}</div>
      <div className="card_event_bottom">
        <div className="font-regular font12">
          🗓 {moment(data.date).format('DD MMM YYYY')} •{' '}
          {moment(data.date).format('hh:mm A')}
        </div>
        {renderRsvpButton()}
      </div>
      {renderShareModal()}
    </div>
  );
};
