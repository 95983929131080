import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import { LinkPreviewTopFilter } from 'components/Main/Home/MyDraft/LinkPreview/TopFilter';
import React, { useEffect, useState } from 'react';
import Columns from 'react-columns';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetContentFiles } from 'shared/api/content';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { CardAllSharedDocs } from './CardAllSharedDocs';

import './index.styles.scss';

interface AllSharedDocsProps {
  selectedOrg?: any;
  onBack?: () => void;
}

export const AllSharedDoc = ({ selectedOrg, onBack }: AllSharedDocsProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [searchText, setSearchText] = useState('');

  const [arrFiles, setArrFiles] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id, searchText]);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiGetContentFiles({
        query: searchText,
        to: id,
        to_type: 'organisation',
        share_type: 'shared',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrFiles(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }
    } else {
      if (!isLoadingFile && hasMore) {
        const res = await apiGetContentFiles({
          query: searchText,
          to: id,
          to_type: 'organisation',
          share_type: 'shared',
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrFiles((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div className="clickable" onClick={onClickBack}>
            <RoundBackIcon />
          </div>
          <div className="font-bold font26" style={{ marginLeft: 8 }}>
            All Shared Docs within Schools
          </div>
        </div>
        <SearchBox
          onSearch={setSearchText}
          width={'300px'}
          height={'48px'}
          fontSize={14}
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="shared_all_docs_body">
        <Columns
          columns={5}
          gap={'16px'}
          rootStyles={{ paddingBottom: 10, width: '100%' }}
        >
          {arrFiles.map((item, index) => {
            return (
              <CardAllSharedDocs
                key={`card_all_shared_docs_${index}`}
                data={item}
              />
            );
          })}
        </Columns>
      </div>
    );
  };

  return (
    <div className="shared_all_docs">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
