import React, { useEffect, useRef, useState } from 'react';
import { apiCreateQADump } from 'shared/api/qa';
import { CardAttachedFile } from './CardAttachedFile';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import Tooltip from '@mui/material/Tooltip';
import appAction from 'redux/app/action';
import { socket } from 'components/Auth';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from 'shared/utils/constants';
import { useDispatch, useSelector } from 'react-redux';

import './index.styles.scss';

import PAFIconDark from 'assets/pngs/PDFIconDark.png';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { AddTagModal } from 'components/Modals/AddTagModal';
import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import SearchIcon from 'assets/svgs/SearchIcon';
import { RootState } from 'redux/interfaces';
import LinkAddIcon from 'assets/svgs/LinkAddIcon';
import FileUploadIcon from 'assets/svgs/FileUploadIcon';
import EditPencilIcon from 'assets/svgs/EditPencilIcon';
import { AddLinkModal } from 'components/Modals/AddLinkModal';
import { LeftArrow, RightArrow } from './ArrowBtns';
import { getFileExtenstion } from 'shared/utils/utils';
import { getBase64String } from 'shared/utils-IPFS/functions';
import useIPFS from 'shared/utils-IPFS/useIPFS';
import { useNavigate } from 'react-router-dom';
import MicIcon from 'assets/svgs/MicIcon';
import FileIcon from 'assets/svgs/FileIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { RecordModal } from 'components/Modals/RecordModal';
import { apiGetContentFiles } from 'shared/api/content';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface AttachedFileProps {
  itemId: number;
  itemType: 'user' | 'group';
  canDump: boolean;
  onHasData: (has) => void;
}

export const AttachedFile = ({
  itemId,
  itemType,
  canDump,
  onHasData
}: AttachedFileProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { uploadBase64, progress } = useIPFS();
  const { hasNewBookmark } = useSelector((state: RootState) => state.app);

  const [arrFile, setArrFile] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filter, setFilter] = useState<
    'all' | 'pdf' | 'text' | 'url' | 'audio'
  >('all');

  const [topTab, setTopTab] = useState<'uploaded' | 'received'>('uploaded');
  const [totalUploadCount, setTotalUploadCount] = useState(0);
  const [totalReceiveCount, setTotalReceiveCount] = useState(0);

  const [isShowAddTagModal, setIsShowAddTagModal] = useState(false);
  const [showAddLinkModal, setShowAddLinkModal] = useState(false);

  const [dragging, setDragging] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [modalPosition, setModalPosition] = useState<number | undefined>(24);
  const [isAttachedSearchExpand, setIsAttachedSearchExpand] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  useEffect(() => {
    initDragAction();
  }, []);

  useEffect(() => {
    loadData();
  }, [searchKeyword, filter, itemId, hasNewBookmark, topTab]);

  useEffect(() => {
    onHasData(arrFile.length > 0);
  }, [arrFile]);

  useEffect(() => {
    if (socket) {
      const newMessageHandler = (data) => {
        console.log('--- new-dump-scraping handler ---', data);
        onUpdateDumpStatus(parseInt(data.dump), data.status);

        if (data.status === 'completed') {
          dispatch(appAction.refreshdBookMarkData());
        }
      };

      const newSummaryHandler = (data) => {
        console.log('--- new-dump-summary handler ---', data);
        onUpdateDumpTitle(parseInt(data.dump), data.title);
        dispatch(appAction.refreshdBookMarkData());
      };

      socket.on('dump-scraping', newMessageHandler);
      socket.on(`dump-summary`, newSummaryHandler);

      return () => {
        socket.removeListener('dump-scraping', newMessageHandler);
        socket.removeListener(`dump-summary`, newSummaryHandler);
      };
    }
  }, [socket]);

  const loadData = async (init = true) => {
    if (init) {
      setIsLoadingFile(true);
      const res = await apiGetContentFiles({
        to: itemId,
        to_type: itemType,
        share_type: topTab,
        limit: 100,
        offset: 0,
        query: searchKeyword,
        type: filter != 'all' ? filter : null
      });

      if (res.success) {
        setArrFile(res.data);
        setTotalReceiveCount(res.received_count);
        setTotalUploadCount(res.uploaded_count);
      }
      setIsLoadingFile(false);
    }
  };

  const initDragAction = () => {
    const homePage = document.getElementById('home_my_draft');
    homePage?.addEventListener('dragenter', (e) => {
      handleDragEnter(e);
    });
    homePage?.addEventListener('dragleave', (e) => {
      handleDragLeave(e);
    });
    homePage?.addEventListener('dragover', (e) => {
      handleDragOver(e);
    });
    homePage?.addEventListener('drop', (e) => {
      handleDrop(e);
    });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = [...e.dataTransfer.files];
    // do something with the dropped files

    const fileType = files[0].type;

    if (
      fileType === 'application/pdf' ||
      fileType === 'application/msword' ||
      fileType === 'application/epub+zip' ||
      fileType ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      fileType.startsWith('video') ||
      fileType.startsWith('audio')
    ) {
      onAddDragFile(files);
    }
  };

  const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  const onLoadMoreData = (vc) => {
    if (vc.isLastItemVisible) {
      loadData(false);
    }
  };

  const onSearch = (searchText: string) => {
    setSearchKeyword(searchText);
  };

  const onClickFilter = (filter) => {
    setFilter(filter);
  };

  const onUpdateTags = (item, newTags) => {
    setArrFile((prev) => {
      return prev.map((fileItem, index) => {
        if (fileItem.id === item.id) {
          const newTagIds = newTags.map((tagItem, _) => tagItem.id);
          const newTagTypes = newTags.map((tagItem, _) => tagItem.type);
          const newTagImages = newTags.map((tagItem, _) => tagItem.image);
          const newTagNames = newTags.map((tagItem, _) => tagItem.name);

          return {
            ...fileItem,
            tag_ids: newTagIds + '',
            tag_types: newTagTypes + '',
            tag_images: newTagImages + '',
            tag_names: newTagNames + ''
          };
        } else {
          return fileItem;
        }
      });
    });
  };

  const onAddLink = (dumpId, link) => {
    // show toast
    showToast('success', 'Successfully uploaded!');

    setShowAddLinkModal(false);
    setArrFile((prev) => [
      {
        id: dumpId,
        url: link,
        filename: link,
        dump_type: 'url',
        status: 'processing',
        size: null
      },
      ...prev
    ]);
  };

  const onAddAudio = (dumpId, link) => {
    // show toast

    showToast('success', 'Successfully uploaded!');

    setShowAddLinkModal(false);
    setArrFile((prev) => [
      {
        id: dumpId,
        url: link,
        filename: link,
        dump_type: 'audio',
        status: 'processing',
        size: null
      },
      ...prev
    ]);
  };

  const onAttachFile = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept =
      'application/pdf,.mp4,.mp3,.wav,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/epub+zip,text/plain';
    inputElement.multiple = true;
    inputElement.addEventListener('change', onAddFile);
    inputElement.dispatchEvent(new MouseEvent('click'));
  };

  const onAddDragFile = async (files) => {
    if (files.length && !isCreating) {
      setIsCreating(true);

      const name = files[0].name;
      const size = files[0].size;

      const fileExtension = getFileExtenstion(name);
      let fileType: 'pdf' | 'audio' | 'url' = 'pdf';
      let mimeType: any = null;

      if (
        fileExtension === 'wav' ||
        fileExtension === 'mp3' ||
        fileExtension === 'mp4'
      ) {
        fileType = 'audio';
      } else if (
        fileExtension === 'docx' ||
        fileExtension === 'txt' ||
        fileExtension === 'epub'
      ) {
        if (fileExtension === 'docx') {
          mimeType =
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
        if (fileExtension === 'txt') {
          mimeType = 'text/plain';
        }
        if (fileExtension === 'epub') {
          mimeType = 'application/epub+zip';
        }
        fileType = 'url';
      }

      setArrFile((prev) => [
        {
          id: -1,
          url: null,
          filename: name,
          dump_type: fileType,
          status: 'uploading',
          size: (parseInt(size) / 1000000).toFixed(2),
          uploadingName:
            mimeType === 'text/plain' ||
            mimeType === 'application/epub+zip' ||
            mimeType ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ? name
              : null
        },
        ...prev
      ]);

      await onCreateFileDump(
        files[0],
        fileType,
        name,
        fileType === 'audio' ? 'wav' : '',
        mimeType
      );

      setIsCreating(false);
    }
  };

  const onAddFile = async (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length && !isCreating) {
      setIsCreating(true);
      const name = files[0].name;
      const size = files[0].size;

      const fileExtension = getFileExtenstion(name);
      let fileType: 'pdf' | 'audio' | 'url' = 'pdf';
      let mimeType: any = null;

      if (
        fileExtension === 'wav' ||
        fileExtension === 'mp3' ||
        fileExtension === 'mp4'
      ) {
        fileType = 'audio';
      } else if (
        fileExtension === 'docx' ||
        fileExtension === 'txt' ||
        fileExtension === 'epub'
      ) {
        if (fileExtension === 'docx') {
          mimeType =
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
        if (fileExtension === 'txt') {
          mimeType = 'text/plain';
        }
        if (fileExtension === 'epub') {
          mimeType = 'application/epub+zip';
        }
        fileType = 'url';
      }

      setArrFile((prev) => [
        {
          id: -1,
          url: null,
          filename: name,
          dump_type: fileType,
          status: 'uploading',
          size: (parseInt(size) / 1000000).toFixed(2),
          uploadingName:
            mimeType === 'text/plain' ||
            mimeType === 'application/epub+zip' ||
            mimeType ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ? name
              : null
        },
        ...prev
      ]);

      await onCreateFileDump(
        files[0],
        fileType,
        name,
        fileType === 'audio' ? 'wav' : '',
        mimeType
      );

      setIsCreating(false);
    }
  };

  const onWrite = () => {
    navigate(`/write/${itemType}/${itemId}`);
  };

  const onRecord = () => {
    setIsRecording(true);
  };

  const onCreateFileDump = async (
    localFileUrl,
    type,
    fileName,
    extension = '',
    mimeType = null
  ) => {
    const base64String = await getBase64String(localFileUrl);
    const url = await uploadBase64(
      base64String,
      mimeType ? mimeType : `application/${type}`,
      extension
    );

    onUpdateDumpStatus(-1, 'processing', url);
    const res = await apiCreateQADump({
      url: url!,
      type: type,
      filename: fileName,
      to: itemId,
      to_type: itemType
    });

    if (res.success) {
      setTotalUploadCount((prev) => prev + 1);
      setArrFile((prev) =>
        prev.map((item, index) => {
          if (item.id == -1) {
            return {
              ...item,
              id: res.id
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  const onUpdateDumpStatus = (dumpId, status, url: any = null) => {
    setArrFile((prev) =>
      prev.map((item, index) => {
        if (item.id == dumpId) {
          return {
            ...item,
            status: status,
            url: url
          };
        } else {
          return item;
        }
      })
    );
  };

  const onUpdateDumpTitle = (dumpId, title) => {
    setArrFile((prev) =>
      prev.map((item, index) => {
        if (item.id == dumpId && title) {
          return {
            ...item,
            title: title
          };
        } else {
          return item;
        }
      })
    );
  };

  const onRemoveDumpOnUI = (dumpId) => {
    setArrFile((prev) => {
      return prev.filter((item, index) => item.id !== dumpId);
    });
  };

  const renderAddTagModal = () => {
    return (
      <AddTagModal
        show={isShowAddTagModal}
        itemId={itemId}
        itemType={itemType}
        onClose={() => setIsShowAddTagModal(false)}
        onSuccess={(item, newTag) => {
          setIsShowAddTagModal(false);
          onUpdateTags(item, newTag);
        }}
        selectedFile={selectedFile}
        positionX={modalPosition}
      />
    );
  };

  const renderEmptyIcon = () => {
    return (
      <div>
        {(filter === 'all' || filter === 'pdf') && <img src={PAFIconDark} />}
        {filter === 'audio' && <MicIcon size={48} color="rgba(0, 0, 0, 0.3)" />}
        {filter === 'text' && <FileIcon size={48} color="rgba(0, 0, 0, 0.3)" />}
        {filter === 'url' && (
          <LinkIcon size={48} color="rgba(0, 0, 0, 0.3)" opacity={1} />
        )}
      </div>
    );
  };

  const renderEmptyAttachedFile = () => {
    return (
      <div className="home_attached_file_empty_body">
        {renderEmptyIcon()}
        <div className="font18 font-regular">
          {filter === 'all' || filter === 'pdf'
            ? `No documents uploaded yet`
            : filter === 'text'
            ? `No notes uploaded yet`
            : filter === 'url'
            ? `No website linked yet`
            : `No audio recordings uploaded yet`}
        </div>
        <div className="font14  font-regular" style={{ opacity: '0.6' }}>
          {filter === 'all' || filter === 'pdf'
            ? `Upload your first document to get content created and referenced automatically by your personal AI.`
            : filter === 'text'
            ? `Upload your first note to to get content created and referenced automatically by your personal AI. \nUse this to jot down short notes for what you want to remember or thoughts you want to share.`
            : filter === 'url'
            ? `Link your first webpage to to get content created and referenced automatically by your personal AI. \nUse this to analyse text content on almost any website, remember content and share it with your network.`
            : `Upload your first audio recording to to get content created and referenced automatically by your personal AI. \nJust let your thoughts flow and speak them out instead of writing everything down.`}
        </div>
      </div>
    );
  };

  const renderNotMatchedAttachedFile = () => {
    return (
      <div className="home_attached_file_empty_body">
        <img src={PAFIconDark} />
        <div className="font20 font-general">No matched documents</div>
        <div className="font16" style={{ opacity: '0.6' }}>
          You can try new keyword to find the right docs
        </div>
      </div>
    );
  };

  const renderAttachedFile = () => {
    return arrFile.length > 4 ? (
      <div id="attachScrollableDiv" className="home_attached_file_body">
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onWheel={onWheel}
          onUpdate={onLoadMoreData}
        >
          {arrFile.map((item, index) => {
            return (
              <CardAttachedFile
                key={`card_attached_file_${index}`}
                data={item}
                index={index}
                tab={topTab}
                screen={itemType}
                onAddTag={(data, positionX) => {
                  setSelectedFile(data);
                  setIsShowAddTagModal(true);
                  setModalPosition(positionX);
                }}
                onRemoveOnUI={onRemoveDumpOnUI}
              />
            );
          })}
        </ScrollMenu>
      </div>
    ) : (
      <div className={`row_align_items home_attached_file_body`}>
        {arrFile.map((item, index) => {
          return (
            <CardAttachedFile
              key={`card_attached_file_${index}`}
              data={item}
              index={index}
              tab={topTab}
              screen={itemType}
              onAddTag={(data, positionX) => {
                setSelectedFile(data);
                setIsShowAddTagModal(true);
                setModalPosition(positionX);
              }}
              onRemoveOnUI={onRemoveDumpOnUI}
            />
          );
        })}
      </div>
    );
  };

  const renderAttachedTabs = () => {
    return (
      <div className="attached_file_tabs">
        <div
          className={`font-bold font16 ${
            filter === 'all'
              ? 'attached_file_tab_selected'
              : 'attached_file_tab'
          } `}
          onClick={() => onClickFilter('all')}
        >
          All
        </div>
        <div
          className={`font-bold font16 ${
            filter === 'pdf'
              ? 'attached_file_tab_selected'
              : 'attached_file_tab'
          } `}
          onClick={() => onClickFilter('pdf')}
        >
          Documents
        </div>
        <div
          className={`font-bold font16 ${
            filter === 'text'
              ? 'attached_file_tab_selected'
              : 'attached_file_tab'
          } `}
          onClick={() => onClickFilter('text')}
        >
          Notes
        </div>
        <div
          className={`font-bold font16 ${
            filter === 'url'
              ? 'attached_file_tab_selected'
              : 'attached_file_tab'
          } `}
          onClick={() => onClickFilter('url')}
        >
          Web
        </div>
        <div
          className={`font-bold font16 ${
            filter === 'audio'
              ? 'attached_file_tab_selected'
              : 'attached_file_tab'
          } `}
          onClick={() => onClickFilter('audio')}
        >
          Audio
        </div>
      </div>
    );
  };

  const renderTopTab = () => {
    return (
      <div className="top_tab row_align_items">
        <div
          className="font-bold font24 clickable"
          style={{
            color: topTab === 'uploaded' ? 'black' : 'rgba(0, 0, 0, 0.3)'
          }}
          onClick={() => setTopTab('uploaded')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">
                All documents, notes, weblinks and audio recordings you have
                uploaded
              </div>
            }
          >
            <div>{`Uploaded(${totalUploadCount})`}</div>
          </Tooltip>
        </div>
        <div
          className="font-bold font24 clickable"
          style={{
            color: topTab === 'received' ? 'black' : 'rgba(0, 0, 0, 0.3)',
            marginLeft: 16
          }}
          onClick={() => setTopTab('received')}
        >
          <Tooltip
            title={
              <div className="font-regular font14">
                This is for anything you received from others users
              </div>
            }
          >
            <div>{`Received(${totalReceiveCount})`}</div>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className="attach_actions row_align_items">
        <div
          className="action_item clickable"
          onClick={() => setShowAddLinkModal(true)}
        >
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Add web links to save content from any website you want
              </div>
            }
          >
            <div className="row_align_items">
              <LinkAddIcon />
              <div className="action_item_label font-bold font16">Add Link</div>
            </div>
          </Tooltip>
        </div>
        <div className="action_item clickable" onClick={onAttachFile}>
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Upload documents like articles, books or presentations in .pdf
                format
              </div>
            }
          >
            <div className="row_align_items">
              <FileUploadIcon color={'#FF3E9A'} />
              <div className="action_item_label font-bold font16">Upload</div>
            </div>
          </Tooltip>
        </div>
        <div className="action_item clickable" onClick={onWrite}>
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Write a quick note to yourself to add to you knowledge base
              </div>
            }
          >
            <div className="row_align_items">
              <EditPencilIcon color={'#FF3E9A'} />
              <div className="action_item_label font-bold font16">Write</div>
            </div>
          </Tooltip>
        </div>
        <div className="action_item clickable" onClick={onRecord}>
          <Tooltip
            arrow
            title={
              <div className="font-regular font14">
                Write a quick note to yourself to add to you knowledge base
              </div>
            }
          >
            <div className="row_align_items">
              <MicIcon color={'#FF3E9A'} />
              <div className="action_item_label font-bold font16">Record</div>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderAddLink = () => {
    return (
      <AddLinkModal
        show={showAddLinkModal}
        itemId={itemId}
        itemType={itemType}
        onClose={() => setShowAddLinkModal(false)}
        onSuccess={(dumpId, link) => {
          onAddLink(dumpId, link);
        }}
      />
    );
  };

  const renderRecordModal = () => {
    return (
      <RecordModal
        show={isRecording}
        itemId={itemId}
        itemType={itemType}
        onClose={() => {
          setIsRecording(false);
        }}
        onSuccess={(dumpId, link) => {
          onAddAudio(dumpId, link);
          setIsRecording(false);
        }}
      />
    );
  };

  const renderDragMask = () => {
    return (
      <div className="drag_mask_view">
        <div className="drag_mask_view">
          <div className="font-bold font32" style={{ color: 'white' }}></div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="home_attached_file">
        <div className="home_attached_file_title font-bold font32">
          {!isMobile ? (
            <div
              className="home_attached_file_title font-bold font32"
              style={{ paddingRight: 32, paddingLeft: 32 }}
            >
              <div
                className="row_space_between"
                style={{
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'center'
                }}
              >
                {renderTopTab()}
                {canDump && renderActions()}
              </div>
            </div>
          ) : isAttachedSearchExpand ? (
            <>
              <div onClick={() => setIsAttachedSearchExpand(false)}>
                <AngleLeftIcon color="#000000" />
              </div>
              <div style={{ width: '100%' }}>
                <SearchBox onSearch={onSearch} fontSize={14} />
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'center'
                }}
              >
                <div className="font-bold font26">Attached Files</div>
              </div>
              <div onClick={() => setIsAttachedSearchExpand(true)}>
                <SearchIcon size={24} />
              </div>
            </>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '12px',
            marginRight: 32,
            marginLeft: 32
          }}
        >
          {renderAttachedTabs()}
          {!isMobile && (
            <SearchBox
              onSearch={onSearch}
              width={'300px'}
              height={'44px'}
              padding={'1px 16px'}
              fontSize={14}
            />
          )}
        </div>

        {searchKeyword !== '' && (
          <div
            className="font16 font-general"
            style={{ marginTop: '28px', marginRight: 32 }}
          >
            Search result for uploads containing "{searchKeyword}":{' '}
            {arrFile.length} {arrFile.length > 1 ? `documents` : `document`}{' '}
            found
          </div>
        )}
        {isLoadingFile ? (
          <div className="home_attached_file_body">
            <LoadingBarAnimation />
          </div>
        ) : arrFile.length > 0 ? (
          renderAttachedFile()
        ) : searchKeyword === '' ? (
          renderEmptyAttachedFile()
        ) : (
          renderNotMatchedAttachedFile()
        )}
      </div>
      {renderAddTagModal()}
      {renderAddLink()}
      {renderRecordModal()}
      {dragging && renderDragMask()}
    </>
  );
};
