import ArrowLeftIcon from 'assets/svgs/ArrowLeftIcon';
import EnvelopeIcon from 'assets/svgs/EnvelopeIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import { JoinOrgByEmailModal } from 'components/Modals/JoinOrgByEmailModal';
import { OrgJoinMagicModal } from 'components/Modals/OrgJoinMagicModal';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { CarOrg } from './CardOrg';
import { CreateNewOrg } from './CreateNewOrg';
import { apiOrgSearch } from 'shared/api/org';
import InfiniteScrollWrapper from './Wrapper';

import './index.styles.scss';
import { socket } from 'components/Auth';
import { getArrFromString } from 'shared/utils/utils';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';

export const JoinNewOrg = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [tab, setTab] = useState<'main' | 'pending' | 'create'>('main');
  const [showJoinByEmailModal, setShowJoinByEmailModal] = useState(false);
  const [showMagicLinkModal, setShowMagicLinkModal] = useState(false);
  const [createOrgModal, setCreateOrgModal] = useState(false);
  const [organisations, setOrganisations] = useState<Array<any>>([]);
  const [hasOrgMore, setHasOrgMore] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [queryOrg, setQueryOrg] = useState('');

  const [additionalEmail, setAdditionalEmail] = useState('');
  const [totalRequestCount, setTotalRequestCount] = useState(0);

  useEffect(() => {
    if (tab === 'pending')
      setOrganisations((prev) =>
        prev.filter((item, index) => item.isFollowed === 'requested')
      );
  }, [tab]);

  useEffect(() => {
    if (tab === 'main') loadOrganisations('', true);
  }, [tab]);

  useEffect(() => {
    if (socket) {
      const newMessageHandler = (data) => {
        if (data.orgs) {
          onUpdateOrg(data);
        }
      };

      socket.on('email-verified', newMessageHandler);

      return () => {
        socket.removeListener('email-verified', newMessageHandler);
      };
    }
  }, [socket]);

  const onBack = () => {
    if (tab === 'main') navigate(-1);
    else if (tab === 'pending') setTab('main');
  };

  const onSearch = (query) => {
    setQueryOrg(query);
    loadOrganisations(query, true);
  };

  const onNext = () => {
    loadOrganisations('', false);
  };

  const onUpdateOrg = (arrOrgId) => {
    const arrJoined = getArrFromString(arrOrgId, ',');
    setOrganisations((prev) => {
      const newArr = prev.map((item, index) => {
        if (arrJoined.includes(item.id)) {
          return {
            ...item,
            isFollowed: 'true'
          };
        } else {
          return item;
        }
      });

      return newArr;
    });
  };

  const loadOrganisations = async (query = '', init = true) => {
    if (init) {
      const res = await apiOrgSearch({
        query: query,
        friends: false,
        limit: 10,
        offset: 0
      });
      if (res.sucess) {
        setOrganisations(res.data);
        setHasOrgMore(res.hasMore);
        setLastId(res.lastId);
        setTotalRequestCount(res.requests);
      }
    } else {
      const res = await apiOrgSearch({
        query: queryOrg,
        friends: false,
        limit: 10,
        offset: lastId
      });
      if (res.sucess) {
        if (tab === 'pending')
          setOrganisations((prev) =>
            [...prev, ...res.data].filter(
              (item, index) => item.isFollowed === 'requested'
            )
          );
        else setOrganisations((prev) => [...prev, ...res.data]);
        setHasOrgMore(res.hasMore);
        setLastId(res.lastId);
      }
    }
  };

  const renderHeader = () => {
    return (
      <div className="join_new_org_header">
        <div className="header_title">
          {location.key !== 'default' && (
            <div
              className="navigation_back_btn clickable"
              onClick={() => onBack()}
            >
              <ArrowLeftIcon />
            </div>
          )}
          <div className="title_text font-bold font24">
            {tab === 'main' ? 'Join New School' : 'Pending Schools'}
          </div>
        </div>
        <div className="header_buttons">
          <div
            className="join_by_email_btn font-bold font16 clickable"
            onClick={() => setShowJoinByEmailModal(true)}
          >
            <EnvelopeIcon />
            Join by Email
          </div>
        </div>
      </div>
    );
  };

  const renderQuestionInput = () => {
    return (
      <SearchBox
        onSearch={onSearch}
        width="100%"
        height="64"
        backgroundColor="#F1F6F9"
        placeholder="Search school here..."
        fontSize={24}
        searchIconClickable={true}
        type="joinNewOrg"
      />
    );
  };

  const renderOrganisations = () => {
    return (
      <div className="card_org_panel">
        {tab === 'main' && queryOrg === '' && (
          <div className="card_org_list_header">
            <div className="header_text font-bold font24">Trending Schools</div>
            <div
              className="pending_count font-bold font16 clickable"
              onClick={() => setTab('pending')}
            >
              {`Pending (${totalRequestCount})`}
            </div>
          </div>
        )}
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={organisations.length}
          functionNext={onNext}
          hasOrgsMore={hasOrgMore}
          parentId="scrollableDiv"
        >
          <div className="card_org_list">
            {organisations.map((item, index) => {
              return <CarOrg key={`car_org_${index}`} data={item} />;
            })}
          </div>
        </InfiniteScrollWrapper>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <>
        {renderHeader()}
        {tab === 'main' && renderQuestionInput()}
        {renderOrganisations()}
      </>
    );
  };

  const renderCreate = () => {
    return <CreateNewOrg setTab={setTab} />;
  };

  const renderJoinByEmailModal = () => {
    return (
      <JoinOrgByEmailModal
        show={showJoinByEmailModal}
        onClose={() => setShowJoinByEmailModal(false)}
        onSuccess={(email) => {
          setShowJoinByEmailModal(false);
          setAdditionalEmail(email);
          setShowMagicLinkModal(true);
        }}
      />
    );
  };

  const renderOrgMagicLinkModal = () => {
    return (
      <OrgJoinMagicModal
        show={showMagicLinkModal}
        onClose={() => setShowMagicLinkModal(false)}
        email={additionalEmail}
      />
    );
  };
  const renderJCreateOrgModal = () => {
    return (
      <JoinOrgByEmailModal
        show={createOrgModal}
        onClose={() => setShowJoinByEmailModal(false)}
        onSuccess={() => {
          setShowJoinByEmailModal(false);
        }}
      />
    );
  };
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (hasOrgMore) onNext();
    }
  };
  return (
    <div className="join_new_org">
      <MainHeader currentPage="school" />
      <div
        className="join_new_org_home"
        onScroll={handleScroll}
        id="scrollableDiv"
      >
        {tab == 'main'
          ? renderMain()
          : tab == 'create'
          ? renderCreate()
          : renderMain()}
        {renderJoinByEmailModal()}
        {renderOrgMagicLinkModal()}
        {renderJCreateOrgModal()}
      </div>
    </div>
  );
};
