import { CreateGroupModal } from 'components/Modals/CreateGroupModal';
import React, { useEffect, useState } from 'react';
import { apiGetGroups, apiGetGroupSearch } from 'shared/api/group';
import { CardJoinedGroup } from './CardJoinedGroup';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import Tooltip from '@mui/material/Tooltip';

import './index.styles.scss';
import { SuccessCreatedGroupModal } from 'components/Modals/SuccessCreateGroupModal';
import { useNavigate } from 'react-router-dom';
import { SendInvitationEmailModal } from 'components/Modals/SendGroupInvitationEmailModal';
import { InviteUserToGroupByIdModal } from 'components/Modals/InviteUserToGroupByIdModal';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';

interface Props {
  onChangedStatus: (groupId) => void;
}

export const JoinedGroup = ({ onChangedStatus }: Props) => {
  const navigate = useNavigate();

  const [arrGroup, setArrGroup] = useState<Array<any>>([]);
  const [lastIdDump, setLastIdDump] = useState(0);
  const [hasMoreDump, setHasMoreDump] = useState(true);
  const [isLoadingDump, setIsLoadingGroup] = useState(false);

  const [searchTxt, setSearchTxt] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [showSuccessCreatedModal, setShowSuccessCreatedModal] = useState(false);
  const [createdGroup, setCreatedGroup] = useState<any>(null);

  const [showSendEmailInviteModal, setShowSendEmailInviteModal] =
    useState(false);
  const [showInviteUserByIdModal, setShowInviteUserByIdModal] = useState(false);

  const [isSearchBoxFocus, setIsSearchBoxFocus] = useState(false);
  const [isSearchedResult, setIsSearchedResult] = useState(false);
  const previousPage = localStorage.getItem('previous_page');

  useEffect(() => {
    if (previousPage === 'profile') {
      setIsSearchBoxFocus(true);
      localStorage.removeItem('previous_page');
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (init = true, keyword = '') => {
    setIsSearchedResult(false);
    if (init) {
      setIsLoadingGroup(true);
      const res = await apiGetGroups({
        query: keyword,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrGroup(res.data);
        setLastIdDump(res.lastId);
        setHasMoreDump(res.hasMore);
      }
      setIsLoadingGroup(false);
    } else {
      if (hasMoreDump && !isLoadingDump) {
        const res = await apiGetGroups({
          query: keyword,
          limit: 10,
          offset: lastIdDump
        });

        if (res.success) {
          setArrGroup((prev) => [...prev, ...res.data]);
          setLastIdDump(res.lastId);
          setHasMoreDump(res.hasMore);
        }
      }
    }
  };

  const loadSearchData = async (init = true, keyword = '') => {
    setIsSearchedResult(true);
    if (init) {
      setIsLoadingGroup(true);
      const res = await apiGetGroupSearch({
        query: keyword,
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrGroup(res.data);
        setLastIdDump(res.lastId);
        setHasMoreDump(res.hasMore);
      }
      setIsLoadingGroup(false);
    } else {
      if (hasMoreDump && !isLoadingDump) {
        const res = await apiGetGroupSearch({
          query: keyword,
          limit: 10,
          offset: lastIdDump
        });

        if (res.success) {
          setArrGroup((prev) => [...prev, ...res.data]);
          setLastIdDump(res.lastId);
          setHasMoreDump(res.hasMore);
        }
      }
    }
  };

  const onShowCreateGroup = () => {
    setShowCreateModal(true);
  };

  const onSuccessToCreateGroup = () => {
    setShowSuccessCreatedModal(false);
    navigate(`/group/detail/${createdGroup!.code}`);
  };

  const onSearch = (sT: string) => {
    setSearchTxt(sT);
    if (sT === '') {
      loadData(true, sT);
    } else {
      loadSearchData(true, sT);
    }
  };

  const renderTop = () => {
    return (
      <div className="group_left_header">
        {!isSearchBoxFocus && (
          <div className="font32 font-bold">Joined Groups</div>
        )}
        <div style={{ display: 'flex', width: isSearchBoxFocus ? '100%' : '' }}>
          <SearchBox
            onSearch={onSearch}
            fontSize={20}
            onFocus={() => setIsSearchBoxFocus(true)}
            onBlur={() => setIsSearchBoxFocus(false)}
            width={isSearchBoxFocus ? 'calc(100% - 168px)' : ''}
          />

          <Tooltip
            title={
              <div className="font-regular font14">
                Create a group and invite others to share documents and insights
                with. Perfect for study groups, research teams or just to share
                with your friends
              </div>
            }
          >
            <div className="btn_create_group" onClick={onShowCreateGroup}>
              <div className="font-bold font14 btn_create_group_txt">
                + Create Group
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="group_left_body">
        {isLoadingDump && (
          <div
            className="row_align_items row_space_between"
            style={{ marginTop: 200 }}
          >
            <LoadingBarAnimation />
          </div>
        )}
        {searchTxt == '' &&
        !isLoadingDump &&
        !hasMoreDump &&
        arrGroup.length == 0 ? (
          <div className="no_group">
            <div className="hug_icon">🤼‍♀️</div>
            <div className="font-bold font20 text_group_yet">
              You have not joined a group yet
            </div>
            <div className="font-regular font14 text_create_group">
              Find a group on the recommendation panel or create your own and
              invite your friends
            </div>
            <Tooltip
              title={
                <div className="font-regular font14">
                  Create a group and invite others to share documents and
                  insights with. Perfect for study groups, research teams or
                  just to share with your friends
                </div>
              }
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="btn_create_group" onClick={onShowCreateGroup}>
                  <div className="font-bold font14 btn_create_group_txt">
                    + Create Group
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={arrGroup.length}
            next={() => {
              if (searchTxt === '') {
                loadData(false);
              } else {
                loadSearchData(false);
              }
            }}
            hasMore={hasMoreDump}
            loader={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LoadingCircular size={20} color="main" />
              </div>
            }
            refreshFunction={() => {}}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
          >
            {arrGroup.map((item, index) => {
              return (
                <CardJoinedGroup
                  key={`card_joined_group_${index}`}
                  data={item}
                  showQuestion={!isSearchedResult}
                  onChangedStatus={onChangedStatus}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    );
  };

  const renderCreateModal = () => {
    return (
      <CreateGroupModal
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onSuccess={(newData) => {
          setShowCreateModal(false);
          setCreatedGroup(newData);
          loadData();
          setShowSuccessCreatedModal(true);
        }}
      />
    );
  };

  const renderCreatedSuccessModal = () => {
    return (
      <SuccessCreatedGroupModal
        show={showSuccessCreatedModal}
        onClose={() => onSuccessToCreateGroup()}
        groupDetail={createdGroup}
        onSendInvitationEmail={() => {
          setShowSuccessCreatedModal(false);
          setShowSendEmailInviteModal(true);
        }}
        onAddUserById={() => {
          setShowSuccessCreatedModal(false);
          setShowInviteUserByIdModal(true);
        }}
      />
    );
  };
  const renderSendInviteEmailModal = () => {
    return (
      <SendInvitationEmailModal
        show={showSendEmailInviteModal}
        groupInfo={createdGroup}
        onClose={() => {
          setShowSendEmailInviteModal(false);
        }}
        onBack={() => {
          setShowSuccessCreatedModal(true);
          setShowSendEmailInviteModal(false);
        }}
      />
    );
  };
  const renderAddUserToGroupByIdModal = () => {
    return (
      <InviteUserToGroupByIdModal
        createdGroup={createdGroup}
        show={showInviteUserByIdModal}
        onClose={() => {
          setShowInviteUserByIdModal(false);
        }}
        onBack={() => {
          setShowSuccessCreatedModal(true);
          setShowInviteUserByIdModal(false);
        }}
      />
    );
  };
  return (
    <div className="group_right">
      {renderTop()}
      {renderBody()}
      {renderCreateModal()}
      {renderCreatedSuccessModal()}
      {renderSendInviteEmailModal()}
      {renderAddUserToGroupByIdModal()}
    </div>
  );
};
