import React, { useEffect, useState } from 'react';

import './index.styles.scss';

export interface TagType {
  item_id: number;
  item_type: any; //'interest' | 'organisation' | 'group' | 'user_tag'
  name: string;
  image: string;
}

interface TagProps {
  isSelected: boolean;
  isEditMode: boolean;
  data: TagType;
  type: 'modal' | 'else';
  onClicked?: (data: TagType) => void;
}

export const Tag = ({
  isSelected,
  isEditMode,
  onClicked,
  data,
  type
}: TagProps) => {
  const [image, setImage] = useState(data.image);

  useEffect(() => {
    setImage(data.image);
  }, [data]);

  const markSelectedColor =
    data.item_type === 'interest'
      ? '#2F9BFF'
      : data.item_type === 'group'
      ? '#2BBC3A'
      : data.item_type === 'user_tag'
      ? '#2F9BFF'
      : '#8D1516';
  const bgSelectedColor =
    data.item_type === 'interest'
      ? '#CCE7FF'
      : data.item_type === 'group'
      ? '#CEFDEF'
      : data.item_type === 'user_tag'
      ? '#E0F0FF'
      : '#F8D3D3';

  let markColor =
    !isSelected && isEditMode ? 'rgba(255, 255, 255, 0.3)' : markSelectedColor;
  let bgColor =
    !isSelected && isEditMode ? 'rgba(255, 255, 255, 0.1)' : bgSelectedColor;
  let tagColor = !isSelected && isEditMode ? '#FFFFFF' : '#000000';
  if (type == 'modal') {
    markColor = !isSelected ? 'rgba(0, 0, 0, 0.1)' : markSelectedColor;
    bgColor = !isSelected ? 'rgba(0, 0, 0, 0.04)' : bgSelectedColor;
    tagColor = !isSelected && isEditMode ? '#FFFFFF' : '#000000';
  }

  return (
    <div
      className="tag_view clickable"
      style={{ backgroundColor: bgColor }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClicked && onClicked(data);
      }}
    >
      <div
        className="tag_view_marker"
        style={{ backgroundColor: markColor }}
      ></div>
      {data.item_type === 'user_tag' ? (
        <div
          className="font-medium font12"
          style={{ color: markColor, marginLeft: 8 }}
        >
          #
        </div>
      ) : (
        !!image && <img src={image} className="tag_view_image" />
      )}

      <div
        className="tag_view_text font-medium font14"
        style={{ color: tagColor }}
      >
        {`${data.name.slice(0, 25)}` + (data.name.length > 25 ? '...' : '')}
      </div>
    </div>
  );
};
