import QuestionIcon from 'assets/svgs/QuestionIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { GroupInvitation } from './Invitation';
import { JoinedGroup } from './JoinedGroup';
import './index.styles.scss';
import { GroupIntro } from './GroupIntro';

interface GroupHomeProps {
  setGroupId: any;
}

export const GroupHome = ({ setGroupId }: GroupHomeProps) => {
  const navigate = useNavigate();
  const [changedId, setChangedId] = useState(-1);

  useEffect(() => {
    setGroupId(0);
  });

  const renderLeftPanel = () => {
    return (
      <div style={{ width: 500, height: '100%' }}>
        <GroupInvitation changedId={changedId} />
      </div>
    );
  };

  const renderRightPanel = () => {
    return (
      <div
        style={{ width: 'calc(100% - 530px)', height: '100%', marginLeft: 28 }}
      >
        {/* <GroupIntro/> */}
        <JoinedGroup onChangedStatus={setChangedId} />
      </div>
    );
  };

  return (
    <div className="row group_body">
      {renderLeftPanel()}
      {renderRightPanel()}
    </div>
  );
};
