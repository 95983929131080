import CloseIcon from 'assets/svgs/CloseIcon';
import NoteIcon from 'assets/svgs/NoteIcon';
import UserCheckIcon from 'assets/svgs/UserCheckIcon';
import React, { useEffect, useState } from 'react';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { CardFileReference } from './CardReference';
import { CardUserReference } from './CardReference/CardUserReference';

import './index.styles.scss';
import { apiGetQAFileReference, apiGetQAUserReference } from 'shared/api/qa';
import InfiniteScrollWrapper from './Wrapper';

interface OrgAddAnswerReferenceModalPrpos {
  show: boolean;
  itemId?: number;
  itemType?: 'user' | 'group' | 'organisation';
  posX: number;
  posY: number;
  onlyCreator?: boolean;
  showType: 'dump' | 'reference';
  onClose: () => void;
  onDone: (arrUserRef, arrFileRef) => void;
}

export const OrgAddAnswerReferenceModal = ({
  show,
  posX,
  posY,
  itemId,
  itemType,
  onlyCreator,
  showType,
  onClose,
  onDone
}: OrgAddAnswerReferenceModalPrpos) => {
  const [tab, setTab] = useState<'creator' | 'document'>('creator');

  const [selectedFileRef, setSelectedFileRef] = useState<Array<number>>([]);
  const [selectedUserRef, setSelectedUserRef] = useState<Array<number>>([]);

  const [arrUserReference, setArrUserReference] = useState<Array<any>>([]);
  const [lastUserReferenceId, setLastUserReferenceId] = useState(0);
  const [hasUserReferenceMore, setHsUserReferenceMore] = useState(true);
  const [isLoadingUserReference, setIsLoadingUserReference] = useState(false);

  const [arrFileReference, setArrFileReference] = useState<Array<any>>([]);
  const [lastFileReferenceId, setLastFileReferenceId] = useState(0);
  const [hasFileReferenceMore, setHsFileReferenceMore] = useState(true);
  const [isLoadingFileReference, setIsLoadingFileReference] = useState(false);

  useEffect(() => {
    if (show) {
      if (showType === 'reference') {
        setTab('creator');
        loadUserReferenceData();
      } else {
        setTab('document');
        loadFileReferenceData();
      }
    } else {
      setArrUserReference([]);
      setArrFileReference([]);
      setSelectedUserRef([]);
      setSelectedFileRef([]);
    }
  }, [show, showType]);

  const loadUserReferenceData = async (init = true, keyword = '') => {
    if (init) {
      setIsLoadingUserReference(true);
      const res = await apiGetQAUserReference({
        query: keyword,
        to: itemId,
        to_type: itemType
      });

      if (res.success) {
        setArrUserReference(res.data);
        setLastUserReferenceId(res.lastId);
        setHsUserReferenceMore(res.hasMore);
      }
      setIsLoadingUserReference(false);
    } else {
      if (hasUserReferenceMore && !isLoadingUserReference) {
        setIsLoadingUserReference(true);
        const res = await apiGetQAUserReference({
          query: keyword,
          to: itemId,
          to_type: itemType,
          offset: lastUserReferenceId
        });

        if (res.success) {
          setArrUserReference((prev) => [...prev, ...res.data]);
          setLastUserReferenceId(res.lastId);
          setHsUserReferenceMore(res.hasMore);
        }
        setIsLoadingUserReference(false);
      }
    }
  };

  const loadFileReferenceData = async (init = true, keyword = '') => {
    if (init) {
      setIsLoadingFileReference(true);
      const res = await apiGetQAFileReference({
        query: keyword,
        to: itemId,
        to_type: itemType
      });

      if (res.success) {
        setArrFileReference(res.data);
        setLastFileReferenceId(res.lastId);
        setHsFileReferenceMore(res.hasMore);
      }
      setIsLoadingFileReference(false);
    } else {
      if (hasFileReferenceMore && !isLoadingFileReference) {
        setIsLoadingFileReference(true);
        const res = await apiGetQAFileReference({
          query: keyword,
          to: itemId,
          to_type: itemType,
          offset: lastFileReferenceId
        });

        if (res.success) {
          setArrFileReference((prev) => [...prev, ...res.data]);
          setLastFileReferenceId(res.lastId);
          setHsFileReferenceMore(res.hasMore);
        }
        setIsLoadingFileReference(false);
      }
    }
  };

  const onDoneAddReference = () => {
    const arrSelectedUserRef: Array<any> = [];
    const arrSelectedFileRef: Array<any> = [];

    // convert id array to ref array
    arrUserReference.forEach((ref) => {
      if (selectedUserRef.includes(ref.creator_id)) {
        arrSelectedUserRef.push(ref);
      }
    });
    arrFileReference.forEach((ref) => {
      if (selectedFileRef.includes(ref.id)) {
        arrSelectedFileRef.push(ref);
      }
    });

    onDone(arrSelectedUserRef, arrSelectedFileRef);
  };

  const onSelectUser = (referenceIdx: number) => {
    if (selectedUserRef.includes(referenceIdx)) {
      setSelectedUserRef((prev) =>
        prev.filter((item, index) => item !== referenceIdx)
      );
    } else {
      setSelectedUserRef((prev) => [...prev, referenceIdx]);
    }
  };

  const onSelectFile = (referenceIdx: number) => {
    if (selectedFileRef.includes(referenceIdx)) {
      setSelectedFileRef((prev) =>
        prev.filter((item, index) => item !== referenceIdx)
      );
    } else {
      setSelectedFileRef((prev) => [...prev, referenceIdx]);
    }
  };

  const renderTop = () => {
    return (
      <div
        className="row_space_between row_align_items"
        style={{ marginBottom: 16 }}
      >
        <div className="row_align_items">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon opacity={0.4} />
          </div>
          <div className="font-bold font16" style={{ marginLeft: 16 }}>
            Add References
          </div>
        </div>
        <div
          className="font-bold font12 clickable"
          style={{ color: '#FF3E9A' }}
          onClick={() => {
            setSelectedFileRef([]);
            setSelectedUserRef([]);
          }}
        >
          Clear
        </div>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className="dlg_tab">
        <div
          className="dlg_tab_creator clickable"
          onClick={() => setTab('creator')}
          style={{
            borderBottomColor: `${
              tab === 'creator' ? '#FF3E9A' : 'rgba(0, 0, 0, 0.1)'
            }`
          }}
        >
          <UserCheckIcon
            color={tab === 'creator' ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
          />
          <div
            className="font-bold font12"
            style={{
              color: `${tab === 'creator' ? 'black' : 'rgba(0, 0, 0, 0.3)'}`
            }}
          >
            Creators
          </div>
        </div>
        <div
          className="dlg_tab_document clickable"
          style={{
            borderBottomColor: `${
              tab === 'document' ? '#FF3E9A' : 'rgba(0, 0, 0, 0.1)'
            }`
          }}
          onClick={() => setTab('document')}
        >
          <NoteIcon
            color={tab === 'document' ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
          />
          <div
            className="font-bold font12"
            style={{
              color: `${tab === 'document' ? 'black' : 'rgba(0, 0, 0, 0.3)'}`
            }}
          >
            Documents
          </div>
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <SearchBox
        fontSize={14}
        onSearch={(keyword) => {
          if (tab === 'creator') {
            loadUserReferenceData(true, keyword);
          } else {
            loadFileReferenceData(true, keyword);
          }
        }}
      />
    );
  };

  const renderBody = () => {
    if (tab === 'creator' || onlyCreator) {
      return (
        <div className="dlg_list_creator">
          <InfiniteScroll
            dataLength={arrUserReference.length}
            next={() => loadUserReferenceData(false)}
            hasMore={hasUserReferenceMore}
            loader={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LoadingCircular size={20} color="main" />
              </div>
            }
            refreshFunction={() => {}}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            style={{ overflow: 'hidden' }}
          >
            {arrUserReference.map((item, index: number) => {
              return (
                <CardUserReference
                  key={`card_user_refer_dlg_${index}`}
                  data={item}
                  isSelected={
                    selectedUserRef.includes(item.creator_id) ? true : false
                  }
                  onSelect={onSelectUser}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      );
    } else {
      return (
        <div className="dlg_list_document" id="scrollableDiv">
          <InfiniteScrollWrapper
            isInfiniteScrollOn={true}
            lengthData={arrFileReference.length}
            functionNext={() => loadFileReferenceData(false)}
            hasFileReferenceMore={hasFileReferenceMore}
            parentId="scrollableDiv"
          >
            {arrFileReference.map((item, index: number) => {
              return (
                <CardFileReference
                  key={`card_file_refer_dlg_${index}`}
                  data={item}
                  onSelect={onSelectFile}
                  isSelected={selectedFileRef.includes(item.id) ? true : false}
                />
              );
            })}
          </InfiniteScrollWrapper>
        </div>
      );
    }
  };

  if (show) {
    return (
      <div className="reference_modal_custom_body">
        <div
          className="dlg_add_reference"
          id="dlg_add_reference"
          style={{ left: posX, top: posY, position: 'fixed' }}
        >
          {renderTop()}
          {renderSearch()}
          {renderBody()}
          <div className="done_btn clickable" onClick={onDoneAddReference}>
            Done
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};
