import FileIcon from 'assets/svgs/FileIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import MicIcon from 'assets/svgs/MicIcon';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import TrashIcon from 'assets/svgs/TrashIcon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiDeletionStatement } from 'shared/api/delete';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { showToast } from 'shared/ui-kit/PrimaryToast';
import './index.styles.scss';

interface Props {
  data: any;
  index: any;
  onRemoveOnUI?: (id) => void;
}

export const CardAnswer = ({ data, index, onRemoveOnUI }: Props) => {
  const navigate = useNavigate();

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isRemoving, setIsRemoving] = useState(false);

  const onRemove = async () => {
    if (!isRemoving && onRemoveOnUI) {
      setIsRemoving(true);

      const res = await apiDeletionStatement({
        statement: data.id
      });

      if (res.success) {
        showToast('success', 'Deletion successful!');

        onRemoveOnUI && onRemoveOnUI(data.id);
      }

      setIsRemoving(false);
    }
  };

  const onClickRef = () => {
    if (data.dump_type === 'pdf') {
      navigate(`/document/${data.dump}`);
    } else if (
      data.dump_type === 'url' ||
      data.dump_type === 'html' ||
      data.dump_type === 'html'
    ) {
      window.open(data.url);
    }
  };

  const renderFileIcon = (type) => {
    return (
      <div
        className="row_align_items row_space_between"
        style={{ width: 20, height: 20 }}
      >
        {type === 'pdf' ? (
          <PdfFileIcon size={20} />
        ) : type === 'audio' ? (
          <MicIcon size={24} color="#FF3E9A" opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={20} color="#FF3E9A" opacity={1} />
        ) : (
          <FileIcon />
        )}
      </div>
    );
  };

  return (
    <div className="card_statement_home">
      <div
        className="card_statement_home_no font72 font-bold"
        style={index > 9 ? { marginLeft: '0px' } : {}}
      >
        {index.toString().padStart(2, '0')}
      </div>
      <div className="card_statement_home_body">
        <div className="font-regular">
          <pre className="font26">{data.text}</pre>
        </div>
        <div className="row_space_between">
          <div
            className="card_statement_home_body_referneces row_align_items clickable"
            onClick={onClickRef}
          >
            {renderFileIcon(data.dump_type)}
            <div className="font14">
              <div style={{ textDecoration: 'none' }}>{data.title}</div>
            </div>
          </div>
          {userInfo?.id == data.user && (
            <div className="book_marked_icon clickable" onClick={onRemove}>
              {isRemoving ? (
                <LoadingCircular size={24} color="main" />
              ) : (
                <TrashIcon />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
