import { useEffect, useState } from 'react';

import { GroupScreen } from 'components/Main/Group';
import { GroupDetail } from 'components/Main/Group/Detail';
import { GroupDetailBookMark } from 'components/Main/Group/Detail/BookMarked';
import { GroupMember } from 'components/Main/Group/GroupMember';
import { GroupHome } from 'components/Main/Group/Home';
import { HomeScreen } from 'components/Main/Home/Home';
import { MyDraft } from 'components/Main/Home/MyDraft';
import { MyQuestion } from 'components/Main/Home/MyQuestion';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { GroupLinkShare } from 'components/Main/Group/GroupLinkShare';
import { Conversation } from 'components/Main/Conversation';
import { Point } from 'components/Main/Point';
import { EmailVerifying } from 'components/Auth/EmailVerifying';
import { Creators } from 'components/Main/Creators';
import { WriteNewDoc } from 'components/Main/Home/WriteNewDoc';
import { DocumentViewer } from 'components/Main/Group/Document/DocumentViewer';

import { ViewDoc } from 'components/Main/Home/WriteNewDoc/ViewDoc';
import { SharePoint } from 'components/Main/Point/SharePoint';
import { LinkNames } from './constants';
import { RegisterMagicLink } from 'components/Auth/RegisterMagicLink';
import { ProfileScreen } from 'components/Main/Profile/Profile';

import { OrganisationScreen } from 'components/Main/Organisation';
import { AllSharedDoc } from 'components/Main/Organisation/AllSharedDoc';
import { AllSharedLink } from 'components/Main/Organisation/AllSharedLink';
import { QuestionDetail } from 'components/Main/Organisation/QuestionDetail';
import { OrgHome } from 'components/Main/Organisation/OrgHome';
import { OrgDetail } from 'components/Main/Organisation/OrgDetail';
import { JoinNewOrg } from 'components/Main/Organisation/JoinNewOrg';
import { Events } from 'components/Main/Organisation/Events';
import { EventDetail } from 'components/Main/Organisation/Events/EventDetail';
import { OrgMember } from 'components/Main/Organisation/OrgMember';
import { NoteViewer } from 'components/Main/Group/Document/DocumentViewer/NoteViewer';
import { AudioViewer } from 'components/Main/Group/Document/DocumentViewer/AudioViewer';
import { AllSharedMeme } from 'components/Main/Organisation/AllSharedMeme';
import { OutOfSevice } from 'components/Main/OutOfService';
import { OrgUnAnsweredQuestion } from 'components/Main/Organisation/OrgUnAnsweredQuestion';
import { AllSharedFlash } from 'components/Main/Organisation/AllSharedFlash';

export const MainRoutes = () => {
  const [groupId, setGroupId] = useState(1);

  useEffect(() => {
    document
      .getElementsByTagName('body')[0]
      .setAttribute('style', 'zoom: 80%;');
  }, []);

  return (
    <Routes>
      <Route path={'/'} element={<HomeScreen />}>
        <Route index path="" element={<MyDraft filters={[]} type="home" />} />
        <Route path="question" element={<MyQuestion filters={[]} />} />
        <Route path="write/:type/:id" element={<WriteNewDoc />} />
        <Route path="view/:dumpId" element={<ViewDoc />} />
      </Route>
      <Route path={'/profile'} element={<ProfileScreen />} />
      <Route path={'/profile/:id/*'} element={<ProfileScreen />} />
      <Route path={'/conversation/:type/:id'} element={<Conversation />} />
      <Route path={'/creators/'} element={<Creators />} />
      <Route path={'/group'} element={<GroupScreen />}>
        <Route
          index
          path={''}
          element={<GroupHome setGroupId={setGroupId} />}
        />
        <Route
          path="detail/:code/*"
          element={<GroupDetail groupId={groupId} setGroupId={setGroupId} />}
        >
          <Route
            index
            path={'*'}
            element={<MyDraft filters={[]} type="group" />}
          />
          <Route
            path={'question'}
            element={<GroupDetailBookMark groupId={0} />}
          />
        </Route>
        <Route path={'member/:id/*'} element={<GroupMember />} />
      </Route>
      <Route path={'/group/:code/*'} element={<GroupLinkShare />}></Route>
      <Route path="/school" element={<OrganisationScreen />}>
        <Route index element={<OrgHome />}></Route>
        <Route path=":id" element={<OrgDetail />}></Route>
        <Route path=":id/docs" element={<AllSharedDoc />}></Route>
        <Route path=":id/links" element={<AllSharedLink />}></Route>
        <Route path=":id/memes" element={<AllSharedMeme />}></Route>
        <Route
          path=":id/flashcards"
          element={<AllSharedFlash selectedOrg={null} />}
        ></Route>
        <Route path="question/:code/*" element={<QuestionDetail />}></Route>
        <Route
          path="question/:code/:section"
          element={<QuestionDetail />}
        ></Route>
        <Route path={'events'} element={<Events />}></Route>
        <Route path="events/:code/*" element={<EventDetail />} />
        <Route path="member/:id" element={<OrgMember />} />
        <Route
          path="noanswer"
          element={<OrgUnAnsweredQuestion selectedOrg={null} />}
        ></Route>
      </Route>
      <Route path={'/school/join'} element={<JoinNewOrg />} />
      <Route path={'/point/*'} element={<Point />}></Route>
      <Route path={'/verify'} element={<EmailVerifying />}></Route>
      <Route path={'/document/:dumpId/*'} element={<DocumentViewer />}></Route>
      <Route path={'/file/:dumpId/*'} element={<NoteViewer />}></Route>
      <Route path={'/audio/:dumpId/*'} element={<AudioViewer />}></Route>
      <Route path={'/share/*'} element={<SharePoint />}></Route>
      <Route path={LinkNames.register_magic} element={<RegisterMagicLink />} />
      <Route path={'/out'} element={<OutOfSevice />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
