import React, { useEffect, useMemo, useState } from 'react';
import { CardTrendingQuestion, QuestionData } from './CardTrendingQuestion';
import './index.styles.scss';

import InfiniteScrollWrapper from 'components/Common/WrapperScroll';
import { QuestionAnswerShareModal } from 'components/Modals/QuestionAnswerShareModal';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { SharedLinks } from '../SharedLinks';
import { SharedDocs } from '../SharedDocs';
import { useNavigate } from 'react-router-dom';
import { SharedMemes } from '../SharedMemes';
import { SharedFlash } from '../SharedFlash';
import { apiGetContentQuestions } from 'shared/api/content';

interface TrendingQuestionProps {
  selectedOrg: any;
  selectedTopic: Array<string>;
  arrOrganisation: any;
  isUnAnsweredFilter: boolean;
  isTrendingFilter: boolean;
  onPostQuestion: () => void;
  onShowAllSharedDoc: () => void;
  onShowAllSharedLink: () => void;
  onShowAllSharedMeme: () => void;
  onShowAllSharedFlash: () => void;
}

export const TrendingQuestion = ({
  selectedOrg,
  selectedTopic,
  arrOrganisation,
  isUnAnsweredFilter,
  isTrendingFilter,
  onPostQuestion,
  onShowAllSharedDoc,
  onShowAllSharedLink,
  onShowAllSharedMeme,
  onShowAllSharedFlash
}: TrendingQuestionProps) => {
  const navigate = useNavigate();

  const [arrQuestions, setArrQuestions] = useState<Array<any>>([]);
  const [hasMoreQuestions, setHasMoreQuestions] = useState(true);
  const [lastIdQuestions, setLastIdQuestions] = useState(0);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
  const [isLoadedQuestions, setIsLoadedQuestions] = useState(false);

  const [selectedQuestionData, setSelectedQuestionData] = useState<
    QuestionData | undefined
  >();
  const [isShareModalShow, setIsShareModalShow] = useState(false);
  const [shareModalType, setShareModalType] = useState<'question' | 'answer'>(
    'question'
  );
  useEffect(() => {
    loadQuestions(true);
  }, [selectedOrg, selectedTopic, isUnAnsweredFilter, isTrendingFilter]);

  const onNext = () => {
    loadQuestions(false);
  };

  const loadQuestions = async (init = true) => {
    const topics = selectedTopic.length == 0 ? undefined : selectedTopic + '';

    if (init) {
      setIsLoadingQuestions(true);
      const res = await apiGetContentQuestions({
        items: selectedOrg === null ? '' : selectedOrg?.id,
        item_type: 'organisation',
        topics,
        unanswered: isUnAnsweredFilter + '',
        trending: isTrendingFilter + '',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setIsLoadedQuestions(true);
        setArrQuestions(res.data);
        setLastIdQuestions(res.lastId);
        setHasMoreQuestions(res.hasMore);
      }
      setIsLoadingQuestions(false);
    } else {
      if (!isLoadingQuestions && hasMoreQuestions) {
        const res = await apiGetContentQuestions({
          items: selectedOrg === null ? '' : selectedOrg?.id,
          item_type: 'organisation',
          topics,
          unanswered: isUnAnsweredFilter + '',
          trending: isTrendingFilter + '',
          limit: 10,
          offset: lastIdQuestions
        });

        if (res.success) {
          setArrQuestions((prev) => [...prev, ...res.data]);
          setLastIdQuestions(res.lastId);
          setHasMoreQuestions(res.hasMore);
        }
      }
    }
  };

  const onShareHandler = (data, modalType) => {
    setSelectedQuestionData(data);
    setIsShareModalShow(true);
    setShareModalType(modalType);
  };

  const onJoinNewOrg = () => {
    navigate(`/school/join/`);
  };

  const convertedArrQuestion = useMemo(() => {
    if (isUnAnsweredFilter) {
      return arrQuestions;
    } else {
      if (arrQuestions?.length === 0) {
        return ['links', 'docs', 'meme', 'flash'];
      } else if (arrQuestions.length === 1) {
        return [...arrQuestions, 'links', 'docs', 'meme', 'flash'];
      } else if (arrQuestions.length === 2) {
        return [
          arrQuestions[0],
          'links',
          arrQuestions[1],
          'docs',
          'meme',
          'flash'
        ];
      } else if (arrQuestions.length === 3) {
        return [
          arrQuestions[0],
          'links',
          arrQuestions[1],
          'docs',
          arrQuestions[2],
          'meme',
          'flash'
        ];
      } else {
        return [
          arrQuestions[0],
          'links',
          arrQuestions[1],
          'docs',
          arrQuestions[2],
          'meme',
          arrQuestions[3],
          'flash',

          ...arrQuestions.slice(4, arrQuestions.length - 1)
        ];
      }
    }
  }, [arrQuestions]);

  const renderShareQuestionToSocial = () => {
    return (
      <QuestionAnswerShareModal
        show={isShareModalShow}
        onClose={() => {
          setIsShareModalShow(false);
        }}
        question={selectedQuestionData}
        type={shareModalType}
      />
    );
  };

  const renderEmptyTrendingQuestions = () => {
    return (
      <div className="empty_trending_questions">
        <div className="empty_trending_questions_body">
          <div className="font56 font-bold">💬</div>
          <div className="empty_trending_questions_body_message">
            <div className="font-bold font20">No questions yet</div>
            <div className="font16 font-general">
              Post your first question here and get an answer from the community
            </div>
          </div>
          <div
            className="create_new_post font14 text-white clickable"
            onClick={onPostQuestion}
          >
            Post a question
          </div>
        </div>
      </div>
    );
  };

  const renderEmptyJoinedOrg = () => {
    return (
      <div className="empty_trending_questions">
        <div className="empty_trending_questions_body">
          <div className="font56 font-bold">💬</div>
          <div className="empty_trending_questions_body_message">
            <div className="font-bold font20">No New Questions</div>
            <div
              className="font16 font-regular"
              style={{ whiteSpace: 'nowrap' }}
            >
              Join an organization to start seeing questions <br /> and answers
              from all members
            </div>
          </div>
          <div
            className="create_new_post font-bold font12 text-white clickable"
            onClick={onJoinNewOrg}
          >
            Join New School
          </div>
        </div>
      </div>
    );
  };

  const renderEmptyQuestion = () => {
    return (
      <div className="empty_trending_questions">
        <div className="empty_trending_questions_body">
          <div className="font56 font-bold">💬</div>
          <div className="empty_trending_questions_body_message">
            <div className="font-bold font20">No Questions Posted</div>
            <div
              className="font16 font-regular"
              style={{ whiteSpace: 'nowrap' }}
            >
              Post your first question here and <br /> get an answer from the
              community
            </div>
          </div>
          <div
            className="create_new_post font-bold font12 text-white clickable"
            onClick={onPostQuestion}
          >
            Post New Question
          </div>
        </div>
      </div>
    );
  };

  const renderTrendingQuestions = () => {
    return (
      <InfiniteScrollWrapper
        isInfiniteScrollOn={true}
        lengthData={arrQuestions.length}
        functionNext={onNext}
        hasOrgsMore={hasMoreQuestions}
        parentId="org_right_panel"
      >
        {isLoadingQuestions ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <LoadingBarAnimation />
          </div>
        ) : convertedArrQuestion.length > 0 ? (
          convertedArrQuestion.map((item, index) => {
            if (item === 'links') {
              return renderSharedLink();
            } else if (item === 'docs') {
              return renderSharedDocs();
            } else if (item === 'meme') {
              return renderSharedMeme();
            } else if (item === 'flash') {
              return renderSharedFlash();
            } else {
              return (
                <CardTrendingQuestion
                  key={`card_trending_question_${index}`}
                  data={item}
                  type="creator"
                  onShareMedia={(data, modalType) => {
                    onShareHandler(data, modalType);
                  }}
                />
              );
            }
          })
        ) : (
          renderEmptyTrendingQuestions()
        )}
      </InfiniteScrollWrapper>
    );
  };

  const renderSharedFlash = () => {
    return (
      <SharedFlash
        selectedOrg={selectedOrg}
        key={'org_shared_flash'}
        onShowAll={onShowAllSharedFlash}
      />
    );
  };

  const renderSharedLink = () => {
    return (
      <SharedLinks
        selectedOrg={selectedOrg}
        key={'org_shared_link'}
        onShowAll={onShowAllSharedLink}
      />
    );
  };

  const renderSharedMeme = () => {
    return (
      <SharedMemes
        selectedOrg={selectedOrg}
        key={'org_shared_meme'}
        onShowAll={onShowAllSharedMeme}
      />
    );
  };

  const renderSharedDocs = () => {
    return (
      <SharedDocs
        selectedOrg={selectedOrg}
        key={'org_shared_doc'}
        onShowAll={onShowAllSharedDoc}
      />
    );
  };

  const renderBody = () => {
    return arrOrganisation?.length === 0
      ? renderEmptyJoinedOrg()
      : arrQuestions.length === 0
      ? renderEmptyQuestion()
      : renderTrendingQuestions();
  };

  if (isLoadingQuestions && !isLoadedQuestions) {
    return (
      <div>
        <LoadingBarAnimation />
      </div>
    );
  } else {
    return (
      <div className="org_trending_question">
        {renderBody()}
        {renderShareQuestionToSocial()}
      </div>
    );
  }
};
