import React from 'react';

function LinkBotMarkIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="74"
      fill="none"
      viewBox="0 0 74 74"
    >
      <path
        fill={props.color ?? '#F3F4F5'}
        d="M74 0H0l13.354 33.703a48 48 0 0026.943 26.943L74 74V0z"
      ></path>
    </svg>
  );
}

export default LinkBotMarkIcon;
