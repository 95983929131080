import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import { ConversationReference } from 'components/Common/ConversationReference';
import { ConversationReferenceDetail } from 'components/Common/ReferenceDetail/ConversationReferenceDetail';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { TypeLoadingText } from 'shared/ui-kit/TypeLoadingText';
import { getArrFromString } from 'shared/utils/utils';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import './index.styles.scss';
import { CustomMathText } from 'components/Common/CustomMathText';

interface ChatDataType {
  id: number;
  room: number;
  creator: number;
  message: string;
  is_ai: number;
  type: 'text';
  timestamp: string;
  ai_type: 'general' | 'user' | 'group' | 'organisation';
  ai_id: number;
  ai_name: string;
  ai_image: string;
  ref_users?: string;
  ref_names?: string;
  ref_images?: string;
  ref_titles?: string;
  ref_filenames?: string;
  ref_sizes?: string;
  ref_texts?: string;
  ref_urls?: string;
  ref_types?: string;
  ref_count?: number;
  ref_ids?: string;
  is_new?: boolean;
}
interface CardChatProps {
  data: ChatDataType;
  setIsSendToOrg: any;
  setCurrentQuestion: any;
}

export const CardChat = ({
  data,
  setIsSendToOrg,
  setCurrentQuestion
}: CardChatProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isExpandReference, setIsExpandReference] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(data.is_new);

  useEffect(() => {
    setIsNewMessage(data.is_new ? data.is_new : false);
  }, [data.is_new]);

  useEffect(() => {
    setIsExpandReference(false);
  }, [data]);

  const arrReferences = useMemo(() => {
    if (data.is_ai) {
      if (data.ref_names || data.ref_types) {
        const arrTmpRefData: Array<any> = [];

        const arrRefIds = getArrFromString(data.ref_ids, ';;;');
        // for user
        const arrTmpName = getArrFromString(data.ref_names, ';;;');
        const arrTmpImage = getArrFromString(data.ref_images, ';;;');

        // for files
        const arrTmpFileName = getArrFromString(
          data.ref_titles ?? data.ref_filenames,
          ';;;'
        );
        const arrTmpFileUrl = getArrFromString(data.ref_urls, ';;;');
        const arrTmpFileType = getArrFromString(data.ref_types, ';;;');

        arrTmpName.map((item, index) => {
          arrTmpRefData.push({
            name: arrTmpName[index],
            image: arrTmpImage[index],
            type: 'user'
          });
        });

        arrTmpFileName.map((item, index) => {
          arrTmpRefData.push({
            id: arrRefIds[index],
            name: arrTmpFileName[index],
            image: arrTmpFileUrl[index],
            type: 'file',
            fileType: arrTmpFileType[index]
          });
        });

        // remove duplicated
        const uniqueArr = arrTmpRefData.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t.image === value.image && t.name === value.name
            )
        );
        return uniqueArr;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [data]);

  const arrReferencesForBelow = useMemo(() => {
    if (data.is_ai === 1) {
      if (data.ref_names || data.ref_types) {
        const arrTmpRefData: Array<any> = [];

        const arrRefIds = getArrFromString(data.ref_ids, ';;;');
        // for user
        const arrTmpName = getArrFromString(data.ref_names, ';;;');
        const arrTmpImage = getArrFromString(data.ref_images, ';;;');
        // for files
        const arrTmpFileName = getArrFromString(
          data.ref_titles ?? data.ref_filenames,
          ';;;'
        );
        const arrTmpFileUrl = getArrFromString(data.ref_urls, ';;;');
        const arrTmpTxt = getArrFromString(data.ref_texts, ';;;');
        const arrTmpFileType = getArrFromString(data.ref_types, ';;;');

        arrRefIds.map((item, index) => {
          arrTmpRefData.push({
            id: arrRefIds[index],
            name: arrTmpName[index],
            fileName: arrTmpFileName[index],
            image: arrTmpImage[index],
            url: arrTmpFileUrl[index],
            text: arrTmpTxt[index],
            fileType: arrTmpFileType[index]
          });
        });
        return arrTmpRefData;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [data]);

  if (data.is_ai === 0) {
    return (
      <>
        <div
          className="card_chat"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}
        >
          <div className="card_chat_header">
            <div className="card_chat_header_image">
              {userInfo?.image && userInfo?.image.startsWith('https') ? (
                <img src={userInfo?.image} className="creator_image" />
              ) : (
                <DefaultAvatarIcon size={24} />
              )}
            </div>
            <div className="font-bold font12" style={{ marginLeft: 8 }}>
              {'You'}
            </div>
          </div>
          <div className="card_chat_own_message font-regular font20">
            {data.message}
          </div>
          <div
            className="font14 font-bold send_to_org clickable"
            onClick={() => {
              setIsSendToOrg(true);
              setCurrentQuestion(data);
            }}
          >
            Send to school
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="card_chat">
          <div className="card_chat_header">
            <div className="card_chat_header_image">
              {data.ai_type === 'general' ? (
                <RobotIcon size={24} />
              ) : data.ai_image ? (
                <img src={data.ai_image} className="creator_image" />
              ) : (
                <DefaultAvatarIcon />
              )}
            </div>
            <div className="font-bold font12" style={{ marginLeft: 8 }}>
              {data.ai_type === 'general' ? `General AI` : `${data.ai_name} AI`}
            </div>
          </div>
          <div className="card_chat_message">
            {isNewMessage ? (
              <div className="font-regular font20">
                <TypeLoadingText
                  text={data.message}
                  speed={100}
                  onFinished={() => {
                    data.is_new = false;
                    setIsNewMessage(false);
                  }}
                />
              </div>
            ) : (
              <pre className="font-regular font20">{data.message}</pre>
            )}
            {/* <SyntaxHighlighter
              language="javascript"
              style={dark}
              wrapLongLines={true}
            > */}
            {/* <CustomMathText /> */}
            {/* </SyntaxHighlighter> */}
            {!isNewMessage && arrReferences.length > 0 && (
              <div style={{ marginTop: 12 }}>
                <ConversationReference
                  data={arrReferences}
                  setIsExpandReference={setIsExpandReference}
                  isExpandReference={isExpandReference}
                />
              </div>
            )}
            {isExpandReference && (
              <ConversationReferenceDetail data={arrReferencesForBelow} />
            )}
          </div>
        </div>
      </>
    );
  }
};
