import React from 'react';

import './index.styles.scss';

interface CardQuestionGroupProps {
  data: any;
  isSelected: boolean;
  onClick: (aiId, aiType) => void;
}
export const CardQuestionGroup = ({
  data,
  isSelected,
  onClick
}: CardQuestionGroupProps) => {
  return (
    <div className="card_org">
      <div className="org_image">
        <img src={data.ai_image} className="org_box_icon" />
      </div>
      <div className="org_info">
        <div className="first_line">
          <div className="org_title font-bold font14">{data.ai_name}</div>
        </div>
      </div>
      <div className="org_select">
        {data.is_shared === 0 && !isSelected ? (
          <div
            className="select_btn text-white font12 font-bold clickable"
            onClick={() => onClick(data.to, data.type)}
          >
            Select
          </div>
        ) : (
          <div
            className="selected_btn font12 font-bold clickable"
            onClick={() => data.is_shared === 0 && onClick(data.to, data.type)}
          >
            {data.is_shared === 1 ? 'Shared' : 'Selected'}
          </div>
        )}
      </div>
    </div>
  );
};
