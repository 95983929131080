import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { PrimaryButton } from 'shared/ui-kit/PrimaryButton/PrimaryButton';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { CarOrg } from './CardOrg';
import InfiniteScrollWrapper from './Wrapper';
import './index.styles.scss';
import { apiQAShareToOrgs } from 'shared/api/qa';
import { apiGetUserOrganisations } from 'shared/api/user';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface SelectOrgModalProps {
  show: boolean;
  onClose: () => void;
  onBack: () => void;
  onSuccess: (groupData) => void;
  question: any;
  type: 'home' | 'conversation';
}

export const SelectOrgModal = ({
  show,
  onClose,
  onSuccess,
  question,
  onBack,
  type
}: SelectOrgModalProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [organisations, setOrganisations] = useState<Array<any>>([]);
  const [hasOrgMore, setHasOrgMore] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [selectedOrgs, setSelectedOrgs] = useState([]);

  useEffect(() => {
    if (show) loadOrganisations(true);
  }, [show]);

  const onNext = () => {
    loadOrganisations(false);
  };

  const loadOrganisations = async (init = true) => {
    if (init) {
      const res = await apiGetUserOrganisations(userInfo?.id, 10, 0);
      if (res.success) {
        setOrganisations(res.data);
        setHasOrgMore(res.hasMore);
        setLastId(res.lastId);
      }
    } else {
      const res = await apiGetUserOrganisations(userInfo?.id, 10, lastId);
      if (res.success) {
        setOrganisations((prev) => [...prev, ...res.data]);
        setHasOrgMore(res.hasMore);
        setLastId(res.lastId);
      }
    }
  };

  const onCreate = async () => {
    setIsCreating(true);
    if (type === 'home') {
      const res = await apiQAShareToOrgs({
        questionId: question.id,
        orgs: selectedOrgs
      });
      if (res.success) {
        showToast('success', 'Shared successfully!');
        setIsCreating(false);
        onSuccess(true);
      }
    } else {
      const res = await apiQAShareToOrgs({
        message: question.id,
        orgs: selectedOrgs
      });
      if (res.success) {
        showToast('success', 'Shared successfully!');

        setIsCreating(false);
        onSuccess(true);
      }
    }
    setIsCreating(false);
  };

  const renderBody = () => {
    return (
      <div className="org_list" id="scrollableDiv">
        <InfiniteScrollWrapper
          isInfiniteScrollOn={true}
          lengthData={organisations.length}
          functionNext={onNext}
          hasOrgsMore={hasOrgMore}
          parentId="scrollableDiv"
        >
          <div className="card_org_list" id="scrollableDiv">
            {organisations.map((item, index) => {
              return (
                <CarOrg
                  key={`car_org_${index}`}
                  data={item}
                  setSelectedOrgs={setSelectedOrgs}
                />
              );
            })}
          </div>
        </InfiniteScrollWrapper>
      </div>
    );
  };
  return (
    <PrimaryModal show={show} width={423} onClose={onClose}>
      <div className="modal_create_group">
        <div className="modal_create_group_header">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onBack();
            }}
          >
            <AngleLeftIcon color="#000000" />
          </div>
          <div className="title font-bold font16">Select School(s)</div>
        </div>
        <div className="modal_create_group_body">
          {renderBody()}
          <div className="btn_create">
            <PrimaryButton
              label="Share to School(s)"
              isLoading={isCreating}
              onPressed={onCreate}
            />
          </div>
        </div>
      </div>
    </PrimaryModal>
  );
};
