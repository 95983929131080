import RobotIcon from 'assets/svgs/RobotIcon';
import UserCheckIcon from 'assets/svgs/UserCheckIcon';
import React, { useMemo } from 'react';
import moment from 'moment';

import './index.styles.scss';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import { getArrFromString } from 'shared/utils/utils';

export interface ChatRoomData {
  id?: number;
  name: string;
  last_interacted: string;
  ai_id: number;
  ai_name: string;
  ai_image: string;
  ai_type: string;
  is_voted: number
}
interface CardChatHistoryProps {
  selectedRoom?: ChatRoomData;
  data: ChatRoomData;
  onSelected: (data: ChatRoomData) => void;
}

export const CardChatHistory = ({
  data,
  selectedRoom,
  onSelected
}: CardChatHistoryProps) => {
  const renderImage = () => {
    if (data.ai_type === 'general') {
      return (
        <div className="card_chat_history_header_image">
          <RobotIcon />
        </div>
      );
    } else {
      return (
        <div className="card_chat_history_header_image">
          {data.ai_image ? (
            <img
              src={data.ai_image}
              className="card_chat_history_header_image"
            />
          ) : (
            <DefaultAvatarIcon />
          )}
        </div>
      );
    }
  };

  const renderName = () => {
    if (data.ai_type === 'general') {
      return (
        <div className="card_chat_history_header_group font-bold font12">
          {`General AI`}
        </div>
      );
    } else {
      return (
        <div className="card_chat_history_header_group font-bold font12">
          {`${data.ai_name} AI`}
        </div>
      );
    }
  };

  return (
    <div
      className="card_chat_history clickable"
      style={
        data.id !== selectedRoom?.id
          ? { backgroundColor: 'transparent' }
          : {
              backgroundColor: 'white'
            }
      }
      onClick={() => {
        onSelected(data);
      }}
    >
      <div className="card_chat_history_header">
        {renderImage()}
        {renderName()}
      </div>
      <div className="card_chat_history_name font-medium font20">
        {data.name}
      </div>
      <div className="card_chat_history_date font-medium font14">
        {`Last interacted at ${moment(data.last_interacted).format('DD MMM')}`}
      </div>
    </div>
  );
};
