import React, { useState } from 'react';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';

import './index.styles.scss';
import { SharePointLeft } from './SharePointLeft';
import { SharePointRight } from './SharePointRight';

export const SharePoint = () => {
  const [invitedCount, setInvitedCount] = useState(0);

  return (
    <div>
      <MainHeader />
      <div className="share_point">
        <SharePointLeft
          invitedCount={invitedCount}
          onUpdateInvitedCount={setInvitedCount}
        />
        <SharePointRight
          onAddNewInvited={(newCount) => {
            setInvitedCount((prev) => prev + newCount);
          }}
        />
      </div>
    </div>
  );
};
