import BookmarkOutlineIcon from 'assets/svgs/BookmarkOutlineIcon';
import ChatSendIcon from 'assets/svgs/ChatSendIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import { socket } from 'components/Auth';
import React, { useEffect, useState } from 'react';
import { apiFileChat, apiFileMark } from 'shared/api/file';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { TypeLoadingAnimation } from 'shared/ui-kit/TypeLoadingAnimation';
import { CardHighlightChat } from './CardHighlightChat';

import appAction from 'redux/app/action';

import './index.styles.scss';
import BookMarkedIcon from 'assets/svgs/BookMarkedIcon';
import { useDispatch } from 'react-redux';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface HighlightConversationProps {
  highlighId: any;
  onClose: () => void;
}

export const HighlightConversation = ({
  highlighId,
  onClose
}: HighlightConversationProps) => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');
  const [isMarked, setIsMarked] = useState(false);

  // messages
  const [arrMessage, setArrMessage] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);

  const [isMarking, setIsMakring] = useState(false);

  useEffect(() => {
    loadMessage();
  }, [highlighId]);

  useEffect(() => {
    onScrollToTop();
  }, [highlighId]);

  useEffect(() => {
    if (socket && highlighId) {
      socket.emit('join-room', `file-ai-${highlighId}`);

      const newMessageHandler = (data) => {
        onAddMessageOnUI(data);
      };

      socket.on('new-file-message-ai', newMessageHandler);

      return () => {
        socket.removeListener('new-file-message-ai', newMessageHandler);
      };
    }
  }, [socket, highlighId]);

  const loadMessage = async (init = true) => {
    setIsLoadingMessage(true);
    const res = await apiFileChat({
      room: highlighId,
      limit: 10,
      offset: 0
    });

    if (res.success) {
      setArrMessage(res.data);
      setLastId(res.lastId);
      setHasMore(res.hasMore);
    } else {
      setArrMessage([]);
    }

    setIsLoadingMessage(false);
  };

  const onMarkUp = async () => {
    if (!isMarking && !isMarked) {
      setIsMakring(true);

      const res = await apiFileMark({ highlight: highlighId });
      if (res.success) {
        setIsMarked(true);
        dispatch(appAction.refreshHighLightData());

        showToast('success', 'Successfully marked!');
      }
      setIsMakring(false);
    }
  };

  const onScrollToTop = () => {
    const viewer = document.getElementById('highlight_conversation');
    viewer?.scrollIntoView();
  };

  const onEnter = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      onSendMessage();
    }
  };

  const onSendMessage = () => {
    if (!!message) {
      socket.emit('send-message-file-ai', {
        room: highlighId,
        type: 'text',
        message
      });

      onAddMessageOnUI({
        is_ai: 0,
        message: message,
        type: 'text'
      });
      setMessage('');
    }
  };

  const onAddMessageOnUI = (data) => {
    setArrMessage((prev) => [{ ...data, is_new: true }, ...prev]);
    setIsWaitingResponse((prev) => !prev);
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between">
        <div className="font-bold font20">{`Highlight`}</div>
        <div className="row_align_items">
          <div
            className="action_btn clickable"
            style={{ width: 140 }}
            onClick={onMarkUp}
          >
            {isMarking ? (
              <LoadingCircular size={18} color="main" />
            ) : (
              <div className="row_align_items">
                <div className="font-medium font12">Mark this part</div>
                <div style={{ marginLeft: 8, display: 'flex' }}>
                  {!isMarked ? (
                    <BookmarkOutlineIcon color="#FF3E9A" />
                  ) : (
                    <BookMarkedIcon />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="clickable" onClick={onClose}>
            <CloseIcon opacity={0.3} />
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="highlight_conversation_body">
        {isLoadingMessage ? (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              marginTop: 12
            }}
          >
            <LoadingCircular size={24} color="main" />
          </div>
        ) : (
          arrMessage.map((item, index) => {
            return <CardHighlightChat key={`card_chat_${index}`} data={item} />;
          })
        )}
      </div>
    );
  };

  const renderInput = () => {
    return (
      <div>
        {isWaitingResponse && (
          <div style={{ marginLeft: 24, marginBottom: 16, marginTop: 16 }}>
            <TypeLoadingAnimation
              background={'#FF3E9A'}
              size={'12px'}
              margin={'4px'}
              duration={'0.5s'}
            />
          </div>
        )}
        <div
          className="highlight_conversation_input"
          id="highlight_conversation_input"
        >
          <input
            value={message}
            placeholder="Ask more questions here..."
            className="chat_box_input font-regular font16"
            onChange={(e) => {
              if (e.nativeEvent['inputType'] === 'insertLineBreak') {
                e.preventDefault();
                return;
              } else {
                setMessage(e.target.value);
              }
            }}
            onKeyUp={(e) => {
              e.preventDefault();
              onEnter(e);
            }}
          />
          <div className="clickable" onClick={onSendMessage}>
            <ChatSendIcon />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="highlight_conversation" id="highlight_conversation">
      {renderHeader()}
      {renderBody()}
      {renderInput()}
    </div>
  );
};
