import React, { useEffect, useMemo, useState } from 'react';

import './index.styles.scss';
import ShareBG from 'assets/pngs/bg_share_point.png';
import CopyIcon from 'assets/svgs/CopyIcon';
import PointIcon from 'assets/svgs/PointIcon';
import { apiGetInvitationStats, apiGetUserInvitation } from 'shared/api/utils';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface Props {
  invitedCount: number;
  onUpdateInvitedCount: (count) => void;
}

export const SharePointLeft = ({
  invitedCount,
  onUpdateInvitedCount
}: Props) => {
  const refferalUrl = process.env.REACT_APP_REFERRAL_URL;

  const [code, setCode] = useState('');
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [points, setPoints] = useState(0);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const res = await apiGetInvitationStats();
    if (res.success) {
      setCode(res.code);
      setAcceptedCount(res.accepted);
      setPoints(res.points);
      onUpdateInvitedCount(res.invitations);
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(refferalUrl + code ?? '');
    showToast('success', 'Successfully copied!');
  };

  const renderTop = () => {
    return (
      <div>
        <img src={ShareBG} width={200} height={166} />
        <div
          className="font-bold font32"
          style={{ marginTop: 32 }}
        >{`Invite your friends`}</div>
        <div
          className="font-regular font14"
          style={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: 16 }}
        >
          Help them skip the waitlist and get onboard immediately
        </div>
      </div>
    );
  };

  const renderLink = () => {
    return (
      <div className="link_view">
        <div
          className="font-bold font12"
          style={{ color: `rgba(0, 0, 0, 0.4)` }}
        >
          Referral Link
        </div>
        <div className="row_space_between" style={{ marginTop: 12 }}>
          <div className="link_txt font-bold font16">
            {refferalUrl?.replace('https://', '') + code}
          </div>
          <div className="clickable" onClick={onCopy}>
            <CopyIcon />
          </div>
        </div>
      </div>
    );
  };

  const renderInvite = () => {
    return (
      <div className="invite_view row_space_between">
        <div className="item_view">
          <div className="item_title font-bold font12">Invited</div>
          <div className="row_align_items">
            <div className="font-bold font24">💌 {invitedCount}</div>
          </div>
        </div>
        <div className="item_view">
          <div className="item_title font-bold font12">Accepted</div>
          <div className="row_align_items">
            <div className="font-bold font24">🙋‍♀️ {acceptedCount}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderEarnPoint = () => {
    return (
      <div className="earn_point_view row_space_between row_align_items">
        <div
          className="font-bold font14"
          style={{ color: 'rgba(0, 0, 0, 0.4)' }}
        >
          Points Earned
        </div>
        <div className="row_align_items">
          <PointIcon size={20} />
          <div className="row_align_items" style={{ marginLeft: 8 }}>
            <div className="font-bold font24">{points}</div>
            <div className="font-regular font24">pts</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="share_point_left">
      {renderTop()}
      {renderLink()}
      {renderInvite()}
      {renderEarnPoint()}
    </div>
  );
};
