import CloseIcon from 'assets/svgs/CloseIcon';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import { FullImageModal } from 'components/Modals/FullImageModal';
import React, { useEffect, useState } from 'react';
import Columns from 'react-columns';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetContentMemes } from 'shared/api/content';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { CardAllSharedDocs } from './CardAllSharedMeme';

import './index.styles.scss';

interface AllSharedMemeProps {
  selectedOrg?: any;
  onBack?: () => void;
}

var queries = [
  {
    columns: 1,
    query: 'min-width: 500px'
  },
  {
    columns: 1,
    query: 'min-width: 1200px'
  },
  {
    columns: 2,
    query: 'min-width: 1400px'
  },
  {
    columns: 3,
    query: 'min-width: 1600px'
  },
  {
    columns: 4,
    query: 'min-width: 1800px'
  },
  {
    columns: 5,
    query: 'min-width: 2000px'
  }
];

export const AllSharedMeme = ({ selectedOrg, onBack }: AllSharedMemeProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [searchText, setSearchText] = useState('');

  const [arrMeme, setArrMeme] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showFullImage, setShowFullImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id, searchText]);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiGetContentMemes({
        query: '',
        to: id,
        to_type: 'organisation',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrMeme(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }
    } else {
      if (!isLoading && hasMore) {
        const res = await apiGetContentMemes({
          query: '',
          to: id,
          to_type: 'organisation',
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrMeme((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const onRemoveOnUI = (id) => {
    setArrMeme((prev) => prev.filter((item, index) => item.id !== id));
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div className="clickable" onClick={onClickBack}>
            <RoundBackIcon />
          </div>
          <div className="font-bold font26" style={{ marginLeft: 8 }}>
            All Shared Memes within Schools
          </div>
        </div>
        <SearchBox
          onSearch={setSearchText}
          width={'300px'}
          height={'48px'}
          fontSize={14}
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="shared_all_meme_body">
        <Columns
          queries={queries}
          gap={'16px'}
          rootStyles={{ paddingBottom: 10, width: '100%' }}
        >
          {arrMeme.map((item, index) => {
            return (
              <CardAllSharedDocs
                key={`card_all_shared_docs_${index}`}
                data={item}
                selectedOrg={selectedOrg}
                onClickedImage={(imgUrl) => {
                  setShowFullImage(true);
                  setSelectedImage(imgUrl);
                }}
                onRemoveOnUI={onRemoveOnUI}
              />
            );
          })}
        </Columns>
      </div>
    );
  };

  const renderFullImageView = () => {
    return (
      <FullImageModal
        show={showFullImage}
        onClose={() => setShowFullImage(false)}
        imageUrl={selectedImage}
      />
    );
  };

  return (
    <div className="shared_all_meme">
      {renderHeader()}
      {renderBody()}
      {renderFullImageView()}
    </div>
  );
};
