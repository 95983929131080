import React from 'react';

import './index.styles.scss';
import IMG_BG from 'assets/pngs/service_out_bg.png';

export const OutOfSevice = () => {
  const renderHeader = () => {
    return (
      <div className="service_header row_align_items">
        <div className="align-vertical-center">
          <img
            src="/logo512.png"
            width={35}
            height={35}
            style={{ objectFit: 'contain' }}
          />
        </div>
        <div className="title align-vertical-center app-font-bold font40">
          {process.env.REACT_APP_NAME}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="service_body">
        <div className="row_align_items">
          <div>
            <div className="font-bold" style={{ fontSize: 50 }}>
              Hi 👋 sorry, we are <br />
              out of service
            </div>
            <div className="font-regular font26" style={{ marginTop: 22 }}>
              Due to high demand, we are out of capacity
              <br /> and service will be restored in{' '}
              <span className="font-bold">1 hour max</span>
            </div>
          </div>
          <img src={IMG_BG} style={{ width: 500, marginLeft: 100 }} />
        </div>
      </div>
    );
  };

  return (
    <div className="out_of_service">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
