import React from "react";

function BookmarkOutlineIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="20"
      fill="none"
      viewBox="0 0 14 20"
    >
      <path
        fill={props.color}
        d="M13 0H1a1 1 0 00-1 1v18a1 1 0 001.65.76L7 15.27l5.29 4.44A1 1 0 0013 20a.84.84 0 00.38-.08A1 1 0 0014 19V1a1 1 0 00-1-1zm-1 16.86L7.64 13.2a1 1 0 00-1.28 0L2 16.86V2h10v14.86z"
      ></path>
    </svg>
  );
}

export default BookmarkOutlineIcon;
