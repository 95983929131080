import React from 'react';

function BookMarkIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 24}
      height={props.size ?? 24}
      fill="none"
      viewBox="0 0 25 24"
    >
      <g opacity={props.opacity ?? 1}>
        <path
          fill={props.color ?? '#000'}
          d="M16.5 2h-8a3 3 0 00-3 3v16a1 1 0 001.5.87l5.5-3.18 5.5 3.18a1 1 0 00.5.13 1 1 0 00.5-.13 1 1 0 00.5-.87V5a3 3 0 00-3-3zm1 17.27l-4.5-2.6a1 1 0 00-1 0l-4.5 2.6V5a1 1 0 011-1h8a1 1 0 011 1v14.27z"
        />
      </g>
    </svg>
  );
}

export default BookMarkIcon;
