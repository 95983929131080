import QuestionCircleIcon from 'assets/svgs/QuestionCircle';
import Question from 'assets/pngs/Question.png';
import React, { useState } from 'react';

import './index.styles.scss';
import ShareIcon from 'assets/svgs/ShareIcon';
import { QuestionAnswerShareModal } from 'components/Modals/QuestionAnswerShareModal';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import MicIcon from 'assets/svgs/MicIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import FileIcon from 'assets/svgs/FileIcon';
import { useNavigate } from 'react-router-dom';
import AudioPlayIcon from 'assets/svgs/AudioPlayIcon';
import { apiContentVoteAI } from 'shared/api/content';
import HeartIcon from 'assets/svgs/HeartIcon';
import HeartBreakIcon from 'assets/svgs/HeartBreakIcon';

export const CardFlipQuestionBody = ({ selectedOrg, data, onFlip }) => {
  const navigate = useNavigate();
  const [showActionModal, setShowActionModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [likeCount, setLikeCount] = useState(data.likes);
  const [dislikeCount, setDislikeCount] = useState(data.dislikes);
  const [userVote, setUserVote] = useState(data.user_like);

  const onVote = async (vote: number) => {
    const res = await apiContentVoteAI({
      item_id: data.bookmark_id,
      item_type: 'generated_question',
      like: vote,
      remove: userVote === vote ? true : false
    });

    if (res.success) {
      const voteData = res.voting;
      setUserVote(voteData.user_like);
      setLikeCount(voteData.likes);
      setDislikeCount(voteData.dislikes);
    }
  };

  const openRefLink = () => {
    if (data.ref_types === 'html') {
      window.open(data.ref_filenames);
    } else if (data.ref_types === 'browser') {
      window.open(data.ref_urls);
    } else if (data.ref_types === 'pdf') {
      navigate(`/document/${data.bookmark_id}`);
    }
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items row_space_between">
        <div className="mark row_align_items">
          <div className="light_view">
            <QuestionCircleIcon />
          </div>
          <div
            className="font-bold font16"
            style={{ color: '#FF3E9A', marginLeft: 6 }}
          >
            Question
          </div>
        </div>
        <div
          className="detail_btn clickable"
          onClick={() => setShowShareModal(true)}
        >
          <ShareIcon size={16} color="rgba(0, 0, 0, 0.3)" />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="flip_question_body">
        <div>
          <div className="question_txt font-bold font24">{data.question}</div>
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="flip_question_bottom">
        <div className="flip_action_conatiner row_align_items">
          <div className="flip_btn font-bold font14 clickable" onClick={onFlip}>
            Flip
          </div>
        </div>
      </div>
    );
  };

  const renderFileIcon = (type) => {
    return (
      <div style={{ width: 28, height: 28 }}>
        {type === 'pdf' ? (
          <PdfFileIcon size={28} />
        ) : type === 'audio' ? (
          <MicIcon size={24} color="#FF3E9A" opacity={1} />
        ) : type === 'url' || type === 'html' ? (
          <LinkIcon size={28} color="#FF3E9A" opacity={1} />
        ) : (
          <FileIcon />
        )}
      </div>
    );
  };

  const renderReferences = () => {
    return (
      <div
        className="ref_container row_align_items clickable"
        onClick={openRefLink}
      >
        {renderFileIcon(data.ref_types)}
        <div
          style={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: 4
          }}
        >
          {data.ref_titles ?? data.ref_filenames ?? data.ref_urls}
        </div>
      </div>
    );
  };

  const renderShareToSocial = () => {
    return (
      <QuestionAnswerShareModal
        show={showShareModal}
        selectedOrg={selectedOrg}
        onClose={() => {
          setShowShareModal(false);
        }}
        question={data}
        type={'question'}
      />
    );
  };

  const renderRightAction = () => {
    return (
      <div className="right_action_view">
        <div className="action_item">
          <AudioPlayIcon color="#FF3E9A" />
        </div>
        <div className="vote_divider"></div>
        <div
          className="action_item"
          onClick={() => onVote(1)}
          style={{ marginTop: 16 }}
        >
          <HeartIcon
            color={userVote === 1 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)'}
          />
          <div
            className="font-medium font16"
            style={{ color: userVote === 1 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)' }}
          >
            {likeCount}
          </div>
        </div>
        <div
          className="action_item"
          onClick={() => onVote(0)}
          style={{ marginTop: 16 }}
        >
          <HeartBreakIcon
            color={userVote === 0 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)'}
          />
          <div
            className="font-medium font16"
            style={{ color: userVote === 0 ? '#FF3E9A' : 'rgba(0, 0, 0, 0.4)' }}
          >
            {dislikeCount}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="all_main_card_flip_question">
      <img src={Question} className="bg_image" />
      {renderHeader()}
      {renderReferences()}
      {renderBody()}
      {renderBottom()}
      {/* {renderRightAction()} */}
      {renderShareToSocial()}
    </div>
  );
};
