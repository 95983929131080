import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import LinkIcon from 'assets/svgs/LinkIcon';
import RoundOutIcon from 'assets/svgs/RoundOutIcon';
import React from 'react';

import './index.styles.scss';

interface CardAllSharedLinkProp {
  data: any;
}

export const CardAllSharedLink = ({ data }: CardAllSharedLinkProp) => {
  const onDetail = () => {
    window.open(`https://${data.domain}`);
  };

  return (
    <div className="card_all_shared_link clickable" onClick={onDetail}>
      <div className="card_all_top_view">
        <img src={data.image} className="top_image" />
        <div className="mark_view clickable">
          <RoundOutIcon />
        </div>
      </div>
      <div className="link_title font-bold font14" style={{ marginTop: 8 }}>
        {data.site_title && data.site_title !== ''
          ? data.site_title
          : data.site_name}
      </div>
      <div className="row_align_items" style={{ marginTop: 8 }}>
        <LinkIcon color="black" opacity={0.2} />
        <div
          className="link_txt font-regular font10"
          style={{ color: 'rgba(0, 0, 0, 0.5)' }}
        >
          {data.domain}
        </div>
      </div>
      <div className="row_align_items">
        {data.creator_image && data.creator_image.startsWith('https') ? (
          <img
            src={data.creator_image}
            style={{ width: 16, height: 16, borderRadius: 16 }}
          />
        ) : (
          <DefaultAvatarIcon size={16} />
        )}
        <div className="font-medium font10" style={{ marginLeft: 4 }}>
          {data.creator_name ? data.creator_name : 'Anonymous'}
        </div>
      </div>
    </div>
  );
};
