import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import CommentCheckIcon from 'assets/svgs/CommentCheckIcon';
import CommentQuestionIcon from 'assets/svgs/CommentQuestionIcon';

import './index.styles.scss';
import PlusIcon from 'assets/svgs/PlusIcon';
import { CardAccepted } from './CardAccepted';
import { InviteFriendModal } from 'components/Modals/InviteFriendModal';
import { apiGetUserInvitation } from 'shared/api/utils';
import { CardPending } from './CardPending';
import CommentRejectIcon from 'assets/svgs/CommentRejectIcon';
import { RootState } from 'redux/interfaces';
import { useSelector } from 'react-redux';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface Props {
  onAddNewInvited: (count) => void;
}

export const SharePointRight = ({ onAddNewInvited }: Props) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [tab, setTab] = useState<'accepted' | 'pending' | 'rejected'>(
    'accepted'
  );

  const [showShareModal, setShowShareModal] = useState(false);

  const [arrData, setArrData] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);

    const res = await apiGetUserInvitation({
      limit: 100,
      offset: 0
    });

    if (res.success) {
      setArrData(res.data);
    }

    setIsLoading(false);
  };

  const onAddManually = (newData: Array<any>) => {
    onAddNewInvited(newData.length);
    const newInvited = newData.map((item, index) => {
      return {
        id: -1,
        email: item,
        points: 0,
        timestamp: moment().toString(),
        creator_image: userInfo?.image,
        creator_name: userInfo?.name,
        status: 'pending'
      };
    });

    setArrData((prev) => [...prev, ...newInvited]);
    setShowShareModal(false);

    // show snackbar
    showToast('success', 'Successfully invited');
  };

  const arrAccepted = useMemo(() => {
    return arrData.filter((item, index) => item.status === 'accepted');
  }, [arrData]);

  const arrPending = useMemo(() => {
    return arrData.filter((item, index) => item.status === 'pending');
  }, [arrData]);

  const arrRejected = useMemo(() => {
    return arrData.filter((item, index) => item.status === 'rejected');
  }, [arrData]);

  const renderTop = () => {
    return (
      <div className="row_space_between row_align_items">
        <div className="row_align_items">
          <div
            className={`row_align_items clickable ${
              tab === 'accepted' ? 'selected_tab' : 'default_tab'
            }`}
            onClick={() => setTab('accepted')}
          >
            <CommentCheckIcon
              color={tab === 'accepted' ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
            />
            <div
              className="font-bold font16"
              style={{
                color: tab === 'accepted' ? '#000' : 'rgba(0, 0, 0, 0.6)',
                marginLeft: 12
              }}
            >
              Accepted
            </div>
          </div>
          <div
            className={`row_align_items clickable ${
              tab === 'pending' ? 'selected_tab' : 'default_tab'
            }`}
            onClick={() => setTab('pending')}
            style={{ marginLeft: 24 }}
          >
            <CommentQuestionIcon
              color={tab === 'pending' ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
            />
            <div
              className="font-bold font16"
              style={{
                color: tab === 'pending' ? '#000' : 'rgba(0, 0, 0, 0.6)',
                marginLeft: 12
              }}
            >
              Pending
            </div>
          </div>
          <div
            className={`row_align_items clickable ${
              tab === 'rejected' ? 'selected_tab' : 'default_tab'
            }`}
            onClick={() => setTab('rejected')}
            style={{ marginLeft: 24 }}
          >
            <CommentRejectIcon
              color={tab === 'rejected' ? '#FF3E9A' : 'rgba(0, 0, 0, 0.3)'}
            />
            <div
              className="font-bold font16"
              style={{
                color: tab === 'rejected' ? '#000' : 'rgba(0, 0, 0, 0.6)',
                marginLeft: 12
              }}
            >
              Rejected
            </div>
          </div>
        </div>
        <div
          className="send_more_btn row_align_items clickable"
          onClick={() => setShowShareModal(true)}
        >
          <PlusIcon color="white" />
          <div className="send_more_title font-bold font14">Send More</div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div style={{ marginTop: 32 }}>
        {tab === 'accepted' &&
          arrAccepted.map((item, index) => {
            return <CardAccepted key={`card_aceepted_${index}`} data={item} />;
          })}
        {tab === 'pending' &&
          arrPending.map((item, index) => {
            return <CardPending key={`card_pending_${index}`} data={item} />;
          })}
        {tab === 'rejected' &&
          arrRejected.map((item, index) => {
            return <CardPending key={`card_pending_${index}`} data={item} />;
          })}
      </div>
    );
  };

  const renderShareModal = () => {
    return (
      <InviteFriendModal
        show={showShareModal}
        onClose={() => {
          setShowShareModal(false);
        }}
        onSuccess={onAddManually}
      />
    );
  };

  return (
    <div className="share_point_right">
      {renderTop()}
      {renderBody()}
      {renderShareModal()}
    </div>
  );
};
