import React, { useEffect, useState } from 'react';
import Columns from 'react-columns';

import './index.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import RoundBackIcon from 'assets/svgs/RoundBackIcon';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { CardAlllFlash } from './CardAllFlash';
import { apiGetContentFlashCards } from 'shared/api/content';

interface SharedFlashProps {
  selectedOrg: any;
  onBack?: () => void;
}

export const AllSharedFlash = ({ selectedOrg, onBack }: SharedFlashProps) => {
  var queries = [
    {
      columns: 1,
      query: 'max-width: 1000px'
    },
    {
      columns: 1,
      query: 'min-width: 1000px'
    },
    {
      columns: 2,
      query: 'min-width: 1300px'
    },
    {
      columns: 3,
      query: 'min-width: 1600px'
    },
    {
      columns: 4,
      query: 'min-width: 1900px'
    },
    {
      columns: 5,
      query: 'min-width: 2000px'
    }
  ];

  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [searchText, setSearchText] = useState('');

  const [arrFiles, setArrFiles] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id, searchText]);

  const loadData = async (init = true) => {
    if (init) {
      const res = await apiGetContentFlashCards({
        query: searchText,
        to: id,
        to_type: 'organisation',
        limit: 10,
        offset: 0
      });

      if (res.success) {
        setArrFiles(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
        setIsLoaded(true);
      }
    } else {
      if (!isLoadingFile && hasMore) {
        const res = await apiGetContentFlashCards({
          query: searchText,
          to: id,
          to_type: 'organisation',
          limit: 10,
          offset: lastId
        });

        if (res.success) {
          setArrFiles((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const renderHeader = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          <div className="clickable" onClick={onClickBack}>
            <RoundBackIcon />
          </div>
          <div className="font-bold font26" style={{ marginLeft: 8 }}>
            All Shared Flashs within Schools
          </div>
        </div>
        <SearchBox
          onSearch={setSearchText}
          width={'300px'}
          height={'48px'}
          fontSize={14}
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="shared_all_docs_body">
        <Columns
          queries={queries}
          gap={'16px'}
          rootStyles={{ paddingBottom: 10, width: '100%' }}
        >
          {arrFiles.map((item, index) => {
            return (
              <CardAlllFlash
                key={`card_all_shared_flash_${index}`}
                data={item}
                selectedOrg={selectedOrg}
              />
            );
          })}
        </Columns>
      </div>
    );
  };

  return (
    <div className="all_shared_flash_container">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
