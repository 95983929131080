import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-responsive-masonry';
import { CardProfileOrg } from './CardProfileOrg/CardProfileOrg';
import UserFollowerIcon from 'assets/svgs/UserFollowerIcon';
import UserFollowingIcon from 'assets/svgs/UserFollowingIcon';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';
import {
  apiGetUserOrganisations,
  apiGetUserActivityAI,
  apiGetUserInfo,
  apiUserFollow,
  apiUserUnFollow,
  apiGetUserGroups,
  apiGetUserQuestions
} from 'shared/api/user';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import './Profile.styles.scss';
import PointIcon from 'assets/svgs/PointIcon';
import AngleUpIcon from 'assets/svgs/AngleUpIcon';
import EditIcon from 'assets/svgs/EditIcon';
import AngleDownIcon from 'assets/svgs/AngleDownIcon';
import { apiGetGroups } from 'shared/api/group';
import { CardProfileGroup } from './CardProfileGroup/CardProfileGroup';
import { useNavigate, useParams } from 'react-router-dom';

import { SocialMedia } from './SocialMedia';

import OrgPng from 'assets/pngs/OrgPng.png';
import JoinedGroupPng from 'assets/pngs/JoinedGroupPng.png';
import RecentActivitiesPng from 'assets/pngs/RecentActivitiesPng.png';
import SocialMediasPng from 'assets/pngs/SocialMediasPng.png';
import { QuestionAnswerShareModal } from 'components/Modals/QuestionAnswerShareModal';
import {
  CardTrendingQuestion,
  QuestionData
} from '../Organisation/OrgHome/components/TrendingQuestion/CardTrendingQuestion';

export const OtherProfileScreen = ({ userId }) => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState<any>();

  const [arrOrg, setArrOrg] = useState<Array<any>>([]);
  const [lastIdOrg, setLastIdOrg] = useState(0);
  const [hasMoreOrg, setHasMoreOrg] = useState(true);
  const [isLoadingOrg, setIsLoadingOrg] = useState(false);

  const [arrGroup, setArrGroup] = useState<Array<any>>([]);
  const [lastIdGroup, setLastIdGroup] = useState(0);
  const [hasMoreGroup, setHasMoreGroup] = useState(true);
  const [isLoadingGroup, setIsLoadingGroup] = useState(false);

  const [arrUserActivityAI, setArrUserActivityAI] = useState<Array<any>>([]);
  const [lastIdUserActivityAI, setLastIdUserActivityAI] = useState(0);
  const [hasMoreUserActivityAI, setHasMoreUserActivityAI] = useState(true);
  const [isLoadingUserActivityAI, setIsLoadingUserActivityAI] = useState(false);

  const [isViewOrg, setIsViewOrg] = useState(true);
  const [isViewGroup, setIsViewGroup] = useState(true);

  const [followStatus, setFollowStatus] = useState<
    'false' | 'true' | 'requested'
  >('false');
  const [isFollowing, setIsFollowing] = useState(false);

  const [isLoadedGroup, setIsLoadedGroup] = useState(false);
  const [isLoadedOrg, setIsLoadedOrg] = useState(false);
  const [selectedQuestionData, setSelectedQuestionData] = useState<
    QuestionData | undefined
  >();
  const [isShareModalShow, setIsShareModalShow] = useState(false);
  const [shareModalType, setShareModalType] = useState<'question' | 'answer'>(
    'question'
  );

  const [followerCount, setFollowerCount] = useState(0);

  useEffect(() => {
    if (userId) {
      loadUserInfo();
      loadOrgData();
      loadGroupData();
      loadUserActivityAIData();
    }
  }, [userId]);

  const loadUserInfo = async () => {
    const res = await apiGetUserInfo(userId);

    if (res.success) {
      setUserInfo(res.data);
      setFollowStatus(res.data.isFollowing);
      setFollowerCount(res.data.followers);
    }
  };

  const loadOrgData = async (init = true) => {
    if (init) {
      setIsLoadingOrg(true);
      const res = await apiGetUserOrganisations(userId);
      if (res.success) {
        setIsLoadedOrg(true);
        setArrOrg(res.data);
        setLastIdOrg(res.lastId);
        setHasMoreOrg(res.hasMore);
      }
      setIsLoadingOrg(false);
    } else {
      if (hasMoreOrg && !isLoadingOrg) {
        setIsLoadingOrg(true);
        const res = await apiGetUserOrganisations(userId, 10, lastIdOrg);
        if (res.success) {
          setArrOrg((prev) => [...prev, ...res.data]);
          setLastIdOrg(res.lastId);
          setHasMoreOrg(res.hasMore);
        }
        setIsLoadingOrg(false);
      }
    }
  };

  const loadGroupData = async (init = true, keyword = '') => {
    if (init) {
      setIsLoadingGroup(true);
      const res = await apiGetUserGroups(userId, 10, 0);

      if (res.success) {
        setIsLoadedGroup(true);
        setArrGroup(res.data);
        setLastIdGroup(res.lastId);
        setHasMoreGroup(res.hasMore);
      }
      setIsLoadingGroup(false);
    } else {
      if (hasMoreGroup && !isLoadingGroup) {
        setIsLoadingGroup(true);
        const res = await apiGetUserGroups(userId, 10, lastIdGroup);

        if (res.success) {
          setArrGroup((prev) => [...prev, ...res.data]);
          setLastIdGroup(res.lastId);
          setHasMoreGroup(res.hasMore);
        }
        setIsLoadingGroup(false);
      }
    }
  };

  const loadUserActivityAIData = async (init = true) => {
    if (init) {
      setIsLoadingUserActivityAI(true);
      const res = await apiGetUserQuestions({
        id: userId,
        toUser: userId,
        offset: 0,
        limit: 10
      });
      if (res.success) {
        setArrUserActivityAI(res.data);
        setLastIdUserActivityAI(res.lastId);
        setHasMoreUserActivityAI(res.hasMore);
      }
      setIsLoadingUserActivityAI(false);
    } else {
      if (hasMoreUserActivityAI && !isLoadingUserActivityAI) {
        setIsLoadingUserActivityAI(true);
        const res = await apiGetUserQuestions({
          id: userId,
          offset: lastIdUserActivityAI,
          limit: 10
        });
        if (res.success) {
          setArrUserActivityAI((prev) => [...prev, ...res.data]);
          setLastIdUserActivityAI(res.lastId);
          setHasMoreUserActivityAI(res.hasMore);
        }
        setIsLoadingUserActivityAI(false);
      }
    }
  };

  const onFollowAction = () => {
    if (followStatus === 'false') {
      onFollow();
    } else {
      onUnFollow();
    }
  };

  const onFollow = async () => {
    if (!isFollowing) {
      setIsFollowing(true);

      const res = await apiUserFollow({ followingId: userInfo.id });
      if (res.success) {
        setFollowerCount((prev) => prev + 1);
        setFollowStatus(res.isFollowed);
      }

      setIsFollowing(false);
    }
  };

  const onUnFollow = async () => {
    if (!isFollowing) {
      setIsFollowing(true);

      const res = await apiUserUnFollow({ following: userInfo.id });
      if (res.success) {
        setFollowerCount((prev) => prev - 1);
        setFollowStatus('false');
      }

      setIsFollowing(false);
    }
  };

  const onShareHandler = (data, modalType) => {
    setSelectedQuestionData(data);
    setIsShareModalShow(true);
    setShareModalType(modalType);
  };

  const renderFollowBtn = () => {
    if (followStatus === 'false') {
      return (
        <div
          className="follow_btn font-bold font16 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? <LoadingCircular size={20} color="white" /> : `Follow`}
        </div>
      );
    } else if (followStatus === 'true') {
      return (
        <div
          className="unfollow_btn font-bold font16 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={20} color="main" />
          ) : (
            `Unfollow`
          )}
        </div>
      );
    } else {
      return (
        <div
          className="requested_btn font-bold font16 clickable"
          onClick={onFollowAction}
        >
          {isFollowing ? (
            <LoadingCircular size={20} color="main" />
          ) : (
            `Requested`
          )}
        </div>
      );
    }
  };

  const renderUserInfo = () => {
    return (
      <>
        <div className="user_info row_space_between">
          <div className="row_align_items" style={{ gap: 24 }}>
            <div style={{ position: 'relative' }}>
              {userInfo !== undefined && userInfo.image !== '' ? (
                <img
                  src={userInfo.image}
                  style={{
                    width: 136,
                    height: 136,
                    borderRadius: 136,
                    objectFit: 'cover'
                  }}
                />
              ) : (
                <DefaultAvatarIcon size={136} />
              )}
            </div>
            <div className="user_info_detail">
              <div className="font-bold font32">{userInfo?.name}</div>
              <div className="user_info_follow">
                <div className="user_info_follower">
                  <UserFollowerIcon color="rgba(0, 0, 0, 0.4)" size={16} />
                  <div className="font-medium font14">
                    {followerCount} followers
                  </div>
                </div>
                <div className="user_info_following">
                  <UserFollowingIcon color="rgba(0, 0, 0, 0.4)" size={16} />
                  <div className="font-medium font14">
                    {userInfo?.followings} following
                  </div>
                </div>
              </div>
              <div className="description font-regular font18">
                {userInfo?.description}
              </div>
            </div>
          </div>

          {renderFollowBtn()}
        </div>
        <div className="user_evaluation">
          <div className="user_evaluation_box">
            <div className="row_align_items">
              <UserFollowerIcon color="#FF3E9A" size={16} />
              <div className="font-regular font14 user_evaluation_box_label">
                Followers
              </div>
            </div>
            <div className="font-regular font14 user_evaluation_box_number">
              {userInfo?.followers}
            </div>
          </div>
          <div className="user_evaluation_box">
            <div className="row_align_items">
              <UserFollowingIcon color="#FF3E9A" size={16} opacity={1} />
              <div className="font-regular font14 user_evaluation_box_label">
                Following
              </div>
            </div>
            <div className="font-regular font14 user_evaluation_box_number">
              {userInfo?.followings}
            </div>
          </div>
          <div className="user_evaluation_box clickable">
            <div className="row_align_items">
              <PointIcon size={16} />
              <div className="font-regular font14 user_evaluation_box_label">
                Points
              </div>
            </div>
            <div className="font-regular font14 user_evaluation_box_number">
              {userInfo?.energy.toFixed(1)}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderJoinedOrg = () => {
    return (
      <div className="render_block" id={'profile_joined_org'}>
        <div className="block_top row_space_between">
          <div className="row_align_items">
            <img src={OrgPng} />
            <div className="font-bold font32" style={{ marginLeft: 16 }}>
              Joined Schools
            </div>
          </div>
          <div className="row_align_items">
            <div
              className="fold_btn clickable"
              onClick={() => setIsViewOrg(!isViewOrg)}
            >
              {isViewOrg ? (
                <AngleUpIcon opacity={0.3} size={20} />
              ) : (
                <AngleDownIcon opacity={0.3} size={20} />
              )}
            </div>
          </div>
        </div>
        {isViewOrg &&
          (arrOrg.length > 0 ? (
            <InfiniteScroll
              dataLength={arrOrg.length} //This is important field to render the next data
              next={() => loadOrgData(false)}
              hasMore={hasMoreOrg}
              loader={
                <div
                  style={{ display: 'flex', justifyContent: 'center' }}
                ></div>
              }
              refreshFunction={() => {}}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
              style={{ overflow: 'hidden', width: '100%' }}
            >
              <Masonry gutter={'20px'}>
                {arrOrg.map((item, index) => {
                  return (
                    <CardProfileOrg
                      key={`card_profile_org_${index}`}
                      data={item}
                    />
                  );
                })}
              </Masonry>
            </InfiniteScroll>
          ) : (
            isLoadedOrg && renderEmptyOrg()
          ))}
      </div>
    );
  };

  const renderJoinedGroup = () => {
    return (
      <div className="render_block">
        <div className="block_top row_space_between">
          <div className="row_align_items">
            <img src={JoinedGroupPng} />
            <div className="font-bold font32" style={{ marginLeft: 16 }}>
              Joined Groups
            </div>
          </div>
          <div className="row_align_items">
            <div
              className="fold_btn clickable"
              onClick={() => setIsViewGroup(!isViewGroup)}
            >
              {isViewGroup ? (
                <AngleUpIcon opacity={0.3} size={20} />
              ) : (
                <AngleDownIcon opacity={0.3} />
              )}
            </div>
          </div>
        </div>
        {isViewGroup &&
          (arrGroup.length > 0 ? (
            <InfiniteScroll
              dataLength={arrGroup.length} //This is important field to render the next data
              next={() => loadGroupData(false)}
              hasMore={hasMoreGroup}
              loader={
                <div
                  style={{ display: 'flex', justifyContent: 'center' }}
                ></div>
              }
              refreshFunction={() => {}}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
              style={{ overflow: 'hidden', width: '100%' }}
            >
              <Masonry gutter={'20px'}>
                {arrGroup.map((item, index) => {
                  return (
                    <CardProfileGroup
                      key={`card_joined_group_${index}`}
                      data={item}
                    />
                  );
                })}
              </Masonry>
            </InfiniteScroll>
          ) : (
            isLoadingGroup && renderEmptyGroup()
          ))}
      </div>
    );
  };

  const renderEmptyOrg = () => {
    return (
      <div className="empty_status">
        {/* <div className="empty_status_icon">
          <OrgIcon color={'#FF3E9A'} />
        </div>
        <div className="empty_status_info">
          <div className="font-bold font14">
            You haven’t joined any schools or orgs yet
          </div>
          <div
            className="font-regular font12"
            style={{ color: 'rgba(0, 0, 0, 0.8)' }}
          >
            Explore existing ones and request to join!
          </div>
        </div>
        <div
          className="font-bold font12 clickable"
          style={{ color: '#FF3E9A' }}
          onClick={() => navigate('/org/join')}
        >
          Explore Now
        </div> */}
      </div>
    );
  };

  const renderEmptyGroup = () => {
    return (
      <div className="empty_status">
        {/* <div className="empty_status_icon">
          <OrgIcon color={'#FF3E9A'} />
        </div>
        <div className="empty_status_info">
          <div className="font-bold font14">You haven’t join any groups</div>
          <div
            className="font-regular font12"
            style={{ color: 'rgba(0, 0, 0, 0.8)' }}
          >
            Explore and join any group that match your interests.
          </div>
        </div>
        <div
          className="font-bold font12 clickable"
          style={{ color: '#FF3E9A' }}
        >
          Explore Now
        </div> */}
      </div>
    );
  };

  const renderSocialMedia = () => {
    if (userInfo) {
      return (
        <div className="render_block">
          <div
            className="block_top row_space_between"
            style={{ marginBottom: 16 }}
          >
            <div className="row_align_items">
              <img src={SocialMediasPng} />
              <div className="font-bold font32" style={{ marginLeft: 16 }}>
                Connected Accounts
              </div>
            </div>
          </div>
          <SocialMedia
            showConnectButton={false}
            userInfo={userInfo}
            onConnect={() => {}}
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderRecentActivity = () => {
    return (
      <div className="render_block">
        <div className="block_top row_align_items">
          <img src={RecentActivitiesPng} />
          <div className="font-bold font32" style={{ marginLeft: 16 }}>
            Recent Activities
          </div>
        </div>
        <div className="user_activity_ai_list">
          {arrUserActivityAI.length > 0 && (
            <InfiniteScroll
              dataLength={arrUserActivityAI.length} //This is important field to render the next data
              next={() => loadUserActivityAIData(false)}
              hasMore={hasMoreUserActivityAI}
              loader={
                <div style={{ display: 'flex', margin: 'auto' }}>
                  <LoadingCircular size={20} color="main" />
                </div>
              }
              refreshFunction={() => {}}
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
            >
              {arrUserActivityAI.map((item, index) => {
                return (
                  <CardTrendingQuestion
                    key={`card_user_activity_ai_${index}`}
                    data={item}
                    type={'creator'}
                    hideBookMark={true}
                    onShareMedia={(data, modalType) => {
                      onShareHandler(data, modalType);
                    }}
                  />
                );
              })}
            </InfiniteScroll>
          )}
          {!isLoadingUserActivityAI &&
            !hasMoreUserActivityAI &&
            arrUserActivityAI.length == 0 && (
              <div className="empty_user_activity_ai">
                <div
                  className="font-bold font56"
                  style={{ textAlign: 'center', marginTop: 24 }}
                >
                  🏃‍♀️
                </div>
                <div
                  className="font-bold font18"
                  style={{ textAlign: 'center', marginTop: 16 }}
                >
                  No activities found
                </div>
                <div
                  className="font-regular font14"
                  style={{
                    textAlign: 'center',
                    marginTop: 8,
                    marginBottom: 24
                  }}
                >
                  This user hasn’t posted any questions, added answers, or
                  upvoted others’ answers yet. Check back later to see their
                  contributions!
                </div>
              </div>
            )}
        </div>
      </div>
    );
  };

  const renderShareQuestionToSocial = () => {
    return (
      <QuestionAnswerShareModal
        show={isShareModalShow}
        onClose={() => {
          setIsShareModalShow(false);
        }}
        question={selectedQuestionData}
        showAnswer={false}
        type={shareModalType}
      />
    );
  };

  return (
    <div className="profile_container">
      <MainHeader currentPage="user" />
      <div className="profile">
        {renderUserInfo()}
        {arrOrg.length > 0 && renderJoinedOrg()}
        {renderJoinedGroup()}
        {renderSocialMedia()}
        {renderRecentActivity()}
        {renderShareQuestionToSocial()}
      </div>
    </div>
  );
};
