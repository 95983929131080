import React, { useEffect, useState, useMemo, useRef } from 'react';
import { apiGetQAAsk } from 'shared/api/qa';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { CardMyQuestion } from '../MyQuestion/CardMyQuestion';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import { AttachedFile } from './AttachedFile';
import SearchIcon from 'assets/svgs/SearchIcon';
import Tooltip from '@mui/material/Tooltip';

import { HomeBookmark } from '../Bookmark';
import { Reference } from 'components/Common/Reference';
import PdfFileIcon from 'assets/svgs/PdfFileIcon';
import LockIcon from 'assets/svgs/LockIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';

import './index.styles.scss';
import { LinkPreview } from './LinkPreview';
import { LoadingBarAnimation } from 'shared/ui-kit/LoadingBarAnimation';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from 'shared/utils/constants';
import { CreateEventModal } from 'components/Modals/CreateEventModal';
import { socket } from 'components/Auth';
import { GroupAttachedFile } from './GroupAttachedFile';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';

interface MyDraftProps {
  filters: Array<any>;
  groupId?: number;
  canDump?: boolean;
  type: 'home' | 'group';
}

export const MyDraft = ({
  filters,
  type,
  canDump = true,
  groupId
}: MyDraftProps) => {
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const draftType = type;

  const [refreshBookmark, setRefreshBookmark] = useState(false);
  const [question, setQuestion] = useState('');
  const [isPostingQuestion, setIsPostingQuestion] = useState(false);
  const [isExpandReference, setIsExpandReference] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [questionInfo, setQuestionInfo] = useState<{
    success: boolean;
    id: number;
    user: number;
    question: string;
    to: number;
    to_type: string;
    ai_answer: string;
    context: string;
    timestamp: string;
    answer_count: number;
    ref_users?: string;
    ref_names?: string;
    ref_images?: string;
    ref_filenames?: string;
    ref_sizes?: string;
    ref_texts?: string;
    ref_urls?: string;
    ref_types?: string;
  }>({
    success: false,
    id: 0,
    user: 0,
    question: '',
    to: 0,
    to_type: '',
    ai_answer: '',
    context: '',
    timestamp: '',
    answer_count: 0
  });

  // question
  const [selectedUserRef, setSelectedUserRef] = useState([]);
  const [selectedFileRef, setSelectedFileRef] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  useEffect(() => {
    if (socket) {
      const newMessageHandler = (data) => {
        console.log('--- new-dump-scraping handler ---', data);
      };

      const newSummaryHandler = (data) => {
        console.log('--- new-dump-summary handler ---', data);
      };

      socket.on('dump-scraping', newMessageHandler);
      socket.on(`dump-summary`, newSummaryHandler);

      return () => {
        socket.removeListener('dump-scraping', newMessageHandler);
        socket.removeListener(`dump-summary`, newSummaryHandler);
      };
    }
  }, [socket]);

  const onEnter = (e) => {
    e.stopPropagation();
    if (e.keyCode == 13) {
      e.preventDefault();
      onPostQuestion();
    }
  };

  const onPostQuestion = async () => {
    setShowAnswer(true);

    if (!isPostingQuestion && question) {
      setIsPostingQuestion(true);
      const userId = localStorage.getItem('userId') || '';

      const arrFileRefId = selectedFileRef.map((item: any, index) => item.id);
      const arrUserRefId = selectedUserRef.map((item: any, index) => item.id);

      const res = await apiGetQAAsk({
        question,
        to: draftType === 'home' ? userId : groupId!.toString(),
        to_type: draftType === 'home' ? 'user' : 'group',
        interests: filters.length > 0 ? filters + '' : undefined,
        tag_ids: undefined,
        dumpString: arrFileRefId.length > 0 ? arrFileRefId + '' : undefined,
        users: arrUserRefId.length > 0 ? arrUserRefId + '' : ''
      });

      if (res.success) {
        setQuestionInfo({
          success: true,
          ...res.data
        });
      }
      setIsPostingQuestion(false);
    }
  };

  const onDoneAddReference = (userRef, fileRef) => {
    setSelectedUserRef(userRef);
    setSelectedFileRef(fileRef);
  };

  const arrQuestionReferences = useMemo(() => {
    const arrTmpRefData: Array<any> = [];

    if (type == 'home') {
      // add extra file references
      selectedFileRef.forEach((fileRef: any) => {
        arrTmpRefData.push({
          id: fileRef.id,
          name: fileRef.filename,
          image: fileRef.url,
          type: 'file',
          fileType: fileRef.dump_type
        });
      });
    } else {
      // add extra file references
      selectedFileRef.forEach((fileRef: any) => {
        arrTmpRefData.push({
          id: fileRef.id,
          name: fileRef.filename,
          image: fileRef.url,
          type: 'file',
          fileType: fileRef.dump_type
        });
      });

      // add extra user references
      selectedUserRef.forEach((userRef: any) => {
        arrTmpRefData.push({
          name: userRef.creator_name,
          image: userRef.creator_image,
          type: 'user'
        });
      });
    }

    // remove duplicated
    const uniqueArr = arrTmpRefData.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.image === value.image || t.name === value.name)
    );
    return uniqueArr;
  }, [selectedUserRef, selectedFileRef]);

  const arrQuestionReferencesDetail = useMemo(() => {
    const arrTmpRefData: Array<any> = [];

    if (type == 'home') {
      // add extra file references
      selectedFileRef.forEach((fileRef: any) => {
        arrTmpRefData.push({
          id: fileRef.id,
          name: fileRef.filename,
          image: fileRef.url,
          type: 'file',
          fileType: fileRef.dump_type
        });
      });
    } else {
      // add extra file references
      selectedFileRef.forEach((fileRef: any) => {
        arrTmpRefData.push({
          id: fileRef.id,
          name: fileRef.filename,
          image: fileRef.url,
          type: 'file',
          fileType: fileRef.dump_type
        });
      });

      // add extra user references
      selectedUserRef.forEach((userRef: any) => {
        arrTmpRefData.push({
          name: userRef.creator_name,
          image: userRef.creator_image,
          type: 'user'
        });
      });
    }

    // remove duplicated
    const uniqueArr = arrTmpRefData.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.image === value.image || t.name === value.name)
    );
    return uniqueArr;
  }, [selectedUserRef, selectedFileRef]);

  const renderReferencesBelow = () => {
    return (
      <>
        {arrQuestionReferencesDetail.length > 0 && (
          <div className="reference_box">
            <div className="font-bold font16">References</div>
            {arrQuestionReferencesDetail.map((item, index) => {
              return (
                <div key={`card_ref_${index}`}>
                  <div className="font-regular font16 reference_text"></div>
                  <div className="reference_user">
                    {item.type == 'file' ? (
                      <PdfFileIcon size={16} />
                    ) : (
                      <DefaultAvatarIcon />
                    )}
                    <div className="reference_name font-medium font12">
                      {item.name}
                    </div>
                    <div className="font-regular font12 on_label">on</div>
                    <LockIcon opacity={0.4} />
                    <div className="font-regular font12">Private dump</div>
                  </div>
                  {arrQuestionReferencesDetail.length > 0 &&
                    index < arrQuestionReferencesDetail.length - 1 && (
                      <div
                        className="row_divide_line"
                        style={{ margin: '24px 0' }}
                      />
                    )}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  const renderAskQuestion = () => {
    if (!isMobile)
      return (
        <div
          className="question_create"
          style={type === 'group' ? { marginLeft: 32 } : {}}
        >
          <div className="row_space_between">
            <div className="font-bold font20" style={{ color: '#FF3E9A' }}>
              Ask a Question!
            </div>
            <div
              className="post_question_btn clickable"
              onClick={onPostQuestion}
            >
              <div className="font-bold font16" style={{ color: 'white' }}>
                {isPostingQuestion ? (
                  <LoadingCircular size={20} color="white" />
                ) : (
                  <Tooltip
                    title={
                      <div className="font-regular font14">
                        Find an answer from content in above knowledge base
                      </div>
                    }
                  >
                    <div className="post_question_btn_find_btn">
                      <SearchIcon color={'white'} />
                      <div style={{ marginLeft: 12 }}>Find Answers</div>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          <div className="question_create_body">
            <textarea
              value={question}
              placeholder="Write your question here..."
              className="question_box_input font-regular font28"
              onChange={(e) => {
                if (e.nativeEvent['inputType'] === 'insertLineBreak') {
                  e.preventDefault();
                  return;
                } else {
                  setQuestion(e.target.value);
                }
              }}
              onKeyUp={(e) => {
                e.preventDefault();
                onEnter(e);
              }}
            />
          </div>
          {questionInfo.id === 0 && (
            <Reference
              data={arrQuestionReferences}
              showAddButton={true}
              itemId={userInfo?.id}
              itemType={type === 'home' ? 'user' : 'group'}
              onDoneAddReference={onDoneAddReference}
              setIsExpandReference={setIsExpandReference}
              isExpandReference={isExpandReference}
            />
          )}
          {showAnswer && renderAnswerBody()}
          {isExpandReference ? renderReferencesBelow() : <></>}
        </div>
      );
    else
      return (
        <div className="question_create_mobile">
          <div className="row_space_between" style={{ alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <div className="font-bold font20">Ask a Question!</div>
              <div className="question_create_body">
                <textarea
                  value={question}
                  placeholder="Write your question here..."
                  className="question_box_input font-regular font28"
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                />
              </div>
            </div>
            {questionInfo.id == 0 && (
              <div
                className="post_question_btn_mobile clickable"
                onClick={onPostQuestion}
              >
                {isPostingQuestion ? (
                  <LoadingCircular size={20} color="white" />
                ) : (
                  <div className="post_question_btn_mobile_find_btn">
                    <SearchIcon color={'white'} size={22} />
                  </div>
                )}
              </div>
            )}
          </div>

          {questionInfo.id === 0 && (
            <Reference
              data={arrQuestionReferences}
              showAddButton={true}
              itemId={userInfo?.id}
              itemType={type === 'home' ? 'user' : 'group'}
              onDoneAddReference={onDoneAddReference}
              setIsExpandReference={setIsExpandReference}
              isExpandReference={isExpandReference}
            />
          )}
          {showAnswer && renderAnswerBody()}
          {isExpandReference ? renderReferencesBelow() : <></>}
        </div>
      );
  };

  const renderAnswerBody = () => {
    return (
      <>
        {isPostingQuestion ? (
          <LoadingBarAnimation />
        ) : (
          questionInfo.success && (
            <div className="answers">
              <CardMyQuestion
                question={question}
                data={questionInfo}
                itemId={type === 'home' ? userInfo!.id : groupId!}
                type={type}
                filters={filters}
                tags={''}
                onRefresh={() => {}}
                onRefreshBookmark={() => {
                  setRefreshBookmark((prev) => !prev);
                }}
              />
            </div>
          )
        )}
      </>
    );
  };

  const renderAttachedFile = () => {
    if (userInfo) {
      if (type === 'home') {
        return (
          <AttachedFile
            itemId={userInfo!.id}
            itemType={'user'}
            canDump={canDump}
            onHasData={() => {}}
          />
        );
      } else {
        return (
          <GroupAttachedFile
            itemId={groupId!}
            itemType={'group'}
            canDump={canDump}
            onHasData={() => {}}
          />
        );
      }
    }
  };

  const renderBookmark = () => {
    if (userInfo) {
      return (
        <div>
          <HomeBookmark
            shouldRefresh={refreshBookmark}
            itemId={type === 'home' ? userInfo!.id : groupId!}
            itemType={type === 'home' ? 'user' : 'group'}
            setMobileTab={() => {}}
          />
        </div>
      );
    }
  };

  const renderLinkPreview = () => {
    if (userInfo) {
      return (
        <LinkPreview
          item_id={type === 'home' ? userInfo?.id : groupId!}
          item_type={type === 'home' ? 'user' : 'group'}
        />
      );
    } else {
      return <div />;
    }
  };

  const renderEncryptView = () => {
    return (
      <div className="encrypt_view">
        <div className="row_align_items">
          <LockIcon size={20} />
          <div className="font-regular font20" style={{ marginLeft: 6 }}>
            All your links and all your content is encrypted and no one except
            you can see it
          </div>
        </div>
      </div>
    );
  };

  const renderNormalPage = () => {
    return (
      <div className="home_my_draft" id="home_my_draft">
        {type === 'home' && renderEncryptView()}
        {renderLinkPreview()}
        {renderAttachedFile()}
        {renderAskQuestion()}
        {renderBookmark()}
      </div>
    );
  };

  return renderNormalPage();
};
