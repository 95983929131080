import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import RobotIcon from 'assets/svgs/RobotIcon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CardRelatedQuestion = ({ data }) => {
  const navigate = useNavigate();

  const onDetail = () => {
    navigate(`/org/detail/${data.bookmark_id}`, { replace: true });
  };

  const onViewProfile = () => {
    navigate(`/profile/${data.creator_id}`);
  };

  const renderTopCreatorInfo = () => {
    if (data.top_is_anonymous === 1) {
      <div className="align-vertical-center">
        <DefaultAvatarIcon size={24} />
        <div className="font-medium font16 user_name" style={{ marginLeft: 8 }}>
          {'Anonymous'}
        </div>
      </div>;
    } else {
      if (data.top_creator_id) {
        return (
          <div
            className="align-vertical-center clickable"
            onClick={onViewProfile}
          >
            {data.top_creator_image ? (
              <img
                className="creator_image"
                src={data.top_creator_image}
                width="24"
                height="24"
                style={{ borderRadius: 24 }}
              ></img>
            ) : (
              <DefaultAvatarIcon size={24} />
            )}

            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {data.top_creator_name ? data.top_creator_name : 'Anonymous'}
            </div>
          </div>
        );
      } else {
        return (
          <div className="align-vertical-center">
            <RobotIcon />
            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8 }}
            >
              {'General AI'}
            </div>
          </div>
        );
      }
    }
  };

  const renderPlaceInfo = () => {
    if (
      !data.ai_type ||
      data.ai_type === 'user' ||
      data.ai_type === 'general' ||
      !data.ai_id
    ) {
      return <div />;
    } else {
      return (
        <div className="align-vertical-center">
          <div className="font-medium font16 in_label">in</div>
          <img
            className="creator_image"
            src={data.ai_image}
            width="24"
            height="24"
            style={{ borderRadius: 24 }}
          ></img>
          <div className="font-medium font16 org_name">{data.ai_name}</div>
          <div className="font-medium font16 posted_question">
            posted a new question
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="question_box clickable" onClick={onDetail}>
        <div className="question_box_top">
          <div className="question_box_top_user">
            {renderTopCreatorInfo()}
            {renderPlaceInfo()}
          </div>
        </div>
        <div
          className="question_text font-bold font36"
          style={{ marginTop: '24px' }}
        >
          {data?.question}
        </div>
      </div>
    </div>
  );
};
