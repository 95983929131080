import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';
import { AllSharedDoc } from './AllSharedDoc';
import { AllSharedLink } from './AllSharedLink';
import { AllSharedMeme } from './AllSharedMeme';
import { OrgLeftMenu } from './components/LeftMenu';
import { Events } from './Events';
import { EventDetail } from './Events/EventDetail';

import './index.styles.scss';
import { OrgDetail } from './OrgDetail';
import { OrgHome } from './OrgHome';
import { OrgMember } from './OrgMember';
import { QuestionDetail } from './QuestionDetail';
import { OrgUnAnsweredQuestion } from './OrgUnAnsweredQuestion';
import { sendAnalytics } from 'shared/utils/analytics';
import { AllSharedFlash } from './AllSharedFlash';

export const OrganisationScreen = () => {
  const [question, setQuestion] = useState('');
  const [selectedOrg, setSelectedOrg] = useState<any>(null);
  const [selectedTopic, setSelectedTopic] = useState<Array<string>>([]);
  const [arrOrganisation, setArrOrganisation] = useState<any>([]);

  useEffect(() => {
    sendAnalytics('Schools');
  }, []);

  const renderHeader = () => {
    return (
      <MainHeader
        currentPage="school"
        selectedOrg={selectedOrg}
        arrOrganisation={arrOrganisation}
      />
    );
  };

  const renderBody = () => {
    return (
      <div className="row org_body">
        <div
          style={{
            width: 500,
            height: `calc(100%)`,
            overflowY: 'auto',
            border: '1px solid rgba(0, 0, 0, 0.1)'
          }}
        >
          <OrgLeftMenu
            selectedOrg={selectedOrg}
            selectedTopic={selectedTopic}
            arrOrganisation={arrOrganisation}
            onSetArrOrg={setArrOrganisation}
            onSelectedOrg={setSelectedOrg}
            onSelectedTopics={setSelectedTopic}
          />
        </div>
        <div
          style={{ flex: 1, height: `calc(100%)`, overflowY: 'auto' }}
          id="org_right_panel"
        >
          <Routes>
            <Route
              index
              element={
                <OrgHome
                  question={question}
                  selectedOrg={selectedOrg}
                  selectedTopics={selectedTopic}
                  arrOrganisation={arrOrganisation}
                  onSetQuestion={setQuestion}
                  onInitSelectedOrg={() => setSelectedOrg(null)}
                />
              }
            ></Route>
            <Route
              path=":id"
              element={
                <OrgDetail
                  question={question}
                  arrOrganisation={arrOrganisation}
                  selectedOrg={selectedOrg}
                  selectedTopics={selectedTopic}
                  onSetSelectedOrg={(orgInfo) => setSelectedOrg(orgInfo)}
                  onSetQuestion={setQuestion}
                />
              }
            ></Route>
            <Route
              path=":id/docs"
              element={<AllSharedDoc selectedOrg={selectedOrg} />}
            ></Route>
            <Route
              path=":id/links"
              element={<AllSharedLink selectedOrg={selectedOrg} />}
            ></Route>
            <Route
              path=":id/memes"
              element={<AllSharedMeme selectedOrg={selectedOrg} />}
            ></Route>
            <Route
              path=":id/flashcards"
              element={<AllSharedFlash selectedOrg={selectedOrg} />}
            ></Route>
            <Route path="question/:code/*" element={<QuestionDetail />}></Route>
            <Route path={'events'} element={<Events />}></Route>
            <Route path="events/:code/*" element={<EventDetail />} />
            <Route path="member/:id" element={<OrgMember />} />
            <Route
              path="noanswer"
              element={<OrgUnAnsweredQuestion selectedOrg={selectedOrg} />}
            ></Route>
          </Routes>
        </div>
      </div>
    );
  };

  return (
    <div className="org_container">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
