import AllOrgIcon from 'assets/svgs/AllOrgIcon';
import BookMarkedIcon from 'assets/svgs/BookMarkedIcon';
import CloseIcon from 'assets/svgs/CloseIcon';
import CommentAltCheckIcon from 'assets/svgs/CommentAltCheckIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import EditIcon from 'assets/svgs/EditIcon';
import AlertCheckIcon from 'assets/svgs/AlertCheckIcon';
import GeneralAiIcon from 'assets/svgs/GeneralAiIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/interfaces';
import moment from 'moment';
import {
  apiQABookMark,
  apiQAEditAnswer,
  apiQAUnBookMark,
  apiGetQAAskGeneral
} from 'shared/api/qa';
import { apiDeletionGeneratedQuestion } from 'shared/api/delete';

import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { getArrFromString, removeFirstBlank } from 'shared/utils/utils';

import './index.styles.scss';
import UCoinIcon from 'assets/svgs/UCoinsIcon';
import { Reference } from 'components/Common/Reference';
import { ReferenceDetail } from 'components/Common/ReferenceDetail';
import RobotIcon from 'assets/svgs/RobotIcon';
import { useNavigate } from 'react-router-dom';
import TrashIcon from 'assets/svgs/TrashIcon';
import { showToast } from 'shared/ui-kit/PrimaryToast';

interface AskQuestionProps {
  selectedOrg: any;
  textQuestion: string;
  qaAskInfo: { [key: string]: any } | null;
  isAsking: boolean;
  setQuestion: any;
  onUpdateQAInfo: any;
  setTab: any;
  questionDetail: any;
  questionPoints: number;
}
export const PostAnswer = ({
  selectedOrg,
  textQuestion,
  qaAskInfo,
  isAsking,
  setQuestion,
  onUpdateQAInfo,
  setTab,
  questionDetail,
  questionPoints
}: AskQuestionProps) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.profile);
  const [isOpenAIToogle, setIsOpenAIToogle] = useState(false);
  const [isSavingAnswer, setIsSavingAnswer] = useState(false);
  const [isEditAnswer, setIsEditAnswer] = useState(false);

  const [isRemoving, setIsRemoving] = useState(false);
  const [isExpandReference, setIsExpandReference] = useState(false);

  const [isBookMarkingAnswer, setIsBookMarkingAnswer] = useState(false);
  const [isUnBookMarkingAnswer, setIsUnBookMarkingAnswer] = useState(false);
  const [answer, setAnswer] = useState(questionDetail?.ai_answer);
  const [snackBarShow, setSnackBarShow] = useState(false);

  const [isAIGeneral, setIsAIGeneral] = useState(false);
  const [isSavingGeneralAIAnswer, setIsSavingGeneralAIAnswer] = useState(false);
  const [isEditGeneralAIAnswer, setIsEditGeneralAIAnswer] = useState(false);
  const [generalAIAnswer, setGeneralAIAnswer] = useState('');
  const [tempGeneralAIAnswer, setTempGeneralAIAnswer] = useState('');
  const [isAskingGeneralAIAnswer, setIsAskingGeneralAIAnswer] = useState(false);
  const [arrExtraUserRef, setArrExtraUserRef] = useState<Array<any>>([]);
  const [arrExtraFileRef, setArrExtraFileRef] = useState<Array<any>>([]);

  const onDoneAddReference = (newUserRef, newFileRef) => {
    setArrExtraUserRef(newUserRef);
    setArrExtraFileRef(newFileRef);
  };

  useEffect(() => {
    if (questionDetail) {
      setAnswer(questionDetail?.ai_answer);
    }
  }, [questionDetail]);

  const onSaveAnswer = async () => {
    if (isAIGeneral) {
      if (!isSavingGeneralAIAnswer) {
        setIsSavingGeneralAIAnswer(true);

        const res = await apiQAEditAnswer({
          question: questionDetail ? questionDetail.id : 0,
          answer: tempGeneralAIAnswer
        });

        if (res.success) {
          setGeneralAIAnswer(tempGeneralAIAnswer);
        }
        setIsSavingGeneralAIAnswer(false);
        setIsEditGeneralAIAnswer(false);
      }
    } else {
      if (!isSavingAnswer) {
        setIsSavingAnswer(true);
        const res = await apiQAEditAnswer({
          question: questionDetail ? questionDetail.id : 0,
          answer: answer
        });
        if (res.success) {
        }
        setIsSavingAnswer(false);
        setIsEditAnswer(false);
      }
    }
  };

  const onAnswerUnBookMark = async () => {
    if (!isUnBookMarkingAnswer) {
      setIsUnBookMarkingAnswer(true);

      const res = await apiQAUnBookMark({
        question: questionDetail ? questionDetail.id : 0,
        item_id: userInfo ? userInfo.id : 0,
        item_type: 'user'
      });
      if (res.success && qaAskInfo) {
        questionDetail.is_bookmarked = 1;
      }

      setIsUnBookMarkingAnswer(false);
    }
  };

  const onAnswerBookMark = async () => {
    if (!isBookMarkingAnswer && !isAsking) {
      setIsBookMarkingAnswer(true);

      const res = await apiQABookMark({
        question: questionDetail ? questionDetail.id : 0,
        item_id: userInfo ? userInfo.id : 0,
        item_type: 'user'
      });
      if (res.success && qaAskInfo) {
        questionDetail.is_bookmarked = 1;
        setSnackBarShow(true);
        setInterval(() => {
          setSnackBarShow(false);
        }, 3000);
      }

      setIsBookMarkingAnswer(false);
    }
  };

  const onSwitchAIType = async () => {
    setIsAIGeneral(!isAIGeneral);
    if (!isAIGeneral) {
      setIsAskingGeneralAIAnswer(true);
      const res = await apiGetQAAskGeneral({
        question: textQuestion,
        to_type: 'organisation',
        to: 30
      });
      if (res.success) {
        setGeneralAIAnswer(res.answer);
        setTempGeneralAIAnswer(res.answer);
      }
      setIsAskingGeneralAIAnswer(false);
    }

    setIsAIGeneral(!isAIGeneral);
  };

  const onViewProfile = () => {
    navigate(`/profile/${questionDetail.creator_id}`);
  };

  const onRemove = async () => {
    if (!isRemoving) {
      setIsRemoving(true);

      const res = await apiDeletionGeneratedQuestion({
        bookmark: questionDetail.bookmark_id
      });

      if (res.success) {
        showToast('success', 'Deletion successful!');

        navigate(-1);
      }

      setIsRemoving(false);
    }
  };

  const arrReferences = useMemo(() => {
    if (questionDetail?.ref_names || questionDetail?.ref_types) {
      const arrTmpRefData: Array<any> = [];

      const arrRefIds = getArrFromString(questionDetail.ref_ids, ';;;');
      // for user
      const arrTmpName = getArrFromString(questionDetail.ref_names, ';;;');
      const arrTmpImage = getArrFromString(questionDetail.ref_images, ';;;');

      // for files
      const arrTmpFileName = getArrFromString(
        questionDetail.ref_titles ?? questionDetail.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(questionDetail.ref_urls, ';;;');
      const arrTmpFileType = getArrFromString(questionDetail.ref_types, ';;;');

      arrTmpName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpName[index],
          image: arrTmpImage[index],
          type: 'user'
        });
      });

      arrTmpFileName.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpFileName[index],
          image: arrTmpFileUrl[index],
          type: 'file',
          fileType: arrTmpFileType[index]
        });
      });

      // add extra file references
      arrExtraFileRef.forEach((fileRef) => {
        arrTmpRefData.push({
          name: fileRef.filename,
          image: fileRef.url,
          type: 'file'
        });
      });

      // add extra user references
      arrExtraUserRef.forEach((userRef) => {
        arrTmpRefData.push({
          name: userRef.creator_name,
          image: userRef.creator_image,
          type: 'user'
        });
      });
      // remove duplicated
      const uniqueArr = arrTmpRefData.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.image === value.image || t.name === value.name
          )
      );
      return uniqueArr;
    } else {
      return [];
    }
  }, [qaAskInfo, arrExtraUserRef, arrExtraFileRef]);

  const referencesForBelow = useMemo(() => {
    if (questionDetail?.ref_names || questionDetail?.ref_types) {
      const arrTmpRefData: Array<any> = [];

      const arrRefIds = getArrFromString(questionDetail.ref_ids, ';;;');
      // for user
      const arrTmpName = getArrFromString(questionDetail.ref_names, ';;;');
      const arrTmpImage = getArrFromString(questionDetail.ref_images, ';;;');

      // for files
      const arrTmpFileName = getArrFromString(
        questionDetail.ref_titles ?? questionDetail.ref_filenames,
        ';;;'
      );
      const arrTmpFileUrl = getArrFromString(questionDetail.ref_urls, ';;;');
      const arrTmpTxt = getArrFromString(questionDetail.ref_texts, ';;;');
      const arrTmpFileType = getArrFromString(questionDetail.ref_types, ';;;');

      arrRefIds.map((item, index) => {
        arrTmpRefData.push({
          name: arrTmpName[index],
          image: arrTmpImage[index],
          fileName: arrTmpFileName[index],
          url: arrTmpFileUrl[index],
          text: arrTmpTxt[index],
          fileType: arrTmpFileType[index]
        });
      });
      // add extra file references
      arrExtraFileRef.forEach((fileRef) => {
        arrTmpRefData.push({
          fileName: fileRef.filename,
          url: fileRef.url,
          type: 'file',
          fileType: fileRef.dump_type
        });
      });

      // add extra user references
      arrExtraUserRef.forEach((userRef) => {
        arrTmpRefData.push({
          name: userRef.creator_name,
          image: userRef.creator_image,
          type: 'user'
        });
      });

      // remove duplicated
      // const uniqueArr = arrTmpRefData.filter(
      //   (value, index, self) =>
      //     index ===
      //     self.findIndex(
      //       (t) => t.image === value.image && t.name === value.name
      //     )
      // );
      return arrTmpRefData;
    } else {
      return [];
    }
  }, [qaAskInfo, arrExtraUserRef, arrExtraFileRef]);

  const renderQuestion = () => {
    return (
      <div
        className="question_text font-bold font32"
        style={{ marginTop: '24px' }}
      >
        {textQuestion}
      </div>
    );
  };

  const renderAddAnswerbar = () => {
    return (
      <div className="add_answer_bar">
        <div className="left_side">
          <div className="question_info">
            <div className="snack">
              <div className="font16 question_info_icon">👀</div>
              <div className="font14 question_info_text">
                {`${questionDetail.views} view${
                  questionDetail.views > 1 ? 's' : ''
                }`}
              </div>
            </div>
            <div className="snack">
              <div className="font16 question_info_icon">💬</div>
              <div className="font14 question_info_text">
                {`${questionDetail.answer_count} answer${
                  questionDetail.answer_count > 1 ? 's' : ''
                }`}
              </div>
            </div>
          </div>
          <div
            style={{
              width: 2,
              height: 16,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              margin: `0px 16px`
            }}
          />
          <div className="question_points" style={{ marginLeft: 0 }}>
            <UCoinIcon />
            &nbsp;
            {`${questionPoints} pt${questionPoints > 1 ? 's' : ''}`}
          </div>
        </div>
        <div className="right_side">
          <div className="question_date font14">
            {moment(questionDetail.timestamp).format('DD MMM yyyy').toString()}
          </div>
          <div
            className="add_answer_btn clickable"
            onClick={() => setTab('addAnswer')}
          >
            Add answer
          </div>
        </div>
      </div>
    );
  };
  const renderTopView = () => {
    return (
      <div className="row_space_between">
        <div className="row_align_items">
          {!isAIGeneral ? (
            <>
              {/* <DefaultAvatarIcon size={48} /> */}
              {questionDetail ? (
                <img
                  src={questionDetail.ai_image}
                  alt=""
                  style={{
                    width: '48px',
                    height: '48px',
                    borderRadius: '48px'
                  }}
                />
              ) : (
                <DefaultAvatarIcon size={48} />
              )}
              <div style={{ marginLeft: 8, position: 'relative' }}>
                <div className="font-bold font12 org_ai_label">School AI</div>
                <div
                  className="row_align_items"
                  onClick={() => setIsOpenAIToogle(true)}
                >
                  <div className="font-bold font20">
                    {questionDetail && questionDetail.ai_name}
                  </div>
                  <div>{/* <AngleDownIcon opacity="0.4" /> */}</div>
                </div>
                {/* {isOpenAIToogle && renderAIToogle()} */}
              </div>
            </>
          ) : (
            <div className="row_align_items">
              <div className="general_ai_icon">
                <GeneralAiIcon />
              </div>
              <div className="font-bold font20 general_ai_label">
                General AI
              </div>
            </div>
          )}
        </div>
        <div className="row_align_items">
          <div
            className="switch_ai_btn clickable"
            onClick={() => onSwitchAIType()}
          >
            {!isAIGeneral ? <AllOrgIcon /> : <GeneralAiIcon />}
            <div className="font-medium font16 ">Switch AI</div>
          </div>
        </div>
      </div>
    );
  };
  const renderSnackbar = () => {
    return (
      <div className="snackbar">
        <AlertCheckIcon />
        <div className="alert-message font-bold font15">
          Successfully post question on org
        </div>
      </div>
    );
  };
  const renderAnswer = () => {
    return (
      <div className="question_detail_box">
        {isEditAnswer ? renderEditAnswer() : renderNormalAnswer()}
      </div>
    );
  };
  const renderNormalAnswer = () => {
    return (
      <div className="row_align_items">
        <div style={{ width: '100%' }}>
          <div className="row_space_between">
            <div className="font-regular font20">
              <pre className="font20">{removeFirstBlank(answer)}</pre>
            </div>
            {questionDetail?.images && (
              <img src={questionDetail?.images} className="question_image" />
            )}
          </div>

          <div
            className="row_space_between"
            style={{ position: 'relative', marginTop: 16 }}
          >
            <div className="row_align_items" style={{ flex: 1 }}>
              <Reference
                data={arrReferences}
                showAddButton={true}
                itemId={30}
                itemType={'organisation'}
                onDoneAddReference={onDoneAddReference}
                setIsExpandReference={setIsExpandReference}
                isExpandReference={isExpandReference}
              />
            </div>
            <div className="row_align_items">
              <div
                className="edit_btn clickable"
                onClick={() => {
                  if (!isAsking) {
                    setIsEditAnswer(true);
                  }
                }}
              >
                <EditIcon color="#FF3E9A" />
              </div>
              {questionDetail && questionDetail.is_bookmarked ? (
                <>
                  <div
                    className="edit_btn clickable"
                    style={{ marginLeft: 12 }}
                    onClick={onAnswerUnBookMark}
                  >
                    {isUnBookMarkingAnswer ? (
                      <LoadingCircular size={24} color="main" />
                    ) : (
                      <BookMarkedIcon size={24} />
                    )}
                  </div>
                </>
              ) : (
                <div
                  className="post_as_answer_btn clickable"
                  onClick={onAnswerBookMark}
                >
                  {isBookMarkingAnswer ? (
                    <LoadingCircular size={20} color="white" />
                  ) : (
                    <>
                      <CommentAltCheckIcon />
                      <div className="font-bold font16">Post as Answer</div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          {isExpandReference && <ReferenceDetail data={referencesForBelow} />}
        </div>
      </div>
    );
  };

  const renderEditAnswer = () => {
    return (
      <>
        <div className="row_space_between">
          <div className="font-bold font18">Edit Answer</div>
          <div
            className="font-bold font14 clickable"
            style={{ color: '#FF3E9A' }}
            onClick={() => {
              setAnswer(questionDetail ? questionDetail.ai_answer : '');
            }}
          >
            Refresh
          </div>
        </div>
        <textarea
          value={answer}
          className="font-regular font20 answer_text_area"
          style={{ height: 'fit-content' }}
          onChange={(e) => setAnswer(e.target.value)}
        />
        <div className="row_space_between">
          <div
            className="cancel_btn font-medium font16 clickable"
            onClick={() => {
              setIsEditAnswer(false);
              setAnswer(questionDetail ? questionDetail.ai_answer : '');
            }}
          >
            Cancel
          </div>
          <div
            className="save_answer_btn font-bold font16 clickable"
            onClick={onSaveAnswer}
          >
            {isSavingAnswer ? (
              <LoadingCircular size={20} color="white" />
            ) : (
              <>Save Answer</>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderGeneralAIAnswer = () => {
    return (
      <div className="question_detail_box">
        {isEditGeneralAIAnswer
          ? renderEditGeneralAIAnswer()
          : renderNormalGeneralAIAnswer()}
      </div>
    );
  };
  const renderNormalGeneralAIAnswer = () => {
    return (
      <>
        <div className="font-regular font20" style={{ marginBottom: 32 }}>
          {isAskingGeneralAIAnswer ? (
            <LoadingCircular size={20} color="main" />
          ) : (
            <>{generalAIAnswer}</>
          )}
        </div>
        <div
          className="row_space_between"
          style={{ position: 'relative', marginTop: 16 }}
        >
          <div className="row_align_items">
            <div
              className="edit_btn clickable"
              onClick={() => {
                if (!isAsking) {
                  setIsEditGeneralAIAnswer(true);
                }
              }}
            >
              <EditIcon color="#FF3E9A" />
            </div>
            {qaAskInfo && qaAskInfo.bookmark ? (
              <>
                <div
                  className="edit_btn clickable"
                  style={{ marginLeft: 12 }}
                  onClick={onAnswerUnBookMark}
                >
                  {isUnBookMarkingAnswer ? (
                    <LoadingCircular size={24} color="main" />
                  ) : (
                    <BookMarkedIcon size={24} />
                  )}
                </div>
              </>
            ) : (
              <div
                className="post_as_answer_btn clickable"
                onClick={onAnswerBookMark}
              >
                {isBookMarkingAnswer ? (
                  <LoadingCircular size={20} color="white" />
                ) : (
                  <>
                    <CommentAltCheckIcon />
                    <div className="font-bold font16">Post as Answer</div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  const renderEditGeneralAIAnswer = () => {
    return (
      <>
        <div className="row_space_between">
          <div className="font-bold font18">Edit Answer</div>
          <div
            className="font-bold font14 clickable"
            style={{ color: '#FF3E9A' }}
            onClick={() => {
              setAnswer(qaAskInfo ? qaAskInfo.ai_answer : '');
            }}
          >
            Refresh
          </div>
        </div>
        <textarea
          value={tempGeneralAIAnswer}
          className="font-regular font20 answer_text_area"
          onChange={(e) => setTempGeneralAIAnswer(e.target.value)}
        />
        <div className="row_space_between">
          <div
            className="cancel_btn font-medium font16 clickable"
            onClick={() => {
              setIsEditGeneralAIAnswer(false);
              setTempGeneralAIAnswer(generalAIAnswer);
            }}
          >
            Cancel
          </div>
          <div
            className="save_answer_btn font-bold font16 clickable"
            onClick={onSaveAnswer}
          >
            {isSavingAnswer ? (
              <LoadingCircular size={20} color="white" />
            ) : (
              <>Save Answer</>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderAIToogle = () => {
    return (
      <div className="dlg_ai_toogle" id="dlg_ai_toogle">
        <div className="row_space_between">
          <div className="font-bold font18">Select School AI</div>
          <div
            className="clickable"
            onClick={() => {
              setIsOpenAIToogle(false);
            }}
          >
            <CloseIcon opacity={0.2} />
          </div>
        </div>
        <div className="dlg_list">
          <div className="dlg_list_item">
            <DefaultAvatarIcon size={40} />
            <div>
              <div className="font-bold font14">Standford</div>
              <div className="font-medium font12 member_count">
                43,474 members
              </div>
            </div>
            <div className="font-bold font12">Selected</div>
          </div>
          <div className="dlg_list_item added_item">
            <DefaultAvatarIcon size={40} />
            <div>
              <div className="font-bold font14">Oxford</div>
              <div className="font-medium font12 member_count">
                43,474 members
              </div>
            </div>
            <div className="select_btn font-bold font12">Select</div>
          </div>
        </div>
      </div>
    );
  };

  const renderCreatorInfo = () => {
    if (questionDetail?.is_anonymous == 1) {
      return (
        <div className="align-vertical-center">
          <DefaultAvatarIcon size={24} />
          <div
            className="font-medium font16 user_name"
            style={{ marginLeft: 8 }}
          >
            {'Anonymous'}
          </div>
        </div>
      );
    } else {
      if (questionDetail?.creator_id) {
        return (
          <div
            className="align-vertical-center clickable"
            onClick={onViewProfile}
          >
            {questionDetail?.creator_image ? (
              <img
                className="creator_image"
                src={questionDetail?.creator_image}
                width="24"
                height="24"
                style={{ borderRadius: 24 }}
              ></img>
            ) : (
              <DefaultAvatarIcon size={24} />
            )}

            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8, whiteSpace: 'nowrap' }}
            >
              {questionDetail?.creator_name
                ? questionDetail?.creator_name
                : 'Anonymous'}
            </div>
          </div>
        );
      } else {
        return (
          <div className="align-vertical-center">
            <RobotIcon />
            <div
              className="font-medium font16 user_name"
              style={{ marginLeft: 8, whiteSpace: 'nowrap' }}
            >
              {'General AI'}
            </div>
          </div>
        );
      }
    }
  };

  const renderPlaceInfo = () => {
    if (
      !questionDetail?.ai_type ||
      questionDetail?.ai_type === 'user' ||
      questionDetail?.ai_type === 'general' ||
      !questionDetail?.ai_id
    ) {
      return <div />;
    } else {
      return (
        <div className="row_space_between">
          <div className="align-vertical-center">
            <div className="font-medium font16 in_label">in</div>
            <img
              className="creator_image"
              src={questionDetail?.ai_image}
              width="24"
              height="24"
              style={{ borderRadius: 24 }}
            ></img>
            <div className="font-medium font16 org_name">
              {questionDetail?.ai_name}
            </div>
            <div className="font-medium font16 posted_question">
              posted a new question
            </div>
          </div>
          {userInfo?.id == questionDetail.user && (
            <div className="book_marked_icon clickable" onClick={onRemove}>
              {isRemoving ? (
                <LoadingCircular size={24} color="main" />
              ) : (
                <TrashIcon color={'#FF3E9A'} />
              )}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="post_answer_question_box">
      <div className="question_box_top">
        <div className="question_box_top_user" style={{ width: '100%' }}>
          {questionDetail && renderCreatorInfo()}
          {questionDetail && renderPlaceInfo()}
        </div>
      </div>
      {renderQuestion()}
      {questionDetail && renderAddAnswerbar()}
      <div className="line"></div>
      {renderTopView()}
      {!isAIGeneral ? renderAnswer() : renderGeneralAIAnswer()}
      {snackBarShow ? renderSnackbar() : <></>}
    </div>
  );
};
