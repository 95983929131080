import React, { useEffect, useState } from 'react';
import { MainHeader } from 'shared/ui-kit/MainHeader/MainHeader';
import { MonthlyTopCreators } from './MonthlyTopCreators';
import { TrendingNewCreators } from './TrendingNewCreators';
import { WeeklyLeaderBoard } from './WeeklyLeaderboard';
import './index.styles.scss';
import AngleLeftIcon from 'assets/svgs/AngleLeftIcon';
import { SearchBox } from 'shared/ui-kit/SearchBox';
import { useNavigate } from 'react-router-dom';
import { apiChatGetPersonalAIs } from 'shared/api/chat';

interface CreatorsProps {
  ai_id: number;
  ai_name: string;
  ai_image: string;
  ai_type: string;
  satisfactions: number;
  votes: number;
  total_rows: number;
}

export const Creators = () => {
  const navigate = useNavigate();
  const [personalAIs, setPersonalAIs] = useState<Array<any>>([]);
  const [lastId, setLastId] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadPersonalAIs();
  }, []);

  const loadPersonalAIs = async (init = true, query = '') => {
    if (init) {
      const res = await apiChatGetPersonalAIs({
        query: query,
        limit: 10,
        offset: 0
      });
      if (res.success) {
        setPersonalAIs(res.data);
        setLastId(res.lastId);
        setHasMore(res.hasMore);
      } else {
        const res = await apiChatGetPersonalAIs({
          query: query,
          limit: 10,
          offset: lastId
        });
        if (res.success) {
          setPersonalAIs((prev) => [...prev, ...res.data]);
          setLastId(res.lastId);
          setHasMore(res.hasMore);
        }
      }
    }
  };

  const onSearch = (query: string) => {
    loadPersonalAIs(true, query);
  };
  const renderTopBar = () => {
    return (
      <div className="find_new_creator_top_bar">
        <div className="find_new_creator_top_bar_left">
          <div
            className="find_new_creator_top_bar_left_back_icon clickable"
            onClick={() => navigate(-1)}
          >
            <AngleLeftIcon />
          </div>
          <div className="font32 font-bold">Find New Creator</div>
        </div>
        <SearchBox
          onSearch={onSearch}
          placeholder="search creator here..."
          fontSize={20}
          height="64"
        />
      </div>
    );
  };
  return (
    <div className="conversation">
      <div style={{ position: 'fixed', width: '100%' }}>
        <MainHeader />
      </div>
      <div className="find_new_creator">
        {renderTopBar()}
        {/* <div className='find_new_creator_top_week'>
                    <MonthlyTopCreators />
                    <WeeklyLeaderBoard />
                </div> */}
        <TrendingNewCreators personalAIs={personalAIs} />
      </div>
    </div>
  );
};
