import CloseIcon from 'assets/svgs/CloseIcon';
import DefaultAvatarIcon from 'assets/svgs/DefaultAvatarIcon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/interfaces';
import { apiGetQAAskOrgGeneral } from 'shared/api/qa';
import { LoadingCircular } from 'shared/ui-kit/LoadingCircular';
import { PrimaryModal } from 'shared/ui-kit/PrimaryModal';
import './index.styles.scss';

interface ShareQuestionToOrgModalProps {
  show: boolean;
  onClose: () => void;
  question: any;
  selectedOrgId: number;
}

export const PostQuestionModal = ({
  show,
  onClose,
  question,
  selectedOrgId
}: ShareQuestionToOrgModalProps) => {
  const [isPostingQuestion, setIsPostingQuestion] = useState(false);
  const [questionToPost, setQuestionToPost] = useState(question);
  const [isAnonymous, setIsAnonymous] = useState(false);

  const { userInfo } = useSelector((state: RootState) => state.profile);
  const navigate = useNavigate();

  const onPostQuestion = async () => {
    setIsPostingQuestion(true);
    const res = await apiGetQAAskOrgGeneral({
      question: questionToPost,
      org: selectedOrgId
    });

    if (res.success) {
      setIsPostingQuestion(false);
      navigate(`/school/question/${res.bookmark}`);
    }
    setIsPostingQuestion(false);
  };

  const renderBody = () => {
    return (
      <textarea
        value={questionToPost}
        className="textarea_question font24 font-bold"
        onChange={(e) => {
          setQuestionToPost(e.target.value);
        }}
        rows={7}
      />
    );
  };

  const renderBottomBar = () => {
    return (
      <div className="add_answer_bottom_bar">
        <div className="add_answer_bottom_bar_btns row_align_items">
          <div
            className="add_answer_bottom_bar_referneces"
            style={{ marginTop: 0 }}
          >
            <div
              className={
                !isAnonymous
                  ? 'user_star_btn_selected clickable'
                  : 'user_star_btn clickable'
              }
              key={`card_ref_user_1`}
              onClick={() => setIsAnonymous(false)}
            >
              {userInfo?.image === '' ? (
                <DefaultAvatarIcon size={24} />
              ) : (
                <img src={userInfo?.image} className="user_avatar" />
              )}
              <div
                className="font-medium font14"
                style={{
                  maxWidth: 100,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {userInfo?.name}
              </div>
            </div>
            <div
              className={
                isAnonymous
                  ? 'user_star_btn_selected clickable'
                  : 'user_star_btn clickable'
              }
              key={`card_ref_user_2`}
              onClick={() => setIsAnonymous(true)}
            >
              <DefaultAvatarIcon size={24} />
              <div className="font-medium font14">Anonymous</div>
            </div>
          </div>
          <div
            className="add_answer_btn clickable"
            onClick={() => {
              onPostQuestion();
            }}
          >
            <div className="font-bold font14">
              {isPostingQuestion ? (
                <LoadingCircular color="white" size={16} />
              ) : (
                'Post Question'
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <PrimaryModal show={show} width={500} onClose={onClose}>
      <div className="modal_create_group">
        <div className="modal_create_group_header">
          <div
            className="align-vertical-center clickable"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon opacity={0.4} />
          </div>
          <div className="question_title font-bold font20">
            Post My Question
          </div>
        </div>

        <div className="modal_post_question_body">
          {renderBody()}
          {renderBottomBar()}
        </div>
      </div>
    </PrimaryModal>
  );
};
