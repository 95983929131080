import React, { useState } from 'react';
import './index.styles.scss';
import { LeftPanel } from './LeftPanel';
import { RigthPanel } from './RightPanel';
interface AddAnswerProps {
  setTab: any;
  questionDetail: any;
  onAddAnswer: () => void;
  showSnackBar: () => void;
}
export const AddAnswer = ({
  setTab,
  questionDetail,
  onAddAnswer,
  showSnackBar
}: AddAnswerProps) => {
  const [answer, setAnswer] = useState('');
  const [isEditAnswer, setIsEditAnswer] = useState(true);
  const [selectedUserRefs, setSelectedUserRefs] = useState<Array<any>>([]);
  const [selectedFileRefs, setSelectedFileRefs] = useState<Array<any>>([]);

  return (
    <div className="row org_home">
      <div className="col-md-9 ">
        <LeftPanel
          showSnackBar={showSnackBar}
          questionDetail={questionDetail}
          onAddAnswer={onAddAnswer}
          setTab={setTab}
          setAnswer={setAnswer}
          answer={answer}
          isEditAnswer={isEditAnswer}
          setIsEditAnswer={setIsEditAnswer}
          selectedUserRefs={selectedUserRefs}
          selectedFileRefs={selectedFileRefs}
        />
      </div>
      <div className="col-md-3 ">
        <RigthPanel
          currentAnswer={answer}
          onUpdateCurrentAnswer={setAnswer}
          setIsEditAnswer={setIsEditAnswer}
          onUpdatedUserRef={setSelectedUserRefs}
          onUpdatedFileRef={setSelectedFileRefs}
          selectedUserRefs={selectedUserRefs}
          selectedFileRefs={selectedFileRefs}
          questionDetail={questionDetail}
        />
      </div>
    </div>
  );
};
